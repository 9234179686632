import React, { useContext, useEffect, useState } from 'react';
import { linkTo } from '@storybook/addon-links';

// Components
import CardOffice from '@components/card/CardOffice';
import { PER_PAGE_FIFTEEN_RECORDS } from '@constants/pagination-constants';
import Pagination from '@components/pagination/Pagination';
import * as paginationActions from '@services/pagination/Pagination';
import OfficeApi from '@apis/office.api';
import DrawerOfficeSearch from '@components/drawer/DrawerOfficeSearch';
import usaStates from '@constants/us-states';
import { officeTypeDictionary, officeTypeFullFormDictionary } from '@constants/dictionaries';
import ProfileApi from '@apis/profile.api';
import CardFilterBar, { IconTypes } from '@components/card/CardFilterBar';
import WarrantylinkApi, { WarrantylinkApiSuppressErrors } from '@apis/warrantylink.api';
import DrawerWLKInvite from '@components/drawer/DrawerWLKInvite';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import ProfileContext from '../../context/ProfileContext/index';
import { fireGAEvent } from '@app/core/tracking.service';
import { WLK_REMOVED } from '@constants/ga-events.constants';
import { msgsWParams } from '@app/locales/en';
import ModalConfirmWLRemoval from '@components/modal/ModalConfirmWLRemoval';
import { FILTER_BY_NUMBER_NAME_ADDRESS } from '@constants/formField-constants';
import { IsTheme, Theme } from '@app/core/featureToggle';
import { classNames } from '@utils';
import REText from '@components/wrappedBDS/REText';
import NotOfficesFound from '@components/offices/NotOfficesFound';

const OfficesTemplate = (props) => {
  // Update filter list
  const { profile } = useContext(ProfileContext);
  const [officesList, setOfficesList] = useState([]); // main list
  const [filteredOfficesList, setFilteredOfficesList] = useState([]); // list modified after filtering
  const [perPageOfficesList, setPerPageOfficesList] = useState([]); // list getting displayed
  const [PER_PAGE_RECORDS] = useState(PER_PAGE_FIFTEEN_RECORDS);
  const [activePage, setActivePage] = useState(0);
  const [state, setState] = useState({});
  const [values, setValues] = useState({});
  const [showSearchOfficeDrawer, setShowSearchOfficeDrawer] = useState(false);
  const [isInviteToWLKDrawerActive, setIsInviteToWLKDrawerActive] = useState(false);
  const [isConfirmRemoveWLKActive, setIsConfirmRemoveWLKActive] = useState(false);
  const [isFetchingOffices, setIsFetchingOffices] = useState(false);
  const [officeToInvite, setOfficeToInvite] = useState({
    officeID: undefined,
    officeName: undefined,
    email: undefined,
  });
  const [officeToRemoveWLK, setOfficeToRemoveWLK] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { addErrorToQueue, addSuccessToQueue } = useGlobalAlert();

  useEffect(() => {
    setState({ ...values });
  }, [values]);

  useEffect(() => {
    fetchOfficeDetails();
  }, []);

  useEffect(() => {
    if (filteredOfficesList) {
      setPerPageOfficesList(filteredOfficesList);
      setActivePage(0);
    }
  }, [filteredOfficesList]);

  async function fetchOfficeDetails() {
    let myOffices = [];
    if (profile) {
      if (profile.offices && profile.offices.length) {
        myOffices = myOffices.concat(
          profile.offices.map((office) => {
            return { id: office.id, type: office.type };
          }),
        );
      }

      if (profile.workedWithOffices && profile.workedWithOffices.length) {
        myOffices = myOffices.concat(
          profile.workedWithOffices.map((office) => {
            return { id: office.id, type: office.type };
          }),
        );
      }
    }
    setIsFetchingOffices(true);
    const officeList = await OfficeApi.getOfficeList(myOffices, false);
    setIsFetchingOffices(false);
    if (officeList?.offices?.length) {
      const totalPageCount = paginationActions.setTotalPageCount(
        officeList.offices.length,
        PER_PAGE_RECORDS,
      );
      setOfficesList(officeList.offices);
      setFilteredOfficesList(officeList.offices);
      setValues({
        ...values,
        filteredOfficesList: officeList.offices,
        officesList: officeList.offices,
        TotalPages: totalPageCount,
      });
    }
  }

  // TODO This is now updated to mirror the old functionality before refactor
  // I don't think this is what it's suppose to do though
  async function submitOfficeDrawerHandler(offices) {
    const { profile, setProfile } = this.context;

    const officeRequest = offices.map((office) => {
      return { id: office.id, type: officeTypeFullFormDictionary[office.type] };
    });

    const request = { profileID: profile.profileID, offices: officeRequest };
    try {
      await ProfileApi.updateUsersOfficeDetails(request);
      const updatedProfile = await ProfileApi.getUser();
      setProfile(updatedProfile);
    } catch (e) {
      console.log(e);
    }

    closeSearchOfficeDrawer();
  }

  function closeSearchOfficeDrawer() {
    setShowSearchOfficeDrawer(false);
  }

  function hideInviteToWLKDrawer() {
    setOfficeToInvite({
      officeID: undefined,
      officeName: undefined,
      email: undefined,
    });
    setIsInviteToWLKDrawerActive(false);
  }

  function showInviteToWLKDrawer(office) {
    setOfficeToInvite({ officeID: office.id, officeName: office.name, email: office.email });
    setIsInviteToWLKDrawerActive(true);
  }

  async function submitWLKInviteHandler(email) {
    WarrantylinkApiSuppressErrors.inviteToWarrantylink(officeToInvite.officeID, email)
      .then(() => {
        addSuccessToQueue(msgsWParams.WLK_INVITE_SUCCESS(email));
        fetchOfficeDetails();
      })
      .catch((err) => {
        addErrorToQueue(msgsWParams.WLK_INVITE_ERROR(email));
        console.error(err);
      });
    hideInviteToWLKDrawer();
  }

  async function removeWLKHandler(effectiveDate) {
    const removedOffice = officesList.find((a) => a.id === officeToRemoveWLK);
    WarrantylinkApi.removeWarrantylink(officeToRemoveWLK, effectiveDate)
      .then(() => {
        addSuccessToQueue(
          msgsWParams.WLK_REMOVE_SUCCESS(
            `${removedOffice?.name} ${removedOffice.address.address1}`,
          ),
        );
        fetchOfficeDetails();
      })
      .catch((err) => {
        addErrorToQueue(
          msgsWParams.WLK_REMOVE_ERROR(`${removedOffice?.name} ${removedOffice.address.address1}`),
        );
        console.error(err);
      });
    setIsConfirmRemoveWLKActive(false);
    setOfficeToRemoveWLK('');
    fireGAEvent(WLK_REMOVED);
  }

  function filterOffices(filterString) {
    const upperFilterString = filterString.toUpperCase();
    setSearchValue(filterString);
    let filteredOffices = officesList;
    if (filterString.trim()) {
      filteredOffices = officesList.filter((office) => {
        return (
          office.name.toUpperCase().includes(upperFilterString) ||
          office.address.address1.toUpperCase().includes(upperFilterString) ||
          office.address.city.toUpperCase().includes(upperFilterString) ||
          office.address.state.toUpperCase().includes(upperFilterString) ||
          office.address.zip.toUpperCase().includes(upperFilterString)
        );
      });
    }

    setFilteredOfficesList(filteredOffices);
  }

  return (
    <div className={classNames([IsTheme(Theme.Ahs2024) ? '' : 'container', 'px-0 mx-0'])}>
      <CardFilterBar
        id="orders-search--filter-container"
        className="w-full shadow-none border-2"
        inputLabel="Search for offices by name or address"
        showClearButton={true}
        placeHolder={FILTER_BY_NUMBER_NAME_ADDRESS}
        onChange={filterOffices}
        icon={IsTheme(Theme.Ahs2024) ? IconTypes.Search : undefined}
        outerCSS="p-3"
        filterText={searchValue}
      />
      {!isFetchingOffices && (
        <div
          id="result_number"
          className={classNames(['text-sm', IsTheme(Theme.Ahs2024) ? 'mt-6' : 'mt-4'])}
        >
          <REText variant="caption" id="my-offices_number-offices">
            {filteredOfficesList.length} Offices
          </REText>
        </div>
      )}

      <div className={classNames(['flex flex-wrap -mx-2', IsTheme(Theme.Ahs2024) ? 'mt-4' : ''])}>
        {isFetchingOffices ? (
          Array(6)
            .fill(null)
            .map((item, idx) => (
              <div key={idx} className="w-full sm:w-1/2 lg:w-1/3 p-2">
                <CardOffice isSkeleton={true} />
              </div>
            ))
        ) : perPageOfficesList.length > 0 ? (
          perPageOfficesList.map((item, idx) => (
            <div key={idx} className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <CardOffice
                id={item.id}
                name={item.name}
                address={item.address}
                phoneNumber={item.phones.office}
                warrantyLinkEligible={item.warrantyLinkEligible}
                warrantyLinkStatus={item.warrantyLinkStatus}
                onClick={linkTo('Templates/Office', 'Single Office')}
                menuLinks={[
                  {
                    label: 'Invite to WarrantyLink',
                    onClick: () => showInviteToWLKDrawer(item),
                    id: 'invite_to_wlink',
                  },
                  {
                    label: 'Remove WarrantyLink',
                    onClick: () => {
                      setOfficeToRemoveWLK(item.id);
                      setIsConfirmRemoveWLKActive(true);
                    },
                    id: 'remove_wlink',
                  },
                ]}
              />
            </div>
          ))
        ) : (
          <NotOfficesFound />
        )}
      </div>

      <ModalConfirmWLRemoval
        id="remove-warranty-link-confirm-modal"
        heading="Confirm WarrantyLink Removal"
        isActive={isConfirmRemoveWLKActive}
        onClose={() => setIsConfirmRemoveWLKActive(false)}
        onConfirm={removeWLKHandler}
      />

      <div className="flex justify-center mt-10">
        {filteredOfficesList.length && state.TotalPages > 1 ? (
          <Pagination>
            <Pagination.Prev
              onClick={() => paginationActions.onPaginationPrevClick(setActivePage, activePage)}
            />
            {Array(state.TotalPages)
              .fill(null)
              .map((item, idx) => (
                <Pagination.Link
                  key={idx}
                  isActive={idx === activePage}
                  onClick={(event) =>
                    paginationActions.onPageNumberClick(setActivePage, parseInt(idx))
                  }
                >
                  {idx + 1}
                </Pagination.Link>
              ))}
            <Pagination.Next
              onClick={() =>
                paginationActions.onPaginationNextClick(setActivePage, activePage, state.TotalPages)
              }
            />
          </Pagination>
        ) : (
          ''
        )}
      </div>
      <DrawerOfficeSearch
        usaStates={usaStates}
        typeCode={officeTypeDictionary[profile.roleIDType]}
        excludeBrandSearch={false}
        isActive={showSearchOfficeDrawer}
        onClose={closeSearchOfficeDrawer}
        onSubmit={submitOfficeDrawerHandler}
      />
      <DrawerWLKInvite
        officeID={officeToInvite.officeID}
        officeName={officeToInvite.officeName}
        officeEmail={officeToInvite.email}
        isActive={isInviteToWLKDrawerActive}
        onClose={hideInviteToWLKDrawer}
        onSubmit={submitWLKInviteHandler}
      />
    </div>
  );
};

export default OfficesTemplate;
