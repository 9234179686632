import DrawerOrder from '@components/drawer/DrawerOrder';
import { getOrderRowShortStatusLabel } from '@helpers/order.utils';
import React, { useContext, useEffect, useState } from 'react';
import ProfileContext from '@context/ProfileContext';
import { accountToOfficeTypeDictionary, officeTypeDictionary } from '@constants/dictionaries';
import OfficeApi from '@apis/office.api';
import ContractApi, { ContractApiSuppressErrors } from '@apis/contract.api';

import { Contract, REOrder } from '@apis/models';
import RealEstateOrderApi from '@apis/realestateorder.api';
import { isInternalUser } from '@helpers/profile.utils';
import Path from '@constants/paths';
import { ORDER_DETAILS_ACCESS_FAILURE } from '@constants/formField-constants';
import useGlobalAlert, { Message } from '@app/core/GlobalAlertModal';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { useNavigate } from 'react-router-dom';

interface DrawerOrderCombinedProps {
  onClose: () => void;
  contractID: string;
  isOrderDrawerActive: boolean;
  onRefreshedContractLoad?: (contract: Contract) => void;
  includeScreenLoadSpinner?: boolean;
}

const DrawerOrderCombined: React.FC<DrawerOrderCombinedProps> = (props) => {
  const { profile } = useContext(ProfileContext);
  const navigate = useNavigate();
  const { addWarningToQueue } = useGlobalAlert();
  const { showSpinner } = useGlobalOverlaySpinner();

  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedREOrder, setSelectedREOrder] = useState(null);
  const [offices, setOffices] = useState(null);

  const [loading, setLoading] = useState(false);

  const msgs: { [key: string]: Message } = {
    ACCESS_DENIED: {
      id: 'notification-access-denied-warning',
      onClose: () => props.onClose(),
      className: 'max-w-full',
      title: 'Unable to Access Order Details',
      message: ORDER_DETAILS_ACCESS_FAILURE,
      actions: [
        {
          href: Path.ContactUs,
          label: 'Contact Us',
        },
      ],
    },
  };

  useEffect(() => {
    if (profile) {
      const reOffices = [...profile.offices, ...profile.workedWithOffices].filter(
        (o) => officeTypeDictionary[o.type] === 'RE',
      );
      OfficeApi.getOfficeList(
        reOffices.map((office) => {
          return { id: office.id, type: office.type };
        }),
      ).then((userOffices) => setOffices(userOffices.offices));
    }
  }, [profile]);

  useEffect(() => {
    if (props.contractID) {
      clearCurrentContract();
      getContractDetails(props.contractID);
    }
  }, [props.contractID]);

  /** display a page load overlay when needed */
  useEffect(() => {
    showSpinner(props.includeScreenLoadSpinner && loading);
  }, [props.includeScreenLoadSpinner, loading]);

  async function getContractDetails(contractID: string) {
    try {
      if (contractID) {
        setLoading(true);
        const contractDetails = await ContractApiSuppressErrors.getContractDetails(
          [contractID],
          true,
          true,
        );

        if (contractDetails?.length) {
          setSelectedContract(contractDetails[0]);
          props.onRefreshedContractLoad?.(contractDetails[0]);
        } else {
          console.error('no contracts returned');
          //suppress the global error message and redirect to dashboard if contract not found
          navigate(Path.Dashboard);
          return;
        }

        if (
          isInternalUser(profile) ||
          accountToOfficeTypeDictionary[profile.roleIDType] === officeTypeDictionary.ClosingCompany
        ) {
          const { summary } = contractDetails[0];
          const tempReOrder: REOrder = {
            id: parseInt(summary.id),
            tableId: parseInt(summary.id),
            address: summary.address,
            expirationDate: summary.expirationDate,
            initiatingAgentName: summary.agentName,
            initiatingOfficeName: summary.officeName,
            status: summary.status,
            realEstateStatus: summary.realEstateStatus,
            awaitingWlkSubmission: summary.awaitingWlkSubmission,
            initiatingAgentID: undefined,
            initiatingOfficeID: undefined,
            warrantylink: { status: undefined, deadlineDate: undefined },
          };

          setSelectedREOrder(tempReOrder);
        } else {
          const contractSummary =
            await RealEstateOrderApi.searchProfileContractByContractID(contractID);
          if (contractSummary && contractSummary.orders?.length) {
            setSelectedREOrder(contractSummary.orders[0]);
          } else {
            console.error('no contracts returned');
            addWarningToQueue(msgs.ACCESS_DENIED);
          }
        }
      }
    } catch (e) {
      console.error('failed to load contract', e);
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateContract(contract) {
    await getContractDetails(contract.summary.id);
  }

  function clearCurrentContract() {
    setSelectedContract(null);
    setSelectedREOrder(null);
  }

  return (
    <div>
      {props.isOrderDrawerActive && selectedContract && selectedREOrder && (
        <DrawerOrder
          id="drawer-order"
          isActive={props.isOrderDrawerActive}
          contract={selectedContract}
          realEstateStatus={getOrderRowShortStatusLabel(selectedREOrder)}
          selectedREOrder={selectedREOrder}
          onClose={() => props.onClose()}
          onUpdate={(contract) => handleUpdateContract(contract)}
          userDetails={profile}
          userOffices={offices}
        />
      )}
    </div>
  );
};

export default DrawerOrderCombined;
