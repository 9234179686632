import React from 'react';
import PanelContractSummary from '@components/panel/PanelContractSummary';
import { orderToSummary } from '@helpers/order.utils';
import ContractSearchBar, {
  ContractSearchBarProps,
} from '@components/drawer/subcomponents/ContractSearchBar';
import ProfileContext from '@context/ProfileContext';
import ProfileModel from '@app/models/profile.model';
import { OfficeIDAndType } from '@apis/models';
import { officeTypeDictionary } from '@constants/dictionaries';
import { isAgent } from '@helpers/profile.utils';
import { isInternalUser } from '@helpers/profile.utils';
import { accountToOfficeTypeDictionary } from '@constants/dictionaries';
import REText from '@components/wrappedBDS/REText';

interface props
  extends Pick<
    ContractSearchBarProps,
    | 'includedStatuses'
    | 'placeHolder'
    | 'globalSearchRestrictions'
    | 'unsuccessfulSearchErrorMessage'
  > {
  title: any;
  subTitle?: string;
  idPrefix: string;
  onClickContract: any;
  showExpiryDate?: boolean;
  actions?: any;
}

class QuickLinkSearch extends React.Component<props> {
  static contextType = ProfileContext;
  constructor(props) {
    super(props);
  }

  // TODO Pagination isn't needed with the current state of the Global Search...
  // onPageChange = (page) => {
  //  // Fetch Contracts from API with page * itemsPerPage
  //  // SetState the new contracts
  // }
  getProfileOfficeIDsAndTypes = (): OfficeIDAndType[] => {
    const profile: ProfileModel = this.context.profile;

    if (
      isInternalUser(profile) ||
      accountToOfficeTypeDictionary[profile.roleIDType] == officeTypeDictionary.ClosingCompany
    ) {
      return [];
    }

    if (isAgent(profile)) {
      return [];
    }

    return profile?.offices.map<OfficeIDAndType>((office) => ({
      id: office.id,
      type: officeTypeDictionary[office.type],
    }));
  };

  getProfileAgentIDAndTypes = (): OfficeIDAndType[] => {
    const profile: ProfileModel = this.context.profile;

    if (
      isInternalUser(profile) ||
      accountToOfficeTypeDictionary[profile.roleIDType] == officeTypeDictionary.ClosingCompany
    ) {
      return [];
    }

    if (!isAgent(profile)) {
      return [];
    }

    return [
      {
        id: profile.roleID,
        type: officeTypeDictionary[profile.roleIDType],
      },
    ];
  };

  render() {
    return (
      <ContractSearchBar
        includedStatuses={this.props.includedStatuses}
        placeHolder={this.props.placeHolder}
        unsuccessfulSearchErrorMessage={this.props.unsuccessfulSearchErrorMessage}
        headerContentRenderer={() => (
          <>
            <REText id={`${this.props.idPrefix}_title`} variant="heading-03" color="primary">
              {this.props.title}
            </REText>
            {this.props.subTitle && (
              <p className="mt-4 mb-2">
                <REText variant="body-short">{this.props.subTitle}</REText>
              </p>
            )}
          </>
        )}
        filterByOffices={this.getProfileOfficeIDsAndTypes()}
        filterByAgents={this.getProfileAgentIDAndTypes()}
        globalSearchRestrictions={this.props.globalSearchRestrictions}
        searchedContractsRenderer={(orders, searchState) => (
          <>
            {orders.map((contract) => (
              <PanelContractSummary
                key={contract.id}
                contract={orderToSummary(contract)}
                idPrefix={`${contract.id}`}
                onClickContract={this.props.onClickContract}
                query={searchState.searchTerm}
                actions={this.props.actions?.(contract)}
                showExpiryDate={this.props.showExpiryDate}
              />
            ))}
          </>
        )}
        footerContentRenderer={(state) => (
          <>
            {/* <Pagination */}
            {/*  onItemsPerPageChange={null} */}
            {/*  itemsPerPage={itemsPerPage} */}
            {/*  itemsPerPageOptions={[{value: '15', label: '15'}]} */}
            {/*  onPageChange={this.onPageChange} */}
            {/*  totalItems={state.totalContracts} */}
            {/*  page={state.activePage} */}
            {/*  hideViewAll={true} */}
            {/* /> */}
          </>
        )}
      />
    );
  }
}

export default QuickLinkSearch;
