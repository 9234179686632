import React, { useState } from 'react';
import OrderForm from '@components/orders/forms/OrderForm';
import { Input, Select } from '@ftdr/blueprint-components-react';
import { ORDER_FORM_FIELDS } from '@constants/newOrder-constants';
import AddressSuggestionInput from '@components/input/AddressSuggestionInput';
import { usStatesForBDSSelect } from '@constants/us-states';
import { OrderFormProps } from '@components/orders/forms/OrderForm.types';
import { OrderAddressInfoFormData } from '@app/models/order/order.model';
import ZipInput from '@components/input/ZipInput';
import * as c from '@constants/formField-constants';
import { Address } from '@app/models';

export enum OrderFormAddressInfoField {
  zip = 'zip',
  streetAddress = 'streetAddress',
  unitNumber = 'unitNumber',
  city = 'city',
  state = 'state',
}
interface OrderFormAddressInfoProps
  extends OrderFormProps<OrderAddressInfoFormData, OrderFormAddressInfoField> {
  ids: Record<OrderFormAddressInfoField, string>;
  label: string;
  allowPOBox: boolean;
}

const OrderFormAddressInfo: React.FC<OrderFormAddressInfoProps> = (props) => {
  const onZipBlur = (zip: string) => {
    props.onValidateField(OrderFormAddressInfoField.zip, zip);
  };

  const onZipChange = (zip: string) => {
    props.clearFieldError(OrderFormAddressInfoField.zip);
    props.onUpdateFormData({
      ...props.formData,
      state: '',
      city: '',
      zip,
    });
  };

  const onUnitBlur = (unit: string) => {
    props.onValidateField(OrderFormAddressInfoField.unitNumber, unit);
  };

  const onUnitChange = (unit: string) => {
    props.onUpdateFormData({
      ...props.formData,
      unit,
    });
  };

  const handleCityBlur = (city: string) => {
    props.onValidateField(OrderFormAddressInfoField.city, city);
  };

  const onCityChange = (city: string) => {
    props.onUpdateFormData({
      ...props.formData,
      city,
    });
  };

  const setAddressValue = (value: Address) => {
    props.onUpdateFormData({
      ...props.formData,
      streetAddress: value.streetAddress,
      city: value.city,
    });
  };

  const handleStateSelect = (e) => {
    props.onValidateField(OrderFormAddressInfoField.state, e.value);
    props.onUpdateFormData({
      ...props.formData,
      state: e.value,
    });
  };

  const onLoadCustomerAddressZipDetails = (res) => {
    if (res) {
      props.clearFieldError(OrderFormAddressInfoField.zip);
      props.clearFieldError(OrderFormAddressInfoField.city);
      props.clearFieldError(OrderFormAddressInfoField.state);
      props.onUpdateFormData({
        ...props.formData,
        zip: res.zip,
        city: res.city,
        state: res.state,
      });
    } else {
      props.onFieldError(OrderFormAddressInfoField.zip, c.INVALID_ZIP_CODE);
    }
  };

  return (
    <OrderForm.Container id="address-info-form" heading={props.label}>
      <OrderForm.Row>
        <OrderForm.RowItem width="initial" className="w-1/4">
          <ZipInput
            id={props.ids.zip}
            value={props.formData.zip}
            error={props.errors.zip}
            onZipBlur={onZipBlur}
            onZipChange={onZipChange}
            onZipError={() =>
              props.onValidateField(OrderFormAddressInfoField.zip, props.formData.zip)
            }
            onLoadZipDetails={(e) => onLoadCustomerAddressZipDetails(e)}
          />
        </OrderForm.RowItem>
      </OrderForm.Row>
      <OrderForm.Row>
        <OrderForm.RowItem>
          <AddressSuggestionInput
            id={props.ids.streetAddress}
            name="streetAddress"
            label="Street Address"
            addressLookup={{
              city: props.formData.city,
              state: props.formData.state,
              zip: props.formData.zip,
            }}
            allowPOBox={props.allowPOBox}
            value={props.formData.streetAddress}
            error={props.errors.streetAddress}
            setValue={setAddressValue}
            setError={(err) => props.onFieldError(OrderFormAddressInfoField.streetAddress, err)}
          />
        </OrderForm.RowItem>
        <OrderForm.RowItem>
          <Input
            formField={true}
            id={props.ids.unitNumber}
            name="unitNumber"
            required={false}
            value={props.formData.unit}
            autoComplete="off"
            onBlur={(e) => onUnitBlur(e.currentTarget.value)}
            onChange={(e) => onUnitChange(e.currentTarget.value)}
            label="Unit #"
          />
        </OrderForm.RowItem>
      </OrderForm.Row>
      <OrderForm.Row>
        <OrderForm.RowItem width="initial" className="w-1/2 pr-2">
          <Input
            formField={true}
            id={props.ids.city}
            name="city"
            required={true}
            value={props.formData.city}
            error={props.errors.city}
            autoComplete="off"
            onBlur={(e) => handleCityBlur(e.currentTarget.value)}
            onChange={(e) => onCityChange(e.currentTarget.value)}
            label="City"
          />
        </OrderForm.RowItem>
        <OrderForm.RowItem width="initial" className="w-1/4">
          <Select
            className="-ml-2"
            formField={true}
            id={props.ids.state}
            name="state"
            required={true}
            options={usStatesForBDSSelect}
            selected={
              props.formData.state
                ? usStatesForBDSSelect.find((o) => o.value === props.formData.state)
                : null
            }
            onSelect={handleStateSelect}
            error={props.errors.state}
            disabled={false}
            label="State"
          />
        </OrderForm.RowItem>
      </OrderForm.Row>
    </OrderForm.Container>
  );
};

export default OrderFormAddressInfo;
