// Note: This file has moved towards centralized handling of submissions for all individual card submissions, see handleCardSubmission for example of pattern
// TODO: File is too large and should be refactored into smaller components if possible
// TODO: when normalizing domain, convert sellerInfo.sellerInfo to something to the effect of doYouHaveASellerRadioValues or something to that effect

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

// Components
import CardNewOrderAgentInfo from '@components/card/CardNewOrderAgentInfo';
import CardNewOrderPropertyAddress from '@components/card/CardNewOrderPropertyAddress';
import CardNewOrderPropertyDetails from '@components/card/CardNewOrderPropertyDetails';
import CardNewOrderBuyerInfo from '@components/card/CardNewOrderBuyerInfo';
import CardNewOrderSellerInfo from '@components/card/CardNewOrderSellerInfo';
import CardNewOrderClosingInfo, {
  isClosingDateWithinRange,
} from '@components/card/CardNewOrderClosingInfo';
import ModalCancelOrder from '@components/modal/ModalCancelOrder';
import usaStates from '@constants/us-states';
import {
  ACTION_EDIT,
  ACTION_SAVE,
  ACTION_SELECT,
  AGENT_LABELS,
  CLOSING_LABEL,
  CONTEXT,
  FSBO_ALLOWED_ROLES,
  getOriginatorName,
  MILITARY_DISCOUNT,
  NEW_ORDER,
  NEW_ORDER_LABELS,
  ORDER_CARD_TITLE,
  REALTOR_OWNED_FOR_INIT_AGENT_ROLES,
} from '@constants/newOrder-constants';
import { searchCityStateByZip } from '@services/zip.service';
import { DEFAULT_CUSTOMER_PHONE_TYPE, PLACE_ORDER_FAIL_MSG } from '@constants/formField-constants';

import { useNavigate } from 'react-router-dom';
import { getBrand } from '@helpers/brand.utils';
import Path from '@constants/paths';
import {
  calculateListingTermInDays,
  getAgeByNewConstruction,
  getFranchiseCodeFromOffice,
  getOrderRequestFromNewOrderValues,
  getPlanReselectionReason,
  getSquareFootage,
  getSquareFootageFromOptionName,
  getUniqueDocumentTypes,
  hasBuyerInfo,
  isBuyerContactInfoRequired,
  isBuyerInfoRequired,
  isClosingInfoRequired,
  isSellerContactInfoRequired,
  isSellerNameRequired,
  OrderFlowType,
  OrderRequestStateAttributes,
  PlanCardResetReason,
  shouldReturnToBuyerInfo,
  toPlanCoverageValues,
} from '@helpers/order.utils';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  CAPTURE_SELLERS_COVERAGE,
  MILITARY_DISCOUNT_REMOVED,
  ORDER__ACTION_SEND_DOCUMENTS,
  ORDER__BUYER_SELLER_CLOSING_INFO_STEP,
  ORDER__CANCEL,
  ORDER__PROPERTY_ADDRESS_SAVE,
  ORDER__PROPERTY_DETAILS_SAVE,
  ORDER__SUBMIT,
  ORDER__UPDATE_PLAN_STEP,
  ORDERS_SUBMITTED_FROM_PLANS_PRICES,
  PLAN_PRICE__CONVERT_ORDER_SUBMIT,
  SUBMITTED_ABANDONED_ORDER,
} from '@constants/ga-events.constants';
import PropertyApi from '@apis/property.api';

import { cleanPhone, formatDate, formatDateToISO8601, parseDate } from '@helpers/utils';
import { isEmpty, isNil, merge } from 'lodash';
import msgs from '@app/locales/en';
import {
  ContractPricing,
  CreateOrderRequest,
  CreateOrderSelectedProduct,
  ProductPricingDetailsRequest,
} from '@apis/models';
import {
  Button,
  Panel,
  Popover,
  PopoverContent,
  WorkflowIndicator,
  WorkflowStep,
  WorkflowStepStatus,
} from '@ftdr/blueprint-components-react';

import useGlobalAlert, { Message } from '@app/core/GlobalAlertModal';
import ModalNewOrderAccountFailure from '@components/modal/ModalNewOrderAccountFailure';
import ModalNewOrderIncompleteRequest from '@components/modal/ModalNewOrderIncompleteRequest';
import CardNewOrderPlansCoverage, {
  PlansCoverageValues,
} from '@components/card/CardNewOrderPlansCoverage';
import { UserRoleType } from '@apis/models/profile.api.model';
import { format } from 'date-fns';
import SendDocuments, { Recipient, Type as DocumentType } from '@components/SendDocuments';
import ProductApi from '@apis/product.api';
import { RecipientType } from '@app/generators/recipientGenerator';
import OrdersApi, { GetUserError, OrdersApiSuppressErrors } from '@apis/orders.api';
import { EmailDocument, EmailRecipient, Order } from '@apis/models/orders.api.model';
import { getYearBuiltFromAge } from '@services/helpers';
import { PromoBanner } from '@components/misc/PromoBanner';
import { NEW_ORDER_SOURCE_PLANS_AND_PRICES } from '@templates/misc/PlansAndPricesTemplate';
import { getNewOrderStepLabelOrdered } from '@helpers/new-order-step';
import ValidateApi from '@apis/validate.api';
import ProfileModel from '@app/models/profile.model';
import NewOrderLoader from '@helpers/new-order-loader.order';
import { NewOrderSellerInfo } from '@helpers/new-order-loader';
import { NewOrderStepDeterminer } from '@helpers/new-order-step.order';
import { isREAgent } from '@helpers/profile.utils';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import RealEstateQuoteApi from '@apis/realestatequote.api';
import { UnsubmittedOrderCancellationWorkflow } from '@constants/app.constants';
import ModalNoPlansFound from '@components/modal/ModalNoPlansFound';
import ModalInvalidClosingDate from '@components/modal/ModalInvalidClosingDate';
import cloneDeep from 'lodash/cloneDeep';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

// Used for tracking state of buyer/seller/both throughout the order process
// This needs to be set outside the component so that it holds its value through re-renders
let originatorName = '';

/** User roles that can be offered RO products */
const ALLOWED_REALTOR_OWNED_ROLES: UserRoleType[] = [
  UserRoleType.RealEstateAgent,
  UserRoleType.Broker,
  UserRoleType.RealEstateAdmin,
  UserRoleType.FieldSales,
  UserRoleType.InternalSalesAgent,
  UserRoleType.SiteAdmin,
];

export interface Props {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  planCoveragesValues: PlansCoverageValues;
  setPlanCoveragesValues: React.Dispatch<React.SetStateAction<PlansCoverageValues>>;
  handleSubmit: (event: any) => any;
  userDetails: ProfileModel;
  existingOrderID: string;
  isNewOrder: boolean;
  source: string;
  /** passed in an order with details to load onto the page. */
  existingOrder: Order;
  /** when true, this will be following the My Quote > Convert to Order flow logic and operations */
  isQuoteConvertToOrder: boolean;
  /** when true, if existingOrder provided, would automatically jump to their latest step after loading */
  shouldUserJumpToExistingOrderStep: boolean;
  /** when true, this would set the earnix rule id and product listing effective date based on historic data.
   *  otherwise, the latest earnix rule id would be used. */
  shouldUserBeOfferedOldProducts: boolean;
}

interface StepStates {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewOrderTemplate: React.FC<Props> = ({
  isQuoteConvertToOrder,
  planCoveragesValues,
  setPlanCoveragesValues,
  ...props
}) => {
  const isRealtorOwnedDefaultsSet = useRef(false); // use of non hook allows us to avoid a re-render
  const [isRealtorOwnedSelectionEnabled, setIsRealtorOwnedSelectionEnabled] =
    useState<boolean>(false);

  const { addErrorToQueue } = useGlobalAlert();
  const { showSpinner } = useGlobalOverlaySpinner();

  const [modalCancelOrderActive, setModalCancelOrderActive] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [customCooperatingAgentDetails, setCustomCooperatingAgentDetails] = useState({
    hasValue: false,
    agentFirstName: '',
    agentLastName: '',
    agentEmail: '',
  });
  const [isAbandonedOrder, setIsAbandonedOrder] = useState(false);

  const [modalInvalidClosingDateActive, setModalInvalidClosingDateActive] =
    useState<boolean>(false);
  const [invalidClosingDateValue, setInvalidClosingDateValue] = useState<Date>(null);

  useEffect(() => {
    // convert order from 'my quotes' page flow
    if (props.existingOrderID) {
      setOrderID(props.existingOrderID);
    }
  }, []);

  /** handles loading props.existingOrder to the page */
  useEffect(() => {
    if (!props.existingOrder) {
      return;
    }
    loadStatesFromOrder(props.existingOrder);
    if (props.shouldUserJumpToExistingOrderStep) {
      jumpToStepFromOrder(props.existingOrder);
    }
    postLoadOrder(props.existingOrder);
  }, [props.existingOrder, props.shouldUserJumpToExistingOrderStep]);

  /** for a given existing order returned from property address card, load and jump to latest step */
  const loadExistingOrderFromPropertyAddress = (orderID: string): void => {
    if (!orderID) {
      return;
    }
    setIsAbandonedOrder(true);
    showSpinner(true);
    OrdersApi.get(orderID)
      .then((order) => {
        if (order) {
          loadStatesFromOrder(order);
          jumpToStepFromOrder(order);
          postLoadOrder(order);
        }
      })
      .catch((err) => {
        console.error('failed to load order', err);
        addErrorToQueue(msgs.FAILED_TO_LOAD_ORDER);
      })
      .finally(() => {
        showSpinner(false);
      });
  };

  /** for a given order, begin loading the component states with values */
  const loadStatesFromOrder = (order: Order): void => {
    if (!order) {
      console.warn('attempted to load states wih undefined order. ignored.');
      return;
    }

    const newOrderLoader = new NewOrderLoader();
    const existingOrderValues = newOrderLoader.mapToNewOrderData(order);

    // handle values and initial states
    props.setValues(existingOrderValues);
    setOrderID(order.orderID);
    setSellerInfo(existingOrderValues.sellerInfo);
    setAgentInfo({ agentInfo: existingOrderValues.agentInfo });
    setProjectedClosingDate(existingOrderValues.closingInfo?.projectedClosingDate);

    // handle other initial states
    setPropertyAddressUUID(order.order.propertyAddressUUID);
    setBuyerAddressUUID(order.order.customers?.buyer?.addressUUID);
    setCoBuyerAddressUUID(order.order.customers?.cobuyer?.addressUUID);
    setSellerAddressUUID(order.order.customers?.seller?.addressUUID);
    setCoSellerAddressUUID(order.order.customers?.coseller?.addressUUID);

    setQuoteID(order.order.quoteID);
    setPricingValues(order?.details?.pricing);
    setPlanCoveragesValues(
      toPlanCoverageValues(
        order.details?.planAndCoverages,
        order.details?.pricing?.appliedSpecialDiscounts?.includes(MILITARY_DISCOUNT),
      ),
    );
    setProductPricingDetailsRequest(newOrderLoader.mapToProductPricingDetailsRequest(order));

    if (props.shouldUserBeOfferedOldProducts) {
      setEarnixRuleID(order.details?.pricing?.earnixRuleID);
    }

    // handle cooperating
    if (order?.order?.offices?.cooperatingOffice?.agentFirstName) {
      const { agentFirstName, agentLastName, agentEmail } = order.order.offices.cooperatingOffice;
      onPersistCustomCooporatingAgent({
        hasValue: true,
        agentFirstName,
        agentLastName,
        agentEmail,
      });
    }
  };

  /** conditional for checking if the closing date modal should be opened. */
  const shouldModalInvalidClosingDateBeOpen = (order: Order): boolean =>
    order.getClosingDate() && !isClosingDateWithinRange(order.order.estimatedClosingDate, null);

  /** perform any post operations after loading the order */
  const postLoadOrder = (order: Order): void => {
    // open modal for user to select a new plan
    if (shouldModalInvalidClosingDateBeOpen(order)) {
      setInvalidClosingDateValue(order.getClosingDate());
      setModalInvalidClosingDateActive(true);
    }
  };

  /** for a given order, begin opening and closing edit card based off of the steps */
  const jumpToStepFromOrder = (order: Order): void => {
    const stepDeterminer = new NewOrderStepDeterminer(isREAgent(props.userDetails));
    const step = stepDeterminer.getCurrentNewOrderStep(order);
    const stepWorkflow = stepDeterminer.getCurrentNewOrderWorkflow(order);

    closeSteps(); // close all steps first to avoid active states defaulted as true

    setActiveStep(stepWorkflow.indexOf(step) + 1);
    setREActiveStep(stepWorkflow.indexOf(step) + 1);

    // do not open the card for specific situations
    if (
      step === ORDER_CARD_TITLE.PLANS_AND_COVERAGES &&
      shouldModalInvalidClosingDateBeOpen(order)
    ) {
      console.warn('due to closing date needing to be updated, plan card is not opened');
    } else {
      openSingleStepToEditByName(step);
    }

    // TODO: may or may not ned to backfill state 'hasBeenSaved'. uncertain of its importance
  };

  /** given step name(s), closes the step.
   * @param steps -- the step names defined in ORDER_CARD_TITLE. If none mentioned, then all steps are closed.
   * */
  const closeSteps = (...steps: string[]): void => {
    getStatesByStepNameMap().forEach((stepStates, key) => {
      if (steps.length === 0 || steps.includes(key)) {
        stepStates.setIsEditing(false);
      }
    });
  };

  /** given the step name, open edit mode for that step.
   * @param step -- the step name defined in ORDER_CARD_TITLE
   * @param closeAllOtherCards - when set to true, all other cards will be closed along the process */
  const openSingleStepToEditByName = (step: string, closeAllOtherCards: boolean = true): void => {
    getStatesByStepNameMap().forEach((stepStates, key) => {
      if (step === key) {
        stepStates.setIsEditing(true);
      } else if (closeAllOtherCards) {
        stepStates.setIsEditing(false);
      }
    });
  };

  /** returns common states pertaining to the step */
  const getStatesByStepName = (step: string): StepStates => {
    return getStatesByStepNameMap().get(step);
  };

  /** to consolidate some states into an object to pass it around easier */
  const getStatesByStepNameMap = (): Map<string, StepStates> => {
    const states = new Map<string, StepStates>();
    states.set(ORDER_CARD_TITLE.AGENT_INFO, {
      isEditing: isAgentEdit,
      setIsEditing: setIsAgentEdit,
    });
    states.set(ORDER_CARD_TITLE.PROPERTY_ADDRESS, {
      isEditing: isAddressEdit,
      setIsEditing: setIsAddressEdit,
    });
    states.set(ORDER_CARD_TITLE.PROPERTY_DETAILS, {
      isEditing: isPropertyEdit,
      setIsEditing: setIsPropertyEdit,
    });
    states.set(ORDER_CARD_TITLE.SELLER_INFO, {
      isEditing: isSellerEdit,
      setIsEditing: setIsSellerEdit,
    });
    states.set(ORDER_CARD_TITLE.BUYER_INFO, {
      isEditing: isBuyerEdit,
      setIsEditing: setIsBuyerEdit,
    });
    states.set(ORDER_CARD_TITLE.CLOSING_INFO, {
      isEditing: editingClosingInfo,
      setIsEditing: setEditingClosingInfo,
    });
    states.set(ORDER_CARD_TITLE.PLANS_AND_COVERAGES, {
      isEditing: isPlansCoverageEditing,
      setIsEditing: setIsPlansCoverageEditing,
    });
    return states;
  };

  /** when user submits closing date update, should update the order and continue as normal */
  const onSubmitClosingDateUpdate = async (closingDate: Date): Promise<void> => {
    const date = formatDate(closingDate);

    const updatedValues = cloneDeep(props.values);
    const updatedStates: Partial<OrderRequestStateAttributes> = {};

    // update states
    updatedValues.closingInfo = {
      ...updatedValues.closingInfo,
      dateUnknown: false,
      projectedClosingDate: date,
    };
    props.setValues(updatedValues);
    setProjectedClosingDate(date);

    // also update the quote if exists
    if (quoteID) {
      updatedStates.quoteID = await refreshQuoteIDWithNewClosingDate(
        date,
        projectedClosingDate,
        quoteID,
      );
    }

    await handleOrderIDUpdates(updatedValues, updatedStates);

    setModalInvalidClosingDateActive(false);
    openSingleStepToEditByName(ORDER_CARD_TITLE.PLANS_AND_COVERAGES);
  };

  const { width } = useWindowSize();
  const [isSmall, setIsSmall] = useState(false);
  const [isAgentEdit, setIsAgentEdit] = useState(true);
  const [isAddressEdit, setIsAddressEdit] = useState(true);
  const [isPropertyEdit, setIsPropertyEdit] = useState(true);
  const [isSellerEdit, setIsSellerEdit] = useState(false);
  const [isBuyerEdit, setIsBuyerEdit] = useState(false);
  const navigate = useNavigate();
  const [sellerInfo, setSellerInfo] = useState<NewOrderSellerInfo>({
    sellerInfo: { yes: false, no: false },
  });
  const [
    isARetroactivelyCollectedSellersInfoCallback,
    setIsARetroactivelyCollectedSellersInfoCallback,
  ] = useState(false);
  const [showSellerCoverageDefaultSelectionMessage, setShowSellerCoverageDefaultSelectionMessage] =
    useState(false);
  const [isPlansCoverageEditing, setIsPlansCoverageEditing] = useState(true);

  useEffect(() => {
    setIsSmall(width < 768);
  }, [width]);

  useEffect(() => {
    if (isSellerEdit) {
      //ARE-11220 (required to show message in Quote -> conver to Order flow)
      //show message if represent is Buyer, Seller default selected Yes and disabled
      const requireSeller = hasSellersCoverage();
      const requireSellerName = isSellerNameRequired(
        requireSeller,
        props.values.agentInfo?.agentRepresents,
      );
      const hasSellerName = props.values.sellerInfo?.firstName;
      const doesAgentRepresentBuyer = props.values?.agentInfo?.representing === 'Buyer';
      const canShowMesage = requireSellerName && !hasSellerName && doesAgentRepresentBuyer;
      setShowSellerCoverageDefaultSelectionMessage(canShowMesage);
    }
  }, [isSellerEdit]);

  const [userType, setUserType] = useState('');
  const [reActiveStep, setREActiveStep] = useState(1);
  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const brandLocation = getBrand();
  const [editingClosingInfo, setEditingClosingInfo] = useState(false);

  const [canSubmitNewOrder, setCanSubmitNewOrder] = useState(false);
  const [isSubmittingNewOrder, setIsSubmittingNewOrder] = useState(false);

  const [modalIncompleteRequestActive, setModalIncompleteRequestActive] = useState(false);
  const [incompleteRequestMsg, setIncompleteRequestMsg] = useState<string>(null);
  const [modalNoPlansFoundActive, setModalNoPlansFoundActive] = useState<boolean>(false);

  const [pricingValues, setPricingValues] = useState<ContractPricing>(null);
  const [agentInfo, setAgentInfo] = useState(null);

  // used to scroll to view
  const buyerCardRef = React.useRef<HTMLDivElement>(null);
  const sellerCardRef = React.useRef<HTMLDivElement>(null);
  const closingCardRef = React.useRef<HTMLDivElement>(null);
  const sendDocumentsRef = React.useRef<HTMLDivElement>(null);

  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const prevClosingDateRef = useRef();
  const prevClosingAgentEmailRef = useRef();

  const [orderID, setOrderID] = useState<string>(null);
  const [propertyAddressUUID, setPropertyAddressUUID] = useState<string>(null);
  const [buyerAddressUUID, setBuyerAddressUUID] = useState<string>(null);
  const [coBuyerAddressUUID, setCoBuyerAddressUUID] = useState<string>(null);
  const [sellerAddressUUID, setSellerAddressUUID] = useState<string>(null);
  const [coSellerAddressUUID, setCoSellerAddressUUID] = useState<string>(null);
  const [earnixRuleID, setEarnixRuleID] = useState<number>(null);
  const [quoteID, setQuoteID] = useState<string>(null);
  const [productPricingDetailsRequest, setProductPricingDetailsRequest] =
    useState<ProductPricingDetailsRequest>(null);
  const [projectedClosingDate, setProjectedClosingDate] = useState<string>(null);
  const [planSelectionError, setPlanSelectionError] = useState<string>('');
  const [isCreatingQuoteID, setIsCreatingQuoteID] = useState<boolean>(false);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState<boolean>(false);
  const [isSendDocumentsValid, setIsSendDocumentsValid] = useState<boolean>(false);

  const productListingEffectiveDate: Date = useMemo(() => {
    if (!props.shouldUserBeOfferedOldProducts) {
      return null;
    }
    return new Date(props.existingOrder?.createdDate) || parseDate(props.values.effectiveDate);
  }, [props.existingOrder, props.values]);

  const [hasBeenSaved, setHasBeenSaved] = useState({
    agentCard: false,
    propertyAddressCard: false,
    propertyDetailsCard: false,
    buyerCard: false,
    sellerCard: false,
    closingCard: false,
    plansCard: false,
  });

  const cardSubmittingHelperText = useMemo(() => {
    if (isCreatingQuoteID || isUpdatingOrder) {
      return 'saving...';
    }
    return '';
  }, [isCreatingQuoteID, isUpdatingOrder]);

  const orderRequestStateAttributes: OrderRequestStateAttributes = useMemo(() => {
    return {
      propertyAddressUUID,
      quoteID,
      buyerAddressUUID,
      coBuyerAddressUUID,
      sellerAddressUUID,
      coSellerAddressUUID,
    };
  }, [
    propertyAddressUUID,
    quoteID,
    buyerAddressUUID,
    coBuyerAddressUUID,
    sellerAddressUUID,
    coSellerAddressUUID,
  ]);

  const createEmailDocuments = (): EmailDocument => {
    const invoice: EmailRecipient[] = [];
    const confirmation: EmailRecipient[] = [];
    recipients.forEach((x) => {
      if (x.selectedTypes.includes(DocumentType.Invoice)) {
        invoice.push({
          email: x.email,
          recipient: x.type[0].toLowerCase() + x.type.slice(1).replace('', ''),
        });
      }

      if (x.selectedTypes.includes(DocumentType.Confirmation)) {
        confirmation.push({
          email: x.email,
          recipient: x.type[0].toLowerCase() + x.type.slice(1).replace('', ''),
        });
      }
    });
    return { invoice, confirmation };
  };

  /** handle order id create or update, provided the partial updates.
   * it takes the partial updates and merge it together with the props.values and orderRequestStateAttributes
   * to maintain non-related card submission values.
   * Leave as {} if no partial updates found. */
  const handleOrderIDUpdates = async (
    partialUpdateValues: any = {},
    partialUpdateAttributes: Partial<OrderRequestStateAttributes> = {},
  ): Promise<void> => {
    const orderRequest = getOrderRequestFromNewOrderValues(
      { ...props.values, ...partialUpdateValues },
      { ...orderRequestStateAttributes, ...partialUpdateAttributes },
      createEmailDocuments(),
      props.userDetails,
    );

    console.group('handle order updates');
    console.debug('orderid', orderID);
    console.debug('saved card updates', partialUpdateValues, partialUpdateAttributes);
    console.debug('existing values', props.values, orderRequestStateAttributes);
    console.debug('orderRequest', orderRequest);
    console.groupEnd();

    // Dont save agentID/OfficeID in custom cooperating agent flow.
    if (customCooperatingAgentDetails.hasValue) {
      orderRequest.offices.cooperatingOffice = { agentID: '', officeID: '' };
    }

    // if order id is defined, we want to update the order. otherwise we need to create one
    if (orderID && orderRequest.propertyAddressUUID) {
      setIsUpdatingOrder(true);
      OrdersApiSuppressErrors.update(orderID, orderRequest)
        .then((res) => {
          if (res?.errors) {
            handleOrderErrors(res.errors);
          }
          console.info('order was updated', orderID);
        })
        .catch((err) => {
          console.error('failed to update order', err);
        })
        .finally(() => {
          setIsUpdatingOrder(false);
        });
    } else {
      // we only create the order if property address has been defined
      if (orderRequest.propertyAddressUUID) {
        setIsUpdatingOrder(true);
        OrdersApiSuppressErrors.create(orderRequest)
          .then((res) => {
            if (res?.orderID) {
              console.info('order was created', res?.orderID);
              setOrderID(res.orderID);
            }
            if (res?.errors) {
              handleOrderErrors(res.errors);
            }
          })
          .catch((err) => {
            console.error('failed to create order id:', err);
          })
          .finally(() => {
            setIsUpdatingOrder(false);
          });
      } else {
        console.info('order is not ready to be created yet');
      }
    }
  };

  const handleOrderErrors = (errors) => {
    addErrorToQueue({
      title: 'Attention Required',
      messageHTML: (
        <>
          {errors.map((error) => (
            <div key={error.code}>{GetUserError(error)}</div>
          ))}
        </>
      ),
    });
  };

  // TODO Refactor the seller population logic, specifically sellerInfo.no check
  // Should probably just populate the seller object and never return null
  // With whatever data we have.  We can validate that the info is complete/required elsewhere
  // But returning null even if we potentially have data is bad and leads to data dependencies
  // This causes extra bugs when one of the data dependencies has a bug, and is hard to track down
  // Unsure of the effects of always populating this object though
  const getSeller = (req) => {
    return req.sellerInfo?.sellerInfo && req.sellerInfo?.sellerInfo.no
      ? null
      : {
          first_name: req.sellerInfo.firstName,
          last_name: req.sellerInfo.lastName,
          email: req.sellerInfo.email,
          phone: cleanPhone(req.sellerInfo.phone),
          phone_type: !req.sellerInfo.phone ? '' : DEFAULT_CUSTOMER_PHONE_TYPE,
          alt_address: !req.sellerInfo.alternateAddressFields
            ? null
            : {
                street_address: req.sellerInfo.streetAddress,
                unit: req.sellerInfo.unit,
                city: req.sellerInfo.city,
                state: req.sellerInfo.state,
                zip: req.sellerInfo.zipCode,
              },
          co_seller: !req.sellerInfo.coSellerFields
            ? []
            : [
                {
                  first_name: req.sellerInfo.coCustomerFirstName,
                  last_name: req.sellerInfo.coCustomerLastName,
                  email: req.sellerInfo.coCustomerEmail,
                  phone: cleanPhone(req.sellerInfo.coCustomerPhone),
                  phone_type: !req.sellerInfo.coCustomerPhone ? '' : DEFAULT_CUSTOMER_PHONE_TYPE,
                  address: !req.sellerInfo.coSellerFields
                    ? {}
                    : {
                        street_address: req.sellerInfo.coCustomerStreetAddress,
                        unit: req.sellerInfo.coCustomerUnit,
                        city: req.sellerInfo.coCustomerCity,
                        state: req.sellerInfo.coCustomerState,
                        zip: req.sellerInfo.coCustomerZipCode,
                      },
                },
              ],
        };
  };

  const getBuyer = (req) => {
    return req.buyerInfo.buyerInfo && req.buyerInfo.buyerInfo.no
      ? null
      : {
          first_name: req.buyerInfo.firstName,
          last_name: req.buyerInfo.lastName,
          email: req.buyerInfo.email,
          phone: cleanPhone(req.buyerInfo.phone),
          phone_type: !req.buyerInfo.phone ? '' : DEFAULT_CUSTOMER_PHONE_TYPE,
          alt_address: !req.buyerInfo.alternateAddressFields
            ? null
            : {
                street_address: req.buyerInfo.streetAddress,
                unit: req.buyerInfo.unit,
                city: req.buyerInfo.city,
                state: req.buyerInfo.state,
                zip: req.buyerInfo.zipCode,
              },
          co_buyer: !req.buyerInfo.coBuyerFields
            ? []
            : [
                {
                  first_name: req.buyerInfo.coCustomerFirstName,
                  last_name: req.buyerInfo.coCustomerLastName,
                  email: req.buyerInfo.coCustomerEmail,
                  phone: cleanPhone(req.buyerInfo.coCustomerPhone),
                  phone_type: !req.buyerInfo.coCustomerPhone ? '' : DEFAULT_CUSTOMER_PHONE_TYPE,
                  address: {
                    street_address: req.buyerInfo.coCustomerStreetAddress,
                    unit: req.buyerInfo.coCustomerUnit,
                    city: req.buyerInfo.coCustomerCity,
                    state: req.buyerInfo.coCustomerState,
                    zip: req.buyerInfo.coCustomerZipCode,
                  },
                },
              ],
        };
  };

  const getLatestRecipients = (): Recipient[] => {
    let copy = [...recipients];
    const existingTypes = copy.map((x) => x.type);

    let buyerInfo = {};
    let sellerInfo = {};
    const cooperatingAgent = getCooperatingAgent(props.values);
    const { agentInfo } = props.values;

    if (!isEmpty(props.values?.buyerInfo?.buyerInfo)) buyerInfo = getBuyer(props.values) || {};
    if (!isEmpty(props.values?.sellerInfo?.sellerInfo)) sellerInfo = getSeller(props.values) || {};

    const latest = [
      {
        // @ts-expect-error TODO: type doesn't exist
        name: `${buyerInfo.first_name} ${buyerInfo.last_name}`,
        // @ts-expect-error TODO: type doesn't exist
        email: buyerInfo.email,
        type: RecipientType.Buyer,
        selectedTypes: [],
      },
      {
        // @ts-expect-error TODO: type doesn't exist
        name: buyerInfo?.co_buyer
          ? // @ts-expect-error TODO: type doesn't exist
            `${buyerInfo?.co_buyer[0]?.first_name} ${buyerInfo?.co_buyer[0]?.last_name}`
          : '',
        // @ts-expect-error TODO: type doesn't exist
        email: buyerInfo?.co_buyer ? buyerInfo?.co_buyer[0]?.email : '',
        type: RecipientType.CoBuyer,
        selectedTypes: [],
      },
      {
        // @ts-expect-error TODO: type doesn't exist
        name: `${sellerInfo.first_name} ${sellerInfo.last_name}`,
        // @ts-expect-error TODO: type doesn't exist
        email: sellerInfo.email,
        type: RecipientType.Seller,
        selectedTypes: [],
      },
      {
        // @ts-expect-error TODO: type doesn't exist
        name: sellerInfo?.co_seller
          ? // @ts-expect-error TODO: type doesn't exist
            `${sellerInfo?.co_seller[0]?.first_name} ${sellerInfo?.co_seller[0]?.last_name}`
          : '',
        // @ts-expect-error TODO: type doesn't exist
        email: sellerInfo?.co_seller ? sellerInfo?.co_seller[0]?.email : '',
        type: RecipientType.CoSeller,
        selectedTypes: [],
      },
      {
        name: `${props.userDetails?.firstName} ${props.userDetails?.lastName}`,
        email: props.userDetails?.email,
        type: RecipientType.Myself,
        selectedTypes: [],
      },
      {
        name: agentInfo?.AgentName,
        email: agentInfo?.AgentEmail,
        type: RecipientType.InitiatingAgent,
        selectedTypes: [],
      },
      {
        name: cooperatingAgent?.AgentName,
        email: cooperatingAgent?.AgentEmail,
        type: RecipientType.CooperatingAgent,
        selectedTypes: [],
      },
      {
        name: `${customCooperatingAgentDetails?.agentFirstName} ${customCooperatingAgentDetails.agentLastName}`,
        email: customCooperatingAgentDetails?.agentEmail,
        type: RecipientType.CooperatingAgentInvited,
        selectedTypes: [],
      },
    ];

    setDefaults(latest).forEach((l) => {
      if (existingTypes.includes(l.type)) {
        copy.find((existing) => existing.type === l.type).email = l.email;
        copy.find((existing) => existing.type === l.type).name = l.name;
      } else {
        copy = [...copy, l];
      }
    });

    return copy.filter((x) => {
      if (x.type === RecipientType.Other) return true;

      return !isEmpty(x.email);
    });
  };

  const getAllowedTypes = (): DocumentType[] => {
    return [
      DocumentType.Confirmation,
      !isEmpty(props.values.closingInfo?.projectedClosingDate) ? DocumentType.Invoice : null,
    ];
  };

  const setClosingInfoDefaults = (recipients: Recipient[]): Recipient[] => {
    if (isEmpty(props.values?.closingInfo?.projectedClosingDate)) return recipients;

    return recipients.map((r) => {
      if (
        [
          RecipientType.ClosingAgent,
          RecipientType.Myself,
          RecipientType.InitiatingAgent,
          RecipientType.CooperatingAgent,
          RecipientType.CooperatingAgentInvited,
        ].includes(r.type)
      ) {
        r.selectedTypes = [...r.selectedTypes, DocumentType.Invoice];
      }

      return r;
    });
  };

  const setDefaults = (recipients: Recipient[]): Recipient[] => {
    let copy = [...recipients];

    copy = recipients.map((r) => {
      if (
        [
          RecipientType.CooperatingAgent,
          RecipientType.CooperatingAgentInvited,
          RecipientType.Myself,
          RecipientType.InitiatingAgent,
        ].includes(r.type)
      ) {
        r.selectedTypes = [...r.selectedTypes, DocumentType.Confirmation];
      }

      return r;
    });

    return setClosingInfoDefaults(copy);
  };

  const isRecipientSelected = () => {
    return recipients.some((x) => x.selectedTypes.length > 0 && !isEmpty(x.email));
  };

  const getRowDefaults = (recipients: Recipient[]): Recipient[] => {
    if (!recipients.find((x) => x.type === RecipientType.Other))
      return [...recipients, { name: '', email: '', type: RecipientType.Other, selectedTypes: [] }];

    return recipients;
  };

  useEffect(() => {
    setRecipients(getLatestRecipients());
  }, [
    props.values?.buyerInfo?.email,
    props.values?.sellerInfo?.email,
    props.values?.buyerInfo?.co_buyer?.buyerInfo?.co_buyer[0]?.email,
    props.values?.sellerInfo?.co_seller?.sellerInfo?.co_seller[0]?.email,
    props.userDetails?.email,
    props.values?.agentInfo?.AgentEmail,
    props.values?.agentInfo?.AgentName,
  ]);

  const getClosingDateUpdate = (recipients: Recipient[]): Recipient[] => {
    let update = [...recipients];

    if (isEmpty(props.values?.closingInfo?.projectedClosingDate)) {
      // we've gone from no info to info, so remove ability to send invoices
      update = update.map((x) => {
        // remove invoice selections on closing info delete
        x.selectedTypes = x.selectedTypes.filter((type) => type !== DocumentType.Invoice);

        return x;
      });
    } else if (isEmpty(prevClosingDateRef.current)) {
      // we've gone from no info to info, so set defaults
      update = setClosingInfoDefaults(update);
    }

    prevClosingDateRef.current = props.values?.closingInfo?.projectedClosingDate;

    return update;
  };

  const getClosingAgentEmailUpdate = (recipients: Recipient[]): Recipient[] => {
    if (!props.values?.closingInfo?.projectedClosingDate) return recipients;

    let update = [...recipients];
    const latestName = props.values?.closingInfo?.AgentName;
    const latestEmail = props.values?.closingInfo?.AgentEmail;

    if (!isEmpty(props.values?.closingInfo?.AgentEmail)) {
      const existing = recipients.some((x) => x.type === RecipientType.ClosingAgent);

      if (existing) {
        update.find((x) => x.type === RecipientType.ClosingAgent).name = latestName;
        update.find((x) => x.type === RecipientType.ClosingAgent).email = latestEmail;
      } else if (isEmpty(prevClosingAgentEmailRef.current)) {
        // we've gone from no agent to an agent, so add and update defaults
        update = [
          ...update,
          ...setDefaults([
            {
              name: latestName,
              email: latestEmail,
              type: RecipientType.ClosingAgent,
              selectedTypes: [],
            },
          ]),
        ];
      }
    } else {
      // we've gone from agent to no agent so remove
      update = update.filter((x) => x.type !== RecipientType.ClosingAgent);
    }

    prevClosingAgentEmailRef.current = latestEmail;

    return update;
  };

  useEffect(() => {
    setRecipients(getClosingAgentEmailUpdate(getClosingDateUpdate(getLatestRecipients())));
  }, [props.values?.closingInfo?.projectedClosingDate, props.values?.closingInfo?.AgentEmail]);

  const getCooperatingAgent = (req): any => {
    if (req.agentInfo?.representing === 'Buyer' && req.sellerInfo?.AgentEmail) {
      return req.sellerInfo;
    }
    if (req.agentInfo?.representing === 'Seller' && req.buyerInfo?.AgentEmail) {
      return req.buyerInfo;
    }

    return {};
  };

  const ORDER_FAILED_NOTIFICATION = (errMsg: string): Message => {
    return {
      id: 'order-failed-notification-error',
      messageHTML: (
        <>
          <div>{PLACE_ORDER_FAIL_MSG}</div>
          {errMsg && (
            <div className="text-red-400 mt-2 px-3">
              <ul className="list-disc">
                {errMsg.split('\n').map((msg, idx) => msg && <li key={idx}>{msg}</li>)}
              </ul>
            </div>
          )}
        </>
      ),
    };
  };

  const scrollIntoViewOptions: any = {
    block: isSmall ? 'center' : 'start',
    behavior: 'smooth',
  };

  useEffect(() => {
    if (props.userDetails && props.userDetails.roleIDType) {
      setUserType(props.userDetails.roleIDType);
      setUser(props.userDetails);
      if (props.userDetails.roleIDType === 'RealEstateAgent') {
        setActiveStep(2);
      }

      setIsRealtorOwnedSelectionEnabled(
        ALLOWED_REALTOR_OWNED_ROLES.includes(props.userDetails.roleIDType as UserRoleType),
      );
    }
  }, [props.userDetails]);

  const userIsActive = () => {
    // Currently only RE Agents have an inactive status (from STAR) so if the
    // user isn't type RE Agent they're active.
    return props.userDetails.active === true || props.userDetails.roleIDType !== 'RealEstateAgent';
  };

  const [warnCannotSubmitOrder, setWarnCannotSubmitOrder] = useState(!userIsActive());

  useEffect(() => {
    if (props.values?.sellerInfo) setSellerInfo(props.values?.sellerInfo);
  }, [props.values?.sellerInfo]);

  /** Load the property ID when address and property are set */
  const propertyUpdateHandler = (propertyAddr, propertyDetails) => {
    try {
      // Do not load property ID if information is not fully available
      if (isNil(propertyAddr) || isNil(propertyDetails)) {
        return;
      }

      if (propertyDetails && propertyAddr.meta?.addressId) {
        PropertyApi.updateProperty(propertyAddr.meta?.addressId, {
          yearBuilt: getYearBuiltFromAge(propertyDetails.age),
          residenceType: propertyDetails.residenceType,
          squareFootage: getSquareFootageFromOptionName(propertyDetails.squareFootage),
        }).catch((err) => console.error('failed to update or create property:', err));
      }
    } catch (e) {
      console.error('Failed to make call to get property id', e);
    }
  };

  /** determines whether or not the plan card is visible on the form */
  const isPlanCardVisible = (): boolean => {
    // currently determine if plan card is visible via what step the user is currently on
    return reActiveStep >= 7 || activeStep >= 7;
  };

  const isSellerCardBeforeBuyerCard = (agentInfo = props.values.agentInfo): boolean => {
    return agentInfo?.agentRepresents && !agentInfo?.agentRepresents.buyer;
  };

  /** determines whether or not the buyer card is visible on the form */
  const isBuyerCardVisible = (agentInfo = props.values.agentInfo): boolean => {
    const buyerCardStep = isSellerCardBeforeBuyerCard(agentInfo) ? 5 : 4;

    return reActiveStep >= buyerCardStep || activeStep >= buyerCardStep;
  };

  /** determines whether or not the seller card is visible on the form */
  const isSellerCardVisible = (agentInfo = props.values.agentInfo): boolean => {
    const sellerCardStep = isSellerCardBeforeBuyerCard(agentInfo) ? 4 : 5;
    return reActiveStep >= sellerCardStep || activeStep >= sellerCardStep;
  };

  /** determines whether or not the seller card is visible on the form */
  const isClosingCardVisible = (): boolean => {
    return reActiveStep >= 6 || activeStep >= 6;
  };

  const propertyAddressSubmitHandler = (propertyAddressValues) => {
    fireGAEvent(ORDER__PROPERTY_ADDRESS_SAVE(hasBeenSaved.propertyAddressCard, orderID));

    setPropertyAddressUUID(propertyAddressValues.meta?.addressId); // get the property address uuid
    handleOrderIDUpdates(
      {},
      {
        propertyAddressUUID: propertyAddressValues.meta?.addressId,
      },
    );

    // open the plan card if property address changed and we are on the plan card step
    if (propertyAddressValues.propertyAddressChanged && isPlanCardVisible()) {
      handlePlanCardReset(PlanCardResetReason.AddressChanged);
    } else {
      saveSubmit(userType !== 'RealEstateAgent' ? 3 : 2);
    }

    setHasBeenSaved({ ...hasBeenSaved, propertyAddressCard: true });
  };

  const propertyDetailsSubmitHandler = (propertyDetailsValues) => {
    fireGAEvent(
      ORDER__PROPERTY_DETAILS_SAVE({
        residenceType: propertyDetailsValues.residenceType,
        squareFootage: `${getSquareFootageFromOptionName(propertyDetailsValues.squareFootage)}`,
        isNewConstruction: propertyDetailsValues.newConstruction,
        isEdit: hasBeenSaved.propertyDetailsCard,
        orderId: orderID,
      }),
    );

    setHasBeenSaved({ ...hasBeenSaved, propertyDetailsCard: true });

    handleOrderIDUpdates(
      {
        propertyDetails: propertyDetailsValues,
      },
      {},
    );

    // open the plan card if property address changed and we are on the plan card step. otherwise it is the normal flow
    if (propertyDetailsValues.propertyChanged && isPlanCardVisible()) {
      return handlePlanCardReset(PlanCardResetReason.PropertyChanged);
    }

    // go straight to closing card for RO flow
    if (props.values.agentInfo.isRealtorOwned) {
      setEditingClosingInfo(true);
      saveSubmit(6);
      return;
    }

    saveSubmit(userType !== 'RealEstateAgent' ? 4 : 3);
    updateStepEditState(ORDER_CARD_TITLE.PROPERTY_DETAILS);
  };

  /** closes current step and opens next step for edit */
  const updateStepEditState = (
    currentStep: string,
    represents: string = props.values.agentInfo?.representing,
    openEditNextStep: boolean = true,
  ) => {
    // close current step
    getStatesByStepName(currentStep).setIsEditing(false);

    // opens next step
    if (openEditNextStep) {
      const workflow = getNewOrderStepLabelOrdered(isREAgent(props.userDetails), represents);
      const currentStepIndex = workflow.indexOf(currentStep);
      const nextStepIndex = currentStepIndex + 1;
      if (nextStepIndex > 0 && nextStepIndex < workflow.length) {
        const nextStep = workflow[nextStepIndex];
        getStatesByStepName(nextStep).setIsEditing(true);
      }
    }
  };

  const saveSubmit = (step) => {
    if (userType === 'RealEstateAgent') step > reActiveStep && setREActiveStep(step);
    else step > activeStep && setActiveStep(step);
  };

  /**
   * transition from agent info card based off of the data.
   * - If RO value got changed and plan card visible, then jump to plan card.
   * - If franchise office got changed and plan card visible, then jump to plan card.
   * - If RO flow, jump to plan card if visible, otherwise,
   *    - if agent user, jump to closing card
   *    - else, normal transition flow
   * - Otherwise, enter normal transition flow,
   *   - if representing buyer, jump to buyer edit
   *   - if representing seller, jump to seller edit
   *   - if representing both, jump to seller edit
   * @param update -- values ({agentInfo}) from the agent card
   */
  const transitionFromAgentInfo = (update) => {
    // must always be triggered first for GA event handling
    originatorName = getOriginatorName(update);

    const isREAgentUser = userType === UserRoleType.RealEstateAgent;
    const updatedAgentInfoIsRO: boolean = update.agentInfo.isRealtorOwned;

    const isAgentEmailAndPhoneEmpty =
      update.agentInfo.AgentEmail === '' && update.agentInfo.AgentPhone === ''; // agent is used for buyer info for RO flow
    const isROToggled = props.values.agentInfo.isRealtorOwned !== update.agentInfo.isRealtorOwned;
    const isFranchiseChanged = update.agentInfo.didOfficeFranchiseChange;

    // we don't want to continue to the normal flow if one of the following is true. this is for any edit flows
    let shouldStopPrematurely = false;

    // set the selected office and agent info
    setAgentInfo(update);

    setIsBuyerEdit(false);
    setIsSellerEdit(false);

    // is plan card is visible, open plan card edit when a new plan selection needs to be done
    if (isPlanCardVisible()) {
      if (isROToggled) {
        handlePlanCardReset(
          update.agentInfo.isRealtorOwned
            ? PlanCardResetReason.RealtorOwnedPropertySelected
            : PlanCardResetReason.RealtorOwnedPropertyDeselected,
        );
        shouldStopPrematurely = true;
      } else if (isFranchiseChanged) {
        handlePlanCardReset(PlanCardResetReason.FranchiseChanged);
        shouldStopPrematurely = true;
      }
    }

    // if RO is set, we need to see if the buyer and seller card needs to be open
    if (isBuyerCardVisible(update.agentInfo)) {
      if (
        updatedAgentInfoIsRO &&
        REALTOR_OWNED_FOR_INIT_AGENT_ROLES.includes(userType) &&
        isAgentEmailAndPhoneEmpty
      ) {
        // open buyer card if agent email/phone is empty
        setIsBuyerEdit(true);
        shouldStopPrematurely = true;
      } else if (isROToggled && !updatedAgentInfoIsRO) {
        if (isSellerCardBeforeBuyerCard(update.agentInfo)) {
          setIsSellerEdit(true);
          shouldStopPrematurely = true;
        } else {
          setIsBuyerEdit(true);
          shouldStopPrematurely = true;
        }
      }
    }

    if (shouldStopPrematurely) {
      console.log('agent info caused other cards to open up. we do not want normal flow anymore');
      return;
    }

    // RO flow, plan card if visible, otherwise closing card
    if (updatedAgentInfoIsRO) {
      if (isPlanCardVisible()) {
        setIsPlansCoverageEditing(true);
        saveSubmit(7);
        return;
      }
      if (isREAgentUser) {
        setEditingClosingInfo(true);
        saveSubmit(6);
        return;
      }
    }

    // Normal flow

    // set the buyer/seller edit
    if (updatedAgentInfoIsRO) {
      // For Admin user type, keep the buyer card in edit mode if agent both email and phone are missing.
      if (REALTOR_OWNED_FOR_INIT_AGENT_ROLES.includes(userType) && isAgentEmailAndPhoneEmpty) {
        setIsBuyerEdit(true);
        setIsSellerEdit(false);
      } else {
        setIsBuyerEdit(false);
        setIsSellerEdit(false);
      }
    } else if (update.agentInfo.agentRepresents.both) {
      setIsBuyerEdit(false);
      setIsSellerEdit(true);
    } else {
      setIsBuyerEdit(update.agentInfo.agentRepresents.buyer);
      setIsSellerEdit(!update.agentInfo.agentRepresents.buyer);
    }

    // choose the correct step based on type
    if (isREAgentUser) {
      saveSubmit(4);
    } else {
      saveSubmit(2);
    }
  };

  const sellerFirstSubmitHandler = () => {
    saveSubmit(5);
    setIsSellerEdit(false);
    setIsBuyerEdit(true);
  };

  const hasSellersCoverage = (): boolean => {
    if (planCoveragesValues) {
      return planCoveragesValues.selectedPlanCoverages?.product?.sellersCoverage;
    }
    return false;
  };

  const orderSubmitHandler = (roFlow) => {
    const hasIncompleteSellerName = handleIncompleteSellerNameCheck();

    if (!hasIncompleteSellerName) {
      setErrorMessage('');
      let len = props.values && Object.keys(props.values).length;
      // add to the length if plan/coverage is filled.
      if (planCoveragesValues) {
        len += 1;
      }

      // ... WTF?  This is BAD validation.
      // TODO Fix whatever the fuck this is.  I'm not sure how/why this works this way
      // Maybe just validating the correct number of props were passed in?
      // Maybe a failsafe?
      if (len >= 7) {
        const ordersRequestBody = getOrderRequestFromNewOrderValues(
          props.values,
          orderRequestStateAttributes,
          createEmailDocuments(),
          props.userDetails,
        );

        // assign custom cooporating agent details. AgentID/OrderId not required for custom cooperating agent flow
        if (customCooperatingAgentDetails.hasValue) {
          ordersRequestBody.offices.cooperatingOffice = {
            ...ordersRequestBody.offices.cooperatingOffice,
            agentEmail: customCooperatingAgentDetails.agentEmail,
            agentFirstName: customCooperatingAgentDetails.agentFirstName,
            agentLastName: customCooperatingAgentDetails.agentLastName,
            officeID: '',
          };
        }

        const requestBody = createNewOrderRequest(props.values);

        /*
         * ARE-7022 -- upon submission, check to see if buyer/seller/closing information is incomplete.
         * If so, display a modal and navigate the user to the buyer/seller/closing card where error is present.
         * @return boolean - true if has incomplete submission. should not call API if so.
         */
        const { incomplete, isMissingBuyerInfo, isMissingSellerInfo, isMissingClosingDate } =
          isIncompleteRequest(requestBody);
        if (incomplete) {
          console.log('request is incomplete, existing submission');
          openIncompleteRequestErrorModal(
            isMissingBuyerInfo,
            isMissingSellerInfo,
            isMissingClosingDate,
          );
          scrollToIncompleteCard(isMissingBuyerInfo, isMissingSellerInfo, isMissingClosingDate);
          return;
        }

        setIsSubmittingNewOrder(true);

        OrdersApi.createContract(ordersRequestBody, orderID)
          .then((res) => {
            if (!res || !res.contractID) {
              addErrorToQueue(ORDER_FAILED_NOTIFICATION(errorMessage));
              const msg = 'Failed to create order using order MS';
              setErrorMessage(msg);
            } else {
              onSuccess(res, roFlow, pricingValues);
              if (hasSellersCoverage()) {
                fireGAEvent(
                  CAPTURE_SELLERS_COVERAGE(res?.realEstate?.initiatingOffice?.represents),
                );
              }
              fireGAEventsBySource(props.source, originatorName, res.contractID, orderID);

              if (isAbandonedOrder) {
                fireGAEvent(
                  SUBMITTED_ABANDONED_ORDER('Abandoned Order Retrieval on New Order page'),
                );
              } else if (props.existingOrder) {
                fireGAEvent(SUBMITTED_ABANDONED_ORDER('Not Submitted Tab on My Orders page'));
              } else if (!props.isNewOrder) {
                fireGAEvent(PLAN_PRICE__CONVERT_ORDER_SUBMIT);
              }
              // Track in GA send documents from new order
              const uniqueDocTypes = getUniqueDocumentTypes(recipients);
              if (uniqueDocTypes?.length > 0) {
                fireGAEvent(ORDER__ACTION_SEND_DOCUMENTS(uniqueDocTypes, orderID));
              }
            }
          })
          .catch((e) => {
            console.error(e);
            addErrorToQueue(ORDER_FAILED_NOTIFICATION(errorMessage));
          })
          .finally(() => {
            setIsSubmittingNewOrder(false);
          });
      }
    }
  };

  const fireGAEventsBySource = (source, originatorName, contractId, orderId) => {
    fireGAEvent(ORDER__SUBMIT(originatorName, contractId, orderId));
    switch (source) {
      case NEW_ORDER_SOURCE_PLANS_AND_PRICES:
        fireGAEvent(ORDERS_SUBMITTED_FROM_PLANS_PRICES);
        break;
      default:
        break;
    }
  };

  const onSuccess = (res, roFlow, pricing?: ContractPricing) => {
    // Passing order data with route -- data should be added only in body as bellow
    navigate(Path.NewOrderSubmitted, {
      state: {
        newOrderData: props.values,
        userDetails: user,
        contractId: res.contract ? res.contract : res.contractID,
        roFlow,
        pricing,
        orderID: res.orderID,
      },
    });
  };

  /**
   * Checks if create order request is incomplete.
   * If error occurs, this returns false so we can consider the request as complete and have the api handle the validation.
   * @param r - the create order request
   * @returns -- the incomplete flag, as well as individual fields that are missing data
   */
  const isIncompleteRequest = (
    r: CreateOrderRequest,
  ): {
    incomplete: boolean;
    isMissingBuyerInfo?: boolean;
    isMissingSellerInfo?: boolean;
    isMissingClosingDate?: boolean;
  } => {
    try {
      const isMissingBuyerInfo = r.isMissingBuyerInfo();
      const isMissingSellerInfo = r.isMissingSellerInfo();
      const isMissingClosingDate = r.isMissingClosingDate();

      const incomplete: boolean = [
        isMissingBuyerInfo,
        isMissingSellerInfo,
        isMissingClosingDate,
      ].some((b) => b);

      return {
        incomplete,
        isMissingBuyerInfo,
        isMissingSellerInfo,
        isMissingClosingDate,
      };
    } catch (e) {
      console.error(
        'error occurred when checking request. skipping incomplete check and moving forward',
        e,
      );
      return {
        incomplete: false,
      };
    }
  };

  /**
   * scroll to the first incomplete card depending on what information is missing.
   * @param isMissingBuyerInfo
   * @param isMissingSellerInfo
   * @param isMissingClosingDate
   */
  const scrollToIncompleteCard = (
    isMissingBuyerInfo: boolean,
    isMissingSellerInfo: boolean,
    isMissingClosingDate: boolean,
  ): void => {
    try {
      // open edit for the card that needs to be edited
      let ref: React.MutableRefObject<HTMLDivElement>;
      if (isMissingBuyerInfo) {
        setIsBuyerEdit(true);
        ref = buyerCardRef;
      } else if (isMissingSellerInfo) {
        setIsSellerEdit(true);
        ref = sellerCardRef;
      } else if (isMissingClosingDate) {
        setEditingClosingInfo(true);
        ref = closingCardRef;
      }

      // scroll to view
      ref?.current?.scrollIntoView(scrollIntoViewOptions);
    } catch (e) {
      console.error('error occurred when scrolling to incomplete card. avoid scrolling', e);
    }
  };

  /**
   * Opens a modal for user to know about the incomplete request
   * @param isMissingBuyerInfo
   * @param isMissingSellerInfo
   * @param isMissingClosingDate
   */
  const openIncompleteRequestErrorModal = (
    isMissingBuyerInfo: boolean,
    isMissingSellerInfo: boolean,
    isMissingClosingDate: boolean,
  ): void => {
    // create the base error string
    let errorMsg = `${[
      isMissingBuyerInfo && 'buyer information',
      isMissingSellerInfo && 'seller information',
      isMissingClosingDate && 'closing date',
    ]
      .filter((v) => Boolean(v)) // remove any false values (not missing)
      .join(', ')} is required to submit the order.`;

    // first character should be uppercase, last comma should be switched to 'and'
    errorMsg = errorMsg[0].toUpperCase() + errorMsg.substring(1);
    const lastCommaIndex = errorMsg.lastIndexOf(',');
    if (lastCommaIndex > -1) {
      errorMsg = `${errorMsg.substring(0, lastCommaIndex)} and ${errorMsg.substring(lastCommaIndex + 1)}`;
    }

    // open the modal
    setIncompleteRequestMsg(errorMsg);
    setModalIncompleteRequestActive(true);
  };

  const createNewOrderRequest = (req) => {
    return new CreateOrderRequest({
      office: getOffice(req),
      closing_information: getClosingInformation(req),
      property: getPropertyAddress(req),
      selected_product: planCoveragesValues
        ? mapPlanCoverageValuesToCreateOrderSelectedProduct(planCoveragesValues)
        : getSelectedProduct(req),
      seller: getSeller(req),
      buyer: getBuyer(req),
      mls_number: req.propertyDetails.mlsNumber,
    });
  };

  const getOffice = (req) => {
    const { agentInfo } = req;
    return {
      id: agentInfo.office?.id,
      agent: agentInfo.AgentId,
      represents: agentInfo.representing,
      cooperating_office: getCooperatingOffice(req),
    };
  };
  const getClosingInformation = (req) => {
    return !req.closingInfo
      ? null
      : {
          office_id: req.closingInfo.closingOffice?.id,
          date: req.closingInfo.projectedClosingDate
            ? formatDateToISO8601(new Date(req.closingInfo.projectedClosingDate))
            : '',
          agent: req.closingInfo.AgentId,
          office_file_number: req.closingInfo.closingFileNumber,
        };
  };

  const getPropertyAddress = (req) => {
    return {
      id: req.propertyDetails.propertyID,
      street_address: req.propertyAddr.streetAddress,
      unit: req.propertyAddr.unit,
      city: req.propertyAddr.city,
      state: req.propertyAddr.state,
      zip: req.propertyAddr.zip,
      zip_plus_4: req.propertyAddr.zipPlus4,
      type_of_residence: getResidenceType(req.propertyDetails.residenceType),
      square_footage: getSquareFootage(req.propertyDetails.squareFootage),
      guest_unit: planCoveragesValues ? planCoveragesValues.selectedGuestUnit : getGuestUnit(req),
      new_construction: !!req.propertyDetails.newConstruction,
    };
  };

  /** converts the refactored values into the existing values that is used to build the new order request */
  const mapPlanCoverageValuesToCreateOrderSelectedProduct = (
    vals: PlansCoverageValues,
  ): CreateOrderSelectedProduct => {
    return (
      vals && {
        id: vals.selectedPlanCoverages.product.starPVID,
        brand: brandLocation,
        selected_optional_coverages: vals.selectedPlanCoverages.optionalCoverages.map((cvg) => ({
          id: cvg.id,
        })),
        selected_optional_coverage_groups: vals.selectedPlanCoverages.groupCoverages.map((cvg) => ({
          id: cvg.id,
        })),
      }
    );
  };

  /** @deprecated using old logic. Should remove and switch to refactored logic. */
  const getSelectedProduct = (req) => {
    return !req.plansCoverage.productId
      ? null
      : {
          brand: brandLocation,
          id: req.plansCoverage.productId,
          selected_optional_coverages: getSelectedOptionalCoverages(req),
          selected_optional_coverage_groups: getCoverageGroups(req),
        };
  };

  const getCooperatingOffice = (req) => {
    if (req.agentInfo.representing === 'Buyer') {
      return {
        id: !req.sellerInfo.office ? '' : req.sellerInfo.office?.id,
        agent: req.sellerInfo.AgentId,
      };
    }
    if (req.agentInfo.representing === 'Seller') {
      return {
        id: !req.buyerInfo.office ? '' : req.buyerInfo.office?.id,
        agent: req.buyerInfo.AgentId,
      };
    }
  };

  /** @deprecated using old logic. Should remove and switch to refactored logic. */
  const getSelectedOptionalCoverages = (req) => {
    let optionalCoverage = req.plansCoverage.selectedOptionalCoverage;
    if (optionalCoverage && optionalCoverage.length === 0) {
      return [];
    }
    optionalCoverage = optionalCoverage
      .filter(function (item) {
        return item.coverageId !== '0';
      })
      .map(function ({ coverageId }) {
        return { id: coverageId };
      });
    return optionalCoverage;
  };

  /** @deprecated using old logic. Should remove and switch to refactored logic. */
  const getCoverageGroups = (req) => {
    let optionalCoverage = req.plansCoverage.selectedOptionalCoverage;
    if (optionalCoverage && optionalCoverage.length === 0) {
      return [];
    }
    optionalCoverage = optionalCoverage
      .filter(function (item) {
        return item.groupId && item.groupId !== '0';
      })
      .map(function ({ groupId }) {
        return { id: groupId };
      });
    return optionalCoverage;
  };

  /** @deprecated using old logic. Should remove and switch to new logic. */
  const getGuestUnit = (req) => {
    return !!(
      req.plansCoverage &&
      req.plansCoverage.guestUnitCoverage &&
      req.plansCoverage.guestUnitCoverage.length > 0 &&
      req.plansCoverage.guestUnitCoverage[0].value
    );
  };

  const getResidenceType = (residenceType) => {
    return residenceType && residenceType.charAt(0).toUpperCase() + residenceType.slice(1);
  };

  const handleAgentInfoSubmit = (data: any, shouldSetHasBeenSaved: boolean = true) => {
    handleCardSubmission({ id: CONTEXT.AGENT_INFO, ...data });

    // I don't know why this is set in one but not both. it was probably overlooked
    if (shouldSetHasBeenSaved) {
      setHasBeenSaved({ ...hasBeenSaved, agentCard: true });
    }
  };

  const handleSellerInfoSubmitBeforeBuyer = async (sellerInfo: any): Promise<boolean> => {
    const actionText = hasBeenSaved.sellerCard ? ACTION_EDIT : ACTION_SAVE;
    fireGAEvent(
      ORDER__BUYER_SELLER_CLOSING_INFO_STEP(
        actionText,
        AGENT_LABELS.seller,
        originatorName,
        orderID,
      ),
    );
    setSellerAddressUUID(sellerInfo.customerAddressUUID);
    setCoSellerAddressUUID(sellerInfo.coCustomerAddressUUID);
    handleOrderIDUpdates(
      {
        sellerInfo,
      },
      {
        sellerAddressUUID: sellerInfo.customerAddressUUID,
        coSellerAddressUUID: sellerInfo.coCustomerAddressUUID,
      },
    );

    props.setValues({ ...props.values, sellerInfo });

    if (!isARetroactivelyCollectedSellersInfoCallback) {
      sellerFirstSubmitHandler();
    } else {
      setIsARetroactivelyCollectedSellersInfoCallback(false);
    }

    setHasBeenSaved({ ...hasBeenSaved, sellerCard: true });

    return true;
  };

  const handleSellerInfoSubmitAfterBuyer = async (sellerInfo: any): Promise<boolean> => {
    const actionText = hasBeenSaved.sellerCard ? ACTION_EDIT : ACTION_SAVE;
    fireGAEvent(
      ORDER__BUYER_SELLER_CLOSING_INFO_STEP(
        actionText,
        AGENT_LABELS.seller,
        originatorName,
        orderID,
      ),
    );
    setSellerAddressUUID(sellerInfo.customerAddressUUID);
    setCoSellerAddressUUID(sellerInfo.coCustomerAddressUUID);
    handleOrderIDUpdates(
      {
        sellerInfo,
      },
      {
        sellerAddressUUID: sellerInfo.customerAddressUUID,
        coSellerAddressUUID: sellerInfo.coCustomerAddressUUID,
      },
    );

    setIsSellerEdit(false);
    props.setValues({ ...props.values, sellerInfo });

    if (!isARetroactivelyCollectedSellersInfoCallback) {
      setEditingClosingInfo(true);
    } else {
      setIsARetroactivelyCollectedSellersInfoCallback(false);
    }
    setHasBeenSaved({ ...hasBeenSaved, sellerCard: true });

    saveSubmit(6);

    return true;
  };

  const handleBuyerInfoSubmitCommon = async (buyerInfo: any): Promise<void> => {
    const actionText = hasBeenSaved.buyerCard ? ACTION_EDIT : ACTION_SAVE;
    fireGAEvent(
      ORDER__BUYER_SELLER_CLOSING_INFO_STEP(
        actionText,
        AGENT_LABELS.buyer,
        originatorName,
        orderID,
      ),
    );
    setBuyerAddressUUID(buyerInfo.customerAddressUUID);
    setCoBuyerAddressUUID(buyerInfo.coCustomerAddressUUID);

    const updatedStates: Partial<OrderRequestStateAttributes> = {
      buyerAddressUUID: buyerInfo.customerAddressUUID,
      coBuyerAddressUUID: buyerInfo.coCustomerAddressUUID,
    };

    if (shouldRefreshQuoteIDWithMilitaryDiscount(buyerInfo)) {
      fireGAEvent(MILITARY_DISCOUNT_REMOVED(OrderFlowType.NewOrder));
      updatedStates.quoteID = await refreshQuoteIDWithMilitaryDiscount(quoteID);
      handleOrderIDUpdates(
        {
          buyerInfo,
        },
        updatedStates,
      );
    } else {
      handleOrderIDUpdates(
        {
          buyerInfo,
        },
        updatedStates,
      );
    }
    setHasBeenSaved({ ...hasBeenSaved, buyerCard: true });
    setIsBuyerEdit(false); // Close buyer card

    // asynchronously call validate for buyer email/phone
    if (buyerInfo.email || buyerInfo.phone) {
      ValidateApi.validateEmailPhone({
        email: buyerInfo.email,
        phone: buyerInfo.phone,
        flow: OrderFlowType.NewOrder,
      });
    }
  };
  const shouldRefreshQuoteIDWithMilitaryDiscount = (savedBuyerInfo: any): boolean => {
    return (
      quoteID?.length > 0 &&
      !hasBuyerInfo(savedBuyerInfo) &&
      productPricingDetailsRequest?.options?.specialDiscounts?.includes(MILITARY_DISCOUNT)
    );
  };
  /**
   * Refreshes a new quote id given that there is already pricing details from existing quote.
   * After completion, would return the quote id created and to be inserted into the order
   */
  const refreshQuoteIDWithMilitaryDiscount = async (existingQuoteID: string): Promise<string> => {
    if (existingQuoteID?.length > 0) {
      //Remove the military discount when no buyer
      productPricingDetailsRequest.options.specialDiscounts = [];
      //create the quoteID and set in the state.
      const res = await createQuoteID(productPricingDetailsRequest);
      return res?.quoteID;
    }
    return null;
  };

  const handleBuyerInfoSubmitBeforeSeller = async (buyerInfo: any): Promise<boolean> => {
    handleBuyerInfoSubmitCommon(buyerInfo);
    saveSubmit(5);
    setIsSellerEdit(true);
    props.setValues({ ...props.values, buyerInfo });
    return true;
  };

  const handleBuyerInfoSubmitAfterSeller = async (buyerInfo: any): Promise<boolean> => {
    handleBuyerInfoSubmitCommon(buyerInfo);
    let updatedValues = { ...props.values, buyerInfo };

    // Skip closing info card if no buyer information
    if (!buyerInfo.firstName) {
      setEditingClosingInfo(false);
      updatedValues = {
        ...updatedValues,
        closingInfo: { ...updatedValues.closingInfo, dateUnknown: true },
      };
      saveSubmit(7);
      setIsPlansCoverageEditing(true);
    } else {
      setEditingClosingInfo(true);
      saveSubmit(6);
    }

    props.setValues(updatedValues);

    return true;
  };

  /**
   * Refreshes a new quote id  given that there is already pricing details from existing quote.
   * After completion, would return the quote id created and to be inserted into the order
   * @param closingDate the new closing date
   * @return {Promise<string>} the new quote id
   */
  const refreshQuoteIDWithNewClosingDate = async (
    updatedClosingDate: string,
    existingClosingDate: string,
    existingQuoteID: string,
  ): Promise<string> => {
    if (existingQuoteID?.length > 0 && existingClosingDate !== updatedClosingDate) {
      // calculate the number of list term days based on the closing date
      const listingTermInDays = calculateListingTermInDays(new Date(updatedClosingDate));

      // Set the new calculated listing term in days to create the new quote
      productPricingDetailsRequest.options.listingTermInDays = listingTermInDays;

      // Set the initiating office id
      productPricingDetailsRequest.options.initiatingOfficeID = agentInfo.office?.id;

      // Call the new endpoint to create the quoteID and set in the state.
      const res = await createQuoteID(productPricingDetailsRequest);
      return res?.quoteID;
    }
    return null;
  };

  const handleClosingInfoSubmit = async (closingInfo: any): Promise<boolean> => {
    const actionText = hasBeenSaved.closingCard ? ACTION_EDIT : ACTION_SAVE;
    setProjectedClosingDate(closingInfo?.projectedClosingDate);
    fireGAEvent(
      ORDER__BUYER_SELLER_CLOSING_INFO_STEP(actionText, CLOSING_LABEL, originatorName, orderID),
    );
    let updatedValues = {
      ...props.values,
      closingInfo,
    };
    setHasBeenSaved({ ...hasBeenSaved, closingCard: true });

    if (quoteID?.length > 0 && projectedClosingDate !== closingInfo?.projectedClosingDate) {
      const updatedQuoteID = await refreshQuoteIDWithNewClosingDate(
        closingInfo?.projectedClosingDate,
        projectedClosingDate,
        quoteID,
      );

      handleOrderIDUpdates(
        {
          closingInfo,
        },
        {
          quoteID: updatedQuoteID,
        },
      );
    } else {
      handleOrderIDUpdates({
        closingInfo,
      });
    }

    // if you dont have buyer information and you provide a closing date, open buyer card again and require buyer Info
    if (shouldReturnToBuyerInfo(props.values.buyerInfo, closingInfo)) {
      updatedValues = {
        ...updatedValues,
        buyerInfo: {
          ...props.values.buyerInfo,
          buyerInfo: {
            yes: true,
            no: false,
          },
        },
      };
      setIsBuyerEdit(true);
      setEditingClosingInfo(false);
    } else {
      setEditingClosingInfo(false);
      saveSubmit(7);
      setIsPlansCoverageEditing(true);
    }

    props.setValues(updatedValues);

    // turn the switch value to 'No' and disable plans if the closing date is in the past
    setPlanSelectionError(null);
    if (closingInfo.projectedClosingDate) {
      const closingDate = parseDate(closingInfo.projectedClosingDate).setHours(0, 0, 0, 0);
      const today = new Date().setHours(0, 0, 0, 0);
      if (closingDate < today) {
        // reset plams
        if (isPlanCardVisible()) {
          handlePlanCardReset(PlanCardResetReason.PastClosingDateSelected);
        }
        saveSubmit(7);
      }
    }

    return true;
  };

  const getClearedFromRealtorOwnedToggleOrderData = (
    currentChanges,
    isRealtorOwned,
    represents,
  ) => {
    if (isRealtorOwned) {
      currentChanges.buyerInfo = {
        firstName: REALTOR_OWNED_FOR_INIT_AGENT_ROLES.includes(userType)
          ? currentChanges.agentInfo.AgentName.split(' ')[0]
          : props.userDetails.firstName,
        lastName: REALTOR_OWNED_FOR_INIT_AGENT_ROLES.includes(userType)
          ? currentChanges.agentInfo.AgentName.split(' ')[1]
          : props.userDetails.lastName,
        phone: REALTOR_OWNED_FOR_INIT_AGENT_ROLES.includes(userType)
          ? currentChanges.agentInfo.AgentPhone?.phoneNumber
          : props.userDetails.phoneNumber,
        email: REALTOR_OWNED_FOR_INIT_AGENT_ROLES.includes(userType)
          ? currentChanges.agentInfo.AgentEmail
          : props.userDetails.email,
        buyerInfo: {
          yes: true,
          no: false,
        },
      };
      currentChanges.sellerInfo = { sellerInfo: { yes: false, no: true } };
      currentChanges.closingInfo = {
        projectedClosingDate: format(new Date(), 'MM/dd/yyyy'),
        dateUnknown: false,
        optionValues: { yes: false, no: true },
      };
    } else {
      currentChanges.buyerInfo = { buyerInfo: { yes: false, no: true } };
      currentChanges.closingInfo = { optionValues: { yes: false, no: true } };
      //if buyerInfo is false, military discount gets removed
      if (productPricingDetailsRequest?.options?.specialDiscounts?.includes(MILITARY_DISCOUNT)) {
        fireGAEvent(MILITARY_DISCOUNT_REMOVED(OrderFlowType.NewOrder));
      }

      if (represents.buyer) {
        currentChanges.sellerInfo = { sellerInfo: { yes: false, no: false } };
      } else {
        currentChanges.sellerInfo = { sellerInfo: { yes: true, no: false } };
      }
    }

    return currentChanges;
  };

  // TODO: ensure input is fully typed
  const handleCardSubmission = (data) => {
    persistScratchData(data);
    setNextUIState(data);
  };

  const toggleROCheckbox = (data) => {
    return data.isRealtorOwned !== props.values.agentInfo.isRealtorOwned;
  };

  const getBuyerInfoForROAdminUser = (currentChanges) => {
    currentChanges.buyerInfo = {
      firstName: currentChanges.agentInfo.AgentName.split(' ')[0],
      lastName: currentChanges.agentInfo.AgentName.split(' ')[1],
      phone: currentChanges.agentInfo.AgentPhone?.phoneNumber,
      email: currentChanges.agentInfo.AgentEmail,
      buyerInfo: {
        yes: true,
        no: false,
      },
    };
    return currentChanges;
  };

  const persistScratchData = (data) => {
    let changes = merge({}, props.values);

    if (data?.id === CONTEXT.AGENT_INFO) {
      changes.agentInfo = data;

      // When isRealtorOwned is toggled, clear the rest of the order (new behavior ARE-8595)
      if (toggleROCheckbox(data)) {
        changes = getClearedFromRealtorOwnedToggleOrderData(
          { ...changes },
          data.isRealtorOwned,
          data.agentRepresents,
        );
        setPlanCoveragesValues(null);
        isRealtorOwnedDefaultsSet.current = true;
      }
      // Prepopulate the buyerInfo for RO Admin user when agent is changed from dropdown.
      if (
        REALTOR_OWNED_FOR_INIT_AGENT_ROLES.includes(userType) &&
        data.isRealtorOwned &&
        props.values.AgentId !== data.AgentId
      ) {
        changes = getBuyerInfoForROAdminUser({ ...changes });
      }

      if (data.isRealtorOwned && props.values.propertyDetails?.newConstruction) {
        setModalNoPlansFoundActive(true);
        const noNewConstruction = {
          propertyDetails: {
            age: 10,
            newConstruction: false,
            notNewConstruction: true,
          },
        };
        changes = merge(changes, noNewConstruction);
      }

      handleOrderIDUpdates({
        agentInfo: changes.agentInfo,
      });
    }

    props.setValues(changes);
  };

  const setNextUIState = (data) => {
    if (data?.id === CONTEXT.AGENT_INFO) {
      transitionFromAgentInfo({ agentInfo: data });
    }
  };

  const handleCancelOrder = () => {
    fireGAEvent(ORDER__CANCEL(`${originatorName} | ${NEW_ORDER}`, orderID));
    handleCancelUnsubmittedOrder();
    navigate(Path.Dashboard);
  };

  /** unsubmitted orders get canceled. Orders from My Quotes do not get canceled. */
  const handleCancelUnsubmittedOrder = () => {
    if (isQuoteConvertToOrder) {
      return;
    }
    if (orderID) {
      RealEstateQuoteApi.cancelUnsubmittedOrder(
        orderID,
        UnsubmittedOrderCancellationWorkflow.NewOrder,
      );
    }
  };

  const shouldDisableSubmitButton = () => {
    return (
      !canSubmitNewOrder ||
      isSubmittingNewOrder ||
      !userIsActive ||
      !isRecipientSelected() ||
      !isSendDocumentsValid
    );
  };

  /** reset the plan card to a state that user should start with, including displaying a notification and opening card. */
  const handlePlanCardReset = (reason: PlanCardResetReason) => {
    setPlanCoveragesValues(null); // reset all selections to force user to start over
    setIsPlansCoverageEditing(true); // open the card
    saveSubmit(7); // updates the step
    setCanSubmitNewOrder(false); // disable the order submit button (if enabled)

    // display notification as to why it was reset and reopened
    setPlanSelectionError(getPlanReselectionReason(reason));
  };

  const onOpenPlanCard = () => {
    setIsPlansCoverageEditing(true);
  };

  const onClosePlanCard = () => {
    setPlanSelectionError('');
    setIsPlansCoverageEditing(false);
  };

  const onSavePlanCard = async ({ formData, pricing, pricingDetailsRequest }) => {
    const actionText = hasBeenSaved.plansCard ? ACTION_EDIT : ACTION_SELECT;
    fireGAEvent(ORDER__UPDATE_PLAN_STEP(actionText, originatorName, orderID));
    setPlanSelectionError('');
    setPlanCoveragesValues(formData);
    setPricingValues(pricing);
    setHasBeenSaved({ ...hasBeenSaved, plansCard: true });

    // Call the new endpoint to create the quoteID and set in the state.
    const res = await createQuoteID(pricingDetailsRequest);
    handleOrderIDUpdates(
      {},
      {
        quoteID: res?.quoteID,
      },
    );

    setIsPlansCoverageEditing(false);

    saveSubmit(7); // updates the active step
    setCanSubmitNewOrder(true); // enable the new order button at this point

    const hasIncompleteSellerName = handleIncompleteSellerNameCheck(
      formData?.selectedPlanCoverages?.product?.sellersCoverage,
    );

    // scroll wont work properly because plan card didnt close yet, needs to wait
    if (!hasIncompleteSellerName) {
      setTimeout(() => {
        sendDocumentsRef.current?.scrollIntoView(scrollIntoViewOptions);
      }, 0.5);
    }
  };

  const createQuoteID = (request: ProductPricingDetailsRequest) => {
    setIsCreatingQuoteID(true);
    return ProductApi.createQuote(request)
      .then((res) => {
        setQuoteID(res?.quoteID);
        setProductPricingDetailsRequest(request);
        setEarnixRuleID(request?.options?.earnixRuleID);
        return res;
      })
      .finally(() => {
        setIsCreatingQuoteID(false);
      });
  };

  /** upon saving plan card or submitting, check if seller info is necessary based on if plan has sellers coverage.
   * This logic performs the 'isARetroactivelyCollectedSellersInfoCallback' logic.
   * @return boolean - true if seller name was incomplete. False if seller name is complete. */
  const handleIncompleteSellerNameCheck = (requireSeller = hasSellersCoverage()): boolean => {
    const requireSellerName = isSellerNameRequired(
      requireSeller,
      props.values.agentInfo?.agentRepresents,
    );
    const hasSellerName = props.values.sellerInfo?.firstName;
    if (requireSellerName && !hasSellerName) {
      const update = {
        ...props.values.sellerInfo,
        sellerInfo: { yes: true, no: false },
        isSellerCoverage: true,
        isSellerFieldsDisabled: false,
      };
      props.setValues({ ...props.values, sellerInfo: update });
      setSellerInfo(update);
      setIsARetroactivelyCollectedSellersInfoCallback(true);
      setIsSellerEdit(true);
      setIsBuyerEdit(false);
      return true;
    }
    const update = {
      ...props.values.sellerInfo,
      isSellerCoverage: false,
      isSellerFieldsDisabled: false,
    };
    props.setValues({ ...props.values, sellerInfo: update });
    setSellerInfo(update);
    return false;
  };

  const onConfirmSellersCoverageRO = () => {
    const plansCovValuesCopy = { ...planCoveragesValues };
    plansCovValuesCopy.selectedPlanCoverages.product.sellersCoverage = false;
    plansCovValuesCopy.selectedFilters.includeSellersCoverage = false;
    setPlanCoveragesValues(plansCovValuesCopy);
  };

  // send only custom cooperating agent details alone. Dont sent office info
  const onPersistCustomCooporatingAgent = (customCooperatingAgent) => {
    // needs to be done synchronously. Cant wait until the completion of event handler's execution as these values are to be sent to the back end.
    customCooperatingAgentDetails.hasValue = customCooperatingAgent.hasValue;
    customCooperatingAgentDetails.agentFirstName = customCooperatingAgent.agentFirstName;
    customCooperatingAgentDetails.agentLastName = customCooperatingAgent.agentLastName;
    customCooperatingAgentDetails.agentEmail = customCooperatingAgent.agentEmail;
  };

  const updateAgentCardStatusForGATracking = () =>
    setHasBeenSaved({ ...hasBeenSaved, agentCard: true });

  return (
    <>
      <div className="container py-10">
        <div className="flex flex-row justify-between flex-col md:flex-row">
          <REText id="wb_newOrderLogo" variant="heading-03">
            {NEW_ORDER_LABELS.FORM_HEADER}
          </REText>
          <div className="max-w-4/5">
            <PromoBanner address={props.values.propertyAddr} />
          </div>
        </div>
        <Panel
          backgroundColor={IsTheme(Theme.Ahs2024) ? 'accent-500' : 'interactive-50'}
          rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'md'}
          borderColor="gray-300"
          className="mt-4"
          id="new-order-banner"
          shadow={!IsTheme(Theme.Ahs2024)}
        >
          <REText id="new-order-banner-text">
            Orders can be entered for properties closed in the past 90 days or closing in the next
            365 days
          </REText>
        </Panel>
        <div className="my-8">
          <NewOrderStepper
            isREAgentUser={userType === 'RealEstateAgent'}
            currentStep={userType === 'RealEstateAgent' ? reActiveStep : activeStep}
            representing={props?.values?.agentInfo?.representing}
          />
        </div>
        {userType !== 'RealEstateAgent' && (
          <CardNewOrderAgentInfo
            isCollapsed={!isAgentEdit}
            cancelModal={true}
            values={props.values.agentInfo}
            orderId={orderID}
            setValues={(agentInfo) => {
              props.setValues({ ...props.values, agentInfo });
            }}
            userType={userType}
            handleSubmit={(data) => handleAgentInfoSubmit(data)}
            isEditing={isAgentEdit}
            setIsEditing={setIsAgentEdit}
            usaStates={usaStates}
            userDetails={props.userDetails}
            isExistingOrder={false}
            fsboOptionEnabled={FSBO_ALLOWED_ROLES.includes(userType)}
            realtorOwnedSelectionEnabled={isRealtorOwnedSelectionEnabled}
            hasBeenSaved={hasBeenSaved.agentCard}
            updateAgentCardStatusForGATracking={updateAgentCardStatusForGATracking}
            quoteHasSellersCoverage={planCoveragesValues?.selectedFilters?.includeSellersCoverage}
            onConfirmSellersCoverageRO={() => onConfirmSellersCoverageRO()}
            flow={OrderFlowType.NewOrder}
          />
        )}
        {((userType !== 'RealEstateAgent' && activeStep >= 2) ||
          userType === 'RealEstateAgent') && (
          <CardNewOrderPropertyAddress
            isCollapsed={!isAddressEdit}
            cancelModal={true}
            values={props.values.propertyAddr}
            setValues={(update) => {
              props.setValues({ ...props.values, ...update });
              propertyUpdateHandler(update.propertyAddr, props.values.propertyDetails);
            }}
            handleSubmit={(event) => {
              propertyAddressSubmitHandler(event);
            }}
            usaStates={usaStates}
            isEditing={isAddressEdit}
            setIsEditing={setIsAddressEdit}
            searchCityStateByZip={searchCityStateByZip}
            user={user}
            agentInfo={agentInfo}
            skipAvailability={isQuoteConvertToOrder}
            checkAbandonedOrders={true}
            continuePreviousOrderID={loadExistingOrderFromPropertyAddress}
          />
        )}
        {((userType !== 'RealEstateAgent' && activeStep >= 3) ||
          (userType === 'RealEstateAgent' && reActiveStep >= 2)) && (
          <CardNewOrderPropertyDetails
            isCollapsed={!isPropertyEdit}
            cancelModal={true}
            orderId={orderID}
            isRealtorOwned={props.values.agentInfo && props.values.agentInfo.isRealtorOwned}
            values={props.values.propertyDetails}
            setValues={(update) => {
              props.setValues({ ...props.values, ...update });
              propertyUpdateHandler(props.values.propertyAddr, update.propertyDetails);
            }}
            handleSubmit={(e) => {
              propertyDetailsSubmitHandler(e);
            }}
            propertyAddr={props.values.propertyAddr}
            isEditing={isPropertyEdit}
            setIsEditing={setIsPropertyEdit}
            usaStates={usaStates}
            originator={originatorName}
          />
        )}

        {userType === 'RealEstateAgent' && reActiveStep >= 3 && (
          <CardNewOrderAgentInfo
            isCollapsed={!isAgentEdit}
            cancelModal={true}
            values={props.values.agentInfo}
            setValues={(agentInfo) => {
              props.setValues({ ...props.values, agentInfo });
            }}
            handleSubmit={(data) => handleAgentInfoSubmit(data, false)}
            isEditing={isAgentEdit}
            setIsEditing={setIsAgentEdit}
            usaStates={usaStates}
            userType={userType}
            userDetails={props.userDetails}
            isExistingOrder={false}
            fsboOptionEnabled={FSBO_ALLOWED_ROLES.includes(userType)}
            realtorOwnedSelectionEnabled={isRealtorOwnedSelectionEnabled}
            hasBeenSaved={hasBeenSaved.agentCard}
            updateAgentCardStatusForGATracking={updateAgentCardStatusForGATracking}
            quoteHasSellersCoverage={planCoveragesValues?.selectedFilters?.includeSellersCoverage}
            onConfirmSellersCoverageRO={() => onConfirmSellersCoverageRO()}
          />
        )}

        {isSellerCardBeforeBuyerCard() ? (
          <>
            {isSellerCardVisible() && (
              <div ref={sellerCardRef}>
                <CardNewOrderSellerInfo
                  alertText={
                    (isARetroactivelyCollectedSellersInfoCallback ||
                      showSellerCoverageDefaultSelectionMessage) &&
                    isSellerEdit
                      ? msgs.NEW_ORDER_FLOW_SELLER_INFO_REQUIRED.message
                      : ''
                  }
                  isCustomerNameRequired={isSellerNameRequired(
                    hasSellersCoverage(),
                    props.values.agentInfo?.agentRepresents,
                  )}
                  isCustomerContactInfoRequired={isSellerContactInfoRequired(
                    props.values.agentInfo?.agentRepresents,
                  )}
                  showCooperatingFields={
                    !(
                      props.values.agentInfo?.agentRepresents.seller ||
                      props.values.agentInfo?.agentRepresents.both
                    )
                  }
                  isCollapsed={!isSellerEdit}
                  cancelModal={true}
                  values={sellerInfo} // running set of saved values as we hold onto the values until the end of our new order flow for submission
                  setValues={(update) => props.setValues({ ...props.values, ...update })}
                  handleSubmit={handleSellerInfoSubmitBeforeBuyer}
                  usaStates={usaStates}
                  searchCityStateByZip={searchCityStateByZip}
                  isEditing={isSellerEdit}
                  setIsEditing={setIsSellerEdit}
                  isRequired={
                    props.values.agentInfo.agentRepresents.seller ||
                    props.values.agentInfo.agentRepresents.both
                  }
                  userDetails={props.userDetails}
                  initiatingAgentId={props.values.agentInfo.AgentId}
                  originator={originatorName}
                  hasSellersCoverage={
                    planCoveragesValues?.selectedPlanCoverages?.product?.sellersCoverage
                  }
                  disableUnverifiedAddressCreation={false}
                  agentInfo={props.values.agentInfo}
                  flow="New Order"
                  onPersistCustomCooporatingAgent={onPersistCustomCooporatingAgent}
                />
              </div>
            )}
            {isBuyerCardVisible() && (
              <div ref={buyerCardRef}>
                <CardNewOrderBuyerInfo
                  isEditing={isBuyerEdit}
                  setIsEditing={setIsBuyerEdit}
                  cancelModal={true}
                  values={{
                    ...props.values.buyerInfo,
                    needEmailOrPhone:
                      props.values.agentInfo.agentRepresents?.buyer ||
                      props.values.agentInfo.agentRepresents?.both,
                  }}
                  showCooperatingFields={props.values.agentInfo.agentRepresents.seller}
                  isRequired={isBuyerInfoRequired(props.values.agentInfo, props.values.closingInfo)}
                  isCustomerContactInfoRequired={isBuyerContactInfoRequired(
                    props.values.agentInfo?.agentRepresents,
                  )}
                  handleSubmit={handleBuyerInfoSubmitAfterSeller}
                  usaStates={usaStates}
                  searchCityStateByZip={searchCityStateByZip}
                  userDetails={props.userDetails}
                  initiatingAgentId={props.values.agentInfo.AgentId}
                  originator={originatorName}
                  disableUnverifiedAddressCreation={false}
                  agentInfo={props.values.agentInfo}
                  flow="New Order"
                  onPersistCustomCooporatingAgent={onPersistCustomCooporatingAgent}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {isBuyerCardVisible() && (
              <div ref={buyerCardRef}>
                <CardNewOrderBuyerInfo
                  isEditing={isBuyerEdit}
                  setIsEditing={setIsBuyerEdit}
                  cancelModal={true}
                  values={{
                    ...props.values.buyerInfo,
                    needEmailOrPhone:
                      props.values.agentInfo.agentRepresents?.buyer ||
                      props.values.agentInfo.agentRepresents?.both,
                  }}
                  showCooperatingFields={props.values.agentInfo.agentRepresents.seller}
                  isRequired={isBuyerInfoRequired(props.values.agentInfo, props.values.closingInfo)}
                  isCustomerContactInfoRequired={isBuyerContactInfoRequired(
                    props.values.agentInfo?.agentRepresents,
                  )}
                  usaStates={usaStates}
                  searchCityStateByZip={searchCityStateByZip}
                  handleSubmit={handleBuyerInfoSubmitBeforeSeller}
                  userDetails={props.userDetails}
                  initiatingAgentId={props.values.agentInfo.AgentId}
                  disableUnverifiedAddressCreation={false}
                  agentInfo={props.values.agentInfo}
                  flow="New Order"
                  onPersistCustomCooporatingAgent={onPersistCustomCooporatingAgent}
                />
              </div>
            )}
            {isSellerCardVisible() && (
              <div ref={sellerCardRef}>
                <CardNewOrderSellerInfo
                  alertText={
                    (isARetroactivelyCollectedSellersInfoCallback ||
                      showSellerCoverageDefaultSelectionMessage) &&
                    isSellerEdit
                      ? msgs.NEW_ORDER_FLOW_SELLER_INFO_REQUIRED.message
                      : ''
                  }
                  isCustomerNameRequired={isSellerNameRequired(
                    hasSellersCoverage(),
                    props.values.agentInfo?.agentRepresents,
                  )}
                  isCustomerContactInfoRequired={isSellerContactInfoRequired(
                    props.values.agentInfo?.agentRepresents,
                  )}
                  showCooperatingFields={
                    !(
                      props.values.agentInfo?.agentRepresents.seller ||
                      props.values.agentInfo?.agentRepresents.both
                    )
                  }
                  isCollapsed={!isSellerEdit}
                  cancelModal={true}
                  values={sellerInfo} // running set of saved values as we hold onto the values until the end of our new order flow for submission
                  setValues={(update) => props.setValues({ ...props.values, ...update })}
                  handleSubmit={handleSellerInfoSubmitAfterBuyer}
                  usaStates={usaStates}
                  searchCityStateByZip={searchCityStateByZip}
                  isEditing={isSellerEdit}
                  setIsEditing={setIsSellerEdit}
                  isRequired={
                    props.values.agentInfo.agentRepresents.seller ||
                    props.values.agentInfo.agentRepresents.both
                  }
                  userDetails={props.userDetails}
                  initiatingAgentId={props.values.agentInfo.AgentId}
                  hasSellersCoverage={
                    planCoveragesValues?.selectedPlanCoverages?.product?.sellersCoverage
                  }
                  disableUnverifiedAddressCreation={false}
                  agentInfo={props.values.agentInfo}
                  flow="New Order"
                  onPersistCustomCooporatingAgent={onPersistCustomCooporatingAgent}
                />
              </div>
            )}
          </>
        )}

        {isClosingCardVisible() && (
          <div ref={closingCardRef}>
            <CardNewOrderClosingInfo
              submitHelperText={cardSubmittingHelperText}
              isCollapsed={true}
              cancelModal={true}
              values={props.values.closingInfo}
              agentRepresent={isClosingInfoRequired(props.values.agentInfo, props.values.buyerInfo)}
              showUnknownDate={
                !isClosingInfoRequired(props.values.agentInfo, props.values.buyerInfo)
              }
              handleSubmit={handleClosingInfoSubmit}
              isEditing={editingClosingInfo}
              setIsEditing={setEditingClosingInfo}
              usaStates={usaStates}
              userType={userType}
              userDetails={props.userDetails}
              shouldDisplayBanner={true}
            />
          </div>
        )}
        {isPlanCardVisible() && (
          <>
            <CardNewOrderPlansCoverage
              submitHelperText={cardSubmittingHelperText}
              isSmall={false}
              defaultCollapsed={true}
              isEditing={isPlansCoverageEditing}
              initiatingOfficeId={props.values.agentInfo?.office?.id}
              initiatingOfficeFranchiseCode={getFranchiseCodeFromOffice(
                props.values.agentInfo?.agentOffice,
              )}
              propertyAddress={{
                address1: props.values.propertyAddr?.streetAddress,
                address2: props.values.propertyAddr?.unit,
                city: props.values.propertyAddr?.city,
                state: props.values.propertyAddr?.state,
                zip: props.values.propertyAddr?.zip,
                zipPlus4: props.values.propertyAddr?.zipPlus4,
              }}
              propertyDetails={{
                residenceType: getResidenceType(props.values.propertyDetails?.residenceType),
                age: getAgeByNewConstruction(props.values.propertyDetails?.newConstruction),
                squareFootage: getSquareFootageFromOptionName(
                  props.values.propertyDetails?.squareFootage,
                ),
              }}
              listingEffectiveDate={productListingEffectiveDate}
              listingExpirationDate={
                props.values.closingInfo?.projectedClosingDate &&
                new Date(props.values.closingInfo.projectedClosingDate)
              } // the closing date
              formData={planCoveragesValues}
              roProductsOnly={props.values.agentInfo.isRealtorOwned}
              pricing={null} // set to null so card uses its own pricing
              onOpen={onOpenPlanCard}
              onClose={onClosePlanCard}
              onSave={onSavePlanCard}
              alertText={planSelectionError}
              contractListTimestamp={productListingEffectiveDate}
              earnixRuleID={earnixRuleID}
              enableQuantitySelection={true}
              allowMilitaryDiscount={hasBuyerInfo(props.values?.buyerInfo)}
            />
            <div ref={sendDocumentsRef}>
              {canSubmitNewOrder ? (
                <div className="mt-4">
                  <Panel
                    padding={IsTheme(Theme.Ahs2024) ? 'xl' : 'xl'}
                    rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'md'}
                    backgroundColor="white"
                    borderT={IsTheme(Theme.Ahs2024) ? '0' : '2'}
                    borderColor="gray-100"
                    shadow={IsTheme(Theme.Ahs2024) ? false : true}
                  >
                    <SendDocuments
                      recipients={getRowDefaults(getLatestRecipients())}
                      allowedTypes={getAllowedTypes()}
                      onChange={setRecipients}
                      onUpdateFormValidState={(isValid) => setIsSendDocumentsValid(isValid)}
                      newOrderClosingDate={projectedClosingDate}
                    />
                  </Panel>
                </div>
              ) : null}
            </div>
          </>
        )}

        <div className="flex sm-max:flex-col align-center md:flex-wrap md:justify-end mt-12 md:mt-12">
          <div className="order-2 md:mr-4 sm-max:mb-4">
            <Popover
              triggerInteraction="hover"
              placement="bottom"
              content={(popoverContentProps) => (
                <PopoverContent {...popoverContentProps}>
                  <REText>{NEW_ORDER_LABELS.FORM_CANCEL_BTN__TOOLTIP}</REText>
                </PopoverContent>
              )}
              disabled={shouldDisableSubmitButton()}
            >
              <Button
                id="new-order-template__submit-order-btn"
                label={
                  props.values.agentInfo.isRealtorOwned
                    ? NEW_ORDER_LABELS.FORM_SUBMIT_BTN_RO_LATER
                    : NEW_ORDER_LABELS.FORM_SUBMIT_BTN
                }
                width="full"
                labelAlign="center"
                variant={IsTheme(Theme.Ahs2024) ? 'outlined' : undefined}
                disabled={shouldDisableSubmitButton()}
                onClick={() => orderSubmitHandler(false)}
              />
            </Popover>
          </div>

          {props.values.agentInfo.isRealtorOwned && (
            <div className="order-1 md:mr-4 md:order-3 sm-max:mb-4">
              <Button
                id="new-order-template__submit-order-btn-ro-now"
                label={
                  props.values.agentInfo.isRealtorOwned
                    ? NEW_ORDER_LABELS.FORM_SUBMIT_BTN_RO_NOW
                    : NEW_ORDER_LABELS.FORM_SUBMIT_BTN
                }
                width="full"
                variant={IsTheme(Theme.Ahs2024) ? 'outlined' : undefined}
                labelAlign="center"
                disabled={shouldDisableSubmitButton()}
                onClick={() => orderSubmitHandler(true)}
              />
            </div>
          )}

          <div className="order-3 md:order-1 md:mr-4">
            <Button
              id="new-order-template__cancel-order-link"
              variant="ghost"
              label={NEW_ORDER_LABELS.FORM_CANCEL_BTN}
              width="full"
              labelAlign="center"
              onClick={() => setModalCancelOrderActive(true)}
            />
          </div>
        </div>
      </div>

      <ModalCancelOrder
        id="new-order-template__modal-cancel-order"
        isActive={modalCancelOrderActive}
        onClose={() => setModalCancelOrderActive(false)}
        onConfirm={handleCancelOrder}
      />

      <ModalInvalidClosingDate
        isActive={modalInvalidClosingDateActive}
        existingClosingDate={invalidClosingDateValue}
        onSubmitClosingDateUpdate={onSubmitClosingDateUpdate}
      />

      <ModalNewOrderAccountFailure
        isActive={warnCannotSubmitOrder}
        onClose={() => setWarnCannotSubmitOrder(false)}
      />

      <ModalNewOrderIncompleteRequest
        id="new-order__model-incomplete-request"
        isActive={modalIncompleteRequestActive}
        onClose={() => {
          setModalIncompleteRequestActive(false);
          setIncompleteRequestMsg(null);
        }}
        buttonId="new-order__modal-incomplete-confirm"
        message={incompleteRequestMsg}
      />

      <ModalNoPlansFound
        isActive={modalNoPlansFoundActive}
        onClose={() => setModalNoPlansFoundActive(false)}
      />
    </>
  );
};

interface NewOrderStepperProps {
  isREAgentUser: boolean;
  currentStep: number;
  representing: string;
}

const NewOrderStepper: React.FC<NewOrderStepperProps> = (props) => {
  const [stepLabels, setStepLabels] = useState<string[]>([]);

  useEffect(() => {
    const steps = getNewOrderStepLabelOrdered(props.isREAgentUser, props.representing);
    setStepLabels(steps);
  }, [props.isREAgentUser, props.currentStep, props.representing]);

  const getStepStatus = (currentStep: number, stepIndex: number): WorkflowStepStatus => {
    const step = stepIndex + 1;
    if (step === currentStep) {
      return 'in-progress';
    }
    if (step > currentStep) {
      return 'not-started';
    }
    return 'completed';
  };

  return (
    <WorkflowIndicator>
      {stepLabels.map((step, idx) => (
        <WorkflowStep key={step} status={getStepStatus(props.currentStep, idx)}>
          {step}
        </WorkflowStep>
      ))}
    </WorkflowIndicator>
  );
};

export default NewOrderTemplate;
