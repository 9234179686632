import React from 'react';
import { toFormattedNumber } from '@helpers/utils';
import REText from '@components/wrappedBDS/REText';

export interface DashboardActionHeaderProps {
  id: string;
  heading: string;
  /** set to undefined/null if no number is to be displayed. Otherwise, '0' is treated as a number to display. */
  totalContractsCount: number;
}

const DashboardActionHeader: React.FC<DashboardActionHeaderProps> = (props) => {
  return (
    <REText id={props.id} variant="heading-03">
      {props.heading}
      {props.totalContractsCount !== null && <> ({toFormattedNumber(props.totalContractsCount)})</>}
    </REText>
  );
};

DashboardActionHeader.defaultProps = {
  totalContractsCount: null,
};

export default DashboardActionHeader;
