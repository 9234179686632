import REText from '@components/wrappedBDS/REText';
import { Panel } from '@ftdr/blueprint-components-react';
import React from 'react';

const NotOfficesFound = () => {
  return (
    <Panel
      className="w-full flex justify-center"
      shadow
      border="2"
      borderColor="gray-300"
      padding="xl"
    >
      <REText className="my-4" variant="heading-06" id="offices__Not-Found">
        No Offices Currently Exist
      </REText>
    </Panel>
  );
};

export default NotOfficesFound;
