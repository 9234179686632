import React, { useContext, useEffect, useRef, useState } from 'react';
import { useEvent } from 'react-use';
import { isMobileView } from '@utils';
import { useNavigate } from "react-router-dom";
import ContractApi from '@apis/contract.api';
import DrawerOrderCombined from '@components/drawer/DrawerOrderCombined';
import { orderStatusDictionary, Permissions, UserRoleType } from '@constants/dictionaries';
import {
  Ahs2024LogoColor,
  Ahs2024LogomarkColor,
  AhsLogoColor,
  AhsLogomarkColor,
  GlobalHeader,
  HsaLogoColor,
  IconSearch,
  IconUiV1Close,
  Input,
} from '@ftdr/blueprint-components-react';
import { getBrand } from '@helpers/brand.utils';
import { Brand } from '@constants/brands';
import Path from '@constants/paths';
import {
  getWLKBrokerOffices,
  hasWarrantyLinkOffice,
} from '@services/helpers/profile.offices.helper';
import ProfileContext from '@context/ProfileContext';
import { hasAdminPermissions } from '@helpers/permissions.utils';
import { hardLogout } from '@helpers/auth.utils';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  NAVIGATION__TOP_LINK_CLICK,
  ORDER__START_ORDER,
  PLAN_PRICE__NAVIGATION,
} from '@constants/ga-events.constants';
import { Features, IsFeatureEnabled, IsTheme, Theme } from '@app/core/featureToggle';
import { GlobalHeaderUserMenuProps } from '@ftdr/blueprint-components-react/lib/types/components/global-header/global-header-user-menu';
import { GlobalHeaderButtonProps } from '@ftdr/blueprint-components-react/lib/types/components/global-header/global-header';
import BannerNotification from '@components/notification/BannerNotification';
import { Canceler } from 'axios';
import SearchInputFormAdapter, {
  PropertyInfoType,
} from '@components/search/SearchInputFormAdapter';
import { addressToString } from '@services/helpers';
import { useDebouncedCallback } from 'use-debounce';

const allowedOfficeRoles = [UserRoleType.SiteAdmin];
const allowedWLKRoles = [UserRoleType.SiteAdmin];

const GLOBAL_SEARCH_LIMIT = 15;
const SEARCH_DEBOUNCE_OFFSET = 200; // ms
const MINIMUM_QUERY_CHARS = 2; // 0-based, minimum is 3

let searchCanceler: Canceler;

// TODO Componentize DrawerOrder and its dependency Drawers
// TODO Consider Class Component to cleanup structure
const Header = (props) => {
  /** sets the color used in the nav menu. Individual CTAs and such can set their own color to override the default. */
  const DEFAULT_NAV_MENU_COLOR: 'primary' | 'interactive' = IsTheme(Theme.Ahs2024)
    ? 'interactive'
    : 'primary';

  const { profile } = useContext(ProfileContext);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isSearchSelectionDisabled, setIsSearchSelectionDisabled] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isOrderDrawerActive, setIsOrderDrawerActive] = useState(false);
  const [selectedContractID, setSelectedContractID] = useState(null);

  const brand = getBrand();
  const [menuItems, setMenuItems] = useState({ menuItems: [] });
  const [ctaItems, setCtaItems] = useState([]);
  const isDesktop = !isMobileView();

  const [userMenuItems, setUserMenuItems] = useState<GlobalHeaderUserMenuProps>({
    firstName: '',
    lastName: '',
    menuItems: [],
    isDesktop,
  });
  const debouncedSearch = useDebouncedCallback((query: string) => {
    searchCanceler?.();
    const [search, currentSearchCanceler] = ContractApi.searchGlobalContracts({
      query,
      limit: GLOBAL_SEARCH_LIMIT,
      initiatingOfficeAddress: true,
    });
    searchCanceler = currentSearchCanceler;

    search().then((res) => {
      setSearchResults([]);
      const tempSearchResults: PropertyInfoType[] = [];
      if (res?.orders) {
        res?.orders.forEach((order) => {
          const { address } = order;
          const hit: PropertyInfoType = {
            id: order.id.toString(),
            streetAddress: addressToString(
              address.address1,
              address.address2,
              address.city,
              address.state,
              address.zip,
            ),
            initiatingAgent: order.initiatingAgentName,
            initiatingOffice: order.initiatingOfficeName,
            officeAddress:
              order.initiatingOfficeAddress &&
              addressToString(
                order.initiatingOfficeAddress.address1,
                order.initiatingOfficeAddress.address2,
                order.initiatingOfficeAddress.city,
                order.initiatingOfficeAddress.state,
                order.initiatingOfficeAddress.zip,
              ),
            status: orderStatusDictionary[order.realEstateStatus],
          };
          tempSearchResults.push(hit);
        });
      }

      setSearchResults(tempSearchResults);
      setIsSearchSelectionDisabled(false);
      setShowAutocomplete(true);
    });
  }, SEARCH_DEBOUNCE_OFFSET);

  /**
   * This is a master object for all of the various nav items
   * They are slotted into the correct places and order via the init
   * functions below.
   */
  const NavItems = {
    Dashboard: {
      id: 'nav-dashboard',
      label: 'Dashboard',
      active: props.slug === 'dashboard',
      onClick: () => {
        navigate(Path.Dashboard);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('Dashboard', 'Dashboard'));
      },
    },

    MyOrders: {
      id: 'nav-myorders',
      label: 'My Orders',
      active: props.slug === 'orders',
      onClick: () => {
        navigate(Path.MyOrders);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('My Orders', 'My Orders'));
      },
    },

    MyQuotes: {
      id: 'nav-myquotes',
      label: 'My Quotes',
      active: props.slug === 'quotes',
      onClick: () => {
        navigate(Path.MyQuote);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('My Quotes', 'My Quotes'));
      },
    },

    Offices: {
      id: 'nav-offices',
      label: 'Offices',
      active: props.slug === 'offices',
      onClick: () => {
        navigate(Path.MyOffices);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('Offices', 'Offices'));
      },
    },

    WarrantyLinkMenu: {
      id: 'nav-warrantylink',
      label: 'WarrantyLink',
      active: props.slug === 'warrantyLink',
      subMenuItems: [],
      onClick: null,
    },

    AccountDetails: {
      id: 'nav-my-account',
      label: 'Account Details',
      onClick: () => {
        navigate(Path.Account);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('Account Details', 'AccountDetails'));
      },
    },

    SiteAdmin: {
      id: 'nav-site-admin',
      label: 'Site Admin',
      onClick: () => {
        navigate(Path.SiteAdmin);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('Site Admin', 'Site Admin'));
      },
    },

    SignOut: {
      id: 'nav-sign-out',
      label: 'Sign Out',
      onClick: () => {
        hardLogout();
      },
    },

    PlansAndPrices: {
      label: 'Plans & Prices',
      id: 'nav-plans-and-prices',
      active: props.slug === 'plans-and-prices',
      onClick: () => {
        navigate(Path.PlansAndPrices);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('Plans & Prices', 'Plans & Prices'));
      },
    },

    Faq: {
      label: 'FAQs',
      id: 'nav-faq',
      active: props.slug === 'faq',
      onClick: () => {
        navigate(Path.FAQ);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('FAQs', 'FAQs'));
      },
    },

    SalesMaterial: {
      label: 'Sales Material',
      id: 'nav-sales-material',
      active: props.slug === 'sales-material',
      onClick: () => {
        navigate(Path.SalesMaterial);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('Sales Material', 'Sales Material'));
      },
    },

    WarrantyLink: {
      id: 'nav-warrantyLink-submenu',
      label: 'WarrantyLink',
      onClick: () => {
        navigate(Path.Warrantylink);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('WarrantyLink', 'WarrantyLink'));
      },
    },

    WarrantyLinkPayments: {
      id: 'nav-payments-submenu',
      label: 'Payments',
      onClick: () => {
        navigate(Path.WarrantylinkPayments);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('WarrantyLink', 'Payments'));
      },
    },

    WarrantyLinkAgreements: {
      id: 'nav-agreements-submenu',
      label: 'Program Agreements',
      onClick: () => {
        navigate(Path.WarrantylinkAgreements);
        fireGAEvent(NAVIGATION__TOP_LINK_CLICK('WarrantyLink', 'Agreements'));
      },
    },
  };

  const CTAButtons: { [name: string]: GlobalHeaderButtonProps } = {
    Search: {
      id: 'nav-search',
      label: '',
      startIcon: <IconSearch />,
      variant: 'ghost',
      placementOnMobile: 'main-header',
      onClick: () => {
        setIsSearchActive(true);
      },
    },

    NewOrder: {
      label: 'New Order',
      id: 'nav-create-order',
      variant: 'filled',
      shape: IsTheme(Theme.Ahs2024) ? 'pill' : 'rounded',
      placementOnMobile: 'main-header',
      onClick: () => {
        navigate(Path.NewOrder);
        fireGAEvent(ORDER__START_ORDER);
      },
    },

    SeePrices: {
      label: 'See Prices',
      id: 'nav-see-prices',
      variant: 'outlined',
      shape: IsTheme(Theme.Ahs2024) ? 'pill' : 'rounded',
      placementOnMobile: 'mobile-menu',
      color: 'interactive',
      onClick: () => {
        navigate(Path.PlansAndPrices);
        fireGAEvent(PLAN_PRICE__NAVIGATION);
      },
    },

    // TODO: ARE-10336 -- remove the button entirely
    NewOrderRedesign: {
      label: 'Redesign',
      id: 'nav-create-order-redesign',
      variant: 'filled',
      className: 'rounded',
      placementOnMobile: 'main-header',
      color: 'accent',
      size: 'small',
      onClick: () => {
        navigate(Path.NewOrderStep1);
      },
    },
  };

  useEffect(() => {
    initMenuItems();
    initUserMenuItems();
    initCTA();
  }, []);

  /**
   * The init classes below applies to logic to take items from the above NavItems
   * and put them into the various arrays at the correct locations.
   */
  const initMenuItems = () => {
    const hasWLKOffices = getWLKBrokerOffices(profile).length > 0; // We do not display payment or agreement if no user extension offices

    const items = [];

    items.push(NavItems.Dashboard);
    // my quotes is only for AHS brand
    if (brand === Brand.AHS && IsFeatureEnabled(Features.MyQuotes)) {
      items.push(NavItems.MyQuotes);
    }
    items.push(NavItems.MyOrders);

    if (props.isLoggedIn && allowedOfficeRoles.includes(UserRoleType[profile.roleIDType])) {
      items.push(NavItems.Offices);
    }
    if (
      props.isLoggedIn &&
      (hasWarrantyLinkOffice(profile) || allowedWLKRoles.includes(UserRoleType[profile.roleIDType]))
    ) {
      NavItems.WarrantyLinkMenu.subMenuItems = [NavItems.WarrantyLink];
      if (hasWLKOffices || allowedWLKRoles.includes(UserRoleType[profile.roleIDType])) {
        NavItems.WarrantyLinkMenu.subMenuItems = [
          ...NavItems.WarrantyLinkMenu.subMenuItems,
          NavItems.WarrantyLinkAgreements,
          NavItems.WarrantyLinkPayments,
        ];
      }

      items.push(NavItems.WarrantyLinkMenu);
    }

    items.push(NavItems.SalesMaterial);

    if (brand === Brand.AHS) {
      items.push(NavItems.PlansAndPrices);
    }

    items.push(NavItems.Faq);
    setMenuItems({ menuItems: items });
  };

  const initUserMenuItems = async () => {
    const items = [NavItems.AccountDetails];
    const isAdmin = await hasAdminPermissions([Permissions.UserMaintenance]);
    if (isAdmin) {
      items.push(NavItems.SiteAdmin);
    }
    items.push(NavItems.SignOut);
    setUserMenuItems({
      firstName: profile.firstName,
      lastName: profile.lastName,
      menuItems: items,
      isDesktop,
    });
  };

  const initCTA = () => {
    // TODO: ARE-10336 should also remove the button usage entirely.
    if (IsFeatureEnabled(Features.NewOrderRedesignDevButton)) {
      setCtaItems([CTAButtons.NewOrder, CTAButtons.NewOrderRedesign, CTAButtons.Search]);
      return;
    }
    const HeaderCTA =
      brand == Brand.AHS
        ? [CTAButtons.NewOrder, CTAButtons.SeePrices, CTAButtons.Search]
        : [CTAButtons.NewOrder, CTAButtons.Search];
    setCtaItems(HeaderCTA);
  };

  const handleInputChange = async (e) => {
    let { value } = e.target;
    setInputValue(value);
    value = value.trim();
    if (value?.length > MINIMUM_QUERY_CHARS) {
      setIsSearchSelectionDisabled(true);
      setShowAutocomplete(true);
      debouncedSearch(value);
    }
  };

  const onCloseSearch = () => {
    setIsSearchActive(false);
    setSearchResults([]);
    setShowAutocomplete(false);
    setInputValue('');
  };

  const searchInput = useRef(null);
  useEffect(() => {
    if (isSearchActive) searchInput.current.focus();
  }, [isSearchActive]);

  useEvent('keydown', (e) => {
    // Activate search
    if (String(e.key) === '/') {
      const { activeElement } = document;
      const inputs = ['input', 'select', 'textarea'];

      if (activeElement && !inputs.includes(activeElement.tagName.toLowerCase())) {
        e.preventDefault();
        setIsSearchActive(true);
        searchInput.current.focus();
        return false;
      }
    }

    if (String(e.key) === 'Escape') {
      (document.activeElement as HTMLElement).blur(); // Unfocus input
      setInputValue(''); // Clear value
      setShowAutocomplete(false); // Hide autocomplete
    }
  });

  const navigate = useNavigate();

  async function handleClickSearchItem(contractID: string) {
    setSelectedContractID(contractID);
    setIsOrderDrawerActive(true);
    onCloseSearch();
  }

  const getHeaderLogo = (variant, isDesktop) => {
    if (IsTheme(Theme.Ahs2024)) {
      return isDesktop ? <Ahs2024LogoColor height={50} /> : <Ahs2024LogomarkColor height={50} />;
    }

    if (brand === 'AHS') {
      return isDesktop ? <AhsLogoColor height={50} /> : <AhsLogomarkColor height={50} />;
    }
    if (brand === 'HSA') {
      return isDesktop ? <HsaLogoColor height={50} /> : null;
    }
  };

  return (
    <div className="sticky top-0 z-40">
      <BannerNotification />
      <GlobalHeader
        menuColor={DEFAULT_NAV_MENU_COLOR}
        logoRenderer={(variant, isDesktop) => (
          <div className="cursor-pointer" onClick={() => navigate(Path.Dashboard)}>
            {getHeaderLogo(variant, isDesktop)}
          </div>
        )}
        navMenuItems={menuItems.menuItems}
        userMenuProps={{
          color: DEFAULT_NAV_MENU_COLOR,
          ...userMenuItems,
        }}
        ctaButtons={ctaItems.map((i) => ({ color: DEFAULT_NAV_MENU_COLOR, ...i }))}
        isUserSignedIn={true}
        onNavMenuItemActivate={(id) => {}}
      />

      {isSearchActive && (
        <header className="relative w-full bg-white shadow-header">
          <div className="container">
            <SearchInputFormAdapter
              inputForm={
                <div className="flex items-center sm-max:-mt-px md-only:my-px py-3 lg:py-4">
                  <div className="flex-1 md:mt-px lg:py-px">
                    <Input
                      id="header__search-input"
                      size="small"
                      inputRef={searchInput}
                      type="text"
                      name="search"
                      label=""
                      placeholder="Search by plan # or full address (street address, city, state, zip)"
                      className="p-0"
                      inputClassName="py-2 border-none text-base"
                      startEnhancer={<IconSearch />}
                      value={inputValue}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  </div>
                  <button id="header__search-close-btn" onClick={onCloseSearch}>
                    <IconUiV1Close
                      size={24}
                      color="gray"
                      className="select-none hover:text-gray-500"
                    />
                  </button>
                </div>
              }
              hits={searchResults}
              query={inputValue}
              onHitClick={handleClickSearchItem}
            />
          </div>
        </header>
      )}

      {props.isLoggedIn && (
        <DrawerOrderCombined
          isOrderDrawerActive={isOrderDrawerActive}
          contractID={selectedContractID}
          onClose={() => setIsOrderDrawerActive(false)}
        />
      )}
    </div>
  );
};

export default Header;
