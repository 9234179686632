import React from 'react';
import { Popover, PopoverContent } from '@ftdr/blueprint-components-react';
import Skeleton from 'react-loading-skeleton';
import { PLANS_AND_PRICES_LABELS } from '@templates/misc/PlansAndPricesTemplate';
import { ZestyWYSIWYGField } from '@apis/models/zesty.api.model';
import { classNames } from '@utils';
import REText from '@components/wrappedBDS/REText';

interface Props {
  containerClassName?: string;
  loadingServiceFee: boolean;
  loadingText: boolean;
  serviceFee: number;
  serviceFeeDescription: ZestyWYSIWYGField;
  serviceFeeTooltip: ZestyWYSIWYGField;
}

const PlansPriceTradeServiceFeeFooter: React.FC<Props> = (props) => {
  return (
    <div
      id="result-container--footer--fee"
      className={classNames(['space-x-2', props.containerClassName])}
    >
      <REText className="inline font-bold">
        $
        <span id="result-container--footer--fee--price">
          {props.loadingServiceFee ? (
            <>
              {' '}
              <Skeleton width={28} />
            </>
          ) : (
            <>{props.serviceFee || PLANS_AND_PRICES_LABELS.EMPTY_TRADE_SERVICE_FEE}</>
          )}
        </span>{' '}
        {PLANS_AND_PRICES_LABELS.SERVICE_FEE}:
      </REText>
      {props.loadingText ? (
        <></>
      ) : (
        <REText
          className="zesty-html inline"
          dangerouslySetInnerHTML={{ __html: props.serviceFeeDescription }}
        />
      )}
      {props.serviceFeeTooltip && (
        <>
          <Popover
            className="inline"
            placement="top"
            triggerInteraction="hover"
            content={(popoverContentProps) => (
              <PopoverContent {...popoverContentProps}>
                <REText
                  className="max-w-xs zesty-html"
                  dangerouslySetInnerHTML={{ __html: props.serviceFeeTooltip }}
                />
              </PopoverContent>
            )}
          >
            <REText
              id="result-container--footer--fee--help"
              className="underline inline"
              color="primary"
            >
              {PLANS_AND_PRICES_LABELS.SERVICE_FEE_HELP}
            </REText>
          </Popover>
        </>
      )}
    </div>
  );
};

export default PlansPriceTradeServiceFeeFooter;
