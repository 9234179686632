import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { AlignProps, Dialog } from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';
import { IsTheme, Theme } from '@app/core/featureToggle';

/**
 * @param onClose When null, disables all normal close events on the Modal (X, Esc, Close button, Click off) by default
 */
export interface ModalProps {
  id?: string; // TODO: This should not be defaulted. Later MR, update all Modals to have id
  isActive?: boolean;
  heading?: string;
  onClose?: (e?: any) => void;
  onCloseByOutsideClick?: () => void;
  onCloseByXButton?: () => void;
  onCloseByEscapeKey?: () => void;
  canClose?: true | boolean;
  closeOnOutsideClick?: boolean;
  actions?: JSX.Element[];
  actionsAlign?: AlignProps;
  isModalDialog?: boolean;
  showBackdrop?: boolean;
  disableScrolling?: boolean;
  overwriteBottomMargin?: boolean;
  fullContentArea?: false | boolean;
  className?: '' | string;
  hideClose?: boolean;
  /** remove a content container from being defined to use BDS directly */
  noContentContainer?: boolean;
  /** remove dialog content X margin to be aligned to edge */
  noContentMarginX?: boolean;
  /** remove dialog actions top margin */
  noActionsMarginT?: boolean;
}

export enum ModalCloseType {
  Unspecified = 'unspecified',
  XButton = 'x-button',
  OutsideClick = 'outside-click',
  EscapeKey = 'escape-key',
  LearnMore = 'learn-more', // currently used for splash screen only
}

const Modal: React.FC<ModalProps> = (props) => {
  const vertScrollStyle: any = props.disableScrolling
    ? { overflowY: 'hidden' }
    : { overflowY: 'auto' };
  const overwriteBottomMarginStyle: any = props.overwriteBottomMargin
    ? { marginBottom: '-3rem' }
    : {};

  // Focus on the close button on open
  const closeEl = useRef(null);
  useEffect(() => {
    props.isActive && props.canClose && closeEl.current && closeEl.current.focus();
  }, [props.isActive]);

  // Handle closing by close type
  const onCloseWithEvent = (event: SyntheticEvent<HTMLElement>) => {
    let closeType = ModalCloseType.Unspecified;
    if (
      event.currentTarget.tagName === 'DIV' &&
      event.currentTarget.className.includes('overlay-backdrop')
    )
      closeType = ModalCloseType.OutsideClick;
    else if (
      event.currentTarget.tagName === 'BUTTON' &&
      event.currentTarget.className.includes('button-small')
    )
      closeType = ModalCloseType.XButton;
    else if (event.type === 'keydown' && event['key'] === 'Escape')
      closeType = ModalCloseType.EscapeKey;

    if (closeType === ModalCloseType.Unspecified) {
      props.onClose?.();
    } else if (closeType === ModalCloseType.OutsideClick) {
      if (props.onCloseByOutsideClick) {
        props.onCloseByOutsideClick();
      } else {
        props.onClose?.();
      }
    } else if (closeType === ModalCloseType.XButton) {
      if (props.onCloseByXButton) {
        props.onCloseByXButton();
      } else {
        props.onClose?.();
      }
    } else if (closeType === ModalCloseType.EscapeKey) {
      if (props.onCloseByEscapeKey) {
        props.onCloseByEscapeKey();
      } else {
        props.onClose?.();
      }
    }
  };

  useEffect(() => {
    document.body.style.overflow = props.isActive ? 'hidden' : '';
  }, [props.isActive]);

  return (
    <Dialog
      id={props.id}
      header={props.heading}
      actions={[...props.actions]}
      actionsAlign={props.actionsAlign ? props.actionsAlign : 'right'}
      modal={props.isModalDialog}
      open={props.isActive}
      onClose={props.onClose ? onCloseWithEvent : null}
      closeOnOutsideClick={props.closeOnOutsideClick}
      showBackdrop={props.showBackdrop}
      className={classNames([
        props.className,
        props.noActionsMarginT ? 'dialog-actions-wrapper--mt-2' : '',
        props.noContentMarginX ? 'dialog-body---mx-4' : '',
      ])}
      hideClose={props.hideClose || !props.onClose}
    >
      <>
        {props.noContentContainer ? (
          props.children
        ) : !props.fullContentArea ? (
          <>
            {/* This styling is a workaround. 50vh prevents the dialog content from getting so tall that the dialog goes off-screen. */}
            <div
              style={{
                maxHeight: '50vh',
                overflowX: 'hidden',
                ...vertScrollStyle,
                ...overwriteBottomMarginStyle,
              }}
            >
              {props.children}
            </div>
          </>
        ) : (
          <>
            {/* This allows the children to occupy the full content area of the dialog and sit behind the X button. */}
            <div
              style={{
                marginLeft: '-1rem',
                marginRight: '-1rem',
                marginTop: '-3.5rem',
                marginBottom: '-3rem',
              }}
            >
              {props.children}
            </div>
          </>
        )}
      </>
    </Dialog>
  );
};

Modal.defaultProps = {
  heading: '',
  actions: [],
  isModalDialog: true,
  showBackdrop: true,
  disableScrolling: false,
  overwriteBottomMargin: false,
};

export default Modal;
