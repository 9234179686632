import React, { useEffect, useState } from 'react';
import {
  Button,
  IconInfoFull,
  IconPinSolid,
  MaskedInput,
  Popover,
  PopoverContent,
  ProgressIndicator,
  Select,
  SelectHandler,
  SelectOption,
  Switch,
} from '@ftdr/blueprint-components-react';
import { CityStates, DwellingType } from '@apis/models';
import { classNames } from '@utils';
import { SELLERS_COVERAGE } from '@constants/formField-constants';
import { PlanPriceSummary } from '@components/card/CardPlanPriceSummary';
import PlanCards from '@components/card/PlanCards';
import { GetIncludedCoveragesHandler, PlanCardContent } from '@components/card/PlanCard';
import getBrandConstants from '@constants/brands';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  PLAN_PRICE__SHARE_QUOTE,
  PLAN_PRICE__START_ORDER,
  PLAN_PRICE__UPDATE_PRICE,
} from '@constants/ga-events.constants';
import { PlansAndPricesMetaData } from '@apis/models/zesty.api.model';
import { ContentBoxContainer } from '@components/layout/ContentBoxContainer';
import { findMatchRecordByName, mapWYSIWYGBulletToArray } from '@pages/misc/PlansAndPrices';
import CardPlanPriceCoverage, {
  OptionalCoverageItem,
} from '@components/card/CardPlanPriceCoverage';
import CardQuoteSummary, { QuoteSummarySelection } from '@components/misc/QuoteSummary';
import ServiceUnavailable from '@components/modal/subcomponents/ServiceUnavailable';
import DrawerPlanPriceShareQuote, {
  DrawerPlanPriceShareQuoteProps,
} from '@components/drawer/DrawerPlanPriceShareQuote';
import DrawerPlanPriceSaveQuote from '@components/drawer/DrawerPlanPriceSaveQuote';
import PlansPriceTradeServiceFeeFooter from '@components/misc/PlansPriceTradeServiceFeeFooter';
import { dwellingTypeDictionary, RESIDENCE_DISPLAY } from '@constants/dictionaries';
import { useNavigate } from 'react-router-dom';
import Path from '@constants/paths';
import { getSquareFootageRangeValue, isAgeNewConstruction } from '@services/helpers';
import { NewOrderRouteData } from '@pages/order/NewOrder';
import { NewOrderData } from '@helpers/new-order-loader';
import { PlansCoverageValues } from '@components/card/CardNewOrderPlansCoverage';
import { hasGuestUnitCoverage } from '@helpers/order.utils';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

export const NEW_ORDER_SOURCE_PLANS_AND_PRICES = 'plans-and-prices';

export const PLANS_AND_PRICES_LABELS = {
  EMPTY_RESULT: 'No Plans Found',

  INIT_RESULT_HEADER: 'Enter a ZIP Code',
  INIT_RESULT_SUBTEXT: 'To see plans and prices for that location.',

  NO_RESULT_HEADER: 'Sorry, We Don’t Service That Area',
  NO_RESULT_SUBTEXT: `American Home Shield is unavailable in this area. To request a real estate product, you may call and speak to an Associate regarding other options for your client.
  Please call ${getBrandConstants('AHS').supportPhone}.`,

  FORM_HEADER: 'Base Coverage',
  COVERAGE_SELECTION_HEADER: 'Optional Coverage',
  QUOTE_SUMMARY_HEADER: 'Quote Summary',

  EMPTY_TRADE_SERVICE_FEE: ' ---',

  SUBMIT_BTN: 'Update Price',
  RESIDENCE_TYPE_INPUT: 'Residence Type',
  ZIP_INPUT: 'Zip Code',
  SELLERS_COVERAGE_INPUT: 'Seller Coverage',
  SELLERS_COVERAGE_TOOLTIP: SELLERS_COVERAGE,
  SELLERS_COVERAGE_RESTRICTED_TOOLTIP:
    'Seller’s Coverage is not offered for this type of residence.',

  SERVICE_FEE: 'Trade Service Call Fee',
  SERVICE_FEE_ACRONYM: 'TSCF',
  SERVICE_FEE_HELP: 'Learn More',
  ZIPCODE: 'Zip code affects the price',
};

const PLANS_AND_PRICES_FORM_ERROR = {
  MISSING_RESIDENCE_TYPE: 'Selection required',
  INVALID_ZIP_CODE: 'Enter a valid 5-Digit ZIP code',
};

interface OwnPlansAndPricesTemplateProps {
  /** initial load of the form data */
  initialFormData: PlansAndPricesFormData;
  /** copy of the form data used during submission, used for reference and arguments */
  formData: PlansAndPricesFormData;
  cityOptions: CityStates[];
  loadingPageMeta: boolean;
  loading: boolean;
  loadingPrice: boolean;
  plans: PlanPriceSummary[];
  optionalCoverages: OptionalCoverageItem[];
  selectedPlan: PlanPriceSummary;
  setSelectedPlan: any;
  selectedCoverages: OptionalCoverageItem[];
  quoteSummary: QuoteSummarySelection;
  onPlanSelection: PlanSelectionHandler;
  onCoverageSelection: CoverageSelectionHandler;
  setSelectedCoverageItems: any;
  tradeServiceFee: number;
  onFormDataChange?: PlansAndPricesFormHandler;
  onSubmit: PlansAndPricesFormHandler;
  /** for a given zip string, return the state of the ZIP */
  getZipCityState: GetZipCityStateHandler;
  autoSubmitForm: boolean;
  meta: PlansAndPricesMetaData;
  getIncludedCoverages: GetIncludedCoveragesHandler;
  loadingFilters: boolean;
  disableSellersCoverageOption: boolean;
}

export type PlansAndPricesTemplateProps = OwnPlansAndPricesTemplateProps &
  Pick<DrawerPlanPriceShareQuoteProps, 'productPricingDetailsRequest'>;

export interface PlansAndPricesFormData {
  residenceType: DwellingType;
  zipCode: string;
  includeSellersCoverage: boolean;
}

export type PlanSelectionHandler = (plan: PlanPriceSummary) => void;
export type CoverageSelectionHandler = (cvg: OptionalCoverageItem) => void;
export type GetZipCityStateHandler = (
  zip: string,
) => Promise<{ city: string; state: string; cityStates: CityStates[] }>;
export type PlansAndPricesFormHandler = (data: PlansAndPricesFormData) => void;

export interface PlanCardMetaFields {
  planSubtext: string;
  coverageItems: string[];
}

export type PlanCardMetaFieldRecordsByLookup = Record<string, PlanCardMetaFields>;

export const PlansAndPricesTemplate: React.FC<PlansAndPricesTemplateProps> = (props) => {
  const navigate = useNavigate();

  const [init, setInit] = useState<boolean>(true);

  const [residenceType, setResidenceType] = useState<DwellingType>(undefined);
  const [zipCode, setZipCode] = useState<string>('');
  const [includeSellersCoverage, setIncludeSellersCoverage] = useState<boolean>(false);

  const [fetchingZipState, setFetchingZipState] = useState<boolean>(false);

  const [residenceTypeError, setResidenceTypeError] = useState<string>(null);
  const [zipCodeError, setZipCodeError] = useState<string>(null);

  const [planCardMetaFields, setPlanCardMetaFields] = useState<PlanCardMetaFieldRecordsByLookup>(
    {},
  );

  const [shareQuoteDrawerActive, setShareQuoteDrawerActive] = useState<boolean>(false);

  const [saveQuoteDrawerActive, setSaveQuoteDrawerActive] = useState<boolean>(false);

  const getPendingFormData = (): PlansAndPricesFormData => ({
    residenceType,
    zipCode,
    includeSellersCoverage,
  });

  /** set the meta data for plan text and items */
  useEffect(() => {
    if (props.meta) {
      const metaFields: PlanCardMetaFieldRecordsByLookup = {};
      props.meta.plans.forEach((plan) => {
        metaFields[plan.lookup_text] = {
          planSubtext: plan.plan_subtext,
          coverageItems: mapWYSIWYGBulletToArray(plan.coverage_items),
        };
      });
      setPlanCardMetaFields(metaFields);
    }
  }, [props.meta]);

  /** updates the state if the form data was changed */
  useEffect(() => {
    if (props.initialFormData) {
      setResidenceType(props.initialFormData.residenceType);
      setZipCode(props.initialFormData.zipCode);
      setIncludeSellersCoverage(props.initialFormData.includeSellersCoverage);
    }
  }, [props.initialFormData]);

  /** if zipCode is changed, then clear the error */
  useEffect(() => {
    setZipCodeError(null);
  }, [zipCode]);

  /** if residence type is changed, then clear the error */
  useEffect(() => {
    setResidenceTypeError(null);
  }, [residenceType]);

  /** if there are pending form data changes, and form data is valid, then notify about it */
  useEffect(() => {
    validateForm(getPendingFormData(), false).then((valid) => {
      if (valid) {
        props.onFormDataChange?.(getPendingFormData());
        if (props.autoSubmitForm) {
          validateAndSubmitForm(getPendingFormData());
        }
      }
    });
  }, [residenceType, zipCode, includeSellersCoverage]);

  /* if sellers coverage is disabled, exclude sellers coverage on UI */
  useEffect(() => {
    if (includeSellersCoverage && props.disableSellersCoverageOption) {
      setIncludeSellersCoverage(false);
    }
  }, [props.disableSellersCoverageOption]);

  const getPlanSubtext = (planName: string): string => {
    return (
      findMatchRecordByName<PlanCardMetaFieldRecordsByLookup, PlanCardMetaFields>(
        planCardMetaFields,
        planName,
      )?.planSubtext || ''
    );
  };

  const getCoverageItems = (planName: string): string[] => {
    return (
      findMatchRecordByName<PlanCardMetaFieldRecordsByLookup, PlanCardMetaFields>(
        planCardMetaFields,
        planName,
      )?.coverageItems || ['<i>Failed to load</i>']
    );
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // prevent form submission
    await validateAndSubmitForm();
  };

  /** validate the form and submit if valid. by default, uses the state values. otherwise, values may be passed in directly. */
  const validateAndSubmitForm = async (
    data: PlansAndPricesFormData = getPendingFormData(),
  ): Promise<void> => {
    if (await validateForm(data)) {
      if (!init) {
        // only trigger on updates as this validateAndSubmitForm is currently called on init load as well
        fireGAEvent(PLAN_PRICE__UPDATE_PRICE(data));
      }

      props.onSubmit(data); // TODO: ARE-8966 -- ensure relevant prior work is canceled to avoid displaying inaccurate plans due to race conditions
      setInit(false);
    }
  };

  /** validate the form data and return true if no errors. by default, uses the state values. otherwise, values may be passed in directly. */
  const validateForm = async (
    data: PlansAndPricesFormData = getPendingFormData(),
    updateFormErrorStates = true,
  ): Promise<boolean> => {
    const errors = {
      residenceType: validateResidenceType(data.residenceType),
      zipCode: await validateZipCode(data.zipCode, updateFormErrorStates), // only validate exists when we want to update form error
    };

    if (updateFormErrorStates) {
      setResidenceTypeError(errors.residenceType);
      setZipCodeError(errors.zipCode);
    }

    return Object.keys(errors).every((k) => !errors[k]);
  };

  const validateResidenceType = (val: string): string => {
    if (!val) {
      return PLANS_AND_PRICES_FORM_ERROR.MISSING_RESIDENCE_TYPE;
    }
    return null;
  };

  /** validates the zip code is the correct length and if exists (has a US state) */
  const validateZipCode = async (val: string, validateExists = true): Promise<string> => {
    if (!val) {
      return PLANS_AND_PRICES_FORM_ERROR.INVALID_ZIP_CODE;
    }
    if (val.length !== 5) {
      return PLANS_AND_PRICES_FORM_ERROR.INVALID_ZIP_CODE;
    }

    if (validateExists) {
      const zipState = await validateZipExists(val);
      if (!zipState) {
        return PLANS_AND_PRICES_FORM_ERROR.INVALID_ZIP_CODE;
      }
    }

    return null;
  };

  /** sets and unsets the state that we are fetching the zip's state */
  const validateZipExists = (val: string): Promise<string> => {
    setFetchingZipState(true);
    return props
      .getZipCityState(val)
      .then((res) => {
        const state = res?.state;
        console.info(`zip=${val}, state=${state}`);
        return state;
      })
      .finally(() => {
        setFetchingZipState(false);
      });
  };

  const onSelectResidenceType = async (option: SelectOption) => {
    const newResidenceType = option?.value as DwellingType;
    setResidenceType(newResidenceType);
  };

  const onSelectSellersCoverage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeSellersCoverage(e.target.checked);
  };

  const onBlurZIPInput = async () => {
    setZipCodeError(await validateZipCode(zipCode));
  };

  /** two separate instances of this is used for mobile and for desktop view. */
  const QuoteSummary = () => {
    const onClickShareQuoteButton = () => {
      fireGAEvent(PLAN_PRICE__SHARE_QUOTE);
      setShareQuoteDrawerActive(true);
    };

    const onClickSaveQuoteButton = () => {
      setSaveQuoteDrawerActive(true);
    };

    return (
      <>
        {props.plans.length > 0 && (
          <CardQuoteSummary
            quoteSummary={props.quoteSummary}
            residenceType={props.formData?.residenceType}
            zipCode={props.formData?.zipCode}
            sellersCoverage={props.formData?.includeSellersCoverage}
            onClickShareQuoteButton={onClickShareQuoteButton}
            onClickSaveQuoteButton={onClickSaveQuoteButton}
            onClickStartOrderButton={onClickStartNewOrderButton}
            isLoading={props.loadingPrice}
          />
        )}
      </>
    );
  };
  const getPlans = (): PlanCardContent[] => {
    return props.plans.map((plan) => {
      return {
        pvid: plan.pvid,
        heading: plan.heading,
        subtext: getPlanSubtext(plan.heading),
        price: plan.price,
        coverages: getCoverageItems(plan.heading) as string[],
        bestCoverage: false,
        selected: props.selectedPlan?.pvid === plan.pvid,
        zestyBased: true,
      };
    });
  };

  const handleSelect = (pvid: string) => {
    const planContent = props.plans.find((plan) => plan.pvid === pvid);

    props.onPlanSelection(planContent);
  };

  const resetOptions = () => {
    setResidenceType(props.initialFormData.residenceType);
    setZipCode(props.initialFormData.zipCode);
    setIncludeSellersCoverage(props.initialFormData.includeSellersCoverage);
    props.setSelectedPlan(null);
    props.setSelectedCoverageItems([]);
  };

  const onClickStartNewOrderButton = () => {
    const property = props.productPricingDetailsRequest?.property;

    const newOrderData: NewOrderData = {
      propertyAddr: {
        zip: zipCode,
      },
      propertyDetails: {
        age: property?.age,
        newConstruction: isAgeNewConstruction(property?.age),
        notNewConstruction: !isAgeNewConstruction(property?.age),
        squareFootage: getSquareFootageRangeValue(property?.squareFootage),
        residenceType: dwellingTypeDictionary[residenceType],
        typeOfResidence: dwellingTypeDictionary[residenceType],
        propertyID: null,
        mlsNumber: '',
      },
    };

    const planAndCoverageData: PlansCoverageValues = {
      selectedFilters: {
        includeSellersCoverage,
        termYear: 1,
        includeACCoverage: true,
      },
      selectedPlanCoverages: {
        product: {
          starPVID: props.selectedPlan.pvid,
          name: props.selectedPlan.heading,
          contractTermMonths: 12,
          sellersCoverage: includeSellersCoverage,
          acCoverage: true,
          id: null,
        },
        optionalCoverages: props.selectedCoverages.map((c) => ({
          id: c.id,
          name: c.name,
          quantity: 1,
        })),
        groupCoverages: [],
      },
      selectedGuestUnit: false,
      selectedMilitaryDiscount: false,
    };
    planAndCoverageData.selectedGuestUnit = hasGuestUnitCoverage(
      planAndCoverageData.selectedPlanCoverages.optionalCoverages,
    );

    const routeData: NewOrderRouteData = {
      values: newOrderData,
      planCoverageValues: planAndCoverageData,
      source: NEW_ORDER_SOURCE_PLANS_AND_PRICES,
    };

    fireGAEvent(PLAN_PRICE__START_ORDER(RESIDENCE_DISPLAY[residenceType], zipCode, includeSellersCoverage));
    navigate(Path.NewOrder, { state: routeData });
  };

  return (
    <>
      <div className=" border-t border-b border-gray-300 pb-10">
        <ContentBoxContainer className="mx-auto py-2 flex flex-row-reverse">
          <div id="quote-summary-container" className="hidden xl:block">
            <div className="sticky-container-top w-80 ml-5">
              <QuoteSummary />
            </div>
          </div>
          <div id="result-container" className="flex-1 w-full">
            <div id="result-container--form" className={`${IsTheme(Theme.Ahs2024) ? 'pb-4' : 'pb-8'} pt-8`}>
              <form>
                <REText variant="heading-04" className="mt-0" color="primary" >
                  {PLANS_AND_PRICES_LABELS.FORM_HEADER}
                </REText>
                <div className="flex lg-max:flex-col lg-max:space-y-4 lg:space-x-2" id='filter-content'>
                  <div className="w-full">
                    <SelectResidenceType
                      id="result-container--form--select-residence-type"
                      selected={residenceType}
                      onSelect={onSelectResidenceType}
                      disabled={props.loading}
                      error={residenceTypeError}
                    />
                  </div>
                  <div className="w-full">
                    <InputZipCode
                      id="result-container--form--input-zipcode"
                      value={zipCode}
                      onChangeZIP={(val) => setZipCode(val)}
                      onBlur={onBlurZIPInput}
                      disabled={props.loading}
                      error={zipCodeError}
                      loading={fetchingZipState}
                    />
                  </div>
                  <div className="w-full">
                    <div className="flex flex-col space-y-1">
                      <div className="flex flex-row space-x-2 items-center">
                        <REText variant="label">
                          {PLANS_AND_PRICES_LABELS.SELLERS_COVERAGE_INPUT}
                        </REText>
                        <Popover
                          placement="top"
                          triggerInteraction="hover"
                          content={(popoverContentProps) => (
                            <PopoverContent {...popoverContentProps}>
                              <REText className="max-w-xs" variant="caption">
                                {props.disableSellersCoverageOption
                                  ? PLANS_AND_PRICES_LABELS.SELLERS_COVERAGE_RESTRICTED_TOOLTIP
                                  : PLANS_AND_PRICES_LABELS.SELLERS_COVERAGE_TOOLTIP}
                              </REText>
                            </PopoverContent>
                          )}
                        >
                          <IconInfoFull
                            color="interactive"
                            size={18}
                            className={classNames(['inline'])}
                          />
                        </Popover>
                      </div>
                      <Switch
                        id="result-container--form--checkbox-sellers-coverage"
                        value="yes"
                        on={includeSellersCoverage}
                        onChange={onSelectSellersCoverage}
                        disabled={
                          props.loading ||
                          props.loadingFilters ||
                          props.disableSellersCoverageOption
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-1">
                  <Button
                    id="result-container--form--submit-btn"
                    label={PLANS_AND_PRICES_LABELS.SUBMIT_BTN}
                    type="submit"
                    size="medium"
                    width="full"
                    onClick={onSubmit}
                    disabled={props.loading}
                    loading={props.loading}
                    loadingAnimation="spinner-only"
                    className={classNames([props.autoSubmitForm && 'hidden'])}
                  />
                </div>
              </form>
            </div>

            <div id="result-container--plans">
              <div className="w-full flex space-x-2">
                {!props.loading && props.plans.length === 0 && !props.loadingPageMeta && (
                  <EmptyResultsContainer init={init} />
                )}
                {props.loading && (
                  <PlanCards plans={[]} onSelectCallback={null} loading={true} loadingCount={3} />
                )}
                {!props.loading && props.plans.length > 0 && (
                  <PlanCards
                    onSelectCallback={handleSelect}
                    plans={getPlans()}
                    getIncludedCoverages={props.getIncludedCoverages}
                    zestyBased={true}
                    selectedPlanID={props.selectedPlan?.pvid}
                  />
                )}
              </div>
            </div>

            {props.plans.length > 0 && (
              <div id="result-container--footer" className="mt-6 space-y-4">
                {!props.loadingPageMeta && props.meta?.footnote && (
                  <div id="result-container--footer--footnote">
                    <REText
                      className="zesty-html"
                      dangerouslySetInnerHTML={{ __html: props.meta?.footnote }}
                    />
                  </div>
                )}

                <PlansPriceTradeServiceFeeFooter
                  loadingServiceFee={props.loading}
                  loadingText={props.loadingPageMeta}
                  serviceFee={props.tradeServiceFee}
                  serviceFeeDescription={props.meta?.tscf_text}
                  serviceFeeTooltip={props.meta?.tscf_tooltip}
                />
              </div>
            )}

            {props.plans.length > 0 && (
              <div className="border-t-1 border-gray-300 mt-5 py-3 space-y-2">
                <div id='plans-optional-coverages'>
                  <REText variant="heading-04" className="mt-5" color="primary">
                    {PLANS_AND_PRICES_LABELS.COVERAGE_SELECTION_HEADER}
                  </REText>

                  <div id="coverages-container">
                    <div className="space-y-5 my-3">
                      {props.optionalCoverages.map((cvg) => (
                        <CardPlanPriceCoverage
                          key={cvg.id}
                          id={cvg.id}
                          name={cvg.name}
                          subtext={cvg.subtext}
                          icon={cvg.icon}
                          price={cvg.price}
                          selected={!!props.selectedCoverages.find((s) => s.id === cvg.id)}
                          onSelect={() => props.onCoverageSelection(cvg)}
                        />
                      ))}
                    </div>
                  </div>
                  <div id="quote-summary-container-mobile" className="block xl:hidden">
                    <QuoteSummary />
                  </div>
                </div>
              </div>
            )}
          </div>
        </ContentBoxContainer>
      </div>

      <DrawerPlanPriceShareQuote
        isActive={shareQuoteDrawerActive}
        onClose={() => setShareQuoteDrawerActive(false)}
        onSuccess={resetOptions}
        quote={props.quoteSummary}
        productPricingDetailsRequest={props.productPricingDetailsRequest}
        formData={props.formData}
        cityOptions={props.cityOptions}
      />
      <DrawerPlanPriceSaveQuote
        isActive={saveQuoteDrawerActive}
        onClose={() => setSaveQuoteDrawerActive(false)}
        onSuccess={resetOptions}
        productPricingDetailsRequest={props.productPricingDetailsRequest}
        quote={props.quoteSummary}
        formData={props.formData}
        cityOptions={props.cityOptions}
      />
    </>
  );
};

export const EmptyResultsContainer: React.FC<{ init: boolean }> = ({ init }) => {
  const iconClassName = classNames(['text-gray-400']);

  return (
    <div className="min-h-96 flex flex-col items-center m-auto place-content-center max-w-96 text-center sm-max:p-8 py-20">
      {init && <IconPinSolid color="gray" size={48} className={iconClassName} />}

      <REText variant="heading-04" color="primary" id="no-plans--header" className="my-4">
        {init
          ? PLANS_AND_PRICES_LABELS.INIT_RESULT_HEADER
          : PLANS_AND_PRICES_LABELS.NO_RESULT_HEADER}
      </REText>

      <REText color="gray" id="no-plans--subtext" className="leading-snug text-gray-600">
        {init ? PLANS_AND_PRICES_LABELS.INIT_RESULT_SUBTEXT : <ServiceUnavailable />}
      </REText>
    </div>
  );
};

interface SelectResidenceTypeProps {
  id: string;
  selected?: DwellingType;
  disabled?: boolean;
  onSelect: SelectHandler<SelectOption>;
  error?: string;
}

const SelectResidenceType: React.FC<SelectResidenceTypeProps> = (props) => {
  const residenceTypeOptions: SelectOption[] = [
    {
      id: 'residence-type-option--sfr',
      label: 'Single Family Residence',
      value: DwellingType.SingleFamilyResidence,
    },
    {
      id: 'residence-type-option--condo',
      label: 'Condominium',
      value: DwellingType.Condominium,
    },
    {
      id: 'residence-type-option--th',
      label: 'Townhouse',
      value: DwellingType.Townhouse,
    },
    {
      id: 'residence-type-option--duplex',
      label: 'Duplex',
      value: DwellingType.Duplex,
    },
    {
      id: 'residence-type-option--triplex',
      label: 'Triplex',
      value: DwellingType.Triplex,
    },
    {
      id: 'residence-type-option--fourplex',
      label: 'Fourplex',
      value: DwellingType.Fourplex,
    },
    {
      id: 'residence-type-option--mh',
      label: 'Mobile Home',
      value: DwellingType.MobileHome,
    },
  ];

  return (
    <Select
      id={props.id}
      required={true}
      label={PLANS_AND_PRICES_LABELS.RESIDENCE_TYPE_INPUT}
      formField={true}
      options={residenceTypeOptions}
      onSelect={props.onSelect}
      selected={props.selected && residenceTypeOptions.find((o) => o.value === props.selected)}
      disabled={props.disabled}
      error={props.error}
    />
  );
};

interface InputZipCodeProps {
  id: string;
  value: string;
  onChangeZIP?: (zip: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: string;
  /** adds a spinner to the end of the input */
  loading?: boolean;
}

const InputZipCode: React.FC<InputZipCodeProps> = (props) => {
  const [value, setValue] = useState<string>('');

  /** update the state if prop value changes */
  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  return (
    <MaskedInput
      id={props.id}
      type="text"
      inputMode="numeric"
      required={true}
      label={PLANS_AND_PRICES_LABELS.ZIP_INPUT}
      formField={true}
      value={value}
      onChange={(_, val) => props.onChangeZIP(val)}
      onBlur={props.onBlur}
      maxLength={5}
      disabled={props.disabled}
      error={props.error}
      mask="00000"
      endEnhancer={
        props.loading && (
          <ProgressIndicator
            variant="circular"
            color="gray"
            size="small"
            value={undefined}
            showValueLabel={false}
          />
        )
      }
    />
  );
};
