import React, { useState } from 'react';
import { OrderFormProps } from '@components/orders/forms/OrderForm.types';
import { OrderOfficeAgentData } from '@app/models/order/order.model';
import { Office } from '@apis/models';
import { Office as AppOffice } from '@app/models';
import OrderForm from '@components/orders/forms/OrderForm';
import InitiatingOfficeInput from '@components/input/InitiatingOfficeInput';
import InitiatingAgentInput from '@components/input/InitiatingAgentInput';
import { OrderFormOfficeAgentField } from '@components/orders/forms/OrderFormOfficeAgent';
import REText from '@components/wrappedBDS/REText';

interface OrderFormOfficeAgentProps {
  ids: Record<OrderFormOfficeAgentField, string>;
  labels: Record<OrderFormOfficeAgentField, string>;
  officeName: string;
  agentName: string;
}

/** TODO: this component is incomplete. it isn't handling clear logic properly yet.
 *   In addition, the step template needs logic to save the office into user profile on save of step.
 *   In addition, should handle add agent to office logic.
 *   Should see if we can switch over to the BDS Select Autocomplete implementation instead for Agent. */
const OrderFormPrefilledOfficeAgent: React.FC<OrderFormOfficeAgentProps> = (props) => {
  return (
    <OrderForm.Container id="office-agent-order-form" heading="Agent Information">
      <OrderForm.Row>
        <OrderForm.RowItem>
          <REText variant="label">{props.labels.officeInput}</REText>
          <REText id={props.ids.officeInput}>{props.officeName}</REText>
        </OrderForm.RowItem>
        <OrderForm.RowItem>
          <REText variant="label">{props.labels.agentInput}</REText>
          <REText id={props.ids.agentInput}>{props.agentName}</REText>
        </OrderForm.RowItem>
      </OrderForm.Row>
    </OrderForm.Container>
  );
};

OrderFormPrefilledOfficeAgent.defaultProps = {};

export default OrderFormPrefilledOfficeAgent;
