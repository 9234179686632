import { QuoteStatus, RealEstateStatus, WarrantylinkStatus } from '@constants/dashboardFilters';

export const accountToOfficeTypeDictionary = {
  RealEstateAgent: 'RE',
  ClosingCompanyAgent: 'CC',
  RealEstateAdmin: 'RE',
  ClosingCompanyAdmin: 'CC',
  Broker: 'RE',
};

// partially deprecated as this was previously setup to support account types as well, though accountToOfficeTypeDictionary is to be used now instead
export const officeTypeDictionary = {
  RealEstate: 'RE',
  RE: 'RE',
  ClosingCompany: 'CC',
  CC: 'CC',
  ...accountToOfficeTypeDictionary,
};

export const officeTypeFullFormDictionary = {
  RE: 'RealEstate',
  CC: 'ClosingCompany',
  RealEstate: 'RealEstate',
  ClosingCompany: 'ClosingCompany',
};

/** Key = API, Value = what is used in CardNewOrderPropertyDetails */
export const dwellingTypeDictionary = {
  singleFamilyResidence: 'singleFamilyResidence',
  SFR: 'singleFamilyResidence',
  SINGLEFAMILYRESIDENCE: 'singleFamilyResidence',
  SINGLE_FAMILY: 'singleFamilyResidence',
  CONDOMINIUM: 'condominium',
  condominium: 'condominium',
  CONDO: 'condominium',
  townhouse: 'townhouse',
  TWNH: 'townhouse',
  TOWNHOUSE: 'townhouse',
  TOWNHOME: 'townhouse',
  duplex: 'duplex',
  DUPLEX: 'duplex',
  DUP: 'duplex',
  TRIPLEX: 'triplex',
  triplex: 'triplex',
  TRIP: 'triplex',
  fourplex: 'fourplex',
  FOURPLEX: 'fourplex',
  FOUR_PLEX: 'fourplex',
  FOUR: 'fourplex',
  MOBILEHOME: 'mobileHome',
  MOBILE_HOME: 'mobileHome',
  mobileHome: 'mobileHome',
  MH: 'mobileHome',
};

export const hasNarIdDictionary = {
  RealEstateAgent: true,
  Broker: true,
};

/** Key = status string, Value = label display text */
export const orderStatusDictionary = {
  [RealEstateStatus.PAYMENT_DUE]: 'Payment Due',
  [RealEstateStatus.PAYMENT_PAST_DUE]: 'Payment Past Due',
  [RealEstateStatus.CLOSING_SOON]: 'Closing Soon',
  [RealEstateStatus.CLOSING_LATER]: 'Closing Later',
  [RealEstateStatus.EXPIRING]: 'Expiring',
  [RealEstateStatus.EXPIRED]: 'Expired',
  [RealEstateStatus.ACTIVE]: 'Active',
  [RealEstateStatus.CANCELLED_ORDER]: 'Cancelled Order',
  [RealEstateStatus.CANCELLED_CONTRACT]: 'Cancelled Contract',
  //ARE-9853. Commenting out temporarily
  //[RealEstateStatus.AWAITING_WL_SUBMISSION]: 'WarrantyLink Eligible',
  [RealEstateStatus.CLOSED]: 'Closed',
  [RealEstateStatus.OPEN_ORDER]: 'Open Order',
  [WarrantylinkStatus.READY]: 'Ready',
  [WarrantylinkStatus.SAVED]: 'Saved',
  [WarrantylinkStatus.CLOSED]: 'Closed',
  [WarrantylinkStatus.SUBMITTED]: 'Submitted',
  [WarrantylinkStatus.PENDING]: 'Pending',
  [WarrantylinkStatus.COMPLETED]: 'Completed',
  [WarrantylinkStatus.READYSAVED]: 'Ready & Saved',

  [QuoteStatus.SAVED]: 'Saved',
  [QuoteStatus.CANCELLED]: 'Cancelled',
  [QuoteStatus.SHARED]: 'Shared',
  [QuoteStatus.CONVERTED_TO_ORDER]: 'Converted',
  [QuoteStatus.EXPIRED]: 'Expired',
} as const;

export const quotesStatusDisplay = {
  COMPLETE: 'Complete',
  ABANDONED: 'Abandoned',
  EXPIRING: 'Expiring Soon',
  EXPIRED: 'Expired',
  SAVED: 'Saved',
  SHARED: 'Shared',
  CANCELLED: 'Cancelled',
  CONVERTED_ORDER: 'Converted',
  NEW: 'New',
} as const;

export const quotesStatusIDDictionary = {
  COMPLETE: 'COMPLETE',
  EXPIRING: 'EXPIRING',
  EXPIRED: 'EXPIRED',
  SAVED: 'SAVED',
  SHARED: 'SHARED',
  CANCELLED: 'CANCELLED',
  CONVERTED_ORDER: 'CONVERTED_ORDER',
  NEW: 'NEW',
} as const;

export const orderStatusIDDictionary = {
  [RealEstateStatus.PAYMENT_DUE]: 'paymentDue_status',
  [RealEstateStatus.CLOSING_SOON]: 'closing_soon_status',
  [RealEstateStatus.EXPIRING]: 'expiring_status',
  [RealEstateStatus.EXPIRED]: 'expired_status',
  [RealEstateStatus.ACTIVE]: 'active_status',
  [RealEstateStatus.CANCELLED_ORDER]: 'cancelled_order_status',
  [RealEstateStatus.CANCELLED_CONTRACT]: 'cancelled_contract_status',
  //ARE-9853. Commenting out temporarily
  // [RealEstateStatus.AWAITING_WL_SUBMISSION]: 'awaiting_warrantyLink_submission_status',
  [RealEstateStatus.CLOSED]: 'closed_status',
  [RealEstateStatus.OPEN_ORDER]: 'open_order_status',
} as const;

export const orderFilterIDDictionary = {
  [RealEstateStatus.PAYMENT_DUE]: 'paymentDue',
  [RealEstateStatus.CLOSING_SOON]: 'closing_soon',
  [RealEstateStatus.EXPIRING]: 'expiring',
  [RealEstateStatus.EXPIRED]: 'expired',
  [RealEstateStatus.ACTIVE]: 'active',
  [RealEstateStatus.CANCELLED_ORDER]: 'cancelled_order',
  [RealEstateStatus.CANCELLED_CONTRACT]: 'cancelled_contract',
  //ARE-9853. Commenting out temporarily
  //[RealEstateStatus.AWAITING_WL_SUBMISSION]: 'awaiting_warrantyLink_submission',
  [RealEstateStatus.CLOSED]: 'closed',
  [RealEstateStatus.OPEN_ORDER]: 'open_order',
} as const;

export const orderContractDuplicateStatus = {
  L: 'An open order',
  A: 'An active contract',
  X: 'A cancelled order',
  C: 'A cancelled contract',
  '': '',
};

export const ErrorCodes = {
  UpdateUserOfficeOfficeNotInFranchise: '20021',
};

export enum UserExtension {
  WLKBroker = 'WarrantyLinkBroker',
}

export enum Permissions {
  UserMaintenance = 'UserMaintenance',
  ContractChangeInitiatingOfficeOrAgent = 'ContractChangeInitiatingOfficeOrAgent',
  WLKSubmit = 'SubmitWarrantyLink',
}
export enum UserRoleType {
  SiteAdmin = 'SiteAdmin',
  FieldSales = 'FieldSales',
  RealEstateAdmin = 'RealEstateAdmin',
  ClosingCompanyAdmin = 'ClosingCompanyAdmin',
  RealEstateAgent = 'RealEstateAgent',
  ClosingCompanyAgent = 'ClosingCompanyAgent',
  Broker = 'Broker',
  InternalSalesAgent = 'InternalSalesAgent',
}

/** enum holds the names of each coverage we want to replace its name */
export enum CoverageNameOverride {
  GuestUnit = 'Guest Unit', // 114932
  Pool = 'Pool', // (HSA),  // 65
  PoolOnly = 'Pool Only', // 366
  PoolIngroundSpaSharedEquipment = 'Pool/Inground Spa Shared Equipment', // 19
}

/** if listed, the coverage name should be overwritten with the provided value. Exact match only. */
export const coverageNameOverridesDictionary: Record<CoverageNameOverride, string> = {
  [CoverageNameOverride.GuestUnit]: 'Guest Unit (less than 750 sq. ft.)',
  [CoverageNameOverride.Pool]: 'Chlorine Pool',
  [CoverageNameOverride.PoolOnly]: 'Chlorine Pool Only',
  [CoverageNameOverride.PoolIngroundSpaSharedEquipment]:
    'Chlorine Pool/Inground Spa Shared Equipment',
};

export const brandFullNameDictionary = {
  AHS: 'American Home Shield',
  HSA: 'Home Security of America',
};

/** RE Product Sales Groups */
export enum ProductSalesGroup {
  RealtorOwned = 'RO',
  Franchise = 'RELO',
}

/** Customer type codes */
export enum CustomerTypes {
  Buyer = 'BUY',
  Seller = 'SEL',
  CoBuyer = 'COBUY',
  CoSeller = 'COSEL',
}

export enum RESIDENCE_DISPLAY {
  'SINGLEFAMILYRESIDENCE' = 'Single Family Residence',
  'CONDOMINIUM' = 'Condominium',
  'TOWNHOUSE' = 'Townhouse',
  'DUPLEX' = 'Duplex',
  'TRIPLEX' = 'Triplex',
  'FOURPLEX' = 'Fourplex',
  'MOBILEHOME' = 'Mobile Home',
}
