import React from 'react';
import {
  Button,
  ContextMenu,
  IconNavArrowDown,
  Separator,
  Text,
  TypographicVariants,
} from '@ftdr/blueprint-components-react';
import { PLANS_AND_PRICES_LABELS } from '@templates/misc/PlansAndPricesTemplate';
import { DwellingType } from '@apis/models';
import { removeTags, toCurrency, toPercentage } from '@helpers/utils';
import { classNames, isMobileView } from '@utils';
import { DiscountLabel } from '@components/drawer/subcomponents/MakePaymentStripe';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

export interface QuoteSummaryItem {
  /** the pvid or the cvgId */
  id: string;
  name: string;
  price: number;
}

export interface QuoteSummarySelection {
  plan: QuoteSummaryItem;
  coverages: QuoteSummaryItem[];
  tax: {
    price: number;
    rate: number;
    taxableAmount: number;
  };
  total: number;
  sellersCoverage: {
    total: number;
    days: number;
    amount: number;
  };
  hasSellersCoverage?: boolean;
  discountAmount: number;
  militaryDiscountApplied: boolean;
}

export interface QuoteSummaryProps {
  quoteSummary: QuoteSummarySelection;
  residenceType: DwellingType;
  zipCode: string;
  sellersCoverage: boolean;
  disablePadding?: boolean;
  isLoading?: boolean;
  plansNameVariant?: TypographicVariants;
}

export interface CardQuoteSummaryProps extends QuoteSummaryProps {
  onClickShareQuoteButton?: () => void;
  onClickSaveQuoteButton?: () => void;
  onClickStartOrderButton?: () => void;
}

const CardQuoteSummary: React.FC<CardQuoteSummaryProps> = ({
  onClickShareQuoteButton,
  onClickSaveQuoteButton,
  onClickStartOrderButton,
  ...props
}) => {
  const menuItems = [
    { label: 'Start Order', onClick: onClickStartOrderButton, id: 'start-order-open-drawer' },
    { label: 'Save Quote', onClick: onClickSaveQuoteButton, id: 'save-quote-open-drawer' },
    { label: 'Share Quote', onClick: onClickShareQuoteButton, id: 'share-quote-open-drawer' },
  ];
  return (
    <div
      className={`${IsTheme(Theme.Ahs2024) ? 'rounded-5 p-6' : 'border border-gray-300 rounded-3 shadow-1 py-5'} bg-white w-full`}
      style={{ boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1)' }}
    >
      <QuoteSummary
        {...props}
        disablePadding={IsTheme(Theme.Ahs2024) ? true : props.disablePadding}
      />

      <div
        className={`${IsTheme(Theme.Ahs2024) ? '' : 'mx-4'} flex flex-wrap  pt-4 justify-center`}
      >
        {isMobileView() ? (
          <>
            {menuItems.map((menuItem) => (
              <div key={menuItem.label} className="w-full mt-2">
                <Button
                  onClick={() => menuItem.onClick?.()}
                  label={menuItem.label}
                  width="full"
                  size="medium"
                  id={menuItem.id}
                  disabled={props.isLoading}
                />
              </div>
            ))}
          </>
        ) : (
          <div
            id="plansAndPricesContext"
            className={`${IsTheme(Theme.Ahs2024) ? 'align-center' : ''} w-full mt-2 max-w-1/4`}
          >
            <ContextMenu
              className="w-full "
              menuItems={menuItems}
              menuSizeStyle={{ width: '286px' }}
            >
              <Button
                label="Select Action"
                width="full"
                size="medium"
                id="select-action"
                disabled={props.isLoading}
                labelAlign="justified"
                endIcon={<IconNavArrowDown />}
              />
            </ContextMenu>
          </div>
        )}
      </div>
    </div>
  );
};

CardQuoteSummary.defaultProps = {
  isLoading: false,
};

export const QuoteSummary: React.FC<QuoteSummaryProps> = (props) => {
  function normalizeResidenceType(rt) {
    if (rt) {
      const residenceType = rt.toUpperCase();
      switch (residenceType) {
        case 'SINGLEFAMILYRESIDENCE':
        case 'SINGLE_FAMILY':
        case 'SFR':
          return 'Single Family Residence';
        case 'CONDOMINIUM':
        case 'CONDO':
          return 'Condominium';
        case 'TOWNHOUSE':
        case 'TOWNHOME':
        case 'TWNH':
          return 'Townhouse';
        case 'DUPLEX':
        case 'DUP':
          return 'Duplex';
        case 'TRIPLEX':
        case 'TRIP':
          return 'Triplex';
        case 'FOURPLEX':
        case 'FOUR_PLEX':
        case 'FOUR':
          return 'Fourplex';
        case 'MOBILEHOME':
        case 'MOBILE_HOME':
        case 'MH':
          return 'Mobile Home';
        default:
          return rt;
      }
    }
    return rt;
  }

  const lineItemContainerClassName = `${IsTheme(Theme.Ahs2024) ? 'mb-2' : ''} flex flex-nowrap flex-row justify-between items-center`;

  const LineItem: React.FC<{ id: string; className?: string }> = ({ id, children, className }) => {
    return (
      <div id={id} className={classNames([lineItemContainerClassName, className])}>
        {children}
      </div>
    );
  };

  return (
    <div className={props?.disablePadding ? 'px-0' : 'px-6'}>
      <REText
        variant="heading-04"
        className={`${IsTheme(Theme.Ahs2024) ? 'mb-4' : 'mt-0 mb-2'}`}
        color="primary"
      >
        {PLANS_AND_PRICES_LABELS.QUOTE_SUMMARY_HEADER}
      </REText>
      {props.residenceType ? (
        <LineItem
          id="quote-summary-residence-type"
          className={`${IsTheme(Theme.Ahs2024) ? 'mb-4' : ''}`}
        >
          <REText variant={IsTheme(Theme.Ahs2024) ? 'heading-06' : 'label'}>
            Residence Type:{' '}
          </REText>
          <REText
            className={IsTheme(Theme.Ahs2024) ? 'text-right' : ''}
            variant={IsTheme(Theme.Ahs2024) ? 'body-short' : 'caption'}
          >
            {normalizeResidenceType(props.residenceType)}
          </REText>
        </LineItem>
      ) : null}
      {props.zipCode ? (
        <LineItem id="quote-summary-zip-code" className={`${IsTheme(Theme.Ahs2024) ? 'mb-4' : ''}`}>
          <REText variant={IsTheme(Theme.Ahs2024) ? 'heading-06' : 'label'}>Zip Code: </REText>
          <REText variant={IsTheme(Theme.Ahs2024) ? 'body-short' : 'caption'}>
            {props.zipCode}
          </REText>
        </LineItem>
      ) : null}

      {props.sellersCoverage ? (
        <div id="quote-summary-product-sellers-coverage">
          <div className={lineItemContainerClassName}>
            <REText variant={IsTheme(Theme.Ahs2024) ? 'heading-06' : 'label'}>
              Seller's Coverage:{' '}
            </REText>
            {props.quoteSummary?.sellersCoverage?.total ? (
              <REText variant={IsTheme(Theme.Ahs2024) ? 'body-short' : 'caption'}>
                {toCurrency(props.quoteSummary?.sellersCoverage?.total || 0)}
              </REText>
            ) : (
              <REText variant={IsTheme(Theme.Ahs2024) ? 'body-short' : 'caption'}>Included</REText>
            )}
          </div>
        </div>
      ) : null}

      {props.quoteSummary?.sellersCoverage?.amount > 0 ? (
        <div className="ml-2">
          <REText variant={IsTheme(Theme.Ahs2024) ? 'body-short' : 'caption'} color="gray">
            Fee ({toCurrency(props.quoteSummary?.sellersCoverage?.amount || 0)}/day for{' '}
            {props.quoteSummary?.sellersCoverage?.days} days)
          </REText>
        </div>
      ) : null}

      {!IsTheme(Theme.Ahs2024) && <br />}

      {props.quoteSummary?.plan?.name ? (
        <LineItem
          id="quote-summary-product-quote"
          className={`${IsTheme(Theme.Ahs2024) ? 'mb-4' : ''}`}
        >
          <REText
            variant={IsTheme(Theme.Ahs2024) ? props.plansNameVariant ?? 'body-short' : 'label'}
          >
            {props.quoteSummary?.plan?.name}
          </REText>
          <REText variant={IsTheme(Theme.Ahs2024) ? 'heading-06' : 'label'}>
            {toCurrency(props.quoteSummary?.plan?.price || 0)}
          </REText>
        </LineItem>
      ) : null}

      {props.quoteSummary?.coverages?.map((c) =>
        c.name ? (
          <LineItem
            key={c.id}
            id={`quote-summary-coverage-${c.id}`}
            className={`${IsTheme(Theme.Ahs2024) ? 'mb-4' : ''}`}
          >
            <REText variant={IsTheme(Theme.Ahs2024) ? 'body-short' : 'caption'}>
              {removeTags(c.name)}{' '}
            </REText>
            <REText variant={IsTheme(Theme.Ahs2024) ? 'heading-06' : 'label'}>
              {toCurrency(c.price || 0)}
            </REText>
          </LineItem>
        ) : null,
      )}

      {props.quoteSummary?.tax ? (
        <LineItem id="quote-summary-quotetax">
          <div className="flex space-x-2 items-center">
            <REText variant={IsTheme(Theme.Ahs2024) ? 'body-short' : 'caption'}>Tax </REText>
            {props.quoteSummary.tax.taxableAmount > 0 && props.quoteSummary.tax.price > 0 && (
              <REText
                variant="helper-text"
                color="gray"
              >{`${toPercentage(props.quoteSummary.tax.rate)} of ${toCurrency(props.quoteSummary.tax.taxableAmount)}`}</REText>
            )}
          </div>
          <REText variant={IsTheme(Theme.Ahs2024) ? 'heading-06' : 'label'}>
            {toCurrency(props.quoteSummary.tax.price || 0)}
          </REText>
        </LineItem>
      ) : null}

      {props.quoteSummary?.discountAmount > 0 ? (
        <LineItem id="quote-summary-discount" className={`${IsTheme(Theme.Ahs2024) ? 'mt-4' : ''}`}>
          <div className="flex space-x-2 items-center">
            <REText variant="caption">
              <DiscountLabel
                label={
                  props.quoteSummary.militaryDiscountApplied
                    ? 'Military Discount Applied'
                    : undefined
                }
              />{' '}
            </REText>
          </div>
          <REText variant="label">{toCurrency(0 - props.quoteSummary.discountAmount)}</REText>
        </LineItem>
      ) : null}

      {IsTheme(Theme.Ahs2024) ? <Separator orientation="horizontal" gap="sm" /> : <hr />}

      {props.quoteSummary ? (
        <LineItem
          id="quote-summary-quotetotal"
          className={`${IsTheme(Theme.Ahs2024) ? 'mb-4' : ''}`}
        >
          <REText variant={IsTheme(Theme.Ahs2024) ? 'heading-06' : 'label'}>Annual Total: </REText>
          <REText variant={IsTheme(Theme.Ahs2024) ? 'heading-06' : 'label'}>
            {toCurrency(props.quoteSummary?.total || 0)}
          </REText>
        </LineItem>
      ) : null}
    </div>
  );
};

export default CardQuoteSummary;
