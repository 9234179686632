import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@ftdr/blueprint-components-react';
import { QuoteSummary, QuoteSummarySelection } from '@components/misc/QuoteSummary';
import { DwellingType, ProductPricingDetailsRequest } from '@apis/models';
import { useNavigate } from 'react-router-dom';
import Path from '@constants/paths';
import { fireGAEvent } from '@app/core/tracking.service';
import { ORDER__START_ORDER } from '@constants/ga-events.constants';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { isAdmin } from '@helpers/profile.utils';
import ProfileContext from '@context/ProfileContext';
import { Quote } from '@apis/models/quote.api.model';
import { getBrand } from '@helpers/brand.utils';
import ZestyApi from '@apis/zesty.apis';
import { OptionalCoverageItem } from '@components/card/CardPlanPriceCoverage';
import { formatDate } from '@helpers/utils';
import { quotesStatusDisplay, quotesStatusIDDictionary } from '@constants/dictionaries';
import { addressObjectToString } from '@services/helpers';
import { TextSubHeading } from '@components/textUtilities/TextSubHeading';
import { NewOrderFromSource } from '@pages/order/NewOrder';
import { PlansAndPricesFormData } from '@templates/misc/PlansAndPricesTemplate';
import { Order } from '@apis/models/orders.api.model';
import NewOrderLoader from '@helpers/new-order-loader.order';
import { OrdersApiSuppressErrors } from '@apis/orders.api';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

const LABELS = {
  HEADING: 'Quote Details',
  CONVERT_TO_ORDER: 'Convert to Order',
  CANCEL_BUTTON: 'Cancel',
  SHARE_QUOTE: 'Share Quote',
  VIEW_ORDER: 'View Order',
  QUOTE_NAME: 'Quote Number: ',
  PROPERTY_ADDRESS: 'Covered Property Address: ',
  RESIDENCE_TYPE: 'Residence Type: ',
  QUOTE_CREATED_DATE: 'Quote Created Date: ',
  INITIATING_OFFICE: 'Initiating Office: ',
  STATUS: 'Quote Status: ',
  INITIATING_AGENT: 'Initiating Agent: ',
  QUOTE_SHARING_DATE: 'Quote Shared Date: ',
  ZIP_CODE: 'Zip Code: ',
  CUSTOMER_NAME: 'Customer Name: ',
};

export interface PlanPriceViewQuoteProps {
  selectedOrder: Quote;
  onClose: () => void;
  onShareQuote: (shareDataProps) => void;
  onCancelQuote: (cancelQuoteProps) => void;
  onConvertToOrder: (selectedContract) => void;
  onViewOrder: (contractID) => void;
}

const defaultQuoteSummary: QuoteSummarySelection = {
  tax: {
    price: 0,
    rate: 0,
    taxableAmount: 0,
  },
  total: 0,
  plan: {
    id: '',
    name: '',
    price: 0,
  },
  coverages: [],
  sellersCoverage: {
    total: 0,
    amount: 0,
    days: 0,
  },
  hasSellersCoverage: false,
  militaryDiscountApplied: false,
  discountAmount: 0,
};

export const loadOrderForViewQuote = (
  orderID: string,
): Promise<{
  quoteSummary: QuoteSummarySelection;
  productPricingDetailsRequest: ProductPricingDetailsRequest;
  planPriceFormData: PlansAndPricesFormData;
}> => {
  return OrdersApiSuppressErrors.get(orderID).then(parseMyQuoteFromOrder);
};

export const parseMyQuoteFromOrder = (
  order: Order,
): {
  quoteSummary: QuoteSummarySelection;
  productPricingDetailsRequest: ProductPricingDetailsRequest;
  planPriceFormData: PlansAndPricesFormData;
} => {
  const newOrderLoader = new NewOrderLoader();

  return {
    productPricingDetailsRequest: newOrderLoader.mapToProductPricingDetailsRequest(order),
    quoteSummary: newOrderLoader.mapToQuoteSummary(order),
    planPriceFormData: newOrderLoader.mapToPlanPriceFormData(order),
  };
};

const PlanPriceViewQuote: React.FC<PlanPriceViewQuoteProps> = (props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isAdminRole, setIsAdminRole] = useState(false);
  const [quoteSummary, setQuoteSummary] = useState<QuoteSummarySelection>(defaultQuoteSummary);
  const [productPricingDetails, setProductPricingDetails] =
    useState<ProductPricingDetailsRequest>();
  const [formData, setFormData] = useState<PlansAndPricesFormData>({
    includeSellersCoverage: false,
    residenceType: '' as DwellingType,
    zipCode: '',
  });

  const { addSuccessToQueue, addErrorToQueue } = useGlobalAlert();
  const quoteId = props?.selectedOrder?.summary?.id;
  const navigate = useNavigate();
  const residenceType = props.selectedOrder?.property?.dwellingTypeCode as DwellingType;
  const { agentName } = props.selectedOrder.summary;
  const { officeName } = props.selectedOrder.summary;
  const { customers } = props.selectedOrder.detail;
  const { address } = props.selectedOrder.summary;
  const { status } = props.selectedOrder;

  const { profile } = useContext(ProfileContext);
  const brand = getBrand();

  useEffect(() => {
    (async () => {
      // Fetch profile & match admin
      if (isAdmin(profile)) {
        setIsAdminRole(true);
      }

      const orderID = `${props.selectedOrder.id}`;

      const {
        planPriceFormData: formData,
        quoteSummary,
        productPricingDetailsRequest: productPricingRequest,
      } = await loadOrderForViewQuote(orderID);

      ZestyApi.GetPlansAndPricesCoverages(brand)
        .then((data) => {
          const coverageMeta = data;
          quoteSummary.coverages = quoteSummary.coverages.map((cvg) => {
            const zestyData = coverageMeta.find((c) => c.coverage_id === cvg.id);
            return {
              ...cvg,
              name: zestyData?.updated_name || `<b>${cvg.name}</b>`,
            } as OptionalCoverageItem;
          });
          setQuoteSummary(quoteSummary);
          setFormData(formData);
          setProductPricingDetails(productPricingRequest);
        })
        .catch((e) => {
          console.error('failed to load coverage meta', e);
        });
    })();
  }, [profile, quoteId]);

  const lineItemParent = 'flex flex-col md:flex-row justify-between';
  return (
    <div className="full-height-card">
      <form>
        <REText id="save-quote--heading" variant="heading-02" color="primary" className="mb-8">
          {LABELS.HEADING}
        </REText>
        <div className={lineItemParent}>
          <div className={IsTheme(Theme.Ahs2024) ? 'space-y-4' : 'space-y-2'}>
            <TextSubHeading
              id="save-quote--subheading-order-id"
              label={LABELS.QUOTE_NAME}
              caption={props.selectedOrder.summary.id}
              labelVariant="heading-06"
              captionVariant="body-short"
            />
            <TextSubHeading
              id="save-quote--subheading-propery-address"
              label={LABELS.PROPERTY_ADDRESS}
              caption={addressObjectToString(address)}
              labelVariant="heading-06"
              captionVariant="body-short"
            />
            <TextSubHeading
              id="save-quote--subheading-created-date"
              label={LABELS.QUOTE_CREATED_DATE}
              caption={formatDate(props.selectedOrder.detail.importantDates.creationDate)}
              labelVariant="heading-06"
              captionVariant="body-short"
            />
            {isAdminRole && (
              <TextSubHeading
                id="save-quote--subheading-init-office"
                label={LABELS.INITIATING_OFFICE}
                caption={officeName}
                labelVariant="heading-06"
                captionVariant="body-short"
              />
            )}
          </div>
          <div className={IsTheme(Theme.Ahs2024) ? 'md:pr-2 mt-4 md:mt-0 space-y-4' : 'space-y-2'}>
            <TextSubHeading
              id="save-quote--subheading-status"
              label={LABELS.STATUS}
              caption={quotesStatusDisplay[status]}
              labelVariant="heading-06"
              captionVariant="body-short"
            />
            <TextSubHeading
              id="save-quote--subheading-sharing-date"
              label={LABELS.QUOTE_SHARING_DATE}
              caption={
                props.selectedOrder?.sharedDate ? formatDate(props.selectedOrder.sharedDate) : ''
              }
              labelVariant="heading-06"
              captionVariant="body-short"
            />
            {isAdminRole && (
              <TextSubHeading
                id="save-quote--subheading-init-agent"
                label={LABELS.INITIATING_AGENT}
                caption={agentName}
                labelVariant="heading-06"
                captionVariant="body-short"
              />
            )}
          </div>
        </div>
        <hr className="h-px my-6 bg-gray-200 border-0 dark:bg-gray-700" />
        <div className="w-full py-5 mb-4">
          {Object.values(quoteSummary).length > 0 && (
            <QuoteSummary
              plansNameVariant="heading-06"
              residenceType={residenceType}
              sellersCoverage={formData.includeSellersCoverage}
              zipCode={props.selectedOrder.summary.address.zip}
              quoteSummary={quoteSummary}
              disablePadding={true}
            />
          )}
        </div>
        <div
          id="share-quote--actions"
          className="flex flex-col-reverse md:flex-row items-center justify-between md:justify-between"
        >
          {(status === quotesStatusIDDictionary.SAVED ||
            status === quotesStatusIDDictionary.SHARED) && (
            <div className="text-center sm-max:mt-4 md:mr-4">
              <Button
                id="view-quote--actions--cancel"
                className="text-primary-400"
                variant="ghost"
                label="Cancel Quote"
                size="small"
                loading={isSubmitting}
                onClick={() => {
                  props.onClose();
                  props.onCancelQuote({
                    selectedQuote: props.selectedOrder,
                  });
                }}
              />
            </div>
          )}
          <div className="flex flex-col-reverse md:flex-row">
            {status === quotesStatusIDDictionary.CONVERTED_ORDER && (
              <div className="text-center sm-max:mt-4 md:mr-4">
                <Button
                  id="view-quote--actions--view-order"
                  variant="outlined"
                  size="medium"
                  width="full"
                  className="mt-4 md:mt-0"
                  label={LABELS.VIEW_ORDER}
                  onClick={() => {
                    if (props?.onViewOrder) {
                      props.onViewOrder(props?.selectedOrder?.contractId);
                    }
                  }}
                />
              </div>
            )}
            {status === quotesStatusIDDictionary.SAVED && (
              <div className="text-center sm-max:mt-4 md:mr-4">
                <Button
                  id="view-quote--actions--share"
                  variant="outlined"
                  size="medium"
                  width="full"
                  className="mt-4 md:mt-0"
                  label={LABELS.SHARE_QUOTE}
                  onClick={() =>
                    props.onShareQuote({
                      quote: quoteSummary,
                      formData,
                      productPricingDetailsRequest: productPricingDetails,
                      selectedQuote: props.selectedOrder,
                    })
                  }
                />
              </div>
            )}
            {(status === quotesStatusIDDictionary.SAVED ||
              status === quotesStatusIDDictionary.SHARED) && (
              <div>
                <Button
                  id="view-quote--actions--submit"
                  size="medium"
                  width="full"
                  className="mt-4 md:mt-0"
                  label={LABELS.CONVERT_TO_ORDER}
                  onClick={() => {
                    navigate(
                      `${Path.NewOrder}/${quoteId}?from=${NewOrderFromSource.ViewQuote}`,
                    );
                    fireGAEvent(ORDER__START_ORDER);
                    props.onClose();
                    props.onConvertToOrder(props.selectedOrder);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default PlanPriceViewQuote;
