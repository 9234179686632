import React from 'react';
import ContactUsAuth from './ContactUsAuth';
import ContactUsNoAuth from './ContactUsNoAuth';
import { useLocation } from 'react-router-dom';

interface IContactUsProps {
  isAuthed?: boolean;
  contractId?: string;
  topic?: string;
  emailText?: string;
}

const ContactUs = (props: IContactUsProps) => {
  const location = useLocation();

  return props.isAuthed ? (
    <ContactUsAuth
      contractId={location?.state?.contractId}
      topic={location?.state?.topic}
      emailText={location?.state?.emailText}
    />
  ) : (
    <ContactUsNoAuth />
  );
};

export default ContactUs;
