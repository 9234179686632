import { Link, List } from '@ftdr/blueprint-components-react';
import React from 'react';
import REText from '@components/wrappedBDS/REText';

const Coverage = (props) => {
  return (
    <div className="flex flex-wrap pt-6">
      <div className="w-full">
        <REText className="pb-2" variant="heading-05">
          Covered Items
        </REText>
        <div id={`${props.id}-covered-items-list`}>
          <List listItems={props.covered?.map((item) => ({ text: item }))} variant="unordered" />
          <Link id={`${props.id}-more-options-link`} className="block mt-6 leading-tight">
            Looking for more options? you can add additional coverage after selecting this plan.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Coverage;
