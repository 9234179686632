import React, { FunctionComponent } from 'react';
import { Button, Link } from '@ftdr/blueprint-components-react';
import { Office } from '@app/models';
import { isSmallView } from '@utils';
import REText from '@components/wrappedBDS/REText';

interface IProps {
  incorrectOfficeInfoBtnHandler: (arg: string) => void;
  correctOfficeInfoBtnHandler: (arg: string) => void;
  office: Office;
}

const ConfirmOfficeTemplate: FunctionComponent<IProps> = ({
  incorrectOfficeInfoBtnHandler,
  correctOfficeInfoBtnHandler,
  office,
}) => {
  const isSmall = isSmallView();

  return (
    <>
      <REText variant="heading-03" id="confirm-office_welcome">
        Welcome!
      </REText>
      <REText variant="body-short" id="confirm-office_copy" className="mt-2">
        Please confirm that your office information is correct.
      </REText>

      <div className="sm:flex sm:flex-wrap mt-8">
        <div className="sm-max:w-full md:pr-24">
          <REText variant="heading-06" id="confirm-office_name">
            {office && office.name}
          </REText>
          <p>
            <REText variant="body-short" id="confirm-office_address">
              {office && office.address.streetAddress} {office && office.address.unit}
            </REText>
            <REText variant="body-short" id="confirm-office_state-zip">
              {office && office.address.city}, {office && office.address.state}{' '}
              {office && office.address.zip}
            </REText>
          </p>
          <p>
            <REText variant="body-short" id="confirm-office_phone">
              Main:{' '}
              <Link href={`tel:${office && office.contact.phones.office}`} color="interactive">
                {office && office.contact.phones.office}
              </Link>
            </REText>
            <REText variant="body-short" id="confirm-office_fax">
              Fax:{' '}
              <Link href={`tel:${office && office.contact.phones.fax}`} color="interactive">
                {office && office.contact.phones.fax}
              </Link>
            </REText>
          </p>
        </div>
      </div>

      <div className="md:flex md:flex-row-reverse mt-12">
        <Button
          className="sm-max:block md:ml-4"
          label="Yes, this is correct"
          labelAlign="center"
          width={isSmall ? 'full' : 'auto'}
          color="success"
          onClick={() => correctOfficeInfoBtnHandler('dashboard')}
        />
        <Button
          className="sm-max:block sm-max:mt-4"
          label="No, this is incorrect"
          labelAlign="center"
          width={isSmall ? 'full' : 'auto'}
          color="error"
          onClick={() => incorrectOfficeInfoBtnHandler('searchOffice')}
        />
      </div>
    </>
  );
};

export default ConfirmOfficeTemplate;
