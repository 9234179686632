import React, { Component } from 'react';
import ZestyApi from '@apis/zesty.apis';
import { IconUser } from '@ftdr/blueprint-components-react';
import CardSalesMaterial from '@components/card/CardSalesMaterial';
import { getBrand } from '@helpers/brand.utils';
import { SalesMaterial } from '@apis/models/zesty.api.model';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

interface state {
  salesMaterials: SalesMaterial[];
}

export default class SalesMaterialTemplate extends Component<unknown, state> {
  constructor(props) {
    super(props);

    this.state = {
      salesMaterials: [],
    };

    ZestyApi.GetSalesMaterial().then((res) => {
      const resFiltered = res
        .filter((item) => item.brand.toUpperCase() === getBrand())
        .sort((a, b) => a.sort - b.sort);
      this.setState({ salesMaterials: resFiltered || [] });
    });
  }

  render() {
    return (
      <div className={`${IsTheme(Theme.Ahs2024) ? "my-6" : "card my-4 py-4"} mx-auto`}>
        {!IsTheme(Theme.Ahs2024) && <IconUser className="float-left mr-1 ml-3" color="primary" />}
        {!IsTheme(Theme.Ahs2024) && <REText variant="heading-06"> For Real Estate Professionals</REText>}

        <div className={IsTheme(Theme.Ahs2024) ? "grid gap-6 md:grid-cols-2 lg:grid-cols-3" : "md:flex flex-wrap"}>
          {this.state.salesMaterials.map((item) => {
            return <CardSalesMaterial salesMaterial={item} key={item.title} />;
          })}
        </div>
      </div>
    );
  }
}
