import { Button, DateTimeInput, IconNavArrowLeft } from '@ftdr/blueprint-components-react';
import FormNewOrderCustomer from '@components/form/FormNewOrderCustomer';
import { CustomerFields } from '@components/input/CustomerInfoInput';
import { ORDER_FORM_FIELDS } from '@constants/newOrder-constants';
import { CLOSING_DATE_RANGE } from '@constants/formField-constants';
import React, { useContext, useEffect, useState } from 'react';
import { Contract, ContractDateType } from '@apis/models/contract.api.model';
import { breakdownContractCustomers } from '@helpers/order.utils';
import {
  getAgeByYearBuilt,
  getSquareFootageRangeValue,
  isNewConstructionByYearBuilt,
} from '@services/helpers';
import { dwellingTypeDictionary } from '@constants/dictionaries';
import { NOT_APPLICABLE_OPTION } from '@services/autosuggest/autosuggest';
import { isEmailValid, isPhoneNumberValid } from '@services/validation/ValidationRules';
import * as c from '@constants/formField-constants';
import ProfileContext from '@context/ProfileContext';
import { isEmpty } from 'lodash';
import { compose } from 'lodash/fp';
import msgs from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { parseDate } from '@app/helpers/utils';
import REText from '@components/wrappedBDS/REText';

interface props {
  contract: Contract;
  handleBackToSearchResults?: () => void;
  onClose: any;
  onSave: any;
  showTopSection: boolean;
  showClosingInfoFirst: boolean;
  showCancelButton: boolean;
  saveButtonText: string;
  onEmailChange?: any;
  triggerOnSave?: any; // TODO: rework component to allow form submission, and validity detection to be more easily managed by parent
  setTriggerOnSave?: any; // TODO: ^
  setCanSave?: any; // TODO: ^
  onChange?: (scratchContract) => void;
  setClosingDate?: any;
  onBuyerInfoUpdate?: (value: boolean) => void;
}

const UpdateBuyerInfo = (props: props) => {
  const [originalDate, setOriginalDate] = useState<Date>(undefined);
  const [isFirstConversion, setIsFirstConversion] = useState<boolean>(true);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: '',
  });

  const { profile } = useContext(ProfileContext);

  const onChangeInput = (e) => {
    if (!e) return;

    const { name, value } = e.target;
    const tempContractData = { ...contractData };
    const errorCopy = { ...errors };
    tempContractData.buyerInfo[name] = value;
    setContractData(tempContractData);
    if (props.onChange) {
      props.onChange(tempContractData);
    }

    errorCopy[name] = '';

    /**
     * Because only email OR phone is required, we need to remove the error on the opposite field if the opposite field is blank.
     */
    if (name === CustomerFields.email && !tempContractData.buyerInfo.phone) {
      errorCopy.phone = '';
    }

    if (name === CustomerFields.phone && !tempContractData.buyerInfo.email) {
      errorCopy.email = '';
    }

    if (props.onEmailChange && name === 'email') {
      props.onEmailChange(value);
    }

    setErrors(errorCopy);
    if (props.onBuyerInfoUpdate) {
      props.onBuyerInfoUpdate(true);
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    const { firstName, lastName, email, phone } = contractData.buyerInfo;
    const errorCopy = { ...errors };

    if (!firstName && name === CustomerFields.firstName) {
      errorCopy.firstName = c.FIRST_NAME_REQUIRED;
    }

    if (!lastName && name === CustomerFields.lastName) {
      errorCopy.lastName = c.LAST_NAME_REQUIRED;
    }

    /**
     * Either the phone OR the email is required. Need to check validity of both here.
     */
    if (email && !isEmailValid(email) && name === CustomerFields.email) {
      errorCopy.email = c.INVALID_EMAIL;
    }

    if (phone && !isPhoneNumberValid(phone) && name === CustomerFields.phone) {
      errorCopy.phone = c.INVALID_PHONE_NUMBER;
    }

    // TODO: revisit how this is handled. We may want to have another constant that says "Email or Phone required" that shows up somewhere.
    if (
      !email &&
      !phone &&
      (name === CustomerFields.email || name === CustomerFields.phone) &&
      isBuyerContract(props.contract)
    ) {
      errorCopy.email = c.EMAIL_REQUIRED;
      errorCopy.phone = '';
    }

    if (!contractData.closingInfo.projectedClosingDate && name === 'projectedClosingDate') {
      errorCopy.date = c.PROJECTED_CLOSING_DATE;
    }
    setErrors(errorCopy);
  };

  const setClosingDateOnContractData = (date) => {
    const tempContractData = { ...contractData };
    tempContractData.closingInfo.projectedClosingDate = date;
    tempContractData.closingInfo.dateUnknown = !!date;
    setContractData(tempContractData);
    if (props.onChange) {
      props.onChange(tempContractData);
    }
  };

  const onDateChangeHandler = (date) => {
    setErrors({
      ...errors,
      date: '',
    });
    setClosingDateOnContractData(date);
    if (date && props.setClosingDate) {
      props.setClosingDate(date);
    }
    if (props.onBuyerInfoUpdate && date) {
      props.onBuyerInfoUpdate(true);
    }
  };

  /**
   * This converts the contract we get from the search into the object we need to send to the endpoint when we save.
   * There are some extra fields that get converted that we don't need, keeping them around in case we need them later.
   * @param contract the contract model passed in from the ContractApi call during the search.
   */
  const convertContractData = (contract) => {
    const { addErrorToQueue } = useGlobalAlert();
    try {
      if (contract?.detail) {
        const tempContractData = { ...contract };
        const { initiatingOfficeAgent } = contract.detail;
        const { cooperatingOfficeAgent } = contract.detail;
        const { buyer, seller, cobuyer, coseller } = breakdownContractCustomers(contract);

        // CardNewOrderAgentInfo
        tempContractData.id = contract?.detail?.id;
        tempContractData.agentInfo = {
          agentRepresents: {
            // This should be specific to the card, not passed in
            buyer: initiatingOfficeAgent?.represents === 'BUYER',
            seller: initiatingOfficeAgent?.represents === 'SELLER',
            both: initiatingOfficeAgent?.represents === 'BOTH',
          },
          agentlist: [], // This is more tied to the card itself and shouldn't be a prop (?)
          initiatingAgent: initiatingOfficeAgent?.agent
            ? `${initiatingOfficeAgent.agent.firstName} ${initiatingOfficeAgent.agent.lastName}`
            : '',
          AgentId: initiatingOfficeAgent?.agent?.realEstateAgentID,
          AgentEmail: initiatingOfficeAgent?.agent?.email,
          initiatingOffice: initiatingOfficeAgent?.office?.name,
          office: {
            id: initiatingOfficeAgent?.office?.id,
            type: initiatingOfficeAgent?.office?.type,
          },
          representing: initiatingOfficeAgent?.represents,
          agentOffice: {
            franchiseCode: initiatingOfficeAgent?.office?.franchiseCode,
            id: initiatingOfficeAgent?.office?.id,
            type: initiatingOfficeAgent?.office?.type,
          },
        };

        // CardNewOrderPropertyAddress + CardNewOrderPropertyDetails
        const { property, mlsNumber } = contract.detail;
        tempContractData.propertyAddr = {
          streetAddress: property.streetAddress,
          city: property.city,
          state: property.state,
          zip: property.zip,
          isDisabled: true, // Should not be in contract data
        };

        const listDate = contract.detail.dates.find((d) => d.type === ContractDateType.ListingDate);
        const listDateYear = (listDate && listDate.effective.split('-')[0]) || undefined;

        if (!tempContractData.propertyDetails) {
          // if we just opened the drawer
          tempContractData.propertyDetails = {
            age: getAgeByYearBuilt(+property.yearBuilt, +listDateYear),
            newConstruction: isNewConstructionByYearBuilt(property.yearBuilt, +listDateYear),
            notNewConstruction: !isNewConstructionByYearBuilt(property.yearBuilt, +listDateYear),
            squareFootage: getSquareFootageRangeValue(property.squareFeet), // This should be specific to the card, not passed in as string
            residenceType: dwellingTypeDictionary[property.dwellingType], // This and typeOfResidence matches, no reason to split from what I can see
            typeOfResidence: dwellingTypeDictionary[property.dwellingType],
            propertyID: property && property.ID,
          };
        }

        tempContractData.propertyDetails.mlsNumber = mlsNumber;

        // CardNewOrderBuyerInfo
        tempContractData.buyerInfo = {
          buyerInfo: {
            yes: Boolean(buyer),
            no: !buyer,
          },
          alternateAddressFields: buyer && buyer.address.zipCode !== '',
          coBuyerFields: Boolean(cobuyer),
          coBuyerAlternateAddressFields: cobuyer && cobuyer.address.zipCode !== '',

          // used to determine if email/phone is required, based SOLELY on who agent represents and not closing information.
          needEmailOrPhone:
            initiatingOfficeAgent?.represents === 'BUYER' ||
            initiatingOfficeAgent?.represents === 'BOTH',

          // Buyer
          customerId: buyer?.customerId,
          firstName: buyer && buyer.firstName,
          lastName: buyer && buyer.lastName,
          email: buyer && buyer.email && buyer.email.address,
          emailId: buyer?.email?.id, // TODO: Model email as first class resource after domain model normalization
          phone: buyer && buyer.phones && buyer.phones.number,
          phoneType: buyer?.phones?.type, // TODO: Model phone as first class resource after domain model normalization

          streetAddress: buyer && buyer.address.address1,
          unit: buyer && buyer.address.address2,
          city: buyer && buyer.address.cityName,
          state: buyer && buyer.address.state,
          zipCode: buyer && buyer.address.zipCode,

          // CoBuyer
          coCustomerId: cobuyer?.customerId,
          coCustomerFirstName: cobuyer && cobuyer.firstName,
          coCustomerLastName: cobuyer && cobuyer.lastName,
          coCustomerEmail: cobuyer && cobuyer.email && cobuyer.email.address,
          coCustomerEmailId: cobuyer?.email?.id, // TODO: Model email as first class resource after domain model normalization
          coCustomerPhone: cobuyer && cobuyer.phones && cobuyer.phones.number,
          coCustomerPhoneType: cobuyer?.phones?.type, // TODO: Model phone as first class resource after domain model normalization

          coCustomerStreetAddress: cobuyer && cobuyer.address.address1,
          coCustomerUnit: cobuyer && cobuyer.address.address2,
          coCustomerCity: cobuyer && cobuyer.address.cityName,
          coCustomerState: cobuyer && cobuyer.address.state,
          coCustomerZipCode: cobuyer && cobuyer.address.zipCode,

          agentlist: [], // This is more tied to the card itself and shouldn't be a prop (?)
          isDisabled: true,
          office:
            cooperatingOfficeAgent?.represents === 'BUYER'
              ? cooperatingOfficeAgent?.office
              : undefined,
          cooperatingOffice:
            cooperatingOfficeAgent && cooperatingOfficeAgent.represents === 'BUYER'
              ? cooperatingOfficeAgent.office.name
              : '',
          cooperatingAgent:
            cooperatingOfficeAgent && cooperatingOfficeAgent.represents === 'BUYER'
              ? `${cooperatingOfficeAgent.agent && cooperatingOfficeAgent.agent.firstName} ${cooperatingOfficeAgent.agent && cooperatingOfficeAgent.agent.lastName}`
              : '',
          AgentId: cooperatingOfficeAgent?.agent?.realEstateAgentID,
        };

        tempContractData.sellerInfo = {
          sellerInfo: {
            yes: Boolean(seller),
            no: !seller,
          },
          alternateAddressFields: seller && seller.address.zipCode !== '',
          coSeller: Boolean(coseller),
          coSellerFields: Boolean(coseller),
          coSellerAlternateAddressFields: coseller && coseller.address.zipCode !== '',

          // used to determine if email/phone is required, based SOLELY on who agent represents and not closing information.
          needEmailOrPhone:
            initiatingOfficeAgent?.represents === 'SELLER' ||
            initiatingOfficeAgent?.represents === 'BOTH',

          // Seller
          customerId: seller?.customerId,
          firstName: seller && seller.firstName,
          lastName: seller && seller.lastName,
          email: seller && seller.email && seller.email.address,
          emailId: seller?.email?.id, // TODO: Model email as first class resource after domain model normalization
          phone: seller && seller.phones && seller.phones.number,
          phoneType: seller?.phones?.type, // TODO: Model phone as first class resource after domain model normalization

          streetAddress: seller && seller.address.address1,
          unit: seller && seller.address.address2,
          city: seller && seller.address.cityName,
          state: seller && seller.address.state,
          zipCode: seller && seller.address.zipCode,

          // CoSeller
          coCustomerId: coseller?.customerId,
          coCustomerFirstName: coseller && coseller.firstName,
          coCustomerLastName: coseller && coseller.lastName,
          coCustomerEmail: coseller && coseller.email && coseller.email.address,
          coCustomerEmailId: coseller?.email?.id, // TODO: Model email as first class resource after domain model normalization
          coCustomerPhone: coseller && coseller.phones && coseller.phones.number,
          coCustomerPhoneType: coseller?.phones?.type, // TODO: Model phone as first class resource after domain model normalization

          coCustomerStreetAddress: coseller && coseller.address.address1,
          coCustomerUnit: coseller && coseller.address.address2,
          coCustomerCity: coseller && coseller.address.cityName,
          coCustomerState: coseller && coseller.address.state,
          coCustomerZipCode: coseller && coseller.address.zipCode,

          agentlist: [], // This is more tied to the card itself and shouldn't be a prop (?)
          isDisabled: true,
          office:
            cooperatingOfficeAgent?.represents === 'SELLER'
              ? cooperatingOfficeAgent?.office
              : undefined,
          cooperatingOffice:
            cooperatingOfficeAgent && cooperatingOfficeAgent.represents === 'SELLER'
              ? cooperatingOfficeAgent.office.name
              : '',
          cooperatingAgent:
            cooperatingOfficeAgent && cooperatingOfficeAgent.represents === 'SELLER'
              ? `${cooperatingOfficeAgent.agent && cooperatingOfficeAgent.agent.firstName} ${cooperatingOfficeAgent.agent && cooperatingOfficeAgent.agent.lastName}`
              : '',
          AgentId: cooperatingOfficeAgent?.agent?.realEstateAgentID,
        };

        // CardNewOrderClosingInfo
        const { closingFileNumber, closingOfficeAgent } = contract.detail;
        const projectedClosingDate = contract.detail.importantDates.projectedClosingDate
          ? new Date(contract.detail.importantDates.projectedClosingDate)
          : null;

        if (isFirstConversion) {
          setOriginalDate(projectedClosingDate);
          setIsFirstConversion(false);
        }

        tempContractData.closingInfo = {
          closingFileNumber,
          closingTitleCompany: closingOfficeAgent ? closingOfficeAgent.office.name : '',
          closingTitleAgent:
            closingOfficeAgent && closingOfficeAgent.agent
              ? `${closingOfficeAgent.agent.firstName} ${closingOfficeAgent.agent.lastName}`
              : '',
          titleCompany: {
            // This should be part of the card itself
            yes: Boolean(closingOfficeAgent),
            no: !closingOfficeAgent,
          },
          AgentId: closingOfficeAgent?.agent?.realEstateAgentID,
          AgentEmail: closingOfficeAgent?.agent?.email,
          closingOffice: closingOfficeAgent?.office,
          office: closingOfficeAgent?.office,
          offices: [], // meh
          dateUnknown: !projectedClosingDate,
          projectedClosingDate: projectedClosingDate || '',
          userDetails: {
            firstName: profile && profile.firstName,
            lastName: profile && profile.lastName,
          },
        };

        // If we have an office but no agent, then consider as 'not applicable'
        if (closingOfficeAgent?.office && !closingOfficeAgent?.agent) {
          tempContractData.closingInfo.closingTitleAgent = `${NOT_APPLICABLE_OPTION.firstName} ${NOT_APPLICABLE_OPTION.lastName}`;
        }

        return tempContractData;
      }
    } catch (e) {
      console.error(e);
      addErrorToQueue(msgs.FAILED_TO_LOAD_CONTRACT);
    }
  };

  const [contractData, setContractData] = useState(convertContractData(props.contract));

  // handle 'onMount' validation
  useEffect(() => {
    if (props.setCanSave) {
      compose(props.setCanSave, isValid, convertContractData)(props.contract);
    }
  }, []);

  useEffect(() => {
    if (props.setCanSave) {
      compose(props.setCanSave, isValid)(contractData);
    }
  }, [errors]);

  useEffect(() => {
    if (props.triggerOnSave) {
      handleSave();
      props.setTriggerOnSave(false);
    }
  }, [props.triggerOnSave]);

  const isValid = (contractData): boolean => {
    const errs = getValidationErrors(contractData);

    return !Object.values(errs).some((x) => !isEmpty(x));
  };

  const getValidationErrors = (contractData) => {
    const { firstName, lastName, email, phone } = contractData.buyerInfo;
    const errorCopy = { ...errors };

    if (!firstName) errorCopy.firstName = c.FIRST_NAME_REQUIRED;
    if (!lastName) errorCopy.lastName = c.LAST_NAME_REQUIRED;

    if (isBuyerContract(props.contract)) {
      /**
       * Either the phone OR the email is required. Need to check validity of both here.
       */
      if (email && !isEmailValid(email)) errorCopy.email = c.INVALID_EMAIL;
      if (phone && !isPhoneNumberValid(phone)) errorCopy.phone = c.INVALID_PHONE_NUMBER;

      // TODO: revisit how this is handled. We may want to have another constant that says "Email or Phone required" that shows up somewhere.
      if (!email && !phone) {
        errorCopy.email = c.EMAIL_REQUIRED;
        errorCopy.phone = '';
      }
    }

    if (!contractData.closingInfo.projectedClosingDate) errorCopy.date = c.PROJECTED_CLOSING_DATE;

    return errorCopy;
  };

  const isBuyerContract = (contract) => {
    return contract.meta?.offices?.some(
      (office) =>
        office.initiating === 'Y' &&
        (office.represents === 'BUYER' || office.represents === 'BOTH'),
    );
  };

  const handleBackToSearch = () => {
    setContractData({});
    setErrors({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      date: '',
    });
    props.handleBackToSearchResults?.();
  };

  const handleSave = () => {
    if (!contractData || contractData.dateUnknown) {
      setErrors({
        ...errors,
        date: c.PROJECTED_CLOSING_DATE,
      });
      return false;
    }
    props.onSave(contractData, originalDate !== contractData.closingInfo.projectedClosingDate);
  };

  const renderTopSection = () => {
    return (
      <>
        <REText variant="heading-03" color="primary">
          Update Buyer Information
        </REText>
        <REText variant="body-long" className="mt-4">
          {`${props.contract.summary.address.address1}, ${props.contract.summary.address.city}, ${props.contract.summary.address.state} ${props.contract.summary.address.zip}`}
        </REText>
        <Button
          className="-ml-4"
          label="Back to search results"
          variant="ghost"
          size="small"
          color="interactive"
          startIcon={<IconNavArrowLeft />}
          onClick={handleBackToSearch}
        />
      </>
    );
  };

  const renderBuyerInfoSection = () => {
    return (
      <>
        <REText variant="heading-05" className="mt-4">
          Buyer Information
        </REText>
        <div className="-ml-4 -mr-4 w-auto">
          <FormNewOrderCustomer
            alternateMobileView={true}
            ids={null}
            names={{
              firstName: CustomerFields.firstName,
              lastName: CustomerFields.lastName,
              email: CustomerFields.email,
              phone: CustomerFields.phone,
            }}
            values={{
              firstName: contractData.buyerInfo.firstName,
              lastName: contractData.buyerInfo.lastName,
              email: contractData.buyerInfo.email,
              phone: contractData.buyerInfo.phone,
            }}
            errors={{
              firstName: errors.firstName,
              lastName: errors.lastName,
              email: errors.email,
              phone: errors.phone,
            }}
            required={{
              firstName: true,
              lastName: true,
              email: isBuyerContract(props.contract),
              phone: isBuyerContract(props.contract),
            }}
            onInputChange={onChangeInput}
            onInputBlur={handleBlur}
          />
        </div>
      </>
    );
  };

  const renderClosingInfoSection = () => {
    return (
      <>
        <REText variant="heading-05" className="mt-4">
          Closing Information
        </REText>
        <DateTimeInput
          name="projectedClosingDate"
          label={ORDER_FORM_FIELDS.PROJECTED_CLOSING_DATE}
          required={true}
          onlyDate={true}
          datePicker={true}
          autoComplete="off"
          selectedDate={contractData.closingInfo.projectedClosingDate}
          onDateSelect={onDateChangeHandler}
          onBlur={handleBlur}
          minDate={
            CLOSING_DATE_RANGE(parseDate(props.contract?.detail?.importantDates?.creationDate)).min
          }
          maxDate={
            CLOSING_DATE_RANGE(parseDate(props.contract?.detail?.importantDates?.creationDate)).max
          }
          error={errors.date}
        />
      </>
    );
  };

  const renderButtonSection = () => {
    return (
      <div className="flex flex-row justify-end mt-4 -mr-4">
        <div>
          <Button
            className="mr-4"
            label="Cancel"
            variant="ghost"
            size="medium"
            onClick={props.onClose}
          />

          <Button
            className="mr-4"
            label={props.saveButtonText}
            variant="filled"
            size="medium"
            color="interactive"
            onClick={handleSave}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {props.showTopSection && renderTopSection()}

      {!!contractData && (
        <>
          {props.showClosingInfoFirst && renderClosingInfoSection()}
          {renderBuyerInfoSection()}
          {!props.showClosingInfoFirst && renderClosingInfoSection()}
          {props.showCancelButton && renderButtonSection()}
        </>
      )}
    </>
  );
};

export default UpdateBuyerInfo;
