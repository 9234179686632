import { Button } from '@ftdr/blueprint-components-react';
import React from 'react';
import Modal, { ModalProps } from './Modal';
import REText from '@components/wrappedBDS/REText';

interface Props extends ModalProps {
  onClickDoLater: () => void;
  onClickAddPhone: () => void;
  isActive: boolean;
  onClose: () => void;
}
const ModalMissingBuyerPhone: React.FC<Props> = (props) => {
  const actions = [
    <Button
      key="cancel"
      size="medium"
      label="I'll add it later"
      width="full"
      labelAlign="center"
      onClick={props.onClickDoLater}
    />,
    <Button
      key="submit"
      size="medium"
      label="Add phone number"
      width="full"
      labelAlign="center"
      onClick={props.onClickAddPhone}
    />,
  ];

  return (
    <Modal
      id="modal-missing-buyer-phone"
      isActive={props.isActive}
      actions={actions}
      actionsAlign="center"
      onClose={props.onClose}
    >
      {props.isActive && (
        <div>
          <REText variant="body-short" className="inline">
            The buyer&apos;s phone number field is incomplete! Without this the buyer{' '}
          </REText>
          <REText color="secondary" variant="body-short" className="underline font-bold inline">
            may not receive
          </REText>
          <REText variant="body-short" className="inline">
            {' '}
            all necessary information on their warranty coverage and benefits. Are you sure you want
            to proceed?
          </REText>
        </div>
      )}
    </Modal>
  );
};

export default ModalMissingBuyerPhone;
