import React, { useEffect, useState } from 'react';
import { Notification, Link } from '@ftdr/blueprint-components-react';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';
import { classNames } from '@utils';

export const NotificationDismissalKeys = {
  WELCOME_DASHBOARD_BOTTOM_NOTIFICATION_DISMISSED_KEY:
    'new-dashboard-bottom-notification-dismissed',
  WELCOME_DASHBOARD_TOP_NOTIFICATION_DISMISSED_KEY: 'new-dashboard-top-notification-dismissed',
};

const NotificationInfo = (props) => {
  const [isNotificationDismissed, setIsNotificationDismissed] = useState(false);

  const dismissNotification = () => {
    localStorage.setItem(props.dismissalKey, 'true');
    setIsNotificationDismissed(true);
  };

  useEffect(() => {
    const isDismissalStored = localStorage.getItem(props.dismissalKey) === 'true';
    setIsNotificationDismissed(isDismissalStored);
  }, []);

  return (
    <>
      {isNotificationDismissed || !props.showNotification ? null : (
        <div className={props.className || 'mb-8'}>
          <Notification
            variant="floating"
            status="info"
            inline={true}
            showStatusLabel={false}
            className={classNames([
              IsTheme(Theme.Ahs2024) ? 'w-full' : 'max-w-full',
              'flex  flex-col',
            ])}
            onClose={dismissNotification}
          >
            <div className="flex flex-col md:flex-row items-baseline">
              {props.notificationContent && (
                <REText
                  variant="body-long"
                  className="zesty-html"
                  dangerouslySetInnerHTML={{ __html: props.notificationContent }}
                />
              )}
              {props.notificationData?.notification_url && (
                <Link
                  bold={true}
                  color="interactive"
                  href={props.notificationData?.notification_url}
                >
                  {props.notificationData?.link_text}
                </Link>
              )}
            </div>
          </Notification>
        </div>
      )}
    </>
  );
};

export default NotificationInfo;
