import React from 'react';
import { Button } from '@ftdr/blueprint-components-react';

export interface SearchButtonProps {
  id: string;
  isDisabled?: boolean;
  onClick: () => void;
  className: string;
}

export const SearchButton: React.FC<SearchButtonProps> = ({
  id,
  isDisabled,
  onClick,
  className,
}) => {
  return (
    <Button
      className={className}
      variant="filled"
      id={id}
      label="Search"
      size="medium"
      disabled={isDisabled}
      onClick={onClick}
      width="full"
    />
  );
};
