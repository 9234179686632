import { Link } from '@ftdr/blueprint-components-react';
import React from 'react';
import Path from '@constants/paths';
import { useNavigate } from 'react-router-dom';
import REText from '@components/wrappedBDS/REText';

const NHDCoverage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-4">
      <REText variant="caption">
        Natural Hazard Disclosure Report by American Home Shield satisfies Seller&apos;s legal
        disclosure requirements under the Natural Hazards Disclosure Act and includes:
      </REText>
      <ul className="list-disc ml-5 text-sm">
        <li>Natural hazard disclosure report</li>
        <li>Environmental hazard disclosure report</li>
        <li>Property tax disclosure report</li>
        <li>
          Exclusive for AHS customers &quot;Know Your Neighborhood&quot; disclosing neighborhood
          amenities
        </li>
        <li>
          Government Hazard booklets delivered with report and available for free download at
          www.shieldnhd.com
        </li>
        <li>Invoice paid from Seller&apos;s proceeds at the time escrow closes</li>
        <li>Superior parcel polygon technology</li>
        <li>Backed by AHS E&O insurance</li>
      </ul>

      <REText variant="caption">
        For questions contact our{' '}
        <Link onClick={() => navigate(Path.ContactUs)}>support team</Link>.
      </REText>
    </div>
  );
};

export default NHDCoverage;
