import React, { useEffect, useMemo, useState } from 'react';
import CardBox from '@components/card/CardBox';
import FormFieldset from '@components/form/FormFieldset';
import TableCheckboxes, { TableCheckboxOption } from '@components/table/TableCheckboxes';
import {
  ProductAndCoveragesResponse,
  ProductCoverage,
  ProductGroup,
  ProductPricingDetailsResponse,
  ProductPricingResponse,
} from '@apis/models';
import { SelectedPlanCoverages } from '@components/card/CardNewOrderPlansCoverage';
import { CoverageDependencyHandler } from '@apis/utils/coverage-dependencies';
import { coverageNameOverridesDictionary } from '@constants/dictionaries';
import { ZestyPlansAndPricesCoverage } from '@apis/models/zesty.api.model';
import ZestyApi from '@apis/zesty.apis';
import { getBrand } from '@helpers/brand.utils';
import { IsTheme, Theme } from '@app/core/featureToggle';

export interface FormNewOrderCoverageTablesProps {
  /** data of plans and coverages to load from */
  availablePlansAndCoverages: ProductAndCoveragesResponse[];
  /** data of prices to load from */
  planPricingData: ProductPricingResponse[];
  /** data of prices obtained for price summary, includes quantity and tier calculation */
  selectionPricingDetails: ProductPricingDetailsResponse;
  /** the selected plan and selected coverages to pull the correct data and to display the correct selection */
  selectedPlanCoverages: SelectedPlanCoverages;
  /** the selected guest unit to pull the correct data and to display the correct selection */
  selectedGuestUnit: boolean;
  /** enables the ability to add quantity for each coverage.  applicable to optional coverages only.  */
  enableQuantitySelection: boolean;
  /** event fired when an optional coverage was selected */
  onSelectOptionalCoverage: (cvg: ProductCoverage) => void;
  /** event fired when a group coverage was selected */
  onSelectGroupCoverage: (group: ProductGroup) => void;
  /** even fired when a guest unit was selected */
  onSelectGuestUnit: () => void;
  /** event fired when quantity was changed for a coverage */
  onQuantityChangeOptionalCoverage: (cvgId: string, qty: number) => void;
}

/** extension of the TableCheckboxOption to include the ids to help determine which tables the coverage goes to */
interface CoverageTableItem extends TableCheckboxOption {
  coverageId: string;
  groupId: string;
}

export const GUEST_UNIT_LABEL = coverageNameOverridesDictionary['Guest Unit'];

const FormNewOrderCoverageTables: React.FC<FormNewOrderCoverageTablesProps> = ({
  availablePlansAndCoverages,
  planPricingData,
  selectionPricingDetails,
  selectedPlanCoverages,
  selectedGuestUnit,
  ...props
}) => {
  const brand = getBrand();
  const [loadingZestyCoverageMeta, setLoadingZestyCoverageMeta] = useState(false);
  const [zestyCoverageMeta, setZestyCoverageMeta] = useState<ZestyPlansAndPricesCoverage[]>([]);

  useEffect(() => {
    loadZestyCoverageMeta();
  }, []);

  const loadZestyCoverageMeta = () => {
    setLoadingZestyCoverageMeta(true);
    ZestyApi.GetPlansAndPricesCoverages(brand)
      .then((data) => {
        setZestyCoverageMeta(data);
      })
      .catch((e) => {
        console.error('failed to load zesty coverage meta', e);
      })
      .finally(() => {
        setLoadingZestyCoverageMeta(false);
      });
  };

  const getMaxQuantityForCoverageID = (cvgID: string): number | null => {
    const zestyCoverage = zestyCoverageMeta?.find((c) => c.coverage_id === cvgID);
    return zestyCoverage?.max_quantity || null;
  };

  const selectGuestCoverage = (cvg) => {
    props.onSelectGuestUnit();
    props.onSelectOptionalCoverage(cvg);
  };

  const coverageHandler = new CoverageDependencyHandler();

  const sortCoverageItems = (a: CoverageTableItem, b: CoverageTableItem) => {
    return a.label.localeCompare(b.label);
  };

  /** get the data related to planAndCoverages from selection. Returns null if data not populated or no product selected. */
  const planAndCoverages: ProductAndCoveragesResponse = useMemo(() => {
    if (!selectedPlanCoverages?.product) {
      return null;
    }
    return availablePlansAndCoverages?.find(
      (plan) => plan.product.id === selectedPlanCoverages.product.id,
    );
  }, [selectedPlanCoverages, availablePlansAndCoverages]);

  /** get the data related to pricing from selection.  Returns null if data not populated or no product selected.  */
  const priceData: ProductPricingResponse = useMemo(() => {
    if (!selectedPlanCoverages?.product) {
      return null;
    }
    return planPricingData?.find(
      (data) => data.product.id === selectedPlanCoverages.product.starPVID,
    );
  }, [selectedPlanCoverages, planPricingData]);

  /** transforms optional coverages into coverage table items based on props */
  const optionalCoverageTableItems: CoverageTableItem[] = useMemo(() => {
    if (!planAndCoverages || !priceData) {
      return [];
    }

    const availableCoverages = zestyCoverageMeta.length > 0 ?
      planAndCoverages.coverages.optional.filter((cv) => {
        return zestyCoverageMeta.some((zestyData) => {
          return zestyData.coverage_id === cv.starCoverageId && zestyData.ishidden === "0";
        });
      })
      : planAndCoverages.coverages.optional;

    // add optional coverages
    return availableCoverages.map<CoverageTableItem>((cvg) => {
      const cvgPriceData = priceData.optionalCoverages.find(
        (cvgPrice) => cvg.starCoverageId === cvgPrice.id,
      );
      const cvgSelection = selectedPlanCoverages.optionalCoverages.find(
        (selectedCvg) => selectedCvg.id === cvg.starCoverageId,
      );
      const priceSummaryCvgPrice = selectionPricingDetails?.optionalCoverages.find(
        (cvgPrice) => cvg.starCoverageId === cvgPrice.id,
      )?.price;

      // coverage dependency should only apply to old RE1 products
      let disabled = false;
      if (planAndCoverages.product?.isOldRE1Product) {
        disabled = coverageHandler.shouldCoverageBeDisabled(
          cvg.starCoverageId,
          selectedPlanCoverages.optionalCoverages.map((cvg) => cvg.id),
        );
      }

      const isGuestUnit = cvg.name === GUEST_UNIT_LABEL; // disable quantity selection for guest unit

      const item: CoverageTableItem = {
        coverageId: cvg.starCoverageId,
        isDisabled: disabled,
        label: cvg.name,
        price:
          priceSummaryCvgPrice ||
          (selectedGuestUnit ? cvgPriceData?.priceWithGuest : cvgPriceData?.price) ||
          0,
        groupId: null,
        selected: isGuestUnit ? selectedGuestUnit : !!cvgSelection,
        quantity: cvgSelection?.quantity || 0,
        maxQuantity: getMaxQuantityForCoverageID(cvg.starCoverageId),
        onSelect: () =>
          isGuestUnit ? selectGuestCoverage(cvg) : props.onSelectOptionalCoverage(cvg),
        onQuantityChange: (_, qty) =>
          props.onQuantityChangeOptionalCoverage(cvg.starCoverageId, qty),
        quantityEnabled: props.enableQuantitySelection,
        meta: {
          cvgType: 'optional',
        },
      };
      return item;
    });
  }, [
    planAndCoverages,
    priceData,
    selectionPricingDetails,
    selectedPlanCoverages,
    selectedGuestUnit,
    props.enableQuantitySelection,
    props.onQuantityChangeOptionalCoverage,
    props.onSelectOptionalCoverage,
    zestyCoverageMeta,
  ]);

  /** transforms group coverages into coverage table items based on props */
  const groupCoverageTableItems: CoverageTableItem[] = useMemo(() => {
    if (!planAndCoverages || !priceData) {
      return [];
    }
    // add group coverages
    return planAndCoverages.coverages.group.map<CoverageTableItem>((cvg) => {
      const cvgPriceData = priceData.groupCoverages.find(
        (cvgPrice) => cvg.starGroupId === cvgPrice.id,
      );
      const cvgSelection = selectedPlanCoverages.groupCoverages.find(
        (selectedCvg) => selectedCvg.id === cvg.starGroupId,
      );

      const item: CoverageTableItem = {
        coverageId: null,
        isDisabled: false, // group coverage don't have dependency rules
        label: cvg.name,
        price: (selectedGuestUnit ? cvgPriceData?.priceWithGuest : cvgPriceData?.price) || 0,
        groupId: cvg.starGroupId,
        selected: !!cvgSelection,
        quantity: cvgSelection?.quantity || 0,
        maxQuantity: getMaxQuantityForCoverageID(cvg.starGroupId),
        onSelect: () => props.onSelectGroupCoverage(cvg),
        onQuantityChange: null,
        quantityEnabled: false, // group coverage should not be allowed to have additional qty
        meta: {
          cvgType: 'group',
        },
      };
      return item;
    });
  }, [
    planAndCoverages,
    priceData,
    selectedPlanCoverages,
    selectedGuestUnit,
    props.onSelectGroupCoverage,
  ]);

  /** transforms guest unit into coverage table items based on props.
   * NOTE: THIS IS FOR OLD RE1 PRODUCTS.  We want to remove this once we do not support old RE1 products anymore. */
  const oldProductGuestUnitTableItems: CoverageTableItem[] = useMemo(() => {
    if (!priceData) {
      return [];
    }

    const items: CoverageTableItem[] = [];

    if (priceData && priceData.guestUnitPrice !== 0) {
      items.push({
        coverageId: null,
        isDisabled: false, // never disabled
        label: GUEST_UNIT_LABEL,
        price: priceData.guestUnitPrice,
        groupId: null,
        selected: selectedGuestUnit,
        quantity: selectedGuestUnit ? 1 : 0,
        onSelect: () => props.onSelectGuestUnit(),
        meta: {
          cvgType: 'oldProductGuestUnit',
        },
      });
    }

    return items;
  }, [priceData, selectedGuestUnit, props.onSelectGuestUnit]);

  /** sellers coverage items to display on the table */
  const sellersCoverageTableItems: CoverageTableItem[] = useMemo(() => {
    return [
      ...optionalCoverageTableItems.filter((cvg) =>
        coverageHandler.isSellersCoverageByCvgId(cvg.coverageId),
      ),
      ...groupCoverageTableItems.filter((cvg) =>
        coverageHandler.isSellersCoverageByGroupId(cvg.groupId),
      ),
    ].sort(sortCoverageItems);
  }, [optionalCoverageTableItems, groupCoverageTableItems]);

  /** non-sellers coverage items to display on the table */
  const coverageTableItems: CoverageTableItem[] = useMemo(() => {
    return [
      ...optionalCoverageTableItems.filter(
        (cvg) => !coverageHandler.isSellersCoverageByCvgId(cvg.coverageId),
      ),
      ...groupCoverageTableItems.filter(
        (cvg) => !coverageHandler.isSellersCoverageByGroupId(cvg.groupId),
      ),
      ...oldProductGuestUnitTableItems,
    ].sort(sortCoverageItems);
  }, [optionalCoverageTableItems, groupCoverageTableItems, oldProductGuestUnitTableItems]);

  return (
    <>
      {selectedPlanCoverages?.product && (
        <>
          {sellersCoverageTableItems.length > 0 && (
            <div className="pt-6">
              <CardBox heading="Select Seller's Coverage Options" className="sm-max:mx-1">
                <div className="w-full">
                  <FormFieldset>
                    <TableCheckboxes
                      id="card-new-order-plans-coverage__sellers-coverage-options-form"
                      className=""
                      items={sellersCoverageTableItems as TableCheckboxOption[]}
                    />
                  </FormFieldset>
                </div>
              </CardBox>
            </div>
          )}
          {coverageTableItems.length > 0 && (
            <div className="pt-6">
              <CardBox heading="Select Add-ons" className={`${IsTheme(Theme.Ahs2024) ? 'pl-6' : ''} sm-max:mx-1`}>
                <div className="w-full ml-2 pr-4">
                  <TableCheckboxes
                    id="card-new-order-plans-coverage__optional-coverage-options-form"
                    className="sm-max:-mx-4"
                    items={coverageTableItems as TableCheckboxOption[]}
                  />
                </div>
              </CardBox>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FormNewOrderCoverageTables;
