import React, { Component } from 'react';
import { isWarrantyLinkBroker } from '@services/helpers/profile.offices.helper';
import AdminApi from '@apis/admin.api';
import { AuthType, UserDetails } from '@app/models/profile.model';
import { EditButton } from '@components/button/EditButton';
import ProfileApi from '@apis/profile.api';
import { isEmailValid } from '@services/validation/ValidationRules';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import ModalSiteAdminRetrievePasswordResetLink from '@components/modal/ModalSiteAdminRetrievePasswordResetLink';
import { Button, Input, Link, Panel, Select } from '@ftdr/blueprint-components-react';
import ModalChoice from '@components/modal/ModalChoice';
import msgs from '@app/locales/en';
import { accountToOfficeTypeDictionary } from '@constants/dictionaries';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';
import { classNames } from '@utils';
import LineText from '@components/admin/LineText';

interface state {
  passwordLink: string;
  isModalActive: boolean;
  isEditingAgent: boolean;
  newAgent: string;
  isEditingEmail: boolean;
  newEmail: string;
  isNewEmailValid: boolean;
  isEditingUserType: boolean;
  newUserType: string;
  showDeleteModal: boolean;
}

interface props {
  user: UserDetails;
  updateUser: any;
  removeUser: any;
}

export default class ViewUserDetails extends Component<props, state> {
  constructor(props) {
    super(props);
    this.state = {
      passwordLink: '',
      isModalActive: false,
      isEditingAgent: false,
      newAgent: props.user.agentID,
      isEditingEmail: false,
      newEmail: props.user.email,
      isNewEmailValid: true,
      isEditingUserType: false,
      newUserType: props.user.userType,
      showDeleteModal: false,
    };
  }

  userTypeSelectOptions = (oldType: string) => {
    const officeType = accountToOfficeTypeDictionary[oldType];
    return Object.keys(accountToOfficeTypeDictionary)
      .filter((type) => accountToOfficeTypeDictionary[type] == officeType)
      .map((type) => {
        return {
          id: `user-type-${type}`,
          label: type,
          value: type,
        };
      });
  };

  getPasswordResetLink = () => {
    AdminApi.getPasswordRestLink(this.props.user.email, this.props.user.brand).then((res) => {
      this.setState({ passwordLink: res, isModalActive: true });
    });
  };

  toggleModal = () => {
    this.setState({ isModalActive: !this.state.isModalActive });
  };

  handleAgentChange = (event) => {
    this.setState({ newAgent: event.target.value });
  };

  toggleEditAgent = () => {
    this.setState({
      newAgent: this.props.user.agentID,
      isEditingAgent: !this.state.isEditingAgent,
    });
  };

  saveAgentId = () => {
    ProfileApi.adminUpdateProfile({
      profileID: this.props.user.profileID,
      roleID: this.state.newAgent,
    } as any)
      .then((response) => {
        if (!response) {
          // We only respond on an error, so only update if we don't have a response
          const tempUser = this.props.user;
          tempUser.agentID = this.state.newAgent;
          this.props.updateUser(tempUser);
        }
        this.toggleEditAgent();
      })
      .catch((e) => {
        this.toggleEditAgent();
        console.error(e);
      });
  };

  handleUserTypeChange = (event) => {
    this.setState({ newUserType: event.value });
  };

  toggleEditUserType = () => {
    this.setState({
      newUserType: this.props.user.userType,
      isEditingUserType: !this.state.isEditingUserType,
    });
  };

  saveUserType = () => {
    ProfileApi.adminUpdateProfile({
      profileID: this.props.user.profileID,
      roleIDType: this.state.newUserType,
    } as any)
      .then((response) => {
        if (!response) {
          // We only respond on an error, so only update if we don't have a response
          const tempUser = this.props.user;
          tempUser.userType = this.state.newUserType;
          this.props.updateUser(tempUser);
        }
        this.toggleEditUserType();
      })
      .catch((e) => {
        const errors = e.response.data.errors?.map((e) => e.message).join(', ');
        useGlobalAlert().addErrorToQueue({
          message: `The user type could not be updated due to: ${errors}`,
        });
      });
  };

  handleEmailChange = (event) => {
    this.setState({
      newEmail: event.target.value,
      isNewEmailValid: isEmailValid(event.target.value),
    });
  };

  toggleEditEmail = () => {
    this.setState({
      newEmail: this.props.user.email,
      isEditingEmail: !this.state.isEditingEmail,
      isNewEmailValid: isEmailValid(this.props.user.email),
    });
  };

  saveEmail = () => {
    ProfileApi.adminUpdateProfile({
      profileID: this.props.user.profileID,
      email: this.state.newEmail,
    } as any)
      .then((response) => {
        if (!response) {
          // We only respond on an error, so only update if we don't have a response
          const tempUser = this.props.user;
          tempUser.email = this.state.newEmail;
          this.props.updateUser(tempUser);
        }
        this.toggleEditEmail();
      })
      .catch((e) => {
        const errors = e.response.data.errors?.map((e) => e.message).join(', ');
        useGlobalAlert().addErrorToQueue({
          message: `The users email could not be updated due to: ${errors}`,
        });
      });
  };

  deleteUser = () => {
    AdminApi.deleteFusionAuth(this.props.user).then((success) => {
      this.setState({ showDeleteModal: false });

      if (success) {
        useGlobalAlert().addSuccessToQueue(msgs.DELETE_FA_USER_SUCCESS(this.props.user.faEmail));
        this.props.removeUser(this.props.user.userID);
      } else {
        useGlobalAlert().addErrorToQueue(msgs.DELETE_FA_USER_FAILURE(this.props.user.faEmail));
      }
    });
  };

  render() {
    const { user } = this.props;
    const {
      isModalActive,
      passwordLink,
      isEditingAgent,
      newAgent,
      isEditingEmail,
      newEmail,
      isNewEmailValid,
      isEditingUserType,
      newUserType,
    } = this.state;

    const userTypeOptions = this.userTypeSelectOptions(newUserType);
    const selectedUserTypeOption = userTypeOptions.find((option) => option.value == newUserType);

    return (
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-3/4 md-max:mb-4 px-4">
          <REText
            variant="heading-04"
            id="orderDetail.header.address"
            className={IsTheme(Theme.Ahs2024) ? 'mb-4' : ''}
          >
            {user.firstName} {user.lastName}
          </REText>
          {IsTheme(Theme.Ahs2024) ? (
            <Link
              id="pwd-reset"
              bold={true}
              color="interactive"
              onClick={this.getPasswordResetLink}
            >
              Reset Password
            </Link>
          ) : (
            <Button
              variant="filled"
              id="pwd-reset"
              label="PW Reset Link"
              size="small"
              onClick={this.getPasswordResetLink}
            />
          )}
          {!user.profileID && user.authIDType === AuthType.FusionAuth && user.authID && (
            <Button
              variant="filled"
              id="delete-user"
              label="Delete User"
              size="small"
              color="error"
              className="ml-4"
              onClick={() => this.setState({ showDeleteModal: true })}
            />
          )}
          <Panel
            className="mt-6 flex flex-col"
            borderColor="gray-300"
            border="2"
            padding="xl"
            rounded="xl"
            shadow={false}
          >
            <Input
              type="text"
              id="user_email"
              name="Email"
              label="Email"
              placeholder="Email"
              onChange={this.handleEmailChange}
              onBlur={null}
              value={newEmail}
              disabled={!isEditingEmail}
              error={isNewEmailValid ? '' : 'Valid email required'}
              formField={true}
              formFieldMessageId="email-error"
              className="w-full"
            />
            <div className="flex justify-end">
              <EditButton
                id="wb_admin-email-edit"
                isEditing={isEditingEmail}
                isDisabled={false}
                onClick={this.toggleEditEmail}
              />
              {isEditingEmail && (
                <Button
                  className="mt-5 ml-4"
                  variant="outlined"
                  id="wb_admin-email-save"
                  label="Save"
                  size="small"
                  onClick={this.saveEmail}
                  disabled={!isNewEmailValid}
                />
              )}
            </div>
          </Panel>
          <Panel
            className="mt-6"
            borderColor="gray-300"
            border="2"
            padding="xl"
            rounded="xl"
            shadow={false}
          >
            <LineText
              className="mt-4"
              boldText="Web ID: "
              text={user.faEmail}
              boldTextId="user-detail-label-webId"
              textId="user-detail-webId"
            />
            <LineText
              className="mt-4"
              boldText="Verified: "
              text={user.emailVerified.toString()}
              boldTextId="user-detail-label-emailVerified"
              textId="user-detail-emailVerified"
            />
            <LineText
              className="mt-4"
              boldText="Phone Number: "
              text={user.phone}
              boldTextId="user-detail-label-phone"
              textId="user-detail-phone"
            />
            <LineText
              className="mt-4"
              boldText="User ID: "
              text={user.userID}
              boldTextId="user-detail-label-userId"
              textId="user-detail-userId"
            />
          </Panel>
          <Panel
            className="mt-6 flex flex-col"
            borderColor="gray-300"
            border="2"
            padding="xl"
            rounded="xl"
            shadow={false}
          >
            <Select
              label="User Type"
              formField={true}
              placeholder="User Type"
              selected={selectedUserTypeOption}
              onSelect={this.handleUserTypeChange}
              options={userTypeOptions}
              disabled={!isEditingUserType}
            />
            <div className="flex justify-end">
              <EditButton
                id="wb_admin-usertype-edit"
                isEditing={isEditingUserType}
                isDisabled={false}
                onClick={this.toggleEditUserType}
              />
              {isEditingUserType && (
                <Button
                  className="mt-5 ml-4"
                  variant="outlined"
                  id="wb_admin-save-usertype"
                  label="Save"
                  size="small"
                  onClick={this.saveUserType}
                />
              )}
            </div>
          </Panel>
          <Panel
            className="mt-6 flex flex-col"
            borderColor="gray-300"
            border="2"
            padding="xl"
            rounded="xl"
            shadow={false}
          >
            <Input
              type="text"
              id="wb_admin-agent"
              name="Agent ID"
              placeholder="Agent ID"
              label="Agent ID"
              onChange={this.handleAgentChange}
              onBlur={null}
              value={newAgent}
              disabled={!isEditingAgent}
              formField={true}
              className="w-full"
            />
            <div className="flex justify-end">
              <EditButton
                id="wb_admin-agent-edit"
                isEditing={isEditingAgent}
                isDisabled={false}
                onClick={this.toggleEditAgent}
              />
              {isEditingAgent && (
                <Button
                  className="mt-5 ml-4"
                  variant="outlined"
                  id="wb_admin-save-agent"
                  label="Save"
                  size="small"
                  onClick={this.saveAgentId}
                />
              )}
            </div>
          </Panel>

          <Panel
            id="offices"
            className="mt-6"
            borderColor="gray-300"
            border="2"
            padding="xl"
            rounded="xl"
            shadow={false}
          >
            <div className="flex flex-row">
              <div className="w-1/3">
                <REText id="offices-label" className="max-w-24" variant="heading-06">
                  Offices:
                </REText>
              </div>
              <div className="w-2/3">
                <ul>
                  {user.offices &&
                    user.offices.map((office) => (
                      <li key={office.id}>
                        <REText id={'office-' + office.id} variant="body-short">
                          {office.type} {office.id}
                        </REText>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <LineText
              className="mt-4"
              boldText="Registration Date: "
              text={user.registrationDate}
              boldTextId="user-detail-label-registrationDate"
              textId="user-detail-registrationDate"
            />
            <LineText
              className="mt-4"
              boldText="Brand: "
              text={user.brand}
              boldTextId="user-detail-label-brand"
              textId="user-detail-brand"
            />
            <LineText
              className="mt-4"
              boldText="Last Active: "
              text={user.lastActiveDate}
              boldTextId="user-detail-label-lastActiveDate"
              textId="user-detail-lastActiveDate"
            />
            <LineText
              className="mt-4"
              boldText="Last Password Update: "
              text={user.lastPasswordUpdate || 'None'}
              boldTextId="user-detail-label-lastPasswordUpdate"
              textId="user-detail-lastPasswordUpdate"
            />
            <LineText
              className="mt-4"
              boldText="Warranty Link Broker: "
              text={isWarrantyLinkBroker(user as any).toString()}
              boldTextId="user-detail-label-warrantyLinkBroker"
              textId="user-detail-warrantyLinkBroker"
            />
          </Panel>
        </div>
        <ModalChoice
          id="delete-user-modal"
          heading="Are you sure you want to delete this user’s account?"
          onConfirm={this.deleteUser}
          onClose={() => this.setState({ showDeleteModal: false })}
          isActive={this.state.showDeleteModal}
          yesText="Delete it"
          noText="Keep it"
        />
        <ModalSiteAdminRetrievePasswordResetLink
          isActive={isModalActive}
          onClose={this.toggleModal}
          passwordLink={passwordLink}
        />
      </div>
    );
  }
}
