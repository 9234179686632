import React, { useState } from 'react';
import { addressToString } from '@services/helpers';
// Components
import { Link, Table } from '@ftdr/blueprint-components-react';
import { formatDateFromString } from '@helpers/utils';
import ModalWlkContractDetails from '@components/modal/ModalWlkContractDetails';
import REText from '@components/wrappedBDS/REText';

/**
 * Props:
 *   - skeleton: if true, then show a skeleton load
 */
const AccordionItemPayment = (props) => {
  const [selectedContract, setSelectedContract] = useState(null);
  const [showContractModal, setShowContractModal] = useState(false);

  function onClickContract(selectedContractId) {
    const selectedContract = props.rows.find((contract) => contract.id === selectedContractId);

    if (selectedContract) {
      setSelectedContract(selectedContract);
      toggleModal();
    }
  }

  function toggleModal() {
    if (selectedContract && showContractModal) {
      setSelectedContract(null);
    }
    setShowContractModal(!showContractModal);
  }

  const columns = [
    {
      Header: <REText variant="heading-06">Plan #</REText>,
      accessor: 'id',
      Cell: ({ value }) => (
        <Link color="interactive" onClick={() => onClickContract(value)}>
          {value}
        </Link>
      ),
    },
    {
      Header: <REText variant="heading-06">Covered Property Address</REText>,
      accessor: 'address', // accessor is the "key" in the data
      Cell: ({ value }) => (
        <REText variant="caption">
          {addressToString(value?.address1, value?.address2, value?.city, value?.state, value?.zip)}
        </REText>
      ),
    },
    {
      Header: <REText variant="heading-06">Submission Date</REText>,
      accessor: 'submissionDate',
      Cell: ({ value }) => <REText variant="caption">{formatDateFromString(value)}</REText>,
    },
    {
      Header: <REText variant="heading-06">Agent</REText>,
      accessor: 'agent',
      Cell: ({ value }) => <REText variant="caption">{value}</REText>,
    },
    {
      Header: <REText variant="heading-06">Office Address</REText>,
      accessor: 'officeAddress',
      Cell: ({ value }) => (
        <REText variant="caption">
          {addressToString(value?.address1, value?.address2, value?.city, value?.state, value?.zip)}
        </REText>
      ),
    },
    {
      Header: <REText variant="heading-06">Amount</REText>,
      accessor: 'amount',
      Cell: ({ value }) => <REText variant="caption">{value}</REText>,
    },
  ];

  return (
    <>
      <Table
        data={props.rows}
        columns={columns}
        striped="gray"
        variant="light"
        sortable={false}
        paginate={false}
        selectable={false}
      />
      {showContractModal && selectedContract && (
        <ModalWlkContractDetails
          isActive={showContractModal}
          contract={selectedContract}
          onClose={toggleModal}
        />
      )}
    </>
  );
};

export default AccordionItemPayment;
