import React from 'react';
import './autosuggest.css';
import { Button } from '@ftdr/blueprint-components-react';
import REText from '@components/wrappedBDS/REText';

export const NOT_APPLICABLE_OPTION = {
  firstName: 'Not',
  lastName: 'Applicable',
  realEstateAgentID: '',
  phoneNumbers: [],
};

/** empty option to force suggestions to display */
export const EMPTY_OPTION = {
  empty: true,
};
/** Returns the list of suggestions, including or excluding the NA option at start. */
export const getSuggestions = (agentList, includeNAOption) => {
  let suggestions = agentList || [];
  if (includeNAOption) {
    suggestions = [NOT_APPLICABLE_OPTION, ...suggestions];
  }
  return suggestions;
};

/** used to remove all non-agent suggestions from the list. */
export const filterSuggestionsListWithoutActions = (suggestions) => {
  return suggestions?.filter((s) => s && s !== NOT_APPLICABLE_OPTION && s !== EMPTY_OPTION) || [];
};

export const onFetchSuggestions = (value, agentsData, setTypeAheadAddress) => {
  let filteredSuggestions = [];
  if (agentsData && agentsData.length > 0) {
    filteredSuggestions = filteredSuggestions.concat(agentsData);
  }
  if (value && value?.trim() !== '' && agentsData) {
    const val = value?.toLowerCase().trim();
    filteredSuggestions = agentsData.filter((agent) => {
      const stringToCheckMatch = `${agent.firstName.toLowerCase()} ${agent.lastName.toLowerCase()}`;
      if (stringToCheckMatch.includes(val)) return agent;

      return '';
    });
    if (filteredSuggestions.length) {
      // condition to show only 10 records in DD
      filteredSuggestions = filteredSuggestions.slice(0, 10);

      // show DD list sorted by Firstname of agent
      filteredSuggestions.sort((agent1, agent2) => {
        if (agent1.firstName.toLowerCase() > agent2.firstName.toLowerCase()) return 1;
        if (agent2.firstName.toLowerCase() > agent1.firstName.toLowerCase()) return -1;
        return 0;
      });
    }
  }

  // if there are no items on the array add a null value to force the auto complete
  // to show the container with the add new button
  if (filteredSuggestions.length === 0) {
    filteredSuggestions.push(EMPTY_OPTION);
  }

  setTypeAheadAddress(filteredSuggestions);
  return filteredSuggestions;
};

export const onClearSuggestions = (setTypeAheadAddress) => {
  setTypeAheadAddress([]);
};

export const selectSuggestion = (e, option, changeSelectedAgent, setValues, values) => {
  if (!option.suggestion.empty) {
    // TODO: ARE-5132, should handle the NOT_APPLICABLE_OPTION properly and not like this
    // if (option.suggestion === NOT_APPLICABLE_OPTION) { /* Do this */ }

    const ddOption = `${option.suggestion.firstName} ${option.suggestion.lastName}`;
    const agentId = !option.suggestion.realEstateAgentID ? '' : option.suggestion.realEstateAgentID;
    const newValues = {
      ...values,
      AgentName: ddOption,
      AgentEmail: option.suggestion.email,
      AgentPhone:
        option.suggestion.phoneNumbers.length > 0 ? option.suggestion.phoneNumbers[0] : '',
      AgentId: agentId,
      office: {
        id: option.suggestion.officeID,
        type: option.suggestion.agentType, // TODO: ARE-7105 this office set should be done in the individual cards when office is selected, not when agent selected.
      },
      initAgentId: agentId,
    };
    setValues(newValues);
    changeSelectedAgent(ddOption);
  }
};

/**
 * Clears the the values set to treat as if no agent was selected.
 * See what is entered in selectSuggestion for newValues
 */
export const clearSelectionValues = (values, setValues) => {
  const newValues = {
    ...values,
    AgentEmail: '',
    AgentId: '',
  };
  setValues(newValues);
};

export const getSuggestion = (suggestion) => {
  return suggestion;
};

function BoldedText(text, shouldBeBold) {
  const textArray = text.split(RegExp(shouldBeBold, 'ig'));
  const match = text.match(RegExp(shouldBeBold, 'ig'));

  return (
    <span>
      {textArray.map((item, index) => (
        <>
          {item}
          {index !== textArray.length - 1 && match && <b>{match[index]}</b>}
        </>
      ))}
    </span>
  );
}

export const renderSuggestion = (suggestion, { isHighlighted }, office, value, idPrefix) => {
  return (
    suggestion != EMPTY_OPTION && (
      <div className="p-4 border-b border-gray-300">
        <div className="pb-2">
          {value !== '' ? (
            BoldedText(`${suggestion.firstName} ${suggestion.lastName}`, value)
          ) : (
            <REText id={`${idPrefix}-name-${suggestion.realEstateAgentID}`} variant="label">{`${suggestion.firstName} ${suggestion.lastName}`}</REText>
          )}
        </div>
        <div>
          {suggestion !== NOT_APPLICABLE_OPTION && (
            <REText id={`${idPrefix}-office-${suggestion.realEstateAgentID}`} variant="helper-text">{office || ' '}</REText>
          )}
          <REText id={`${idPrefix}-email-${suggestion.realEstateAgentID}`} variant="helper-text">{suggestion.email || ' '}</REText>
        </div>
      </div>
    )
  );
};

export const renderSuggestionsContainer = (
  { containerProps, children },
  suggestions,
  setIsAddAgentModalActive,
  changeSelectedAgent,
  isAddAgentEnabled,
  buttonText,
  subText,
  noResultsFoundHelperText,
  hasNoSuggestions,
) => (
  <div {...containerProps}>
    <div className="card card-autosuggest w-full">
      <div className="auto-suggest-li">{children}</div>

      {hasNoSuggestions && noResultsFoundHelperText ? (
        <div className="my-2 mx-1 p-2">
          <REText variant="label">No Results Found</REText>
          <div>
            <REText variant="helper-text">{noResultsFoundHelperText}</REText>
          </div>
        </div>
      ) : null}

      <div className="my-2 mx-1 p-2">
        {isAddAgentEnabled && (
          <Button
            id="wb_addAgentBtn"
            variant="outlined"
            size="small"
            label={buttonText || 'Add New Agent'}
            onClick={() => {
              setIsAddAgentModalActive(true);
              changeSelectedAgent(''); // Clear the selected agent to close the autosuggest container
            }}
          />
        )}
        {subText && (
          <REText variant="helper-text" className="italic pt-2">
            Invite the cooperating agent to associate themselves to the order
          </REText>
        )}
      </div>
    </div>
  </div>
);

export const onSuggestionTextChange = (e, changeSelectedAgent, agentsData, setTypeAheadAddress) => {
  const { value } = e.target;
  changeSelectedAgent(value);
  onFetchSuggestions(value, agentsData, setTypeAheadAddress);
};
