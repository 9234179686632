import React, { useContext, useEffect, useState } from 'react';
import { classNames } from '@utils';
import Accordion from '@components/accordion/Accordion';
import AccordionItemWarranty from '@components/accordion/AccordionItemWarranty';
import TagGroup from '@components/tag/TagGroup';
import ModalLegalConfirmation from '@components/modal/ModalLegalConfirmation';
import WarrantylinkService from '@services/apis/warrantylink.api';
import ProfileContext from '../../context/ProfileContext/index';
import {
  Contract,
  CustomerData,
  REOrder,
  Warrantylink,
  WarrantylinkContract,
  WarrantylinkFormData,
} from '@apis/models';
import { addressToString } from '@services/helpers';
import { formatDateFromString, formatDateToISO8601 } from '@app/helpers/utils';
import AgentApi from '@apis/agent.api';
import { isEmpty } from 'lodash';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  NAVIGATION__ORDER_FILTER_SORT,
  WLK_SUBMIT_BULK,
  WLK_SUBMIT_SINGLE,
} from '@constants/ga-events.constants';
import { ProfileOffice } from '@app/models/profile.model';
import useGlobalAlert, { Message } from '@app/core/GlobalAlertModal';
import { WarrantylinkContractData } from '@services/apis/models/warrantylink.api.model';
import msgs from '@app/locales/en';
import { Badge, Button, Dialog, Tag } from '@ftdr/blueprint-components-react';
import { useWindowSize } from 'react-use';
import { ContentBox } from '@components/layout/ContentBox';
import ListFiltering from '@components/misc/ListFiltering';
import { StatusMenuType } from '@components/filter/OrderStatusFilter';
import { FilterOperation, FilterType, WarrantylinkStatus } from '@constants/dashboardFilters';
import { officeTypeDictionary } from '@constants/dictionaries';
import { WarrantyLinkFilter } from '@components/filter/WarrantyLinkFilter';
import { hasContractPermissions } from '@helpers/permissions.utils';
import { Permissions } from '@constants/dictionaries';
import cloneDeep from 'lodash/cloneDeep';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

export interface WLKContract extends Contract {
  warrantylink: Warrantylink;
}

export interface REOrderWithBuyerAndSellerInfo extends REOrder {
  form?: WarrantylinkFormData;
}

interface WarrantyLinkTemplateProps {
  summaryView: REOrderWithBuyerAndSellerInfo[];
  isLoading: boolean;
  canSubmitContracts: boolean;
  refreshWarrantyLinkData: (contractIDs: string[]) => Promise<void>;
  wlkOfficeList: ProfileOffice[];
}

const SKELETON_LOAD_CNT = 10;

const WarrantyLinkTemplate: React.FC<WarrantyLinkTemplateProps> = (props) => {
  const { addErrorToQueue, addSuccessToQueue } = useGlobalAlert();
  const { profile } = useContext(ProfileContext);
  const [isLegalModalActive, setIsLegalModalActive] = useState(false);
  const [selectedContractIDs, setSelectedContractIDs] = useState<string[]>([]);
  const [isContractSelected, setIsContractSelected] = useState(false);
  const [legalModalOffice, setLegalModalOffice] = useState<Partial<REOrder>>({
    id: 0,
    initiatingOfficeName: '',
    initiatingAgentName: '',
  });
  const [warrantyLinkContracts, setWarrantyLinkContracts] = useState<REOrder[]>([]);
  const [warrantyLinkContractsFiltered, setWarrantyLinkContractsFiltered] = useState<
    REOrderWithBuyerAndSellerInfo[]
  >([]);
  const [contractFormsErrors, setContractFormsErrors] = useState<string[]>([]);
  const [filterList, setFilterList] = useState<WarrantyLinkFilter[]>([]);
  const [agentList, setAgentList] = useState<any[]>([]);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  const [editingContractIDs, setEditingContractIDs] = useState([]);
  const [canEditWLKContracts, setCanEditWLKContracts] = useState(false);

  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(width < 640);
  const wlkRequiredPermissions = [Permissions.WLKSubmit];

  const INCOMPLETE_FORM_NOTIFICATION = (invalidContractIDs: string[]): Message => {
    return {
      id: 'modal--wlk-incomplete-form',
      messageHTML: (
        <p>
          Contracts <b>{invalidContractIDs.join(', ')}</b> have incomplete data. You must have
          either buyer or seller information fully completed to submit the contract. Please update
          the information and resubmit.
        </p>
      ),
    };
  };

  useEffect(() => {
    setIsMobile(width < 640);
    hasContractPermissions(wlkRequiredPermissions).then((result) => {
      setCanEditWLKContracts(result);
    });
  }, [width]);

  useEffect(() => {
    // Deal with lookup quicker
    const warrantylinkDataMap = {};
    for (const data of props.summaryView) {
      warrantylinkDataMap[data.id] = data;
    }

    // Combine contract data with warrantylink data
    const mappedData = props.summaryView.map<REOrderWithBuyerAndSellerInfo>((c) => ({
      id: c.id,
      tableId: c.id,
      address: {
        address1: c.address?.address1,
        address2: c.address?.address2,
        city: c.address?.city,
        state: c.address?.state,
        zip: c.address?.zip,
      },
      expirationDate: c.expirationDate,
      initiatingAgentName: c.initiatingAgentName,
      initiatingOfficeName: c.initiatingOfficeName,
      initiatingAgentID: c.initiatingAgentID,
      initiatingOfficeID: c.initiatingOfficeID,
      status: c.status,
      realEstateStatus: c.realEstateStatus,
      awaitingWlkSubmission: c.awaitingWlkSubmission,
      warrantylink: {
        status: c.warrantylink.status,
        deadlineDate: c.warrantylink.deadlineDate,
        submissionDate: c.warrantylink.submissionDate,
      },
      form: c.form,
    }));

    const initialContracts = [];
    mappedData.forEach((contract) => {
      const wlkOfficeInProfile = props.wlkOfficeList.some(
        (office) => office.id === contract.initiatingOfficeID,
      );
      if (wlkOfficeInProfile) {
        initialContracts.push(contract);
      }
    });

    setWarrantyLinkContracts(initialContracts);
    setWarrantyLinkContractsFiltered(initialContracts);
  }, [props.summaryView]);

  /** Update select/deselect all if selected contracts was changed */
  useEffect(() => {
    setIsContractSelected(selectedContractIDs.length > 0);
  }, [selectedContractIDs]);

  // update selected contracts based on changing filtered list, which changes based on selected filters
  useEffect(() => {
    if (isContractSelected) {
      const allSubmitableContracts = warrantyLinkContractsFiltered.filter(
        (contract) =>
          ['Ready', 'Saved'].includes(contract.warrantylink.status) &&
          selectedContractIDs.includes(contract.id?.toString()),
      );

      if (allSubmitableContracts.length > 0) {
        const contractIDs = allSubmitableContracts.map((submitableContract) =>
          submitableContract.id?.toString(),
        );
        // setLegalModalOffice(allSubmitableContracts[0]);
        setSelectedContractIDs(contractIDs);
      } else {
        setSelectedContractIDs([]);
      }
    }
  }, [warrantyLinkContractsFiltered]);

  useEffect(() => {
    filterWarrantylinkContracts();
  }, [filterList, warrantyLinkContracts]);

  const addOrRemoveContractSelection = (contract: REOrderWithBuyerAndSellerInfo) => {
    if (contract) {
      const id = contract.id?.toString();
      if (selectedContractIDs.includes(id)) {
        const filteredContracts = selectedContractIDs.filter((i) => i != id);
        setSelectedContractIDs(filteredContracts);
      } else {
        setSelectedContractIDs([...selectedContractIDs, id]);
      }
    } else {
      console.error('no contract was returned from selection');
    }
  };

  /** Checks if a single form data is completed.
   * Considered completed if all fields are filled, i.e. first name, last name, email, and phone.
   */
  const isCustomerFormCompleted = (data: CustomerData): boolean => {
    return Boolean(data.firstName && data.lastName && data.email && data.phone);
  };

  /** Checks to see if contract is ready for submission based on the form data.
   * @returns true if valid for submission. Otherwise false.
   */
  const validateContractReadyForSubmission = (contractID: string): boolean => {
    const contract = props.summaryView.find((c) => c.id.toString() === contractID);

    // Contract not found
    if (!contract) {
      return false;
    }

    // Check for complete form data
    const { buyer, seller } = contract.form;
    return isCustomerFormCompleted(buyer) || isCustomerFormCompleted(seller);
  };

  // TODO: refactor so that id's are stored in a centralized data structure as opposed to one for single and one for batch
  const selectedIDs = (): string[] => {
    if (selectedContractIDs.length > 0) return selectedContractIDs;
    if (legalModalOffice.id) return [`${legalModalOffice.id}`];

    return [];
  };

  async function submitContracts(): Promise<boolean> {
    const contractIds: string[] = selectedIDs();

    if (isEmpty(contractIds)) return false;

    // Determine if there are any contracts that are not valid for submission
    // If so, display a modal to alert the user
    const invalidContractIDs: string[] = [];
    const validWarrantylinkContracts: WarrantylinkContractData[] = [];

    for (const contractId of contractIds) {
      if (!validateContractReadyForSubmission(contractId)) {
        invalidContractIDs.push(contractId);
      } else {
        const con = props.summaryView.find((c) => c.id.toString() === contractId);
        const WarrantylinkContractData = { id: con.id.toString(), form: con.form };
        validWarrantylinkContracts.push(WarrantylinkContractData);
      }
    }
    if (invalidContractIDs.length > 0) {
      addErrorToQueue(INCOMPLETE_FORM_NOTIFICATION(invalidContractIDs));

      return false;
    }

    try {
      await WarrantylinkService.submitWarrantylinkContracts(
        contractIds,
        validWarrantylinkContracts,
      );
      addSuccessToQueue(msgs.WLK_SUBMIT_SUCCESS);
      return true;
    } catch (error) {
      console.error('failed to submit wlk contracts', error);
      addErrorToQueue(msgs.WLK_SUBMIT_ERROR);
      return false;
    }
  }

  /** Saves the WLK form data and performs a refresh. Returns true on success, otherwise false. */
  async function onSaveContractForm(contract: Partial<WarrantylinkContract>): Promise<boolean> {
    try {
      const success = await WarrantylinkService.updateWarrantylinkContractForm(contract);

      // On success, refresh the contract data
      props.refreshWarrantyLinkData([contract.id]);
      return success;
    } catch (error) {
      console.error('failed to submit wlk contracts', error);
      addErrorToQueue(msgs.WLK_SUBMIT_ERROR);
      return false;
    }
  }

  function filterWarrantylinkContracts() {
    // if there are no filters then set the list back to its original state
    if (filterList.length === 0) {
      setWarrantyLinkContractsFiltered(warrantyLinkContracts);
      return;
    }
    // Reset Agent list
    const dateFilters = filterList.filter((filter) => {
      return filter.type === 'date';
    });
    const statusFilters = filterList.filter((filter) => {
      return filter.type === 'status';
    });
    const officeFilters = filterList.filter((filter) => {
      return filter.type === 'office';
    });
    const agentFilters = filterList.filter((filter) => {
      return filter.type === 'agent';
    });

    // if there is an agent filter then filter by agent for the group of contracts that have that office and any of the statuses
    // if there is an office filter filter by that office and any of the matching statuses
    // The warrantylink response doesn't include the agent id so we have to match names, hence the paring of both agent name
    // and office id to ensure we're matching the correct agent.
    let andFilteredContracts = [];
    if (agentFilters.length) {
      // If there's an agent, find the matching office ID and then "and" those two filters together
      agentFilters.forEach((agentWarrantyLinkFilter: WarrantyLinkFilter) => {
        // find the correct office
        const matchedOffice = officeFilters.find((officeWarrantyLinkFilter: WarrantyLinkFilter) => {
          return (
            officeWarrantyLinkFilter.payload.office.id ===
            agentWarrantyLinkFilter.payload.agent.officeID
          );
        });

        if (matchedOffice) {
          const filterAgent =
            `${agentWarrantyLinkFilter.payload.agent.firstName} ${agentWarrantyLinkFilter.payload.agent.lastName}`.toLowerCase();
          const filterOffice = matchedOffice.payload.office.name.toLowerCase();
          warrantyLinkContracts.forEach((contract: REOrder) => {
            const contractAgent = contract.initiatingAgentName.toLowerCase();
            const contractOffice = contract.initiatingOfficeName.toLowerCase();
            if (filterOffice === contractOffice && filterAgent === contractAgent) {
              andFilteredContracts.push(contract);
            }
          });
        }
      });
    } else if (officeFilters.length) {
      officeFilters.forEach((officeWarrantyLinkFilter: WarrantyLinkFilter) => {
        const filterOffice = officeWarrantyLinkFilter.payload.office.id;
        warrantyLinkContracts.forEach((contract: REOrder) => {
          const hasOfficeMatch = props.wlkOfficeList.some(
            (office) => office.id === contract.initiatingOfficeID && office.id === filterOffice,
          );

          if (hasOfficeMatch) {
            andFilteredContracts.push(contract);
          }
        });
      });
    }

    // if no contracts were filtered by the "and" filters, set that collection to the full
    // warrantylink contract collection.
    if (!andFilteredContracts.length) {
      andFilteredContracts = [];
    }
    // Once we have the "and" filtered contracts, iterate over those and find any matching "or" statuses
    let orFilteredContracts = [];
    if (statusFilters.length) {
      // deep clone here so we don't modify the original
      const statusFiltersCopy = cloneDeep(statusFilters);
      const readySavedFilter = statusFiltersCopy.find(
        (s) => s.payload.status === WarrantylinkStatus.READYSAVED,
      );
      if (readySavedFilter) {
        // apply both ready and saved status to the filter
        readySavedFilter.payload.status = WarrantylinkStatus.READY;
        statusFiltersCopy.push(cloneDeep(readySavedFilter));
        readySavedFilter.payload.status = WarrantylinkStatus.SAVED;
      }

      const statuses = statusFiltersCopy.map((s) => s.payload.status);
      if (officeFilters.length > 0 || agentFilters.length > 0) {
        orFilteredContracts = andFilteredContracts.filter((c) =>
          statuses.includes(c.warrantylink.status),
        );
      } else {
        orFilteredContracts = warrantyLinkContracts.filter((c) =>
          statuses.includes(c.warrantylink.status),
        );
      }
    } else {
      // If we don't have any status filters just set this new variable to the full set
      orFilteredContracts = andFilteredContracts;
    }

    // Finally take the conditionally filtered collection and filter it by date range
    let finalMatchingContracts = [];
    if (dateFilters.length) {
      dateFilters.forEach((dateWarrantyLinkFilter: WarrantyLinkFilter) => {
        const startDate = dateWarrantyLinkFilter.payload.date.start;
        const endDate = dateWarrantyLinkFilter.payload.date.end;
        // Only date filter is applied, filter from original list
        if (filterList.length === 1) {
          warrantyLinkContracts.forEach((contract) => {
            const warrantylinkDate = new Date(contract.warrantylink.deadlineDate);
            if (warrantylinkDate >= startDate && warrantylinkDate <= endDate) {
              finalMatchingContracts.push(contract);
            }
          });
        } else {
          orFilteredContracts.forEach((contract: WLKContract) => {
            const warrantylinkDate = new Date(contract.warrantylink.deadlineDate);
            if (warrantylinkDate >= startDate && warrantylinkDate <= endDate) {
              finalMatchingContracts.push(contract);
            }
          });
        }
      });
    } else {
      finalMatchingContracts = orFilteredContracts;
    }

    setWarrantyLinkContractsFiltered(finalMatchingContracts);
  }

  const updateContractFormErrorsList = (error: string) => {
    if (contractFormsErrors.includes(error)) {
      const updatedErrors = contractFormsErrors.filter((string) => string !== error);
      setContractFormsErrors(updatedErrors);
    } else {
      setContractFormsErrors([...contractFormsErrors, error]);
    }
  };

  const toggleAllContractSelection = () => {
    // If we have contracts selected, we want to de-select them all. Otherwise, we are selecting all contracts.
    const hasContractsBeenSelected = selectedContractIDs.length > 0;

    // De-select all contracts
    if (hasContractsBeenSelected) {
      setSelectedContractIDs([]);
    } else {
      // Otherwise, select all valid contracts
      const allSubmitableContracts = warrantyLinkContractsFiltered.filter((contract) =>
        ['Ready', 'Saved'].includes(contract.warrantylink.status),
      );

      if (allSubmitableContracts.length > 0) {
        const contractIDs = allSubmitableContracts.map((submitableContract) =>
          submitableContract.id?.toString(),
        );

        setLegalModalOffice(allSubmitableContracts[0]);
        setSelectedContractIDs(contractIDs);
      }
    }
  };

  const handleSubmit = async () => {
    setIsLegalModalActive(false);
    const contractIds: string[] = selectedIDs();
    const success = await submitContracts();

    if (success) {
      contractIds.length > 1
        ? fireGAEvent(WLK_SUBMIT_BULK)
        : fireGAEvent(WLK_SUBMIT_SINGLE(contractIds[0]));
      await props.refreshWarrantyLinkData(selectedIDs());
    }
  };

  const toggleEdit = (contractID, isEditing) => {
    if (isEditing) {
      setEditingContractIDs([...editingContractIDs, contractID]);
    } else {
      setEditingContractIDs(editingContractIDs.filter((id) => id !== contractID));
    }
  };

  const canBulkSubmit = () => {
    return selectedContractIDs.length > 0 && editingContractIDs.length === 0;
  };

  const canSelectAll = () => {
    return editingContractIDs.length === 0;
  };

  const getListFiltering = () => {
    return (
      <ListFiltering
        id="orders-container--filter-dropdowns"
        addFilter={onFilterChange}
        applyFilters={onFilterBatchChange}
        onFilterTextChangeHandler={(e) => null}
        officeList={props.wlkOfficeList}
        agentList={agentList}
        userRoleID={profile.roleID}
        userRoleIDType={profile.roleIDType}
        activeFilters={filterList}
        statusAlerts={null}
        isMobile={isMobile}
        totalAwaitingWlkSubmission={null}
        statusMenuType={StatusMenuType.WarrantyLink}
      />
    );
  };

  const getFilterBadge = () => {
    return filterList?.length ? (
      <Badge color="interactive" size="small" maxCount={99} className="ml-1-important">
        {filterList.length.toString()}
      </Badge>
    ) : null;
  };

  /**
   * On [add/remove] filter changes, we want to check if it is a valid change. If so, we would then make a call to refresh the dashboard contracts.
   * On refresh, we should return back to first page in pagination.
   */
  function onFilterChange(label: string, event: WarrantyLinkFilter): boolean {
    let result: boolean;

    try {
      // Handle individual event type
      switch (event.type) {
        case FilterType.OFFICE:
          result = updateOfficeFilter(event);
          break;
        case FilterType.AGENT:
          result = updateAgentFilter(event);
          break;
        case FilterType.STATUS:
          result = updateStatusFilter(event);
          break;
        case FilterType.DATE:
          result = updateDateFilter(event);
          break;
      }

      // On valid update, we want to proceed onwards to update the tags and make a new request
      if (result && event.label) {
        fireGAEvent(NAVIGATION__ORDER_FILTER_SORT(`${event.operation}: ${event.label}`));
      }
    } catch (e) {
      console.error('failed to apply filter changes', e);
    }

    return result;
  }

  function updateOfficeFilter(event: WarrantyLinkFilter): boolean {
    let validUpdate = false;

    const { office } = event.payload;
    const inFilter = filterList.find(
      ({ type, payload }) =>
        type === 'office' && payload.office.id === office.id && payload.office.type === office.type,
    );

    switch (event.operation) {
      case FilterOperation.ADD:
        if (!inFilter) {
          validUpdate = true;

          if (office && office.id) {
            // Update the agent list with a new set of agents for the office
            AgentApi.searchAgents(
              {
                activeInactive: 'A',
                officeId: office.id,
                officeType: office.type,
              },
              {
                tags: { source: 'Orders#updateOfficeFilter' },
              },
            )
              .then((res) => {
                if (res.agentsList.length) {
                  console.log('adding agents to filter list, count=', res.agentsList.length);
                  setAgentList([...agentList, ...res.agentsList]);
                }
              })
              .catch((e) => {
                console.error(e);
              });
          }
        }
        break;
      case FilterOperation.REMOVE:
        if (inFilter) {
          const tags = filterList.filter(
            ({ type, payload }) =>
              !(
                type === 'office' &&
                payload.office.id === office.id &&
                payload.office.type === office.type
              ),
          );
          validUpdate = true;

          // Update the agent list and filter tags by removing agents under the office removed
          console.log('removing agents from filter list');
          setAgentList(
            agentList.filter(
              (a) =>
                !(a.officeID === office.id && officeTypeDictionary[a.agentType] === office.type),
            ),
          );
          setFilterList(
            tags.filter(
              (i) =>
                !(
                  i.type === 'agent' &&
                  i.payload.agent.officeID === office.id &&
                  officeTypeDictionary[i.payload.agent.agentType] === office.type
                ),
            ),
          );
        } else {
          console.warn('attempted to remove, but cannot find it');
        }
        break;
    }

    return validUpdate;
  }

  function updateAgentFilter(event: WarrantyLinkFilter): boolean {
    let validUpdate = false;

    const { agent } = event.payload;
    const inFilter = filterList.find(
      ({ type, payload }) =>
        type === 'agent' &&
        payload.agent.realEstateAgentID === agent.realEstateAgentID &&
        payload.agent.agentType === agent.agentType,
    );

    switch (event.operation) {
      case FilterOperation.ADD:
        if (!inFilter) {
          setFilterList([...filterList, event]);
          validUpdate = true;
        }
        break;
      case FilterOperation.REMOVE:
        if (inFilter) {
          setFilterList(
            filterList.filter(
              ({ type, payload }) =>
                !(
                  type === 'agent' &&
                  payload.agent.realEstateAgentID === agent.realEstateAgentID &&
                  payload.agent.agentType === agent.agentType
                ),
            ),
          );
          validUpdate = true;
        } else {
          console.warn('attempted to remove, but cannot find it');
        }
        break;
    }

    return validUpdate;
  }

  function updateDateFilter(event: WarrantyLinkFilter): boolean {
    let validUpdate = false;

    const { date } = event.payload;
    const inFilter = filterList.find(({ type }) => type === 'date');

    switch (event.operation) {
      case FilterOperation.ADD: {
        // We always update the date range if add occurs; replacing the existing one; as there should only be 1 date range filter
        const startDate = formatDateToISO8601(date.start);
        const endDate = formatDateToISO8601(date.end);
        const validDates = Boolean(startDate && endDate);
        if (validDates) {
          setFilterList([...filterList.filter((i) => i.type !== 'date'), event]);
          validUpdate = true;
        }
        break;
      }
      case FilterOperation.REMOVE: {
        if (inFilter) {
          setFilterList(filterList.filter((i) => i.type !== 'date'));
          validUpdate = true;
        } else {
          console.warn('attempted to remove date, but cannot find it');
        }
        break;
      }
    }

    return validUpdate;
  }

  function updateStatusFilter(event: WarrantyLinkFilter): boolean {
    let validUpdate = false;

    const { status } = event.payload;
    const inFilter = filterList.find(
      ({ type, payload }) => type === 'status' && payload.status === status,
    );

    switch (event.operation) {
      case FilterOperation.ADD:
        if (!inFilter) {
          setFilterList([...filterList, event]);
          validUpdate = true;
        }
        break;
      case FilterOperation.REMOVE:
        if (inFilter) {
          setFilterList(
            filterList.filter(
              ({ type, payload }) => !(type === 'status' && payload.status === status),
            ),
          );
          validUpdate = true;
        }
        break;
    }

    return validUpdate;
  }

  /**
   * On [add/remove] filter changes, pass in filter list from the modal.
   * Overwrite the existing filters with the new list, fire GA event, update agent list.
   */
  function onFilterBatchChange(events: WarrantyLinkFilter[]): boolean {
    const updatedAgentList = [];

    try {
      // remove all the office type tags then append with our new list
      const tempFilterList = filterList.filter((filter) => filter.type !== FilterType.OFFICE);

      setFilterList([...tempFilterList, ...events]);

      events.forEach((e) => {
        // Update the agent list with a new set of agents for the office
        fireGAEvent(NAVIGATION__ORDER_FILTER_SORT(`${e.operation}: ${e.label}`));
        if (e.payload.office && e.payload.office.id !== '') {
          AgentApi.searchAgents(
            {
              activeInactive: 'A',
              officeId: e.payload.office.id,
              officeType: e.payload.office.type,
            },
            {
              tags: { source: 'Orders#updateOfficeFilter' },
            },
          )
            .then((res) => {
              if (res.agentsList.length) {
                console.log('adding agents to filter list, count=', res.agentsList.length);
                updatedAgentList.push(...res.agentsList);
                setAgentList(updatedAgentList);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    } catch (e) {
      console.error('failed to apply filter changes', e);
      return false;
    }

    return true;
  }

  return (
    <ContentBox
      title="WarrantyLink&trade;"
      leftContent={
        !isMobile && (
          <div className="w-48 min-w-48 mr-4">
            <REText variant="heading-06" className="my-2">
              Filter WarrantyLink
            </REText>
            {getListFiltering()}
          </div>
        )
      }
    >
      <>
        <div
          id="wlk-sticky-container"
          className={`${IsTheme(Theme.Ahs2024) ? '' : 'extended-border-invisible border-bottom-faded sticky-container-top-index-3'}`}
        >
          {isMobile ? (
            <Button
              id="filter-wlk-orders-button"
              label="Filter WarrantyLink"
              shape="rounded"
              variant="outlined"
              endIcon={getFilterBadge()}
              onClick={() => setShowFilterModal(true)}
              width="full"
              labelAlign="center"
              className={IsTheme(Theme.Ahs2024) ? 'button-pill' : ''}
            />
          ) : (
            <div className="md:mb-4">
              <TagGroup>
                {filterList.map((filter) => (
                  <Tag
                    id={`wlk_contract_status_filter__${filter.label}`}
                    key={filter.label}
                    className="ml-4"
                    removable
                    color="interactive"
                    onClick={(e) => {
                      filter.operation = FilterOperation.REMOVE;
                      onFilterChange(filter.label, filter);
                    }}
                  >
                    {filter.label}
                  </Tag>
                ))}
              </TagGroup>
            </div>
          )}

          <div
            className={classNames(['flex mb-4', !props.canSubmitContracts && 'invisible'])}
            style={{ alignItems: 'end', justifyContent: 'space-between' }}
          >
            {!isMobile && canEditWLKContracts && (
              <>
                <Button
                  id={
                    isContractSelected ? 'wlk_contract__select_all' : 'wlk_contract__deselect_all'
                  }
                  className="underline floatLeft -mb-2"
                  variant="ghost"
                  color="interactive"
                  size="small"
                  label={isContractSelected ? 'Deselect All' : 'Select All'}
                  onClick={toggleAllContractSelection}
                  disabled={!canSelectAll()}
                />
                <Button
                  id="wlk_contract__submit_selected"
                  className="sm-only:w-full floatRight"
                  color="interactive"
                  size="medium"
                  label="Submit"
                  disabled={!canBulkSubmit()}
                  onClick={() => {
                    setIsLegalModalActive(true);
                  }}
                />
              </>
            )}
          </div>
        </div>

        {warrantyLinkContractsFiltered.length === 0 && !props.isLoading && (
          <div className="mt-3">
            <strong>No Results Found</strong>
          </div>
        )}

        {/*TODO: ARE-9273 This uses old SF accordion, change this to BDS. causes props.onClick black magic*/}
        {/* Contracts accordion list */}
        <Accordion className="mt-3">
          {props.isLoading
            ? Array(SKELETON_LOAD_CNT)
              .fill(null)
              .map((item, idx) => <AccordionItemWarranty key={idx} skeleton={true} />)
            : warrantyLinkContractsFiltered.map((wlkContract, idx) => (
              <AccordionItemWarranty
                filteredList={warrantyLinkContractsFiltered}
                key={idx}
                id={`wlk_contract__${idx}`}
                selectedContracts={selectedContractIDs}
                contract={wlkContract}
                canSubmit={props.canSubmitContracts}
                address={`${addressToString(
                  wlkContract?.address?.address1,
                  wlkContract?.address?.address2,
                  wlkContract?.address?.city,
                  wlkContract?.address?.state,
                  wlkContract?.address?.zip,
                )}`}
                submissionDeadline={formatDateFromString(wlkContract?.warrantylink?.deadlineDate)}
                submissionDate={formatDateFromString(wlkContract?.warrantylink?.submissionDate)}
                contractNumber={wlkContract.id}
                contractId={wlkContract.id}
                agent={wlkContract.initiatingAgentName}
                office={wlkContract.initiatingOfficeName}
                buyer={wlkContract.form?.buyer}
                seller={wlkContract.form?.seller}
                onSaveForm={onSaveContractForm}
                updateErrors={updateContractFormErrorsList}
                status={wlkContract?.warrantylink?.status}
                onCheckboxClick={addOrRemoveContractSelection}
                toggleEdit={toggleEdit}
                isMobile={isMobile}
                canEditWLKContracts={canEditWLKContracts}
              />
            ))}
        </Accordion>

        {isMobile && canEditWLKContracts && (
          <div className="sticky-container-bottom extended-border-invisible force-background-white border-top-faded force-margin-x-wide flex items-center">
            <div className="m-auto">
              <Button
                id={isContractSelected ? 'wlk_contract__select_all' : 'wlk_contract__deselect_all'}
                className="underline"
                variant="ghost"
                color="interactive"
                size="small"
                label={isContractSelected ? 'Deselect All' : 'Select All'}
                onClick={toggleAllContractSelection}
                disabled={!canSelectAll()}
                width="full"
                labelAlign="center"
              />
            </div>
            <div className="m-auto">
              <Button
                id="wlk_contract__submit_selected"
                color="interactive"
                size="medium"
                label="Submit"
                disabled={!canBulkSubmit()}
                onClick={() => {
                  setIsLegalModalActive(true);
                }}
                width="full"
                labelAlign="center"
              />
            </div>
          </div>
        )}

        <Dialog
          id="filter-wlk-modal"
          header="Filter Orders"
          modal={true}
          onClose={() => setShowFilterModal(false)}
          open={showFilterModal && isMobile}
          actionsAlign="right"
          actions={[
            <Button
              key="action"
              size="small"
              label="Done"
              onClick={() => setShowFilterModal(false)}
            />,
          ]}
        >
          <div className="min-w-80">{getListFiltering()}</div>
        </Dialog>

        {legalModalOffice && (
          <ModalLegalConfirmation
            isActive={isLegalModalActive}
            office={legalModalOffice.initiatingOfficeName}
            firstName={profile.firstName}
            lastName={profile.lastName}
            onClose={() => setIsLegalModalActive(false)}
            onSubmit={handleSubmit}
          />
        )}
      </>
    </ContentBox>
  );
};

export default WarrantyLinkTemplate;
