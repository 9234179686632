import React from 'react';
import {
  AbstractNewOrderStepTemplate,
  NewOrderStepStates,
  NewOrderStepTemplateProps,
} from '@templates/order/AbstractNewOrderStepTemplate';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import cloneDeep from 'lodash/cloneDeep';
import { NewOrderStep } from '@app/models/order/neworder.model';
import OrderFormProductFilters, {
  OrderFormProductFiltersInfoField,
} from '@components/orders/forms/OrderFormProductFilters';
import ProductApi, { ProductApiSuppressErrors } from '@apis/product.api';
import {
  DwellingType,
  ProductAndCoveragesResponse,
  ProductAvailabilityRequest,
  ProductFilters,
  ProductFiltersRequest,
  ProductPricingDetailsRequest,
  ProductPricingResponse,
} from '@apis/models';
import { isClosingDateRequired } from '@helpers/order.utils';
import { OrderPlanInfoFormData, OrderProductFiltersFormData } from '@app/models/order/order.model';
import OrderFormPlanInfo from '@components/orders/forms/OrderFormPlanInfo';
import { OptionalCoverageItem } from '@components/card/CardPlanPriceCoverage';
import { isNullOrUndefined } from '@helpers/utils';
import { OrderFormFieldError } from '@components/orders/forms/OrderForm.types';
import {
  validatePlanSelection,
  validateProjectedClosingDate,
} from '@services/validation/NewOrder.FieldValidationRules';
import { Notification } from '@ftdr/blueprint-components-react';
import { Canceler } from 'axios';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  NEW_ORDER_FLOW__PLANS_AND_COVERAGE_COMPLETE,
  NEW_ORDER_FLOW__OPTIONAL_COVERAGE_SELECTION,
  NEW_ORDER_FLOW__SELLERS_COVERAGE_SELECTED,
} from '@constants/ga-events.constants';
import REText from '@components/wrappedBDS/REText';

interface States
  extends NewOrderStepStates,
    Pick<NewOrderContextData, 'planInfo' | 'productFilters'> {
  loadingEnabledFilters: boolean;
  enabledFilters: ProductFilters;
  loadProductsAndCoverages: boolean;
  availablePlansOptCoverages: ProductAndCoveragesResponse[];
  availablePlansPriceData: ProductPricingResponse[];

  /** used to determine if a new availability call is necessary */
  prevProductAvailabilityRequest: ProductAvailabilityRequest;

  loadingPriceSummary: boolean;

  productFiltersErrors: OrderFormFieldError<OrderFormProductFiltersInfoField>;
  planSelectionError: string;

  /** should be set if we are forcing plan reselection. It should be cleared once the a plan selection has been completed. */
  disableInitialPlanSelection: boolean;
}

export class NewOrderStep2Template extends AbstractNewOrderStepTemplate<States> {
  loadPriceCanceler: Canceler;
  ProductAPISuppressErrors: typeof ProductApi;

  constructor(props) {
    super(props);
    this.ProductAPISuppressErrors = ProductApi.new({
      suppressResponseErrorNotification: true,
      returnCancelErrorAsNull: false,
    });
  }

  componentDidMount() {
    if (!this.state.enabledFilters) {
      this.getProductFilters();
    }
  }

  componentWillUnmount() {
    this.loadPriceCanceler?.('unmount');
  }

  getProductFilters = async () => {
    try {
      this.setState({
        loadingEnabledFilters: true,
        enabledFilters: null,
      });
      const enabledFiltersRequest: ProductFiltersRequest = {
        age: this.props.savedData.propertyInfo.age,
        state: this.props.savedData.addressInfo.state,
        zip: this.props.savedData.addressInfo.zip,
        brand: this.getBrand(),
        franchiseCode: this.props.savedData.initiatingAgent.franchiseCode,
        contractListTimestamp: '',
        roProductsOnly: this.props.savedData.agentInfo.selectROOption,
        squareFootage: this.props.savedData.propertyInfo.squareFootage,
        typeOfResidence: this.props.savedData.propertyInfo.residenceType as DwellingType,
      };
      const res = await ProductApiSuppressErrors.getFilters(enabledFiltersRequest);
      this.setState({
        enabledFilters: res,
        productFilters: this.getFilterSelectionsByEnabledFilters(res),
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        loadingEnabledFilters: false,
      });
    }
  };

  /**
   * called when we want to update the filter selections, e.g. when the enabled filters are updated. returns refreshed seleted filters.
   * */
  getFilterSelectionsByEnabledFilters = (
    enabledFilters: ProductFilters,
  ): OrderProductFiltersFormData => {
    const selectedFilters = cloneDeep(this.state.productFilters);
    if (enabledFilters) {
      // if term is not available, select the first term
      if (!enabledFilters.terms.includes(selectedFilters.term)) {
        selectedFilters.term = enabledFilters.terms[0];
      }
      // if sellers coverage is not available, or it hasn't been set yet, set to false.
      if (!enabledFilters.sellersCoverage || isNullOrUndefined(selectedFilters.sellersCoverage)) {
        selectedFilters.sellersCoverage = false;
      }
      // if ac coverage is not available, or it hasn't been set yet, set to true, set to true.
      if (!enabledFilters.acCoverage || isNullOrUndefined(selectedFilters.acCoverage)) {
        selectedFilters.acCoverage = true;
      }
    }
    return selectedFilters;
  };

  onFilterSelection = (filters: OrderProductFiltersFormData) => {
    this.setState({ productFilters: filters });
  };

  onCompleteFilterSelection = () => {
    const availabilityRequest = this.getProductAvailabilityRequest();
    if (
      JSON.stringify(this.state.prevProductAvailabilityRequest) !==
      JSON.stringify(availabilityRequest)
    ) {
      this.loadAvailablePlans(availabilityRequest);
    }
  };

  getProductAvailabilityRequest = (
    data = this.state.productFilters,
  ): ProductAvailabilityRequest => {
    return {
      office: {
        id: this.props.savedData.initiatingAgent.officeID,
      },
      options: {
        acCoverage: data.acCoverage,
        contractListTimestamp: '',
        roProductsOnly: this.props.savedData.agentInfo.selectROOption,
        sellersCoverage: data.sellersCoverage,
        tenant: this.getBrand(),
        contractTermMonths: data.term * 12,
      },
      property: {
        address1: this.props.savedData.addressInfo.streetAddress,
        address2: this.props.savedData.addressInfo.unit,
        age: this.props.savedData.propertyInfo.age,
        zip: this.props.savedData.addressInfo.zip,
        state: this.props.savedData.addressInfo.state,
        residenceType: this.props.savedData.propertyInfo.residenceType,
        squareFootage: this.props.savedData.propertyInfo.squareFootage || 4999,
        city: this.props.savedData.addressInfo.city,
      },
    };
  };

  loadAvailablePlans = async (newProductAvailabilityRequest: ProductAvailabilityRequest) => {
    this.setState({
      prevProductAvailabilityRequest: newProductAvailabilityRequest,
      availablePlansOptCoverages: [],
      availablePlansPriceData: [],
      loadProductsAndCoverages: true,
    });

    const res1 = await ProductApiSuppressErrors.getProductAvailability(
      newProductAvailabilityRequest,
    );

    const priceData: ProductPricingResponse[] = await Promise.all(
      res1.products.map((product) =>
        ProductApi.getProductPricingByProductId(product.starPVID, {
          property: newProductAvailabilityRequest.property,
          options: {
            contractTermMonths: newProductAvailabilityRequest.options.contractTermMonths,
            listingTermInDays: product.listingTermInDays,
            earnixRuleID: null,
            initiatingOfficeID: newProductAvailabilityRequest.office?.id,
            initiatingOfficeFranchiseCode: null,
            specialDiscounts: [],
          },
        }),
      ),
    );

    const coveragesResponses = await Promise.all(
      res1.products.map(async (product) =>
        ProductApi.getProductCoveragesByProductId(
          product.starPVID,
          this.props.savedData.propertyInfo.residenceType,
        ),
      ),
    );

    this.setState({
      availablePlansOptCoverages: coveragesResponses,
      availablePlansPriceData: priceData,
      loadProductsAndCoverages: false,
    });

    // handle initial plan selection as necessary
    if (coveragesResponses) {
      if (this.state.disableInitialPlanSelection) {
        console.debug('disable initial plan selection. have user select');
        return;
      }
      let planSelection: ProductAndCoveragesResponse;
      // default to user's previous selection by plan name.
      if (this.state.planInfo.selectedPlanName !== '') {
        planSelection = coveragesResponses.find(
          (s) => s.product.name === this.state.planInfo.selectedPlanName,
        );
      }
      // if matching plan cannot be found, then default to ShieldComplete, otherwise default to first plan.
      if (!planSelection) {
        planSelection =
          coveragesResponses.find((s) => s.product.name === 'ShieldComplete') ||
          coveragesResponses[0];
      }
      if (planSelection) {
        this.onPlanSelect(planSelection.product.starPVID, coveragesResponses, priceData);
        return;
      }
    }
  };

  /**
   * on new pvid selection, select the plan and plan's name.  maintain and drop coverages based on its availability on switched plans
   * */
  onPlanSelect = (
    pvid: string,
    plansAndCoverages = this.state.availablePlansOptCoverages,
    plansPricings = this.state.availablePlansPriceData,
  ) => {
    if (pvid) {
      const planAndCoverages = plansAndCoverages.find(({ product }) => product.starPVID === pvid);
      const planPricing = plansPricings.find(({ product }) => product.id === pvid);
      if (planAndCoverages) {
        const { product, coverages } = planAndCoverages;
        const planInfo: OrderPlanInfoFormData = {
          ...this.state.planInfo,
          selectedPlanID: product.starPVID,
          selectedPlanName: product.name,
          selectedPlanPrice: planPricing?.product.price,
          selectedPlanListingTermInDays: product.listingTermInDays,
          selectedOptionalCoverages: this.state.planInfo.selectedOptionalCoverages.filter(
            (c) =>
              !!coverages.optional.map((cvg) => cvg.starCoverageId).find((cvgid) => cvgid === c.id),
          ),
          selectedGroupCoverages: this.state.planInfo.selectedGroupCoverages.filter(
            (c) => !!coverages.group.map((cvg) => cvg.starGroupId).find((cvgid) => cvgid === c.id),
          ),
        };
        this.setState({
          planInfo,
          disableInitialPlanSelection: false, // remove the disabling of initial plan selection
        });
        this.loadPriceSummary(planInfo);
      }
    }
  };

  onCoverageSelect = (cvg: OptionalCoverageItem) => {
    const { planInfo } = this.state;
    if (cvg.isGroupCoverage) {
      const updatedSelectedCvgs = this.state.planInfo.selectedGroupCoverages.filter(
        (selectedCvg) => selectedCvg.id !== cvg.id,
      );
      if (updatedSelectedCvgs.length === planInfo.selectedGroupCoverages.length) {
        planInfo.selectedGroupCoverages.push({
          id: cvg.id,
          name: cvg.name,
          price: cvg.price,
        });
      } else {
        planInfo.selectedGroupCoverages = updatedSelectedCvgs;
      }
    } else {
      const updatedSelectedCvgs = this.state.planInfo.selectedOptionalCoverages.filter(
        (selectedCvg) => selectedCvg.id !== cvg.id,
      );
      if (updatedSelectedCvgs.length === planInfo.selectedOptionalCoverages.length) {
        planInfo.selectedOptionalCoverages.push({
          id: cvg.id,
          name: cvg.name,
          price: cvg.price,
        });
      } else {
        planInfo.selectedOptionalCoverages = updatedSelectedCvgs;
      }
    }

    this.setState({
      planInfo,
    });
    this.loadPriceSummary(planInfo);
  };
  getProductPricingDetailsRequest = (
    planInfo = this.getPendingData().planInfo,
  ): ProductPricingDetailsRequest => {
    const { property } = this.getProductAvailabilityRequest();
    return {
      property,
      options: {
        contractTermMonths: this.getPendingData().productFilters.term * 12,
        listingTermInDays: planInfo.selectedPlanListingTermInDays,
        earnixRuleID: null,
        initiatingOfficeID: this.props.savedData.initiatingAgent?.officeID,
        initiatingOfficeFranchiseCode: null,
        specialDiscounts: [],
      },
      selected: {
        productID: planInfo.selectedPlanID,
        optionalCoverages: planInfo.selectedOptionalCoverages.map(({ id }) => ({
          id,
          quantity: 1,
        })),
        groupCoverages: planInfo.selectedGroupCoverages.map(({ id }) => ({ id, quantity: 1 })),
      },
    };
  };

  loadPriceSummary = (planInfo = this.getPendingData().planInfo) => {
    const pricingRequest = this.getProductPricingDetailsRequest(planInfo);

    this.loadPriceCanceler?.('fetching new price');
    this.setState({ loadingPriceSummary: true });

    const cancelSource = this.ProductAPISuppressErrors.createNewCancelTokenSource();
    this.loadPriceCanceler = cancelSource.cancel;

    this.ProductAPISuppressErrors.withRequestConfigAs({ cancelToken: cancelSource.token })
      .getProductPricingDetails(pricingRequest)
      .then((pricingResponse) => {
        this.setState({ loadingPriceSummary: false });
        if (pricingResponse) {
          this.setState({
            planInfo: {
              ...this.state.planInfo,
              pricingRequest,
              pricingResponse,
            },
          });
        }
      })
      .catch((err) => {
        if (this.ProductAPISuppressErrors.isCancellationError(err)) {
          return;
        }
        console.error(err);
      });
  };

  getDefaultState(props: NewOrderStepTemplateProps): States {
    // if we have completed this current step already, then we want to refresh some validation.
    const productFiltersErrors: OrderFormFieldError<OrderFormProductFiltersInfoField> = {
      [OrderFormProductFiltersInfoField.acCoverage]: '',
      [OrderFormProductFiltersInfoField.sellersCoverage]: '',
      [OrderFormProductFiltersInfoField.termLength]: '',
      [OrderFormProductFiltersInfoField.projectedClosingDate]: '',
    };
    if (this.props.latestStep > this.props.currentStep) {
      productFiltersErrors.projectedClosingDate = validateProjectedClosingDate(
        props.savedData.productFilters.projectedClosingDate,
        isClosingDateRequired(
          props.savedData.agentInfo.represents,
          !!props.savedData.buyerInfo.firstName,
        ),
      );
    }

    return {
      loadingEnabledFilters: false,
      enabledFilters: null,
      planInfo: cloneDeep(props.savedData.planInfo),
      productFilters: cloneDeep(props.savedData.productFilters),
      availablePlansOptCoverages: [],
      availablePlansPriceData: [],
      loadProductsAndCoverages: false,
      prevProductAvailabilityRequest: null,
      productFiltersErrors,
      disableInitialPlanSelection: !!this.props.metaData.planReselectionReason, // disable if plan reselection required
      planSelectionError: '',
      loadingPriceSummary: false,
    };
  }

  getPendingData(): NewOrderContextData {
    return {
      ...this.props.savedData,
      productFilters: this.state.productFilters,
      planInfo: this.state.planInfo,
    };
  }

  getPartialSubmittedData(): NewOrderContextData {
    // should perform validation on the fields and stripped off any incomplete data
    return this.getPendingData(); // for now, saving all data regardless of incomplete data
  }

  onClear(): void {
    this.setState(this.getDefaultState(this.props));
    this.getProductFilters();
  }
  onSubmit(): void {
    // dont submit if price is still loading.  also don't if form isn't valid yet for submission
    if (this.state.loadingPriceSummary || !this.isFormValidForSubmission()) {
      return;
    }

    const dataToSubmit = this.getPendingData();
    this.props.submitPage(dataToSubmit);

    // Track in GA the completion of Plans and Coverage Info.
    fireGAEvent(
      NEW_ORDER_FLOW__PLANS_AND_COVERAGE_COMPLETE(
        this.props.userProfile,
        dataToSubmit.agentInfo?.represents,
      ),
    );

    // Track in GA event if Optional coverage was selected or not.
    const optionalCoveragesSelected =
      dataToSubmit.planInfo?.selectedOptionalCoverages?.length > 0 ? true : false;
    fireGAEvent(
      NEW_ORDER_FLOW__OPTIONAL_COVERAGE_SELECTION(
        this.props.userProfile,
        dataToSubmit.agentInfo.represents,
        optionalCoveragesSelected,
      ),
    );

    if (dataToSubmit.productFilters.sellersCoverage) {
      // Track in GA event if Seller's coverage was selected or not.
      // console.log('Should track in GA since Sellers coerage was selected.');
      fireGAEvent(
        NEW_ORDER_FLOW__SELLERS_COVERAGE_SELECTED(
          this.props.userProfile,
          dataToSubmit.agentInfo.represents,
        ),
      );
    }
  }
  onEdit(editStep: NewOrderStep): void {
    this.props.editAnotherStep(editStep, this.getPartialSubmittedData());
  }

  onUpdateErrorProductFiltersField = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormProductFiltersInfoField,
    value: string = '',
  ) => {
    const errors = this.state.productFiltersErrors;
    errors[fieldName] = shouldExecValidateFc
      ? this.validateProductFiltersField(fieldName, value)
      : value;
    this.setState({ productFiltersErrors: errors });
  };

  validateProductFiltersField = (fieldName: OrderFormProductFiltersInfoField, value: string) => {
    switch (fieldName) {
      case OrderFormProductFiltersInfoField.projectedClosingDate:
        return validateProjectedClosingDate(
          this.getPendingData().productFilters.projectedClosingDate,
          isClosingDateRequired(
            this.props.savedData.agentInfo.represents,
            !!this.props.savedData.buyerInfo.firstName,
          ),
        );
      default:
        break;
    }
    return '';
  };

  isFormValidForSubmission(): boolean {
    const productFiltersErrors: OrderFormFieldError<OrderFormProductFiltersInfoField> = {
      [OrderFormProductFiltersInfoField.acCoverage]: '',
      [OrderFormProductFiltersInfoField.sellersCoverage]: '',
      [OrderFormProductFiltersInfoField.termLength]: '',
      [OrderFormProductFiltersInfoField.projectedClosingDate]: this.validateProductFiltersField(
        OrderFormProductFiltersInfoField.projectedClosingDate,
        this.getPendingData().productFilters.projectedClosingDate?.toString(),
      ),
    };
    const planSelectionError = validatePlanSelection(this.getPendingData().planInfo.selectedPlanID);

    this.setState({ productFiltersErrors, planSelectionError });
    return !planSelectionError && Object.values(productFiltersErrors).every((err) => !err);
  }

  renderForm(): JSX.Element {
    // should render the form
    return (
      <>
        {/* put form components here */}
        <OrderFormProductFilters
          enabledFilters={this.state.enabledFilters}
          formData={this.state.productFilters}
          errors={this.state.productFiltersErrors}
          onUpdateFormData={(data) => this.onFilterSelection(data)}
          onValidateField={(fieldName, value) =>
            this.onUpdateErrorProductFiltersField(true, fieldName, value)
          }
          clearFieldError={(fieldName) => this.onUpdateErrorProductFiltersField(false, fieldName)}
          onFieldError={(fieldName, err) =>
            this.onUpdateErrorProductFiltersField(false, fieldName, err)
          }
          onFormError={console.log}
          onCompleteFilterSelection={this.onCompleteFilterSelection}
          showUnknownDateOption={
            !isClosingDateRequired(
              this.props.savedData.agentInfo.represents,
              !!this.props.savedData.buyerInfo.firstName,
            )
          }
          defaultCheckedUnknownDateOption={
            this.props.latestStep > this.props.currentStep &&
            !this.props.savedData.productFilters.projectedClosingDate
          }
        />

        <div className="mb-4">
          <REText variant="heading-04">Select a Plan</REText>
          {(this.props.metaData.planReselectionReason || this.state.planSelectionError) && (
            <div className="mt-1">
              <Notification id="plan-reselection-reason" status="error" showStatusLabel={false}>
                {[this.state.planSelectionError, this.props.metaData.planReselectionReason]
                  .join(' ')
                  .trim()}
              </Notification>
            </div>
          )}
        </div>

        <OrderFormPlanInfo
          loadingPlans={this.state.loadProductsAndCoverages}
          formData={this.state.planInfo}
          productCoverages={this.state.availablePlansOptCoverages}
          productPricings={this.state.availablePlansPriceData}
          onPlanSelect={this.onPlanSelect}
          onCoverageSelect={this.onCoverageSelect}
          // no validation on plan selection at this time
          errors={null}
          onFormError={console.log}
          clearFieldError={console.log}
          onFieldError={console.log}
          onUpdateFormData={console.log}
          onValidateField={console.log}
        />
      </>
    );
  }
}
