import React, { useEffect, useState } from 'react';
import ZestyApi from '@apis/zesty.apis';
import { Promo } from '@apis/models/zesty.api.model';
import { Panel } from '@ftdr/blueprint-components-react';
import { Address } from '@app/models';
import REText from '@components/wrappedBDS/REText';

interface PromoBannerProps {
  address: Address;
}

export const PromoBanner: React.FC<PromoBannerProps> = (
  props: React.PropsWithChildren<PromoBannerProps>,
) => {
  const [promoData, setPromoData] = useState<Promo[]>(null);
  const [validPromo, setValidPromo] = useState<Promo>(null);
  const [showBanner, setShowBanner] = useState(false);
  const [partnerPortalPages, setPartnerPortalPages] = useState<string[]>([]);

  useEffect(() => {
    ZestyApi.GetPartnerPortalPages().then((res) => {
      setPartnerPortalPages(res);
    });
    ZestyApi.GetPromoBanner().then((res) => {
      setPromoData(res);
    });
  }, []);

  useEffect(() => {
    if (promoData && props.address && partnerPortalPages) {
      checkAndShowPromo(props.address.state);
    } else {
      setShowBanner(false);
    }
  }, [props.address, promoData, partnerPortalPages]);

  const checkAndShowPromo = (state) => {
    const date = new Date();

    const promo = promoData.find((promo) => {
      const startDate = new Date(promo.startDate);
      const endDate = new Date(promo.endDate);

      const inDateRange = date > startDate && date < endDate;

      const pageActive = promo.displayPages.filter((page) => partnerPortalPages.includes(page));

      return promo.states.includes(state) && promo.status === 'on' && pageActive && inDateRange;
    });

    if (promo) {
      setValidPromo(promo);
      setShowBanner(true);
    } else {
      setValidPromo(null);
      setShowBanner(false);
    }
  };

  return (
    <div>
      {showBanner && promoData && (
        <Panel id="promo-banner" shadow={true}>
          <div className="flex flex-row">
            <div>
              <img
                id="promo-banner-image-icon"
                src={validPromo.smallBannerImage}
                className=" min-h-8 max-h-16 object-center object-contain"
              />
            </div>
            <div>
              <REText
                id="promo-banner-text"
                className="zesty-html"
                dangerouslySetInnerHTML={{ __html: validPromo.smallBannerText }}
              />
            </div>
          </div>
        </Panel>
      )}
    </div>
  );
};
