import React, { ChangeEvent } from 'react';
import * as c from '@constants/formField-constants';
import { Input, MaskedInput } from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';
import { getFormInputErrorId } from '@storybook/addon-links';

export enum keyCode {
  ENTER = 13,
}

interface NewOrderCustomer<T> {
  firstName: T;
  lastName: T;
  email: T;
  phone: T;
}

interface FormNewOrderCustomerProps {
  ids: NewOrderCustomer<string>;
  names: NewOrderCustomer<string>;
  values: NewOrderCustomer<string>;
  errors: NewOrderCustomer<string>;
  required: NewOrderCustomer<boolean>;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onInputBlur: (e) => void;
  alternateMobileView?: boolean /** True: in mobile, aligns all fields vertically. False: in mobile, aligns email/phone inline with OR between */;
  buyerPhoneRef?: any;
}

const FormNewOrderCustomer: React.FC<FormNewOrderCustomerProps> = ({
  ids,
  names,
  required,
  values,
  errors,
  ...props
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === keyCode.ENTER) {
      props.onInputBlur(e);
    }
  };

  return (
    <>
      <div className="w-full flex sm-max:flex-col sm-max:space-y-2 px-4 py-2">
        <div className="flex-1">
          <Input
            formField={true}
            formFieldMessageId={getFormInputErrorId(ids?.firstName)}
            type="text"
            inputMode="text"
            id={ids?.firstName}
            name={names?.firstName}
            required={required.firstName}
            value={values.firstName || ''}
            error={errors.firstName}
            autoComplete="off"
            onBlur={props.onInputBlur}
            onChange={props.onInputChange}
            label="First name"
            maxLength={c.FIRST_NAME_MAX_LENGTH}
          />
        </div>

        <div className="w-8"> </div>

        <div className="flex-1">
          <Input
            formField={true}
            formFieldMessageId={getFormInputErrorId(ids?.lastName)}
            type="text"
            inputMode="text"
            id={ids?.lastName}
            name={names?.lastName}
            required={required.lastName}
            value={values.lastName || ''}
            error={errors.lastName}
            autoComplete="off"
            onBlur={props.onInputBlur}
            onChange={props.onInputChange}
            label="Last name"
            maxLength={c.LAST_NAME_MAX_LENGTH}
          />
        </div>
      </div>
      <div
        className={classNames([
          'w-full flex flex-wrap px-4 py-2',
          props.alternateMobileView ? 'xs-max:flex-col' : '',
        ])}
      >
        <div className="flex-1 py-2">
          <MaskedInput
            inputRef={props.buyerPhoneRef}
            formField={true}
            formFieldMessageId={getFormInputErrorId(ids?.phone)}
            type="tel"
            inputMode="tel"
            id={ids?.phone}
            name={names?.phone}
            required={required.phone}
            value={values.phone || ''}
            error={errors.phone}
            autoComplete="off"
            onBlur={props.onInputBlur}
            onChange={props.onInputChange}
            onKeyDown={handleKeyDown}
            label="Phone number"
            mask="(000) 000-0000"
            maxLength={c.PHONE_WITH_MASK_MAX_LENGTH}
          />
        </div>

        <div
          className={classNames([
            'align-center content-center',
            props.alternateMobileView ? 'sm:w-8' : 'w-8 pt-8',
          ])}
        >
          <div className="form-field-top-row"> </div>
          <div className="xs-max:flex xs-max:justify-center xs-max:items-center">or</div>
        </div>

        <div className="flex-1 py-2">
          <Input
            formField={true}
            formFieldMessageId={getFormInputErrorId(ids?.email)}
            type="email"
            inputMode="email"
            id={ids?.email}
            name={names?.email}
            required={required.email}
            value={values.email || ''}
            error={errors.email}
            autoComplete="off"
            onBlur={props.onInputBlur}
            onChange={props.onInputChange}
            onKeyDown={handleKeyDown}
            label="Email address"
          />
        </div>
      </div>
    </>
  );
};

export default FormNewOrderCustomer;
