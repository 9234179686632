import React from 'react';
import { ProgressIndicator } from '@ftdr/blueprint-components-react';
import SentenceText from '@components/SentenceText';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

interface AtypicallySlowOverlayProps {
  isActive: boolean;
}

const AtypicallySlowOverlay: React.FC<AtypicallySlowOverlayProps> = (props) => {
  return (
    props.isActive && (
      <div className={`flex flex-col items-center align-center mx-auto py-8 px-4 bg-white rounded ${IsTheme(Theme.AhsWebApp) ? 'shadow-2' : ''}`}>
        <SentenceText variant="heading-04" className="text-primary line-clamp-none">
          This is taking longer than we expected, please hold!
        </SentenceText>
        <ProgressIndicator variant="circular" size="large" />
        <REText color="gray" className="pb-8 mt-3" variant="caption">
          Loading...
        </REText>
      </div>
    )
  );
};

export default AtypicallySlowOverlay;
