import React from 'react';
import { addressObjectToString } from '@services/helpers';
import REText from '@components/wrappedBDS/REText';

const InactiveOffice = (props) => {
  return (
    <div>
      <REText variant="body-short">This office is no longer available for use in the system. </REText>
      <REText variant="body-short">
        <div>{props.inactiveOffice.name}</div>
        <div>{addressObjectToString(props.inactiveOffice.address)}</div>
        <div>Office ID: {props.inactiveOffice.id}</div>
      </REText>
    </div>
  );
};

export default InactiveOffice;
