import React, { useContext, useEffect, useState } from 'react';
import LayoutCard from '@components/layout/LayoutCard';
import Template from '@templates/registration/RegistrationTemplate';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AgentApi from '@services/apis/agent.api';
import ProfileApi from '@services/apis/profile.api';
import OfficeSearch from '@components/misc/OfficeSearch';
import usaStates from '@constants/us-states';
import {
  accountToOfficeTypeDictionary,
  officeTypeDictionary,
  officeTypeFullFormDictionary,
} from '@constants/dictionaries';
import { cleanPhone } from '@helpers/utils';
import { useAuth } from '@ftdr/use-auth/build';
import { providerType } from '@helpers/auth.utils';
import { authProvider } from '@constants/auth-providers';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { fireGAEvent } from '@app/core/tracking.service';
import { ACCOUNT_MANAGEMENT__PROFILE_CREATED } from '@constants/ga-events.constants';
import ProfileContext from '@context/ProfileContext';
import { JUST_REGISTERED } from '@helpers/localStorage.utils';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import msgs from '@app/locales/en';
import { CreateProfileRequest } from '@apis/models/profile.api.model';
import Path from '@constants/paths';
import DeepLinkService from '@services/deepLink/deepLink.service';

const HTTP_STATUS_CREATED = 201;
const HTTP_STATUS_CONFLICT = 409;

let values = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  accountType: '',
  narIdentificationNumber: '',
};

const RegisterAccountTemplate = (props) => {
  const navigate = useNavigate();
  const { showSpinner } = useGlobalOverlaySpinner();
  const { profile } = useContext(ProfileContext);
  const { logout, oidcUser } = useAuth();
  const { registrationType } = props;
  const { addErrorToQueue, addWarningToQueue } = useGlobalAlert();
  const narAccountOptions = {
    RealEstateAgent: 'Real Estate Agent',
    Broker: 'Real Estate Broker',
  };
  const defaultAccountType = {
    RealEstateAdmin: false,
    ClosingCompanyAgent: false,
    ClosingCompanyAdmin: false,
  };
  const [accountTypeValues, setAccountTypeValues] = useState(defaultAccountType);
  const [isOfficeSearchActive, setisOfficeSearchActive] = useState(false);
  const [userIsCorporate, setUserIsCorporate] = useState(false);
  const [accountTypeOptions, setAccountTypeOptions] = useState({
    RealEstateAdmin: 'Real Estate Office Administrator / Transaction Coordinator',
    ClosingCompanyAgent: 'Closing, Title, or Escrow Agent',
    ClosingCompanyAdmin: 'Closing Office Administrator',
  } as any);

  function setValues(v) {
    values = v;
  }

  useEffect(() => {
    if (providerType(oidcUser.access_token) === authProvider.CORPORATE) {
      setAccountTypeOptions({
        FieldSales: 'Field Sales',
        InternalSalesAgent: 'Internal Sales Agent',
      });
      setUserIsCorporate(true);
    }

    checkForIncompleteProfile();
  }, []);

  function checkForIncompleteProfile() {
    if (profile.isIncomplete) {
      setValues({
        ...values,
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        phoneNumber: profile.phoneNumber || '',
        narIdentificationNumber: profile.narID || '',
        accountType: profile.roleIDType || '',
      });

      if (
        accountTypeValues[profile.roleIDType] !== null &&
        accountTypeValues[profile.roleIDType] !== undefined
      ) {
        const modifiedAccountType = accountTypeValues;
        modifiedAccountType[profile.roleIDType] = true;
        setAccountTypeValues(modifiedAccountType);
      }
    }
  }

  async function submitOfficeDrawerHandler(company) {
    const office = { type: officeTypeFullFormDictionary[company[0].type], id: company[0].id };
    const narID = values.narIdentificationNumber;

    try {
      showSpinner(true);

      if (!profile.isIncomplete) {
        const newProfile: CreateProfileRequest = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: '',
          roleIDType: values.accountType,
          roleID: '',
          phoneNumber: values.phoneNumber,
          phoneType: 'Office',
          narID,
          offices: [office],
        };

        const response = await ProfileApi.createProfile(newProfile);

        showSpinner(false);
        if (response.status === HTTP_STATUS_CREATED) {
          localStorage.setItem(JUST_REGISTERED, 'true');
          await navigateOutOfRegistration();
        } else if (response.status === HTTP_STATUS_CONFLICT) {
          setisOfficeSearchActive(false);
          addWarningToQueue(msgs.PROFILE_EXISTS_ERROR);
          await navigateOutOfRegistration();
        } else {
          setisOfficeSearchActive(false);
          addErrorToQueue(msgs.PROFILE_SUBMIT_ERROR);
          await navigateOutOfRegistration();
        }
      } else {
        // updating an incomplete profile. Incomplete profiles exist only for Brokers.
        const updatedProfile = {
          ...profile,
          firstName: values.firstName,
          lastName: values.lastName,
          roleIDType: values.accountType,
          phoneNumber: values.phoneNumber,
          phoneType: 'Office',
          narID: values.narIdentificationNumber,
          email: null, // ARE-6882 Email should only be updated from the Edit My Account page.
        };
        const officeRequest = {
          profileID: profile.profileID,
          offices: [office],
        };

        const officeResponse = await ProfileApi.updateUsersOfficeDetails(officeRequest);
        if (officeResponse) {
          const profileResponse = await ProfileApi.updateUser(updatedProfile);
          if (!profileResponse.isAxiosError) {
            localStorage.setItem(JUST_REGISTERED, 'true');
            await navigateOutOfRegistration();
          } else {
            setisOfficeSearchActive(false);
            addErrorToQueue(msgs.PROFILE_SUBMIT_ERROR);
            await navigateOutOfRegistration();
          }
        }
      }
    } catch (error) {
      console.error(error);
      showSpinner(false);
      setisOfficeSearchActive(false);
      addErrorToQueue(msgs.PROFILE_SUBMIT_ERROR);
      await navigateOutOfRegistration();
    }
  }

  async function navigateOutOfRegistration() {
    const deepLink = DeepLinkService.GetDeepLinkString();

    if (deepLink) {
      await window.location.assign(deepLink);
    } else {
      await navigate(Path.Dashboard);
    }
  }

  function setUpdatedValues(newValues) {
    values.firstName = newValues.firstName;
    values.lastName = newValues.lastName;
    values.phoneNumber = newValues.phoneNumber;
    values.accountType = newValues.accountType;
    values.narIdentificationNumber = newValues.narId;
  }

  async function handleCancel() {
    logout();
  }

  async function handleSave(userInfo) {
    setUpdatedValues(userInfo);
    fireGAEvent(ACCOUNT_MANAGEMENT__PROFILE_CREATED(values.accountType));

    if (Object.keys(values).length) {
      if (['ClosingCompanyAgent', 'RealEstateAgent'].includes(values.accountType)) {
        try {
          const searches = [];

          searches.push(
            AgentApi.searchAgents(
              {
                officeType: accountToOfficeTypeDictionary[values.accountType],
                firstName: values.firstName,
                lastName: values.lastName,
              },
              {
                tags: { source: 'RegistrationAccount#handleSave' },
              },
            ),
          );

          if (values.phoneNumber) {
            searches.push(
              AgentApi.searchAgents(
                {
                  officeType: accountToOfficeTypeDictionary[values.accountType],
                  phone: cleanPhone(values.phoneNumber), // TODO: Should be cleaned in API. Later
                },
                {
                  tags: { source: 'RegistrationAccount#handleSave' },
                },
              ),
            );
          }

          let agentList = [];
          showSpinner(true);

          await Promise.all(searches).then((results) => {
            results.forEach((result) => {
              agentList = agentList.concat(result.agentsList);
            });
          });

          showSpinner(false);
          const removeDuplicateAgents = (agents) => {
            const uniqueAgents = [];
            agents.forEach((agent) => {
              if (
                uniqueAgents.find((a) => a.realEstateAgentID === agent.realEstateAgentID) ===
                undefined
              ) {
                uniqueAgents.push(agent);
              }
            });
            return uniqueAgents;
          };
          const prunedAgentList = agentList.filter(
            (agent) =>
              officeTypeDictionary[agent.agentType] === officeTypeDictionary[values.accountType],
          );
          const uniqueAgentList = removeDuplicateAgents(prunedAgentList);
          if (uniqueAgentList.length > 0) {
            const narID = values.narIdentificationNumber;
            navigate(Path.RegistrationExistingUser, {
              state: {
                agentsList: uniqueAgentList,
                currentUser: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  roleIDType: values.accountType,
                  phoneNumber: cleanPhone(values.phoneNumber),
                  narID,
                },
                profile,
              },
            });
          } else {
            setisOfficeSearchActive(true);
          }
        } catch (error) {
          showSpinner(false);
          console.log(error);
        }
      } else {
        setisOfficeSearchActive(true);
      }
    }
  }

  const handleOfficeCancel = () => {
    window.location.reload();
  };

  return (
    <>
      <LayoutCard roundedBorders hideHeader={true} hideFooter={true}>
        {isOfficeSearchActive ? (
          <OfficeSearch
            usaStates={usaStates}
            typeCode={officeTypeDictionary[values.accountType]}
            excludeBrandSearch={false}
            isActive={isOfficeSearchActive}
            onClose={() => setisOfficeSearchActive(false)}
            onSubmit={submitOfficeDrawerHandler}
            ignoreReadOnly={true}
            onCancel={handleOfficeCancel}
          />
        ) : (
          <Template
            type={registrationType}
            handleSubmit={handleSave}
            handleCancel={handleCancel}
            narAccountOptions={narAccountOptions}
            accountTypeOptions={accountTypeOptions}
            accountTypeValues={accountTypeValues}
            userIsCorporate={userIsCorporate}
          />
        )}
      </LayoutCard>
    </>
  );
};

RegisterAccountTemplate.propTypes = {
  registrationType: PropTypes.string.isRequired,
};
export default RegisterAccountTemplate;
