import { BorderWidthOption, Checkbox, Panel } from '@ftdr/blueprint-components-react';
import { addressObjectToString } from '@services/helpers';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Contract, ContractSummary } from '@apis/models';
import { formatDateFromString } from '@helpers/utils';
import Mark from 'mark.js';
import { breakpoints, isViewInRange } from '@utils';
import { CustomerTypes, orderStatusDictionary } from '@constants/dictionaries';
import REText from '@components/wrappedBDS/REText';

interface internalProps {
  contract: ContractSummary;
  idPrefix: string;
  onClickContract?: any;
  actions?: any[];
  leftActions?: any[];
  showBuyer?: boolean;
  showExpiryDate?: boolean;
  showSubmissionDeadline?: boolean;
  showStatus?: boolean;
  /** text used for highlighting phrases on the contract summary. */
  query?: string;
  showWLKDeadline?: boolean;
  contractDetails?: Contract;
  disabled?: boolean;
  disabledLabel?: string;
  mode?: PanelContractSummaryMode;
  showCheckbox?: boolean;
  checkboxChecked?: boolean;
  onChangeCheckbox?: (checked: boolean) => void;
  isCheckboxIndeterminate?: boolean;
  orderNumberLabel?: any;
  propertyAddressLabel?: any;
  expiryDateLabel?: any;
  submissionDeadlineLabel?: any;
  statusLabel?: any;
  buyerLabel?: any;
}

export enum PanelContractSummaryMode {
  Default = 0,
  Compact,
  HeadersOnly,
}

const PanelContractSummary = (props: internalProps) => {
  const [buyerName, setBuyerName] = useState<string>('');
  const isMobile = isViewInRange(0, breakpoints.md);
  const container = useRef<HTMLDivElement | null>(null);

  const performMark = useCallback(() => {
    if (container?.current && props.query) {
      const instance = new Mark(container.current);
      instance.unmark({
        done() {
          instance.mark(props.query.split(' '), {
            separateWordSearch: false,
            diacritics: true,
            debug: false,
            exclude: ['.DO-NOT-HIGHLIGHT'],
            className: 'font-bold',
          });
        },
      });
    }
  }, [props.query]);

  useEffect(() => {
    performMark();
  }, [props.query, props.contract]);

  useEffect(() => {
    if (props.contractDetails?.detail?.customers) {
      const buyers = props.contractDetails.detail.customers.filter(
        (c) => c.type === CustomerTypes.Buyer,
      );
      if (buyers.length) {
        setBuyerName(`${buyers[0]?.firstName} ${buyers[0]?.lastName}`);
      }
    }
  }, [props.contractDetails]);

  const getPanelClassname = (): string => {
    switch (props.mode) {
      case PanelContractSummaryMode.Compact:
        return 'bg-white w-full';
      case PanelContractSummaryMode.HeadersOnly:
        return 'w-full';
      case PanelContractSummaryMode.Default:
      default:
        return 'rounded bg-white shadow-1 border-2 mb-4 w-full';
    }
  };

  const getPanelBorder = (): BorderWidthOption => {
    switch (props.mode) {
      case PanelContractSummaryMode.Compact:
      case PanelContractSummaryMode.HeadersOnly:
        return '0';
      case PanelContractSummaryMode.Default:
      default:
        return '1';
    }
  };

  const getPanelShadow = (): boolean => {
    switch (props.mode) {
      case PanelContractSummaryMode.HeadersOnly:
      case PanelContractSummaryMode.Compact:
        return false;
      case PanelContractSummaryMode.Default:
      default:
        return true;
    }
  };

  return (
    <Panel
      border={getPanelBorder()}
      borderColor="gray-300"
      borderB={props.mode === PanelContractSummaryMode.Compact ? '1' : undefined}
      rounded={props.mode === PanelContractSummaryMode.Compact ? 'none' : 'sm'}
      backgroundColor={props.mode === PanelContractSummaryMode.HeadersOnly ? 'gray-100' : undefined}
      padding="none"
      shadow={getPanelShadow()}
      className={getPanelClassname()}
    >
      <div
        ref={container}
        className={`flex justify-start items-center w-full py-3 pl-4 pr-4 md:pl-6 text-left text-sm md:text-base bg-transparent ${props.onClickContract && 'cursor-pointer'}`}
        id={props.idPrefix}
        onClick={() => props.onClickContract?.(props.contract)}
      >
        {/* TODO Clean up the Div bloat... This code was copied from elsewhere to start componentization, but time constraints prevented a full cleanup effort */}
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-full pl-3 max-w-19/20 overflow-hidden">
            <div className="flex md:flex-row md-max:flex-col ">
              {isMobile &&
                props.leftActions?.length > 0 &&
                props.leftActions.map((action) => {
                  return (
                    <div key={action} className="mr-4 -ml-4">
                      {action}
                    </div>
                  );
                })}
              {!isMobile &&
                props.leftActions?.length > 0 &&
                props.leftActions.map((action) => {
                  return (
                    <div key={action} className="mr-4 -ml-4">
                      {action}
                    </div>
                  );
                })}
              <div
                className="flex md:flex-row md-max:flex-col"
                onClick={() => props.onClickContract?.(props.contract)}
              >
                {props.showCheckbox && (
                  <div className="float-left flex flex-row">
                    <Checkbox
                      indeterminate={props.isCheckboxIndeterminate}
                      className="mr-6"
                      checked={props.checkboxChecked}
                      onChange={(e) => props.onChangeCheckbox(e.target.checked)}
                      disabled={props.disabled}
                    />
                  </div>
                )}
                <div className="min-w-max w-fit flex">
                  <div className="float-left flex flex-row min-w-24">
                    <div className="flex flex-col w-full ">
                      {props.mode !== PanelContractSummaryMode.Compact && (
                        <REText
                          variant="label"
                          color={
                            props.mode === PanelContractSummaryMode.HeadersOnly ? 'black' : 'gray'
                          }
                          className="nowrap DO-NOT-HIGHLIGHT"
                        >
                          {props.orderNumberLabel ? props.orderNumberLabel : <>ORDER NUMBER</>}
                        </REText>
                      )}
                      {props.mode !== PanelContractSummaryMode.HeadersOnly && (
                        <REText variant="caption" color={props.disabled ? 'gray' : 'black'}>
                          {props.contract?.id}
                        </REText>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row float-right">
                    {isMobile &&
                      !props.disabled &&
                      props.actions?.length > 0 &&
                      props.actions.map((action) => {
                        return (
                          <div key={action} className="ml-4">
                            {action}
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="flex flex-col min-w-56">
                  {props.mode !== PanelContractSummaryMode.Compact && (
                    <REText
                      variant="label"
                      color={props.mode === PanelContractSummaryMode.HeadersOnly ? 'black' : 'gray'}
                      className="pr-48 nowrap DO-NOT-HIGHLIGHT"
                    >
                      {props.propertyAddressLabel ? (
                        props.propertyAddressLabel
                      ) : (
                        <>COVERED PROPERTY ADDRESS</>
                      )}
                    </REText>
                  )}
                  {props.mode !== PanelContractSummaryMode.HeadersOnly && (
                    <REText
                      variant="caption"
                      color={props.disabled ? 'gray' : 'black'}
                      className="overflow-visible"
                    >
                      {addressObjectToString(props.contract?.address)}
                    </REText>
                  )}
                </div>

                {(props.showExpiryDate === undefined || props.showExpiryDate === true) && (
                  <div className="flex flex-col nowrap pr-6 min-w-22">
                    {props.mode !== PanelContractSummaryMode.Compact && (
                      <REText
                        variant="label"
                        color={
                          props.mode === PanelContractSummaryMode.HeadersOnly ? 'black' : 'gray'
                        }
                        className="DO-NOT-HIGHLIGHT"
                      >
                        {props.expiryDateLabel ? props.expiryDateLabel : <>EXPIRY DATE</>}
                      </REText>
                    )}
                    {props.mode !== PanelContractSummaryMode.HeadersOnly && (
                      <REText variant="caption" color={props.disabled ? 'gray' : 'secondary'}>
                        {formatDateFromString(props.contract?.expirationDate)}
                      </REText>
                    )}
                  </div>
                )}

                {props.showSubmissionDeadline && (
                  <div className="flex flex-col nowrap pr-6 w-fit">
                    {props.mode !== PanelContractSummaryMode.Compact && (
                      <REText
                        variant="label"
                        color={
                          props.mode === PanelContractSummaryMode.HeadersOnly ? 'black' : 'gray'
                        }
                        className="DO-NOT-HIGHLIGHT"
                      >
                        {props.submissionDeadlineLabel ? (
                          props.submissionDeadlineLabel
                        ) : (
                          <>SUBMISSION DEADLINE</>
                        )}
                      </REText>
                    )}
                    {props.mode !== PanelContractSummaryMode.HeadersOnly && (
                      <REText variant="caption" color="secondary">
                        {formatDateFromString(props.contract?.warrantylink?.deadlineDate)}
                      </REText>
                    )}
                  </div>
                )}

                {props.showStatus && (
                  <div className="flex flex-col nowrap pr-6 min-w-14">
                    {props.mode !== PanelContractSummaryMode.Compact && (
                      <REText
                        variant="label"
                        color={
                          props.mode === PanelContractSummaryMode.HeadersOnly ? 'black' : 'gray'
                        }
                        className="DO-NOT-HIGHLIGHT"
                      >
                        {props.statusLabel ? props.statusLabel : <>STATUS</>}
                      </REText>
                    )}
                    {props.mode !== PanelContractSummaryMode.HeadersOnly && (
                      <REText variant="caption" color={props.disabled ? 'gray' : 'black'}>
                        {orderStatusDictionary[props.contract?.realEstateStatus]}
                      </REText>
                    )}
                  </div>
                )}

                {props.showBuyer && (
                  <div className="flex flex-col w-full nowrap pr-6">
                    {props.mode !== PanelContractSummaryMode.Compact && (
                      <REText
                        variant="label"
                        color={
                          props.mode === PanelContractSummaryMode.HeadersOnly ? 'black' : 'gray'
                        }
                        className="DO-NOT-HIGHLIGHT"
                      >
                        {props.buyerLabel ? props.buyerLabel : <>BUYER</>}
                      </REText>
                    )}
                    {props.mode !== PanelContractSummaryMode.HeadersOnly && (
                      <REText variant="caption" color={props.disabled ? 'gray' : 'black'}>
                        {buyerName}
                      </REText>
                    )}
                  </div>
                )}
              </div>
            </div>
            {props.disabledLabel && (
              <REText variant="caption" color="black" className="DO-NOT-HIGHLIGHT">
                {props.disabledLabel}
              </REText>
            )}
          </div>
          {!isMobile &&
            !props.disabled &&
            props.actions?.length > 0 &&
            props.actions.map((action) => {
              return (
                <div key={action} className="flex flex-row mr-4 h-fit md-max:mr-4">
                  {action}
                </div>
              );
            })}
        </div>
      </div>
    </Panel>
  );
};

export default PanelContractSummary;
