import React from 'react';
import CardDashboardAction, { DashboardActionType } from '@components/card/CardDashboardAction';
import { Button } from '@ftdr/blueprint-components-react';
import { DASHBOARD_AWAITING_PAYMENT_VIEW_ORDERS } from '@constants/ga-events.constants';
import { fireGAEvent } from '@app/core/tracking.service';
import { useNavigate } from 'react-router-dom';
import Path from '@constants/paths';
import { toFormattedNumber } from '@helpers/utils';
import { RealEstateStatus } from '@constants/dashboardFilters';
import ICardDashBoardActionProps from '@components/card/ICardDashboardAction';

export const AWAITING_PAYMENT_FILTERS = [
  RealEstateStatus.PAYMENT_DUE,
  RealEstateStatus.PAYMENT_PAST_DUE,
];

const CardDashboardActionAwaitingPayment = (props: ICardDashBoardActionProps) => {
  const navigate = useNavigate();

  const navigateToMyOrdersFiltered = () => {
    fireGAEvent(DASHBOARD_AWAITING_PAYMENT_VIEW_ORDERS);

    const preFilter = {
      orderStatuses: AWAITING_PAYMENT_FILTERS,
      officeLimitBypass: true,
    };

    navigate(Path.MyOrders, { state: preFilter });
  };

  return (
    <CardDashboardAction
      isManualFetch={props.isManualFetch}
      fetchCount={props.fetchCount}
      type={props.count ? DashboardActionType.NoLabel : DashboardActionType.DoesNotNeedAttention}
      heading={props.count === null ? null : toFormattedNumber(props.count)}
      subHeading="awaiting payment on orders past close of escrow"
      action={
        <Button
          label="View Orders"
          size="medium"
          variant="ghost"
          disabled={props.count === null || props.count === 0}
          onClick={() => navigateToMyOrdersFiltered()}
        />
      }
    />
  );
};

export default CardDashboardActionAwaitingPayment;
