import React from 'react';
import Modal, { ModalProps } from '@components/modal/Modal';
import { Button } from '@ftdr/blueprint-components-react';
import Path from '@constants/paths';
import { useNavigate } from 'react-router-dom';
import { addressToString } from '@services/helpers';
import { ContractDetail } from '@apis/models';
import CannotAddCooperating from '@components/modal/subcomponents/CannotAddCooperating';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  ORDERS_ADDMCA_ACTIVE_CONTRACT,
  ORDERS_ADDMCA_ALREADY_ASSIGNED,
  ORDERS_ADDMCA_CANCELLED_CONTRACT,
  ORDERS_ADDMCA_CANCELLED_ORDER,
  ORDERS_ADDMCA_NONE_ASSIGNED,
} from '@constants/ga-events.constants';

export enum CannotAddCooperatingType {
  CooperatingExists = 1,
  ContractActive,
  CancelledOrder,
  CancelledContract,
}

interface Props extends ModalProps {
  isActive: boolean;
  onClose: () => void;
  contract: ContractDetail;
  cannotAddCooperatingReason: CannotAddCooperatingType;
}

export const ModalCannotAddCooperating: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleContactUs = () => {
    const contractID = props.contract?.id;
    const { property } = props.contract;
    const propertyAddress = addressToString(
      property.streetAddress,
      property.streetAddress2,
      property.city,
      property.state,
      property.zip,
    );
    let supportMessage = '';

    switch (props.cannotAddCooperatingReason) {
      case CannotAddCooperatingType.CooperatingExists:
        fireGAEvent(ORDERS_ADDMCA_ALREADY_ASSIGNED);
        supportMessage = `I have been notified that my contact details have been added for Cooperating agent details on this open order ${contractID} for ${propertyAddress}. But a Cooperating agent has already been assigned on this order. I need assistance with getting more details on this order.`;
        break;
      case CannotAddCooperatingType.ContractActive:
        fireGAEvent(ORDERS_ADDMCA_ACTIVE_CONTRACT);
        supportMessage = `I have been notified that my contact details have been added for Cooperating agent details on this active contract ${contractID} for ${propertyAddress}. I need assistance with getting more details on this contract.`;
        break;
      case CannotAddCooperatingType.CancelledOrder:
        fireGAEvent(ORDERS_ADDMCA_CANCELLED_ORDER);
        supportMessage = `I have been notified that my contact details have been added for Cooperating agent details on this order ${contractID} for ${propertyAddress} which is showing as cancelled. I need assistance with getting more details on this order.`;
        break;
      case CannotAddCooperatingType.CancelledContract:
        fireGAEvent(ORDERS_ADDMCA_CANCELLED_CONTRACT);
        supportMessage = `I have been notified that my contact details have been added for Cooperating agent details on this contract ${contractID} for ${propertyAddress} which is showing as cancelled. I need assistance with getting more details on this order.`;
        break;
      default:
        break;
    }

    if (
      props.contract?.contractStatus !== 'A' &&
      props.contract?.contractStatus !== 'C' &&
      props.contract?.contractStatus !== 'X' &&
      !props.contract?.cooperatingOfficeAgent
    ) {
      fireGAEvent(ORDERS_ADDMCA_NONE_ASSIGNED);
    }

    navigate(Path.ContactUs, {
      state: {
        topic: 'Order Management',
        emailText: supportMessage,
      },
      replace: true,
    });
  };

  const getHeading = () => {
    let type = '';

    if (props.contract?.initiatingOfficeAgent?.represents === 'SELLER') {
      type = 'buyer';
    } else {
      type = 'seller';
    }

    switch (props.cannotAddCooperatingReason) {
      case CannotAddCooperatingType.CooperatingExists:
        return `A ${type} agent has already been assigned on this order. Please contact support team if you feel this is an error.`;
      case CannotAddCooperatingType.ContractActive:
        return 'This is an active contract. Please contact support team if you want to make any updates.';
      case CannotAddCooperatingType.CancelledOrder:
        return 'This order has been cancelled. Please contact support team if you have any questions.';
      case CannotAddCooperatingType.CancelledContract:
        return 'This contract has been cancelled. Please contact support team if you have any questions.';
      default:
        return '';
    }
  };

  const actions = [
    <Button
      key="submit"
      id="modal-confirm"
      label="Continue"
      onClick={handleContactUs}
      width="full"
      labelAlign="center"
      size="medium"
    />,
    <Button
      key="cancel"
      id="modal-cancel"
      variant="ghost"
      label="Cancel"
      onClick={props.onClose}
      width="full"
      labelAlign="center"
      size="medium"
    />,
  ];

  return (
    <Modal
      id="modal-cooperating-exists"
      isActive={props.isActive}
      actions={actions}
      onClose={props.onClose}
      closeOnOutsideClick={true}
      showBackdrop={true}
      isModalDialog={false}
      heading={getHeading()}
    >
      {props.isActive && <CannotAddCooperating {...props} />}
    </Modal>
  );
};
