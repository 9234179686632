import React, { ReactNode } from 'react';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

interface LineItemProps {
  label: string;
  caption: string;
  id: string | ReactNode;
}

const LineItem: React.FC<LineItemProps> = ({ label, caption, id }) => {
  return (
    <div className="flex">
      <REText
        variant={IsTheme(Theme.Ahs2024) ? 'label' : 'body-short'}
        id={`office-label_${id}`}
        className="mr-2 flex"
      >
        {label}
      </REText>
      <REText
        variant={IsTheme(Theme.Ahs2024) ? 'caption' : 'body-short'}
        id={`office-caption_${id}`}
      >
        {caption}
      </REText>
    </div>
  );
};

export default LineItem;
