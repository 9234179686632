import {
  Button,
  Input,
  MaskedInput,
  Popover,
  PopoverContent,
  IconInfoFull,
} from '@ftdr/blueprint-components-react';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  PHONE_WITH_MASK_MAX_LENGTH,
} from '@constants/formField-constants';
import React, { useEffect, useState } from 'react';
import useForm from '@helpers/UseForm';
import validate from '@services/validation/ValidationRules';
import { MOBILE_PHONE_NUMBER_AGENT } from '@constants/formField-constants';
import { classNames } from '@utils';
import { getFormInputErrorId } from '@storybook/addon-links';
import REText from '@components/wrappedBDS/REText';

export const ADD_AGENT_TO_OFFICE_LABELS = {
  AGENT_MOBILE_PHONE_NUMBER_INPUT: 'Mobile phone number',
  AGENT_MOBILE_PHONE_NUMBER_TOOLTIP: MOBILE_PHONE_NUMBER_AGENT,
};

interface Props {
  id: string;
  loading: boolean;
  setActions: React.Dispatch<React.SetStateAction<JSX.Element[]>>;
  onCancel: () => void;
  onSubmitForm: (formData: NewAgentFormData) => void;
}

export interface NewAgentFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
}

const AddAgentToOffice: React.FC<Partial<Props>> = (props) => {
  const { values, setValues, errors, setErrors, setTouched, handleBlur, forceTouchAll } =
    useForm<NewAgentFormData>(validate, {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    });
  const [formRef, setFormRef] = useState(null);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
  };

  /** Perform submit action when user clicks on the submit button */
  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const { hasErrors } = forceTouchAll();
    if (hasErrors) {
      return;
    }
    props.onSubmitForm(values);
  };

  useEffect(() => {
    props.setActions([
      <Button
        key="continue"
        id={`${props.id}--btn-submit`}
        size="medium"
        label="Continue"
        onClick={() => {
          formRef.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
        }}
        disabled={props.loading}
        width="full"
        labelAlign="center"
      />,
      <Button
        key="cancel"
        id={`${props.id}--btn-cancel`}
        variant="ghost"
        size="medium"
        label="Cancel"
        onClick={props.onCancel}
        width="full"
        labelAlign="center"
      />,
    ]);
  }, [props.loading, formRef]);

  // TODO Refactor to not use form component. Should match pattern used in my account and registration pages.

  return (
    <form onSubmit={(e) => onSubmit(e)} ref={(ref) => setFormRef(ref)}>
      <div>
        <REText variant="heading-06">Personal Information</REText>

        <div className="mt-2 w-full xs:w-1/2 flex flex-col space-y-2">
          <Input
            formField={true}
            formFieldMessageId={getFormInputErrorId(`${props.id}--first-name`)}
            id={`${props.id}--first-name`}
            name="firstName"
            label="First name"
            required={true}
            autoComplete="off"
            value={values.firstName}
            error={errors.firstName}
            onChange={onChangeInput}
            onBlur={handleBlur}
            maxLength={FIRST_NAME_MAX_LENGTH}
          />

          <Input
            formField={true}
            formFieldMessageId={getFormInputErrorId(`${props.id}--last-name`)}
            id={`${props.id}--last-name`}
            name="lastName"
            label="Last name"
            required={true}
            autoComplete="off"
            value={values.lastName}
            error={errors.lastName}
            onChange={onChangeInput}
            onBlur={handleBlur}
            maxLength={LAST_NAME_MAX_LENGTH}
          />

          <MaskedInput
            formField={true}
            formFieldMessageId={getFormInputErrorId(`${props.id}--phone-number`)}
            id={`${props.id}--phone-number`}
            type="tel"
            inputMode="tel"
            name="phoneNumber"
            label={ADD_AGENT_TO_OFFICE_LABELS.AGENT_MOBILE_PHONE_NUMBER_INPUT}
            required={true}
            autoComplete="off"
            value={values.phoneNumber}
            error={errors.phoneNumber}
            onChange={onChangeInput}
            onBlur={handleBlur}
            maxLength={PHONE_WITH_MASK_MAX_LENGTH}
            mask="(000) 000-0000"
            secondaryAction={
              <Popover
                placement="top"
                triggerInteraction="hover"
                content={(popoverContentProps) => (
                  <PopoverContent {...popoverContentProps}>
                    <REText className="max-w-xs" variant="caption">
                      {ADD_AGENT_TO_OFFICE_LABELS.AGENT_MOBILE_PHONE_NUMBER_TOOLTIP}
                    </REText>
                  </PopoverContent>
                )}
              >
                <IconInfoFull
                  color="interactive"
                  size={18}
                  className={classNames(['inline', 'align-center'])}
                />
              </Popover>
            }
          />

          <Input
            formField={true}
            formFieldMessageId={getFormInputErrorId(`${props.id}--email-address`)}
            id={`${props.id}--email-address`}
            inputMode="email"
            name="email"
            label="Email address"
            required={true}
            autoComplete="off"
            value={values.email}
            error={errors.email}
            onChange={onChangeInput}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </form>
  );
};

export default AddAgentToOffice;
