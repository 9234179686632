import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '@utils';
import { useWindowSize } from 'react-use';
import isEmpty from 'lodash/isEmpty';
import CardNewOrder from '@components/card/CardNewOrder';
import {
  convertCheckboxGroupOnChangeToOnClick,
  convertOptionsObjToCheckboxGroupOptions,
  convertValuesToCheckboxGroupValue,
} from '@components/form/FormCheckbox';
import {
  isAllFieldFormatsValid,
  isAllRequiredValuesPresent,
  validateName,
  validateSellerForm,
} from '@services/validation/ValidationRules';
import useForm from '@helpers/UseForm';
import * as c from '@constants/formField-constants';
import DrawerOfficeSearch from '@components/drawer/DrawerOfficeSearch';
import { deleteUndefinedValues, formatPhoneNumber } from '@app/helpers/utils';
import AgentApi from '@apis/agent.api';
import { selectSuggestion } from '@services/autosuggest/autosuggest';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import AgentAutosuggest from '../form/AgentAutosuggest';
import FormNewOrderCustomer from '@components/form/FormNewOrderCustomer';
import FormNewOrderCustomerAddress from '@components/form/FormNewOrderCustomerAddress';
import { CheckboxGroup, Input, RadioGroup } from '@ftdr/blueprint-components-react';
import { ORDER_FORM_FIELDS, ORDER_VIEW_LABELS } from '@constants/newOrder-constants';
import {
  convertOptionsObjToRadioGroupOptions,
  convertRadioGroupOnChangeToOnClick,
  convertValuesToRadioGroupValue,
  getFormInputErrorId,
  getFormRadioErrorId,
} from '@storybook/addon-links';
import AddressVerification from '@components/misc/AddressVerification';
import CustomCooperatingAgent from '@components/form/CustomCooperatingAgent';
import { ADDED_EXISTING_COOP_AGENT, CREATED_CUSTOM_AGENT } from '@constants/ga-events.constants';
import { fireGAEvent } from '@app/core/tracking.service';
import { ModalAgentVerification } from '@components/modal/ModalAgentVerification';
import REText from '@components/wrappedBDS/REText';

/** enum key used for referencing
 * temporary simulated enum until this file is typescript. */
const CustomerKey = {
  Customer: 'customer',
  CoCustomer: 'coCustomer',
};

const customCooperatingAgentErrorMessage = {
  firstName: 'First Name required',
  lastName: 'Last Name required',
  email: 'Email required',
};

/*
    Notes for props when we convert to typescript due to certain props not being clear what they are.

    values: the data supplied to this component for display. Is renamed to 'savedFormValues' for clarity within the child that it is meant to be the 'saved data' for resets.
    isRequired: if set to true, we should default the radio selection to true and not display it
    showCooperatingFields: if set to true, display the cooperating office and agent fields
    userDetails: user profile

 */

var foundCustomCooperatingAgent = null;
const CardNewOrderSellerInfo = ({
  values: savedFormValues = {
    sellerInfo: { yes: false, no: false },
  },
  isEditing,
  setIsEditing,
  ...props
}) => {
  const isCustomerInfoRequired = () => {
    return props.isCustomerNameRequired || props.isCustomerContactInfoRequired;
  };

  const { addErrorToQueue } = useGlobalAlert();

  const { values, errors, handleBlur, handleSubmit, setValues, setErrors, setTouched } = useForm(
    validateSellerForm(
      isCustomerInfoRequired(),
      props.isCustomerContactInfoRequired,
      props.userDetails,
      { email: props.agentInfo?.AgentEmail, phone: props.agentInfo?.AgentPhone?.phoneNumber },
    ),
    savedFormValues,
  );

  let [scratchFormValues, setScratchFormValues] = [values, setValues];

  const { width } = useWindowSize();
  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed || false);
  const [isDrawerActive, setIsDrawerActive] = useState(false);
  const [showSellerInfoFields, setShowSellerInfoFields] = useState(isCustomerInfoRequired());
  const [showAlternateAddressFields, setShowAlternateAddressFields] = useState(false);
  const [showCoSellerFields, setShowCoSellerFields] = useState(false);
  const [showCoSellerAlternateAddressFields, setShowCoSellerAlternateAddressFields] =
    useState(false);
  const sellerInfoFields = useRef(null);
  const coSellerAlternateAddressFields = useRef(null);
  const alternateAddressFields = useRef(null);
  const coSellerFields = useRef(null);
  const [doesZipExist, setDoesZipExist] = useState(true);
  const [doesCoCustomerZipExist, setDoesCoCustomerZipExist] = useState(true);
  const [typeAheadAddress, setTypeAheadAddress] = useState([]);
  const [selectedAgent, changeSelectedAgent] = useState(savedFormValues?.cooperatingAgent || '');

  const [agentHelpText, setAgentHelpText] = useState('');
  const [isAddAgentModalActive, setIsAddAgentModalActive] = useState(false);
  const [isAgentVerificationActive, setIsAgentVerificationActive] = useState(false);
  const initialCustomCooperatingAgent = {
    firstName: '',
    lastName: '',
    email: '',
    office: null,
    cooperatingOffice: '',
    hasValue: false,
  };
  const unsetCustomCooperatingAgent = {
    AgentName: '',
    AgentEmail: '',
    AgentPhone: '',
    AgentId: '',
  };

  const [sellerFirstNameError, setSellerFirstNameError] = useState('');
  const [sellerLastNameError, setSellerLastNameError] = useState('');
  const [cosellerFirstNameError, setCosellerFirstNameError] = useState('');
  const [cosellerLastNameError, setCosellerLastNameError] = useState('');

  const [addressVerificationItems, setAddressVerificationItems] = useState([]);
  const [enableAddressVerification, setEnableAddressVerification] = useState(false);

  const [cooperatingAgentCustom, setCooperatingAgentCustom] = useState({
    ...initialCustomCooperatingAgent,
  });
  const [cooperatingAgentErrors, setCooperatingAgentErrors] = useState({
    ...initialCustomCooperatingAgent,
  });
  const [previousCustomCooperatingAgent, setPreviousCustomCooperatingAgent] = useState({
    ...initialCustomCooperatingAgent,
  });
  const [previousCooperatingAgent, setPreviousCooperatingAgent] = useState({
    selectedAgent: savedFormValues?.cooperatingAgent || '',
    selectedAgentId: values.AgentId || '',
  });
  const [customCooperatingAgentForEditOrder, setCustomCooperatingAgentForEditOrder] = useState({
    ...initialCustomCooperatingAgent,
    cooperatingOffice: '',
    office: null,
  });

  const customerCheckboxLabels = {
    alternateAddressFields: 'Use alternate mailing address',
    coSellerFields: 'Include Co-seller',
  };
  const [customerCheckboxValues, setCustomerCheckboxValues] = useState({
    alternateAddressFields: savedFormValues?.alternateAddressFields || false,
    coSellerFields: savedFormValues?.coSellerFields || false,
  });
  const customerRadioLabel = {
    hasSellerInfo: 'Yes',
    noSellerInfo: 'No',
  };

  const [customerRadioValues, setCustomerRadioValues] = useState({
    hasSellerInfo: props.isRequired,
    noSellerInfo: false,
  });

  const coCustomerCheckboxLabels = {
    coSellerAlternateAddressFields: 'Add Alternate Mailing Address',
  };
  const [coCustomerCheckboxValues, setCoCustomerCheckboxValues] = useState({
    coSellerAlternateAddressFields: savedFormValues?.coSellerAlternateAddressFields || false,
  });

  /** used for FormNewOrderCustomer.ids and FormNewOrderCustomerAddress.ids
   * The ids of the form fields */
  const formNewOrderCustomerPropIds = {
    customer: {
      firstName: 'wb_sellerFirstName',
      lastName: 'wb_sellerLastName',
      email: 'wb_sellerEmail',
      phone: 'wb_sellerPhone',
    },
    customerAddress: {
      address: 'wb_streetAddressSeller',
      unit: 'wb_unitSeller',
      city: 'wb_citySeller',
      state: 'wb_stateSeller',
      zip: 'wb_zipCodeSeller',
    },
    coCustomer: {
      firstName: 'wb_coSellerFirstName',
      lastName: 'wb_coSellerLastName',
      email: 'wb_coSellerEmail',
      phone: 'wb_coSellerPhone',
    },
    coCustomerAddress: {
      address: 'wb_streetAddressCoSeller',
      unit: 'wb_unitCoSeller',
      city: 'wb_cityCoSeller',
      state: 'wb_stateCoSeller',
      zip: 'wb_zipCodeCoSeller',
    },
  };

  /** used for FormNewOrderCustomer.names and FormNewOrderCustomerAddress.names
   * The names of the form fields.  These names are inevitably used in the useForm logic */
  const formNewOrderCustomerPropNames = {
    customer: {
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      phone: 'phone',
    },
    customerAddress: {
      address: 'streetAddress',
      unit: 'unit',
      city: 'city',
      state: 'state',
      zip: 'zipCode',
    },
    coCustomer: {
      firstName: 'coCustomerFirstName',
      lastName: 'coCustomerLastName',
      email: 'coCustomerEmail',
      phone: 'coCustomerPhone',
    },
    coCustomerAddress: {
      address: 'coCustomerStreetAddress',
      unit: 'coCustomerUnit',
      city: 'coCustomerCity',
      state: 'coCustomerState',
      zip: 'coCustomerZipCode',
    },
  };

  useEffect(() => {
    if (isCustomerInfoRequired()) {
      setCustomerRadioValues({
        hasSellerInfo: true,
        noSellerInfo: false,
      });
    } else {
      setCustomerRadioValues({
        hasSellerInfo: savedFormValues.sellerInfo?.yes,
        noSellerInfo: savedFormValues.sellerInfo?.no,
      });
    }
  }, [savedFormValues.sellerInfo]);

  // Show/hide seller info fields
  const toggleSellerInfoFields = (e) => {
    setCustomerRadioValues({
      hasSellerInfo: false,
      noSellerInfo: false,
      [e.target.name]: e.target.checked,
    });

    if (e.target.name === 'hasSellerInfo') {
      setScratchFormValues({ ...scratchFormValues, sellerInfo: { yes: true, no: false } });
    } else {
      setScratchFormValues({
        ...scratchFormValues,
        sellerInfo: { yes: false, no: true },
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        alternateAddressFields: false,
        coSellerFields: false,
      });
      setErrors({});
      setCustomerCheckboxValues({ alternateAddressFields: false, coSellerFields: false });
      setShowAlternateAddressFields(false);
      setShowCoSellerFields(false);
      setShowCoSellerAlternateAddressFields(false);
      setCoCustomerCheckboxValues({ coSellerAlternateAddressFields: false });
    }
  };

  // Show/hide alternate address and co-seller fields
  const toggleCheckboxFields = (e) => {
    if (e.target.name === 'alternateAddressFields') {
      setShowAlternateAddressFields(!showAlternateAddressFields);
      if (!e.target.checked) {
        setScratchFormValues({
          ...scratchFormValues,
          zipCode: '',
          streetAddress: '',
          unit: '',
          city: '',
          state: '',
          [e.target.name]: e.target.checked,
        });
      } else {
        setScratchFormValues({ ...scratchFormValues, [e.target.name]: e.target.checked });
      }
    } else {
      setShowCoSellerFields(!showCoSellerFields);
      if (!e.target.checked) {
        setScratchFormValues({
          ...scratchFormValues,
          coCustomerFirstName: '',
          coCustomerLastName: '',
          coCustomerEmail: '',
          coCustomerPhone: '',
          coCustomerZipCode: '',
          coCustomerStreetAddress: '',
          coCustomerUnit: '',
          coCustomerCity: '',
          coCustomerState: '',
          coSellerAlternateAddressFields: false,
          [e.target.name]: e.target.checked,
        });
        setShowCoSellerAlternateAddressFields(false);
        setCoCustomerCheckboxValues({ coSellerAlternateAddressFields: false });
      } else {
        setScratchFormValues({ ...scratchFormValues, [e.target.name]: e.target.checked });
      }
    }
    setCustomerCheckboxValues({ ...customerCheckboxValues, [e.target.name]: e.target.checked });
  };

  // Show/hide co-seller's alternate mailing address fields
  const toggleCoSellerCheckboxFields = (e) => {
    if (!e.target.checked) {
      setScratchFormValues({
        ...scratchFormValues,
        coCustomerZipCode: '',
        coCustomerStreetAddress: '',
        coCustomerUnit: '',
        coCustomerCity: '',
        coCustomerState: '',
        [e.target.name]: e.target.checked,
      });
    } else {
      setScratchFormValues({ ...scratchFormValues, [e.target.name]: e.target.checked });
    }
    setShowCoSellerAlternateAddressFields(!showCoSellerAlternateAddressFields);
    setCoCustomerCheckboxValues({ ...coCustomerCheckboxValues, [e.target.name]: e.target.checked });
  };

  // Scroll to alternate address fields
  useEffect(() => {
    if (showAlternateAddressFields && alternateAddressFields.current !== null) {
      alternateAddressFields.current.scrollIntoView({
        block: width >= 768 ? 'center' : 'start',
        behavior: 'smooth',
      });
    }
  }, [showAlternateAddressFields]);

  // Scroll to seller info fields
  useEffect(() => {
    if (showSellerInfoFields && sellerInfoFields.current !== null) {
      sellerInfoFields.current.scrollIntoView({
        block: width >= 768 ? 'center' : 'start',
        behavior: 'smooth',
      });
    }
  }, [showSellerInfoFields]);

  // Scroll to co-seller fields
  useEffect(() => {
    if (showCoSellerFields && coSellerFields.current !== null) {
      coSellerFields.current.scrollIntoView({
        block: width >= 768 ? 'center' : 'start',
        behavior: 'smooth',
      });
    }
  }, [showCoSellerFields]);

  // Scroll to co-seller alternate address fields
  useEffect(() => {
    if (showCoSellerAlternateAddressFields && coSellerAlternateAddressFields.current !== null) {
      coSellerAlternateAddressFields.current.scrollIntoView({
        block: width >= 768 ? 'center' : 'start',
        behavior: 'smooth',
      });
    }
  }, [showCoSellerAlternateAddressFields]);

  useEffect(() => {
    if (scratchFormValues.isSellerCoverage) {
      let errs = { firstName: c.FIRST_NAME_REQUIRED, lastName: c.LAST_NAME_REQUIRED };

      if (props.isCustomerContactInfoRequired)
        errs = { ...errors, email: c.EMAIL_REQUIRED, phone: c.PHONE_NUMBER_REQUIRED };

      setErrors(errs);
    }
  }, [scratchFormValues.isSellerCoverage]);

  /** When the agent represents get changed, we should update our view if necessary */
  useEffect(() => {
    const isCustomerInfoOptional = !isCustomerInfoRequired();
    const hasCustomerInfo = Boolean(savedFormValues?.firstName); // Just check one of the fields...

    // If customer info is required, we should mark it with 'yes' immediately
    if (!isCustomerInfoOptional) {
      setCustomerRadioValues({
        hasSellerInfo: true,
        noSellerInfo: false,
      });
    }

    if (props.isExistingContract && isCustomerInfoOptional && !hasCustomerInfo) {
      setCustomerRadioValues({
        hasSellerInfo: false,
        noSellerInfo: true,
      });
    }

    // If customer info is optional, and we have customer info, default to 'yes'
    if (isCustomerInfoOptional && hasCustomerInfo) {
      setCustomerRadioValues({
        hasSellerInfo: true,
        noSellerInfo: false,
      });
    }
  }, [props.isCustomerNameRequired, props.isCustomerContactInfoRequired]);

  /** Update the show seller info fields boolean when selection changes */
  useEffect(() => {
    setShowSellerInfoFields(
      customerRadioValues.hasSellerInfo ||
        props.isCustomerNameRequired ||
        props.isCustomerContactInfoRequired,
    );
  }, [customerRadioValues, props.isCustomerNameRequired, props.isCustomerContactInfoRequired]);

  useEffect(() => {
    if (isEmpty(savedFormValues)) return;

    if (isEditing) {
      //Applicable only in Edit Order flow (custom cooperating agent flow)
      //user has already selected and saved custom cooperating agent. Now, if the user again tried to edit this component then set the scratchForValues.
      //In custom cooperating agent flow, scratchFormValues will not have any values as the contract does not save custom cooperating agent details.
      if (props.flow == 'Edit Order' && customCooperatingAgentForEditOrder.hasValue) {
        const editCustomCoopertingValues = {
          ...savedFormValues,
          office: customCooperatingAgentForEditOrder.office,
          cooperatingOffice: customCooperatingAgentForEditOrder.cooperatingOffice,
          AgentName:
            customCooperatingAgentForEditOrder.firstName +
            ' ' +
            customCooperatingAgentForEditOrder.lastName,
          AgentEmail: customCooperatingAgentForEditOrder.email,
        };
        setScratchFormValues({ ...editCustomCoopertingValues });
      } else {
        setScratchFormValues(savedFormValues); // reset so previous scratch values aren't carried over
      }

      setCustomerCheckboxValues({
        ...customerCheckboxValues,
        coSellerFields: savedFormValues.coSellerFields,
        alternateAddressFields: savedFormValues.alternateAddressFields,
      });

      setShowAlternateAddressFields(savedFormValues.alternateAddressFields);
      setShowCoSellerFields(savedFormValues.coSellerFields);
      setCoCustomerCheckboxValues({
        coSellerAlternateAddressFields: savedFormValues.coSellerAlternateAddressFields,
      });
      setShowCoSellerAlternateAddressFields(savedFormValues.coSellerAlternateAddressFields);
    }
  }, [isEditing]);

  /** This use effect is a hack that removes errors after the fact and reopens edit view if
   *    there are any persisting error messages.
   *  The underlying problem should be with when the error was assigned, not here. Needs to be resolved.
   */
  useEffect(() => {
    if (isCustomerInfoRequired()) {
      if (Object.keys(errors).length) {
        setIsCollapsed(false);
        setIsEditing(true);
      }
    }
  }, [errors]);

  useEffect(() => {
    if (selectedAgent) {
      setScratchFormValues({ ...scratchFormValues, cooperatingAgent: selectedAgent });
    }
    // quick fix to update displayed cooperating agent if empty when editing
    else if (
      !(Object.keys(scratchFormValues).length === 0 && scratchFormValues.constructor === Object)
    ) {
      setScratchFormValues({ ...scratchFormValues, cooperatingAgent: '' });
    }
  }, [selectedAgent]);

  /** transform the data from the card to what is needed for the parent.
   * prior to that, validate the address during submission. */
  const submit = async (skipAddressVerification = false) => {
    // check address validation necessary
    if (!skipAddressVerification) {
      const addressVerificationNeeded = performAddressVerification();
      if (addressVerificationNeeded) {
        setIsEditing(true);
        return;
      }
    }

    // Update the sellerInfo boolean
    const { hasSellerInfo, noSellerInfo } = customerRadioValues;
    let savedData = {
      ...scratchFormValues,
      sellerInfo: { yes: hasSellerInfo, no: noSellerInfo },
    };

    // send a GA event if the agent selected already exists
    if (foundCustomCooperatingAgent || !!selectedAgent) {
      fireGAEvent(ADDED_EXISTING_COOP_AGENT(props.flow));
    } else if (cooperatingAgentCustom.firstName) {
      fireGAEvent(CREATED_CUSTOM_AGENT(props.flow));
    }

    if (foundCustomCooperatingAgent) {
      const agentName = `${foundCustomCooperatingAgent?.firstName?.trim()} ${foundCustomCooperatingAgent?.lastName?.trim()}`;

      savedData = {
        ...savedData,
        AgentName: agentName,
        AgentEmail: foundCustomCooperatingAgent.email,
        AgentPhone:
          foundCustomCooperatingAgent.phoneNumbers.length > 0
            ? foundCustomCooperatingAgent.phoneNumbers[0]
            : '',
        AgentId: foundCustomCooperatingAgent.realEstateAgentID,
        cooperatingAgent: agentName,
      };
    }

    //dont save agent/office details for custom coopertating agent flow on save and continue button click. It will be saved on submit click
    if (
      !foundCustomCooperatingAgent &&
      !scratchFormValues.AgentId &&
      cooperatingAgentCustom.firstName &&
      cooperatingAgentCustom.lastName &&
      cooperatingAgentCustom.email
    ) {
      const cooperatingAgentName = `${cooperatingAgentCustom.firstName?.trim()} ${cooperatingAgentCustom?.lastName?.trim()}`;
      savedData = {
        ...savedData,
        cooperatingAgent: cooperatingAgentName?.trim(),
        office: { id: '', type: '' },
        ...unsetCustomCooperatingAgent,
      };

      //for display view in Edit-Order flow
      setCustomCooperatingAgentForEditOrder({
        ...cooperatingAgentCustom,
        cooperatingOffice: scratchFormValues.cooperatingOffice,
        office: { ...scratchFormValues.office, name: scratchFormValues.cooperatingOffice },
        agentlist: null,
        hasValue: true,
      });
    } else {
      //clear our edit data. This will be fetched from contract
      setCustomCooperatingAgentForEditOrder({
        ...initialCustomCooperatingAgent,
        cooperatingOffice: '',
        office: {},
        hasValue: false,
      });
    }

    const success = await props.handleSubmit(savedData); // TODO: new order should be utilizing this instead of setValues
    clearPreviousCooperatingAgents();
  };

  const hasCustomCooperatingAgentErrors = () =>
    cooperatingAgentErrors.firstName ||
    cooperatingAgentErrors.lastName ||
    cooperatingAgentErrors.email;

  const validateAndAssignCustomCooperatingAgent = (initiatingAndCooperatingAgentMatchParam) => {
    let isValid = true;
    let firstNameError = '';
    let lastNameError = '';
    let emailError = '';

    if (hasCustomCooperatingAgentErrors()) {
      isValid = false;
      return isValid;
    }
    if (!cooperatingAgentCustom.firstName) {
      firstNameError = customCooperatingAgentErrorMessage.firstName;
    }
    if (!cooperatingAgentCustom.lastName) {
      lastNameError = customCooperatingAgentErrorMessage.lastName;
    }
    if (!cooperatingAgentCustom.email) {
      emailError = customCooperatingAgentErrorMessage.email;
    }

    if (firstNameError || lastNameError || emailError) {
      setCooperatingAgentErrors({
        firstName: firstNameError,
        lastName: lastNameError,
        email: emailError,
      });
      isValid = false;
      return isValid;
    }

    //save agent and office details
    setCooperatingAgentCustom({
      ...cooperatingAgentCustom,
      office: scratchFormValues.office,
      cooperatingOffice: scratchFormValues.cooperatingOffice,
      agentlist: null,
      hasValue: true,
    });

    //clear previously selected agent
    setScratchFormValues({ ...scratchFormValues, AgentId: '', AgentName: '', AgentEmail: '' });
    changeSelectedAgent('');

    //check if there is an existing agent with the inputted firstName, lastName and email. If so, use the existing agent
    foundCustomCooperatingAgent = scratchFormValues.agentlist?.find(
      (a) =>
        a?.firstName?.trim().toUpperCase() ==
          cooperatingAgentCustom.firstName.trim().toUpperCase() &&
        a?.lastName?.trim().toUpperCase() == cooperatingAgentCustom.lastName.trim().toUpperCase() &&
        a?.email?.trim().toUpperCase() == cooperatingAgentCustom.email.trim().toUpperCase(),
    );

    //exact match case
    if (foundCustomCooperatingAgent) {
      //check if the found custom cooperating agent matches selected initiating agent
      let initiatingAndCooperatingAgentMatch =
        props.initiatingAgentId === foundCustomCooperatingAgent?.realEstateAgentID &&
        foundCustomCooperatingAgent?.realEstateAgentID;
      if (initiatingAndCooperatingAgentMatch) {
        // If initiating and cooperating agent matches, then display error later
        if (initiatingAndCooperatingAgentMatch) {
          changeSelectedAgent('');
          initiatingAndCooperatingAgentMatchParam.key = true;
          return isValid;
        }
      } else {
        setIsAddAgentModalActive(!isAddAgentModalActive);
        setCooperatingAgentCustom({ ...initialCustomCooperatingAgent });
      }

      const agentName = `${foundCustomCooperatingAgent?.firstName?.trim()} ${foundCustomCooperatingAgent?.lastName?.trim()}`;
      changeSelectedAgent(agentName);

      //set scratch form values (mimicing existing agent selection steps)
      const newScratchFormValues = {
        ...scratchFormValues,
        AgentName: agentName,
        AgentEmail: foundCustomCooperatingAgent.email,
        AgentPhone:
          foundCustomCooperatingAgent.phoneNumbers.length > 0
            ? foundCustomCooperatingAgent.phoneNumbers[0]
            : '',
        AgentId: foundCustomCooperatingAgent.realEstateAgentID,
      };

      setScratchFormValues(newScratchFormValues);
      scratchFormValues.cooperatingAgent = agentName;
      changeSelectedAgent(agentName);

      //set values. Other card's submit to update order requires agentID and officeId
      const newValues = {
        ...values,
        AgentName: agentName,
        AgentEmail: foundCustomCooperatingAgent.email,
        AgentPhone:
          foundCustomCooperatingAgent.phoneNumbers.length > 0
            ? foundCustomCooperatingAgent.phoneNumbers[0]
            : '',
        AgentId: foundCustomCooperatingAgent.realEstateAgentID,
        office: { id: scratchFormValues.office.id, type: scratchFormValues.office.type },
        agentlist: null,
        initAgentId: foundCustomCooperatingAgent.realEstateAgentID,
      };
      setValues(newValues);

      //clear NewOrderTemplate data.
      if (props.onPersistCustomCooporatingAgent) {
        props.onPersistCustomCooporatingAgent({ ...unsetCustomCooperatingAgent, hasValue: false });
      }
    } else {
      //persist custom coorporating agent details in NewOrderTemplate. This will be saved to server on submit
      if (props.onPersistCustomCooporatingAgent) {
        props.onPersistCustomCooporatingAgent({
          agentFirstName: cooperatingAgentCustom.firstName,
          agentLastName: cooperatingAgentCustom.lastName,
          agentEmail: cooperatingAgentCustom.email,
          hasValue: true,
        });
      }
    }

    return isValid;
  };

  const onSetIsAddAgentModalActive = () => {
    setIsAddAgentModalActive(true);

    //clear if an existing agent was selected from agentList
    setScratchFormValues({
      ...scratchFormValues,
      AgentEmail: '',
      AgentId: '',
      AgentName: '',
      AgentPhone: '',
    });
  };

  const clearPreviousCooperatingAgents = () => {
    setPreviousCustomCooperatingAgent({ ...initialCustomCooperatingAgent });
    setPreviousCooperatingAgent({ selectedAgent: '', selectedAgentId: '' });
    foundCustomCooperatingAgent = null;
  };

  const handleSave = (event) => {
    handleSubmit(event);

    const hasCustomerName =
      scratchFormValues?.firstName?.trim() && scratchFormValues?.lastName?.trim();
    const hasCooperatingOffice = !!scratchFormValues?.cooperatingOffice?.trim();
    let hasCooperatingAgent = !!scratchFormValues?.cooperatingAgent?.trim();
    let initiatingAndCooperatingAgentMatch =
      props.initiatingAgentId === scratchFormValues?.AgentId && scratchFormValues?.AgentId;

    if (
      props.showCooperatingFields &&
      !selectedAgent?.trim() &&
      hasCooperatingOffice &&
      isAddAgentModalActive
    ) {
      let initiatingAndCooperatingAgentMatchParam = { key: initiatingAndCooperatingAgentMatch };
      if (validateAndAssignCustomCooperatingAgent(initiatingAndCooperatingAgentMatchParam)) {
        hasCooperatingAgent = true;
        initiatingAndCooperatingAgentMatch = initiatingAndCooperatingAgentMatchParam.key;
      } else {
        setIsEditing(true);
        return;
      }
    } else {
      //clear out OrderNewTemplate custom cooperating agent details
      if (props.onPersistCustomCooporatingAgent) {
        props.onPersistCustomCooporatingAgent({ ...unsetCustomCooperatingAgent, hasValue: false });
      }
    }

    //in edit order flow, set the parent component's(DrawerOrder) cooperatingAgentCustom. Assigning it synchronously cause we need these values in this event handler
    if (props.cooperatingAgentCustom) {
      props.cooperatingAgentCustom.firstName = cooperatingAgentCustom.firstName;
      props.cooperatingAgentCustom.lastName = cooperatingAgentCustom.lastName;
      props.cooperatingAgentCustom.email = cooperatingAgentCustom.email;
    }

    const cooperatingInfoValid = hasCooperatingAgent === hasCooperatingOffice; // if both filled or both not filled
    const { hasSellerInfo, noSellerInfo } = customerRadioValues;

    const sellerInfoYesNo = { sellerInfo: { yes: hasSellerInfo, no: noSellerInfo } }; // to add to setValue for updates

    let validSave = false;

    const allNameFiledsValid = validateAllNameFields();

    // TODO: most of the logic below should be handled without needing all of these field checks. Common between the logic
    if (
      !isAllFieldFormatsValid(errors) ||
      !doesCoCustomerZipExist ||
      !doesZipExist ||
      !allNameFiledsValid
    ) {
      setIsEditing(true);
      // setIsOkToSubmit(false);
      return;
    }

    if (
      values.alternateAddressFields &&
      !(values.streetAddress && values.city && values.state && values.zipCode)
    ) {
      setIsEditing(true);
      return;
    }

    if (
      values.coSellerAlternateAddressFields &&
      !(
        values.coCustomerStreetAddress &&
        values.coCustomerCity &&
        values.coCustomerState &&
        values.coCustomerZipCode
      )
    ) {
      setIsEditing(true);
      return;
    }

    // If SELLER agent was entered but no SELLER agent id was selected, open add agent box
    if (!isAddAgentModalActive && selectedAgent && !values.AgentId && props.showCooperatingFields) {
      setIsAgentVerificationActive(true);
      return;
    }

    // If initiating and cooperating agent matches, then display error
    if (initiatingAndCooperatingAgentMatch) {
      addErrorToQueue({
        title: 'Attention Required',
        message: c.INITIATING_COOPERATING_OFFICE_MATCH,
      });
      setIsEditing(true);
      return;
    }

    // If cooperating information not properly entered, then end
    if (!cooperatingInfoValid) {
      //Ensure error message shows up. sometimes it does not show up
      if (!hasCooperatingAgent) {
        setErrors({ ...errors, cooperatingAgent: c.COOPERATING_AGENT_REQUIRED });
      }

      console.log(
        `cooperating information incomplete office=${hasCooperatingOffice}, agent=${hasCooperatingAgent}`,
      );
      setIsEditing(true);
      return;
    }

    if (
      scratchFormValues.coSellerFields &&
      !(scratchFormValues.coCustomerFirstName && scratchFormValues.coCustomerLastName)
    ) {
      setIsEditing(true);
      return;
    }

    // if agent is not represented & all fields have valid data then submit -- no required validation here
    if (!isCustomerInfoRequired()) {
      // add a condition here to check if seller is not selected yes
      // TODO: the seller's coverage check should be used in the prop for isRequired instead, otherwise validation check is needlessly duplicated
      if (!scratchFormValues.isSellerCoverage) {
        // If not required, and we have sellers info with custoer name, or no seller info, then submit
        if ((hasSellerInfo && hasCustomerName) || noSellerInfo) {
          setIsEditing(false);
          validSave = true;
          submit();
        } else {
          setIsEditing(true);
          // setIsOkToSubmit(false);
        }
      } else if (
        hasSellerInfo &&
        isAllRequiredValuesPresent(values, props.isCustomerContactInfoRequired)
      ) {
        // If has sellers coverage, has seller info, and has name and either phone or email
        const update = { ...scratchFormValues, ...sellerInfoYesNo, isSellerCoverage: false };
        if (!props.isExistingContract) props.setValues({ sellerInfo: update });
        setScratchFormValues({ ...update });
        setIsEditing(false);
        validSave = true;
        submit();
      } else {
        setIsEditing(true);
      }
    } else if (
      isAllRequiredValuesPresent(values, props.isCustomerContactInfoRequired) &&
      Object.keys(errors).length === 0
    ) {
      // this condition is used if no errors are there and form has valid  values to submit  if agent is representing
      setIsEditing(false);

      validSave = true;
      submit();
    } else {
      setIsEditing(true);
    }

    if (!props.isExistingContract && validSave) {
      //this is required when buyer info is added and closing date needs to be selected
      let exactMatchAgentValues = {};
      if (foundCustomCooperatingAgent) {
        const agentName = `${foundCustomCooperatingAgent?.firstName?.trim()} ${foundCustomCooperatingAgent?.lastName?.trim()}`;
        exactMatchAgentValues = {
          AgentName: agentName,
          AgentEmail: foundCustomCooperatingAgent.email,
          AgentPhone:
            foundCustomCooperatingAgent.phoneNumbers.length > 0
              ? foundCustomCooperatingAgent.phoneNumbers[0]
              : '',
          AgentId: foundCustomCooperatingAgent.realEstateAgentID,
          office: { id: scratchFormValues.office.id, type: scratchFormValues.office.type },
          agentlist: null,
          initAgentId: foundCustomCooperatingAgent.realEstateAgentID,
        };
      }
      props.setValues({ sellerInfo: { ...values, ...sellerInfoYesNo, ...exactMatchAgentValues } });
    }
  };

  const onLoadCustomerAddressZipDetails = (res) => {
    if (res) {
      setDoesZipExist(true);
      setScratchFormValues({
        ...scratchFormValues,
        state: res.state,
        city: res.city,
        isDisabled: true,
      });
    } else {
      setDoesZipExist(false);
      setScratchFormValues({
        ...scratchFormValues,
        state: '',
        city: '',
        isDisabled: false,
      });
    }
  };

  const onLoadCoCustomerAddressZipDetails = (res) => {
    if (res) {
      setDoesCoCustomerZipExist(true);
      setScratchFormValues({
        ...scratchFormValues,
        coCustomerState: res.state,
        coCustomerCity: res.city,
        isCoCustomerDisabled: true,
      });
    } else {
      setDoesCoCustomerZipExist(false);
      setScratchFormValues({
        ...scratchFormValues,
        coCustomerState: '',
        coCustomerCity: '',
        isCoCustomerDisabled: false,
      });
    }
  };

  const data = {
    values,
    setOptionValues: setCustomerCheckboxValues,
    setSellerOptionValues: setCustomerRadioValues,
    setValues,
    handleSubmit: handleSave,
    optionValues: customerCheckboxValues,
    sellerOptionValues: customerRadioValues,
    setShowAlternateAddressFields,
    setShowCoSellerFields,
    setShowSellerInfoFields,
    setCoSellerOptionValues: setCoCustomerCheckboxValues,
    setShowCoSellerAlternateAddressFields,
    coSellerOptionValues: coCustomerCheckboxValues,
    changeSelectedAgent,
    setErrors,
    overrideDefaultToggleCollapsed: true,
    setTouched,
  };

  /** TODO: CardNewOrderBuyerInfo and CardNewOrderSellerInfo should be refactored into
   * a CardNewOrderCustomerInfo, as their logic should entirely be identical except for
   * the headers, labels, and data values being written (or data can be generalized
   * and then remapped in parent). This is left separated for now, hence the duplication.
   */
  async function updateOfficeAgents(offices) {
    let agentsList = [];
    if (offices[0] && offices[0].id !== '') {
      // getting list of agent as per selcted office
      await AgentApi.searchAgents(
        {
          officeId: offices[0].id,
          officeType: offices[0].type,
          activeInactive: 'A',
        },
        {
          tags: { source: 'CardNewOrderSellerInfo#handleOfficeSelect' },
        },
      ).then((res) => {
        agentsList = res.agentsList;
      });
      setScratchFormValues({
        ...scratchFormValues,
        office: offices[0],
        cooperatingOffice: offices[0].name,
        agentlist: agentsList,
      });
    }
  }

  async function handleOfficeSelect(offices) {
    await updateOfficeAgents(offices);

    setIsDrawerActive(false);

    // Clear the selected agent when the selected office changes
    changeSelectedAgent('');
  }

  useEffect(() => {
    if (scratchFormValues.office && !scratchFormValues.agentlist) {
      updateOfficeAgents([scratchFormValues.office]);
    }
  }, [scratchFormValues.agentlist]);

  /** When adding agent to office, we want to update the agent list to include the new agent, as well as select the agent */
  function addNewAgentToOffice(agent) {
    // Add the agent to the list of agents
    const updatedValues = {
      ...scratchFormValues,
      agentlist: [...scratchFormValues.agentlist, agent],
    };

    // Make the selection (done this way so that we are sync up with the auto-suggest logic)
    selectSuggestion(
      undefined,
      { suggestion: agent },
      changeSelectedAgent,
      setScratchFormValues,
      updatedValues,
    );
  }

  const onChangeInput = (e) => {
    if (e) {
      setScratchFormValues({
        ...scratchFormValues,
        [e.target.name]: e.target.value,
      });

      if (
        ['firstName', 'lastName', 'coCustomerFirstName', 'coCustomerLastName'].some(
          (name) => e.target.name === name,
        )
      ) {
        validateNameFields(e.target.name, e.target.value);
      }

      setErrors({
        ...errors,
        [e.target.name]: '',
      });
    }
  };

  // Check the validity of the first name and return the error message if it does not meet the requirements.
  const validateFirstName = (firstName) => {
    let errorMsg = '';
    if (!firstName) {
      errorMsg = c.FIRST_NAME_REQUIRED;
    } else if (firstName.length > c.FIRST_NAME_MAX_LENGTH) {
      errorMsg = c.FIRST_NAME_LENGTH_EXCEEDED;
    } else if (validateName(firstName)) {
      errorMsg = c.CUSTOMER_FIRST_NAME_SPECIAL_CHARACTERS_NOT_ALLOWED;
    }
    return errorMsg;
  };

  // Check the validity of the last name and return the error message if it does not meet the requirements.
  const validateLastName = (lastName) => {
    let errorMsg = '';
    if (!lastName) {
      errorMsg = c.LAST_NAME_REQUIRED;
    } else if (lastName.length > c.LAST_NAME_MAX_LENGTH) {
      errorMsg = c.LAST_NAME_LENGTH_EXCEEDED;
    } else if (validateName(lastName)) {
      errorMsg = c.CUSTOMER_LAST_NAME_SPECIAL_CHARACTERS_NOT_ALLOWED;
    }
    return errorMsg;
  };

  // Check the validity of the field and set its state
  const validateNameFields = (fieldName, fieldValue) => {
    switch (fieldName) {
      case 'firstName':
        setSellerFirstNameError(validateFirstName(fieldValue));
        break;
      case 'lastName':
        setSellerLastNameError(validateLastName(fieldValue));
        break;
      case 'coCustomerFirstName':
        setCosellerFirstNameError(validateFirstName(fieldValue));
        break;
      case 'coCustomerLastName':
        setCosellerLastNameError(validateLastName(fieldValue));
        break;
      default:
        console.log(
          `deafult condition encountered in validateNameFields, field ${fieldName} value: ${fieldValue}`,
        );
    }
  };

  // validate all name fields and set their state
  const validateAllNameFields = () => {
    let allNameFieldsValid = true;
    let errMsg = '';

    if (
      showSellerInfoFields &&
      (savedFormValues.firstName !== scratchFormValues.firstName || !scratchFormValues.firstName)
    ) {
      // validate the first name
      errMsg = validateFirstName(scratchFormValues.firstName);
      setSellerFirstNameError(errMsg);
      if (errMsg.length > 0) {
        allNameFieldsValid = false;
        errMsg = '';
      }
    }

    if (
      showSellerInfoFields &&
      (savedFormValues.lastName !== scratchFormValues.lastName || !scratchFormValues.lastName)
    ) {
      // validate the last name
      errMsg = validateLastName(scratchFormValues.lastName);
      setSellerLastNameError(errMsg);
      if (errMsg.length > 0) {
        allNameFieldsValid = false;
        errMsg = '';
      }
    }

    if (
      scratchFormValues.coSellerFields &&
      (savedFormValues.coCustomerFirstName !== scratchFormValues.coCustomerFirstName ||
        !scratchFormValues.coCustomerFirstName)
    ) {
      // validate the coCustomer first name
      errMsg = validateFirstName(scratchFormValues.coCustomerFirstName);
      setCosellerFirstNameError(errMsg);
      if (errMsg.length > 0) {
        allNameFieldsValid = false;
        errMsg = '';
      }
    }

    if (
      scratchFormValues.coSellerFields &&
      (savedFormValues.coCustomerLastName !== scratchFormValues.coCustomerLastName ||
        !scratchFormValues.coCustomerLastName)
    ) {
      // validate the coCustomer last name
      errMsg = validateLastName(scratchFormValues.coCustomerLastName);
      setCosellerLastNameError(errMsg);
      if (errMsg.length > 0) {
        allNameFieldsValid = false;
        errMsg = '';
      }
    }
    return allNameFieldsValid;
  };

  const handleSetAddress = (address, formFieldAddressNames) => {
    if (address) {
      // due to co-customers, derive the property name via the form field name
      const addressValues = {
        [formFieldAddressNames.address]: address.streetAddress,
        [formFieldAddressNames.unit]: address.unit,
        [formFieldAddressNames.city]: address.city,
        [formFieldAddressNames.state]: address.state,
        [formFieldAddressNames.zip]: address.zip,
      };

      setValues({
        ...values,
        ...deleteUndefinedValues(addressValues), // clear undefined values to avoid replacement
      });
    }
  };

  const handleStreetAddressError = (err, formFieldStreetAddressName) => {
    setErrors({
      ...errors,
      [formFieldStreetAddressName]: err,
    });
  };

  /** performs address verification.
   * Returns true if address verification necessary and executing.
   * Otherwise, returns false if no address verification necessary (i.e. no addresses entered) */
  const performAddressVerification = () => {
    const addressesToVerify = [];
    if (showAlternateAddressFields) {
      addressesToVerify.push({
        overrideModalId: 'validate-address--seller',
        overrideModalHeading: 'Seller Address Verification',
        key: CustomerKey.Customer,
        address: {
          streetAddress: scratchFormValues.streetAddress,
          unit: scratchFormValues.unit,
          city: scratchFormValues.city,
          state: scratchFormValues.state,
          zip: scratchFormValues.zipCode,
        },
      });
    }
    if (showCoSellerAlternateAddressFields) {
      addressesToVerify.push({
        overrideModalId: 'validate-address--coseller',
        overrideModalHeading: 'Co-Seller Address Verification',
        key: CustomerKey.CoCustomer,
        address: {
          streetAddress: scratchFormValues.coCustomerStreetAddress,
          unit: scratchFormValues.coCustomerUnit,
          city: scratchFormValues.coCustomerCity,
          state: scratchFormValues.coCustomerState,
          zip: scratchFormValues.coCustomerZipCode,
        },
      });
    }
    setAddressVerificationItems(addressesToVerify);

    if (addressesToVerify.length !== 0) {
      setEnableAddressVerification(true);
      return true;
    }
    return false;
  };

  const updateAddressFromVerification = ({ key, verifiedAddress }) => {
    switch (key) {
      case CustomerKey.Customer:
        setScratchFormValues({
          ...scratchFormValues,
          streetAddress: verifiedAddress.streetAddress,
          unit: verifiedAddress.unit,
          city: verifiedAddress.city,
          state: verifiedAddress.state,
          zipCode: verifiedAddress.zip,
          customerAddressUUID: verifiedAddress.meta?.addressId,
        });
        break;
      case CustomerKey.CoCustomer:
        setScratchFormValues({
          ...scratchFormValues,
          coCustomerStreetAddress: verifiedAddress.streetAddress,
          coCustomerUnit: verifiedAddress.unit,
          coCustomerCity: verifiedAddress.city,
          coCustomerState: verifiedAddress.state,
          coCustomerZipCode: verifiedAddress.zip,
          coCustomerAddressUUID: verifiedAddress.meta?.addressId,
        });
        break;
    }
  };

  const onClearCustomCooperatingAgentFields = () => {
    foundCustomCooperatingAgent = null;
    setCooperatingAgentErrors({ ...initialCustomCooperatingAgent });
    setCooperatingAgentCustom({ ...initialCustomCooperatingAgent });

    //save a copy if the user decides to cancel changes
    setPreviousCustomCooperatingAgent(cooperatingAgentCustom);
    // setPreviousCooperatingAgent({selectedAgent, selectedAgentId: values.AgentId}); // TODO Why are we doing this? Removed because this caused a bug during ARE-11726
    setIsAddAgentModalActive(false);
  };

  const onClear = () => {
    setCustomerCheckboxValues({ alternateAddressFields: false, coSellerFields: false });
    setCustomerRadioValues({ hasSellerInfo: props.isRequired, noSellerInfo: false });
    setShowAlternateAddressFields(false);
    setShowCoSellerFields(false);
    setShowCoSellerAlternateAddressFields(false);
    setCoCustomerCheckboxValues({ coSellerAlternateAddressFields: false });
    changeSelectedAgent('');
    isCustomerInfoRequired ? setShowSellerInfoFields(true) : setShowSellerInfoFields(false);
    setScratchFormValues({
      sellerInfo: { yes: isCustomerInfoRequired(), no: false },
    });
    setTouched({});
    setValues({});
    setErrors?.({});
    onClearCustomCooperatingAgentFields();
  };

  const toggleCustomCooperatingAgent = () => {
    setIsAddAgentModalActive(!isAddAgentModalActive);
    setCooperatingAgentCustom({ ...initialCustomCooperatingAgent });
    setCooperatingAgentErrors({ ...initialCustomCooperatingAgent });
    foundCustomCooperatingAgent = null;

    //clear any previously selected custom cooperating agent details
    const newValues = {
      ...scratchFormValues,
      AgentName: '',
      AgentEmail: '',
      AgentPhone: '',
      AgentId: '',
      isCustomCooperatingAgentFlow: !isAddAgentModalActive,
    };
    setScratchFormValues(newValues);
    changeSelectedAgent('');
    setPreviousCustomCooperatingAgent({ ...cooperatingAgentCustom, hasValue: true });
    setPreviousCooperatingAgent({ selectedAgent: selectedAgent, selectedAgentId: values.AgentId });

    //Applicable only in Edit Order flow.
    //Switching to existing agent selection flow from custom cooperating agent. Loading office and its agents.
    if (props.flow == 'Edit Order' && customCooperatingAgentForEditOrder.hasValue) {
      updateOfficeAgents([customCooperatingAgentForEditOrder.office]);
    }
  };

  useEffect(() => {
    if (scratchFormValues.office && scratchFormValues.agentlist?.length === 0) {
      updateOfficeAgents([scratchFormValues.office]);
    }
  }, [scratchFormValues.AgentId]);

  useEffect(() => {
    //needed for validation. This determines if custom cooperating agent/ existing cooperating agent validations needs to be checked
    setValues({ ...values, isCustomCooperatingAgentFlow: isAddAgentModalActive });
    setErrors?.({});
  }, [isAddAgentModalActive]);

  const getCooperatingAgentName = () => {
    if (
      cooperatingAgentCustom.hasValue &&
      !scratchFormValues.AgentId &&
      !foundCustomCooperatingAgent
    ) {
      return (
        <div>
          <div>
            {`${cooperatingAgentCustom.firstName} ${cooperatingAgentCustom.lastName} (to be invited)`}
          </div>
          <div>{cooperatingAgentCustom.email}</div>
        </div>
      );
    } else {
      return savedFormValues.cooperatingAgent;
    }
  };

  const onClose = () => {
    setIsEditing(!isEditing);

    if (scratchFormValues.AgentId || foundCustomCooperatingAgent?.AgentId) {
      changeSelectedAgent(savedFormValues.cooperatingAgent);
      setIsAddAgentModalActive(false);
      setCooperatingAgentCustom({ ...initialCustomCooperatingAgent });
      setCooperatingAgentErrors({ ...initialCustomCooperatingAgent });
      foundCustomCooperatingAgent = null;
    } else if (previousCustomCooperatingAgent.hasValue || cooperatingAgentCustom.hasValue) {
      //reset custom cooperating agent
      setCooperatingAgentCustom({ ...previousCustomCooperatingAgent });
      const agentName = `${cooperatingAgentCustom.firstName} ${cooperatingAgentCustom.lastName}`;
      changeSelectedAgent(agentName?.trim());
      setIsAgentVerificationActive(agentName?.trim() !== '');
    }
  };

  const onEdit = () => {
    setIsEditing(!isEditing);

    if (previousCooperatingAgent.selectedAgent && previousCooperatingAgent.selectedAgentId) {
      changeSelectedAgent(previousCooperatingAgent.selectedAgent);
      setValues({ ...values, AgentId: previousCooperatingAgent.selectedAgentId });
    }

    //default to cooperating agent flow when no selection is made yet
    if (
      (!foundCustomCooperatingAgent || !scratchFormValues.AgentId) &&
      !cooperatingAgentCustom.hasValue
    ) {
      setIsAddAgentModalActive(false);
      return;
    }

    //switch to custom cooperating agent view if selectedAgentId is not assigned
    if (!scratchFormValues?.AgentId && !foundCustomCooperatingAgent) {
      setPreviousCustomCooperatingAgent({ ...cooperatingAgentCustom, hasValue: true });
      setIsAddAgentModalActive(true);
    }
  };

  const getCooperatingOfficeInEditMode = () => {
    //return saved data if it is Edit order with custom cooperating agent flow
    if (props.flow == 'Edit Order' && customCooperatingAgentForEditOrder.hasValue) {
      return customCooperatingAgentForEditOrder.cooperatingOffice;
    }

    return scratchFormValues.cooperatingOffice || '';
  };

  const getOfficeInDisplayMode = () => {
    //return saved data if it is Edit order with custom cooperating agent flow
    if (props.flow == 'Edit Order' && customCooperatingAgentForEditOrder.hasValue) {
      return customCooperatingAgentForEditOrder.cooperatingOffice;
    }

    return savedFormValues.cooperatingOffice || '';
  };

  const isCooperatingAgentDisabled = () => {
    //custom cooperating already selected and now user wants to select existing agent
    if (props.flow == 'Edit Order') {
      return !scratchFormValues.cooperatingOffice && !customCooperatingAgentForEditOrder.hasValue;
    }

    return !scratchFormValues.cooperatingOffice;
  };

  //clear custom cooperating fields on unload
  useEffect(() => {
    return () => {
      setCustomCooperatingAgentForEditOrder({
        ...initialCustomCooperatingAgent,
        cooperatingOffice: '',
        office: undefined,
      });
      setScratchFormValues({ ...scratchFormValues, agentlist: null });
    };
  }, []);

  return (
    <>
      <CardNewOrder
        alertText={props.alertText}
        title="Seller Information"
        isEditing={isEditing}
        toggleEditing={onEdit}
        isCollapsed={isCollapsed}
        toggleCollapsed={() => {
          setIsCollapsed(!isCollapsed);
          setIsEditing(false);
        }}
        isSmall={props.isSmall}
        disableEdit={props.disableEdit}
        onCancel={props.onCancel}
        data={data}
        isRequired={isCustomerInfoRequired()}
        cancelModal={props.cancelModal}
        onClear={onClear}
        onClose={onClose}
      >
        {!isEditing ? (
          <div
            className={classNames([
              'sm:flex sm:flex-col sm:flex-1 sm:-my-2',
              props.isSmall && 'sm:-mx-4',
              isCollapsed && 'xs-max:hidden',
            ])}
          >
            {/* Seller information */}
            <div className="sm:flex w-full sm:py-2">
              <div className="sm:w-1/3 sm:px-4">
                <REText variant="label" id="card-new-order-seller-info__seller-name-test">
                  {ORDER_VIEW_LABELS.SELLER_NAME}
                </REText>
                <REText>
                  {savedFormValues.firstName} {savedFormValues.lastName}
                </REText>
              </div>

              <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                <REText
                  variant="label"
                  id="card-new-order-seller-info__seller-contact-information-text"
                >
                  {ORDER_VIEW_LABELS.SELLER_CONTACT}
                </REText>
                <REText>{savedFormValues.email}</REText>
                <REText>{formatPhoneNumber(savedFormValues.phone)}</REText>
              </div>

              {savedFormValues.alternateAddressFields && (
                <div className="sm:w-1/3 sm:px-4">
                  <REText variant="label">{ORDER_VIEW_LABELS.SELLER_ADDRESS}</REText>
                  <REText id="card-new-order-seller-info__seller-alternate-mailing-text">
                    {savedFormValues.streetAddress}{' '}
                    {savedFormValues.unit ? `Unit # ${savedFormValues.unit}` : ''}
                    <br />
                    {`${savedFormValues.city} ${savedFormValues.state} ${savedFormValues.zipCode}`}
                  </REText>
                </div>
              )}
            </div>

            {/* Co-Seller Information */}
            {savedFormValues.coSellerFields && (
              <div className="sm:flex w-full sm:py-2">
                <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                  <REText variant="label">{ORDER_VIEW_LABELS.CO_SELLER_NAME}</REText>
                  <REText id="card-new-order-seller-info__co-seller-text" className="md-max:text-sm">
                    {savedFormValues.coCustomerFirstName} {savedFormValues.coCustomerLastName}
                  </REText>
                </div>

                <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                  <REText variant="label">{ORDER_VIEW_LABELS.CO_SELLER_CONTACT}</REText>
                  <REText id="card-new-order-seller-info__co-seller-contact-information-email-text">
                    {savedFormValues.coCustomerEmail}
                  </REText>
                  <REText id="card-new-order-seller-info__co-seller-contact-information-phone-text">
                    {formatPhoneNumber(savedFormValues.coCustomerPhone)}
                  </REText>
                </div>

                {savedFormValues.coSellerAlternateAddressFields && (
                  <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                    <REText variant="label">{ORDER_VIEW_LABELS.CO_SELLER_ADDRESS}</REText>
                    <REText id="card-new-order-seller-info__co-seller-alternate-mailing-address-text">
                      {savedFormValues.coCustomerStreetAddress}{' '}
                      {savedFormValues.coCustomerUnit
                        ? `Unit # ${savedFormValues.coCustomerUnit}`
                        : ''}
                      <br />
                      {`${savedFormValues.coCustomerCity} ${savedFormValues.coCustomerState} ${savedFormValues.coCustomerZipCode}`}
                    </REText>
                  </div>
                )}
              </div>
            )}

            {/* Cooperating Office/Agent */}
            <div className={!props.showCooperatingFields ? 'hidden' : 'sm:flex w-full sm:py-2'}>
              <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                <REText variant="label">{ORDER_FORM_FIELDS.COOPERATING_OFFICE}</REText>
                <REText id="card-new-order-seller-info__cooperating-seller-office-text">
                  {getOfficeInDisplayMode()}
                </REText>
              </div>

              <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                <REText variant="label">{ORDER_FORM_FIELDS.COOPERATING_AGENT}</REText>
                <REText id="card-new-order-seller-info__cooperating-seller-agent-text">
                  {getCooperatingAgentName()}
                </REText>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap -mx-4">
            <div className="seller-info-fields flex lg:flex-wrap w-full mt-1 flex-col lg:grid lg:grid-cols-2">
              <div
                className={classNames([
                  !props.showCooperatingFields
                    ? 'hidden'
                    : 'w-full px-4 py-2 cursor-pointer order-0 lg:row-start-1 lg:col-start-1',
                ])}
                onClick={() => setIsDrawerActive(!isDrawerActive)}
              >
                <div className="pointer-events-none">
                  <Input
                    formField={true}
                    formFieldMessageId={getFormInputErrorId('wb_sellerCooperatingOffice')}
                    id="wb_sellerCooperatingOffice"
                    name="cooperatingOffice"
                    label={ORDER_FORM_FIELDS.COOPERATING_OFFICE}
                    value={getCooperatingOfficeInEditMode()}
                    autocomplete="off"
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div
                className={
                  !props.showCooperatingFields
                    ? 'hidden'
                    : 'px-4 py-2 order-1 lg:col-start-2 lg:row-start-1 lg:row-span-3'
                }
              >
                {!isAddAgentModalActive ? (
                  <AgentAutosuggest
                    id="wb_sellerCooperatingAgent"
                    idPrefix={"cooperating-agent"}
                    typeAheadAddress={typeAheadAddress}
                    setTypeAheadAddress={setTypeAheadAddress}
                    selectedAgent={selectedAgent}
                    changeSelectedAgent={changeSelectedAgent}
                    agentHelpText={errors.cooperatingAgent}
                    setAgentHelpText={setAgentHelpText}
                    agentOffice={scratchFormValues.cooperatingOffice}
                    agentlist={scratchFormValues.agentlist}
                    placeholder="Cooperating Agent"
                    setValues={setScratchFormValues}
                    values={values}
                    addAgentEnabled={true}
                    setIsAddAgentModalActive={onSetIsAddAgentModalActive}
                    isDisabled={isCooperatingAgentDisabled()}
                    buttonText="Invite Cooperating Agent"
                    subText="Invite the cooperating agent to associate themselves to the order"
                    noResultsFoundHelperText="Try searching for a different agent or send invitation to register"
                  />
                ) : (
                  <CustomCooperatingAgent
                    cooperatingAgentCustom={cooperatingAgentCustom}
                    setIsActive={toggleCustomCooperatingAgent}
                    setCooperatingAgentCustom={setCooperatingAgentCustom}
                    errors={cooperatingAgentErrors}
                    setErrors={setCooperatingAgentErrors}
                  />
                )}
              </div>
              <div
                className={
                  !props.showCooperatingFields
                    ? 'hidden'
                    : 'px-4 py-2 -mb-1 order-2  w-fit lg:col-start-1 lg:row-start-2'
                }
              >
                <RadioGroup
                  formField={true}
                  formFieldMessageId={getFormRadioErrorId('wb_wb_sellerInfo')}
                  id="wb_wb_sellerInfo"
                  name="sellerInfo"
                  label={ORDER_FORM_FIELDS.SELLER__QUESTION}
                  required={true}
                  radios={convertOptionsObjToRadioGroupOptions(
                    'wb_wb_sellerInfo',
                    customerRadioLabel,
                  )}
                  value={convertValuesToRadioGroupValue(customerRadioValues)}
                  onChange={(selected) =>
                    convertRadioGroupOnChangeToOnClick(selected, toggleSellerInfoFields)
                  }
                  error={errors.sellerInfo}
                  orientation="horizontal"
                  disabled={props.hasSellersCoverage}
                />
              </div>
            </div>

            <div ref={sellerInfoFields} className="w-full flex flex-wrap">
              {showSellerInfoFields && (
                <>
                  <FormNewOrderCustomer
                    ids={formNewOrderCustomerPropIds.customer}
                    names={formNewOrderCustomerPropNames.customer}
                    values={{
                      firstName: scratchFormValues.firstName,
                      lastName: scratchFormValues.lastName,
                      email: scratchFormValues.email,
                      phone: scratchFormValues.phone,
                    }}
                    errors={{
                      firstName: sellerFirstNameError,
                      lastName: sellerLastNameError,
                      email: errors.email,
                      phone: errors.phone,
                    }}
                    required={{
                      firstName: true,
                      lastName: true,
                      email: props.isCustomerContactInfoRequired,
                      phone: props.isCustomerContactInfoRequired,
                    }}
                    onInputChange={onChangeInput}
                    onInputBlur={handleBlur}
                  />

                  <div className="w-full px-4 py-2">
                    <CheckboxGroup
                      id="wb_seller-checkbox"
                      label=""
                      checkboxes={convertOptionsObjToCheckboxGroupOptions(
                        'wb_seller-checkbox',
                        customerCheckboxLabels,
                      )}
                      value={convertValuesToCheckboxGroupValue(customerCheckboxValues)}
                      onChange={(items) =>
                        convertCheckboxGroupOnChangeToOnClick(
                          items,
                          customerCheckboxValues,
                          toggleCheckboxFields,
                        )
                      }
                    />
                  </div>

                  <div ref={alternateAddressFields} className="w-full">
                    {showAlternateAddressFields && (
                      <FormNewOrderCustomerAddress
                        shouldLoadZipDetails={true}
                        onLoadZipDetails={onLoadCustomerAddressZipDetails}
                        ids={formNewOrderCustomerPropIds.customerAddress}
                        names={formNewOrderCustomerPropNames.customerAddress}
                        values={{
                          address: scratchFormValues.streetAddress,
                          unit: scratchFormValues.unit,
                          city: scratchFormValues.city,
                          state: scratchFormValues.state,
                          zip: scratchFormValues.zipCode,
                        }}
                        errors={{
                          address: errors.streetAddress,
                          unit: undefined,
                          city: errors.city,
                          state: errors.state,
                          zip:
                            !doesZipExist && errors.zipCode == null
                              ? c.INVALID_ZIP_CODE
                              : errors.zipCode,
                        }}
                        required={{
                          address: false,
                          unit: false,
                          city: false,
                          state: false,
                          zip: false,
                        }}
                        onInputChange={onChangeInput}
                        onInputBlur={handleBlur}
                        onStateSelect={(item) => {
                          handleBlur({
                            target: {
                              name: formNewOrderCustomerPropNames.customerAddress.state,
                              value: item.value,
                            },
                          });
                        }}
                        stateDisabled={values.isDisabled}
                        setAddress={(address) =>
                          handleSetAddress(address, formNewOrderCustomerPropNames.customerAddress)
                        }
                        setStreetAddressError={(err) =>
                          handleStreetAddressError(
                            err,
                            formNewOrderCustomerPropNames.customerAddress.address,
                          )
                        }
                      />
                    )}
                  </div>

                  <div ref={coSellerFields} className="w-full flex flex-wrap">
                    {showCoSellerFields && (
                      <>
                        <FormNewOrderCustomer
                          ids={formNewOrderCustomerPropIds.coCustomer}
                          names={formNewOrderCustomerPropNames.coCustomer}
                          values={{
                            firstName: scratchFormValues.coCustomerFirstName,
                            lastName: scratchFormValues.coCustomerLastName,
                            email: scratchFormValues.coCustomerEmail,
                            phone: scratchFormValues.coCustomerPhone,
                          }}
                          errors={{
                            firstName: cosellerFirstNameError,
                            lastName: cosellerLastNameError,
                            email: errors.coCustomerEmail,
                            phone: errors.coCustomerPhone,
                          }}
                          required={{
                            firstName: true,
                            lastName: true,
                            email: false,
                            phone: false,
                          }}
                          onInputChange={onChangeInput}
                          onInputBlur={handleBlur}
                        />

                        <div className="sm:flex sm:items-end w-full">
                          <div className="w-full px-4 py-2">
                            <CheckboxGroup
                              id="wb_coSellerAltAddressCheck"
                              label=""
                              checkboxes={convertOptionsObjToCheckboxGroupOptions(
                                'wb_coBuyerAltAddressCheck',
                                coCustomerCheckboxLabels,
                              )}
                              value={convertValuesToCheckboxGroupValue(coCustomerCheckboxValues)}
                              onChange={(items) =>
                                convertCheckboxGroupOnChangeToOnClick(
                                  items,
                                  coCustomerCheckboxValues,
                                  toggleCoSellerCheckboxFields,
                                )
                              }
                            />
                          </div>
                        </div>

                        <div ref={coSellerAlternateAddressFields} className="flex flex-wrap w-full">
                          {showCoSellerAlternateAddressFields && (
                            <FormNewOrderCustomerAddress
                              shouldLoadZipDetails={true}
                              onLoadZipDetails={onLoadCoCustomerAddressZipDetails}
                              ids={formNewOrderCustomerPropIds.coCustomerAddress}
                              names={formNewOrderCustomerPropNames.coCustomerAddress}
                              values={{
                                address: scratchFormValues.coCustomerStreetAddress,
                                unit: scratchFormValues.coCustomerUnit,
                                city: scratchFormValues.coCustomerCity,
                                state: scratchFormValues.coCustomerState,
                                zip: scratchFormValues.coCustomerZipCode,
                              }}
                              errors={{
                                address: errors.coCustomerStreetAddress,
                                unit: undefined,
                                city: errors.coCustomerCity,
                                state: errors.coCustomerState,
                                zip:
                                  !doesCoCustomerZipExist && errors.coCustomerZipCode == null
                                    ? c.INVALID_ZIP_CODE
                                    : errors.coCustomerZipCode,
                              }}
                              required={{
                                address: false,
                                unit: false,
                                city: false,
                                state: false,
                                zip: false,
                              }}
                              onInputChange={onChangeInput}
                              onInputBlur={handleBlur}
                              onStateSelect={(item) => {
                                handleBlur({
                                  target: {
                                    name: formNewOrderCustomerPropNames.coCustomerAddress.state,
                                    value: item.value,
                                  },
                                });
                              }}
                              stateDisabled={scratchFormValues.isCoCustomerDisabled}
                              setAddress={(address) =>
                                handleSetAddress(
                                  address,
                                  formNewOrderCustomerPropNames.coCustomerAddress,
                                )
                              }
                              setStreetAddressError={(err) =>
                                handleStreetAddressError(
                                  err,
                                  formNewOrderCustomerPropNames.coCustomerAddress.address,
                                )
                              }
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </CardNewOrder>

      <ModalAgentVerification
        id="modal-agent-verification"
        isActive={isAgentVerificationActive}
        onClose={() => setIsAgentVerificationActive(false)}
        selectedAgent={selectedAgent}
        agentRole="cooperating"
        setTypeAheadAddress={setTypeAheadAddress}
        setIsAddAgentModalActive={toggleCustomCooperatingAgent}
      />

      <DrawerOfficeSearch
        id="card-new-order-seller-info__drawer-office-search"
        typeCode="RE"
        isActive={isDrawerActive}
        onClose={() => setIsDrawerActive(false)}
        userOfficeIDs={props.setUserOfficeIDs}
        onSubmit={handleOfficeSelect}
        ignoreReadOnly={true}
      />

      <AddressVerification
        id="validate-address--seller"
        items={addressVerificationItems}
        isActive={enableAddressVerification}
        onVerifiedAddress={updateAddressFromVerification}
        onComplete={() => submit(true)}
        onReset={() => {
          setAddressVerificationItems([]);
          setEnableAddressVerification(false);
        }}
        disableUnverifiedAddressCreation={props.disableUnverifiedAddressCreation}
      />
    </>
  );
};

export default CardNewOrderSellerInfo;
