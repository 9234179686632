import React, { useEffect, useState } from 'react';
import {
  Button,
  DateTimeInput,
  Dialog,
  Popover,
  PopoverContent,
  RadioGroup,
  Select,
  Text,
} from '@ftdr/blueprint-components-react';
import {
  abandonedOrdersDatePresets,
  dateTypeRadios,
  dateTypes,
  defaultFilterDatePresets,
  entryTypeFilterPresets,
  quoteFilterDatePresets,
  warrantylinkFilterDatePresets,
} from '@constants/dates';
import { StatusMenuType } from '@components/filter/OrderStatusFilter';
import { FilterDatePayload } from '@components/misc/misc.models';
import { getGreatestDate, getLeastDate } from '@helpers/utils';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

interface DateRangeProps {
  open: boolean;
  initialValues: FilterDatePayload;
  onChange(rangeType: string, preset: string, start: Date, end: Date): void;
  onClose(): void;
  statusMenuType?: StatusMenuType;
  minDate?: Date;
  maxDate?: Date;
}

interface DateRangePreset {
  value: string;
  title: string;
}

const DateRangeSelectionModal: React.FC<DateRangeProps> = (props) => {
  const [type, setDateType] = useState<string>(props.initialValues?.type || dateTypes.ESTCOE);
  const [presetTitle, setPresetTitle] = useState<string>(props.initialValues?.presetTitle || '');
  const [presets, setPresets] = useState<DateRangePreset[]>([]);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  const onApply = () => {
    props.onChange(type, presetTitle, startDate, endDate);
    props.onClose();
  };

  const onClose = () => {
    props.onClose();
  };

  const canSubmit = (): boolean => {
    return type !== null && presetTitle !== '' && startDate !== null && endDate !== null;
  };

  const dateInputVisible = (): boolean => {
    return presetTitle === 'custom';
  };

  const onSelect = (preset: any): void => {
    setPresetTitle(preset?.title);
  };

  const setDates = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    let p: DateRangePreset[] = [];
    if (type === dateTypes.ENTRYDATE) {
      p = entryTypeFilterPresets.map((filter) => {
        return {
          value: filter.label,
          title: filter.id,
        };
      });
    } else {
      switch (props.statusMenuType) {
        case StatusMenuType.WarrantyLink:
          p = warrantylinkFilterDatePresets.map((filter) => {
            return {
              value: filter.label,
              title: filter.id,
            };
          });
          break;
        case StatusMenuType.WarrantyLinkPayments:
          p = entryTypeFilterPresets.map((filter) => {
            return {
              value: filter.label,
              title: filter.id,
            };
          });
          break;
        case StatusMenuType.Quotes:
          p = quoteFilterDatePresets.map((filter) => {
            return {
              value: filter.label,
              title: filter.id,
            };
          });
          break;
        case StatusMenuType.abandonedOrders:
          p = abandonedOrdersDatePresets.map((filter) => {
            return {
              value: filter.label,
              title: filter.id,
            };
          });
          break;
        default:
          p = defaultFilterDatePresets.map((filter) => {
            return {
              value: filter.label,
              title: filter.id,
            };
          });
          break;
      }
    }
    setPresets(p);
  }, [type]);

  useEffect(() => {
    if (presetTitle === '') {
      return;
    }
    const allFilters = [...defaultFilterDatePresets, ...entryTypeFilterPresets];
    const filter = allFilters.find((el) => el.id === presetTitle);
    if (filter) {
      setStartDate(filter.start);
      setEndDate(filter.end);
    }
  }, [presetTitle]);

  useEffect(() => {
    setDateType(props.initialValues?.type || dateTypes.ESTCOE);
    setPresetTitle(props.initialValues?.presetTitle || '');
  }, [props.initialValues]);

  const buttons = [
    <Popover
      key="submit"
      placement="bottom"
      triggerInteraction="hover"
      content={(popoverContentProps) => (
        <PopoverContent {...popoverContentProps}>
          <REText className="max-w-xs" color="gray" variant="caption">
            All fields must be filled in to apply date range.
          </REText>
        </PopoverContent>
      )}
      disabled={canSubmit()}
      className="mr-3 w-full"
    >
      <Button id="dateRangePicker-apply" className={`${IsTheme(Theme.Ahs2024) ? 'button-pill' : ''}`} label="Apply" onClick={onApply} disabled={!canSubmit()} />
    </Popover>,
    <Button
      key="cancel"
      id="dateRangePicker-cancel"
      label="Cancel"
      className={`${IsTheme(Theme.Ahs2024) ? 'button-pill' : ''}`}
      onClick={onClose}
      autoFocus={true}
      variant={`${IsTheme(Theme.Ahs2024) ? 'ghost' : 'outlined'}`}
    />,
  ];

  const getTitle = () => {
    switch (props.statusMenuType) {
      case StatusMenuType.WarrantyLinkPayments:
        return 'Filter your payments by date range.';

      case StatusMenuType.Quotes:
        return 'Filter your quotes by quote created date.';

      case StatusMenuType.abandonedOrders:
        return 'Filter your Orders by Created Date';

      default:
        return 'Filter your orders by date type and range.';
    }
  };

  return (
    <>
      <Dialog
        id="dateRangePicker-dialog"
        className="md:w-160"
        header="Date Range"
        actions={buttons}
        open={props.open}
        onClose={onClose}
        showBackdrop={true}
      >
        <>
          <REText id="dateRangePicker-text" className="py-2">
            {getTitle()}
          </REText>
          {props.statusMenuType === StatusMenuType.Orders && (
            <RadioGroup
              id="dateRangePicker-dateType"
              radios={dateTypeRadios}
              value={type}
              label=""
              onChange={setDateType}
            />
          )}
          <div className="sm:w-80 mt-4">
            <Select
              id="dateRangePicker-presets-options"
              options={presets}
              onSelect={onSelect}
              label=""
              placeholder="Select a date range..."
              multiSelect={false}
              selected={presets.find((el) => el.title === presetTitle)}
            />
            {dateInputVisible() && (
              <DateTimeInput
                id="dateRangePicker-dateTimeInput"
                label=""
                onDateSelect={setDates}
                selectsRange={true}
                selectedDate={startDate}
                startDate={startDate}
                endDate={endDate}
                startRangeMinDate={props.minDate}
                startRangeMaxDate={
                  endDate || (props.maxDate && getLeastDate(props.maxDate, endDate))
                }
                endRangeMinDate={
                  startDate || (props.minDate && getGreatestDate(props.minDate, startDate))
                }
                endRangeMaxDate={props.maxDate}
                datePicker={true}
                autoComplete="off"
              />
            )}
          </div>
        </>
      </Dialog>
    </>
  );
};

export default DateRangeSelectionModal;
