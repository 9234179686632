import React, { useEffect, useMemo, useState } from 'react';
import {
  ContractPricing,
  DwellingType,
  OrderPricingData,
  OrderPricingDetails,
  Product,
  ProductAndCoveragesResponse,
  ProductAvailabilityRequest,
  ProductCoverage,
  ProductFilters,
  ProductFiltersRequest,
  ProductGroup,
  ProductPricingDetailsRequest,
  ProductPricingDetailsResponse,
  ProductPricingResponse,
  ProductProperty,
} from '@apis/models';
import CardNewOrder from '@components/card/CardNewOrder';
import { getBrand } from '@helpers/brand.utils';
import { API as _ProductApi, ProductApiSuppressErrors } from '@apis/product.api';
import { Checkbox, ProgressIndicator } from '@ftdr/blueprint-components-react';
import { DateFormat, formatDate, isNullOrUndefined } from '@helpers/utils';
import msgs from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import FormNewOrderCoverageTables, {
  FormNewOrderCoverageTablesProps,
} from '@components/form/FormNewOrderCoverageTables';
import FormNewOrderPlanFilter, {
  FormNewOrderPlanFilterProps,
} from '@components/form/FormNewOrderPlanFilter';
import FormNewOrderPlanSelection from '@components/form/FormNewOrderPlanSelection';
import { classNames } from '@utils';
import TableNewOrderPriceSummary from '@components/table/TableNewOrderPriceSummary';
import addDays from 'date-fns/addDays';
import { cloneDeep } from 'lodash';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import ModalRoofRXCoverage from '@components/modal/ModalRoofRXCoverage';
import ModalElectronicsCoverage from '@components/modal/ModalElectronicsCoverage';
import ModalNHDCoverage from '@components/modal/ModalNHDCoverage';
import {
  isElectronicsCoverageById,
  isNHDCoverageById,
  isRoofRXCoverageById,
  requiresConfirmationById,
} from '@apis/utils/product.api.modal';
import { CoverageDependencyHandler } from '@apis/utils/coverage-dependencies';
import { coverageNameOverridesDictionary } from '@constants/dictionaries';
import { calculateListingTermInDays, getDefaultListingEffectiveDate } from '@helpers/order.utils';
import { fireGAEvent } from '@app/core/tracking.service';
import { CAPTURE_SELLERS_COVERAGE } from '@constants/ga-events.constants';
import { MILITARY_DISCOUNT } from '@constants/newOrder-constants';
import REText from '@components/wrappedBDS/REText';
import { Canceler } from 'axios';

type SaveHandler<T> = (values: T) => void;

export interface CardNewOrderPlansCoverageProps
  extends Partial<Pick<FormNewOrderPlanFilterProps, 'disableSellersSelection'>>,
    Partial<Pick<FormNewOrderCoverageTablesProps, 'enableQuantitySelection'>> {
  // agentRepresents
  agentRepresents?: string;
  /** data stored to be displayed in form and view */
  formData: PlansCoverageValues;
  /** the pricing information for order summary used in view mode. If not provided, it means the form was never completed yet.
   *  Should be set by default for exising contract. Otherwise, used to display the pricing of the form when it saved and allow reset */
  pricing: ContractPricing;
  /** when saving the card and passes form validation, this handler is called. used to close edit mode and update props. */
  onSave: SaveHandler<{
    formData: PlansCoverageValues;
    pricing: ContractPricing;
    pricingDetailsRequest: ProductPricingDetailsRequest;
  }>;
  /** when user cancels changes in the form, this handler is called. used to close edit and any other interactions. */
  onClose: () => void;
  /** when user opens the card via edit, this handler is called. used to open edit mode. */
  onOpen: () => void;
  /** should prevent the user from editing the card */
  disableEdit?: boolean;
  /** displays edit mode or view mode based on boolean */
  isEditing: boolean;
  /** displays the card in a smaller view */
  isSmall: boolean;
  /** in mobile view, collapses the card to just the header by default. */
  defaultCollapsed: boolean;
  /** displays an inline notification error at the top of the card. */
  alertText?: string;
  /** displays inline text next to the save/continue button of card. */
  submitHelperText?: string;

  /** the initiating office id for the contract.
   * Used to return available products offered for the office, if a franchise office. */
  initiatingOfficeId: string;
  initiatingOfficeFranchiseCode: string;
  /** the property address used in the contract. used to determine filters, products, and prices */
  propertyAddress: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    zipPlus4: string;
  };
  /** the property details used in the contract. used to determine filters, products, and prices */
  propertyDetails: PropertyDetails;

  /** the day when the listing is effective on (i.e. LISTDATE.effective).
   * Used to return available products offered during that date.
   *
   * For New Order, the today's date is the listingEffectiveDate. */
  listingEffectiveDate?: Date;
  /** the day when the listing will end on (i.e. LISTDATE.expiration)
   * Used to determine the listing seller's coverage range.
   *
   * For New Order, the projected closing date is the listingExpirationDate.
   * If there is no date, then the component will default to the listingTerm of the selected product
   */
  listingExpirationDate?: Date;

  roProductsOnly?: boolean;

  contractListTimestamp?: Date;

  /** the timestamp for when the quote was originally created */
  quoteEffectiveDate?: Date;
  earnixRuleID?: number;

  selectedProductInfo?: ProductAndCoveragesResponse;
  /** For edit order flow, the military discount checkbox should always be disabled */
  disableEditMilitaryDiscount?: boolean;
  allowMilitaryDiscount?: boolean;
}

export interface PropertyDetails {
  residenceType: string;
  squareFootage: number;
  age: number;
}

export interface PlansCoverageValues {
  selectedFilters: SelectedProductFilters;
  selectedPlanCoverages: SelectedPlanCoverages;
  selectedGuestUnit: boolean;
  selectedMilitaryDiscount: boolean;
}

export interface SelectedProductFilters {
  includeSellersCoverage: boolean;
  includeACCoverage: boolean;
  termYear: number;
}

export interface SelectedPlanCoverages {
  product: {
    id: string;
    starPVID: string;
    name: string;
    contractTermMonths: number;
    sellersCoverage: boolean;
    acCoverage: boolean;
  };
  optionalCoverages: SelectedItem[];
  groupCoverages: SelectedItem[];
}

interface SelectedItem {
  id: string;
  name: string;
  quantity: number;
}

enum CoverageModalType {
  RoofRX,
  Electronics,
  NHD,
}

const defaultSelectedFilters: SelectedProductFilters = Object.freeze({
  includeSellersCoverage: false, // default Sellers selection to false always
  includeACCoverage: true, // default AC selection to true always
  termYear: undefined,
});

const defaultSelectedPlanCoverages: SelectedPlanCoverages = Object.freeze({
  product: undefined,
  groupCoverages: [],
  optionalCoverages: [],
});

const defaultSelectedGuestUnit = false;
const defaultSelectedMilitaryDiscount = false;

const defaultListingEffectiveDate: Date = getDefaultListingEffectiveDate();
const defaultListingExpirationDate: Date = null;

/** When set, if filter changes, reset filter and plan selection instead of attempting to intelligently re-select the filters and plans */
const ALWAYS_CLEAR_PLAN_ON_FILTER_UPDATES = false;

/** canceler used when quote data has been changed */
let quoteLoadCanceler: Canceler;

const CardNewOrderPlansCoverage: React.FC<CardNewOrderPlansCoverageProps> = (props) => {
  const ProductApi = new _ProductApi();
  const brand = getBrand();
  const { addMessageToQueue } = useGlobalAlert();

  /** form data during edit mode. */
  const [formErrorMsg, setFormErrorMsg] = useState<string>(null);
  const [selectedFilters, setSelectedFilters] = useState<SelectedProductFilters>(
    props.formData?.selectedFilters || defaultSelectedFilters,
  );
  const [selectedPlanCoverages, _setSelectedPlanCoverages] = useState<SelectedPlanCoverages>(
    props.formData?.selectedPlanCoverages || defaultSelectedPlanCoverages,
  );
  const [selectedGuestUnit, setSelectedGuestUnit] = useState<boolean>(
    props.formData?.selectedGuestUnit || defaultSelectedGuestUnit,
  );

  const [isSellerCoverageUpdated, setIsSellerCoverageUpdated] = useState(false);
  // overriding setSelectedPlanCoverages to make the coverage arrays immutable with deep clone.
  // this way avoids needing to know to add that step for all instances
  const setSelectedPlanCoverages: React.Dispatch<React.SetStateAction<SelectedPlanCoverages>> = (
    val,
  ) => {
    return _setSelectedPlanCoverages(cloneDeep(val));
  };

  /* loading indicators */
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [isLoadingPlansAndCoverages, setIsLoadingPlansAndCoverages] = useState(false);
  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const [isLoadingPriceSummary, setIsLoadingPriceSummary] = useState(false);

  //const [isPastClosingDate, setIsPastClosingDate] = useState<boolean>(false);

  /* filters */
  const [prevEnabledFiltersRequest, setPrevEnabledFiltersRequest] =
    useState<ProductFiltersRequest>(null);
  const [enabledFilters, setEnabledFilters] = useState<ProductFilters>(null);

  /* plan, coverage, and pricing */
  const [productAvailabilityRequest, setProductAvailabilityRequest] =
    useState<ProductAvailabilityRequest>(null);
  const [availablePlans, setAvailablePlans] = useState<Product[]>([]);
  const [availablePlansAndCoverages, setAvailablePlansAndCoverages] = useState<
    ProductAndCoveragesResponse[]
  >([]);
  const [planPricingData, setPlanPricingData] = useState<ProductPricingResponse[]>([]);
  const [prevIsPastClosingDate, setPrevIsPastClosingDate] = useState<boolean>(null);
  const [isMilitaryDiscountApplied, setIsMilitaryDiscountApplied] = useState<boolean>(
    props.formData?.selectedMilitaryDiscount || defaultSelectedMilitaryDiscount,
  );
  const [pricingIncludesMilitaryDiscount, setPricingIncludesMilitaryDiscount] = useState<boolean>(
    props.formData?.selectedMilitaryDiscount || defaultSelectedMilitaryDiscount,
  ); //For editOrderFlow display Military discount checkbox only if applied

  /* price summary and coverage cost updates */
  const [productPricingDetails, setProductPricingDetails] =
    useState<ProductPricingDetailsResponse>(null);

  const [productPricingDetailsRequest, setProductPricingDetailsRequest] =
    useState<ProductPricingDetailsRequest>(null);

  /* coverage modals */
  const [pendingAcceptCoverage, setPendingAcceptCoverage] = useState<{
    cvgType: CoverageModalType;
    cvg: ProductCoverage;
  }>(null);
  const [optionalCoverageConfirmation, setOptionalCoverageConfirmation] = useState<
    ProductCoverage[]
  >([]);

  const [selections, setSelections] = useState({ selectedPlanCoverages, selectedGuestUnit });

  /** unmount, clear up any cancel if any */
  useEffect(() => {
    return () => {
      quoteLoadCanceler?.('unmounting');
    };
  }, []);

  //optionalCoverages will be pre-populated only in Quote's convert to order flow
  //1) Removing NHD and Electronics coverages from optionalCoverages to mirror new order flow.
  //2) Show confirmation pop-up model for NHD and Electronics coverages via checkIfCoverageConfirmationNeeded method in Quote's convert to order flow
  useEffect(() => {
    const confirmCoverages =
      selectedPlanCoverages.optionalCoverages?.filter(
        (cvg) => isNHDCoverageById(cvg.id) || isElectronicsCoverageById(cvg.id),
      ) || [];

    confirmCoverages.forEach((element) => {
      const removeIndex = selectedPlanCoverages.optionalCoverages.findIndex(
        (item) => item.id == element.id,
      );
      selectedPlanCoverages.optionalCoverages.splice(removeIndex, 1);
    });

    const cvgs = confirmCoverages.map((cvg) => {
      const item: ProductCoverage = {
        id: cvg.id,
        name: cvg.name,
        starCoverageId: cvg.id,
        tags: [],
      };
      return item;
    });

    setOptionalCoverageConfirmation([...cvgs]);
  }, []);

  useEffect(() => {
    checkIfCoverageConfirmationNeeded();
  }, [optionalCoverageConfirmation]);

  useEffect(() => {
    if (props.formData?.selectedMilitaryDiscount) {
      setIsMilitaryDiscountApplied(true);
      setPricingIncludesMilitaryDiscount(true);
    }
  }, [props.formData?.selectedMilitaryDiscount]);

  //Remove the military discount if buyerInfo is removed.
  useEffect(() => {
    if (!props.allowMilitaryDiscount && isMilitaryDiscountApplied) {
      setIsMilitaryDiscountApplied(false);
    }
  }, [props.allowMilitaryDiscount, isMilitaryDiscountApplied]);

  const highestPlanPrice = useMemo(() => {
    if (availablePlans.length > 0 && planPricingData.length > 0) {
      let highestPlanPrice = {
        price: 0,
        product: null as Product,
      };

      availablePlans.forEach((product) => {
        const pricingPlan = planPricingData.find(
          (pricingPlan) => pricingPlan.product.id === product.starPVID,
        );
        if (pricingPlan) {
          const price = pricingPlan.product.price;
          if (price > highestPlanPrice.price) {
            highestPlanPrice = { price, product };
          }
        }
      });
      return highestPlanPrice.product;
    }
    return null;
  }, [availablePlans, planPricingData]);

  useEffect(() => {
    // finds a matching plan by the name and re-select it.\
    const matchingPlan: Product =
      selections?.selectedPlanCoverages?.product &&
      !props.roProductsOnly &&
      availablePlans.find(
        (product) => selections?.selectedPlanCoverages?.product.name === product.name,
      );
    onSelectPlan(matchingPlan, selections);

    if (props.roProductsOnly && !matchingPlan) {
      onSelectPlan(highestPlanPrice, selections);
    }
  }, [selections, availablePlans, highestPlanPrice]);

  const checkIfCoverageConfirmationNeeded = () => {
    if (optionalCoverageConfirmation.length) {
      const cvg = optionalCoverageConfirmation.pop();
      handleCoverageAcceptCheck(cvg);
    }
  };

  /** if the listing effective prop is modified, refresh it */
  const listingEffectiveDate = useMemo(() => {
    return props.listingEffectiveDate || defaultListingEffectiveDate;
  }, [props.listingEffectiveDate]);

  /** if the listing expiration prop is modified, or (if null) has a selected product, refresh it */
  const listingExpirationDate = useMemo(() => {
    // listing expiration from prop takes precedence
    if (props.listingExpirationDate) {
      return props.listingExpirationDate;
    }
    // if no prop set, and product was selected, use list days from the product to determine the expiration
    if (selectedPlanCoverages.product) {
      const matchingPlan = availablePlansAndCoverages.find(
        ({ product }) => product.starPVID === selectedPlanCoverages.product.starPVID,
      );
      if (matchingPlan) {
        const productListDateCoverage = addDays(
          listingEffectiveDate,
          matchingPlan.product.listingTermInDays,
        );
        return productListDateCoverage;
      }
    }
    // otherwise, use default value
    return defaultListingExpirationDate;
  }, [
    listingEffectiveDate,
    props.listingExpirationDate,
    selectedPlanCoverages,
    availablePlansAndCoverages,
  ]);

  const isPastClosingDate = useMemo(() => {
    //closing date not selected
    if (!listingExpirationDate) {
      return false;
    }

    //new order flow
    if (
      (!listingEffectiveDate && listingExpirationDate < listingExpirationDate) ||
      listingExpirationDate < listingEffectiveDate
    ) {
      return true;
    } else {
      //edit order flow
      return false;
    }
  }, [listingExpirationDate, listingEffectiveDate]);

  /** calculate listing days */
  const listingDays: number = useMemo(() => {
    if (listingEffectiveDate && listingExpirationDate) {
      return calculateListingTermInDays(listingExpirationDate, listingEffectiveDate); // differenceInCalendarDays(listingExpirationDate, listingEffectiveDate);
    }
    return null;
  }, [listingEffectiveDate, listingExpirationDate]);

  const specialDiscounts = useMemo(() => {
    const discounts = [];
    if (isMilitaryDiscountApplied) {
      discounts.push(MILITARY_DISCOUNT);
    }
    return discounts;
  }, [isMilitaryDiscountApplied]);

  /** build a new filters request when any dependent values changed */
  const enabledFiltersRequest: ProductFiltersRequest = useMemo(() => {
    if (props.propertyAddress && props.propertyDetails) {
      return {
        brand,
        franchiseCode: props.initiatingOfficeFranchiseCode,
        zip: props.propertyAddress.zip,
        state: props.propertyAddress.state,
        age: props.propertyDetails.age,
        typeOfResidence: props.propertyDetails.residenceType as DwellingType,
        squareFootage: props.propertyDetails.squareFootage,
        roProductsOnly: props.roProductsOnly,
        contractListTimestamp: props.contractListTimestamp
          ? formatDate(new Date(props.contractListTimestamp), DateFormat.ISO8601_Timestamp)
          : '', // TODO: Simplify once we're able to fully switch over to timestamps: https://ftdr.atlassian.net/browse/ARE-8908
      };
    }
    return null;
  }, [
    props.initiatingOfficeFranchiseCode,
    props.propertyAddress,
    props.propertyDetails,
    props.roProductsOnly,
    props.contractListTimestamp,
  ]);

  /** reset form appropriately when edit flag is modified (and when form data changes) */
  useEffect(() => {
    //set prev closing date to null if no filters are available (Plan card opened for the 1st time)

    // when not editing, and form data changes, reset the edit form data to match with the props
    if (!props.isEditing) {
      setFormErrorMsg(null);
      setSelectedFilters(props.formData?.selectedFilters || defaultSelectedFilters);
      setSelectedPlanCoverages(
        props.formData?.selectedPlanCoverages || defaultSelectedPlanCoverages,
      );
      setSelectedGuestUnit(props.formData?.selectedGuestUnit || defaultSelectedGuestUnit);
      setIsMilitaryDiscountApplied(
        props.formData?.selectedMilitaryDiscount || defaultSelectedMilitaryDiscount,
      );
      setPricingIncludesMilitaryDiscount(
        props.formData?.selectedMilitaryDiscount || defaultSelectedMilitaryDiscount,
      );
    }
  }, [props.isEditing, props.formData, isPastClosingDate]);

  /** when switching to edit, if there is selected data but available plans are empty, fetch and load the plans */
  useEffect(() => {
    if (props.isEditing) {
      const selectedPlanId = props.formData?.selectedPlanCoverages?.product?.starPVID;
      const isMissingPlanData = !availablePlans.find((plan) => plan.starPVID === selectedPlanId);
      if (selectedPlanId && isMissingPlanData) {
        fetchAndUpdatePlans(props.formData?.selectedFilters);
        setSelections(props.formData);
      }
    }
  }, [props.isEditing]);

  /** refresh filters only in edit view and when the request has been modified */
  useEffect(() => {
    if (props.isEditing) {
      // check if filter has changed before continuing
      // NOTE: object prototype causing incorrect conditional check for some reason, changed to JSON stringify check instead
      const filterRequestChanged = // prevEnabledFiltersRequest !== enabledFiltersRequest;
        // changed if filter request set and either there are no prev filter request or request was modified
        (enabledFiltersRequest && !prevEnabledFiltersRequest) ||
        JSON.stringify(prevEnabledFiltersRequest) !== JSON.stringify(enabledFiltersRequest) ||
        (isPastClosingDate && isPastClosingDate != prevIsPastClosingDate);

      setPrevIsPastClosingDate(isPastClosingDate);

      if (!filterRequestChanged) {
        return;
      }

      setFormErrorMsg(null); // if filters have changed, then we'd want to clear the form error
      setPrevEnabledFiltersRequest(enabledFiltersRequest);
      setEnabledFilters(null);
      setIsLoadingFilters(true);

      const fetchData = async () => {
        const res = await ProductApiSuppressErrors.getFilters(enabledFiltersRequest);
        setEnabledFilters(res);
        handleEnabledFilterChanges(res, enabledFilters);
      };

      fetchData()
        .catch((err) => {
          console.error(err);
          // addMessageToQueue(msgs.PRODUCT_FILTER_FAILED); // TODO: revisit with ARE-8619/ARE-8569 if we need to show the original msg again.
          addMessageToQueue(msgs.NO_PLANS_FOUND);
        })
        .finally(() => {
          setIsLoadingFilters(false);
        });
    }
  }, [props.isEditing, enabledFiltersRequest, prevEnabledFiltersRequest, isPastClosingDate]);

  /** if a new product was selected, iterate through the coverages if it still exists in the product. otherwise de-select it. */
  useEffect(() => {
    if (!isLoadingPlansAndCoverages && selectedPlanCoverages.product) {
      const matchingPlanCoverage = availablePlansAndCoverages.find(
        ({ product }) => product.starPVID === selectedPlanCoverages.product.starPVID,
      );
      let hasModifiedSelection = false;

      // determine if some coverages need to be removed from selection if not offered for the product
      if (matchingPlanCoverage) {
        selectedPlanCoverages.optionalCoverages = selectedPlanCoverages.optionalCoverages.reduce(
          (acc, selectedCvg) => {
            if (
              matchingPlanCoverage.coverages.optional.find(
                (availableCvg) => availableCvg.starCoverageId === selectedCvg.id,
              )
            ) {
              return [...acc, selectedCvg];
            }
            console.debug('optional cvg not available in plan. removing selection', selectedCvg.id);
            hasModifiedSelection = true;
            return acc;
          },
          [],
        );

        selectedPlanCoverages.groupCoverages = selectedPlanCoverages.groupCoverages.reduce(
          (acc, selectedCvg) => {
            if (
              matchingPlanCoverage.coverages.group.find(
                (availableCvg) => availableCvg.starGroupId === selectedCvg.id,
              )
            ) {
              return [...acc, selectedCvg];
            }
            console.debug('group cvg not available in plan. removing selection', selectedCvg.id);
            hasModifiedSelection = true;
            return acc;
          },
          [],
        );

        if (hasModifiedSelection) {
          setSelectedPlanCoverages(selectedPlanCoverages);
        }
      }
    }
  }, [selectedPlanCoverages, availablePlansAndCoverages, isLoadingPlansAndCoverages]);

  /** if plans or coverages were changed, obtain latest price summary information */
  useEffect(() => {
    // if no plan is selected, clear pricing
    if (!selectedPlanCoverages.product?.starPVID) {
      setProductPricingDetails(null);
      return;
    }

    const productAndCoverages = availablePlansAndCoverages.find(
      (p) => p.product.starPVID === selectedPlanCoverages.product.starPVID,
    );
    if (!productAndCoverages) {
      setProductPricingDetails(null);
      return;
    }

    // otherwise, we will be refreshing the prices
    const pricingDetailsRequest: ProductPricingDetailsRequest = {
      property: productAvailabilityRequest.property,
      options: {
        contractTermMonths: productAvailabilityRequest.options.contractTermMonths,
        listingTermInDays: listingDays,
        earnixRuleID: props.earnixRuleID,
        initiatingOfficeID: props.initiatingOfficeId,
        initiatingOfficeFranchiseCode: props.initiatingOfficeFranchiseCode,
        specialDiscounts: specialDiscounts,
      },
      selected: {
        productID: selectedPlanCoverages.product.starPVID,
        guestUnitQuantity: selectedGuestUnit ? 1 : 0,
        optionalCoverages: selectedPlanCoverages.optionalCoverages.map((cvg) => ({
          id: cvg.id,
          quantity: cvg.quantity,
        })),
        groupCoverages: selectedPlanCoverages.groupCoverages.map((cvg) => ({
          id: cvg.id,
          quantity: cvg.quantity,
        })),
      },
    };

    const fetchData = async () => {
      quoteLoadCanceler?.('fetching new quote summary');
      const cancelSource = ProductApi.createNewCancelTokenSource();
      quoteLoadCanceler = cancelSource.cancel;
      setIsLoadingPriceSummary(true);
      const res = await ProductApi.withRequestConfigAs({
        cancelToken: cancelSource.token,
      })
        .getProductPricingDetails(pricingDetailsRequest)
        .catch((err) => {
          if (ProductApi.isCancellationError(err)) {
            return null;
          }
          throw err;
        });
      if (res) {
        setProductPricingDetailsRequest(pricingDetailsRequest);
        setProductPricingDetails(res);
      }
    };

    fetchData().finally(() => {
      setIsLoadingPriceSummary(false);
    });
  }, [
    selectedPlanCoverages,
    selectedGuestUnit,
    productAvailabilityRequest,
    availablePlansAndCoverages,
    listingDays,
    specialDiscounts,
  ]);

  const getPlanLoadLabel = () => {
    if (isLoadingPlans) {
      return 'Loading Available Plans...';
    }
    if (isLoadingPlansAndCoverages) {
      return 'Loading Coverages...';
    }
    if (isLoadingPrices) {
      return 'Loading Prices...';
    }
    return '';
  };

  /** clears the plan, coverage, and pricing data. */
  const clearAllPlanData = () => {
    setAvailablePlans([]);
    setAvailablePlansAndCoverages([]);
    setPlanPricingData([]);
  };

  const isPlanFilterComplete = (filters: SelectedProductFilters = selectedFilters): boolean => {
    return filters && Object.keys(filters).every((k) => !isNullOrUndefined(filters[k]));
  };

  const fetchAndUpdatePlans = async (
    selectedProductFilters: SelectedProductFilters = selectedFilters,
  ): Promise<void> => {
    const productAvailabilityEffectiveDate =
      props.quoteEffectiveDate ?? props.contractListTimestamp;

    const newProductAvailabilityRequest: ProductAvailabilityRequest = {
      office: {
        id: props.initiatingOfficeId,
      },
      property: {
        address1: props.propertyAddress.address1,
        address2: props.propertyAddress.address2,
        city: props.propertyAddress.city,
        state: props.propertyAddress.state,
        zip: props.propertyAddress.zip,
        zipPlus4: props.propertyAddress.zipPlus4,
        residenceType: props.propertyDetails.residenceType,
        age: props.propertyDetails.age,
        squareFootage: props.propertyDetails.squareFootage,
      },
      options: {
        tenant: brand,
        contractListTimestamp: productAvailabilityEffectiveDate
          ? formatDate(productAvailabilityEffectiveDate, DateFormat.ISO8601_Timestamp)
          : '', // TODO: Simplify once we're able to fully switch over to timestamps: https://ftdr.atlassian.net/browse/ARE-8908
        sellersCoverage: !!selectedProductFilters.includeSellersCoverage,
        acCoverage: selectedProductFilters.includeACCoverage,
        contractTermMonths: selectedProductFilters.termYear * 12,
        roProductsOnly: !!props.roProductsOnly,
      },
    };

    setProductAvailabilityRequest(newProductAvailabilityRequest);

    setIsLoadingPlans(true);
    clearAllPlanData();

    let products: Product[] = [];
    try {
      const res = await ProductApiSuppressErrors.getProductAvailability(
        newProductAvailabilityRequest,
      );
      if (res) {
        products = res.products;
      }
    } catch (e) {
      console.error(e);
      products = []; // returning empty array for now
    } finally {
      setIsLoadingPlans(false);
    }

    setAvailablePlans(products);
    // if no plans found, display message
    if (products.length === 0) {
      addMessageToQueue(msgs.NO_PLANS_FOUND);
      return;
    }

    // after getting available products, get all product detail and prices to load
    const starPVIDs = products.map((product) => product.starPVID);
    fetchAndUpdatePlanCoverages(starPVIDs, newProductAvailabilityRequest.property);
    fetchAndUpdatePrices(
      starPVIDs,
      newProductAvailabilityRequest.property,
      newProductAvailabilityRequest.options.contractTermMonths,
    );
  };

  /** fetches the plan coverages information from a given list of product ids */
  const fetchAndUpdatePlanCoverages = (starPVIDs: string[], property: ProductProperty) => {
    const requestedPVIDs: string[] = [];
    starPVIDs.forEach((pvid) => {
      requestedPVIDs.push(pvid);
    });

    // make multiple coverage calls per returned product id from availability
    setIsLoadingPlansAndCoverages(true);
    const fetchData = async () => {
      let productsAndCoverages = await Promise.all(
        requestedPVIDs.map((starPVID) =>
          ProductApi.getProductCoveragesByProductId(starPVID, property.residenceType),
        ),
      );
      productsAndCoverages = productsAndCoverages.filter((res) => !!res); // remove null results
      setAvailablePlansAndCoverages(productsAndCoverages);
    };

    fetchData().finally(() => {
      setIsLoadingPlansAndCoverages(false);
    });
  };

  /** fetch and update the starting price information for the product and coverages.
   *  @param starPVIDs the product ids
   *  @param property  the product property info
   *  @param contractTermMonths the contract term in months
   *  @param listingTermInDays - value not needed for endpoint, setting to default product to reduce # of side-effects
   */
  const fetchAndUpdatePrices = (
    starPVIDs: string[],
    property: ProductProperty,
    contractTermMonths: number,
    listingTermInDays = 180,
  ) => {
    // make multiple price calls per returned product id from availability
    setIsLoadingPrices(true);
    const fetchData = async () => {
      let pricingData = await Promise.all(
        starPVIDs.map((starPVID) =>
          ProductApi.getProductPricingByProductId(starPVID, {
            property,
            options: {
              contractTermMonths,
              listingTermInDays,
              earnixRuleID: props.earnixRuleID,
              initiatingOfficeID: props.initiatingOfficeId,
              initiatingOfficeFranchiseCode: props.initiatingOfficeFranchiseCode,
              specialDiscounts: [], //don't update the products price with applied promotion. Display updated product price in Order Summary only.
            },
          }),
        ),
      );
      pricingData = pricingData.filter((res) => !!res); // remove null results
      setPlanPricingData(pricingData);
    };

    fetchData().finally(() => {
      setIsLoadingPrices(false);
    });
  };

  /** when enabled filters was changed, check if selections need to be modified
   * @return boolean: if enabled filters were modified and de-selected filters */
  const handleEnabledFilterChanges = (
    newEnabledFilters: ProductFilters,
    prevEnabledFilters: ProductFilters,
  ): boolean => {
    // if this is the initial load of the component (i.e. no filters were not fetched yet), dont continue. If term is singular, we want to continue,
    if (!prevEnabledFilters && newEnabledFilters.terms.length !== 1) {
      return false;
    }

    if (newEnabledFilters !== prevEnabledFilters) {
      const updateSelectedFilters = { ...selectedFilters };
      let selectionModified = false;

      // sellers coverage, if sellers coverage disabled, reset selection to default
      if (
        selectedFilters.includeSellersCoverage !== defaultSelectedFilters.includeSellersCoverage &&
        !newEnabledFilters.sellersCoverage
      ) {
        updateSelectedFilters.includeSellersCoverage =
          defaultSelectedFilters.includeSellersCoverage;
        selectionModified = true;
      }

      // terms
      if (
        selectedFilters.termYear !== defaultSelectedFilters.termYear &&
        !newEnabledFilters.terms.includes(selectedFilters.termYear)
      ) {
        updateSelectedFilters.termYear = defaultSelectedFilters.termYear;
        selectionModified = true;
      }

      // ac coverage
      if (
        selectedFilters.includeACCoverage !== defaultSelectedFilters.includeACCoverage &&
        !newEnabledFilters.acCoverage
      ) {
        updateSelectedFilters.includeACCoverage = defaultSelectedFilters.includeACCoverage;
        selectionModified = true;
      }

      if (ALWAYS_CLEAR_PLAN_ON_FILTER_UPDATES) {
        console.log('considering filter changes regardless if selection was modified');
        updateSelectedFilters.includeSellersCoverage =
          defaultSelectedFilters.includeSellersCoverage;
        updateSelectedFilters.includeACCoverage = defaultSelectedFilters.includeACCoverage;
        updateSelectedFilters.termYear = defaultSelectedFilters.termYear;
        selectionModified = true;
      }

      if (newEnabledFilters.terms && newEnabledFilters.terms.length === 1) {
        updateSelectedFilters.termYear = Number(newEnabledFilters.terms[0].toString());
      }

      setSelectedFilters(updateSelectedFilters);

      // if past closing date selected
      if (isPastClosingDate) {
        selectionModified = true;
      }

      // if any filter selections were modified, reset the plan and coverage selections and offerings
      if (selectionModified) {
        console.log('filters were changed, clear plan/guest selections');
        clearPlanSelections();
        clearAllPlanData();
      }
      if (isPlanFilterComplete(updateSelectedFilters)) {
        // if the filter selection still complete, then fetch new plans
        console.log('same filters are enabled, fetch new plans immediately');
        fetchAndUpdatePlans(updateSelectedFilters);
        setSelections({ selectedPlanCoverages, selectedGuestUnit });
      }

      return selectionModified;
    }

    return false;
  };

  /** clears the form data to default, including filter and plan selection,
   *  and have the user start the card from the beginning again. */
  const clearFormData = () => {
    setSelectedFilters(defaultSelectedFilters);
    clearPlanSelections();
    clearAllPlanData();
    setFormErrorMsg(null);
    setIsMilitaryDiscountApplied(false);
  };

  /** clear the plan selections to default, including coverage and guest unit */
  const clearPlanSelections = () => {
    setSelectedPlanCoverages(props.formData?.selectedPlanCoverages || defaultSelectedPlanCoverages);
    setSelectedGuestUnit(defaultSelectedGuestUnit);
  };

  /** Validate if form data is complete and correct */
  const isValidFormData = (): boolean => {
    let valid = true;
    if (!selectedFilters.termYear) {
      setFormErrorMsg(msgs.NO_PLAN_TERM_SELECTED.message);
      valid = false;
    } else if (!selectedPlanCoverages.product) {
      setFormErrorMsg(msgs.NO_PLAN_SELECTED.message);
      valid = false;
    }
    return valid;
  };

  const onSave = () => {
    // if loading something, wait until completion of load before allowing to save
    if (
      isLoadingFilters ||
      isLoadingPlans ||
      isLoadingPlansAndCoverages ||
      isLoadingPrices ||
      isLoadingPriceSummary
    ) {
      return;
    }

    // validate before saving
    if (!isValidFormData()) {
      return;
    }

    // Fire GA Event - Capture includeSellersCoverage
    if (
      selectedFilters.includeSellersCoverage &&
      props?.agentRepresents &&
      isSellerCoverageUpdated
    ) {
      setIsSellerCoverageUpdated(false);
      fireGAEvent(CAPTURE_SELLERS_COVERAGE(props.agentRepresents));
    }

    props.onSave({
      formData: {
        selectedPlanCoverages,
        selectedGuestUnit,
        selectedFilters,
        selectedMilitaryDiscount: isMilitaryDiscountApplied,
      },
      pricing: {
        pricing: productPricingDetails?.pricing,
        details: productPricingDetails?.details,
        earnixRuleID: props.earnixRuleID,
        appliedSpecialDiscounts: productPricingDetails?.appliedSpecialDiscounts,
      },
      pricingDetailsRequest: productPricingDetailsRequest,
    });
  };

  const onChangeSeller = (includeSellers: boolean) => {
    const updateSelectedFilters: SelectedProductFilters = {
      ...selectedFilters,
      includeSellersCoverage: includeSellers,
    };
    setIsSellerCoverageUpdated(true);
    setSelectedFilters(updateSelectedFilters);

    if (isPlanFilterComplete(updateSelectedFilters)) {
      fetchAndUpdatePlans(updateSelectedFilters);
      setSelections({ selectedPlanCoverages, selectedGuestUnit });
    }
  };

  const onChangeTerms = (selection: string) => {
    const updateSelectedFilters: SelectedProductFilters = {
      ...selectedFilters,
      termYear: Number(selection),
    };
    setSelectedFilters(updateSelectedFilters);
    setFormErrorMsg(null);

    if (
      isPlanFilterComplete(updateSelectedFilters) &&
      updateSelectedFilters.termYear !== selectedFilters?.termYear
    ) {
      fetchAndUpdatePlans(updateSelectedFilters);
      setSelections({ selectedPlanCoverages, selectedGuestUnit });
    }
  };

  const onChangeAC = (includeAC: boolean) => {
    const updateSelectedFilters: SelectedProductFilters = {
      ...selectedFilters,
      includeACCoverage: includeAC,
    };
    setSelectedFilters(updateSelectedFilters);

    if (isPlanFilterComplete(updateSelectedFilters)) {
      fetchAndUpdatePlans(updateSelectedFilters);
      setSelections({ selectedPlanCoverages, selectedGuestUnit });
    }
  };

  const onSelectPlan = (
    plan: Product,
    existingSelections = { selectedPlanCoverages, selectedGuestUnit },
  ) => {
    // if no plan provided, clear plan/coverage/guest selection
    if (isNullOrUndefined(plan)) {
      clearPlanSelections();
      return;
    }

    setFormErrorMsg(null);

    setSelectedPlanCoverages({
      ...existingSelections.selectedPlanCoverages,
      product: {
        id: plan.id,
        starPVID: plan.starPVID,
        name: plan.name,
        acCoverage: plan.acCoverage,
        sellersCoverage: plan.sellersCoverage,
        contractTermMonths: plan.contractTermMonths,
      },
    });

    // determine if guest unit need to be removed from selection if not offered for the product
    if (existingSelections.selectedGuestUnit && plan.maxGuestUnits === 0) {
      console.debug('guest unit not offered in new plan. removing');
      setSelectedGuestUnit(false);
    } else if (existingSelections.selectedGuestUnit && plan.maxGuestUnits !== 0) {
      // clearPlanSelections(); is sometimes called before this which setsSelectedGuestUnit to false,
      // this leads to this being out of sync after this function finishes, this ensures that it's in sync
      setSelectedGuestUnit(true);
    }
  };

  /**
   * Checks if the coverage has a special condition for displaying a modal.
   * If so, the coverage modal is set up.  Otherwise, the coverage is normal continue with normal flow.
   * If a coverage modal is needed for the coverage, the coverage is not selected until
   * the user has accepted the agreement on the coverage modal.
   * @param cvg the selected coverage
   * @return boolean - true if a coverage that needs acceptance modal. Otherwise, false as normal coverage.
   */
  const handleCoverageAcceptCheck = (cvg: ProductCoverage): boolean => {
    if (requiresConfirmationById(cvg.starCoverageId)) {
      const cvgId = cvg.starCoverageId;
      let cvgType: CoverageModalType;

      if (isRoofRXCoverageById(cvgId)) {
        cvgType = CoverageModalType.RoofRX;
      } else if (isElectronicsCoverageById(cvgId)) {
        cvgType = CoverageModalType.Electronics;
      } else if (isNHDCoverageById(cvgId)) {
        cvgType = CoverageModalType.NHD;
      } else {
        console.warn('no confirmation type specified, coverage wont be selected nor open modal');
      }

      setPendingAcceptCoverage({ cvgType, cvg });
      return true;
    }
    return false;
  };

  const onSelectOptionalCoverage = (
    cvg: ProductCoverage,
    skipCoverageAcceptCheck = false,
  ): void => {
    const { optionalCoverages } = selectedPlanCoverages;
    const currentSelectionLength = optionalCoverages.length;
    // filter out the selection, if after filter selection remains the same, add it instead with quantity of 1
    let updatedSelection = optionalCoverages.filter(
      (selectedCvg) => selectedCvg.id !== cvg.starCoverageId,
    );

    if (updatedSelection.length === currentSelectionLength) {
      // when adding new coverage, and
      // - we are not skipping the coverage accept check, and
      // - determined to be a coverage that requires a check
      // have the user accept the coverage agreement before continuing instead of adding immediately.
      if (!skipCoverageAcceptCheck && handleCoverageAcceptCheck(cvg)) {
        return;
      }

      updatedSelection.push({
        id: cvg.starCoverageId,
        name: cvg.name,
        quantity: 1,
      });
    }

    // handle disabled coverages
    updatedSelection = removeDisabledOptionalCoverages(updatedSelection);

    setSelectedPlanCoverages({
      ...selectedPlanCoverages,
      optionalCoverages: updatedSelection,
    });
  };

  /** iterates through the selected coverages and remove any that have dependency on it */
  const removeDisabledOptionalCoverages = (
    selectedOptionalCoverages: SelectedItem[],
  ): SelectedItem[] => {
    const updatedSelection: SelectedItem[] = [];
    const coverageHandler = new CoverageDependencyHandler();
    const selectedCoverageIds = selectedOptionalCoverages.map((cvg) => cvg.id);
    selectedOptionalCoverages.forEach((cvg) => {
      if (!coverageHandler.shouldCoverageBeDisabled(cvg.id, selectedCoverageIds)) {
        updatedSelection.push(cvg);
      }
    });
    return updatedSelection;
  };

  const onSelectGroupCoverage = (group: ProductGroup): void => {
    const { groupCoverages } = selectedPlanCoverages;
    const currentSelectionLength = groupCoverages.length;
    // filter out the selection, if after filter selection remains the same, add it instead with quantity of 1
    const updatedSelection = groupCoverages.filter(
      (selectedCvg) => selectedCvg.id !== group.starGroupId,
    );
    if (updatedSelection.length === currentSelectionLength) {
      updatedSelection.push({
        id: group.starGroupId,
        name: group.name,
        quantity: 1,
      });
    }
    setSelectedPlanCoverages({
      ...selectedPlanCoverages,
      groupCoverages: updatedSelection,
    });
  };

  const onSelectGuestUnit = (): void => {
    setSelectedGuestUnit(!selectedGuestUnit);
  };

  const onCoverageModalAccept = () => {
    onSelectOptionalCoverage(pendingAcceptCoverage.cvg, true);
    setPendingAcceptCoverage(null);
    checkIfCoverageConfirmationNeeded();
  };

  const onCoverageModalDecline = () => {
    setPendingAcceptCoverage(null);
    checkIfCoverageConfirmationNeeded();
  };

  const onQuantityChangeOptionalCoverage = (cvgId: string, qty: number): void => {
    const { optionalCoverages } = selectedPlanCoverages;

    // updates the quantity only if the id matches
    const updatedSelection = optionalCoverages.reduce((acc, cvg) => {
      if (cvg.id === cvgId) {
        cvg.quantity = qty;
      }
      return [...acc, cvg];
    }, []);

    setSelectedPlanCoverages({
      ...selectedPlanCoverages,
      optionalCoverages: updatedSelection,
    });
  };

  return (
    <>
      <CardNewOrder
        alertText={formErrorMsg || props.alertText}
        submitHelperText={props.submitHelperText}
        cancelModal={true} // display the cancel button on mobile view
        title="Plans and Coverage"
        isEditing={props.isEditing}
        onOpen={props.onOpen}
        onClose={props.onClose}
        onClear={clearFormData}
        onSave={onSave}
        isSmall={props.isSmall}
        disableEdit={props.disableEdit}
        defaultCollapsed={props.defaultCollapsed}
        afterSummary={
          <PriceSummary
            selectedPlanCoverages={props.formData?.selectedPlanCoverages || selectedPlanCoverages}
            pricingData={props.pricing?.pricing || productPricingDetails?.pricing} // if pricing is not provided in props, use the card pricing directly
            pricingDetails={props.pricing?.details || productPricingDetails?.details}
            listingEffectiveDate={listingEffectiveDate}
            listingExpirationDate={listingExpirationDate}
            isLoadingPriceSummary={false} // should be a prop passed on parent when loading pricing
            militaryDiscountApplied={
              props.pricing?.appliedSpecialDiscounts?.includes(MILITARY_DISCOUNT) ||
              productPricingDetails?.appliedSpecialDiscounts?.includes(MILITARY_DISCOUNT)
            }
          />
        }
      >
        {!props.isEditing ? (
          <>
            <ReadOnlyView
              formData={props.formData}
              isSmall={props.isSmall}
              propertyAddress={props.propertyAddress}
              selectedProductInfo={availablePlansAndCoverages.find(
                (plan) => plan.product.starPVID === selectedPlanCoverages.product?.starPVID,
              )}
            />
          </>
        ) : (
          <div className="sm-max:-mx-4 mt-4">
            <FormNewOrderPlanFilter
              isLoadingFilters={isLoadingFilters}
              disableSellersSelection={props.disableSellersSelection}
              enabledFilters={enabledFilters}
              selectedFilters={selectedFilters}
              onChangeSeller={onChangeSeller}
              onChangeTerms={onChangeTerms}
              onChangeAC={onChangeAC}
              isPastClosingDate={isPastClosingDate}
            >
              {isLoadingPlans || isLoadingPlansAndCoverages || isLoadingPrices ? (
                <ProgressIndicator
                  variant="linear"
                  size="medium"
                  label={getPlanLoadLabel()}
                  labelPlacement="bottom"
                />
              ) : (
                <>
                  {availablePlansAndCoverages.length !== 0 &&
                    props.allowMilitaryDiscount &&
                    (!props.disableEditMilitaryDiscount ||
                      (props.disableEditMilitaryDiscount && pricingIncludesMilitaryDiscount)) && (
                      <div>
                        <Checkbox
                          id="military-discount"
                          className="mt-5"
                          disabled={props.disableEditMilitaryDiscount}
                          label="Military Discount"
                          checked={isMilitaryDiscountApplied}
                          onChange={() => setIsMilitaryDiscountApplied(!isMilitaryDiscountApplied)}
                        />
                      </div>
                    )}
                </>
              )}
              <FormNewOrderPlanSelection
                selectedProductId={selectedPlanCoverages.product?.starPVID}
                isSmall={props.isSmall}
                isLoading={isLoadingPlans || isLoadingPlansAndCoverages || isLoadingPrices}
                loadingSkeletonCnt={availablePlans?.length || 3}
                availablePlansAndCoverages={availablePlansAndCoverages}
                planPricingData={planPricingData}
                onSelectPlan={onSelectPlan}
              >
                {(isLoadingPlans || isLoadingPlansAndCoverages || isLoadingPrices) && (
                  <ProgressIndicator
                    variant="linear"
                    size="medium"
                    label={getPlanLoadLabel()}
                    labelPlacement="bottom"
                  />
                )}
              </FormNewOrderPlanSelection>
            </FormNewOrderPlanFilter>

            <FormNewOrderCoverageTables
              selectedPlanCoverages={selectedPlanCoverages}
              selectedGuestUnit={selectedGuestUnit}
              availablePlansAndCoverages={availablePlansAndCoverages}
              planPricingData={planPricingData}
              selectionPricingDetails={productPricingDetails}
              enableQuantitySelection={props.enableQuantitySelection}
              onSelectOptionalCoverage={onSelectOptionalCoverage}
              onSelectGroupCoverage={onSelectGroupCoverage}
              onSelectGuestUnit={onSelectGuestUnit}
              onQuantityChangeOptionalCoverage={onQuantityChangeOptionalCoverage}
            />

            <PriceSummary
              selectedPlanCoverages={selectedPlanCoverages}
              pricingData={productPricingDetails?.pricing}
              pricingDetails={productPricingDetails?.details}
              listingEffectiveDate={listingEffectiveDate}
              listingExpirationDate={listingExpirationDate}
              isLoadingPriceSummary={false} // never show spinner during editing for seamless experience
              militaryDiscountApplied={productPricingDetails?.appliedSpecialDiscounts?.includes(
                MILITARY_DISCOUNT,
              )}
            />
          </div>
        )}
      </CardNewOrder>

      <ModalRoofRXCoverage
        isActive={pendingAcceptCoverage?.cvgType === CoverageModalType.RoofRX}
        onAccept={onCoverageModalAccept}
        onDecline={onCoverageModalDecline}
      />
      <ModalElectronicsCoverage
        isActive={pendingAcceptCoverage?.cvgType === CoverageModalType.Electronics}
        onAccept={onCoverageModalAccept}
        onDecline={onCoverageModalDecline}
      />
      <ModalNHDCoverage
        isActive={pendingAcceptCoverage?.cvgType === CoverageModalType.NHD}
        onAccept={onCoverageModalAccept}
        onDecline={onCoverageModalDecline}
      />
    </>
  );
};

export default CardNewOrderPlansCoverage;

const ReadOnlyView: React.FC<
  Pick<
    CardNewOrderPlansCoverageProps,
    'formData' | 'isSmall' | 'propertyAddress' | 'selectedProductInfo'
  >
> = (props) => {
  const toTermYearString = (termMonths: number): string => {
    if (termMonths) {
      return `${termMonths / 12} Year`;
    }
    return null;
  };

  const optionalCoverages = useMemo(() => {
    return [
      ...(props.formData?.selectedPlanCoverages?.optionalCoverages || []).map((item) => {
        return {
          ...item,
          name:
            props.selectedProductInfo?.coverages?.optional?.find(
              (cvg) => cvg.starCoverageId === item.id,
            )?.name || item.name,
        };
      }, null),
      ...(props.formData?.selectedPlanCoverages?.groupCoverages || []),
      ...(props.formData?.selectedGuestUnit
        ? [{ name: coverageNameOverridesDictionary['Guest Unit'] }]
        : []), // add guest unit
    ]
      .reduce<string[]>((acc, cvg) => {
        const name = coverageNameOverridesDictionary[cvg.name] || cvg.name; // get array of name, with name override if necessary
        return acc.includes(name) ? acc : [...acc, name]; // remove duplicates
      }, [])
      .sort((a, b) => a.localeCompare(b)); // sort alphabetically
  }, [props.formData]);

  return (
    <div
      className={classNames([
        'sm:flex sm:flex-col sm:flex-1 sm:-my-2',
        props.isSmall && 'sm:-mx-4',
      ])}
    >
      <div className="sm:flex w-full sm:py-2">
        <div className="sm:w-1/3 sm:px-4">
          <REText variant="label">Plan Name</REText>
          <REText>{props.formData?.selectedPlanCoverages?.product?.name}</REText>
        </div>

        <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
          <REText variant="label">Term Length</REText>
          <REText>
            {toTermYearString(props.formData?.selectedPlanCoverages?.product?.contractTermMonths)}
          </REText>
        </div>

        <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
          <REText variant="label">Optional Coverage</REText>
          {optionalCoverages.map((cvg) => (
            <REText key={cvg}>{cvg}</REText>
          ))}
        </div>
      </div>
      <div className="sm:flex w-full sm:py-2">
        <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
          <REText variant="label">Seller Coverage</REText>
          <REText>
            {props.formData?.selectedPlanCoverages?.product?.sellersCoverage === true && 'Yes'}
            {props.formData?.selectedPlanCoverages?.product?.sellersCoverage === false && 'No'}
          </REText>
        </div>
        <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
          <REText variant="label">A/C Coverage</REText>
          <REText>
            {props.formData?.selectedPlanCoverages?.product?.acCoverage === true && 'Yes'}
            {props.formData?.selectedPlanCoverages?.product?.acCoverage === false && 'No'}
          </REText>
        </div>
      </div>
    </div>
  );
};

interface PriceSummaryProps
  extends Pick<CardNewOrderPlansCoverageProps, 'listingEffectiveDate' | 'listingExpirationDate'> {
  /** holds the names of the selected product and coverages */
  selectedPlanCoverages: SelectedPlanCoverages;
  /** holds the price breakdowns */
  pricingData: OrderPricingData;
  /** holds the price subline details */
  pricingDetails: OrderPricingDetails;
  /** boolean if price summary is being loaded */
  isLoadingPriceSummary: boolean;
  militaryDiscountApplied: boolean;
}

/** seen on edit screen, and for new order the view screen (i.e. contract price summary not provided) */
const PriceSummary: React.FC<PriceSummaryProps> = ({
  selectedPlanCoverages,
  pricingData,
  pricingDetails,
  listingEffectiveDate = getDefaultListingEffectiveDate(), // default to today if none set (i.e. new order)
  listingExpirationDate,
  militaryDiscountApplied,
  ...props
}) => {
  const tableConstants = {
    plan: {
      labelId: 'card-new-order-plans-coverage__summary-plan-name-label',
      priceId: 'card-new-order-plans-coverage__summary-plan-name-price',
    },
    sellerId: 'card-new-order-plans-coverage__summary-sellers-coverage-effective-dates-label',
    perDiem: {
      labelId: 'card-new-order-plans-coverage__summary-sellers-listing-coverage-fee-label',
      priceId: 'card-new-order-plans-coverage__summary-sellers-listing-coverage-fee-value',
    },
    options: {
      label: 'Total Options',
      labelId: 'card-new-order-plans-coverage__summary-total-options-label',
      priceId: 'card-new-order-plans-coverage__summary-total-options-value',
    },
    taxAmount: {
      label: 'Tax',
      labelId: 'card-new-order-plans-coverage__summary-tax-label',
      priceId: 'card-new-order-plans-coverage__summary-tax-value',
    },
    discountAmount: {
      label: 'Military Discount',
      labelId: 'card-new-order-plans-coverage__summary-discount-label',
      priceId: 'card-new-order-plans-coverage__summary-discount-value',
    },
    totalDueAtClosing: {
      label: 'Amount Due At Closing',
      labelId: 'card-new-order-plans-coverage__summary-due-at-closing-label',
      priceId: 'card-new-order-plans-coverage__summary-due-at-closing-value',
    },
  };

  const perDiemLabel = useMemo(() => {
    if (!pricingData?.sellersCoverage) {
      return '';
    }

    let label = `Seller's Listing Coverage Fee`;

    if (pricingDetails?.dailyRate) {
      const days = differenceInCalendarDays(listingExpirationDate, listingEffectiveDate);
      label += ` ($${pricingDetails?.dailyRate}/day for ${days} days)`;
    }
    return label;
  }, [listingEffectiveDate, listingExpirationDate, pricingData, pricingDetails]);

  return (
    <>
      {props.isLoadingPriceSummary ? (
        <div className="mt-4 w-full flex flex-col items-center">
          <ProgressIndicator
            variant="circular"
            size="large"
            label="Loading Order Summary..."
            labelPlacement="bottom"
          />
        </div>
      ) : (
        <>
          {pricingData && pricingDetails && selectedPlanCoverages?.product && (
            <>
              <TableNewOrderPriceSummary
                plan={{
                  ...tableConstants.plan,
                  label: selectedPlanCoverages.product.name,
                  price: pricingData?.productTotal,
                }}
                showSellersCoverageSubLine={selectedPlanCoverages.product.sellersCoverage}
                sellerId={tableConstants.sellerId}
                sellerFromDate={listingEffectiveDate}
                sellerToDate={listingExpirationDate}
                showPerDiemSubLine={pricingData.sellersCoverage > 0}
                perDiem={{
                  ...tableConstants.perDiem,
                  label: perDiemLabel,
                  price: pricingData.sellersCoverage,
                }}
                options={{
                  ...tableConstants.options,
                  price:
                    pricingData.optionalCoverageTotal +
                    pricingData.groupCoverageTotal +
                    pricingData.guestUnitTotal,
                }}
                taxRate={pricingDetails.taxRate}
                taxableAmount={pricingDetails.taxableAmount}
                taxAmount={{
                  ...tableConstants.taxAmount,
                  price: pricingData.tax,
                }}
                discountAmount={{
                  ...tableConstants.discountAmount,
                  price: -pricingData.appliedPromotion,
                }}
                totalDueAtClosing={{
                  ...tableConstants.totalDueAtClosing,
                  price: pricingData.totalDueAtClosing,
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
