import React from 'react';
import { Button, Panel } from '@ftdr/blueprint-components-react';
import Skeleton from 'react-loading-skeleton';
import { fireGAEvent } from '@app/core/tracking.service';
import { MY_ORDERS_NHD_BANNER_CLICK } from '@constants/ga-events.constants';
import REText from '@components/wrappedBDS/REText';

interface MerchandiseWidgetProps {
  htmlText: string;
  navigationUrl: string;
  imageIconUrl: string;
  navIconUrl: string;
  ctaText?: string;
  loading?: boolean;
}

export const MerchandiseWidget: React.FC<MerchandiseWidgetProps> = (
  props: React.PropsWithChildren<MerchandiseWidgetProps>,
) => {
  const handleOnClick = (url: string) => {
    if (url) {
      fireGAEvent(MY_ORDERS_NHD_BANNER_CLICK);
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  };

  return (
    <Panel id="merchandise-widget" shadow={true} paddingY="sm" paddingX="lg" className="w-full">
      <div className="flex items-center space-x-5">
        {props.loading ? (
          <></>
        ) : (
          props.imageIconUrl && (
            <div className="max-w-1/5">
              <img
                id="merchandise-widget-image-icon"
                src={props.imageIconUrl}
                className=" min-h-8 max-h-16 object-contain"
              />
            </div>
          )
        )}
        <div className="flex-1 flex items-center space-x-6">
          {props.loading ? (
            <div className="w-full md:min-w-64">
              <Skeleton count={2} />
            </div>
          ) : (
            <REText
              id="merchandise-widget-text"
              className="zesty-html"
              dangerouslySetInnerHTML={{ __html: props.htmlText }}
            />
          )}
          <div className="flex-1">
            <Button
              id="merchandise-widget-nav-icon"
              label={props.ctaText}
              size="small"
              shape="pill"
              onClick={() => handleOnClick(props.navigationUrl)}
              loading={props.loading}
              loadingAnimation="spinner-only"
            />
          </div>
        </div>
      </div>
    </Panel>
  );
};

MerchandiseWidget.defaultProps = {
  ctaText: 'Go',
};
