import React, { Component } from 'react';
import { FileUpload, MediaUploadMicroFrontend } from '@ftdr/media-upload-micro-frontend';
import { Button, Notification, Panel } from '@ftdr/blueprint-components-react';
import AdminUploadApi from '@apis/adminUpload.api';
import { IsTheme, Theme } from '@app/core/featureToggle';

const ACCEPTED_FILE_TYPES = '.csv';
const MAX_FILES = 1;

interface state {
  uploadedFile: FileUpload;
  submitAttempted: boolean;
  uploadSucceeded: boolean;
  submitSuccessFailDetail: string[];
}

export default class AdminUpload extends Component<unknown, state> {
  constructor(props) {
    super(props);
    this.state = {
      uploadedFile: null,
      submitAttempted: false,
      uploadSucceeded: false,
      submitSuccessFailDetail: [],
    };
  }

  handleMediaUpdateSuccess = (files: FileUpload[]) => {
    this.setState({ submitAttempted: false });
    this.setState({ uploadSucceeded: false });
    if (this.state.uploadedFile || (!this.state.uploadedFile && !!files[0])) {
      this.setState({ uploadedFile: files[0] || null });
    }
  };

  submitUpload = (event) => {
    const formData = new FormData();
    formData.append('uploadedFile', this.state.uploadedFile.file);
    AdminUploadApi.upload(formData).then((res) => {
      this.setState({ submitAttempted: true });
      if (res.success) {
        this.setState({ uploadSucceeded: true, submitSuccessFailDetail: [] });
      } else {
        this.setState({ uploadSucceeded: false, submitSuccessFailDetail: res.details });
      }
    });
    event.preventDefault();
  };

  render() {
    return (
      <Panel
        className="mt-6 flex flex-col items-center"
        shadow={IsTheme(Theme.Ahs2024) ? false : true}
        border="0"
        rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'sm'}
        padding="xl"
      >
        <div className="font-bold">Upload a file (.csv)</div>
        <div className="media-upload-format">
          <MediaUploadMicroFrontend
            id="admin-upload-microfrontend"
            className="height: 100%"
            formFieldClassName="height: 100%"
            baseURL={null}
            token={null}
            isLocalOnly={true}
            label=""
            maxFiles={MAX_FILES}
            multiple={false}
            dragAndDrop={true}
            formField={true}
            accept={ACCEPTED_FILE_TYPES}
            onMediaFailedErrors={null}
            disabled={!!this.state.uploadedFile}
            onMediaUpdate={this.handleMediaUpdateSuccess}
            uploadedFileIds={this.state.uploadedFile ? [this.state.uploadedFile.fileId] : null}
          />
        </div>
        <div className="media-upload-format py-6">
          <Button
            width="full"
            id="submit-admin"
            disabled={!this.state.uploadedFile}
            size="medium"
            label="Submit"
            onClick={this.submitUpload}
          />
        </div>
        {this.state.submitAttempted && this.state.uploadSucceeded && (
          <Notification className="mb-4 max-w-none" status="success" inline={false}>
            Upload succeeded.
          </Notification>
        )}
        {this.state.submitAttempted && !this.state.uploadSucceeded && (
          <Notification className="mb-4 max-w-none" status="error" inline={false}>
            <>
              <span>Upload failed.</span>
              <br />
              {this.state.submitSuccessFailDetail.map((detail) => (
                <>
                  <span>{detail}</span>
                  <br />
                </>
              ))}
            </>
          </Notification>
        )}
      </Panel>
    );
  }
}
