import React from 'react';
import { classNames } from '@utils';
import { IsTheme, Theme } from '@app/core/featureToggle';

export interface ContentBoxContainerProps {
  className?: string;
}

/** Applies just the width of the content box. Used by the child if they are using in conjunction of 'applyContainerWidthToHeaderOnly' */
export const ContentBoxContainer: React.FC<ContentBoxContainerProps> = (props) => {
  const containerWidthClassNames = IsTheme(Theme.Ahs2024) ? 'mx-4 xs:mx-4 sm:mx-4 md:mx-4 lg:mx-4' :
    'lg:px-6 md:px-6 px-2 min-w-80 max-w-xxl mx-auto';

  return (
    <div className={classNames([containerWidthClassNames, props.className])}>
      {props.children}
    </div>
  );
};
