import React from 'react';
import {
  AbstractNewOrderStepTemplate,
  NewOrderStepStates,
  NewOrderStepTemplateProps,
} from '@templates/order/AbstractNewOrderStepTemplate';
import { NewOrderContextData } from '@context/NewOrderContext/model';
import cloneDeep from 'lodash/cloneDeep';
import { NewOrderStep } from '@app/models/order/neworder.model';
import OrderFormCustomerInfo, {
  CustomerEmailPhoneRequirement,
  OrderFormCustomerInfoField,
} from '@components/orders/forms/OrderFormCustomerInfo';
import { OrderFormAddressInfoField } from '@components/orders/forms/OrderFormAddressInfo';
import { Checkbox, Panel, Separator } from '@ftdr/blueprint-components-react';
import { OrderFormFieldError } from '@components/orders/forms/OrderForm.types';
import {
  validateCity,
  validateCooperatingAgent,
  validateCooperatingOffice,
  validateCustomerEmailPhoneInput,
  validateFirstNameInput,
  validateLastNameInput,
  validateState,
  validateStreetAddress,
  validateZip,
  validateClosingOffice,
  validateClosingAgent,
} from '@services/validation/NewOrder.FieldValidationRules';
import { ORDER_CARD_TITLE, ORDER_FORM_FIELDS } from '@constants/newOrder-constants';
import OrderFormOfficeAgent, {
  OrderFormOfficeAgentField,
} from '@components/orders/forms/OrderFormOfficeAgent';
import { getProfileOfficesByType } from '@services/helpers/profile.offices.helper';
import { OfficeType } from '@app/models';
import { isREAgent } from '@helpers/profile.utils';
import {
  CustomerType,
  OrderAddressInfoFormData,
  OrderCustomerFormData,
  Represents,
} from '@app/models/order/order.model';
import AddressVerification, { AddressVerificationItem } from '@components/misc/AddressVerification';
import { capitalize } from 'lodash';
import OrderFormClosingInfo, {
  OrderFormClosingInfoField,
} from '@components/orders/forms/OrderFormClosingInfo';
import msgs from '@app/locales/en';
import { fireGAEvent } from '@app/core/tracking.service';
import { NEW_ORDER_FLOW__BUYER_AND_SELLER_COMPLETE } from '@constants/ga-events.constants';
import REText from '@components/wrappedBDS/REText';

interface AddressVerificationDetails {
  enableAddressVerification: boolean;
  addressVerificationItems: AddressVerificationItem[];
}

interface States
  extends NewOrderStepStates,
    Pick<
      NewOrderContextData,
      | 'buyerInfo'
      | 'coBuyerInfo'
      | 'sellerInfo'
      | 'coSellerInfo'
      | 'cooperatingAgent'
      | 'closingAgent'
      | 'closingInfo'
    > {
  buyerInfoErrors: OrderFormFieldError<OrderFormCustomerInfoField>;
  coBuyerInfoErrors: OrderFormFieldError<OrderFormCustomerInfoField>;
  sellerInfoErrors: OrderFormFieldError<OrderFormCustomerInfoField>;
  coSellerInfoErrors: OrderFormFieldError<OrderFormCustomerInfoField>;
  cooperatingAgentErrors: OrderFormFieldError<OrderFormOfficeAgentField>;
  closingInfoErrors: OrderFormFieldError<OrderFormClosingInfoField>;

  showCoBuyerInfoForm: boolean;
  showCoSellerInfoForm: boolean;

  showBuyerAddressForm: boolean;
  showCobuyerAddressForm: boolean;
  showSellerAddressForm: boolean;
  showCosellerAddressForm: boolean;

  showBuyerInfoRadio: boolean;
  showSellerInfoRadio: boolean;

  addressVerification: AddressVerificationDetails;
}

export class NewOrderStep3Template extends AbstractNewOrderStepTemplate<States> {
  constructor(props) {
    super(props);
  }

  getDefaultState(props: NewOrderStepTemplateProps): States {
    return {
      buyerInfo: cloneDeep(props.savedData.buyerInfo),
      coBuyerInfo: cloneDeep(props.savedData.coBuyerInfo),
      sellerInfo: cloneDeep(props.savedData.sellerInfo),
      coSellerInfo: cloneDeep(props.savedData.coSellerInfo),
      cooperatingAgent: cloneDeep(props.savedData.cooperatingAgent),
      closingAgent: cloneDeep(props.savedData.closingAgent),
      closingInfo: cloneDeep(props.savedData.closingInfo),
      cooperatingAgentErrors: {
        [OrderFormOfficeAgentField.officeInput]: '',
        [OrderFormOfficeAgentField.agentInput]: '',
      },
      buyerInfoErrors: {
        [OrderFormCustomerInfoField.firstName]: '',
        [OrderFormCustomerInfoField.lastName]: '',
        [OrderFormCustomerInfoField.emailAddress]: '',
        [OrderFormCustomerInfoField.phoneNumber]: '',
        [OrderFormCustomerInfoField.zip]: '',
        [OrderFormCustomerInfoField.streetAddress]: '',
        [OrderFormCustomerInfoField.city]: '',
        [OrderFormCustomerInfoField.state]: '',
        [OrderFormCustomerInfoField.unitNumber]: '',
      },
      coBuyerInfoErrors: {
        [OrderFormCustomerInfoField.firstName]: '',
        [OrderFormCustomerInfoField.lastName]: '',
        [OrderFormCustomerInfoField.emailAddress]: '',
        [OrderFormCustomerInfoField.phoneNumber]: '',
        [OrderFormCustomerInfoField.zip]: '',
        [OrderFormCustomerInfoField.streetAddress]: '',
        [OrderFormCustomerInfoField.city]: '',
        [OrderFormCustomerInfoField.state]: '',
        [OrderFormCustomerInfoField.unitNumber]: '',
      },
      sellerInfoErrors: {
        [OrderFormCustomerInfoField.firstName]: '',
        [OrderFormCustomerInfoField.lastName]: '',
        [OrderFormCustomerInfoField.emailAddress]: '',
        [OrderFormCustomerInfoField.phoneNumber]: '',
        [OrderFormCustomerInfoField.zip]: '',
        [OrderFormCustomerInfoField.streetAddress]: '',
        [OrderFormCustomerInfoField.city]: '',
        [OrderFormCustomerInfoField.state]: '',
        [OrderFormCustomerInfoField.unitNumber]: '',
      },
      coSellerInfoErrors: {
        [OrderFormCustomerInfoField.firstName]: '',
        [OrderFormCustomerInfoField.lastName]: '',
        [OrderFormCustomerInfoField.emailAddress]: '',
        [OrderFormCustomerInfoField.phoneNumber]: '',
        [OrderFormCustomerInfoField.zip]: '',
        [OrderFormCustomerInfoField.streetAddress]: '',
        [OrderFormCustomerInfoField.city]: '',
        [OrderFormCustomerInfoField.state]: '',
        [OrderFormCustomerInfoField.unitNumber]: '',
      },
      closingInfoErrors: {
        [OrderFormClosingInfoField.closingOffice]: '',
        [OrderFormClosingInfoField.closingOfficeAgent]: '',
      },
      showCoBuyerInfoForm: false,
      showCoSellerInfoForm: false,
      showBuyerAddressForm: false,
      showCobuyerAddressForm: false,
      showSellerAddressForm: false,
      showCosellerAddressForm: false,
      showBuyerInfoRadio: false,
      showSellerInfoRadio: false,
      addressVerification: {
        enableAddressVerification: false,
        addressVerificationItems: [],
      },
    };
  }

  getPendingData(): NewOrderContextData {
    return {
      ...this.props.savedData,
      buyerInfo: this.state.buyerInfo,
      coBuyerInfo: this.state.coBuyerInfo,
      sellerInfo: this.state.sellerInfo,
      coSellerInfo: this.state.coSellerInfo,
      cooperatingAgent: this.state.cooperatingAgent,
      closingAgent: this.state.closingAgent,
      closingInfo: this.state.closingInfo,
    };
  }

  getPartialSubmittedData(): NewOrderContextData {
    // should perform validation on the fields and stripped off any incomplete data
    return this.getPendingData(); // for now, saving all data regardless of incomplete data
  }

  onClear(): void {
    this.setState(this.getDefaultState(this.props));
  }
  onSubmit(): void {
    if (!this.isFormValidForSubmission()) {
      return;
    }

    const dataToSubmit = this.getPendingData();
    this.props.submitPage(dataToSubmit);

    // Should track in GA that Buyer and Seller was completed!
    fireGAEvent(
      NEW_ORDER_FLOW__BUYER_AND_SELLER_COMPLETE(
        this.props.userProfile,
        dataToSubmit.agentInfo.represents,
      ),
    );
  }
  onEdit(editStep: NewOrderStep): void {
    this.props.editAnotherStep(editStep, this.getPartialSubmittedData());
  }

  /** customer information is only required if representing. otherwise it is all optional */
  customerEmailPhoneRequirements = (customerType: CustomerType) => {
    const { represents } = this.props.savedData.agentInfo;

    if (
      customerType === CustomerType.Seller &&
      [Represents.Seller, Represents.Both].includes(represents)
    ) {
      return CustomerEmailPhoneRequirement.EmailOrPhoneRequired;
    }

    if (
      customerType === CustomerType.Buyer &&
      [Represents.Buyer, Represents.Both].includes(represents)
    ) {
      // should use optimizely to change the validation type for buyer
      // if (optimizely.variations.emailandphonerequired) {
      //  return CustomerEmailPhoneRequirement.EmailAndPhoneRequired;
      // }
      return CustomerEmailPhoneRequirement.EmailOrPhoneRequired;
    }
    // default to optional for all other situations
    return CustomerEmailPhoneRequirement.EmailAndPhoneOptional;
  };

  validateCustomerInfoPhoneEmailFields = (
    fieldName: OrderFormCustomerInfoField,
    value: string,
    customerType: CustomerType,
  ) => {
    const { customerInfoKey } = this.getCustomerStateKeysByCustomerType(customerType);
    const customerInfo = this.state[customerInfoKey] as OrderCustomerFormData;

    const customerEmailPhoneValidation: CustomerEmailPhoneRequirement =
      this.customerEmailPhoneRequirements(customerType);

    switch (fieldName) {
      case OrderFormCustomerInfoField.emailAddress:
        return validateCustomerEmailPhoneInput(
          value,
          customerInfo.phoneNumber,
          customerEmailPhoneValidation,
        );
      case OrderFormCustomerInfoField.phoneNumber:
        return validateCustomerEmailPhoneInput(
          customerInfo.emailAddress,
          value,
          customerEmailPhoneValidation,
        );
      default:
        break;
    }
    return { phone: '', email: '' };
  };

  validateCustomerInfoField = (fieldName: OrderFormCustomerInfoField, value: string) => {
    switch (fieldName) {
      case OrderFormCustomerInfoField.firstName:
        return validateFirstNameInput(value);
      case OrderFormCustomerInfoField.lastName:
        return validateLastNameInput(value);
      case OrderFormCustomerInfoField.zip:
        return validateZip(value);
      case OrderFormCustomerInfoField.city:
        return validateCity(value);
      case OrderFormCustomerInfoField.state:
        return validateState(value);
      case OrderFormCustomerInfoField.streetAddress:
        return validateStreetAddress(value, true);
      case OrderFormCustomerInfoField.emailAddress: // should use validateCustomerInfoPhoneEmailFields
      case OrderFormCustomerInfoField.phoneNumber: // should use validateCustomerInfoPhoneEmailFields
      default:
        break;
    }
    return '';
  };

  getCustomerFormErrors = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormCustomerInfoField,
    value: string,
    customerType: CustomerType,
  ): OrderFormFieldError<OrderFormCustomerInfoField> => {
    const { customerInfoErrorsKey } = this.getCustomerStateKeysByCustomerType(customerType);
    const prevErrors = this.state[customerInfoErrorsKey];
    const errors = cloneDeep(prevErrors);

    if (!shouldExecValidateFc) {
      errors[fieldName] = value;
      return errors;
    }
    // for email and phone, they have special validation rules to use.
    if (
      [OrderFormCustomerInfoField.emailAddress, OrderFormCustomerInfoField.phoneNumber].includes(
        fieldName,
      )
    ) {
      const { email, phone } = this.validateCustomerInfoPhoneEmailFields(
        fieldName,
        value,
        customerType,
      );
      errors.emailAddress = email;
      errors.phoneNumber = phone;
    } else {
      errors[fieldName] = this.validateCustomerInfoField(fieldName, value);
    }

    return errors;
  };

  /** this would only override address errors to the form and return just the address errors */
  validateAndUpdateCustomerAddressInfoError = (
    address: OrderAddressInfoFormData,
    customerType: CustomerType,
  ): OrderFormFieldError<OrderFormAddressInfoField> => {
    const { customerInfoErrorsKey } = this.getCustomerStateKeysByCustomerType(customerType);
    const errs: OrderFormFieldError<OrderFormAddressInfoField> = {
      [OrderFormAddressInfoField.streetAddress]: this.validateCustomerInfoField(
        OrderFormCustomerInfoField.streetAddress,
        address.streetAddress,
      ),
      [OrderFormAddressInfoField.zip]: this.validateCustomerInfoField(
        OrderFormCustomerInfoField.zip,
        address.zip,
      ),
      [OrderFormAddressInfoField.state]: this.validateCustomerInfoField(
        OrderFormCustomerInfoField.state,
        address.state,
      ),
      [OrderFormAddressInfoField.city]: this.validateCustomerInfoField(
        OrderFormCustomerInfoField.city,
        address.city,
      ),
      [OrderFormAddressInfoField.unitNumber]: '',
    };
    this.updateCustomerInfoErrors(customerType, {
      ...this.state[customerInfoErrorsKey], // leaves all of the errors unrelated to address
      ...errs, // sets the new address validation errors
    });
    return errs;
  };

  getCustomerStateKeysByCustomerType = (
    customerType: CustomerType,
  ): {
    customerInfoKey: string;
    customerInfoErrorsKey: string;
    showCustomerAddressFormKey: string;
  } => {
    return {
      customerInfoKey: `${customerType}Info`,
      customerInfoErrorsKey: `${customerType}InfoErrors`,
      showCustomerAddressFormKey: `show${capitalize(customerType)}AddressForm`,
    };
  };

  getCustomerStateDataByCustomerType = (
    customerType: CustomerType,
    fromState: States = this.state,
  ): {
    customerInfo: OrderCustomerFormData;
    customerInfoErrors: OrderFormFieldError<OrderFormCustomerInfoField>;
    showCustomerAddressForm: boolean;
  } => {
    const { customerInfoKey, customerInfoErrorsKey, showCustomerAddressFormKey } =
      this.getCustomerStateKeysByCustomerType(customerType);
    return {
      customerInfo: this.state[customerInfoKey],
      customerInfoErrors: this.state[customerInfoErrorsKey],
      showCustomerAddressForm: this.state[showCustomerAddressFormKey],
    };
  };

  updateCustomerInfo(customerType: CustomerType, newCustomerInfo: OrderCustomerFormData) {
    const { customerInfoKey } = this.getCustomerStateKeysByCustomerType(customerType);
    this.setState((prevState) => ({
      ...prevState,
      [customerInfoKey]: {
        ...prevState[customerInfoKey],
        ...newCustomerInfo,
      },
    }));
  }

  updateCustomerInfoErrors(
    customerType: CustomerType,
    newCustomerInfo: OrderFormFieldError<OrderFormCustomerInfoField>,
  ) {
    const { customerInfoErrorsKey } = this.getCustomerStateKeysByCustomerType(customerType);
    this.setState((prevState) => ({
      ...prevState,
      [customerInfoErrorsKey]: {
        ...prevState[customerInfoErrorsKey],
        ...newCustomerInfo,
      },
    }));
  }

  updateShowCustomerAddressForm(customerType: CustomerType, showCustomerAddressForm: boolean) {
    const { showCustomerAddressFormKey } = this.getCustomerStateKeysByCustomerType(customerType);
    this.setState((prevState) => ({
      ...prevState,
      [showCustomerAddressFormKey]: showCustomerAddressForm,
    }));
  }

  toggleIncludeCoBuyerInfo = () => {
    this.setState({ showCoBuyerInfoForm: !this.state.showCoBuyerInfoForm });
  };
  toggleIncludeCoSellerInfo = () => {
    this.setState({ showCoSellerInfoForm: !this.state.showCoSellerInfoForm });
  };

  validateCustomerAddress = async (customer: OrderCustomerFormData, customerType: CustomerType) => {
    const addressErrors = this.validateAndUpdateCustomerAddressInfoError(customer, customerType);

    const overrideModalId = `validate-address--${customerType.toLowerCase()}`;
    const customerTypeHeading = capitalize(customerType.replace('co', 'Co-'));
    const overrideModalHeading = `${customerTypeHeading} Address Verification`;

    if (Object.values(addressErrors).every((v) => v === '')) {
      this.setState({
        addressVerification: {
          enableAddressVerification: true,
          addressVerificationItems: [
            {
              overrideModalId,
              overrideModalHeading,
              key: customerType,
              address: {
                city: customer.city,
                state: customer.state,
                zip: customer.zip,
                streetAddress: customer.streetAddress,
                unit: customer.unit,
              },
            },
          ],
        },
      });
    }
  };

  updateAddressFromVerification = ({ verifiedAddress, key: customerType }) => {
    const { customerInfoKey } = this.getCustomerStateKeysByCustomerType(customerType);
    this.updateCustomerInfo(customerType, {
      ...this.state[customerInfoKey],
      streetAddress: verifiedAddress.streetAddress,
      unit: verifiedAddress.unit,
      city: verifiedAddress.city,
      state: verifiedAddress.state,
      zip: verifiedAddress.zip,
      hasVerified: true,
      addressUUID: verifiedAddress.meta?.addressId, // needed for orders ms integration
    });
  };

  onAddressVerificationComplete = () => {
    /* do nothing for now */
  };

  onAddressVerificationReset = () => {
    this.setState({
      addressVerification: {
        enableAddressVerification: false,
        addressVerificationItems: [],
      },
    });
  };

  onUpdateErrorOfficeAgentField = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormOfficeAgentField,
    value: string = '',
  ) => {
    const errors = this.state.cooperatingAgentErrors;
    errors[fieldName] = shouldExecValidateFc
      ? this.validateOfficeAgentField(fieldName, value)
      : value;
    this.setState({ cooperatingAgentErrors: errors });
  };

  validateOfficeAgentField = (fieldName: OrderFormOfficeAgentField, value: string) => {
    switch (fieldName) {
      case OrderFormOfficeAgentField.officeInput:
        return validateCooperatingOffice(value); // TBD: check validation logic in existing flow
      case OrderFormOfficeAgentField.agentInput:
        return validateCooperatingAgent(value);
    }
    return '';
  };

  shouldDisplayCustomerHelperText = () => {
    if (
      this.props.savedData.productFilters.sellersCoverage &&
      this.props.savedData.agentInfo.represents === Represents.Buyer
    ) {
      return this.custHelpRenderer("A plan with seller's coverage requires seller information");
    }
    if (
      this.props.savedData.productFilters.projectedClosingDate &&
      this.props.savedData.agentInfo.represents === Represents.Seller
    ) {
      return this.custHelpRenderer('Buyer Information required when closing date entered');
    }
  };

  custHelpRenderer = (text) => (
    <Panel backgroundColor="interactive-50" borderColor="gray-300">
      <REText variant="body-short" className="inline">
        {text}
      </REText>
    </Panel>
  );

  cooperatingOfficeAgentRenderer = () => (
    <div>
      {this.shouldDisplayCustomerHelperText()}
      <OrderFormOfficeAgent
        hideHeading={true}
        ids={{
          [OrderFormOfficeAgentField.officeInput]: 'wb_cooperatingOfficeTypeahead',
          [OrderFormOfficeAgentField.agentInput]: 'wb_cooperatingAgentTypeahead',
        }}
        labels={{
          [OrderFormOfficeAgentField.officeInput]: 'Cooperating Office',
          [OrderFormOfficeAgentField.agentInput]: 'Cooperating Agent',
        }}
        idNoDropdowns={{
          [OrderFormOfficeAgentField.officeInput]: 'wb_cooperatingOfficeTypeahead',
          [OrderFormOfficeAgentField.agentInput]: 'wb_cooperatingAgentTypeahead',
        }}
        officeSuggestions={getProfileOfficesByType(this.props.userProfile, OfficeType.RealEstate)}
        disableOfficeSuggestions={false}
        disableOfficeAgentSelection={isREAgent(this.props.userProfile)}
        formData={this.state.cooperatingAgent}
        errors={this.state.cooperatingAgentErrors}
        onUpdateFormData={(data) => this.setState({ cooperatingAgent: data })}
        onValidateField={(fieldName, value) =>
          this.onUpdateErrorOfficeAgentField(true, fieldName, value)
        }
        clearFieldError={(fieldName) => this.onUpdateErrorOfficeAgentField(false, fieldName)}
        onFieldError={(fieldName, err) => this.onUpdateErrorOfficeAgentField(false, fieldName, err)}
        onFormError={console.log}
        isOfficeRequired={false}
        isAgentRequired={false}
      />
    </div>
  );

  toggleIncludeCustomerAddress = (customerType: CustomerType) => {
    const { customerInfo, customerInfoErrors, showCustomerAddressForm } =
      this.getCustomerStateDataByCustomerType(customerType);
    const emptyAddressFieldErrors = {
      zip: '',
      streetAddress: '',
      city: '',
      state: '',
      unitNumber: '',
    };
    this.updateCustomerInfo(customerType, {
      ...customerInfo,
      ...emptyAddressFieldErrors,
    });
    this.updateCustomerInfoErrors(customerType, {
      ...customerInfoErrors,
      ...emptyAddressFieldErrors,
    });
    this.updateShowCustomerAddressForm(customerType, !showCustomerAddressForm);
  };

  isSellerRequired = (): boolean => {
    return (
      this.props.savedData.agentInfo.represents !== Represents.Buyer ||
      this.props.savedData.productFilters.sellersCoverage
    );
  };
  isBuyerRequired = (): boolean => {
    return (
      this.props.savedData.agentInfo.represents !== Represents.Seller ||
      !!this.props.savedData.productFilters.projectedClosingDate
    );
  };
  isCoBuyerRequired = (): boolean => {
    return this.state.showCoBuyerInfoForm;
  };
  isCoSellerRequired = (): boolean => {
    return this.state.showCoSellerInfoForm;
  };

  getAllCustomerFormErrors = (
    customerData: OrderCustomerFormData,
    customerType: CustomerType,
    isCustomerRequired: boolean,
    customerInfoFormRadioState?: boolean,
  ): OrderFormFieldError<OrderFormCustomerInfoField> => {
    const customerEmailPhoneValidation: CustomerEmailPhoneRequirement =
      this.customerEmailPhoneRequirements(customerType);
    const { showCustomerAddressForm } = this.getCustomerStateDataByCustomerType(customerType);
    const phoneEmailError = validateCustomerEmailPhoneInput(
      customerData.emailAddress,
      customerData.phoneNumber,
      customerEmailPhoneValidation,
    );
    const addressErrors =
      showCustomerAddressForm &&
      this.validateAndUpdateCustomerAddressInfoError(customerData, customerType);

    return {
      [OrderFormCustomerInfoField.firstName]:
        (isCustomerRequired || customerInfoFormRadioState) &&
        this.validateCustomerInfoField(
          OrderFormCustomerInfoField.firstName,
          customerData.firstName,
        ),
      [OrderFormCustomerInfoField.lastName]:
        (isCustomerRequired || customerInfoFormRadioState) &&
        this.validateCustomerInfoField(OrderFormCustomerInfoField.lastName, customerData.lastName),
      [OrderFormCustomerInfoField.emailAddress]: isCustomerRequired && phoneEmailError.email,
      [OrderFormCustomerInfoField.phoneNumber]: isCustomerRequired && phoneEmailError.phone,
      ...addressErrors,
    };
  };

  isFormValidForSubmission(): boolean {
    const sellerInfoErrors = this.getAllCustomerFormErrors(
      this.getPendingData().sellerInfo,
      CustomerType.Seller,
      this.isSellerRequired(),
      this.state.showSellerInfoRadio,
    );
    const buyerInfoErrors = this.getAllCustomerFormErrors(
      this.getPendingData().buyerInfo,
      CustomerType.Buyer,
      this.isBuyerRequired(),
      this.state.showBuyerInfoRadio,
    );
    const coBuyerInfoErrors = this.getAllCustomerFormErrors(
      this.getPendingData().coBuyerInfo,
      CustomerType.CoBuyer,
      this.isCoBuyerRequired(),
    );
    const coSellerInfoErrors = this.getAllCustomerFormErrors(
      this.getPendingData().coSellerInfo,
      CustomerType.CoSeller,
      this.isCoSellerRequired(),
    );

    this.setState({ buyerInfoErrors, coBuyerInfoErrors, sellerInfoErrors, coSellerInfoErrors });
    return (
      Object.values(buyerInfoErrors).every((err) => !err) &&
      Object.values(coBuyerInfoErrors).every((err) => !err) &&
      Object.values(coSellerInfoErrors).every((err) => !err) &&
      Object.values(sellerInfoErrors).every((err) => !err)
    );
  }

  onUpdateErrorClosingOfficeAgentField = (
    shouldExecValidateFc: boolean,
    fieldName: OrderFormClosingInfoField,
    value: string = '',
  ) => {
    const errors = this.state.closingInfoErrors;
    errors[fieldName] = shouldExecValidateFc
      ? this.validateClosingOfficeAgentField(fieldName, value)
      : value;
    this.setState({ closingInfoErrors: errors });
  };

  validateClosingOfficeAgentField = (fieldName: OrderFormClosingInfoField, value: string) => {
    switch (fieldName) {
      case OrderFormClosingInfoField.closingOffice:
        return validateClosingOffice(value); // TBD: check validation logic in existing flow
      case OrderFormClosingInfoField.closingOfficeAgent:
        return validateClosingAgent(value);
    }
    return '';
  };

  renderForm(): JSX.Element {
    return (
      <>
        <div className="flex flex-col">
          {/* BUYER */}
          <div>
            <OrderFormCustomerInfo
              savedData={this.props.savedData}
              label="Buyer Information"
              ids={{
                [OrderFormCustomerInfoField.firstName]: 'wb_buyerFirstName',
                [OrderFormCustomerInfoField.lastName]: 'wb_buyerLastName',
                [OrderFormCustomerInfoField.emailAddress]: 'wb_buyerEmail',
                [OrderFormCustomerInfoField.phoneNumber]: 'wb_buyerPhone',
                [OrderFormCustomerInfoField.zip]: 'wb_buyerZip',
                [OrderFormCustomerInfoField.streetAddress]: 'wb_buyerAddress',
                [OrderFormCustomerInfoField.city]: 'wb_buyerCity',
                [OrderFormCustomerInfoField.state]: 'wb_buyerState',
                [OrderFormCustomerInfoField.unitNumber]: 'wb_buyerUnit',
              }}
              formData={this.state.buyerInfo}
              clearFieldError={(fieldName) =>
                this.setState({
                  buyerInfoErrors: this.getCustomerFormErrors(
                    false,
                    fieldName,
                    '',
                    CustomerType.Buyer,
                  ),
                })
              }
              errors={this.state.buyerInfoErrors}
              onFieldError={(fieldName, err) =>
                this.setState({
                  buyerInfoErrors: this.getCustomerFormErrors(
                    false,
                    fieldName,
                    err,
                    CustomerType.Buyer,
                  ),
                })
              }
              onUpdateFormData={(data) => this.setState({ buyerInfo: data })}
              onValidateField={(fieldName, value) =>
                this.setState({
                  buyerInfoErrors: this.getCustomerFormErrors(
                    true,
                    fieldName,
                    value,
                    CustomerType.Buyer,
                  ),
                })
              }
              onFormError={console.log}
              isCustomerContactRequired={this.customerEmailPhoneRequirements(CustomerType.Buyer)}
              customerAddressCheckboxLabel="Use Alternative Mailing Address"
              customerAddressLabel="Alternative Mailing Address"
              customerQuestionId="wb_buyerInfo"
              customerQuestionName="buyerInfo"
              toggleIncludeCustomerAddress={() =>
                this.toggleIncludeCustomerAddress(CustomerType.Buyer)
              }
              showCustomerAddressForm={this.state.showBuyerAddressForm}
              toogleCustomerInfoFormRadio={(value) => this.setState({ showBuyerInfoRadio: value })}
              customerQuestionLabel={ORDER_FORM_FIELDS.BUYER__QUESTION}
              isCustomerRequired={this.isBuyerRequired()}
              onClickValidateAddress={() =>
                this.validateCustomerAddress(this.state.buyerInfo, CustomerType.Buyer)
              }
              topContentRenderer={
                this.props.savedData.agentInfo.represents === Represents.Seller &&
                this.cooperatingOfficeAgentRenderer()
              }
              customerBottomContentRenderer={
                <div id="includeCoBuyerInfoForm">
                  <Checkbox
                    id="wb-coBuyerInfo"
                    label="Include Co-buyer"
                    className="mt-3"
                    onChange={this.toggleIncludeCoBuyerInfo}
                    checked={this.state.showCoBuyerInfoForm}
                  />
                  {this.state.showCoBuyerInfoForm && (
                    <div className="mt-2 mb-4">
                      <OrderFormCustomerInfo
                        savedData={this.props.savedData}
                        label="Co-buyer Information"
                        ids={{
                          [OrderFormCustomerInfoField.firstName]: 'wb_coBuyerFirstName',
                          [OrderFormCustomerInfoField.lastName]: 'wb_coBuyerLastName',
                          [OrderFormCustomerInfoField.emailAddress]: 'wb_coBuyerEmail',
                          [OrderFormCustomerInfoField.phoneNumber]: 'wb_coBuyerPhone',
                          [OrderFormCustomerInfoField.zip]: 'wb_coBuyerZip',
                          [OrderFormCustomerInfoField.streetAddress]: 'wb_coBuyerAddress',
                          [OrderFormCustomerInfoField.city]: 'wb_coBuyerCity',
                          [OrderFormCustomerInfoField.state]: 'wb_coBuyerState',
                          [OrderFormCustomerInfoField.unitNumber]: 'wb_coBuyerUnit',
                        }}
                        formData={this.state.coBuyerInfo}
                        clearFieldError={(fieldName) =>
                          this.setState({
                            coBuyerInfoErrors: this.getCustomerFormErrors(
                              false,
                              fieldName,
                              '',
                              CustomerType.CoBuyer,
                            ),
                          })
                        }
                        errors={this.state.coBuyerInfoErrors}
                        onFieldError={(fieldName, err) =>
                          this.setState({
                            coBuyerInfoErrors: this.getCustomerFormErrors(
                              false,
                              fieldName,
                              err,
                              CustomerType.CoBuyer,
                            ),
                          })
                        }
                        onUpdateFormData={(data) => this.setState({ coBuyerInfo: data })}
                        onValidateField={(fieldName, value) =>
                          this.setState({
                            coBuyerInfoErrors: this.getCustomerFormErrors(
                              true,
                              fieldName,
                              value,
                              CustomerType.CoBuyer,
                            ),
                          })
                        }
                        toggleIncludeCustomerAddress={() =>
                          this.toggleIncludeCustomerAddress(CustomerType.CoBuyer)
                        }
                        showCustomerAddressForm={this.state.showCobuyerAddressForm}
                        onFormError={console.log}
                        isCustomerContactRequired={this.customerEmailPhoneRequirements(
                          CustomerType.CoBuyer,
                        )}
                        onClickValidateAddress={() =>
                          this.validateCustomerAddress(this.state.coBuyerInfo, CustomerType.CoBuyer)
                        }
                        isCustomerRequired={true}
                        customerAddressCheckboxLabel="Use Alternative Mailing Address"
                      />
                    </div>
                  )}
                </div>
              }
            />

            <Separator orientation="horizontal" className="mt-6 mb-6" />
          </div>

          {/* SELLER */}
          {/* re-order and have seller to the top if representing seller or both */}
          <div
            className={
              [Represents.Seller, Represents.Both].includes(
                this.props.savedData.agentInfo.represents,
              )
                ? 'order-first'
                : ''
            }
          >
            <OrderFormCustomerInfo
              label="Seller Information"
              savedData={this.props.savedData}
              ids={{
                [OrderFormCustomerInfoField.firstName]: 'wb_sellerFirstName',
                [OrderFormCustomerInfoField.lastName]: 'wb_sellerLastName',
                [OrderFormCustomerInfoField.emailAddress]: 'wb_sellerEmail',
                [OrderFormCustomerInfoField.phoneNumber]: 'wb_sellerPhone',
                [OrderFormCustomerInfoField.zip]: 'wb_sellerZip',
                [OrderFormCustomerInfoField.streetAddress]: 'wb_sellerAddress',
                [OrderFormCustomerInfoField.city]: 'wb_sellerCity',
                [OrderFormCustomerInfoField.state]: 'wb_sellerState',
                [OrderFormCustomerInfoField.unitNumber]: 'wb_sellerUnit',
              }}
              formData={this.state.sellerInfo}
              clearFieldError={(fieldName) =>
                this.setState({
                  sellerInfoErrors: this.getCustomerFormErrors(
                    false,
                    fieldName,
                    '',
                    CustomerType.Seller,
                  ),
                })
              }
              errors={this.state.sellerInfoErrors}
              onFieldError={(fieldName, err) =>
                this.setState({
                  sellerInfoErrors: this.getCustomerFormErrors(
                    false,
                    fieldName,
                    err,
                    CustomerType.Seller,
                  ),
                })
              }
              onUpdateFormData={(data) => this.setState({ sellerInfo: data })}
              onValidateField={(fieldName, value) =>
                this.setState({
                  sellerInfoErrors: this.getCustomerFormErrors(
                    true,
                    fieldName,
                    value,
                    CustomerType.Seller,
                  ),
                })
              }
              onFormError={console.log}
              showCustomerAddressForm={this.state.showSellerAddressForm}
              toggleIncludeCustomerAddress={() =>
                this.toggleIncludeCustomerAddress(CustomerType.Seller)
              }
              toogleCustomerInfoFormRadio={(value) => this.setState({ showSellerInfoRadio: value })}
              isCustomerContactRequired={this.customerEmailPhoneRequirements(CustomerType.Seller)}
              customerAddressCheckboxLabel="Use Alternative Mailing Address"
              customerAddressLabel="Alternative Mailing Address"
              customerQuestionId="wb_sellerInfo"
              customerQuestionName="sellerInfo"
              customerQuestionLabel={ORDER_FORM_FIELDS.SELLER__QUESTION}
              isCustomerRequired={this.isSellerRequired()}
              onClickValidateAddress={() =>
                this.validateCustomerAddress(this.state.sellerInfo, CustomerType.Seller)
              }
              topContentRenderer={
                this.props.savedData.agentInfo.represents === Represents.Buyer &&
                this.cooperatingOfficeAgentRenderer()
              }
              customerBottomContentRenderer={
                <div id="includeCoSellerInfoForm">
                  <Checkbox
                    id="wb-coSellerInfo"
                    label="Include Co-seller"
                    className="mt-3"
                    onChange={this.toggleIncludeCoSellerInfo}
                    checked={this.state.showCoSellerInfoForm}
                  />
                  {this.state.showCoSellerInfoForm && (
                    <div className="mt-2 mb-4">
                      <OrderFormCustomerInfo
                        savedData={this.props.savedData}
                        label="Co-seller Information"
                        ids={{
                          [OrderFormCustomerInfoField.firstName]: 'wb_coSellerFirstName',
                          [OrderFormCustomerInfoField.lastName]: 'wb_coSellerLastName',
                          [OrderFormCustomerInfoField.emailAddress]: 'wb_coSellerEmail',
                          [OrderFormCustomerInfoField.phoneNumber]: 'wb_coSellerPhone',
                          [OrderFormCustomerInfoField.zip]: 'wb_coSellerZip',
                          [OrderFormCustomerInfoField.streetAddress]: 'wb_coSellerAddress',
                          [OrderFormCustomerInfoField.city]: 'wb_coSellerCity',
                          [OrderFormCustomerInfoField.state]: 'wb_coSellerState',
                          [OrderFormCustomerInfoField.unitNumber]: 'wb_coSellerUnit',
                        }}
                        formData={this.state.coSellerInfo}
                        clearFieldError={(fieldName) =>
                          this.setState({
                            coSellerInfoErrors: this.getCustomerFormErrors(
                              false,
                              fieldName,
                              '',
                              CustomerType.CoSeller,
                            ),
                          })
                        }
                        errors={this.state.coSellerInfoErrors}
                        onFieldError={(fieldName, err) =>
                          this.setState({
                            coSellerInfoErrors: this.getCustomerFormErrors(
                              false,
                              fieldName,
                              err,
                              CustomerType.CoSeller,
                            ),
                          })
                        }
                        showCustomerAddressForm={this.state.showCosellerAddressForm}
                        toggleIncludeCustomerAddress={() =>
                          this.toggleIncludeCustomerAddress(CustomerType.CoSeller)
                        }
                        onUpdateFormData={(data) => this.setState({ coSellerInfo: data })}
                        onValidateField={(fieldName, value) =>
                          this.setState({
                            coSellerInfoErrors: this.getCustomerFormErrors(
                              true,
                              fieldName,
                              value,
                              CustomerType.CoSeller,
                            ),
                          })
                        }
                        onFormError={console.log}
                        isCustomerContactRequired={this.customerEmailPhoneRequirements(
                          CustomerType.CoSeller,
                        )}
                        onClickValidateAddress={() =>
                          this.validateCustomerAddress(
                            this.state.coSellerInfo,
                            CustomerType.CoSeller,
                          )
                        }
                        isCustomerRequired={true}
                        customerAddressCheckboxLabel="Use Alternative Mailing Address"
                      />
                    </div>
                  )}
                </div>
              }
            />

            <Separator orientation="horizontal" className="mt-6 mb-6" />
          </div>

          <div>
            <OrderFormClosingInfo
              ids={{
                [OrderFormClosingInfoField.closingOffice]: 'wb_closingOfficeTypeahead',
                [OrderFormClosingInfoField.closingOfficeAgent]: 'wb_closingOfficeAgentTypeahead',
              }}
              labels={{
                [OrderFormClosingInfoField.closingOffice]: 'Closing / title company',
                [OrderFormClosingInfoField.closingOfficeAgent]: 'Closing / title agent or officer',
              }}
              officeSuggestions={getProfileOfficesByType(
                this.props.userProfile,
                OfficeType.ClosingCompany,
              )}
              formData={this.state.closingInfo}
              onValidateField={(fieldName, value) =>
                this.onUpdateErrorClosingOfficeAgentField(true, fieldName, value)
              }
              onUpdateFormData={(data) => this.setState({ closingInfo: data })}
              onFieldError={console.log}
              clearFieldError={console.log}
              onFormError={console.log}
              errors={this.state.closingInfoErrors}
            />
          </div>
        </div>

        <AddressVerification
          id="validate-address--order"
          items={this.state.addressVerification.addressVerificationItems}
          isActive={this.state.addressVerification.enableAddressVerification}
          onVerifiedAddress={this.updateAddressFromVerification}
          onComplete={() => this.onAddressVerificationComplete()}
          onReset={() => this.onAddressVerificationReset()}
          disableUnverifiedAddressCreation={false}
        />
      </>
    );
  }
}
