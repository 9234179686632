import React, { useEffect, useState } from 'react';
import { NOT_APPLICABLE_OPTION, onFetchSuggestions } from '@services/autosuggest/autosuggest';
import { Button, Link } from '@ftdr/blueprint-components-react';
import REText from '@components/wrappedBDS/REText';

const AgentVerification = (props) => {
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [agentListDivs, setAgentListDivs] = useState(Element[0]);

  useEffect(() => {
    setFilteredAgents(
      onFetchSuggestions(props.selectedAgent, props.agentList, props.setTypeAheadAddress),
    );
  }, [props.isActive]);

  useEffect(() => {
    setAgentListDivs(
      filteredAgents.map((agent, idx) => (
        <ul key={agent.realEstateAgentID}>
          <div className="p-4 border-b border-gray-300">
            <div className="flex justify-between">
              <div>
                <div className="pb-2">
                  <REText variant="heading-06">{`${agent.firstName} ${agent.lastName}`}</REText>
                </div>
                <div>
                  {agent !== NOT_APPLICABLE_OPTION && (
                    <div>
                      <REText variant="caption">{props.office?.name || ' '}</REText>
                    </div>
                  )}
                  <REText variant="caption">{agent.email || ' '}</REText>
                </div>
              </div>
              <div>
                <Button
                  id={`wb_agent_verification_selection_${idx}`}
                  label="Select"
                  size="medium"
                  onClick={() => {
                    props.onSelectAgent(agent);
                    props.onClose();
                  }}
                />
              </div>
            </div>
          </div>
        </ul>
      )),
    );
  }, [filteredAgents]);

  return (
    <>
      <div className="max-w-1/2 mb-2">
        <REText>
          For the {props.agentRole} agent, you entered: {props.selectedAgent}
        </REText>
      </div>
      {filteredAgents && !filteredAgents.some((element) => element.empty === true) ? (
        <div>
          <div className="max-w-full mb-2 border-b border-gray-300 pb-4">
            <REText>Did you mean:</REText>
          </div>
          <div>
            <ul>{agentListDivs}</ul>
          </div>
          <div className="py-4 flex flex-row items-center">
            <REText>If none of these are correct, </REText> &nbsp;
            <Link
              id="wb_agent_verification_add_agent"
              style={{ color: '#182AF0', paddingTop: '2px' }}
              onClick={() => {
                props.setIsAddAgentModalActive(true);
                props.onClose();
              }}
            >
              add a new agent.
            </Link>
          </div>
        </div>
      ) : (
        <div className="py-4 flex flex-row items-center">
          <REText>No matches were found.</REText> &nbsp;
          <Link
            id="wb_agent_verification_add_agent"
            style={{ color: '#182AF0', paddingTop: '2px' }}
            onClick={() => {
              props.setIsAddAgentModalActive(true);
              props.onClose();
            }}
          >
            {`${props.agentRole == 'cooperating' ? 'Invite' : 'Add'} a new agent.`}
          </Link>
        </div>
      )}
    </>
  );
};

export default AgentVerification;
