import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import * as Sentry from '@sentry/react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import Orders from '@pages/account/Orders';
import Dashboard from '@pages/account/Dashboard';
import RegistrationAccount from '@pages/registration/RegistrationAccount';
import EditMyAccount from '@pages/account/EditMyAccount';
import NewOrder, { NewOrderRouteData } from '@pages/order/NewOrder';
import Offices from '@pages/office/Offices';
import ExistingUser from '@pages/registration/ExistingUser';
import WarrantyLink from '@pages/warrantylink/index';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import OrderSubmitted from '@pages/order/OrderSubmitted';
import { getThemeClass } from '@ThemeContext';
import Callback from '@app/auth/callback';
import Path from '@constants/paths';
import requireAuth from './auth/requireAuth';
import AppCrashBoundary from './core/AppCrashBoundary';
import { GlobalAlertModal } from './core/GlobalAlertModal';
import { loadGlobalScripts } from './core/globalScripts';
import 'ie11-custom-properties';
import { useAuth } from '@ftdr/use-auth/build';
import SSORedirect from '@app/auth/ssoRedirect';
import VerifyEmail from '@pages/verify-email/verifyEmail';
import { GlobalOverlaySpinner } from '@components/spinner/GlobalOverlaySpinner';
import ProfileError from '@pages/errors/profileError';
import WarrantylinkAgreements from '@pages/warrantylink/WarrantylinkAgreements';
import WarrantylinkPayments from '@pages/warrantylink/WarrantylinkPayments';
import NonSupportIECheck from './core/NonSupportIECheck';
import { Permissions } from '@constants/dictionaries';
import Admin from '@pages/admin/Admin';
import FAQPage from '@pages/FAQ/FAQPage';
import ContactUs from '@pages/contact/ContactUs';
import ContactUsSuccess from '@pages/contact/ContactUsSuccess';
import PlansAndPrices from '@pages/misc/PlansAndPrices';
import { SalesMaterial } from '@pages/misc/SalesMaterial';
import { Features, IsFeatureEnabled, IsTheme, Theme } from '@app/core/featureToggle';
import { getBrand } from '@helpers/brand.utils';
import { Brand } from '@constants/brands';
import { App as BlueprintApp, SupportedLanguages } from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';
import { enUsLocale } from '@constants/bds.constants';
import GlobalDrawerOrder from '@app/core/GlobalDrawerOrder';
import DemoPageLayout from '@components/layout/DemoPageLayout';
import { DemoPages } from '@constants/demo.paths';
import { loadWindowScript, useOptimizely } from '@context/OptimizelyContext';
import NewOrderStep1 from '@pages/order/NewOrderStep1';
import NewOrderStep2 from '@pages/order/NewOrderStep2';
import NewOrderStep3 from '@pages/order/NewOrderStep3';
import NewOrderStep4 from '@pages/order/NewOrderStep4';
import NewOrderComplete from '@pages/order/NewOrderComplete';
import Quotes from '@pages/account/Quotes';
import deepLinkService from '@services/deepLink/deepLink.service';
import utmParametersService from '@services/utmParameters/utmParameters.service';
import queryParamParserService from '@services/queryParamParserService/queryParam.service';
import { DefaultPropsContextProvider, DEFAULT_PROPS_CONTEXT_AHS_2024_THEME_VALUES } from "@ftdr/blueprint-components-react";

const brand = getBrand();

const appSettings = {
  language: 'en_US' as SupportedLanguages,
  textTemplatesByLanguage: {
    ...enUsLocale,
  },
};

const errorFallback = ({ error }) => {
  return <AppCrashBoundary error={error} />;
};

const App = () => {
  const optimizely = useOptimizely();

  const auth = useAuth();

  useEffect(() => {
    loadGlobalScripts();
    loadWindowScript(optimizely);
    deepLinkService.init();
    utmParametersService.init();
    queryParamParserService.init();
  }, []);

  useEffect(() => {
    if (IsTheme(Theme.AhsWebApp)) {
      document.body.className = "ahs-web-app";
    }
    if (IsTheme(Theme.Ahs2024)) {
      // @ts-expect-error ide doesn't see the file
      import(`../public/ahs-2024-gen.css`)
        .then(() => {
          document.body.className = "ahs-2024";
        })
        .catch(error => console.error("error loading AHS 2024 theme", error));
    }
  }, [])

  function getRoutes() {
    const demoRoutes = DemoPages.reduce((acc, demoPage) => {
      return {
        ...acc,
        [demoPage.path]: {
          title: demoPage.path,
          view: requireAuth(
            demoPage.disableDefaultDemoLayout ? (
              <demoPage.component />
            ) : (
              <DemoPageLayout>
                <demoPage.component />
              </DemoPageLayout>
            ),
            demoPage.path,
            auth,
          ),
        },
      };
    }, {});

    let newOrderRedesignRoutes = {};
    if (IsFeatureEnabled(Features.NewOrderRedesign)) {
      newOrderRedesignRoutes = {
        [Path.NewOrderStep1]: {
          title: 'Place New Order | Step 1',
          view: requireAuth(<NewOrderStep1 />, Path.NewOrderStep1, auth),
        },
        [Path.NewOrderStep2]: {
          title: 'Place New Order | Step 2',
          view: requireAuth(<NewOrderStep2 />, Path.NewOrderStep2, auth),
        },
        [Path.NewOrderStep3]: {
          title: 'Place New Order | Step 3',
          view: requireAuth(<NewOrderStep3 />, Path.NewOrderStep3, auth),
        },
        [Path.NewOrderStep4]: {
          title: 'Place New Order | Step 4',
          view: requireAuth(<NewOrderStep4 />, Path.NewOrderStep4, auth),
        },
        [Path.NewOrderComplete]: {
          title: 'Place New Order | Submitted',
          view: requireAuth(<NewOrderComplete />, Path.NewOrderComplete, auth),
        },
      };
    }

    const routes = {
      ...demoRoutes,
      [Path.HomePage]: {
        title: 'Orders',
        view: requireAuth(<Orders />, Path.Dashboard, auth),
      },
      [Path.Dashboard]: {
        title: 'Orders',
        view: requireAuth(<Orders />, Path.Dashboard, auth),
      },
      [Path.RegistrationAccount]: {
        title: 'Registration',
        view: requireAuth(
          <RegistrationAccount registrationType="self" />,
          Path.RegistrationAccount,
          auth,
          { shouldRequireProfile: false, shouldCheckInactiveOffices: false },
        ),
      },
      [Path.RegistrationExistingUser]: {
        title: 'Existing User',
        view: requireAuth(
          <ExistingUser registrationType="self" />,
          Path.RegistrationExistingUser,
          auth,
          { shouldRequireProfile: false },
        ),
      },
      [Path.Account]: {
        title: 'Account Details',
        view: requireAuth(<EditMyAccount />, Path.Account, auth),
      },
      ...newOrderRedesignRoutes,
      [Path.NewOrder]: {
        title: 'Place New Order',
        view: requireAuth(<NewOrder />, Path.NewOrder, auth),
      },
      [Path.ConvertNewOrder]: {
        title: 'Place New Order',
        view: requireAuth(<NewOrder />, Path.NewOrder, auth),
      },
      [Path.MyOrdersDetails]: {
        title: 'My Orders',
        view: requireAuth(<Orders />, Path.MyOrders, auth),
      },
      [Path.MyOrders]: {
        title: 'My Orders',
        view: requireAuth(<Orders />, Path.MyOrders, auth),
      },
      [Path.Warrantylink]: {
        title: 'Warranty Link',
        view: requireAuth(<WarrantyLink />, Path.Warrantylink, auth),
      },
      [Path.WarrantylinkAgreements]: {
        title: 'Warranty Link | Program Agreement',
        view: requireAuth(<WarrantylinkAgreements />, Path.WarrantylinkAgreements, auth),
      },
      [Path.WarrantylinkPayments]: {
        title: 'Warranty Link | Payments',
        view: requireAuth(<WarrantylinkPayments />, Path.WarrantylinkPayments, auth),
      },
      [Path.NewOrderSubmitted]: {
        title: 'Order Submitted',
        view: requireAuth(<OrderSubmitted />, Path.NewOrderSubmitted, auth),
      },
      [Path.Callback]: {
        title: 'Callback',
        view: <Callback authService={auth} />,
      },
      [Path.SSORedirect]: {
        title: 'SSO',
        view: <SSORedirect authService={auth} />,
      },
      [Path.VerifyEmail]: {
        title: 'Verify Email',
        view: <VerifyEmail authService={auth} />,
      },
      [Path.ProfileError]: {
        title: 'Profile Error',
        view: <ProfileError />,
      },
      [Path.MyOffices]: {
        title: 'Offices',
        view: requireAuth(<Offices />, Path.MyOffices, auth),
      },
      [Path.SiteAdmin]: {
        title: 'User Maintenance',
        view: requireAuth(<Admin />, Path.SiteAdmin, auth, {
          requiredPermissions: [Permissions.UserMaintenance],
        }),
      },
      [Path.FAQ]: {
        title: 'FAQs',
        view: requireAuth(<FAQPage />, Path.FAQ, auth, { shouldRequireAuth: false }),
      },
      [Path.ContactUs]: {
        title: 'Contact Us',
        view: requireAuth(<ContactUs />, Path.ContactUs, auth, { shouldRequireAuth: false }),
      },
      [Path.ContactUsSuccess]: {
        title: 'Contact Us Success',
        view: requireAuth(<ContactUsSuccess />, Path.ContactUsSuccess, auth, {
          shouldRequireAuth: false,
        }),
      },
      [Path.HomePage]: {
        title: 'Dashboard',
        view: requireAuth(<Dashboard />, Path.Dashboard, auth),
      },
      [Path.Dashboard]: {
        title: 'Dashboard',
        view: requireAuth(<Dashboard />, Path.Dashboard, auth),
      },
      ['*']: {
        title: 'Dashboard',
        view: requireAuth(<Dashboard />, Path.Dashboard, auth),
      },
    };

    addMyQuoteRoute(routes);
    addFeatureToggleRoutes(routes);
    return routes;
  }

  function addMyQuoteRoute(routes): void {
    if (brand === Brand.AHS && IsFeatureEnabled(Features.MyQuotes)) {
      routes[Path.MyQuote] = {
        title: 'My Quotes',
        view: requireAuth(<Quotes />, Path.MyQuote, auth),
      };
    }
  }

  function addFeatureToggleRoutes(routes): void {
    addSalesMaterialFeatureRoutes(routes);

    if (brand === Brand.AHS) {
      addPlansAndPricesFeatureRoutes(routes);
    }
  }

  function addSalesMaterialFeatureRoutes(routes): void {
    routes[Path.SalesMaterial] = {
      title: 'Sales Material',
      view: requireAuth(<SalesMaterial />, Path.SalesMaterial, auth),
    };
  }

  function addPlansAndPricesFeatureRoutes(routes): void {
    routes[Path.PlansAndPrices] = {
      title: 'Plans & Prices',
      view: requireAuth(<PlansAndPrices />, Path.PlansAndPrices, auth),
    };
  }

  const routeMap = getRoutes();

  return (
    // ...useAuth() doesn't expose it's isLoading value,
    // but instead starts oidcUser as undefined and upon finishing loading,
    // sets oidcUser to null.  So we have to explicitly check for undefined
    // on the oidcUser to determine if it's finished loading or not
    auth.oidcUser !== undefined && (
      // TODO Can remove other calls to theme; we should only be setting Theme class here and components can key off it
      <BlueprintApp
        appSettings={appSettings}
        className={classNames([
          // deprecated simplefocus class
          getThemeClass(),

          // these two classes are put into the body directly. see the README.md for more details on it under the BDS Migrations section
          // 'bds-ahs-web-app', 'fix-minified-bds',

          // these classes has been commented out and moved out to the body itself, as there were
          // too many problems with rendered components outside of the root element with all the SF and minified BDS stuff.
          // the classes below have been commented out, but the description is left here so we have it as reference,
          // leaving it in index.html would be exposing it to the public

          // the BDS minified parent class. required to use the minified file
          // 'bds-ahs-web-app',

          // all of our classes (whether it be tailwind or custom css classes) were being overruled
          // by the minified parent class due to CSS precedence hierarchy rules.
          // for example, '.my-4' would not be applied properly because '.bds-ahs-web-app .component' with a margin would be higher precedence.
          // by adding this class and using it in the postcss.config.js to prefix the css, we can get around the problem temporarily.
          // once we are entirely not reliant on maintaining both SimpleFocus and BDS together,
          // we can remove this and use the BDS tailwind directly instead of the static minified version.
          // 'fix-minified-bds',
        ])}
      >
        <DefaultPropsContextProvider value={IsTheme(Theme.Ahs2024) ? DEFAULT_PROPS_CONTEXT_AHS_2024_THEME_VALUES : null}>
          <SentryErrorBoundary fallback={errorFallback}>
            <HelmetProvider>
              <BrowserRouter>
                <Routes>
                  { Object.keys(routeMap).map( key => (
                    <Route key={key} path={key} element={
                      <>
                        <Helmet>
                          <title>{routeMap[key].title}</title>
                        </Helmet>
                        {routeMap[key].view}
                        <GlobalAlertModal />
                        <GlobalOverlaySpinner />
                        <GlobalDrawerOrder />
                      </>
                    } />
                  ))}
                </Routes>
              </BrowserRouter>
            </HelmetProvider>
          </SentryErrorBoundary>
          <NonSupportIECheck />
        </DefaultPropsContextProvider>
      </BlueprintApp>
    )
  );
};

export default Sentry.withProfiler(App);
