import React from 'react';
import Template from '@templates/FAQ/FAQTemplate';
import { FAQ, FAQCategory } from '@app/models/zesty.model';
import HeaderNoAuth from '@components/general/HeaderNoAuth';
import { ContentBox } from '@components/layout/ContentBox';

interface props {
  FAQs: FAQ[];
  FAQCategories: FAQCategory[];
}

export default class FAQNoAuth extends React.Component<props, never> {
  render() {
    return (
      <div className="layout">
        <HeaderNoAuth />
        <ContentBox title="FAQs">
          <Template FAQs={this.props.FAQs} FAQCategories={this.props.FAQCategories} RoleIDType="" />
        </ContentBox>
      </div>
    );
  }
}
