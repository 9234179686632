import { getConfig } from '@services/app.config.loader';
import { loadSentryIO } from '@app/core/sentry.service';
import { isProdEnv } from '@helpers/utils';

const HOTJAR_VERSION_ID = 6;

const splitScripts = (scriptsStr: string): string[] => {
  return scriptsStr.replace(' ', '').split(',');
};

/**
 * loads all scripts into the site based on config
 */
export const loadGlobalScripts = (): void => {
  loadSentryIO();
  loadHeadScripts();
  loadBodyScripts();
};

const loadHeadScripts = (): void => {
  try {
    const scripts = getConfig('headScripts');
    if (scripts) {
      for (const key of splitScripts(scripts)) {
        try {
          const src = getConfig(key);
          if (src) {
            appendScript(src, true);
          }
        } catch (e) {
          console.error(`failed to load ${key}`, e);
        }
      }
    }
  } catch (e) {
    console.error('failed to load scripts', e);
  }
};

const loadBodyScripts = (): void => {
  try {
    const scripts = getConfig('bodyScripts');
    if (scripts) {
      for (const key of splitScripts(scripts)) {
        try {
          const src = getConfig(key);
          if (src) {
            appendScript(src, false);
          }
        } catch (e) {
          console.error(`failed to load ${key}`, e);
        }
      }
    }
  } catch (e) {
    console.error('failed to load scripts', e);
  }
};

/**
 * Appends a single script src to either the end of head or body
 * @param toHead true if script to be appending to end of head. Otherwise, end of body
 */
export const appendScript = (scriptSrc: string, toHead: boolean): void => {
  const script = document.createElement('script');
  script.src = scriptSrc;
  script.async = true;
  if (toHead) {
    document.head.appendChild(script);
  } else {
    document.body.appendChild(script);
  }
};

/**
 * Removes script from dom with matching src name
 */
export const removeScript = (scriptSrc: string): void => {
  const allsuspects = document.getElementsByTagName('script');
  for (let i = allsuspects.length; i >= 0; i--) {
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute('src') &&
      allsuspects[i].getAttribute('src').indexOf(`${scriptSrc}`) !== -1
    ) {
      // Remove script
      allsuspects[i].parentNode.removeChild(allsuspects[i]);
    }
  }
};
