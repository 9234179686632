import React from 'react';
import { NewOrderStep } from '@app/models/order/neworder.model';
import { Button } from '@ftdr/blueprint-components-react';
import REText from '@components/wrappedBDS/REText';

interface Props {
  currentStep: NewOrderStep;
  onClear: () => void;
}

const StepName = {
  [NewOrderStep.AgentPropertyInfo]: 'Agent & Property Information',
  [NewOrderStep.PlanCoverage]: 'Plans & Coverage',
  [NewOrderStep.BuyerSellerInfo]: 'Buyer & Seller Information',
  [NewOrderStep.ReviewSubmit]: 'Send Documents',
};

const NewOrderCardSection: React.FC<Props> = (props) => {
  return (
    <div className="NewOrderCardSection">
      <div className="NewOrderCardSection_Heading">
        <REText id="NewOrderCardSection.HeadingText" variant="heading-06">
          {StepName[props.currentStep]}
        </REText>
        <Button
          id="NewOrderCardSection.ClearCTA"
          label="Clear info"
          onClick={props.onClear}
          variant="ghost"
          size="small"
        />
      </div>
      <div className="NewOrderCardSection_Content">{props.children}</div>
    </div>
  );
};

export default NewOrderCardSection;
