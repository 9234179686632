import React, { useEffect, useMemo, useState } from 'react';
import OrdersActionSwitcher, { OrdersActionSearchType } from './OrdersActionSwitcher';
import { ContentBox } from '@components/layout/ContentBox';
import { Button, Dialog, Pagination, Table, Tag } from '@ftdr/blueprint-components-react';
import { classNames, isMobileView } from '@utils';
import TagGroup from '@components/tag/TagGroup';
import SearchBar from '@components/input/SearchBar';
import { IconTypes } from '@components/card/CardFilterBar';
import { FILTER_BY_NUMBER_ADDRESS } from '@constants/formField-constants';
import { Address, REOrderSearchRequest, REQuoteSearchRequest } from '@apis/models';
import ActionsDropdown from '@components/button/ActionsDropdown';
import CardOrderGrid from '@components/card/CardOrderGrid';
import AtypicallySlowOverlay from '@components/loader/AtypicallySlowOverlay';
import OrdersAbandonedFiltering from '@components/misc/OrdersAbandonedFiltering';
import ProfileModel from '@app/models/profile.model';
import RealEstateQuoteApi from '@apis/realestatequote.api';
import { DateFormat, formatDate } from '@helpers/utils';
import { Quote } from '@apis/models/quote.api.model';
import { StatusMenuType } from '@components/filter/OrderStatusFilter';
import { FilterChangeEvent, FilterTagItem } from '@components/misc/misc.models';
import { FilterOperation, FilterType } from '@constants/dashboardFilters';
import { officeTypeDictionary } from '@constants/dictionaries';
import { Canceler } from 'axios';
import { useNavigate } from 'react-router-dom';
import Path from '@constants/paths';
import { NewOrderFromSource } from '@pages/order/NewOrder';
import { isCCAgent, isREAgent } from '@helpers/profile.utils';
import { OrderWorkflow, UnsubmittedOrderCancellationWorkflow } from '@constants/app.constants';
import { AbandonedOrderStatuses } from '@apis/models/orders.api.model';
import ModalDeleteOrder from '@components/modal/ModalDeleteOrder';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { toAppAddress } from '@apis/address.api';
import AddressDisplay from '@components/content/AddressDisplay';
import { fireGAEvent } from '@app/core/tracking.service';
import { CONTINUE_ABANDONED_ORDER } from '@constants/ga-events.constants';
import {
  msgsWParams,
  NO_INCOMPLETE_ORDERS_FOUND,
  NO_INCOMPLETE_ORDERS_FOUND_WITH_CRITERIA,
} from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

export const NOTIFICATION_TIMEOUT = 3500;
export const SKELETON_LOAD_CNT_GRID = 6; /** # of grid cards to display while on load */
export const ORDERS_PAGE_SIZE = 15;
export interface OrdersAbandonedTemplateProps {
  updateTab?: (tabName: OrdersActionSearchType) => void;
  profile: ProfileModel;
}

let loadStatusCanceler: Canceler;

// Most of this has been copied from OrdersTemplate
const OrdersAbandonedTemplate = (props: OrdersAbandonedTemplateProps) => {
  const navigate = useNavigate();
  const { addSuccessToQueue, addErrorToQueue } = useGlobalAlert();
  const { showSpinner } = useGlobalOverlaySpinner();

  const isMobile: boolean = isMobileView();
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showDeleteOrderModal, setShowDeleteOrderModal] = useState<boolean>(false);
  const [pendingDeleteOrder, setPendingDeleteOrder] = useState<Quote>(null);
  const [deletedOrderIDs, setDeletedOrderIDs] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [activePage, setActivePage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [summaryView, setSummaryView] = useState<Quote[]>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);

  const [filterTagItems, setFilterTagItems] = useState<FilterTagItem[]>([]);
  const [quoteSearchFilter, setQuoteSearchFilter] = useState<REQuoteSearchRequest>(
    new REOrderSearchRequest(ORDERS_PAGE_SIZE),
  );

  /** unmount, clear up any cancel if any */
  useEffect(() => {
    return () => {
      loadStatusCanceler?.('unmounting');
    };
  }, []);

  const addFilter = (
    label: string,
    event: FilterChangeEvent = {
      label,
      operation: FilterOperation.ADD,
      payload: undefined,
      type: undefined,
    },
    clearFilters = false,
  ): void => {
    if (event.type && event.payload) {
      onFilterChange(event, clearFilters);
      setActivePage(1);
    }
  };

  const filtersForDisplay: FilterTagItem[] = useMemo(() => {
    const excludeFilterTypes = ['orderSort'];
    /** Prevents RE agents from clearing the filter showing only their contracts */
    if (props.profile.roleIDType === 'RealEstateAgent') {
      excludeFilterTypes.push('office', 'agent');
    }
    return filterTagItems.filter((tag) => !excludeFilterTypes.includes(tag.type));
  }, [props.profile, filterTagItems]);

  const onFilterChange = async (
    event: FilterChangeEvent,
    clearFilters = false,
  ): Promise<boolean> => {
    let result: { tags: FilterTagItem[]; validUpdate: boolean } = { tags: [], validUpdate: false };
    let query = quoteSearchFilter?.data?.query || '';

    try {
      if (clearFilters) {
        setFilterTagItems([]);
      }

      // Reset the page back to the first page in the request
      // Should not be moved down, because the page filter would override it
      quoteSearchFilter.meta.page = 0;

      // Handle individual event type
      switch (event.type) {
        case FilterType.DATE:
          result = updateDateFilter(event, filterTagItems);
          break;
        case FilterType.CLEAR_FILTER:
          result = { tags: [], validUpdate: true };
          break;
      }

      if (event.operation === FilterOperation.SEARCH_INPUT) {
        query = event.payload.query;
        result.validUpdate = true;
      }

      // On valid update, we want to proceed onwards to update the tags and make a new request
      if (result.validUpdate) {
        setFilterTagItems(result.tags);
        await loadAbandonedOrders(result.tags, query);
      }
    } catch (e) {
      console.error('failed to apply filter changes', e);
    }

    return result.validUpdate;
  };

  const updateDateFilter = (
    event: FilterChangeEvent,
    tags: FilterTagItem[],
  ): { tags: FilterTagItem[]; validUpdate: boolean } => {
    let validUpdate = false;

    const { date } = event.payload;
    const inFilter = tags.find(({ type }) => type === 'date');

    switch (event.operation) {
      case 'add': {
        // We always update the date range if add occurs; replacing the existing one; as there should only be 1 date range filter
        const startDate = formatDate(date.start, DateFormat.ISO8601);
        const endDate = formatDate(date.end, DateFormat.ISO8601);
        const validDates = Boolean(startDate && endDate);
        if (validDates) {
          tags = [...tags.filter((i) => i.type !== 'date'), event]; // Remove the existing tag first, then add
          validUpdate = true;
        }
        break;
      }
      case 'remove': {
        if (inFilter) {
          tags = tags.filter((i) => i.type !== 'date');
          validUpdate = true;
        } else {
          console.warn('attempted to remove date, but cannot find it', event, tags);
        }
        break;
      }
    }

    return { tags, validUpdate };
  };

  const onSearchFilterChange = (value: string) => {
    const quoteSearchFilter2 = quoteSearchFilter;
    quoteSearchFilter2.meta.page = 0;
    quoteSearchFilter2.data.query = value;
    setSearchText(value);
    setQuoteSearchFilter(quoteSearchFilter2);
    loadAbandonedOrders(filterTagItems, value);
    setActivePage(1);
  };

  const mapNewFilterRequest = (
    quoteSearchFilter: REOrderSearchRequest,
    tags: FilterTagItem[],
    query = '',
  ): REQuoteSearchRequest => {
    quoteSearchFilter.data.agents = tags
      .filter((i) => i.type === 'agent')
      .map((i) => ({
        id: i.payload.agent.realEstateAgentID,
        type: officeTypeDictionary[i.payload.agent.agentType],
      }));

    quoteSearchFilter.data.offices = tags
      .filter((i) => i.type === 'office')
      .map((i) => ({
        id: i.payload.office.id,
        type: i.payload.office.type,
      }));

    quoteSearchFilter.data.order.realEstateStatus = tags
      .filter((i) => i.type === 'status')
      .map((i) => i.payload.status);

    const quoteSortFilter = tags.find((tag) => tag.type === 'quoteSort');
    if (quoteSortFilter) {
      quoteSearchFilter.meta.sortBy = quoteSortFilter.payload.sortBy;
      quoteSearchFilter.meta.asc = quoteSortFilter.payload.asc;
    } else {
      quoteSearchFilter.meta.sortBy = 'EXPDATE';
      quoteSearchFilter.meta.asc = true;
    }

    const dateFilterTag = tags.find((i) => i.type === 'date');
    if (dateFilterTag) {
      const { date } = dateFilterTag.payload;
      const startDate = formatDate(date.start, DateFormat.ISO8601);
      const endDate = formatDate(date.end, DateFormat.ISO8601);
      quoteSearchFilter.data.date = {
        startDate,
        endDate,
        dateType: date.type,
      };
    } else {
      quoteSearchFilter.data.date = {
        startDate: '',
        endDate: '',
        dateType: '',
      };
    }

    quoteSearchFilter.data.query = query;

    return quoteSearchFilter;
  };

  const removeFilter = (tagItem: FilterTagItem): void => {
    // TODO: Move this logic to <Tag> code directly so it can manipulate classes there
    // e.target.classList.add("transition", "opacity-0");
    // Opacity code is removed for now since the filter list is controlled on dashboard, so element gets destroyed immediately instead of after a timeout.

    onFilterChange({ ...tagItem, operation: FilterOperation.REMOVE });
    setActivePage(1);
  };

  const loadAbandonedOrders = async (
    tags: FilterTagItem[] = filterTagItems,
    query: string = searchText,
  ) => {
    try {
      setIsLoading(true);

      const quoteSearchFilter2 = mapNewFilterRequest(quoteSearchFilter, tags, query);
      quoteSearchFilter2.data.order.realEstateStatus = AbandonedOrderStatuses;
      quoteSearchFilter2.data.order.sourceApplicationFlows = [OrderWorkflow.NewOrder];
      quoteSearchFilter2.meta.page = activePage - 1;

      // set what sort of extra data should be returned on the page
      quoteSearchFilter2.includeResponseData = {
        quote: false,
        offices: false,
        initiatingOffice: true,
      };

      quoteSearchFilter2.include.unexpiredOrdersOnly = true; // only display unexpired orders
      quoteSearchFilter2.include.initiatingOfficeOnly = isREAgent(props.profile); // only handle the initiating office check for RE Agent user
      quoteSearchFilter2.include.createdBySelfOnly = isCCAgent(props.profile); // filter to self orders only for CC Agent user

      setQuoteSearchFilter(quoteSearchFilter2);

      loadStatusCanceler?.('fetching new quote summary');
      const cancelSource = RealEstateQuoteApi.createNewCancelTokenSource();
      loadStatusCanceler = cancelSource.cancel;

      const searchProfileAbandonedOrders = await RealEstateQuoteApi.searchProfileQuotes(
        quoteSearchFilter2,
        { cancelToken: cancelSource.token },
      );

      if (!searchProfileAbandonedOrders) return;
      setSummaryView(searchProfileAbandonedOrders.orders);
      setTotalOrders(searchProfileAbandonedOrders.meta.total);
    } catch (error) {
      console.error('Error Fetching the orders', error);
    } finally {
      setIsLoading(false);
    }
  };

  const openDeleteOrderModal = (order: Quote) => {
    if (!order) {
      console.warn('order not provided properly, not opening modal');
      return;
    }
    setShowDeleteOrderModal(true);
    setPendingDeleteOrder(order);
  };

  const resetDeleteOrderStates = () => {
    setShowDeleteOrderModal(false);
    setPendingDeleteOrder(null);
  };

  const markOrderAsDeleted = (orderID: string): void => {
    setDeletedOrderIDs([...deletedOrderIDs, orderID]);
  };

  const onDeleteOrder = (confirmDelete: boolean): void => {
    if (!confirmDelete) {
      resetDeleteOrderStates();
      return;
    }
    const orderIDForDeletion = `${pendingDeleteOrder.id}`;
    showSpinner(true);
    RealEstateQuoteApi.cancelUnsubmittedOrder(
      orderIDForDeletion,
      UnsubmittedOrderCancellationWorkflow.MyOrders,
    )
      .then((success) => {
        if (!success) {
          throw new Error('delete order was unsuccessful');
        }
        addSuccessToQueue(msgsWParams.DELETE_ORDER_SUCCESS(orderIDForDeletion));
        markOrderAsDeleted(orderIDForDeletion);
        resetDeleteOrderStates();
      })
      .catch((e) => {
        console.error('failed to delete order', e);
        addErrorToQueue(msgsWParams.DELETE_ORDER_FAILED(orderIDForDeletion));
      })
      .finally(() => {
        showSpinner(false);
      });
  };

  useEffect(() => {
    loadAbandonedOrders();
  }, [activePage]);

  /* render filter component */
  const getListFiltering = () => {
    return (
      <OrdersAbandonedFiltering
        activeFilters={filterTagItems}
        statusMenuType={StatusMenuType.abandonedOrders}
        addFilter={addFilter}
      />
    );
  };

  const noLoadedTooltips = [{ label: 'No items loaded', onClick: null, id: 'no-items-loaded' }];

  const tooltipActions = (orderID: number | string, disableOrderDrawer = false) => {
    const order = summaryView.find((c) => c.tableId === orderID);
    if (!order) return noLoadedTooltips;

    const items: { label: string; onClick: () => void; disabled?: boolean; id?: string }[] = [
      {
        label: 'Continue Order',
        onClick: () => {
          fireGAEvent(CONTINUE_ABANDONED_ORDER);
          navigate(`${Path.NewOrder}/${orderID}?from=${NewOrderFromSource.MyOrders}`);
        },
        disabled: disableOrderDrawer,
        id: 'continue_order',
      },
      {
        label: 'Delete Order',
        onClick: () => openDeleteOrderModal(order),
        disabled: disableOrderDrawer,
        id: 'delete_order',
      },
    ];

    return items
      .filter((i) => !i.disabled)
      .map((i) => ({ label: i.label, onClick: i.onClick, id: i.id }));
  };

  const isOrderIDBeingDeleted = (orderID: string): boolean => {
    return deletedOrderIDs.includes(orderID);
  };

  /** determine if order and tooltip should be disabled */
  const shouldDisableOrderRow = (orderID: string): boolean => {
    return isOrderIDBeingDeleted(orderID);
  };

  interface TableDataRow {
    id: string;
    expirationDate: string;
    address: Address;
    officeName: string;
    agentName: string;
    status: string;
    actionId: string; // same as id
  }

  const tableData: TableDataRow[] = summaryView.map<TableDataRow>((order) => ({
    id: `${order.id}`,
    expirationDate: order.expirationDate,
    address: order.address,
    officeName: order.summary.officeName,
    agentName: order.summary.agentName,
    status: '',
    actionId: `${order.id}`,
  }));

  const shouldRenderTableActions = (orderID: string): boolean => {
    return !isOrderIDBeingDeleted(orderID);
  };

  const renderTableActions = (orderID: string) => {
    if (shouldRenderTableActions(orderID)) {
      return <ActionsDropdown menuItems={tooltipActions(orderID)} />;
    }
    if (isOrderIDBeingDeleted(orderID)) {
      return (
        <REText color="secondary" className="text-center">
          Deleting...
        </REText>
      );
    }
    return <></>;
  };

  const tableColumns = [
    {
      Header: <REText variant="heading-06">Covered Property Address</REText>,
      accessor: 'address', // accessor is the "key" in the data
      Cell: ({ value }) => (
        <REText variant="caption">
          <AddressDisplay address={toAppAddress(value)} singleLine={true} />
        </REText>
      ),
    },
    {
      Header: <REText variant="heading-06">Order #</REText>,
      accessor: 'id',
      Cell: ({ value }) => <REText>{value}</REText>,
    },
    {
      Header: <REText variant="heading-06">Expiration</REText>,
      accessor: 'expirationDate',
      Cell: ({ value }) => <REText variant="caption">{formatDate(value)}</REText>,
    },
    {
      Header: <REText variant="heading-06">Agent</REText>,
      accessor: 'agentName',
      Cell: ({ value }) => <REText variant="caption">{value}</REText>,
    },
    {
      Header: <REText variant="heading-06">Office</REText>,
      accessor: 'officeName',
      Cell: ({ value }) => <REText variant="caption">{value}</REText>,
    },
    {
      Header: <REText variant="heading-06">Status</REText>,
      accessor: 'status',
      Cell: ({ value }) => <REText>Not Submitted</REText>,
    },
    {
      Header: '',
      accessor: 'actionId',
      disableSortBy: true,
      Cell: ({ value }) => renderTableActions(value),
    },
  ];

  const onPageChange = (pageNumber: number) => {
    if (activePage !== pageNumber) {
      setActivePage(pageNumber);
    }
  };

  return (
    <div className="flex flex-row py-0">
      <div className="flex-1">
        <ContentBox
          title="My Orders"
          leftContent={
            !isMobile && (
              <div className="w-48 min-w-48 mr-4">
                <REText variant="heading-06" className="my-2">
                  Filter Orders
                </REText>
                {getListFiltering()}
              </div>
            )
          }
        >
          <div id="orders-container" className="relative px-0 pt-0 pb-6">
            {/* FILTER BUTTON OR TAGS + SUBMITTED TAB */}
            <div id="orders-not-submitted-container-tag-group" className="mb-4 sm:mt-3">
              {isMobile ? (
                <>
                  <Button
                    id="filter-orders-button"
                    label="Filter Orders"
                    shape={IsTheme(Theme.Ahs2024) ? 'pill' : 'rounded'}
                    variant="outlined"
                    onClick={() => setShowFilterModal(true)}
                    width="full"
                    labelAlign="center"
                  />
                  <OrdersActionSwitcher
                    id="orders-action-not-submitted"
                    searchType={OrdersActionSearchType.NotSubmitted}
                    onChangeSearchType={props.updateTab}
                  />
                </>
              ) : (
                <>
                  <TagGroup>
                    <div className="float-left">Filtered by:</div>
                    <div className="items-center flex flex-wrap">
                      {filtersForDisplay.map((item, idx) => (
                        <Tag
                          id={`tag-${idx}`}
                          key={item.label}
                          color="interactive"
                          removable
                          onRemove={() => {
                            removeFilter(item);
                          }}
                          className="ml-4"
                        >
                          {item.label}
                        </Tag>
                      ))}
                    </div>
                  </TagGroup>

                  <OrdersActionSwitcher
                    id="orders-action-not-submitted"
                    searchType={OrdersActionSearchType.NotSubmitted}
                    onChangeSearchType={props.updateTab}
                  />
                </>
              )}
            </div>

            <div className="orders-container-box">
              {/* SEARCH FILTER + VIEW TOGGLE */}
              <div
                id="orders-container--search-container"
                className={classNames([
                  IsTheme(Theme.AhsWebApp) ? 'card' : '',
                  !isMobile && 'block',
                ])}
              >
                <SearchBar
                  searchText={searchText}
                  onChange={(value) => onSearchFilterChange(value)}
                  icon={IconTypes.Filter}
                  placeHolder={FILTER_BY_NUMBER_ADDRESS}
                  showClearButton={true}
                  outerCSS="p-3"
                />
              </div>

              <>
                {/* TABLE VIEW */}
                {!isMobile && !isLoading && (
                  <div
                    id="orders-container--table-container"
                    className={IsTheme(Theme.Ahs2024) ? 'sm-max:hidden' : 'card sm-max:hidden'}
                  >
                    <Table
                      data={tableData}
                      columns={tableColumns}
                      striped="gray"
                      variant="heavy"
                      sortable={true}
                      paginate={false}
                      selectable={false}
                      onRowsSelect={console.log}
                    />
                  </div>
                )}

                {/* GRID VIEW */}
                <div
                  id="orders-container--grid-container"
                  className={classNames(['sm:flex sm:flex-wrap -mx-2', !isMobile && 'md:hidden'])}
                >
                  {isLoading ? (
                    Array(SKELETON_LOAD_CNT_GRID)
                      .fill(null)
                      .map((v, idx) => (
                        <div
                          id={`orders-card-${idx}`}
                          key={idx}
                          className="w-full sm:w-1/2 lg:w-1/3 p-2"
                        >
                          <CardOrderGrid skeleton={true} />
                        </div>
                      ))
                  ) : (
                    <>
                      {summaryView.map((item, idx) => {
                        return (
                          <div
                            id={`orders-card-${idx}`}
                            key={idx}
                            className="w-full sm:w-1/2 lg:w-1/3 p-2"
                          >
                            <CardOrderGrid
                              awaitingWlkSubmission={item.awaitingWlkSubmission}
                              status={item.realEstateStatus}
                              street={item.address.address1}
                              unit={item.address.address2}
                              city={item.address.city}
                              state={item.address.state}
                              zip={item.address.zip}
                              agent={item.summary.agentName}
                              office={item.summary.officeName}
                              menuLinks={tooltipActions(item.tableId)}
                              enableActions={shouldRenderTableActions(`${item.id}`)}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </>

              <AtypicallySlowOverlay isActive={isLoading} />
              <Pagination
                onItemsPerPageChange={null}
                itemsPerPage={ORDERS_PAGE_SIZE}
                itemsPerPageOptions={[
                  { value: ORDERS_PAGE_SIZE.toString(), label: ORDERS_PAGE_SIZE.toString() },
                ]}
                onPageChange={onPageChange}
                totalItems={totalOrders}
                page={activePage}
                hideViewAll={true}
              />
            </div>

            {/* If no contracts, we should display a message here */}
            {!isLoading && summaryView.length === 0 && (
              <div id="orders-container--no-results" className="m-5">
                {searchText || filtersForDisplay.length > 0
                  ? NO_INCOMPLETE_ORDERS_FOUND_WITH_CRITERIA
                  : NO_INCOMPLETE_ORDERS_FOUND}
              </div>
            )}
          </div>
        </ContentBox>
      </div>

      {/* FILTER MODAL FOR MOBILE VIEW */}
      <Dialog
        id="filter-modal"
        header="Filter Orders"
        modal={true}
        onClose={() => setShowFilterModal(false)}
        open={showFilterModal && isMobile}
        actionsAlign="right"
        actions={[
          <Button
            key="action"
            size="small"
            label="Done"
            onClick={() => setShowFilterModal(false)}
          />,
        ]}
      >
        <div className="min-w-80">{getListFiltering()}</div>
      </Dialog>

      <ModalDeleteOrder
        isActive={showDeleteOrderModal}
        onClose={() => onDeleteOrder(false)}
        onConfirm={() => onDeleteOrder(true)}
        orderAddress={toAppAddress(pendingDeleteOrder?.address)}
      />
    </div>
  );
};

export default OrdersAbandonedTemplate;
