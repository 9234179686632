import React from 'react';
import { Button } from '@ftdr/blueprint-components-react';
import QuickLinkSearch from '@components/drawer/subcomponents/QuickLinkSearch';
import { RealEstateStatus } from '@constants/dashboardFilters';
import { fireGAEvent } from '@app/core/tracking.service';
import { ORDER__REQUEST_SERVICE } from '@constants/ga-events.constants';
import { GetRequestServiceUrl } from '@helpers/order.utils';

const QuickLinkRequestService = (props) => {
  const requestService = (contractId, contract) => {
    fireGAEvent(ORDER__REQUEST_SERVICE(contractId));
    window.open(GetRequestServiceUrl(contract), '_blank');
  };

  const requestServiceButton = (contract) => {
    return [
      <Button
        key="action"
        label="Request Service"
        size="small"
        onClick={() =>
          requestService(contract.id, {
            id: contract.id,
            address: { zip: contract.address.zip },
          })
        }
      />,
    ];
  };

  return (
    <div>
      <QuickLinkSearch
        title="Request Service"
        subTitle="Search for an order number or address"
        unsuccessfulSearchErrorMessage="Order Not Found. Please try again, considering spelling and order status. Cancelled orders are not searchable."
        includedStatuses={[
          RealEstateStatus.ACTIVE,
          RealEstateStatus.OPEN_ORDER,
          RealEstateStatus.CLOSING_SOON,
          RealEstateStatus.PAYMENT_DUE,
          RealEstateStatus.EXPIRING,
          RealEstateStatus.AWAITING_WL_SUBMISSION,
          RealEstateStatus.CLOSING_LATER,
          RealEstateStatus.PAYMENT_PAST_DUE,
        ]}
        idPrefix="qlRequestService"
        onClickContract={() => console.log('do nothing')}
        showExpiryDate={false}
        actions={(contract) => requestServiceButton(contract)}
      />
    </div>
  );
};

export default QuickLinkRequestService;
