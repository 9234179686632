import React from 'react';
import { UserDetails } from '@app/models/profile.model';
import { IconNavArrowRight, Link, Panel } from '@ftdr/blueprint-components-react';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';
import { capitalize } from 'lodash';
import LineText from '@components/admin/LineText';

interface props {
  user?: UserDetails;
  onClick?: any;
}

const CardUserDetails: React.FC<props> = ({ user = {}, onClick }) => {
  return (
    <div onClick={onClick} className="cursor-pointer">
      <Panel
        className={IsTheme(Theme.Ahs2024) ? 'h-full flex flex-col' : ''}
        borderColor={IsTheme(Theme.Ahs2024) ? 'gray-300' : 'white'}
        border={IsTheme(Theme.Ahs2024) ? '2' : '0'}
        rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'none'}
        padding={IsTheme(Theme.Ahs2024) ? 'xl' : 'none'}
      >
        <div className="text-primary-400 hover:text-primary-300 font-bold md:text-lg leading-tight flex justify-between items-center">
          <REText id={'SA-card-title-' + user?.userID} variant="heading-05">
            <Link
              bold={true}
              color="interactive"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {capitalize(user.firstName)} {capitalize(user.lastName)}
              <IconNavArrowRight className="ml-2 mb-1 inline" />
            </Link>
          </REText>
        </div>

        <LineText
          truncateValue
          className="mt-6"
          boldText="Email: "
          text={user.email}
          boldTextId={'SA-card-email-bold-' + user?.userID}
          textId={'SA-card-email-' + user?.userID}
        />

        <LineText
          truncateValue
          className="mt-3"
          boldText="Web ID: "
          text={user.faEmail}
          boldTextId={'SA-card-webid-bold-' + user?.userID}
          textId={'SA-card-webid-' + user?.userID}
        />

        <LineText
          truncateValue
          className="mt-3"
          boldText="UserType: "
          text={user.userType}
          boldTextId={'SA-card-userType-bold-' + user?.userID}
          textId={'SA-card-userType-' + user?.userID}
        />

        <table className="card-user-table w-full border border-gray-500 mt-6 text-left">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 px-6">
                <REText variant="heading-06">Office</REText>
              </th>
              <th className="border border-gray-300 px-6">
                <REText variant="heading-06" className="max-w-32">
                  Warranty Link Broker
                </REText>
              </th>
            </tr>
          </thead>
          <tbody>
            {user.offices &&
              user.offices.map((office) => (
                <tr key={office.id}>
                  <td className="border border-gray-300 px-6">
                    {office.type} {office.id}
                  </td>
                  <td className="border border-gray-300 px-6">
                    {office.userExtensions?.length > 0 ? 'True' : 'False'}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <LineText
          className="mt-6"
          boldText="Registration Date: "
          text={user.registrationDate}
          boldTextId={'SA-card-date-bold-' + user?.userID}
          textId={'SA-card-date-' + user?.userID}
          textClassName="text-right pl-2"
        />
        <LineText
          className="mt-4"
          boldText="Brand: "
          text={user.brand}
          boldTextId={'SA-card-brand-bold-' + user?.userID}
          textId={'SA-card-brand-' + user?.userID}
          textClassName="text-right pl-2"
        />
        <LineText
          className="mt-4"
          boldText="Last Active: "
          text={user.lastActiveDate}
          boldTextId={'SA-card-lastActive-bold-' + user?.userID}
          textId={'SA-card-lastActive-' + user?.userID}
          textClassName="text-right pl-2"
        />
      </Panel>
    </div>
  );
};

export default CardUserDetails;
