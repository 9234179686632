import React from 'react';
import { classNames } from '@utils';
import { Panel, Radio } from '@ftdr/blueprint-components-react';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

export interface CardRadioItemProps {
  isActive?: boolean;
  name?: string;
  onClick?: (event: MouseEvent) => void;
  readOnly?: boolean;
  title?: string;
  leftContent?: JSX.Element;
  centerContent?: JSX.Element;
  rightContent?: JSX.Element;
  key?: string | number;
}

const CardRadioItem = (props: CardRadioItemProps) => {
  const { widthClass, widthClassLeft } = calculateColumnWidths(props);

  const handleSelect = (event) => {
    event.preventDefault();
    props.onClick(event);
  };

  return (
    <Panel
      className="block relative card--radio"
      border="2"
      rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'md'}
      padding={IsTheme(Theme.Ahs2024) ? 'xl' : 'md'}
      borderColor={props.isActive ? 'interactive-400' : 'gray-300'}
      shadow={IsTheme(Theme.Ahs2024) ? false : true}
    >
      <div onClick={handleSelect}>
        <div
          data-testid={`wb_compRadioBtn-${props.name}`}
          className="form-radio-wrap absolute top-50 left-4 cursor-pointer border-gray-500 leading-0"
        >
          <Radio
            name={props.name}
            onChange={handleSelect}
            label=""
            hideLabel={true}
            readOnly={props.readOnly}
            checked={props.isActive}
          />
          <div className="form-icon" />
        </div>

        <div
          className={classNames([
            'md:flex md:items-center cursor-pointer',
            IsTheme(Theme.Ahs2024) ? 'pl-8' : 'p-4 pl-12',
          ])}
        >
          <div className={classNames(['md:pr-8', widthClassLeft])}>
            {props.title && (
              <REText
                variant="heading-06"
                id={'card-item_title-' + props.key}
                color={props.readOnly ? 'gray' : 'primary'}
              >
                {props.title}
              </REText>
            )}
            <REText variant="body-short" id={'card-item_address-' + props.key}>
              {props.leftContent}
            </REText>
          </div>
          <div className={classNames([widthClass])} id={'card-item_phone-' + props.key}>
            <REText variant="body-short">{props.centerContent}</REText>
          </div>
          <div className={classNames([widthClass])} id={'card-item_distID-' + props.key}>
            <REText variant="body-short">{props.rightContent}</REText>
          </div>
        </div>
      </div>
    </Panel>
  );
};

/**
 * The purpose of this is to determine reasonable column proportions given the number
 * of columns provided.
 *
 * If just one column is provided it will get the full width
 * If just two columns are provided it will split them evenly half-and-half
 * If three columns are provided the first column will be a little bit wider with the next two splitting the remaining space
 *
 * @param props
 * @returns {{widthClassLeft: string, widthClass: string}}
 */
const calculateColumnWidths = (props) => {
  let columnCount = props.title || props.leftContent ? 1 : 0;
  if (props.centerContent) {
    columnCount += 1;
  }
  if (props.rightContent) {
    columnCount += 1;
  }
  let widthClassLeft = '';
  let widthClass = '';
  if (columnCount === 2) {
    widthClassLeft = 'md:w-1/2';
    widthClass = 'md:w-1/2';
  } else if (columnCount === 3) {
    widthClassLeft = 'md:w-1/2';
    widthClass = 'md:w-1/4';
  }
  return { widthClass, widthClassLeft };
};

export default CardRadioItem;
