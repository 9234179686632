import React from 'react';
import { Radio } from '@ftdr/blueprint-components-react';
import { ACTION_CONTACT_SUPPORT } from '@constants/newOrder-constants';
import { ContractDetail } from '@apis/models';
import { propertyObjectToString } from '@services/helpers';
import REText from '@components/wrappedBDS/REText';

interface Props {
  contract: ContractDetail;
}

const CannotAddCooperating: React.FC<Props> = (props) => {
  return (
    <div className="w-full px-4 py-2">
      <REText variant="heading-06">Order ID: {props.contract.id}</REText>
      <REText variant="heading-06">
        Covered Property Address: {propertyObjectToString(props.contract.property)}
      </REText>
      <div>
        <Radio
          label="Contact Support"
          name="contact-support"
          id="radio-contact-support"
          value={ACTION_CONTACT_SUPPORT}
          checked={true}
          labelPlacement="right"
          width="auto"
          color="interactive"
        />
      </div>
    </div>
  );
};

export default CannotAddCooperating;
