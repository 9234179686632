import React, { useEffect, useState } from 'react';
import CardCustom, { CardCustomSize } from '@components/card/CardCustom';
import { BDSMainColor } from '@constants/bds.constants';
import { CardTopStripType } from '@components/card/CardTopStrip';
import { Carousel, Link } from '@ftdr/blueprint-components-react';
import ZestyApi from '@apis/zesty.apis';
import { useNavigate } from 'react-router-dom';
import { ZestyDashboardWhatsNewCtaBehavior } from '@apis/models/zesty.api.model';
import { useWindowSize } from 'react-use';
import { fireGAEvent } from '@app/core/tracking.service';
import { DASHBOARD_WHATS_NEW_LINK_EVENT } from '@constants/ga-events.constants';
import { getBrand } from '@helpers/brand.utils';
import { AHS_AND_HSA } from '@constants/dashBoard-constants';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

const CardDashboardWhatsNew = (props) => {
  const CAROUSEL_CONTENT_MAXIMUM_SLIDES = 3;

  /*
    Needed to make Carousel's aspectRatio prop behave.
    You must provide the carousel content size as an aspect ratio, which will
    change when you resize the browser window in mobile view.
  */
  const CAROUSEL_CONTENT_PREFERRED_WIDTH = 232;
  const CAROUSEL_CONTENT_HEIGHT = 200;

  const [slideData, setSlideData] = useState([]);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [carouselContentWidth, setCarouselContentWidth] = useState(
    CAROUSEL_CONTENT_PREFERRED_WIDTH,
  );
  const navigate = useNavigate();

  const advanceSlide = () => {
    fireGAEvent(DASHBOARD_WHATS_NEW_LINK_EVENT(slideData[activeSlideIndex].header));
    setActiveSlideIndex((activeSlideIndex + 1) % Math.max(1, slideData.length));
  };

  const numberComparator = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const fetchDashboardWhatsNewData = () => {
    ZestyApi.GetDashboardWhatsNew().then((res) => {
      const sortedResults = res
        .filter(
          (item) =>
            item.brand.toUpperCase() === getBrand() || item.brand.toUpperCase() === AHS_AND_HSA,
        )
        .sort((a, b) => numberComparator(a.sortOrder, b.sortOrder))
        .slice(0, CAROUSEL_CONTENT_MAXIMUM_SLIDES);

      setSlideData(sortedResults);
    });
  };

  const updateCarouselContentWidth = () => {
    const carousels = document.getElementsByClassName('carousel');
    if (carousels.length > 0) {
      setCarouselContentWidth(carousels[0].clientWidth);
    }
  };

  useEffect(() => {
    fetchDashboardWhatsNewData();
  }, []);

  useEffect(() => {
    updateCarouselContentWidth();
  }, [useWindowSize(), slideData]);

  const renderCarouselContent = (headerText, bodyHtml, ctaText = null, onCtaClick = null) => {
    return (
      <div className="w-full h-full overflow-y-auto mb-3">
        <span className="dashboard-whats-new-header-text">{headerText}</span>
        <REText className="mt-0 text-body-long">
          <span className="zesty-html" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
        </REText>
        {ctaText && onCtaClick && (
          <REText className="mt-1">
            <Link
              bold={true}
              color="interactive"
              underline="always"
              onClick={(e) => {
                e.stopPropagation();
                fireGAEvent(DASHBOARD_WHATS_NEW_LINK_EVENT(headerText));
                onCtaClick();
              }}
            >
              {ctaText}
            </Link>
          </REText>
        )}
      </div>
    );
  };

  return (
    <CardCustom
      size={CardCustomSize.DashboardSmall}
      topStripType={CardTopStripType.Strip}
      topStripColor={IsTheme(Theme.Ahs2024) ? BDSMainColor.Primary : BDSMainColor.Interactive}
    >
      <div className="flex flex-col pt-4 pl-4 pr-4 pb-0 -mb-3" style={{ userSelect: 'none' }}>
        <REText variant="heading-04" className="mt-0 ml-0 mr-0 mb-0 pb-4" onClick={advanceSlide}>
          What&apos;s New?
        </REText>
        {slideData.length >= 1 && (
          <Carousel
            className="carousel-whats-new"
            showArrows={false}
            loop={true}
            aspectRatio={{ width: carouselContentWidth, height: CAROUSEL_CONTENT_HEIGHT }}
            allowMouseEvents={false}
            selectedSlide={activeSlideIndex}
            onChange={setActiveSlideIndex}
          >
            {slideData.map((slide) => (
              <div key={slide.header} className="h-full w-full" onClick={advanceSlide}>
                {renderCarouselContent(
                  slide.header,
                  slide.body,
                  slide.ctaBehavior !== ZestyDashboardWhatsNewCtaBehavior.NoCta
                    ? slide.ctaText
                    : null,
                  () => {
                    if (slide.ctaBehavior === ZestyDashboardWhatsNewCtaBehavior.MediaInNewTab) {
                      window.open(slide.ctaLinkedMedia[0].url, '_blank');
                      return;
                    }
                    if (slide.ctaBehavior === ZestyDashboardWhatsNewCtaBehavior.UrlInNewTab) {
                      window.open(slide.ctaLinkedUrl, '_blank');
                      return;
                    }
                    if (slide.ctaBehavior === ZestyDashboardWhatsNewCtaBehavior.UrlInSameTab) {
                      navigate(slide.linkedUrl);
                    }
                  },
                )}
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </CardCustom>
  );
};

export default CardDashboardWhatsNew;
