import React, { useContext, useState } from 'react';
// Components
import ModalRemoveOffice from '@components/modal/ModalRemoveOffice';
import DrawerOfficeSearch from '@components/drawer/DrawerOfficeSearch';
import {
  ErrorCodes,
  officeTypeDictionary,
  officeTypeFullFormDictionary,
} from '@constants/dictionaries';
import ProfileContext from '../../context/ProfileContext';
import ProfileApi from '@apis/profile.api';
import {
  OFFICE_NOT_IN_NETWORK,
  OFFICE_UPDATED,
  OFFICE_UPDATED_ERROR,
} from '@constants/formField-constants';
import { OfficeRequest, UpdateOfficeRequest } from '@apis/models/profile.api.model';
import * as app from '@app/models';
import ProfileModel, { ProfileOffice } from '@app/models/profile.model';
import {
  getWorkedWithOfficeTypeCode,
  updateWorkedWithOfficesOnSave,
} from '@services/helpers/profile.offices.helper';
import { fireGAEvent } from '@app/core/tracking.service';
import { ACCOUNT_MANAGEMENT__ADDED_OFFICE } from '@constants/ga-events.constants';
import {
  Accordion,
  AccordionSection,
  Button,
  IconPlus,
  IconTrash,
  Link,
  Panel,
} from '@ftdr/blueprint-components-react';
import useGlobalAlert, { Message } from '@app/core/GlobalAlertModal';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

interface Props {
  title: string;
  userDetails: ProfileModel;
  userOfficeDetail: app.Office[];
  workedWithOffices: boolean;
  refreshUser: any;
  removeOffice: (selectedOffice) => void;
  usaStates?;
  setUserOfficeIDs?;
  testIdPrefix?: string;
}

const CardOfficeInfo: React.FC<Props> = (props) => {
  const { addSuccessToQueue, addErrorToQueue } = useGlobalAlert();

  const [showRemoveOfficeModal, setShowRemoveOfficeModal] = useState(false);
  const [showAddOfficeDrawer, setShowAddOfficeDrawer] = useState(false);
  const [showChangeOfficeDrawer, setShowChangeOfficeDrawer] = useState(false);
  const [showAddWorkedWithOfficeDrawer, setShowAddWorkedWithOfficeDrawer] = useState(false);
  const { profile } = useContext(ProfileContext);
  const [selectedOfficeForRemoval, setSelectedOfficeForRemoval] = useState(null);

  let canHaveMultipleOffices = true;
  let showRemoveOfficeBtn = false;
  let showChangeOfficeBtn = false;
  const multiSelectRoles = ['Broker', 'ClosingCompanyAdmin', 'RealEstateAdmin'];
  const canUserMultiSelect = multiSelectRoles.includes(props.userDetails.roleIDType);

  const msgs: { [key: string]: Message } = {
    OFFICE_UPDATED_SUCCESS: {
      id: 'notifications-office-updated-success',
      message: OFFICE_UPDATED,
    },
    OFFICE_UPDATE_FAILED: {
      id: 'notifications-office-updated-error',
      message: OFFICE_UPDATED_ERROR,
    },
    FRANCHISE_ERROR: {
      id: 'notifications-francise-code-error',
      messageHTML: <div dangerouslySetInnerHTML={{ __html: OFFICE_NOT_IN_NETWORK }} />,
    },
  };

  if (props.userDetails && props.userDetails.roleIDType) {
    const { roleIDType } = props.userDetails;
    if (['RealEstateAgent', 'ClosingCompanyAgent'].includes(roleIDType)) {
      canHaveMultipleOffices = false;
    }

    if (props.userOfficeDetail) {
      if (
        ['RealEstateAdmin', 'ClosingCompanyAdmin', 'Broker', 'SiteAdmin'].includes(roleIDType) &&
        props.userOfficeDetail.length > 1
      ) {
        // user should have atleast 1 offices
        showRemoveOfficeBtn = true;
      } else if (roleIDType === 'FieldSales') {
        // AE users can have zero offices
        showRemoveOfficeBtn = true;
      }

      if (
        ['RealEstateAdmin', 'ClosingCompanyAdmin', 'Broker'].includes(roleIDType) &&
        props.userOfficeDetail.length === 1
      ) {
        showChangeOfficeBtn = true;
      }
      if (props.workedWithOffices) {
        showRemoveOfficeBtn = false;
      }
    }
  }

  function closeAddOfficeDrawer() {
    setShowAddOfficeDrawer(false);
  }

  function closeChangeOfficeDrawer() {
    setShowChangeOfficeDrawer(false);
  }

  function closeAddWorkedWithOfficeDrawer() {
    setShowAddWorkedWithOfficeDrawer(false);
  }

  // Change office:
  // Overwrite previous office(s)
  // Call updateOffice()
  // Toggle Drawer
  async function submitChangeOfficeDrawerHandler(selectedOffices) {
    if (selectedOffices.length != 1) {
      return;
    }
    try {
      const updatedOfficeList = selectedOffices.map((office) => {
        return {
          type: officeTypeFullFormDictionary[office.type],
          id: office.id,
        };
      });

      await updateOffice(updatedOfficeList);
      closeChangeOfficeDrawer();
    } catch (e) {
      console.error('failed to update office', e);
      addErrorToQueue(msgs.OFFICE_UPDATE_FAILED);
    }
  }

  // Add office:
  // Add to pre-existing office list
  // Call updateOffice()
  // Toggle Drawer
  async function submitAddOfficeDrawerHandler(selectedOffices) {
    if (selectedOffices.length === 0) {
      return;
    }
    try {
      let updatedOfficeList = [];

      profile.offices.forEach((office) => {
        const officeDetails = { ...office };
        officeDetails.type = officeTypeFullFormDictionary[office.type]
          ? officeTypeFullFormDictionary[office.type]
          : office.type;
        updatedOfficeList.push(officeDetails);
      });
      let newOfficeType = null;
      updatedOfficeList = [
        ...updatedOfficeList,
        ...selectedOffices.map((office) => {
          newOfficeType = office.type;
          return {
            type: officeTypeFullFormDictionary[office.type],
            id: office.id,
          };
        }),
      ];

      await updateOffice(updatedOfficeList);
      closeAddOfficeDrawer();

      fireGAEvent(ACCOUNT_MANAGEMENT__ADDED_OFFICE(officeTypeFullFormDictionary[newOfficeType]));
    } catch (e) {
      console.error('failed to update office', e);
      addErrorToQueue(msgs.OFFICE_UPDATE_FAILED);
    }
  }

  // Add worked with office:
  // Add to pre-existing worked with office list
  // Call updateOffice()
  // Toggle Drawer
  async function submitAddWorkedWithOfficeDrawerHandler(selectedOffices) {
    if (selectedOffices.length === 0) {
      return;
    }
    try {
      let updatedOfficeList = [];

      profile.workedWithOffices.forEach((office) => {
        const officeDetails = { ...office };
        officeDetails.type = officeTypeFullFormDictionary[office.type]
          ? officeTypeFullFormDictionary[office.type]
          : office.type;
        updatedOfficeList.push(officeDetails);
      });

      updatedOfficeList = [
        ...updatedOfficeList,
        ...selectedOffices.map((office) => {
          return {
            type: officeTypeFullFormDictionary[office.type],
            id: office.id,
          };
        }),
      ];

      await updateWorkedWithOffice(updatedOfficeList);
      closeAddWorkedWithOfficeDrawer();
    } catch (e) {
      console.error('failed to update office', e);
      addErrorToQueue(msgs.OFFICE_UPDATE_FAILED);
    }
  }

  // Update worked with office:
  // Make UpdateProfile API request
  // Show Error Modal on Failure
  // Show Success Modal on Success
  async function updateWorkedWithOffice(offices: OfficeRequest[]) {
    const res = await updateWorkedWithOfficesOnSave(
      profile,
      ...offices.map<ProfileOffice>((o) => ({
        id: o.id,
        type: o.type,
        active: undefined,
        address: undefined,
        warrantyLinkEligible: undefined,
        userExtensions: undefined,
      })),
    );

    if (res?.isAxiosError || res?.data?.errors) {
      addErrorToQueue(msgs.OFFICE_UPDATE_FAILED);
    } else {
      props.refreshUser();
      addSuccessToQueue(msgs.OFFICE_UPDATED_SUCCESS);
      fireGAEvent(ACCOUNT_MANAGEMENT__ADDED_OFFICE(officeTypeFullFormDictionary[offices[0].type]));
    }
  }

  // Update office:
  // Make UpdateOffice API request
  // Show Error Modal on Failure
  // Show Success Modal on Success
  async function updateOffice(offices: OfficeRequest[]) {
    const req: UpdateOfficeRequest = {
      profileID: profile.profileID,
      offices,
    };

    const res = await ProfileApi.updateUsersOfficeDetails(req);
    if (res === 'success') {
      props.refreshUser();
      addSuccessToQueue(msgs.OFFICE_UPDATED_SUCCESS);
    } else if (
      res &&
      res.includes(ErrorCodes.UpdateUserOfficeOfficeNotInFranchise) &&
      ['Broker', 'ClosingCompanyAdmin', 'RealEstateAdmin'].includes(profile.roleIDType)
    ) {
      // franchiseCode error
      addErrorToQueue(msgs.FRANCHISE_ERROR);
    } else {
      addErrorToQueue(msgs.OFFICE_UPDATE_FAILED);
    }
  }

  function removeOfficeHandler() {
    setShowRemoveOfficeModal(false);
    props.removeOffice(selectedOfficeForRemoval);
  }

  function displayModalForRemoveOffice(flag, selectedOffice) {
    setSelectedOfficeForRemoval(selectedOffice);
    setShowRemoveOfficeModal(flag);
  }

  function getPhones(office: app.Office, index?: number) {
    const phones = office?.contact?.phones;

    return (
      (phones.office || phones.fax) && (
        <>
          {phones.office && (
            <div className="flex items-center">
              <REText className="mr-1" id={props.testIdPrefix + 'main-' + index}>
                Main:
              </REText>
              <Link color="interactive" href={`tel:${phones.office}`}>
                {phones.office}
              </Link>
            </div>
          )}
          {phones.fax && (
            <div className={`${IsTheme(Theme.Ahs2024) ? 'mt-4' : ''} flex items-center`}>
              <REText id={props.testIdPrefix + 'fax-' + index} className="mr-1">
                Fax:
              </REText>
              <Link color="interactive" href={`tel:${phones.fax}`}>
                {phones.fax}
              </Link>
            </div>
          )}
        </>
      )
    );
  }

  function getAddress(office: app.Office, index?: number) {
    return (
      office.address && (
        <>
          <REText
            id={props.testIdPrefix + 'officeAddress-' + index}
            className={IsTheme(Theme.Ahs2024) ? 'mb-4' : ''}
          >
            {office.address.streetAddress}
          </REText>
          <REText
            id={props.testIdPrefix + 'location-' + index}
            className={IsTheme(Theme.Ahs2024) ? 'mb-4' : ''}
          >
            {office.address.city}, {office.address.state} {office.address.zip}
          </REText>
        </>
      )
    );
  }

  return (
    <Panel
      border="0"
      padding="none"
      shadow={IsTheme(Theme.Ahs2024) ? false : true}
      rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'md'}
      className={
        IsTheme(Theme.Ahs2024)
          ? 'p-4 md:p-6 mt-6'
          : 'card--office-info xs-max:overflow-visible sm:px-8 sm:py-5 mt-8 xs-max:bg-transparent xs-max:shadow-none'
      }
    >
      <div
        className={`${IsTheme(Theme.Ahs2024) ? 'mb-4 md:mb-6 flex-col md:flex-row' : 'mb-4 items-center'} flex justify-between`}
      >
        <div className={IsTheme(Theme.Ahs2024) ? 'mb-2' : ''}>
          <REText variant="heading-05">{props.title}</REText>
        </div>
        <div className={IsTheme(Theme.Ahs2024) ? 'md:min-w-32' : ''}>
          {!props.workedWithOffices ? (
            // Offices
            <Button
              id="wb_my-account-add-office-button"
              startIcon={<IconPlus />}
              variant="outlined"
              color="interactive"
              size="medium"
              onClick={
                canHaveMultipleOffices
                  ? () => setShowAddOfficeDrawer(true)
                  : () => setShowChangeOfficeDrawer(true)
              }
              label={canHaveMultipleOffices ? 'Add Office' : 'Change Office'}
              width={IsTheme(Theme.Ahs2024) ? 'full' : 'auto'}
            />
          ) : (
            // Worked With Offices
            <Button
              id="wb_my-account-add-worked-with-office-button"
              startIcon={<IconPlus />}
              variant="outlined"
              color="interactive"
              size="medium"
              onClick={() => setShowAddWorkedWithOfficeDrawer(true)}
              label="Add Office"
              width={IsTheme(Theme.Ahs2024) ? 'full' : 'auto'}
            />
          )}
        </div>
      </div>
      {props.userOfficeDetail && (
        <Accordion hideFirstSeparator hideLastSeparator mode="multiple">
          {props.userOfficeDetail.map((currentOfficeDetail, index) => (
            <AccordionSection key={currentOfficeDetail.id} label={currentOfficeDetail.name}>
              <div
                className={`${IsTheme(Theme.Ahs2024) ? 'flex-col md:flex-row' : ''} flex lg:flex lg:justify-between mb-4`}
              >
                <div className="lg:flex lg:justify-between w-full">
                  <div className={IsTheme(Theme.Ahs2024) ? 'pl-9' : ''}>
                    {getAddress(currentOfficeDetail, index)}
                    {getPhones(currentOfficeDetail, index)}
                  </div>
                  <div className="sm:flex sm:flex-wrap lg:w-2/3 lg:-mt-2 -mx-2 md-max:pt-4">
                    {Array(0) // leaving 0 here to avoid deleting the layout
                      .fill(null)
                      .map((item, idx) => (
                        <div key={idx} className="sm:w-1/2 md:w-1/3 lg:w-1/2 p-2">
                          <p>
                            <strong>Broker</strong>
                          </p>
                          <p>
                            Sally May
                            <br />
                            <a href="tel:901-555-5555">(901) 555-5555</a>
                            <br />
                            <a
                              href="mailto:sallymay@gmail.com"
                              className="inline-block truncate"
                              title="sallymay@gmail.com"
                            >
                              sallymay@gmail.com
                            </a>
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="flex flex self-start flex-1 w-full md-max:pt-2">
                  {/* Commented as part of ARE-4732
                remove account executive box for now
                <p>
                  <strong>Account Executive</strong>
                </p>

                <p>
                  Karen Smith
                    <br />
                  <a href="tel:901-555-5555">(901) 555-5555</a>
                  <br />
                  <a
                    href="mailto:karensmith@gmail.com"
                    className="inline-block truncate"
                    title="karensmith@gmail.com"
                  >
                    karensmith@gmail.com
                    </a>
                </p> */}

                  {/*  Note: This button is temporarily added. 
                                As the UI elements(https://projects.invisionapp.com/share/H3UAVONDQ2Y#/screens/387876629) 
                                for remove button is currently not integrated.
                                once UI component is integrated remove following button
                                and add its on click action on newly added UI element
                                */}
                  {(props.workedWithOffices ? true : props.userOfficeDetail.length > 1) && (
                    <Button
                      id="wb_my-account-remove-office-button"
                      startIcon={<IconTrash />}
                      variant="outlined"
                      color="error"
                      size="medium"
                      onClick={() => {
                        displayModalForRemoveOffice(true, currentOfficeDetail);
                      }}
                      label="Remove Office"
                      width="full"
                    />
                  )}
                  {showChangeOfficeBtn && (
                    <Button
                      id="wb_my-account-change-office-button"
                      variant="outlined"
                      color="interactive"
                      size="medium"
                      className="block floatRight"
                      onClick={() => setShowChangeOfficeDrawer(true)}
                      label="Change Office"
                      width="full"
                    />
                  )}
                  {/* End of remove button */}
                </div>
              </div>
            </AccordionSection>
          ))}{' '}
        </Accordion>
      )}
      {/* Add Office */}
      <DrawerOfficeSearch
        usaStates={props.usaStates}
        typeCode={officeTypeDictionary[props.userDetails.roleIDType]}
        excludeBrandSearch={false}
        isActive={showAddOfficeDrawer}
        multiSelect={canUserMultiSelect}
        onClose={closeAddOfficeDrawer}
        onSubmit={submitAddOfficeDrawerHandler}
        userOfficeIDs={props.setUserOfficeIDs}
      />

      {/* Change Office */}
      <DrawerOfficeSearch
        usaStates={props.usaStates}
        typeCode={officeTypeDictionary[props.userDetails.roleIDType] || 'RE'}
        excludeBrandSearch={false}
        isActive={showChangeOfficeDrawer}
        multiSelect={canUserMultiSelect}
        onClose={closeChangeOfficeDrawer}
        onSubmit={submitChangeOfficeDrawerHandler}
        userOfficeIDs={props.setUserOfficeIDs}
      />
      {/* Add Worked With Office */}
      <DrawerOfficeSearch
        usaStates={props.usaStates}
        typeCode={getWorkedWithOfficeTypeCode(props.userDetails)}
        excludeBrandSearch={getWorkedWithOfficeTypeCode(props.userDetails) === 'CC'}
        isActive={showAddWorkedWithOfficeDrawer}
        multiSelect={true}
        onClose={closeAddWorkedWithOfficeDrawer}
        onSubmit={submitAddWorkedWithOfficeDrawerHandler}
        userOfficeIDs={props.setUserOfficeIDs}
      />
      <ModalRemoveOffice
        open={showRemoveOfficeModal}
        onClose={() => setShowRemoveOfficeModal(false)}
        onRemove={removeOfficeHandler}
      />
    </Panel>
  );
};

export default CardOfficeInfo;
