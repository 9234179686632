import React, { Component } from 'react';
import { FAQ, FAQCategory } from '@app/models/zesty.model';
import {
  Button,
  IconMail,
  Accordion,
  AccordionSection,
  Panel,
  Link,
} from '@ftdr/blueprint-components-react';
import Path from '@constants/paths';
import { fireGAEvent } from '@app/core/tracking.service';
import { FAQ__CONTACT_US } from '@constants/ga-events.constants';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

interface state {}

interface props {
  FAQs: FAQ[];
  FAQCategories: FAQCategory[];
  RoleIDType: string;
}

export default class FAQTemplate extends Component<props, state> {
  elementRefs = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  createRef = (element, id) => {
    this.elementRefs[id] = element;
  };

  handleQuestion = (id) => {
    this.elementRefs[id].scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  sortByField = (a, b) => {
    return a.sort - b.sort;
  };

  handleRequestSupport = () => {
    console.info('navigating to contact-us page');
    fireGAEvent(FAQ__CONTACT_US(this.props.RoleIDType));
    window.location.assign(Path.ContactUs);
  };

  render() {
    return (
      <div className="px-0 mx-0">
        <Panel
          className={
            IsTheme(Theme.Ahs2024)
              ? 'flex flex-col md:flex-row p-4 md:p-6'
              : '-m-2 flex flex-wrap noFlexWrap1024 mx-0 p-0'
          }
          rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'md'}
          padding="none"
          shadow={IsTheme(Theme.Ahs2024) ? false : true}
          border="0"
        >
          <div
            className={`${IsTheme(Theme.Ahs2024) ? 'w-full md:w-48 mr-12' : 'w-80'} prewrap`}
            style={{ position: 'sticky' }}
          >
            <Accordion mode="multiple" hideFirstSeparator>
              {this.props.FAQCategories.sort(this.sortByField).map((category) => (
                <AccordionSection
                  key={category.id}
                  label={category.name}
                  className="shadow-none text-interactive-color pl-2"
                >
                  <ul className="ml-3 p-4">
                    {this.props.FAQs.filter((faq) => faq.categoryID === category.id)
                      .sort(this.sortByField)
                      .map((faq) => (
                        <li key={faq.id} className="prewrap mb-4">
                          <Link
                            className="font-bold"
                            color="interactive"
                            onClick={() => this.handleQuestion(faq.id)}
                          >
                            {faq.question}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </AccordionSection>
              ))}
            </Accordion>
            <div
              className={`${IsTheme(Theme.Ahs2024) ? 'px-0 py-6 max-w-48 md:w-full' : 'py-3 pl-4 md:pl-6 pr-16 md:pr-20 w-full'} relative text-left block`}
            >
              <Button
                id="request_support_nav"
                onClick={this.handleRequestSupport}
                size="small"
                width="full"
                label="Request Support"
                startIcon={<IconMail />}
              />
            </div>
          </div>
          <div className={IsTheme(Theme.Ahs2024) ? 'max-w-4xl' : 'ml-5'}>
            {this.props.FAQCategories.sort(this.sortByField).map((category) => (
              <div key={category.id} className="mb-4">
                <REText id={'lbl-category-' + category.id} variant="heading-03">
                  {category.name}
                </REText>
                {this.props.FAQs.filter((faq) => faq.categoryID === category.id)
                  .sort(this.sortByField)
                  .map((faq) => (
                    <div
                      id={faq.id}
                      key={faq.id}
                      ref={(element) => this.createRef(element, faq.id)}
                    >
                      <REText
                        id={'lbl-question-' + faq.id}
                        variant="heading-06"
                        className="mt-4 mb-2 normal-case"
                      >
                        {faq.question}
                      </REText>
                      <div
                        className="zesty-html"
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      />
                    </div>
                  ))}
              </div>
            ))}
            <REText
              variant={IsTheme(Theme.Ahs2024) ? 'heading-06' : 'heading-03'}
              className={IsTheme(Theme.Ahs2024) ? 'pt-8 md:pt-2 normal-case' : 'normal-case'}
            >
              Still have questions?
            </REText>
            <REText className={IsTheme(Theme.Ahs2024) ? 'pt-4 md:pt-0' : ''}>
              Submit a support request.
            </REText>
            <Button
              className={IsTheme(Theme.Ahs2024) ? 'mt-6' : ''}
              id="request_support_content"
              onClick={this.handleRequestSupport}
              size="small"
              label="Request Support"
              startIcon={<IconMail />}
            />
          </div>
        </Panel>
      </div>
    );
  }
}
