import React, { Component } from 'react';

// Components
import { FilterType } from '@constants/dashboardFilters';
import { FilterTagItem } from '@components/misc/misc.models';
import { Office } from '@apis/models';
import {
  Button,
  Checkbox,
  Pagination,
  Popover,
  PopoverContent,
  Text,
} from '@ftdr/blueprint-components-react';
import { addressToString } from '@services/helpers';
import { toUpper } from 'lodash';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

interface state {
  selectedOffices: Office[];
  currentPageNumber: number;
  itemsPerPage: number;
  isApplyDisabled: boolean;
}

interface props {
  id?: string;
  heading: string;
  onClose: any;
  isActive: boolean;
  addFilter: any;
  onApply: any;
  activeFilters: FilterTagItem[];
  offices: Office[];
  setActions: any;
}

class OfficesFilter extends Component<props, state> {
  constructor(props) {
    super(props);
    this.state = {
      selectedOffices: this.props.activeFilters
        .filter((office) => office.type === FilterType.OFFICE)
        .map((filter) => filter.payload.office),
      currentPageNumber: 1,
      itemsPerPage: 10,
      isApplyDisabled: true,
    };
  }

  componentDidMount() {
    this.setButtons();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isApplyDisabled !== this.state.isApplyDisabled) {
      this.setButtons();
    }
  }

  setButtons() {
    this.props.setActions([
      <Popover
        key="apply"
        placement="bottom"
        triggerInteraction="hover"
        content={(popoverContentProps) => (
          <PopoverContent {...popoverContentProps}>
            <REText className="max-w-xs" color="gray" variant="caption">
              Office Selection Required.
            </REText>
          </PopoverContent>
        )}
        disabled={!this.state.isApplyDisabled}
        className="mr-3 w-full"
      >
        <Button
          id="apply_button"
          label="Apply"
          variant="filled"
          onClick={this.onApply}
          disabled={this.state.isApplyDisabled}
        />
      </Popover>,
      <Button
        key="cancel"
        id="cancel_button"
        label="Cancel"
        variant={`${IsTheme(Theme.Ahs2024) ? 'ghost' : 'outlined'}`}
        onClick={this.handleCancel}
      />,
    ]);
  }

  shouldDisableApplyButton = (selectedOfficeList): boolean => {
    let shouldDisable: boolean = true;

    // if the selected offices has a new filter to add, we should not disable the button
    this.props.activeFilters
      .filter((filter) => filter.type === FilterType.OFFICE)
      .forEach((activeFilter) => {
        const isInOfficeList: boolean = selectedOfficeList.some(
          (office) => office.id === activeFilter.payload.office.id,
        );
        if (!isInOfficeList) {
          shouldDisable = false;
        }
      });

    // if the selected offices has a new filter, we should not disable the button
    selectedOfficeList.forEach((office) => {
      const isOfficeAlreadyFiltered: boolean = this.props.activeFilters
        .filter((filter) => filter.type === FilterType.OFFICE)
        .some((filter) => filter.payload.office.id === office.id);
      if (!isOfficeAlreadyFiltered) {
        shouldDisable = false;
      }
    });

    return shouldDisable;
  };

  onChange = (office, event) => {
    const isChecked = event.target.checked;
    const officeId = office.id;
    const { selectedOffices } = this.state;
    const exists = selectedOffices.some((filter) => filter.id === officeId);

    if (!exists && isChecked) {
      selectedOffices.push(office);
    } else if (exists && !isChecked) {
      const index = selectedOffices.indexOf(office);
      if (index > -1) {
        selectedOffices.splice(index, 1);
      }
    }

    this.setState({
      selectedOffices,
      isApplyDisabled: this.shouldDisableApplyButton(selectedOffices),
    });
  };

  onApply = () => {
    this.props.onApply(this.state.selectedOffices);
    this.props.onClose();
  };

  handleCancel = () => {
    this.props.onClose();
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPageNumber: pageNumber });
  };

  handleViewAll = () => {
    this.setState({ currentPageNumber: 1, itemsPerPage: this.props.offices.length });
  };

  handleItemsPerPageChange = (items) => {
    if (items.label !== 'All') {
      this.setState({ itemsPerPage: +items.value, currentPageNumber: 1 });
    } else {
      this.setState({ itemsPerPage: this.props.offices.length, currentPageNumber: 1 });
    }
  };

  render() {
    return (
      <>
        <div className="mb-4 overflow-y-auto" style={{ maxHeight: '36vh' }}>
          {this.props.offices
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((office, idx) => {
              if (
                idx >= (this.state.currentPageNumber - 1) * this.state.itemsPerPage &&
                idx < this.state.currentPageNumber * this.state.itemsPerPage
              ) {
                return (
                  <div key={office.id} className="w-full min-h-6 mb-1">
                    <Checkbox
                      checked={this.state.selectedOffices.some((filter) => filter.id === office.id)}
                      // @ts-expect-error TODO: type safety violation
                      label={
                        <>
                          <REText variant="label" className="office-filter-name-text">
                            {toUpper(office.name)}
                          </REText>
                          <REText variant="body-short" color="primary">
                            {addressToString(
                              office.address.address1,
                              office.address.address2,
                              office.address.city,
                              office.address.state,
                              office.address.zip,
                            )}
                          </REText>
                        </>
                      }
                      onChange={(event) => this.onChange(office, event)}
                    />
                  </div>
                );
              }
            })}
        </div>
        <Pagination
          className="w-full"
          itemsPerPage={this.state.itemsPerPage}
          onItemsPerPageChange={(items) => this.handleItemsPerPageChange(items)}
          onPageChange={(idx) => this.handlePageChange(idx)}
          page={this.state.currentPageNumber}
          totalItems={this.props.offices.length}
          hideViewAll={false}
          onViewAllClick={this.handleViewAll}
        />
      </>
    );
  }
}

export default OfficesFilter;
