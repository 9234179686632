import React, { useEffect, useState } from 'react';
import { Button, Panel } from '@ftdr/blueprint-components-react';
import ContractApi from '@apis/contract.api';
import { validatePhoneInput } from '@services/validation/NewOrder.FieldValidationRules';
import PhoneInput from '@components/input/PhoneInput';
import { fireGAEvent } from '@app/core/tracking.service';
import { DASHBOARD_ACTION_MISSING_BUYER_PHONE } from '@constants/ga-events.constants';
import ValidateApi from '@apis/validate.api';
import { OrderFlowType } from '@helpers/order.utils';
import REText from '@components/wrappedBDS/REText';

const AccordionItemMissingBuyerPhone = (props) => {
  const [phoneInput, setPhoneInput] = useState('');
  const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
  const [represents, setRepresents] = useState('');

  const handleSave = () => {
    const err = validateContactPhone(phoneInput);
    if (!err) {
      // asynchronously call validate for buyer email/phone
      if (phoneInput) {
        ValidateApi.validateEmailPhone({
          email: null,
          phone: phoneInput,
          flow: OrderFlowType.DashboardMissingBuyerPhone,
        });
      }

      ContractApi.saveBuyerPhone(props.contract.id, phoneInput).then((success) => {
        props.onSave(success, props.contract.id);
        fireGAEvent(DASHBOARD_ACTION_MISSING_BUYER_PHONE(represents));
      });
    }
    setPhoneErrorMsg(err);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const validateContactPhone = (phoneInput: string): string => {
    return validatePhoneInput(phoneInput);
  };

  useEffect(() => {
    ContractApi.getContractDetails([props.contract.id], true, true).then((fetchedContracts) => {
      if (fetchedContracts[0]) {
        setRepresents(fetchedContracts[0].detail.initiatingOfficeAgent.represents);
        const buyer = fetchedContracts[0].detail.customers.find(
          (customer) => customer.type === 'BUY',
        );
        if (buyer.phones?.number) {
          setPhoneInput(buyer.phones.number);
        }
      }
    });
  }, []);

  return (
    <Panel
      border="1"
      borderColor="gray-300"
      rounded="sm"
      shadow={true}
      className="rounded bg-white shadow-1 border-2 mb-4 -mt-4"
    >
      <div className="px-4 py-2">
        <REText variant="heading-05" className="mt-2 justify-center">
          Buyer Information
        </REText>
        <div className="w-full mt-2">
          <PhoneInput
            formField={true}
            id="missing-buyer-phone"
            label="Phone number"
            value={phoneInput}
            onChangeValue={(phone) => setPhoneInput(phone)}
            validateValue={validateContactPhone}
            error={phoneErrorMsg}
            onClearError={() => setPhoneErrorMsg('')}
            onError={(error) => setPhoneErrorMsg(error)}
          />
        </div>

        <div className="flex flex-row justify-end mt-3 -mr-4">
          <Button
            className="mr-4"
            id="button-cancel"
            label="Cancel"
            variant="ghost"
            size="medium"
            onClick={handleCancel}
          />
          <Button
            className="mr-4"
            id="button-save"
            label="Save"
            variant="filled"
            size="medium"
            color="interactive"
            onClick={handleSave}
          />
        </div>
      </div>
    </Panel>
  );
};

export default AccordionItemMissingBuyerPhone;
