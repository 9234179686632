import { ProgressIndicator } from '@ftdr/blueprint-components-react';
import React from 'react';
import REText from '@components/wrappedBDS/REText';

const LoadingIndicator = () => {
  return (
    <div className="align-center mb-4">
      <ProgressIndicator className="my-5 pt-1" size="large" />
      <REText>Loading...</REText>
    </div>
  );
};

export default LoadingIndicator;
