import React, { RefAttributes } from 'react';
import { Text, TextColorOption } from '@ftdr/blueprint-components-react';
import { CurrentTheme, Theme } from '@app/core/featureToggle';
import { TextProps } from '@ftdr/blueprint-components-react/lib/types/components/text/text.types';
import { TextTemplateKey } from '@ftdr/blueprint-components-react/lib/types/components/types';

const TEXT_DEFAULT_PROPS = {
  [Theme.AhsWebApp]: {},
  [Theme.Ahs2024]: {
    color: "primary" as TextColorOption,
  }
}

const REText = <T extends TextTemplateKey = TextTemplateKey>(props: TextProps<T> & RefAttributes<HTMLHeadingElement | HTMLDivElement>) => {

  const newProps = {...TEXT_DEFAULT_PROPS[CurrentTheme()], ...props};

  return (
    <Text {...newProps}>
      { newProps.children }
    </Text>
  )
}

export default REText;