import React from 'react';
import { useThemeContext } from '@ThemeContext';
import { classNames } from '@utils';
import { Helmet } from 'react-helmet-async';
// Components
import Header from '@components/general/Header';
import Footer from '@components/general/Footer';

const Layout = (props) => {
  const theme = useThemeContext();
  const noIndex = window.location.hostname.indexOf('staging') > -1 || window.location.hostname.indexOf('test') > -1;

  return (
    <>
      <Helmet>
        {noIndex && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      <div className={classNames(['layout sm:flex sm:flex-col min-h-screen', `theme-${theme}`])}>
        {!props.hideHeader && (
          <Header slug={props.slug} isLoggedIn={props.isLoggedIn} isSticky={props.isHeaderSticky} />
        )}
        <div className={props.containerClassName}>{props.children}</div>
        {!props.hideFooter && <Footer />}
      </div>
    </>
  );
};

export default Layout;
