import React from 'react';
import { useNavigate } from 'react-router-dom';
import Template from '@templates/office/OfficesTemplate';
import Layout from '@components/layout/Layout';
import { ContentBox } from '@components/layout/ContentBox';

const Offices = () => {
  return (
    <Layout slug="offices" isLoggedIn={true}>
      <ContentBox title="My Offices">
        <Template />
      </ContentBox>
    </Layout>
  );
};

export default Offices;
