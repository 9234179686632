import React from 'react';
import { ZestyWYSIWYGField } from '@apis/models/zesty.api.model';
import { IconDoc, Link } from '@ftdr/blueprint-components-react';
import CardCustom, { CardCustomSize } from '@components/card/CardCustom';
import { CardTopStripType } from '@components/card/CardTopStrip';
import { fireGAEvent } from '@app/core/tracking.service';
import { DASHBOARD_MARKETING_MATERIAL_LINK_EVENT } from '@constants/ga-events.constants';
import { removeTags } from '../../helpers/utils';
import REText from '@components/wrappedBDS/REText';

interface CardMarketingArticleProps {
  id: string;
  image: string;
  category: string;
  title: ZestyWYSIWYGField;
  content: ZestyWYSIWYGField;
  linkText: string;
  link: string;
}

const CardMarketingArticle: React.FC<CardMarketingArticleProps> = (props) => {
  return (
    <CardCustom
      id={props.id}
      topStripType={CardTopStripType.None}
      size={CardCustomSize.DashboardFull}
      padding="none"
    >
      <div className="flex flex-col md:flex-row">
        {props.image && (
          <div id={`${props.id}--image`} className="flex-1 bg-gray-300">
            <img src={props.image} className="sm-max:w-full md:h-full object-cover object-center" />
          </div>
        )}
        <div className="md:w-1/2 flex flex-col justify-center space-y-4 p-6">
          <REText id={`${props.id}--category`} className="underline uppercase text-gray">
            {props.category}
          </REText>
          <REText
            id={`${props.id}--title`}
            className="zesty-html my-0"
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
          <REText
            id={`${props.id}--content`}
            className="zesty-html"
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
          {props.link && (
            <Link
              id={`${props.id}--link`}
              href={props.link}
              target="_blank"
              startIcon={<IconDoc />}
              color="interactive"
              className="font-bold"
              onClick={(e) => {
                e.stopPropagation();
                fireGAEvent(DASHBOARD_MARKETING_MATERIAL_LINK_EVENT(removeTags(props.title)));
              }}
            >
              {props.linkText || props.link}
            </Link>
          )}
        </div>
      </div>
    </CardCustom>
  );
};

export default CardMarketingArticle;
