import React from 'react';
import { Panel } from '@ftdr/blueprint-components-react';
import { ZestyWYSIWYGField } from '@apis/models/zesty.api.model';
import { classNames } from '@utils';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

export interface ContentBoxProps {
  title?: string;
  titleMessage?: any;
  titleRightContent?: JSX.Element;
  subtitle?: any;
  style?: any;
  className?: any;
  subtitleHtml?: ZestyWYSIWYGField | string;
  leftContent?: any;
  leftContentSticky?: boolean;
  headerFullWidth?: boolean;

  /* when set, the container width is applied individually to the header and subheader only, leaving content alone.
   *  This is to allow setting a full width page on the content.
   *  If the content needs to apply the container width, use the ContentBoxContainer component */
  applyContainerWidthToHeaderOnly?: boolean;
}

export const ContentBox: React.FC<ContentBoxProps> = (props) => {
  const containerWidthClassNames = IsTheme(Theme.Ahs2024)
    ? 'mx-4 xs:mx-4 sm:mx-4 md:mx-4 lg:mx-4'
    : 'lg:px-6 md:px-6 px-2 min-w-80 max-w-xxl mx-auto';

  const subtitleClassNames = classNames([
    props.applyContainerWidthToHeaderOnly && containerWidthClassNames,
    `${IsTheme(Theme.Ahs2024) ? '' : 'mx-auto'} max-w-xxl mt-4`,
  ]);

  return (
    <div
      className={classNames([
        !props.applyContainerWidthToHeaderOnly && containerWidthClassNames,
        'lg:py-12 md:py-12 py-7 flex',
        props.className,
      ])}
      style={props.style}
    >
      <div
        className={`float-left h-full mt-17 ${props.leftContentSticky && 'sticky-container-top'}`}
      >
        {props.leftContent}
      </div>
      <div className="w-full">
        <div
          className={classNames([
            props.applyContainerWidthToHeaderOnly && containerWidthClassNames,
            !props.headerFullWidth && 'max-w-xxl',
            'w-full mx-auto',
            `flex flex-col md:flex-row space-y-6 md:space-y-0 justify-between md:items-center ${IsTheme(Theme.Ahs2024) ? 'ml-0' : ''}`,
          ])}
        >
          {props.title && <REText variant="heading-03">{props.title}</REText>}
          {props.titleRightContent && <div className="md:max-w-3/4">{props.titleRightContent}</div>}
        </div>
        {props.subtitle && !props.subtitleHtml && (
          <REText className={subtitleClassNames} variant="body-short">
            {props.subtitle}
          </REText>
        )}
        {props.subtitleHtml && (
          <REText
            className={classNames([subtitleClassNames, 'zesty-html'])}
            variant="body-short"
            dangerouslySetInnerHTML={{ __html: props.subtitleHtml }}
          />
        )}
        {props.titleMessage && (
          <Panel
            backgroundColor={IsTheme(Theme.Ahs2024) ? 'accent-500' : 'interactive-50'}
            rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'md'}
            borderColor="gray-300"
            className="mt-4"
            id="my-quotes-banner"
            shadow={!IsTheme(Theme.Ahs2024)}
          >
            <REText id="my-quotes-banner-text">{props.titleMessage}</REText>
          </Panel>
        )}
        <div className={props.title ? 'mt-8' : ''}>{props.children}</div>
      </div>
    </div>
  );
};
