import { Office, WarrantylinkAgreement } from '@apis/models';
import { getBrand } from '@helpers/brand.utils';
import { downloadPDFViaURL } from '@helpers/utils';
import React, { useEffect, useState } from 'react';
import { fireGAEvent } from '@app/core/tracking.service';
import { WLK_AGREEMENT_DOWNLOAD } from '@constants/ga-events.constants';
import { Button, IconDownload, Input } from '@ftdr/blueprint-components-react';
import { useWindowSize } from 'react-use';
import REText from '@components/wrappedBDS/REText';

interface Props {
  id: string;
  onAccept: (signerName: string, signerTitle: string) => void;
  onDecline: (signerName: string, signerTitle: string) => void;
  office: Office;
  agreement: WarrantylinkAgreement;
  heading: string;
  isActive: boolean;
}

const WLK_AGREEMENT_PDF_URLS = {
  AHS_MSA: '/statics/ahs/pdfs/Master-Service-Agreement.pdf',
  AHS_SOW: '/statics/ahs/pdfs/Statement-of-Work.pdf',
  HSA_MSA: '/statics/hsa/pdfs/Master-Service-Agreement.pdf',
  HSA_SOW: '/statics/hsa/pdfs/Statement-of-Work.pdf',
};

const SignWlkAgreement: React.FC<Props> = (props) => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(width < 480);

  const [loading, setLoading] = useState(false);
  const [pdfURL, setPdfURL] = useState('');
  const [canSave, setCanSave] = useState(false);

  const [disableSignActions, setDisableSignActions] = useState(true);

  const [signerName, setSignerName] = useState('');
  const [signerTitle, setSignerTitle] = useState('');
  const [signerNameError, setSignerNameError] = useState('');
  const [signerTitleError, setSignerTitleError] = useState('');

  useEffect(() => {
    setIsMobile(width < 480);
  }, [width]);

  /** Restores the state back to its default values */
  const resetState = (): void => {
    setLoading(false);
    setPdfURL('');
    setCanSave(false);
    setDisableSignActions(true);
  };

  /** Initial load */
  useEffect(() => {
    resetState();
  }, []);

  /** When drawer opens or closes, refresh view */
  useEffect(() => {
    resetState();

    if (props.isActive) {
      setLoading(true);

      if (props.agreement) {
        // Load agreement PDF URL
        const agreementURL = getAgreementURLByType(props.agreement.type);
        if (agreementURL) {
          setPdfURL(agreementURL);
        } else {
          setCanSave(false);
        }

        // Check on enabling/disabling buttons
        if (props.agreement.status === 'Pending') {
          setDisableSignActions(false);
        }
      }

      setLoading(false);
    }
  }, [props.isActive]);

  /** Equivalent to ComponentDidUpdate */
  useEffect(() => {
    validateFormFields();
  }, null);

  const downloadPDF = () => {
    if (pdfURL) {
      const filepath = pdfURL.split('/');
      const filename = filepath[filepath.length - 1];
      downloadPDFViaURL(pdfURL, filename);
      fireGAEvent(WLK_AGREEMENT_DOWNLOAD(props.office, props.agreement));
    }
  };

  const getAgreementURLByType = (agreementType, brand = getBrand()): string => {
    const key = `${brand}_${agreementType}`.toUpperCase();
    return WLK_AGREEMENT_PDF_URLS[key];
  };

  const validateFormFields = () => {
    const isValid: boolean = !(!!signerNameError || !!signerTitleError);
    setCanSave(isValid && !!signerName && !!signerTitle);
  };

  const handleBlur = (editField: string) => {
    let fieldError: string = '';
    let isError: boolean = false;
    switch (editField) {
      case 'name':
        isError = signerName.length <= 0;
        fieldError = isError ? 'Name Required' : '';
        setSignerNameError(fieldError);
        break;
      case 'title':
        isError = signerTitle.length <= 0;
        fieldError = isError ? 'Title Required' : '';
        setSignerTitleError(fieldError);
        break;
      default:
        console.error('Error: Invalid field: ', editField);
        break;
    }
  };

  const handleDecline = () => {
    props.onDecline(signerName, signerTitle);
  };

  const handleAccept = () => {
    props.onAccept(signerName, signerTitle);
  };

  return (
    <div className="flex flex-col h-full sm:px-4" style={{ maxWidth: '54 rem' }}>
      {/* This empty div is here so the drawer has an initial width without
            the rest of the content being cut off on the right. */}
      <div style={{ width: '43rem' }} />

      {/* Header */}
      <div className="flex-none w-full">
        <div className="py-4">
          <REText className="float-left" variant="heading-03">
            {props.heading}
          </REText>
          {!isMobile && (
            <Button
              id="agreement-download"
              className="float-right"
              size="medium"
              label="Download PDF"
              endIcon={<IconDownload />}
              variant="ghost"
              onClick={downloadPDF}
            />
          )}
        </div>
      </div>

      {/* PDF */}
      <div className="h-full">
        <div id="pdfViewer" className="w-full h-full my-4 bg-gray-300 rounded">
          {loading ? (
            <p>loading PDF...</p>
          ) : !pdfURL ? (
            <p>Unable to load PDF</p>
          ) : (
            <object type="application/pdf" data={pdfURL} className="w-full h-full">
              <embed type="application/pdf" src={pdfURL} />
            </object>
          )}
        </div>
      </div>

      {/* CTAs */}
      <div className="flex-none mt-8">
        {!disableSignActions && (
          <>
            <div className="flex flex-row w-full">
              <div className="float-left w-1/2 pr-2">
                <Input
                  formField
                  required
                  id="input-signer-name"
                  type="text"
                  name="signer"
                  label="Name"
                  placeholder="Enter"
                  value={signerName}
                  error={signerNameError}
                  onChange={(e) => setSignerName(e.target.value)}
                  onBlur={() => handleBlur('name')}
                />
              </div>
              <div className="float-right w-1/2 pl-2">
                <Input
                  formField
                  required
                  id="input-signer-title"
                  type="text"
                  name="title"
                  label="Title"
                  placeholder="Enter your title..."
                  value={signerTitle}
                  error={signerTitleError}
                  onChange={(e) => setSignerTitle(e.target.value)}
                  onBlur={() => handleBlur('title')}
                />
              </div>
            </div>
            <div className={isMobile ? 'flex-col mt-6' : 'mt-6 float-right flex flex-row-reverse'}>
              <Button
                id="agreement-accept"
                className={isMobile ? 'w-full mt-4' : ''}
                size="medium"
                disabled={!canSave}
                label="Accept"
                variant="filled"
                onClick={handleAccept}
              />
              <Button
                id="agreement-decline"
                className={isMobile ? 'w-full mt-4' : 'mr-4'}
                size="medium"
                disabled={!canSave}
                label="Decline"
                variant="outlined"
                onClick={handleDecline}
              />
              {isMobile && (
                <Button
                  id="agreement-download"
                  className="w-full mt-4"
                  size="medium"
                  label="Download PDF"
                  endIcon={<IconDownload />}
                  variant="ghost"
                  onClick={downloadPDF}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignWlkAgreement;
