import React from 'react';
import REText from '@components/wrappedBDS/REText';
import { TypographicVariants } from '@ftdr/blueprint-components-react';

export interface TextSubHeadingProps {
  id: string;
  label?: string;
  caption?: string;
  labelVariant?: TypographicVariants;
  captionVariant?: TypographicVariants;
}

export const TextSubHeading: React.FC<TextSubHeadingProps> = (props) => {
  return (
    <div className="flex flex-nowrap flex-row items-center space-x-2 justify-start" id={props.id}>
      {props.label && (
        <REText variant={props.labelVariant ?? 'label'} id={`${props.id}--label`}>
          {props.label}
        </REText>
      )}
      {props.caption && (
        <REText variant={props.captionVariant ?? 'caption'} id={`${props.id}--caption`}>
          {props.caption}
        </REText>
      )}
    </div>
  );
};
