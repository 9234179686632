import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import * as AutoSuggestActions from '../../services/autosuggest/autosuggest';
import Autosuggest from 'react-autosuggest';
import { AgentSearchRecord } from '@apis/models';
import { Input, IconSearch } from '@ftdr/blueprint-components-react';
import classNames from 'classnames';
import { getFormInputErrorId } from '@storybook/addon-links';
import { filterSuggestionsListWithoutActions } from '@services/autosuggest/autosuggest';

// TODO: ARE-5132 Should be cleaned up, renamed, typed, etc.
export interface AgentAutosuggestProps {
  id?: any;
  idPrefix: string;
  agentlist?: AgentSearchRecord[]; // The suggestion list, but is used for filtering (full list)
  typeAheadAddress?: AgentSearchRecord[]; // The suggestion list, used for displaying (limited list after filtering)
  setTypeAheadAddress?: (val: any) => void;
  changeSelectedAgent?: (val: any) => void;
  setValues?: (val: any) => void;
  values?: any;
  selectedAgent?: any;
  placeholder?: string;
  agentHelpText?: string;
  setAgentHelpText?: (val: any) => void;
  agentOffice?: any;
  setIsAddAgentModalActive?: any;
  hasNotApplicableOption?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  addAgentEnabled?: boolean;
  selection?: null | string;
  touched?: boolean;
  setTouched?: (value: boolean) => void;
  buttonText?: string;
  subText?: string;
  noResultsFoundHelperText?: string;
}

interface AgentAutosuggestState {
  suggestions: AgentSearchRecord[];
  value?: string[];
}

class AgentAutosuggest extends React.Component<AgentAutosuggestProps, AgentAutosuggestState> {
  private inputRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
    };
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.updateSuggestionsList = this.updateSuggestionsList.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<AgentAutosuggestProps>) {
    if (
      prevProps.typeAheadAddress !== this.props.typeAheadAddress ||
      prevProps.hasNotApplicableOption !== this.props.hasNotApplicableOption
    ) {
      this.updateSuggestionsList();
    }
  }

  /** Refresh the suggestions list state with NA option */
  updateSuggestionsList() {
    let suggestions: AgentSearchRecord[] = AutoSuggestActions.getSuggestions(
      this.props.typeAheadAddress,
      this.props.hasNotApplicableOption,
    );

    // de-duplicates agents from the list in event that the same agent by agent id is in the list.
    // the latest indexed agent is used instead, as we believe this is the latest updated agent in the list
    suggestions = suggestions.reduce<AgentSearchRecord[]>((acc, suggestion) => {
      const idx = acc.findIndex((a) => a.realEstateAgentID === suggestion.realEstateAgentID);
      if (idx > -1) {
        acc[idx] = suggestion;
        return acc;
      } else {
        return [...acc, suggestion];
      }
    }, []);

    this.setState({ suggestions });
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({ value });
    AutoSuggestActions.onFetchSuggestions(
      value,
      this.props.agentlist,
      this.props.setTypeAheadAddress,
    );
  }

  onSuggestionsClearRequested() {
    AutoSuggestActions.onClearSuggestions(this.props.setTypeAheadAddress);
  }

  onSuggestionSelected(e, option) {
    AutoSuggestActions.selectSuggestion(
      e,
      option,
      this.props.changeSelectedAgent,
      this.props.setValues,
      this.props.values,
    );
  }

  onInputChange(e) {
    this.props.setTouched?.(true);
    AutoSuggestActions.onSuggestionTextChange(
      e,
      this.props.changeSelectedAgent,
      this.props.agentlist,
      this.props.setTypeAheadAddress,
    );
    AutoSuggestActions.clearSelectionValues(this.props.values, this.props.setValues);
  }

  suggestionsIsEmpty(): boolean {
    return filterSuggestionsListWithoutActions(this.state.suggestions).length === 0;
  }

  onRenderSuggestionsContainer(e) {
    return AutoSuggestActions.renderSuggestionsContainer(
      e,
      this.state.suggestions,
      this.props?.setIsAddAgentModalActive,
      this.props.changeSelectedAgent,
      this.props.addAgentEnabled,
      this.props.buttonText,
      this.props.subText,
      this.props.noResultsFoundHelperText,
      this.suggestionsIsEmpty(),
    );
  }

  renderInputComponent = (inputProps) => {
    return (
      <ForwardedInputWithRef
        inputProps={inputProps}
        selection={this.props.selection}
        touched={this.props.touched}
        {...this.props}
      />
    );
  };

  render() {
    return (
      <div className="relative">
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={(e) => this.onSuggestionsFetchRequested(e)}
          onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
          onSuggestionSelected={(e, option) => this.onSuggestionSelected(e, option)}
          getSuggestionValue={() => AutoSuggestActions.getSuggestion()}
          renderSuggestion={(suggestion, opt) =>
            AutoSuggestActions.renderSuggestion(
              suggestion,
              opt,
              this.props.agentOffice,
              this.state.value,
              this.props.idPrefix,
            )
          }
          renderSuggestionsContainer={(e) => this.onRenderSuggestionsContainer(e)}
          renderInputComponent={this.renderInputComponent}
          inputProps={{
            value: this.props.selectedAgent || '',
            onChange: (e) => this.onInputChange(e),
          }}
          shouldRenderSuggestions={() => {
            if (!this.props.agentlist) return false;
            if (this.props.selection && !this.props.touched) return false;
            return true;
          }}
        />
      </div>
    );
  }
}

export default AgentAutosuggest;

/** due to react-autosuggest, it requires passing inputRef in a specific way.
 * logic taken from FormInput partially.
 * In long term, we should switch over to BDS once they have optimized their Select component.
 *
 * */
function InputWithRef({ inputProps, ...props }, ref) {
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  useEffect(() => {
    if (props.selection != null && props.selection !== '') {
      if (inputRef.current == null) {
        return;
      }
      inputRef.current.blur();
    }
  }, [props.selection]);

  return (
    <Input
      formField={true}
      formFieldMessageId={getFormInputErrorId('wb_selectedInitAgent')}
      id={props.id || 'wb_selectedInitAgent'}
      name="agentInput"
      label={props.placeholder}
      required={props.isRequired}
      value={props.selectedAgent}
      error={!props.selectedAgent?.trim() && props.agentHelpText}
      autoComplete="off"
      disabled={props.isDisabled}
      startEnhancer={<IconSearch />}
      inputElementRenderer={(inputElementRendererProps) => {
        const { ref, ...others } = inputElementRendererProps;
        return (
          <input
            ref={inputRef}
            {...others}
            {...inputProps}
            className={classNames([
              // requires passing both className as such to apply classes correctly
              inputElementRendererProps['className'],
              inputProps['className'],
            ])}
            placeholder={props.isDisabled ? '' : 'Search by Agent Name'}
            style={{ padding: '11px 24px 11px 48px' }}
          />
        );
      }}
    />
  );
}

const ForwardedInputWithRef = forwardRef(InputWithRef);
