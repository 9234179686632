import React, { useState } from 'react';
import CardCustom, { CardCustomSize } from '@components/card/CardCustom';
import { CardTopStripType } from '@components/card/CardTopStrip';
import { BDSMainColor } from '@constants/bds.constants';
import {
  Button,
  IconRefreshDouble,
  ProgressIndicator,
} from '@ftdr/blueprint-components-react';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

export enum DashboardActionType {
  DoesNotNeedAttention,
  NeedsAttention,
  ForReview,
  NoLabel,
}

const DASHBOARD_ACTION_DETAILS = {
  [DashboardActionType.DoesNotNeedAttention]: {
    title: 'You should not see this!',
    color: BDSMainColor.Gray,
  },
  [DashboardActionType.NeedsAttention]: {
    title: 'Action Needed',
    color: BDSMainColor.Secondary,
  },
  [DashboardActionType.ForReview]: {
    title: 'For Review',
    color: BDSMainColor.Primary,
  },
  [DashboardActionType.NoLabel]: {
    title: '',
    color: BDSMainColor.Primary,
  },
};

export interface CardDashboardActionProps {
  isManualFetch: boolean;
  fetchCount: () => void;
  type: DashboardActionType;
  heading: string;
  subHeading: string;
  action: any;
}

const CardDashboardAction = (props) => {
  // TODO Type props.
  const [isLoading, setIsLoading] = useState(props.heading === null && !props.isManualFetch);

  const handleManualFetch = () => {
    setIsLoading(true);
    if (props.fetchCount) props.fetchCount();
  };

  return (
    <CardCustom
      size={CardCustomSize.DashboardSmall}
      topStripType={
        props.type === DashboardActionType.DoesNotNeedAttention ||
        props.type === DashboardActionType.NoLabel
          ? CardTopStripType.Strip
          : CardTopStripType.Badge
      }
      topStripColor={DASHBOARD_ACTION_DETAILS[props.type].color}
      topStripTitle={DASHBOARD_ACTION_DETAILS[props.type].title}
    >
      <div className="flex flex-col align-center h-full">
        {props.heading ? (
          <REText variant="heading-01" className={`mt-8 pt-2 ${IsTheme(Theme.Ahs2024) ? "mb-4" : ""}`}>
            {props.heading}
          </REText>
        ) : isLoading ? (
          <ProgressIndicator className="my-5 pt-1" size="large" />
        ) : (
          <Button
            startIcon={<IconRefreshDouble />}
            className="mb-5 mt-10"
            size="small"
            variant="ghost"
            onClick={handleManualFetch}
            label="Fetch Count"
          />
        )}
        <REText variant="heading-04" className="mt-1 mb-0 flex-grow">
          {props.subHeading}
        </REText>
        <span className="mb-5">{props.action}</span>
      </div>
    </CardCustom>
  );
};

export default CardDashboardAction;
