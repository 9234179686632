import React, { useContext, useEffect, useMemo, useState } from 'react';
import Drawer from '@components/drawer/Drawer';
import DrawerMakePaymentStripe from '@components/drawer/DrawerMakePaymentStripe';
import DrawerExtendListing from '@components/drawer/DrawerExtendListing';
import DrawerCancelOrder from '@components/drawer/DrawerCancelOrder';
import DrawerNotifications from '@components/drawer/DrawerNotifications';
import CardNewOrderAgentInfo from '@components/card/CardNewOrderAgentInfo';
import CardNewOrderPropertyDetails from '@components/card/CardNewOrderPropertyDetails';
import CardNewOrderBuyerInfo from '@components/card/CardNewOrderBuyerInfo';
import CardNewOrderSellerInfo from '@components/card/CardNewOrderSellerInfo';
import CardNewOrderClosingInfo from '@components/card/CardNewOrderClosingInfo';
import ModalEditHistory from '@components/modal/ModalEditHistory';
import DrawerDocument, { downloadFile, getContractPDFURL } from '@components/drawer/DrawerDocument';
import { classNames, isMobileView } from '@utils';
import usaStates from '@constants/us-states';
import { searchCityStateByZip } from '@services/zip.service';
import {
  Contract,
  ContractBalance,
  ContractDateType,
  ContractDocumentType,
  ContractPlanCoverages,
  ContractPricing,
  MakePaymentResponse,
  Office,
  REOrder,
  UpsertOrderCustomer,
} from '@apis/models';
import { dwellingTypeDictionary, Permissions, UserRoleType } from '@constants/dictionaries';
import {
  addressToString,
  getAgeByYearBuilt,
  getSquareFootageRangeValue,
  isNewConstructionByYearBuilt,
} from '@services/helpers';
import {
  cleanPhone,
  formatDate,
  formatDateFromString,
  formatDateToISO8601,
  parseDate,
  toTitleCase,
} from '@app/helpers/utils';
import { getBrand } from '@helpers/brand.utils';
import { Brand } from '@constants/brands';
import Skeleton from 'react-loading-skeleton';
import ProfileContext from '@app/context/ProfileContext';
import { CardViewOrderDates } from '@components/card/CardViewOrderDates';
import isEmpty from 'lodash/isEmpty';
import { hasContractPermissions } from '@helpers/permissions.utils';
import {
  breakdownContractCustomers,
  canEditAgentInfo,
  getContractRecipientsForNotifications,
  getOrderIDFromContract,
  GetRequestServiceUrl,
  getResidenceType,
  getSquareFootage,
  getSquareFootageFromOptionName,
  hasBuyerInfo,
  isAgentOfficeFSBO,
  isBuyerInfoRequired,
  isClosingInfoRequired,
  isRequiredSellerInfoPresent,
  isROProduct,
  isSellerContactInfoRequired,
  isSellerNameRequired,
  normalizeSquareFootageValue,
  OrderFlowType,
  shouldClosingInfoClearBuyerInfo,
  shouldReturnToBuyerInfo,
  shouldReturnToClosingInfo,
  toPlanCoverageValues,
} from '@helpers/order.utils';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { NOT_APPLICABLE_OPTION } from '@services/autosuggest/autosuggest';
import ContractApi, { ContractApiSuppressErrors } from '@apis/contract.api';
import ProfileModel from '@app/models/profile.model';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  MILITARY_DISCOUNT_REMOVED,
  ORDER__BUYER_INFO_ADDED,
  ORDER__BUYER_SELLER_CLOSING_INFO_STEP,
  ORDER__PAYMENT_CLICK,
  ORDER__PROPERTY_DETAILS_UPDATE,
  ORDER__REQUEST_SERVICE,
  ORDER__UPDATE_PLAN_STEP,
  RENEWALS_SELECTED_SEND_RENEWAL_EMAIL_DETAILS_DRAWER,
} from '@constants/ga-events.constants';
import ModalRemoveClosingInfo from '@components/modal/ModalRemoveClosingInfo';
import useGlobalOverlaySpinner from '@components/spinner/GlobalOverlaySpinner';
import { DEFAULT_CUSTOMER_PHONE_TYPE } from '@constants/formField-constants';
import msgs from '@app/locales/en';
import OrderEditHistoryTable from '@components/card/OrderEditHistoryTable';
import {
  ACTION_UPDATE,
  AGENT_LABELS,
  CLOSING_LABEL,
  getOriginatorName,
  MILITARY_DISCOUNT,
} from '@constants/newOrder-constants';
import { isInternalUser } from '@helpers/profile.utils';
import { Button, ContextMenu, IconDoc, IconNavArrowDown } from '@ftdr/blueprint-components-react';
import paths from '@constants/paths';
import { useNavigate } from 'react-router-dom';
import CardNewOrderPlansCoverage, {
  PlansCoverageValues,
  PropertyDetails,
} from '@components/card/CardNewOrderPlansCoverage';
import deepLinkService from '@services/deepLink/deepLink.service';
import { category, deepLinkImplementations } from '@services/deepLink/deepLink.models';
import realestatequoteApi from '@apis/realestatequote.api';
import ValidateApi from '@apis/validate.api';
import DrawerSendRenewal from '@components/drawer/DrawerSendRenewal';
import { Features, IsFeatureEnabled, IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

interface DocumentItemProps {
  contractId: string;
  isFirst: boolean;
  title: string;
  onClick: (docType: ContractDocumentType) => void;
  isShown: () => boolean;
  docType: ContractDocumentType;
}

const DocumentItem: React.FC<DocumentItemProps> = (props) => {
  const isMobile = isMobileView();
  const [pdfURL, setPdfURL] = useState('');
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);

  const btnText = isMobile ? 'Download' : 'View';

  const downloadPDF = () => {
    if (pdfURL) {
      downloadFile(pdfURL, `${props.docType}-${props.contractId}.pdf`);
    }
  };

  const handleBtnClick = () => {
    if (!isMobile) {
      return props.onClick(props.docType);
    }

    // download cached file if it is already downloaded
    if (pdfURL) {
      return downloadPDF();
    }

    setIsDownloadingFile(true);
    getContractPDFURL(props.contractId, props.docType)
      .then((url) => {
        setPdfURL(url);
      })
      .finally(() => {
        setIsDownloadingFile(false);
      });
  };

  useEffect(() => {
    downloadPDF();
  }, [pdfURL]);

  return (
    <>
      {props.isShown() && (
        <div
          className={classNames([
            'flex justify-between items-end items-center',
            IsTheme(Theme.Ahs2024) ? '' : 'py-2',
            !props.isFirst && !IsTheme(Theme.Ahs2024) && 'border-t',
          ])}
        >
          <div>
            <REText variant="helper-text" className="text-xs">
              {props.title}
            </REText>
          </div>

          <div>
            <Button
              label={isDownloadingFile ? 'Downloading...' : btnText}
              onClick={handleBtnClick}
              disabled={isDownloadingFile}
              variant="ghost"
              size="small"
            />
          </div>
        </div>
      )}
    </>
  );
};

interface DrawerOrderProps {
  userDetails: ProfileModel;
  userOffices: Office[];

  id?: string;
  contract: Contract;
  selectedREOrder: REOrder;
  realEstateStatus: string;
  isActive: boolean;
  onClose: () => void;

  /** Triggers a refresh on the selected contract */
  onUpdate: (contract: Contract) => void;
}

const DrawerOrder: React.FC<DrawerOrderProps> = (props) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [isConfirmRemoveClosingInfoModalActive, setIsConfirmRemoveClosingInfoModalActive] =
    useState(false);
  const [isDocumentDrawerActive, setIsDocumentDrawerActive] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState<ContractDocumentType>(undefined);
  const [editingClosingInfo, setEditingClosingInfo] = useState(false);
  const [saveDisabledClosing, setSaveDisabledClosing] = useState(false);
  const [editingBuyerInfo, setEditingBuyerInfo] = useState(false);
  const [saveDisabledBuyer, setSaveDisabledBuyer] = useState(false);
  const [delayedPlanSaveData, setDelayedPlanSaveData] = useState<any>({});
  const [isEditingSellerInfo, setIsEditingSellerInfo] = useState<boolean>(false);
  const [isEditingAgentInfo, setIsEditingAgentInfo] = useState<boolean>(false);
  const [isEditingPropertyDetails, setIsEditingPropertyDetails] = useState<boolean>(false);
  const [isEditingPlanAndCoverages, setIsEditingPlanAndCoverages] = useState<boolean>(false);
  const [isPendingRetroactiveSellerInfoCollection, setIsPendingRetroactiveSellerInfoCollection] =
    useState(false);
  const [contractEditHistory, setContractEditHistory] = useState([]);
  const [isMakePaymentDrawerActiveStripe, setIsMakePaymentDrawerActiveStripe] = useState(false);
  const [isExtendListingDrawerActive, setIsExtendListingDrawerActive] = useState(false);
  const [isSendRenewalDrawerActive, setIsSendRenewalDrawerActive] = useState(false);
  const [isCancelOrderDrawerActive, setIsCancelOrderDrawerActive] = useState(false);
  const [contractPaymentBalance, setContractPaymentBalance] = useState<ContractBalance>(null);
  const [isNotificationsDrawerActive, setIsNotificationsDrawerActive] = useState(false);
  const [isPendingPropertyUpdate, setIsPendingPropertyUpdate] = useState(false);
  const [isPastClosingDate, setIsPastClosingDate] = useState(false);
  const [cooperatingAgentCustom, setCooperatingAgentCustom] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  // Buyer and closing are inter-related. Trigger two update calls when necessary
  const [pendingClosingUpdate, setPendingClosingUpdate] = useState<any>(undefined);
  const [pendingBuyerUpdate, setPendingBuyerUpdate] = useState<any>(undefined);
  const { showSpinner } = useGlobalOverlaySpinner();

  // permissions
  const [isFetchingPermissions, setIsFetchingPermissions] = useState(true);
  const [canEditAgent, setCanEditAgent] = useState(false);
  const contractRequiredPermissions = [Permissions.ContractChangeInitiatingOfficeOrAgent];

  // Load contract data to the correct format
  const [contractData, setContractData] = useState(undefined);
  const [contractID, setContractID] = useState<string>(null);

  const [contractPricingData, setContractPricingData] = useState<ContractPricing>(null);
  const [isLoadingPricingData, setIsLoadingPricingData] = useState<boolean>(false);
  const [earnixRuleID, setEarnixRuleID] = useState<number>(null);

  const [contractPlanCoveragesData, setContractPlanCoveragesData] =
    useState<ContractPlanCoverages>(null);
  const [isROProductContract, setIsROProductContract] = useState<boolean>(false);

  const [isLoadingContractPlanCoveragesData, setIsLoadingContractPlanCoveragesData] =
    useState<boolean>(false);
  /* data that has not been applied to the contract yet due to needing more information. */
  const [pendingPlanCoverages, setPendingPlanCoverages] = useState<PlansCoverageValues>(null);
  const [pendingPricingData, setPendingPricingData] = useState<ContractPricing>(null);

  const [quoteEffectiveDate, setQuoteEffectiveDate] = useState<Date>(null);

  const { addErrorToQueue, addWarningToQueue, addSuccessToQueue } = useGlobalAlert();

  const { profile } = useContext(ProfileContext); // Should probably be passed in instead.

  const navigate = useNavigate();
  const [isClearAlert, setIsClearAlert] = useState<boolean>(false);

  const [dlPageCategory, setDlPageCategory] = useState<deepLinkImplementations>();

  const documents: Omit<DocumentItemProps, 'contractId' | 'isFirst'>[] = useMemo(
    () => [
      {
        title: 'Order Confirmation',
        docType: ContractDocumentType.Confirmation,
        onClick: (docType) => {
          setIsDocumentDrawerActive(true);
          setSelectedDocType(docType);
        },
        isShown: () => {
          return true;
        },
      },
      {
        title: 'Invoice',
        docType: ContractDocumentType.Invoice,
        onClick: (docType) => {
          setIsDocumentDrawerActive(true);
          setSelectedDocType(docType);
        },
        isShown: () => {
          // Show if contract has a closing date
          return !!props.contract?.detail.importantDates.projectedClosingDate;
        },
      },
      {
        title: 'Contract',
        docType: ContractDocumentType.Contract,
        onClick: (docType) => {
          setIsDocumentDrawerActive(true);
          setSelectedDocType(docType);
        },
        isShown: () => {
          // Show if contract is active or has seller's coverage
          const isActive = props.contract?.summary?.status === 'A';
          const hasSellersCoverage = contractPlanCoveragesData?.product?.sellersCoverage;
          return isActive || hasSellersCoverage;
        },
      },
    ],
    [props.contract, contractPlanCoveragesData],
  );

  function isUpForRenewalVisible() {
    return (
      ['RealEstateAgent', 'RealEstateAdmin', 'Broker'].includes(profile.roleIDType) &&
      IsFeatureEnabled(Features.RenewalEmails) &&
      getBrand() !== Brand.HSA
    );
  }

  function tooltipActions() {
    const details = props.contract;

    if (!details) return;

    const items: { text: string; onClick: () => void; disabled?: boolean }[] = [
      {
        text: 'Send Renewal Discount',
        onClick: () => {
          fireGAEvent(RENEWALS_SELECTED_SEND_RENEWAL_EMAIL_DETAILS_DRAWER);
          setIsSendRenewalDrawerActive(true);
        },
        disabled: !(
          details.features.upForRenewal &&
          details.features.upForRenewal.canSendPromoEmail &&
          isUpForRenewalVisible()
        ),
      },
      {
        text: 'Make a Payment',
        onClick: () => {
          openPaymentHandler(props.selectedREOrder);
        },
        disabled: !(
          details.features.makePayment &&
          details.features.makePayment.isPayable &&
          !isInternalUser(profile)
        ),
      },
      {
        text: 'Extend Listing',
        onClick: () => {
          setIsExtendListingDrawerActive(true);
        },
        disabled: !(details.features.extendListing && details.features.extendListing.canExtend),
      },
      {
        text: 'Documents',
        onClick: () => {
          setIsNotificationsDrawerActive(true);
        },
        disabled: false,
      },
      {
        text: 'Request Service',
        onClick: () => {
          fireGAEvent(ORDER__REQUEST_SERVICE(details.detail.id));
          window.open(GetRequestServiceUrl(props.selectedREOrder), '_blank');
        },
        disabled: false,
      },
      {
        text: 'WarrantyLink',
        onClick: () => {
          navigate(paths.Warrantylink);
        },
        disabled:
          !details.summary.awaitingWlkSubmission ||
          [UserRoleType.ClosingCompanyAdmin, UserRoleType.ClosingCompanyAgent].includes(
            profile.roleIDType as UserRoleType,
          ),
      },
      {
        text: 'Cancel Order',
        onClick: () => {
          setIsCancelOrderDrawerActive(true);
        },
        disabled: !details.features.cancelListing?.isCancellable,
      },
    ];
    // Remove any items that are disabled. Done this way so we can enhance in future
    // to disable instead of remove, since the ButtonDropdown does not accept disabled as prop value
    // If disabled added to ButtonDropdown, remove map and filter.
    return items.filter((i) => !i.disabled).map((i) => ({ text: i.text, onClick: i.onClick }));
  }

  function getEmailAddresses() {
    if (!props.contract) return {};
    return getContractRecipientsForNotifications(props.contract, profile);
  }

  function getNoticeTypes() {
    if (!props.contract) return {};
    const { projectedClosingDate } = props.contract.detail.importantDates;
    return {
      Confirmation: props.contract.summary && props.contract.summary['status'] == 'L' ? true : true,
      Invoice: !!projectedClosingDate,
    };
  }

  /** if RO is set, displays the RO owner name, the buyer name */
  const [roOwnerName, setROOwnerName] = useState<string>('');

  /** set the contract ID to be used in other places */
  useEffect(() => {
    setContractID(props.contract?.detail.id);
  }, [props.contract]);

  /** reload any contract information on view when contract id changes */
  useEffect(() => {
    reloadContractPricingData();
    reloadContractPlanCoverages();
    reloadQuoteEffectiveDate();
  }, [contractID]);

  useEffect(() => {
    if (props.contract) {
      const { buyer } = breakdownContractCustomers(props.contract);
      const name = toTitleCase(`${buyer?.firstName} ${buyer?.lastName}`.trim());
      const profileName = toTitleCase(`${profile.firstName} ${profile?.lastName}`.trim());
      if (name !== profileName) {
        setROOwnerName(name);
      }
    }
  }, [props.contract]);

  useEffect(() => {
    const { contract } = props;

    // Clear pending updates
    setPendingBuyerUpdate(undefined);
    setPendingClosingUpdate(undefined);

    try {
      // TODO: A lot of the new order cards need to be updated to not be strict to new order. Maybe look into
      // creating a separate 'edit order cards' so we de-couple new order and edit order, or update the props and states
      // to be less focused on New Orders.  Should be done separately.
      if (contract && contract.detail) {
        const tempContractData: any = contractData || {};

        // CardNewOrderAgentInfo
        const { initiatingOfficeAgent } = contract.detail;

        tempContractData.id = contract.detail.id;
        tempContractData.agentInfo = {
          agentRepresents: {
            // This should be specific to the card, not passed in
            buyer: initiatingOfficeAgent?.represents === 'BUYER',
            seller: initiatingOfficeAgent?.represents === 'SELLER',
            both: initiatingOfficeAgent?.represents === 'BOTH',
          },
          agentlist: [], // This is more tied to the card itself and shouldn't be a prop (?)
          initiatingAgent: initiatingOfficeAgent?.agent
            ? `${initiatingOfficeAgent.agent.firstName} ${initiatingOfficeAgent.agent.lastName}`
            : '',
          AgentId: initiatingOfficeAgent?.agent?.realEstateAgentID,
          AgentEmail: initiatingOfficeAgent?.agent?.email,
          AgentPhone: {
            phoneNumber: initiatingOfficeAgent?.agent?.phoneNumbers[0]?.phoneNumber,
          },
          initiatingOffice: initiatingOfficeAgent?.office?.name,
          office: {
            id: initiatingOfficeAgent?.office?.id,
            type: initiatingOfficeAgent?.office?.type,
          },
          representing: initiatingOfficeAgent?.represents,
          agentOffice: {
            franchiseCode: initiatingOfficeAgent?.office?.franchiseCode,
            id: initiatingOfficeAgent?.office?.id,
            type: initiatingOfficeAgent?.office?.type,
          },
          isFSBO: isAgentOfficeFSBO(
            initiatingOfficeAgent?.office?.id,
            initiatingOfficeAgent?.agent?.realEstateAgentID,
          ),
        };

        // CardNewOrderPropertyAddress + CardNewOrderPropertyDetails
        const { property, mlsNumber } = contract.detail;
        tempContractData.propertyAddr = {
          streetAddress: property.streetAddress,
          city: property.city,
          state: property.state,
          zip: property.zip,
          isDisabled: true, // Should not be in contract data
        };

        const listDate = contract.detail.dates.find((d) => d.type === ContractDateType.ListingDate);
        const listDateYear = (listDate && listDate.effective.split('-')[0]) || undefined;

        if (!tempContractData.propertyDetails) {
          // if we just opened the drawer
          tempContractData.propertyDetails = {
            age: getAgeByYearBuilt(+property.yearBuilt, +listDateYear),
            newConstruction: isNewConstructionByYearBuilt(property.yearBuilt, +listDateYear),
            notNewConstruction: !isNewConstructionByYearBuilt(property.yearBuilt, +listDateYear),
            squareFootage: getSquareFootageRangeValue(property.squareFeet), // This should be specific to the card, not passed in as string
            residenceType: dwellingTypeDictionary[property.dwellingType], // This and typeOfResidence matches, no reason to split from what I can see
            typeOfResidence: dwellingTypeDictionary[property.dwellingType],
            propertyID: property && property.ID,
          };
        }

        tempContractData.propertyDetails.mlsNumber = mlsNumber;

        // CardNewOrderBuyerInfo & CardNewOrderSellerInfo
        const { cooperatingOfficeAgent } = contract.detail;
        const { buyer, seller, cobuyer, coseller } = breakdownContractCustomers(contract);

        tempContractData.buyerInfo = {
          buyerInfo: {
            yes: Boolean(buyer),
            no: !buyer,
          },
          alternateAddressFields: buyer && buyer.address.zipCode !== '',
          coBuyerFields: Boolean(cobuyer),
          coBuyerAlternateAddressFields: cobuyer && cobuyer.address.zipCode !== '',

          // used to determine if email/phone is required, based SOLELY on who agent represents and not closing information.
          needEmailOrPhone:
            initiatingOfficeAgent?.represents === 'BUYER' ||
            initiatingOfficeAgent?.represents === 'BOTH',

          // Buyer
          customerId: buyer?.customerId,
          firstName: buyer && buyer.firstName,
          lastName: buyer && buyer.lastName,
          email: buyer && buyer.email && buyer.email.address,
          emailId: buyer?.email?.id, // TODO: Model email as first class resource after domain model normalization
          phone: buyer && buyer.phones && buyer.phones.number,
          phoneType: buyer?.phones?.type, // TODO: Model phone as first class resource after domain model normalization

          streetAddress: buyer && buyer.address.address1,
          unit: buyer && buyer.address.address2,
          city: buyer && buyer.address.cityName,
          state: buyer && buyer.address.state,
          zipCode: buyer && buyer.address.zipCode,
          propertyId: buyer && buyer.address.propertyID,

          // CoBuyer
          coCustomerId: cobuyer?.customerId,
          coCustomerFirstName: cobuyer && cobuyer.firstName,
          coCustomerLastName: cobuyer && cobuyer.lastName,
          coCustomerEmail: cobuyer && cobuyer.email && cobuyer.email.address,
          coCustomerEmailId: cobuyer?.email?.id, // TODO: Model email as first class resource after domain model normalization
          coCustomerPhone: cobuyer && cobuyer.phones && cobuyer.phones.number,
          coCustomerPhoneType: cobuyer?.phones?.type, // TODO: Model phone as first class resource after domain model normalization

          coCustomerStreetAddress: cobuyer && cobuyer.address.address1,
          coCustomerUnit: cobuyer && cobuyer.address.address2,
          coCustomerCity: cobuyer && cobuyer.address.cityName,
          coCustomerState: cobuyer && cobuyer.address.state,
          coCustomerZipCode: cobuyer && cobuyer.address.zipCode,
          coCustomerPropertyId: cobuyer && cobuyer.address.propertyID,

          agentlist: [], // This is more tied to the card itself and shouldn't be a prop (?)
          isDisabled: true,
          office:
            cooperatingOfficeAgent?.represents === 'BUYER'
              ? cooperatingOfficeAgent?.office
              : undefined,
          cooperatingOffice:
            cooperatingOfficeAgent && cooperatingOfficeAgent.represents === 'BUYER'
              ? cooperatingOfficeAgent.office.name
              : '',
          cooperatingAgent:
            cooperatingOfficeAgent && cooperatingOfficeAgent.represents === 'BUYER'
              ? `${cooperatingOfficeAgent.agent && cooperatingOfficeAgent.agent.firstName} ${cooperatingOfficeAgent.agent && cooperatingOfficeAgent.agent.lastName}`
              : '',
          AgentId: cooperatingOfficeAgent?.agent?.realEstateAgentID,
        };

        tempContractData.sellerInfo = {
          sellerInfo: {
            yes: Boolean(seller),
            no: !seller,
          },
          alternateAddressFields: seller && seller.address.zipCode !== '',
          coSeller: Boolean(coseller),
          coSellerFields: Boolean(coseller),
          coSellerAlternateAddressFields: coseller && coseller.address.zipCode !== '',

          // used to determine if email/phone is required, based SOLELY on who agent represents and not closing information.
          needEmailOrPhone:
            initiatingOfficeAgent?.represents === 'SELLER' ||
            initiatingOfficeAgent?.represents === 'BOTH',

          // Seller
          customerId: seller?.customerId,
          firstName: seller && seller.firstName,
          lastName: seller && seller.lastName,
          email: seller && seller.email && seller.email.address,
          emailId: seller?.email?.id, // TODO: Model email as first class resource after domain model normalization
          phone: seller && seller.phones && seller.phones.number,
          phoneType: seller?.phones?.type, // TODO: Model phone as first class resource after domain model normalization

          streetAddress: seller && seller.address.address1,
          unit: seller && seller.address.address2,
          city: seller && seller.address.cityName,
          state: seller && seller.address.state,
          zipCode: seller && seller.address.zipCode,
          propertyId: seller && seller.address.propertyID,

          // CoSeller
          coCustomerId: coseller?.customerId,
          coCustomerFirstName: coseller && coseller.firstName,
          coCustomerLastName: coseller && coseller.lastName,
          coCustomerEmail: coseller && coseller.email && coseller.email.address,
          coCustomerEmailId: coseller?.email?.id, // TODO: Model email as first class resource after domain model normalization
          coCustomerPhone: coseller && coseller.phones && coseller.phones.number,
          coCustomerPhoneType: coseller?.phones?.type, // TODO: Model phone as first class resource after domain model normalization

          coCustomerStreetAddress: coseller && coseller.address.address1,
          coCustomerUnit: coseller && coseller.address.address2,
          coCustomerCity: coseller && coseller.address.cityName,
          coCustomerState: coseller && coseller.address.state,
          coCustomerZipCode: coseller && coseller.address.zipCode,
          coCustomerPropertyId: coseller && coseller.address.propertyID,

          agentlist: [], // This is more tied to the card itself and shouldn't be a prop (?)
          isDisabled: true,
          office:
            cooperatingOfficeAgent?.represents === 'SELLER'
              ? cooperatingOfficeAgent?.office
              : undefined,
          cooperatingOffice:
            cooperatingOfficeAgent && cooperatingOfficeAgent.represents === 'SELLER'
              ? cooperatingOfficeAgent.office.name
              : '',
          cooperatingAgent:
            cooperatingOfficeAgent && cooperatingOfficeAgent.represents === 'SELLER'
              ? `${cooperatingOfficeAgent.agent.firstName} ${cooperatingOfficeAgent.agent.lastName}`
              : '',
          AgentId: cooperatingOfficeAgent?.agent?.realEstateAgentID,
        };

        // CardNewOrderClosingInfo
        const { closingFileNumber, closingOfficeAgent } = contract.detail;
        const { projectedClosingDate } = contract.detail.importantDates;

        tempContractData.closingInfo = {
          closingFileNumber,
          closingTitleCompany: closingOfficeAgent ? closingOfficeAgent.office.name : '',
          closingTitleAgent:
            closingOfficeAgent && closingOfficeAgent.agent
              ? `${closingOfficeAgent.agent.firstName} ${closingOfficeAgent.agent.lastName}`
              : '',
          titleCompany: {
            // This should be part of the card itself
            yes: Boolean(closingOfficeAgent),
            no: !closingOfficeAgent,
          },
          AgentId: closingOfficeAgent?.agent?.realEstateAgentID,
          AgentEmail: closingOfficeAgent?.agent?.email,
          closingOffice: closingOfficeAgent?.office,
          office: closingOfficeAgent?.office,
          offices: [], // meh
          dateUnknown: !projectedClosingDate,
          projectedClosingDate: projectedClosingDate || '',
          userDetails: {
            firstName: profile && profile.firstName,
            lastName: profile && profile.lastName,
          },
        };

        // If we have an office but no agent, then consider as 'not applicable'
        if (closingOfficeAgent?.office && !closingOfficeAgent?.agent) {
          tempContractData.closingInfo.closingTitleAgent = `${NOT_APPLICABLE_OPTION.firstName} ${NOT_APPLICABLE_OPTION.lastName}`;
        }

        setContractData(tempContractData);
        return;
      }
    } catch (e) {
      console.error(msgs.FAILED_TO_LOAD_CONTRACT.message, e);
      addErrorToQueue(msgs.FAILED_TO_LOAD_CONTRACT);
    }
  }, [props.contract]);

  useEffect(() => {
    if (isPendingRetroactiveSellerInfoCollection && !isEmpty(delayedPlanSaveData))
      return setIsEditingSellerInfo(true);

    if (!isPendingRetroactiveSellerInfoCollection && !isEmpty(delayedPlanSaveData)) {
      savePlansAndCoverages(delayedPlanSaveData).then(() => {
        setDelayedPlanSaveData({});
      });
    }
  }, [delayedPlanSaveData, isPendingRetroactiveSellerInfoCollection]);

  useEffect(() => {
    ContractApi.getContractEditHistory(props.contract.detail.id).then((res) => {
      if (res?.length) {
        console.log(res);
        setContractEditHistory(res);
      }
    });

    // Handle deep link setup per implementation.
    let dlCategory = deepLinkService?.actions?.deepLinkCategory;
    if (!dlCategory) {
      dlCategory = null;
    }

    if (dlCategory) {
      switch (dlCategory) {
        case category.buyerInfo: {
          handleDlBuyerInfoInit();
          break;
        }
        default: {
          console.info('invalid deep link category: ', dlCategory);
          deepLinkService.reset();
          setDlPageCategory(null);
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    hasContractPermissions(contractRequiredPermissions).then((result) => {
      setCanEditAgent(result);
      setIsFetchingPermissions(false);
    });
  }, [props.userDetails]);

  // handleDlBuyerInfoInit: initializes the deep link page struct based on the given category
  const handleDlBuyerInfoInit = () => {
    deepLinkService.reset();
    setDlPageCategory({
      myOrders: {
        contractDrawer: {
          buyerInfo: deepLinkService.GetDeepLinkString(),
        },
      },
    });
    setEditingBuyerInfo(true);
  };

  /** given the contract, retrieve the property details of the contract */
  const getPropertyDetailsFromContract = (contract: Contract): PropertyDetails => {
    return (
      contract && {
        residenceType: getResidenceType(contract.detail?.property?.dwellingType, true),
        age: getAgeByYearBuilt(
          +contract.detail?.property?.yearBuilt,
          parseDate(contract.detail?.importantDates.listDate).getFullYear(),
        ),
        squareFootage: normalizeSquareFootageValue(contract.detail?.property?.squareFeet),
      }
    );
  };

  /** property details for plan card, as property info can change */
  const planCardPropertyDetails: PropertyDetails = useMemo(() => {
    // below is based on the contract directly for accurate reference, but during edit, it is updating directly to contractData instead of a pending state.
    // initial load using the contract information for reference then switch to contractData
    if (!contractData) {
      return getPropertyDetailsFromContract(props.contract);
    }

    return {
      residenceType: getResidenceType(contractData.propertyDetails.residenceType, true),
      age: contractData.propertyDetails.age,
      squareFootage: getSquareFootageFromOptionName(contractData.propertyDetails.squareFootage),
    };
  }, [props.contract, contractData]);

  const planCardFormData: PlansCoverageValues = useMemo(() => {
    // if currently processing changes, display it
    if (pendingPlanCoverages) {
      return pendingPlanCoverages;
    }

    return toPlanCoverageValues(
      contractPlanCoveragesData,
      contractPricingData?.appliedSpecialDiscounts?.includes(MILITARY_DISCOUNT),
      props.contract?.detail?.property?.numberMotherLawUnits > 0,
    );
  }, [
    props.contract,
    isPendingPropertyUpdate,
    contractPlanCoveragesData,
    pendingPlanCoverages,
    contractPricingData,
  ]);

  /**
   * Call either the props.refreshContractData or the props.onUpdate function.
   * TODO: ARE-7582 we have two functions that does the same thing right now. Grouping them together to easily deal with it when we get to it in a separate story..
   * @param contractID
   * @param useOnUpdateFunc  -- if true, use props.onUpdate, otherwise use props.refreshContractData
   * @param contract         -- the contract object used for the props.onUpdate. Move away from sending the full object
   */
  function refreshContractData(
    contractID: string,
    useOnUpdateFunc = false,
    contract: Contract = undefined,
  ): void {
    if (useOnUpdateFunc) {
      if (props.onUpdate) {
        props.onUpdate(contract);
      }
    }
    reloadContractPricingData();
    reloadContractPlanCoverages();
    reloadQuoteEffectiveDate();
  }

  /**
   * Function will reload the price data in the drawer.
   */
  function reloadContractPricingData(): void {
    if (contractID) {
      setContractPricingData(null); // clear the price immediately
      setIsLoadingPricingData(true);
      ContractApiSuppressErrors.getContractPricing(contractID)
        .then((data) => {
          setContractPricingData(data);
          if (!earnixRuleID) {
            console.debug('contract earnix rule id being set', contractID, data?.earnixRuleID);
          }
          setEarnixRuleID(data?.earnixRuleID);
        })
        .catch((err) => {
          console.error('failed to load the contract pricing', err);
          addErrorToQueue(msgs.FAILED_TO_LOAD_CONTRACT_PRICING);
        })
        .finally(() => setIsLoadingPricingData(false));
    }
  }

  function reloadContractPlanCoverages(): void {
    if (contractID) {
      setContractPlanCoveragesData(null); // clear data immediately
      setIsROProductContract(false);
      setIsLoadingContractPlanCoveragesData(true);
      ContractApiSuppressErrors.getContractPlanCoverages(contractID)
        .then((data) => {
          setContractPlanCoveragesData(data);
          setIsROProductContract(isROProduct(data?.product));
        })
        .catch((err) => {
          console.error('failed to load the contract plan/coverages', err);
          addErrorToQueue(msgs.FAILED_TO_LOAD_CONTRACT_PLAN_COVERAGES);
        })
        .finally(() => setIsLoadingContractPlanCoveragesData(false));
    }
  }

  function reloadQuoteEffectiveDate(): void {
    const orderID = getOrderIDFromContract(props.contract);
    if (orderID) {
      realestatequoteApi.searchProfileQuoteByQuoteID(orderID).then((res) => {
        if (res?.orders?.length === 1) {
          setQuoteEffectiveDate(parseDate(res.orders[0].effectiveDate));
        }
      });
    }
  }

  function openPaymentHandler(contract: REOrder): void {
    const details = props.contract;
    if (!details) return;
    ContractApi.getContractBalance(contract.id.toString())
      .then((res) => {
        if (!res) {
          addWarningToQueue(msgs.FAILED_TO_LOAD_CONTRACT_BALANCE);
        } else {
          setContractPaymentBalance(res);
          setIsMakePaymentDrawerActiveStripe(true);
          fireGAEvent(ORDER__PAYMENT_CLICK('Make Payment', 'Order Details drawer'));
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function handleACHPaymentCall(request, contractID): Promise<MakePaymentResponse> {
    return ContractApi.chargeACH(request, contractID);
  }

  function handleCCPaymentCall(request, contractID): Promise<MakePaymentResponse> {
    return ContractApi.chargeCC(request, contractID);
  }

  /** Extend a contract listing. Returns true if successful, otherwise false. */
  function onExtendListing(contract: Contract): Promise<boolean> {
    try {
      if (contract) {
        console.log('extend listing for contract', contract.summary.id);
        return ContractApi.extendListing(contract.summary.id)
          .then((success) => {
            if (success) {
              addSuccessToQueue(msgs.EXTEND_LISTING_SUCCESS);
              setIsExtendListingDrawerActive(false);
              // Refresh the contract in the table on success.
              refreshContractData(contract.summary.id);
            } else {
              console.log('extend listing failed');
              addErrorToQueue(msgs.FAILED_TO_EXTEND_LISTING);
            }
            return success;
          })
          .catch((err) => {
            console.error('error occurred when extend listing', err);
            addErrorToQueue(msgs.FAILED_TO_EXTEND_LISTING);
            return false;
          });
      }
    } catch (e) {
      console.error('error occurred when extend listing', e);
      addErrorToQueue(msgs.FAILED_TO_EXTEND_LISTING);
      return new Promise((resolve) => resolve(false));
    }
  }

  function handleOrderCancelled(contractId: string) {
    setIsCancelOrderDrawerActive(false);
    refreshContractData(contractId);
  }

  function handleMakePaymentSuccess(contractID: string) {
    refreshContractData(contractID);
  }

  /** Handle when we are saving the closing info */
  const onClosingInfoSave = async (closingInfo: any): Promise<void> => {
    setSaveDisabledClosing(true);
    fireGAEvent(
      ORDER__BUYER_SELLER_CLOSING_INFO_STEP(
        ACTION_UPDATE,
        CLOSING_LABEL,
        getOriginatorName(contractData),
      ),
    );

    // Clear the latest pending updates
    setPendingClosingUpdate(undefined);

    // If closing info has unknown date, we want to clear closing information
    if (
      shouldClosingInfoClearBuyerInfo(
        contractData.agentInfo?.representing,
        contractData.buyerInfo,
        closingInfo,
      )
    ) {
      // We want to alert the user first and have them acknowledge the clear
      setIsConfirmRemoveClosingInfoModalActive(true);
      setSaveDisabledClosing(false);

      return;
    }

    // Get current buyer data. If we have pending updates, then use that instead.
    const buyerInfo = pendingBuyerUpdate || contractData.buyerInfo;

    // If closing info filled but no buyer information, we need to trigger for the buyer card to open
    if (shouldReturnToBuyerInfo(buyerInfo, closingInfo)) {
      setEditingClosingInfo(false); // Close the closing card
      setEditingBuyerInfo(true); // Open edit for buyer card
      setPendingClosingUpdate(closingInfo); // Store pending updates for reference
      setSaveDisabledClosing(false);

      return;
    }

    // If we have pending buyer update, we want to perform a separate update task
    if (pendingBuyerUpdate) {
      await handleUpdateBuyerAndClosingInfo(buyerInfo, closingInfo).then((r) =>
        setSaveDisabledClosing(false),
      );
      return;
    }

    // Otherwise, we update closing information normally
    updateClosingInformation(closingInfo)
      .then(() => {
        // Successful save if good response returns
        addSuccessToQueue(msgs.UPDATE_CLOSING_SUCCESS);
        setEditingClosingInfo(false);
        setSaveDisabledClosing(false);
        refreshContractData(contractID, true, props.contract);
      })
      .catch((err) => {
        setSaveDisabledClosing(false);
        console.error('Failed to save closing information', err);
        addErrorToQueue(msgs.UPDATE_CLOSING_FAILURE);
      });

    // turn the switch value to 'No' and disable plans if the closing date is in the past
    resetPlansFilterIfClosingDateInPast(closingInfo.projectedClosingDate);
  };

  const clearPlanCoverageAlertMessage = () => {
    setIsPendingPropertyUpdate(false);
    setIsPastClosingDate(false);
    setIsClearAlert(true);
  };

  // resets the plans filter if the closing date is in past
  const resetPlansFilterIfClosingDateInPast = (projectedClosingDate: any) => {
    if (projectedClosingDate) {
      const closingDate = parseDate(projectedClosingDate);
      if (closingDate < parseDate(props.contract?.detail?.importantDates.listDate)) {
        setIsEditingPropertyDetails(false);
        setIsEditingPlanAndCoverages(true);

        // reset plan change reason alert message
        clearPlanCoverageAlertMessage();
        setIsPastClosingDate(true);
      }
    }
  };

  const planCardAlertText = useMemo(() => {
    if (isPendingPropertyUpdate) {
      return msgs.PLANS_AND_COVERAGES_PENDING_PROPERTY_INFO.message;
    }
    if (isPastClosingDate) {
      return msgs.PAST_CLOSING_DATE_SELECTED.message;
    }
    console.log('returning null');
    return null;
  }, [isPendingPropertyUpdate, isPastClosingDate, isClearAlert]);

  /** Handle when we clear closing information */
  const handleClearClosingInfo = () => {
    // Close the confirmation modal
    setIsConfirmRemoveClosingInfoModalActive(false);
    // Call API to clear buyer/closing information
    ContractApi.removeContractClosingAndBuyer(contractData.id)
      .then(() => {
        // Successful save if good response returns
        addSuccessToQueue(msgs.DELETE_CLOSING_SUCCESS);

        // if military discount was on the order, then we display a 2nd message to indicate removal
        if (planCardFormData?.selectedMilitaryDiscount) {
          fireGAEvent(MILITARY_DISCOUNT_REMOVED(OrderFlowType.EditOrder));
          addSuccessToQueue(msgs.DELETE_CLOSING_WITH_MILITARY_DISCOUNT);
        }

        setEditingClosingInfo(false);
        refreshContractData(contractID, true, props.contract);
      })
      .catch((err) => {
        console.error('Failed to delete closing information', err);
        addErrorToQueue(msgs.CLEAR_CLOSING_FAILURE);
      });
  };

  /** Handle updating both buyer and closing information at the same time. */
  const handleUpdateBuyerAndClosingInfo = async (buyerInfo, closingInfo) => {
    // We want to update buyer information first as that is more prone to errors.
    // We would update closing information second.

    await upsertBuyerInfo(buyerInfo)
      .then(async () => {
        setEditingBuyerInfo(false); // Close the buyer card

        if (buyerInfo.cooperatingAgent !== '') {
          showSpinner(true);
          await ContractApi.updateCooperatingAgentInfo(contractID, {
            agentId: buyerInfo.AgentId,
            officeId: buyerInfo.office.id,
            agentFirstName: cooperatingAgentCustom.firstName,
            agentLastName: cooperatingAgentCustom.lastName,
            agentEmail: cooperatingAgentCustom.email,
          })
            .catch(() => {
              return false;
            })
            .finally(() => {
              showSpinner(false);
            });
        }

        // Successfully upserted buyer information. Now update closing information
        updateClosingInformation(closingInfo)
          .then(() => {
            // Succesfully updated closing information. Now trigger refresh
            addSuccessToQueue(msgs.UPDATE_BUYER_CLOSING_SUCCESS);
            setEditingClosingInfo(false); // Close the closing card
            refreshContractData(contractID, true, props.contract); // Trigger an update on the contract
          })
          .catch((err) => {
            console.error('failed to update closing information in joint call', err);
            addErrorToQueue(msgs.UPDATE_CLOSING_FAILURE);
            setEditingClosingInfo(true);
          });
      })
      .catch((err) => {
        console.error('failed to update buyer information in joint call', err);
        addErrorToQueue(msgs.UPDATE_BUYER_FAILURE);
        setEditingBuyerInfo(true); // Open the buyer card again to handle fixes
      });
  };

  /** Returns a buyer if one exists on the contract */
  const getExistingBuyerInfo = (): any => {
    const buyer = props.contract.detail.customers?.filter((customer) => {
      return customer.type === 'BUY';
    });
    return buyer?.length > 0 ? buyer[0] : null;
  };

  /** Handle when we are saving the buyer info */
  const onBuyerInfoSave = async (buyerInfo: any): Promise<boolean> => {
    setSaveDisabledBuyer(true);

    // asynchronously call validate for buyer email/phone
    if (buyerInfo.email || buyerInfo.phone) {
      ValidateApi.validateEmailPhone({
        email: buyerInfo.email,
        phone: buyerInfo.phone,
        flow: OrderFlowType.EditOrder,
      });
    }

    // ARE-10721 if the user came from the deeplink and added a phone or an email for the buyer, send a different GA event
    if (dlPageCategory?.myOrders?.contractDrawer?.buyerInfo) {
      const contractBuyerInfo = getExistingBuyerInfo();
      if (!contractBuyerInfo?.email && buyerInfo?.email) {
        fireGAEvent(ORDER__BUYER_INFO_ADDED('Email'));
      }
      if (
        (!contractBuyerInfo?.phones || contractBuyerInfo?.phones?.length <= 0) &&
        buyerInfo?.phone
      ) {
        fireGAEvent(ORDER__BUYER_INFO_ADDED('Phone'));
      }
    }

    fireGAEvent(
      ORDER__BUYER_SELLER_CLOSING_INFO_STEP(
        ACTION_UPDATE,
        AGENT_LABELS.buyer,
        getOriginatorName(contractData),
      ),
    );

    // clear the latest pending updates
    setPendingBuyerUpdate(undefined);

    // Get current closing data. If we have pending updates, then use that instead.
    const closingInfo = pendingClosingUpdate || contractData.closingInfo;

    // If buyer info filled but no closing information, we need to trigger for the closing card to open
    if (shouldReturnToClosingInfo(buyerInfo, closingInfo)) {
      setEditingBuyerInfo(false); // Close the buyer card
      setEditingClosingInfo(true); // Open edit for closing card
      setPendingBuyerUpdate(buyerInfo); // Store the pending changes for reference
      setSaveDisabledBuyer(false);

      return;
    }

    // If we have pending closing update, we want to perform a separate update task
    if (pendingClosingUpdate) {
      await handleUpdateBuyerAndClosingInfo(buyerInfo, closingInfo);
      setSaveDisabledBuyer(false);

      return;
    }

    // Otherwise, we update buyer info normally
    const successBuyer = await upsertBuyerInfo(buyerInfo).catch((err) => {
      console.error('failed to save buyer information', err);
      setSaveDisabledBuyer(false);

      return false;
    });

    let successCooperatingAgent = true;

    if (buyerInfo.cooperatingAgent !== '') {
      showSpinner(true);
      successCooperatingAgent = await ContractApi.updateCooperatingAgentInfo(contractID, {
        agentId: buyerInfo.AgentId,
        officeId: buyerInfo.office.id,
        agentFirstName: cooperatingAgentCustom.firstName,
        agentLastName: cooperatingAgentCustom.lastName,
        agentEmail: cooperatingAgentCustom.email,
      })
        .catch(() => {
          return false;
        })
        .finally(() => {
          showSpinner(false);
        });
    }

    const success = successBuyer && successCooperatingAgent;

    if (success) {
      addSuccessToQueue(msgs.UPDATE_BUYER_SUCCESS);
      setEditingBuyerInfo(false);
      refreshContractData(contractID, true, props.contract);
    } else {
      addErrorToQueue(msgs.UPDATE_BUYER_FAILURE);
    }

    setSaveDisabledBuyer(false);

    return success;
  };

  const updatePropertyDetailsClosingAndMLS = (propertyDetails): Promise<any> => {
    return ContractApi.updateContractClosingAndMLSNumber(contractData.id, {
      mls_number: propertyDetails.mlsNumber,
      agent_id: contractData.closingInfo?.AgentId,
      date: contractData.closingInfo?.projectedClosingDate
        ? formatDateToISO8601(new Date(contractData.closingInfo?.projectedClosingDate))
        : undefined,
      office_file_number: contractData.closingInfo?.closingFileNumber,
      office_id: contractData.closingInfo?.closingOffice?.id,
    });
  };

  const updateClosingInformation = (closingInfo): Promise<any> => {
    return ContractApi.updateContractClosingAndMLSNumber(contractData.id, {
      mls_number: contractData.propertyDetails?.mlsNumber,
      agent_id: closingInfo.AgentId,
      date: closingInfo.projectedClosingDate
        ? formatDateToISO8601(new Date(closingInfo.projectedClosingDate))
        : undefined,
      office_file_number: closingInfo.closingFileNumber,
      office_id: closingInfo.closingOffice?.id,
    });
  };

  // TODO: tighten up typing once domain model is normalized
  const upsertCustomerInfo = (customerInfo): Promise<any> => {
    return ContractApi.upsertCustomerInfo(contractData.id, { customers: customerInfo });
  };

  // TODO: tighten up typing once domain model is normalized
  const upsertSellerInfo = (sellerInfo): Promise<any> => {
    return upsertCustomerInfo([
      ...getBuyerInfoForAPICall(contractData.buyerInfo),
      ...getSellerInfoForAPICall(sellerInfo),
    ]);
  };

  const upsertBuyerInfo = (buyerInfo): Promise<any> => {
    return upsertCustomerInfo([
      ...getBuyerInfoForAPICall(buyerInfo),
      ...getSellerInfoForAPICall(contractData.sellerInfo),
    ]);
  };

  // TODO: tighten up typing once domain model is normalized
  const handleSellerInfoUpsert = async (sellerInfo): Promise<boolean> => {
    fireGAEvent(
      ORDER__BUYER_SELLER_CLOSING_INFO_STEP(
        ACTION_UPDATE,
        AGENT_LABELS.seller,
        getOriginatorName(contractData),
      ),
    );
    showSpinner(true);
    setIsEditingSellerInfo(false);

    const successSeller = await upsertSellerInfo(sellerInfo)
      .catch(() => {
        return false;
      })
      .finally(() => {
        showSpinner(false);
      });

    let successCooperatingAgent = true;

    if (sellerInfo.cooperatingAgent !== '') {
      showSpinner(true);
      successCooperatingAgent = await ContractApi.updateCooperatingAgentInfo(contractID, {
        agentId: sellerInfo.AgentId,
        officeId: sellerInfo.office.id,
        agentFirstName: cooperatingAgentCustom.firstName,
        agentLastName: cooperatingAgentCustom.lastName,
        agentEmail: cooperatingAgentCustom.email,
      })
        .catch(() => {
          return false;
        })
        .finally(() => {
          showSpinner(false);
        });
    }

    const success = successSeller && successCooperatingAgent;

    if (success) {
      refreshContractData(contractID, true, props.contract); // bubble changes up to parent components
      setIsPendingRetroactiveSellerInfoCollection(false);
    }

    return success;
  };

  const handlePropertyDetailsUpdate = async (propertyInfo): Promise<boolean> => {
    fireGAEvent(
      ORDER__PROPERTY_DETAILS_UPDATE({
        residenceType: propertyInfo.residenceType,
        squareFootage: `${getSquareFootageFromOptionName(propertyInfo.squareFootage)}`,
        isNewConstruction: propertyInfo.newConstruction,
      }),
    );

    if (propertyInfo.propertyChanged) {
      setIsPendingPropertyUpdate(true);
    }
    return await updatePropertyDetailsClosingAndMLS(propertyInfo)
      .then(() => {
        // If property details changed more than just mls number, we need to trigger for the plans card to open
        if (propertyInfo.propertyChanged) {
          setIsEditingPropertyDetails(false);
          setIsEditingPlanAndCoverages(true);
        }
        props.onUpdate(props.contract);
        return true;
      })
      .catch(() => {
        setIsPendingPropertyUpdate(false);
        return false;
      });
  };

  const savePlansAndCoverages = (data: any): Promise<boolean> => {
    showSpinner(true);
    const { propertyDetails } = contractData;
    const squareFeet: number = getSquareFootage(propertyDetails.squareFootage);

    return ContractApi.editContract(contractData.id, {
      selectedProduct: {
        id: data.selectedProduct.meta.productId,
        guestUnitCount: data.guestUnitCoverage.filter((item) => item.value).length,
        selectedOptionalCoverages: data.selectedOptionalCoverage
          .filter((item) => item.meta.groupId === '0')
          .map((item) => ({
            id: item.coverageId,
            count: item.quantity,
          })),
        militaryDiscount: data.militaryDiscount,
        selectedOptionalCoverageGroups: data.selectedOptionalCoverage
          .filter((item) => item.meta.groupId !== '0')
          .map((item) => ({
            id: item.meta.groupId,
            count: !item.meta.quantity || item.meta.quantity === 0 ? 1 : item.meta.quantity,
          })),
      },
      property: {
        typeOfResidence: propertyDetails.residenceType,
        squareFeet,
        age: propertyDetails.age,
        mlsNumber: propertyDetails.mlsNumber,
      },
    })
      .then(async () => {
        setIsEditingPlanAndCoverages(false);
        setIsPendingPropertyUpdate(false);
        await refreshContractData(contractID, true, props.contract);
        return true;
      })
      .catch(() => {
        setIsEditingPlanAndCoverages(true);
        return false;
      })
      .finally(() => {
        showSpinner(false);
      });
  };

  /** @deprecated switched to refactored logic. Any logic in here should be applied to the onSavePlanCard() as well */
  const onPlansAndCoverageSubmission = async (delay: boolean, data: any): Promise<boolean> => {
    fireGAEvent(ORDER__UPDATE_PLAN_STEP(ACTION_UPDATE, getOriginatorName(contractData)));

    // if delay is set, it is not valid for submission yet (due to needing sellers info), apply logic to handle retroactive save.
    if (delay) {
      setDelayedPlanSaveData(data);
      setIsPendingRetroactiveSellerInfoCollection(true);

      return true;
    }

    return await savePlansAndCoverages(data);
  };

  const onOpenPlanCard = () => {
    setIsEditingPlanAndCoverages(true);
  };

  const onClosePlanCard = () => {
    setIsEditingPlanAndCoverages(false);
  };

  const onSavePlanCard = ({ formData, pricing }) => {
    fireGAEvent(ORDER__UPDATE_PLAN_STEP(ACTION_UPDATE, getOriginatorName(contractData)));
    setPendingPlanCoverages(null);
    setPendingPricingData(null);

    // TODO: mapping the data to the untyped existing object, will be cleaned up in ARE-7861
    const mappedData: any = {
      selectedProduct: {
        meta: {
          productId: formData.selectedPlanCoverages.product?.starPVID,
          hasSellersCoverage: formData.selectedPlanCoverages.product?.sellersCoverage,
        },
      },
      militaryDiscount: formData.selectedMilitaryDiscount,
      guestUnitCoverage: formData.selectedGuestUnit ? [{ value: true }] : [],
      selectedOptionalCoverage: [
        ...(formData.selectedPlanCoverages.optionalCoverages?.map((cvg) => ({
          coverageId: cvg.id,
          quantity: cvg.quantity,
          meta: {
            groupId: '0',
          },
        })) || {}),
        ...(formData.selectedPlanCoverages.groupCoverages?.map((cvg) => ({
          meta: {
            groupId: `${cvg.id}`,
            quantity: cvg.quantity,
          },
        })) || {}),
      ],
    };

    // if seller coverage selected but no seller info collected, then handle the retroactive logic instead
    const productHasSellersCoverage = formData?.selectedPlanCoverages?.product?.sellersCoverage;
    const sellerInfoComplete = isRequiredSellerInfoPresent(
      contractData?.sellerInfo,
      contractData.agentInfo.agentRepresents.seller || contractData.agentInfo.agentRepresents.both,
    );
    if (productHasSellersCoverage && !sellerInfoComplete) {
      setIsEditingPlanAndCoverages(false); // close the card
      setPendingPlanCoverages(formData); // store the pending form data
      setPendingPricingData(pricing); // store pending pricing data

      // used for retroactive logic
      setDelayedPlanSaveData(mappedData);
      setIsPendingRetroactiveSellerInfoCollection(true);

      return;
    }

    savePlansAndCoverages(mappedData).then((success) => {
      if (success) {
        setIsEditingPlanAndCoverages(false); // close the card

        // clear pending data
        setPendingPlanCoverages(null);
        setPendingPricingData(null);
      }
    });

    clearPlanCoverageAlertMessage();
  };

  function toggleEditHistory() {
    setIsModalActive(!isModalActive);
  }

  const handleUpdateAgentDetails = async (agentInfo: any): Promise<boolean> => {
    const success = await ContractApi.updateAgentInfo(contractData.id, {
      agentId: agentInfo.AgentId,
      officeId: agentInfo.office.id,
    }).catch(() => {
      return false;
    });

    if (success) {
      addSuccessToQueue(msgs.UPDATE_AGENT_SUCCESS);
      refreshContractData(contractID, true, props.contract);
    } else {
      addErrorToQueue(msgs.UPDATE_AGENT_FAILURE);
    }
    return success;
  };

  const updatePropertyDetails = (details) => {
    setIsPendingPropertyUpdate(details.propertyDetails.propertyChanged);
    setContractData({ ...contractData, propertyDetails: details.propertyDetails });
  };

  return (
    <>
      <Drawer {...props} useDefaultWidth={false}>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-3/4 md-max:mb-4 px-4">
            <h1 className="h3" id="orderDetail.header.address">
              {!props.contract ? (
                <Skeleton />
              ) : (
                <REText variant="heading-03">
                  {addressToString(
                    props.contract?.summary?.address?.address1,
                    props.contract?.summary?.address?.address2,
                    props.contract?.summary?.address?.city,
                    props.contract?.summary?.address?.state,
                    props.contract?.summary?.address?.zip,
                  )}
                </REText>
              )}
            </h1>

            <REText>
              <p className="mt-2 leading-6">
                Plan Number:{' '}
                <strong id="orderDetail.header.id">
                  {!props.contract ? <Skeleton width={120} /> : props.contract.summary.id}
                </strong>{' '}
                <br />
                {props.contract?.detail.contractStatus === 'A'
                  ? 'Contract Expiration Date'
                  : 'Order Expiration Date'}
                :{' '}
                <strong id="orderDetail.header.expirationDate">
                  {!props.contract ? (
                    <Skeleton width={120} />
                  ) : (
                    formatDateFromString(props.contract.summary.expirationDate)
                  )}
                </strong>
                <br />
                Order Status:{' '}
                <strong id="orderDetail.header.status">
                  {!props.contract ? <Skeleton width={120} /> : props.realEstateStatus}
                </strong>
              </p>
            </REText>
          </div>

          <div className="w-full md:w-1/4 px-4 md:text-right">
            <ContextMenu
              menuItems={tooltipActions().map((a) => ({ label: a.text, onClick: a.onClick }))}
            >
              <Button
                id="drawer_actions_dropdown"
                label="Actions"
                size="large"
                endIcon={<IconNavArrowDown />}
              />
            </ContextMenu>
          </div>

          {!contractData ? (
            <div className="px-4">Loading...</div>
          ) : (
            <>
              <div className="w-full lg:w-3/4 px-4">
                <CardNewOrderAgentInfo
                  isSmall={true}
                  isCollapsed={true}
                  cancelModal={false}
                  values={{
                    ...contractData.agentInfo,
                    isRealtorOwned: isROProductContract,
                  }}
                  realtorOwnedOwnerName={roOwnerName}
                  isEditing={isEditingAgentInfo}
                  setIsEditing={setIsEditingAgentInfo}
                  disableEdit={
                    !canEditAgentInfo(props.contract, canEditAgent && !isFetchingPermissions)
                  }
                  handleSubmit={handleUpdateAgentDetails}
                  userDetails={props.userDetails}
                  usaStates={usaStates}
                  isExistingOrder={true}
                  flow={OrderFlowType.EditOrder}
                />
                <CardNewOrderPropertyDetails
                  isSmall={true}
                  isCollapsed={true}
                  cancelModal={false}
                  values={contractData.propertyDetails}
                  isEditing={isEditingPropertyDetails}
                  setIsEditing={setIsEditingPropertyDetails}
                  disableEdit={props.contract.summary.status !== 'L'}
                  usaStates={usaStates}
                  handleSubmit={handlePropertyDetailsUpdate}
                  existingOrder={true}
                  originator={getOriginatorName(contractData)}
                  setValues={updatePropertyDetails}
                  isRealtorOwned={isROProductContract}
                />
                <CardNewOrderSellerInfo
                  alertText={
                    isPendingRetroactiveSellerInfoCollection && isEditingSellerInfo
                      ? msgs.EDIT_ORDER_FLOW_SELLER_INFO_REQUIRED.message
                      : ''
                  }
                  isCustomerNameRequired={isSellerNameRequired(
                    isPendingRetroactiveSellerInfoCollection,
                    contractData.agentInfo.agentRepresents,
                  )} // TODO: refactor so that we're just passing a bool for whether or not the user has sellers coverage as opposed to having to use the more indirect proxy
                  isCustomerContactInfoRequired={isSellerContactInfoRequired(
                    contractData.agentInfo.agentRepresents,
                  )}
                  showCooperatingFields={
                    !(
                      contractData.agentInfo.agentRepresents.both ||
                      contractData.agentInfo.agentRepresents.seller
                    )
                  }
                  isSmall={true}
                  cancelModal={false}
                  isExistingContract={true}
                  values={contractData.sellerInfo} // existing saved values
                  isEditing={isEditingSellerInfo}
                  setIsEditing={setIsEditingSellerInfo}
                  disableEdit={props.contract.summary.status !== 'L'}
                  usaStates={usaStates}
                  searchCityStateByZip={searchCityStateByZip}
                  handleSubmit={handleSellerInfoUpsert}
                  userDetails={props.userDetails}
                  initiatingAgentId={contractData.agentInfo.AgentId}
                  hasSellersCoverage={
                    planCardFormData?.selectedPlanCoverages?.product?.sellersCoverage
                  }
                  disableUnverifiedAddressCreation={false}
                  agentInfo={contractData.agentInfo}
                  flow="Edit Order"
                  cooperatingAgentCustom={cooperatingAgentCustom}
                />
                <CardNewOrderBuyerInfo
                  alertText={pendingClosingUpdate ? msgs.BUYER_INFO_REQUIRED.message : undefined}
                  isSmall={true}
                  cancelModal={false}
                  isExistingContract={true}
                  values={pendingBuyerUpdate || contractData.buyerInfo}
                  showCooperatingFields={contractData.agentInfo.agentRepresents.seller}
                  isRequired={isBuyerInfoRequired(
                    contractData.agentInfo,
                    pendingClosingUpdate || contractData.closingInfo,
                  )}
                  isEditing={editingBuyerInfo}
                  setIsEditing={setEditingBuyerInfo}
                  disableEdit={props.contract.summary.status !== 'L'}
                  usaStates={usaStates}
                  searchCityStateByZip={searchCityStateByZip}
                  setValues={() => {
                    /* Do nothing */
                  }}
                  handleSubmit={onBuyerInfoSave}
                  userDetails={props.userDetails}
                  initiatingAgentId={contractData.agentInfo.AgentId}
                  isBuyerDisabled={saveDisabledBuyer}
                  disableUnverifiedAddressCreation={false}
                  agentInfo={contractData.agentInfo}
                  flow="Edit Order"
                  cooperatingAgentCustom={cooperatingAgentCustom}
                />
                <CardNewOrderClosingInfo
                  listingCreationDate={parseDate(
                    props.contract?.detail?.importantDates?.creationDate,
                  )}
                  alertText={pendingBuyerUpdate ? msgs.CLOSING_INFO_REQUIRED.message : undefined}
                  isSmall={true}
                  isCollapsed={true}
                  cancelModal={false}
                  values={pendingClosingUpdate || contractData.closingInfo}
                  agentRepresent={isClosingInfoRequired(
                    contractData.agentInfo,
                    pendingBuyerUpdate || contractData.buyerInfo,
                  )}
                  showUnknownDate={contractData.agentInfo?.agentRepresents?.seller}
                  isEditing={editingClosingInfo}
                  setIsEditing={(value) => setEditingClosingInfo(value)}
                  disableEdit={props.contract.summary.status !== 'L'}
                  usaStates={usaStates}
                  searchCityStateByZip={searchCityStateByZip}
                  isClosingInfoMissing={true}
                  userType={props.userDetails?.roleIDType}
                  userDetails={props.userDetails}
                  offices={props.userOffices}
                  handleSubmit={onClosingInfoSave}
                  isSaveDisabled={saveDisabledClosing}
                />
                <CardNewOrderPlansCoverage
                  isSmall={true}
                  defaultCollapsed={true}
                  isEditing={isEditingPlanAndCoverages}
                  initiatingOfficeId={props.contract?.detail?.initiatingOfficeAgent?.office?.id}
                  initiatingOfficeFranchiseCode={
                    props.contract?.detail?.initiatingOfficeAgent?.office?.franchiseCode
                  }
                  propertyAddress={{
                    address1: props.contract?.detail?.property?.streetAddress,
                    address2: props.contract?.detail?.property?.streetAddress2,
                    city: props.contract?.detail?.property?.city,
                    state: props.contract?.detail?.property?.state,
                    zip: props.contract?.detail?.property?.zip,
                    zipPlus4: props.contract?.detail?.property?.zipFour,
                  }}
                  propertyDetails={planCardPropertyDetails}
                  contractListTimestamp={parseDate(props.contract?.detail?.importantDates.listDate)}
                  quoteEffectiveDate={quoteEffectiveDate}
                  listingEffectiveDate={parseDate(
                    props.contract?.detail?.importantDates.sellersCoverageEffectiveDate ||
                      formatDate(new Date()),
                  )}
                  listingExpirationDate={parseDate(
                    props.contract?.detail?.importantDates.sellersCoverageExpirationDate ||
                      (pendingClosingUpdate || contractData.closingInfo)?.projectedClosingDate,
                  )}
                  disableEdit={props.contract?.detail?.contractStatus !== 'L'}
                  disableSellersSelection={contractPlanCoveragesData?.product?.sellersCoverage}
                  enableQuantitySelection={true} // on drawer, allow quantity selections
                  formData={planCardFormData}
                  agentRepresents={props.contract?.detail?.initiatingOfficeAgent?.represents}
                  pricing={pendingPricingData || contractPricingData}
                  earnixRuleID={earnixRuleID}
                  alertText={planCardAlertText}
                  roProductsOnly={isROProductContract}
                  onOpen={onOpenPlanCard}
                  onClose={onClosePlanCard}
                  onSave={onSavePlanCard}
                  disableEditMilitaryDiscount={!IsFeatureEnabled(Features.EditFlowMilitaryDiscount)}
                  allowMilitaryDiscount={hasBuyerInfo(contractData?.buyerInfo)}
                />
              </div>

              <div
                className={classNames([
                  'w-full lg:w-1/4 md-max:mt-2',
                  IsTheme(Theme.Ahs2024) ? 'pr-4' : 'px-4',
                ])}
              >
                <div
                  className={classNames([
                    'mt-4 p-4',
                    IsTheme(Theme.Ahs2024)
                      ? ' bg-white rounded-tr-5 rounded-br-5 rounded-bl-5 rounded-tl-5'
                      : 'card',
                  ])}
                >
                  <REText className="h5 font-bold pb-4" variant="heading-06">
                    <IconDoc
                      title="document"
                      size={18}
                      color={IsTheme(Theme.Ahs2024) ? 'interactive' : 'gray'}
                      className="inline-block max-w-full align-middle -mt-1 mr-2"
                    />
                    Documents
                  </REText>

                  <div className={IsTheme(Theme.Ahs2024) ? 'pb-4' : undefined}>
                    {documents.map((document, idx) => (
                      <DocumentItem
                        key={idx}
                        isFirst={idx === 0}
                        contractId={contractData.id}
                        {...document}
                      />
                    ))}
                  </div>

                  <Button
                    label="Send"
                    variant={IsTheme(Theme.Ahs2024) ? 'filled' : 'outlined'}
                    onClick={() => setIsNotificationsDrawerActive(true)}
                    width="full"
                    size="medium"
                  />
                </div>

                <CardViewOrderDates
                  dates={props.contract?.detail?.importantDates}
                  expirationDate={props.contract?.summary?.expirationDate}
                />
                <OrderEditHistoryTable
                  history={contractEditHistory.slice(0, 3)}
                  onClickViewMore={toggleEditHistory}
                />
              </div>
            </>
          )}
        </div>
      </Drawer>

      <ModalEditHistory
        isActive={isModalActive}
        onClose={toggleEditHistory}
        history={contractEditHistory}
      />

      <ModalRemoveClosingInfo
        id="modal-remove-closing-info"
        isActive={isConfirmRemoveClosingInfoModalActive}
        onClose={() => setIsConfirmRemoveClosingInfoModalActive(false)}
        onConfirm={handleClearClosingInfo}
      />

      <DrawerDocument
        isActive={isDocumentDrawerActive}
        onClose={() => setIsDocumentDrawerActive(false)}
        contract={props.contract}
        docType={selectedDocType}
      />
      <DrawerMakePaymentStripe
        id="drawer-make-payment2"
        isActive={isMakePaymentDrawerActiveStripe}
        onClose={() => setIsMakePaymentDrawerActiveStripe(false)}
        submitACHPayment={handleACHPaymentCall}
        submitCCPayment={handleCCPaymentCall}
        onSuccess={handleMakePaymentSuccess}
        contractBalance={Number(contractPaymentBalance?.currentBalance)}
        totalPrice={contractPaymentBalance?.details.total}
        creditAmount={contractPaymentBalance?.details.credit}
        discountAmount={contractPaymentBalance?.details.discounts}
        militaryDiscountApplied={contractPaymentBalance?.appliedSpecialDiscounts?.includes(
          MILITARY_DISCOUNT,
        )}
        contract={props.contract}
        sourcePage="Order Details drawer"
      />

      <DrawerExtendListing
        id="drawer-extend-listing"
        isActive={isExtendListingDrawerActive}
        onClose={() => setIsExtendListingDrawerActive(false)}
        onApprove={(contract) => onExtendListing(contract)}
        contract={props.contract}
      />

      <DrawerCancelOrder
        id="drawer-cancel-order"
        isActive={isCancelOrderDrawerActive}
        onClose={() => setIsCancelOrderDrawerActive(false)}
        onOrderCancelled={handleOrderCancelled}
        contract={props.contract}
      />

      <DrawerNotifications
        id="drawer-notifications"
        slidingContentClassName="drawer-notifications-container"
        isActive={isNotificationsDrawerActive}
        userDetails={profile}
        newOrderData={props.contract}
        contractId={props.contract && props.contract.summary.id}
        cooperatingAgentCustom={cooperatingAgentCustom}
        onClose={() => setIsNotificationsDrawerActive(false)}
      />

      {isSendRenewalDrawerActive && (
        <DrawerSendRenewal
          id="drawer-send-renewal"
          isActive={isSendRenewalDrawerActive}
          onClose={() => setIsSendRenewalDrawerActive(false)}
          contract={props.contract}
          userDetails={profile}
          onSendRenewal={(contractID, contract) => {
            refreshContractData(contractID, true, contract);
          }}
        />
      )}
    </>
  );
};

export function getSellerInfoForAPICall(decoratedCustomerInfo) {
  return getCustomerInfoForAPICall(decoratedCustomerInfo, 'SEL');
}

export function getBuyerInfoForAPICall(decoratedCustomerInfo) {
  return getCustomerInfoForAPICall(decoratedCustomerInfo, 'BUY');
}

function getCustomerInfoForAPICall(decoratedCustomerInfo, type) {
  let customersInfo: UpsertOrderCustomer[] = [];

  if (!isEmpty(decoratedCustomerInfo.firstName)) {
    const customerInfo: UpsertOrderCustomer = {
      customerId: decoratedCustomerInfo.customerId || '',
      type,
      firstName: decoratedCustomerInfo.firstName || '',
      lastName: decoratedCustomerInfo.lastName || '',
      email: decoratedCustomerInfo.email
        ? {
            address: decoratedCustomerInfo.email || '',
            id: decoratedCustomerInfo.emailId || '',
          }
        : null,
      phones: decoratedCustomerInfo.phone
        ? {
            number: cleanPhone(decoratedCustomerInfo.phone),
            type: DEFAULT_CUSTOMER_PHONE_TYPE,
          }
        : null,
      address: decoratedCustomerInfo.streetAddress
        ? {
            address1: decoratedCustomerInfo.streetAddress || '',
            address2: decoratedCustomerInfo.unit || '',
            city: decoratedCustomerInfo.city || '',
            state: decoratedCustomerInfo.state || '',
            zip: decoratedCustomerInfo.zipCode || '',
            propertyId: decoratedCustomerInfo.propertyId || '',
          }
        : undefined,
    };

    customersInfo = [customerInfo];
  }

  if (!isEmpty(decoratedCustomerInfo.coCustomerFirstName)) {
    const coCustomerInfo: UpsertOrderCustomer = {
      customerId: decoratedCustomerInfo.coCustomerId || '',
      type: `CO${type}`,
      firstName: decoratedCustomerInfo.coCustomerFirstName || '',
      lastName: decoratedCustomerInfo.coCustomerLastName || '',
      email: decoratedCustomerInfo.coCustomerEmail
        ? {
            address: decoratedCustomerInfo.coCustomerEmail || '',
            id: decoratedCustomerInfo.coCustomerEmailId || '',
          }
        : null,
      phones: decoratedCustomerInfo.coCustomerPhone
        ? {
            number: cleanPhone(decoratedCustomerInfo.coCustomerPhone) || '',
            type: DEFAULT_CUSTOMER_PHONE_TYPE,
          }
        : null,
      address: decoratedCustomerInfo.coCustomerStreetAddress
        ? {
            address1: decoratedCustomerInfo.coCustomerStreetAddress || '',
            address2: decoratedCustomerInfo.coCustomerUnit || '',
            city: decoratedCustomerInfo.coCustomerCity || '',
            state: decoratedCustomerInfo.coCustomerState || '',
            zip: decoratedCustomerInfo.coCustomerZipCode || '',
            propertyId: decoratedCustomerInfo.coCustomerPropertyId || '',
          }
        : null,
    };

    customersInfo = [...customersInfo, coCustomerInfo];
  }

  return customersInfo;
}

export default DrawerOrder;
