import React, { Component } from 'react';
import {
  IconButton,
  IconProhibition,
  IconRefreshDouble,
  Notification,
  Pagination,
  Popover,
  PopoverContent,
} from '@ftdr/blueprint-components-react';
import { Contract, REOrder, REOrderSearchRequest } from '@apis/models';
import { ITEMS_PER_PAGE_ACTION } from '@constants/dashBoard-constants';
import PanelContractSummary from '@components/panel/PanelContractSummary';
import _RealEstateOrderApi from '@apis/realestateorder.api';
import { getTableExtendListingArray, orderToSummary } from '@helpers/order.utils';
import ModalCancelOrder from '@components/modal/ModalCancelOrder';
import ContractApi from '@apis/contract.api';
import LoadingIndicator from '@components/spinner/LoadingIndicator';
import { RealEstateStatus } from '@constants/dashboardFilters';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  DASHBOARD_ACTION_CANCEL_ORDER,
  DASHBOARD_ACTION_EXTEND_ORDER,
} from '@constants/ga-events.constants';
import DashboardActionSwitcher, {
  DashboardActionSearchType,
} from '@components/drawer/subcomponents/DashboardActionSwitcher';
import DashboardActionAdvancedSearch from '@components/drawer/subcomponents/DashboardActionAdvancedSearch';
import { ContractSearchBarHeaderContentRendererFunc } from '@components/drawer/subcomponents/ContractSearchBar';
import { SECOND } from '@helpers/utils';
import msgs from '@app/locales/en';
import REText from '@components/wrappedBDS/REText';

const RealEstateOrderApi = _RealEstateOrderApi.new({
  suppressAllErrorNotifications: true,
});

interface IProps {
  initialContractTotal: number;
  isMobile: boolean;
  updateTotalContracts: any;
}

interface IState {
  totalContracts: number;
  lastCompletedContractId: number;
  lastCompletedIsSuccess: boolean;
  pagedContracts: REOrder[];
  activePage: number;
  isCancelActive: boolean;
  isExtendActive: boolean;
  isNotificationActive: boolean;
  fullContract?: Contract;
  activeContractId?: number;
  errorMessage: any;
  successMessage: string;
  searchType: DashboardActionSearchType;
}

export default class ActionExpired extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      totalContracts: props.initialContractTotal,
      pagedContracts: [],
      lastCompletedIsSuccess: false,
      lastCompletedContractId: 0,
      activePage: 1,
      isCancelActive: false,
      isExtendActive: false,
      isNotificationActive: false,
      fullContract: null,
      activeContractId: null,
      errorMessage: '',
      successMessage: '',
      searchType: DashboardActionSearchType.ShowAll,
    };
  }

  componentDidMount() {
    this.fetchContracts();
  }

  fetchContracts = () => {
    const request = new REOrderSearchRequest(ITEMS_PER_PAGE_ACTION);
    request.data.order.realEstateStatus = [RealEstateStatus.EXPIRED, RealEstateStatus.EXPIRING];
    request.meta.sortBy = 'expirationDate';
    request.meta.asc = true;

    request.meta.page = this.state.activePage - 1;
    RealEstateOrderApi.searchProfileContracts(request).then((response) => {
      if (response) {
        const { totalStatus } = response.meta;
        const totalContracts = (totalStatus.expiring || 0) + (totalStatus.expired || 0);
        this.props.updateTotalContracts(totalContracts);
        this.setState({ totalContracts, pagedContracts: response.orders });
      } else {
        this.setState({
          errorMessage: msgs.DASHBOARD_ACTION_CONTRACT_LOAD_ERROR_GENERIC.message,
          isNotificationActive: true,
          lastCompletedIsSuccess: false,
        });
        return;
      }
    });
  };

  setActivePage = (newPage: number) => {
    this.setState({ activePage: newPage, pagedContracts: [] }, this.fetchContracts);
  };

  setCancelActive = (isActive: boolean, activeContract: number = null) => {
    this.setState({ isCancelActive: isActive, activeContractId: activeContract });
  };

  setExtendActive = async (isActive: boolean, activeContractId: number = null) => {
    let activeContract = null;
    let shouldExtendNow = false;

    if (activeContractId) {
      const response = await ContractApi.getContractDetails(
        [activeContractId.toString()],
        true,
        true,
      );
      if (response.length) {
        activeContract = response[0];
        if (!activeContract.features.extendListing.canExtend) {
          shouldExtendNow = true;
        }
      }
    }
    /** For contract with Seller's coverage and workOrderCount, prevent from extending the contract & display the msg */
    if (
      activeContract?.detail?.importantDates?.sellersCoverageEffectiveDate &&
      activeContract?.features?.extendListing?.workOrderCount > 0
    ) {
      this.setState({
        errorMessage: msgs.EXTEND_ORDER_ERROR_DASHBOARD.messageHTML,
        isNotificationActive: true,
      });
    } else {
      this.setState(
        {
          isExtendActive: isActive && !shouldExtendNow,
          activeContractId,
          fullContract: activeContract,
        },
        () => {
          if (shouldExtendNow) {
            this.extendContract();
          }
        },
      );
    }
  };

  cancelContract = () => {
    this.setState({
      lastCompletedIsSuccess: false,
      lastCompletedContractId: 0,
      errorMessage: '',
      isNotificationActive: false,
    });

    ContractApi.cancelListing(this.state.activeContractId.toString(), {
      reasonType: 'OTHER',
      reasonDescription: 'Other; Other',
    })
      .then(() => {
        fireGAEvent(DASHBOARD_ACTION_CANCEL_ORDER);
        this.setState((prevState) => {
          return {
            lastCompletedContractId: prevState.activeContractId,
            lastCompletedIsSuccess: true,
            successMessage: `Contract ${prevState.activeContractId} has been canceled.`,
            isNotificationActive: true,
          };
        });
      })
      .catch(() => {
        this.setState((prevState) => {
          return {
            lastCompletedIsSuccess: false,
            lastCompletedContractId: prevState.activeContractId,
            errorMessage: `There was an issue cancelling order ${prevState.activeContractId}.`,
            isNotificationActive: true,
          };
        });
      })
      .finally(() => {
        this.fetchContracts();
        this.setCancelActive(false);
      });
  };

  extendContract = () => {
    this.setState({
      lastCompletedIsSuccess: false,
      lastCompletedContractId: 0,
      errorMessage: '',
      isNotificationActive: false,
    });

    ContractApi.extendListing(this.state.activeContractId.toString())
      .then((success) => {
        if (success) {
          fireGAEvent(DASHBOARD_ACTION_EXTEND_ORDER);
          this.setState((prevState) => {
            return {
              lastCompletedContractId: prevState.activeContractId,
              lastCompletedIsSuccess: true,
              successMessage: `Order ${prevState.activeContractId} has been extended to ${getTableExtendListingArray(prevState.fullContract)[0]?.newDate}.`,
              isNotificationActive: true,
            };
          });
        }
      })
      .catch((err) => {
        this.setState((prevState) => {
          return {
            lastCompletedIsSuccess: false,
            lastCompletedContractId: prevState.activeContractId,
            errorMessage: err.errors[0]?.message,
            isNotificationActive: true,
          };
        });
      })
      .finally(() => {
        this.fetchContracts();
        this.setExtendActive(false);
      });
  };

  renderREOrderResults: ContractSearchBarHeaderContentRendererFunc = (
    orders: REOrder[],
    searchState,
  ) => {
    return orders.length > 0 ? (
      <div className="w-full mt-6">
        {orders.map((contract) => (
          <PanelContractSummary
            key={contract.id}
            contract={orderToSummary(contract)}
            idPrefix={contract.id.toString()}
            onClickContract={null}
            query={searchState?.searchTerm}
            actions={[
              <Popover
                key="cancel"
                placement="bottom"
                triggerInteraction="hover"
                content={(popoverContentProps) => (
                  <PopoverContent {...popoverContentProps}>
                    <REText className="max-w-xs" color="gray" variant="caption">
                      Cancel Order
                    </REText>
                  </PopoverContent>
                )}
                className="mx-auto w-full"
              >
                <IconButton
                  label=""
                  icon={<IconProhibition />}
                  variant="outlined"
                  size="small"
                  shape="pill"
                  onClick={() => this.setCancelActive(true, contract.id)}
                />
              </Popover>,
              <Popover
                key="extend"
                placement="bottom"
                triggerInteraction="hover"
                content={(popoverContentProps) => (
                  <PopoverContent {...popoverContentProps}>
                    <REText className="max-w-xs" color="gray" variant="caption">
                      Extend Order
                    </REText>
                  </PopoverContent>
                )}
                className="mx-auto w-full"
              >
                <IconButton
                  label=""
                  icon={<IconRefreshDouble />}
                  variant="filled"
                  shape="pill"
                  size="small"
                  onClick={() => {
                    console.log('calling', contract.id);
                    this.setExtendActive(true, contract.id);
                  }}
                />
              </Popover>,
            ]}
          />
        ))}
      </div>
    ) : (
      <LoadingIndicator />
    );
  };

  changeSearchType = (newSearchType: DashboardActionSearchType) => {
    // only when view changes, we want to refresh the state
    if (newSearchType !== this.state.searchType) {
      this.setState({ searchType: newSearchType });
    }
  };

  render = () => {
    return (
      <div className="full-height-card">
        <DashboardActionSwitcher
          id="drawer-orders-expiring"
          heading="Extend Orders"
          totalContractsCount={this.state.totalContracts}
          includeAdvancedSearch={true}
          searchType={this.state.searchType}
          onChangeSearchType={this.changeSearchType}
          contentRenderer={(searchType) => (
            <>
              <div className="w-full mt-6">
                {this.state.isNotificationActive && (
                  <Notification
                    inline={true}
                    status={this.state.lastCompletedIsSuccess ? 'success' : 'error'}
                    className="max-w-full mb-4"
                    autoCloseDelay={5 * SECOND}
                    onClose={() => this.setState({ isNotificationActive: false })}
                  >
                    {this.state.lastCompletedIsSuccess ? (
                      <span>{this.state.successMessage}</span>
                    ) : (
                      <span>{this.state.errorMessage}</span>
                    )}
                  </Notification>
                )}
                {searchType === DashboardActionSearchType.ShowAll && (
                  <>
                    {this.renderREOrderResults(this.state.pagedContracts)}
                    <Pagination
                      onItemsPerPageChange={null}
                      itemsPerPage={ITEMS_PER_PAGE_ACTION}
                      itemsPerPageOptions={[{ value: '10', label: '10' }]}
                      onPageChange={(page) => this.setActivePage(page)}
                      totalItems={this.state.totalContracts}
                      page={this.state.activePage}
                      hideViewAll={true}
                    />
                  </>
                )}
                {searchType === DashboardActionSearchType.Advanced && (
                  <DashboardActionAdvancedSearch
                    includedStatuses={[RealEstateStatus.EXPIRED, RealEstateStatus.EXPIRING]}
                    subtitle="Search for an order number or address"
                    searchedContractsRenderer={this.renderREOrderResults}
                    onSearchTextChange={(searchText) =>
                      this.setState({ isNotificationActive: false })
                    }
                  />
                )}
              </div>
              <ModalCancelOrder
                id="action-cancel-order__modal-cancel-order"
                isActive={this.state.isCancelActive}
                onClose={() => this.setCancelActive(false)}
                onConfirm={() => this.cancelContract()}
                message="Are you sure you want to cancel this order?"
                heading={`Cancel Order ${this.state.activeContractId}`}
              />
              <ModalCancelOrder
                id="actionextend-order__modal-extend-order"
                isActive={this.state.isExtendActive}
                onClose={() => this.setExtendActive(false)}
                onConfirm={() => this.extendContract()}
                message={`Are you sure you want to extend this order to ${getTableExtendListingArray(this.state.fullContract)[0]?.newDate}?`}
                heading={`Extend Order ${this.state.activeContractId}`}
                confirmButtonLabel="Extend Order"
              />
            </>
          )}
        />
      </div>
    );
  };
}
