import React, { useContext, useState } from 'react';
import QuickLinkSearch from '@components/drawer/subcomponents/QuickLinkSearch';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  DASHBOARD_QUICK_LINK_ACTION_SEND_DOCUMENTS_IN_UPDATE_BUYER_INFORMATION,
  DASHBOARD_QUICK_LINK_ACTION_UPDATE_BUYER,
} from '@constants/ga-events.constants';
import ContractApi from '@apis/contract.api';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import UpdateBuyerInfo from '@components/drawer/subcomponents/UpdateBuyerInfo';
import { getBuyerInfoForAPICall, getSellerInfoForAPICall } from '@components/drawer/DrawerOrder';
import { RealEstateStatus } from '@constants/dashboardFilters';
import { formatDateToISO8601 } from '@helpers/utils';
import SendDocuments, {
  GetLatestRecipients,
  Recipient,
  Type as DocumentType,
} from '@components/SendDocuments';
import ProfileContext from '@context/ProfileContext';
import { Contract } from '@apis/models/contract.api.model';
import { capitalize, isEmpty } from 'lodash';
import { ContractModelVariant } from '@app/models/contract.model';
import { getUniqueDocumentTypes, OrderFlowType } from '@helpers/order.utils';
import ValidateApi from '@apis/validate.api';
import { IsTheme, Theme } from '@app/core/featureToggle';
import { classNames } from '@utils';

export interface props {
  isActive: boolean;
  onClose: any;
}

const QuickLinkUpdateBuyerInfo = (props: props) => {
  const [isBuyerInfoActive, setIsBuyerInfoActive] = useState(false);
  const [contract, setContract] = useState<Contract>(undefined);
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [canSave, setCanSave] = useState(false);
  const [triggerOnSave, setTriggerOnSave] = useState(false);
  const [buyerInfoUpdated, setBuyerInfoUpdated] = useState<boolean>(false);
  const [recipientUpdated, setRecipientUpdated] = useState<boolean>(false);
  const { profile } = useContext(ProfileContext);

  const { addErrorToQueue, addSuccessToQueue } = useGlobalAlert();

  const handleChange = (recipients: Recipient[]) => {
    setRecipients(recipients);
    setRecipientUpdated(true);
  };

  const handleClickContract = (selectedContract) => {
    if (selectedContract) {
      ContractApi.getContractDetails([selectedContract.id], true, true)
        .then((fetchedContracts) => {
          if (fetchedContracts[0]) {
            setContract(fetchedContracts[0]);
            setRecipients(
              GetLatestRecipients(
                fetchedContracts[0],
                ContractModelVariant.API,
                recipients,
                profile.email,
                `${profile.firstName} ${profile.lastName}`,
              ),
            );
            setIsBuyerInfoActive(true);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const handleBackToSearchResults = () => {
    setIsBuyerInfoActive(false);
    setContract(undefined);
    setRecipients([]);
  };

  const getListOfEmails = (words: string[]) => {
    let copy = '';

    if (isEmpty(words)) return '';

    if (words.length > 2) {
      const lastWord = words.splice(-1);
      copy += words.join(', ');
      copy += `, and ${lastWord}`;

      return copy;
    }

    if (words.length == 2) {
      copy += words.join(', and ');

      return copy;
    }

    return words[0];
  };

  const getSuccessMessage = (documentSendSuccess: boolean) => {
    let base = `Order #${contract.summary.id} has been updated.`;

    if (documentSendSuccess) {
      const docTypeAndEmails = {};
      const selectedTypes = new Set(recipients.map((x) => x.selectedTypes).flat(1));

      selectedTypes.forEach((type) => {
        recipients.forEach((recipient) => {
          if (recipient.selectedTypes.includes(type) && docTypeAndEmails[type]) {
            docTypeAndEmails[type].push(recipient.email);
          } else if (recipient.selectedTypes.includes(type)) {
            docTypeAndEmails[type] = [recipient.email];
          }
        });

        base += ` ${capitalize(type)} was sent to ${getListOfEmails(docTypeAndEmails[type])}.`;
      });
    }

    return base;
  };

  const sendDocuments = async (contractID) => {
    const docEmails = recipients
      .filter((recipient) => !isEmpty(recipient.selectedTypes))
      .map(({ email, selectedTypes }) => {
        return { email, docs: selectedTypes };
      });

    return ContractApi.emailContractDocuments({ docEmails }, contractID).catch((e) => {
      console.error('failed to send email closing information in joint call', e);

      throw e;
    });
  };

  const onSave = async (contractData, hasChangedClosingDate: boolean): Promise<boolean> => {
    const { sellerInfo, buyerInfo } = contractData;

    // asynchronously call validate for buyer email/phone
    if (buyerInfo.email || buyerInfo.phone) {
      ValidateApi.validateEmailPhone({
        email: buyerInfo.email,
        phone: buyerInfo.phone,
        flow: OrderFlowType.QuickLinksUpdateBuyerInfo,
      });
    }

    upsertSellerAndBuyerInfo(sellerInfo, buyerInfo)
      .then(async () => {
        if (hasChangedClosingDate) {
          updateClosingInformation(contractData)
            .then(async () => {
              fireGAEvent(DASHBOARD_QUICK_LINK_ACTION_UPDATE_BUYER);

              try {
                await sendDocuments(Number(contract?.summary?.id));
                // Track in GA send documents when closing information was updated in update buyer information
                const uniqueDocTypes = getUniqueDocumentTypes(recipients);
                if (uniqueDocTypes?.length > 0) {
                  fireGAEvent(
                    DASHBOARD_QUICK_LINK_ACTION_SEND_DOCUMENTS_IN_UPDATE_BUYER_INFORMATION(
                      uniqueDocTypes,
                    ),
                  );
                }
                addSuccessToQueue({ message: getSuccessMessage(true) });
              } catch {
                addSuccessToQueue({ message: getSuccessMessage(false) });
              }
            })
            .catch((err) => {
              console.error('failed to update closing information in joint call', err);
              addErrorToQueue({
                message:
                  'Uh oh, there was a problem updating the closing information. Please try again.',
              });
              return false;
            });
        } else {
          try {
            await sendDocuments(Number(contract?.summary?.id));
            addSuccessToQueue({ message: getSuccessMessage(true) });
          } catch {
            addSuccessToQueue({ message: getSuccessMessage(false) });
          }
        }
      })
      .catch((err) => {
        console.error('failed to update buyer information in joint call', err);
        addErrorToQueue({
          message: 'Uh oh, there was a problem updating the buyer information. Please try again.',
        });
        return false;
      });

    props.onClose();
    return true;
  };

  const updateClosingInformation = (contractData): Promise<any> => {
    return ContractApi.updateContractClosingAndMLSNumber(contractData.id, {
      mls_number: contractData.propertyDetails?.mlsNumber,
      agent_id: contractData.closingInfo.AgentId,
      date: contractData.closingInfo.projectedClosingDate
        ? formatDateToISO8601(new Date(contractData.closingInfo.projectedClosingDate))
        : undefined,
      office_file_number: contractData.closingInfo.closingFileNumber,
      office_id: contractData.closingInfo.closingOffice?.id,
    });
  };

  const upsertBuyerInfo = (buyerInfo): Promise<any> => {
    return upsertCustomerInfo([...getBuyerInfoForAPICall(buyerInfo)]);
  };

  const upsertSellerAndBuyerInfo = (sellerInfo, buyerInfo): Promise<any> => {
    return upsertCustomerInfo([
      ...getBuyerInfoForAPICall(buyerInfo),
      ...getSellerInfoForAPICall(sellerInfo),
    ]);
  };

  const upsertCustomerInfo = (customerInfo): Promise<any> => {
    return ContractApi.upsertCustomerInfo(contract.detail.id, { customers: customerInfo });
  };

  const isSaveEnabled = canSave && (recipientUpdated || buyerInfoUpdated);

  return (
    <>
      <div className={props.isActive && isBuyerInfoActive ? 'hidden' : null}>
        <QuickLinkSearch
          title="Update Buyer Information"
          subTitle="Search for an order number or address"
          idPrefix="qlUpdateBuyerInfo"
          unsuccessfulSearchErrorMessage="Order Not Found. Please try again, considering spelling and order status. Active and Cancelled orders are not searchable."
          includedStatuses={[
            RealEstateStatus.EXPIRING,
            RealEstateStatus.PAYMENT_DUE,
            RealEstateStatus.EXPIRED,
            RealEstateStatus.OPEN_ORDER,
            RealEstateStatus.CLOSED,
            RealEstateStatus.AWAITING_WL_SUBMISSION,
            RealEstateStatus.CLOSING_SOON,
            RealEstateStatus.CLOSING_LATER,
            RealEstateStatus.PAYMENT_PAST_DUE,
          ]}
          onClickContract={handleClickContract}
        />
      </div>
      <div className={isBuyerInfoActive ? null : 'hidden'}>
        {isBuyerInfoActive && (
          <>
            <UpdateBuyerInfo
              contract={contract}
              handleBackToSearchResults={handleBackToSearchResults}
              onClose={props.onClose}
              onSave={onSave}
              showTopSection={true}
              showClosingInfoFirst={false}
              showCancelButton={false}
              saveButtonText="Save"
              setCanSave={setCanSave}
              triggerOnSave={triggerOnSave}
              setTriggerOnSave={setTriggerOnSave}
              onBuyerInfoUpdate={(value) => setBuyerInfoUpdated(value)}
              onChange={(appContract) =>
                setRecipients(
                  GetLatestRecipients(
                    appContract,
                    ContractModelVariant.App,
                    recipients,
                    profile.email,
                    `${profile.firstName} ${profile.lastName}`,
                  ),
                )
              }
            />
            <div className="my-4">
              <SendDocuments
                recipients={recipients}
                allowedTypes={[DocumentType.Confirmation, DocumentType.Invoice]}
                onChange={handleChange}
                onSave={() => setTriggerOnSave(true)}
                onCancel={props.onClose}
                disabled={!isSaveEnabled}
                newOrderData={contract}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default QuickLinkUpdateBuyerInfo;
