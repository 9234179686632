import React from 'react';
import ContactUsSuccessTemplate from '@templates/contact/ContactUsSuccessTemplate';
import HeaderNoAuth from '@components/general/HeaderNoAuth';

export const ContactUsNoAuthSuccess: React.FC = () => {
  return (
    <div className="layout">
      <HeaderNoAuth />
      <ContactUsSuccessTemplate isButtonVisible={false} />
    </div>
  );
};
export default ContactUsNoAuthSuccess;
