import React from 'react';

// Components
import Layout from '@components/layout/Layout';
import { Panel } from '@ftdr/blueprint-components-react';
import { IsTheme, Theme } from '@app/core/featureToggle';

const LayoutCard = (props) => {
  return (
    <Layout
      slug={props.slug}
      isLoggedIn={props.isLoggedIn}
      hideHeader={props.hideHeader}
      hideFooter={props.hideFooter}
    >
      <div className="container xs-max:p-0 pb-8">
        {props.roundedBorders && IsTheme(Theme.Ahs2024) ? (
          <Panel
            rounded="xl"
            padding="0"
            shadow={false}
            className="px-4 sm:px-12 lg:px-32 py-8 sm:py-10"
          >
            {props.children}
          </Panel>
        ) : (
          <div className="card px-4 sm:px-12 lg:px-32 py-8 sm:py-10">{props.children}</div>
        )}
      </div>
    </Layout>
  );
};

export default LayoutCard;
