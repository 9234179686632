import React from 'react';
import SubmitSuccess from '../../components/submitSuccess/SubmitSuccess';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

const SUCCESS_TITLE = 'Request successfully submitted';
const SUCCESS_MESSAGE = 'We’ve received your request and we’ll be in touch.';
const SUCCESS_BUTTON_TEXT = 'Back to Orders';

interface IContactUsSuccessProps {
  isButtonVisible: boolean;
  onClick?: () => void;
}

const ContactUsSuccessTemplate: React.FC<IContactUsSuccessProps> = (
  props: IContactUsSuccessProps,
) => {
  return (
    <div id="contact-us-success" className={IsTheme(Theme.Ahs2024) ? "" : "container py-10"}>
      <div className={IsTheme(Theme.Ahs2024) ? "text-left mx-0 lg:text-center lg:m-auto" : ''}>
        <div className="py-4">
          <SubmitSuccess
            title={SUCCESS_TITLE}
            message={SUCCESS_MESSAGE}
            buttonText={SUCCESS_BUTTON_TEXT}
            isButtonVisible={props.isButtonVisible}
            onClick={props.onClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUsSuccessTemplate;
