import React from 'react';
import { TextProps, TextTemplateKey } from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';
import REText from '@components/wrappedBDS/REText';

const SentenceText: React.FC<TextProps<TextTemplateKey>> = (props) => {
  return <REText {...props} className={classNames([props.className, 'important-normal-case'])} />;
};

export default SentenceText;
