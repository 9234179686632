import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '@utils';
import { useWindowSize } from 'react-use';
import kebabCase from 'lodash/kebabCase';
import {
  Button,
  IconNavArrowDown,
  Notification,
  Panel,
} from '@ftdr/blueprint-components-react';
import { NEW_ORDER_LABELS, ORDER_CARD_TITLE } from '@constants/newOrder-constants';
import { isNullOrUndefined } from '@helpers/utils';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

const CardNewOrder = (props) => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(width < 480);
  const cardTitleEnums = Object.freeze(ORDER_CARD_TITLE);

  // collapsed should be controlled by the card itself as it is a view only modification
  const [collapsed, setCollapsed] = useState(props.defaultCollapsed);

  // TODO: This really needs to be moved to the related components and not here.
  const resetFields = () => {
    props.data.setValues({});
    props.data.setErrors?.({});
    switch (props.title) {
      case cardTitleEnums.CLOSING_INFO:
        props.data.setClosingDate(null);
        props.data.setDateCheckboxValue({ dateUnknown: false });
        props.data.setOptionValues({ yes: false, no: false });
        props.data.setShowTitleCompanyFields(false);
        break;

      case cardTitleEnums.AGENT_INFO:
        props.data.setAgentRepresentsOptionValues({ buyer: false, seller: false, both: false });
        props.data.changeSelectedAgent('');
        props.data.setFSBOOptionValue?.('no');
        break;

      case cardTitleEnums.SELLER_INFO:
        props.data.setOptionValues({ alternateAddressFields: false, coSellerFields: false });
        props.data.setSellerOptionValues({ hasSellerInfo: props.isRequired, noSellerInfo: false });
        props.data.setShowAlternateAddressFields(false);
        props.data.setShowCoSellerFields(false);
        props.data.setShowCoSellerAlternateAddressFields(false);
        props.data.setCoSellerOptionValues({ coSellerAlternateAddressFields: false });
        props.data.changeSelectedAgent('');
        props.isRequired
          ? props.data.setShowSellerInfoFields(true)
          : props.data.setShowSellerInfoFields(false);
        props.data.setValues({
          sellerInfo: { yes: props.isRequired, no: false },
        });
        props.data.setTouched({});
        break;

      case cardTitleEnums.PROPERTY_DETAILS:
        props.data.setOptionValues({ newConstruction: false, notNewConstruction: false });
        break;

      case cardTitleEnums.BUYER_INFO:
        props.data.setOptionValues({ alternateAddressFields: false, coBuyerFields: false });
        props.data.setBuyerOptionValues({ hasBuyerInfo: props.isRequired, noBuyerInfo: false });
        props.data.setShowAlternateAddressFields(false);
        props.data.setShowCoBuyerFields(false);
        props.data.setShowCoBuyerAlternateAddressFields(false);
        props.data.setCoBuyerOptionValues({ coBuyerAlternateAddressFields: false });
        props.data.changeSelectedAgent('');
        props.isRequired
          ? props.data.setShowBuyerInfoFields(true)
          : props.data.setShowBuyerInfoFields(false);
        props.data.setValues({
          buyerInfo: { yes: props.isRequired, no: false },
          needEmailOrPhone: props.data.values.needEmailOrPhone,
        });
        props.data.setTouched({});
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    setIsMobile(width < 480);
  }, [width]);

  // Scroll to alternate address fields
  const cardEl = useRef(null);
  useEffect(() => {
    props.isEditing &&
      cardEl.current.scrollIntoView?.({
        block: 'start',
        behavior: 'smooth',
      });
  }, [props.isEditing]);

  const onSubmit = (event) => {
    if (props.onSave) {
      event.preventDefault(); // prevent the form from resolving on its own by default
      props.onSave(event);
      return;
    }

    // TODO: deprecate the props.data usage so the props are not so obscure
    // TODO: We should not have the new order card control the collapse logic. Decision should be given to the parent.
    // This is a temp workaround for now until we make a switch for all cards. See CardNewOrderBuyerInfo of usage.
    if (!props.data.overrideDefaultToggleCollapsed) {
      props.toggleCollapsed();
    }
    props.data.handleSubmit(event);
  };

  const onOpenEdit = (event) => {
    event.stopPropagation();
    if (props.onOpen) {
      props.onOpen();
      return;
    }
    // TODO: deprecate the props.toggleEditing so parent controls it instead
    props.toggleEditing();
  };

  const onCancel = (event) => {
    if (props.onClose) {
      props.onClose();
      return;
    }
    // TODO: deprecate the props.toggleEditing so parent controls it instead
    props.toggleEditing();
  };

  const onReset = (event) => {
    if (props.onClear) {
      props.onClear();
      return;
    }
    // TODO: deprecate the resetFields that was resetting parent data in an obscure way
    resetFields();
  };

  const isCollapsed = () => {
    if (!isNullOrUndefined(props.defaultCollapsed)) {
      return collapsed;
    }
    // TODO: deprecate the usage of props.isCollapsed as main usage of it is uncontrolled and mobile only
    return props.isCollapsed;
  };

  const toggleCollapsed = () => {
    if (props.defaultCollapsed === false || props.defaultCollapsed === true) {
      const newCollapsed = !isCollapsed();
      setCollapsed(newCollapsed);
      // if currently editing, and we are now collapsed, close out of edit mode
      if (props.isEditing && newCollapsed) {
        props.onClose();
      }
      return;
    }
    // TODO: deprecate the props.toggleEditing so parent controls it instead
    props.toggleCollapsed();
  };

  return (
    <div ref={cardEl} className="mt-4">
      <Panel backgroundColor="white" borderT={IsTheme(Theme.Ahs2024) ? undefined : "2"} borderColor="gray-100" shadow={!IsTheme(Theme.Ahs2024)} padding="xl" rounded={IsTheme(Theme.Ahs2024) ? "xl" : undefined}>
        <form onSubmit={onSubmit} noValidate="novalidate">
          <div
            className={classNames([
              'sm:w-full',
              !props.isEditing && 'sm:flex sm:justify-between',
              props.isSmall && !props.isEditing && 'sm:flex-wrap',
            ])}
          >
            <div
              className={classNames([
                'sm:flex sm:justify-between w-full',
                !props.isEditing && !props.isSmall && 'md:w-32 lg:w-48',
              ])}
            >
              <div
                className={classNames([isMobile && '-m-4 p-4 cursor-pointer'])}
                tabIndex={isMobile ? 0 : undefined}
              >
                <REText id={`wb_card-title-${props.title}`} variant="heading-05" className="inline">
                  {props.title}
                </REText>

                <span
                  className={classNames([
                    'sm:hidden sm:ml-2 float-right',
                    props.isSmall && 'float-right',
                    !isCollapsed() && 'rotate-180',
                  ])}
                >
                  <IconNavArrowDown
                    color="black"
                    onClick={isMobile ? toggleCollapsed : undefined}
                  />
                </span>

                {!props.isSmall && (
                  <Button
                    id={`card-new-order__clear-${kebabCase(props.title)}-info-link`}
                    variant="ghost"
                    size="small"
                    className={classNames([
                      'underline',
                      (!props.isEditing || (isCollapsed() && isMobile)) && 'hidden',
                      props.disableEdit ? 'hidden' : 'visible',
                    ])}
                    label={NEW_ORDER_LABELS.CARD_CLEAR}
                    onClick={onReset}
                  />
                )}
              </div>

              <div className={classNames(['sm-max:hidden', !props.isEditing && 'hidden'])}>
                <Button
                  id={`card-new-order__cancel-${kebabCase(props.title)}-link`}
                  variant="ghost"
                  size="small"
                  className="underline"
                  label={NEW_ORDER_LABELS.CARD_CANCEL}
                  onClick={onCancel}
                />
              </div>

              {/* edit button for edit order and for the mobile view of new order */}
              {(props.isSmall || isMobile) && (
                <div
                  className={classNames([
                    'relative float-right sm-max:top-1',
                    (props.isEditing || (isCollapsed() && isMobile)) && 'hidden',
                    props.disableEdit ? 'hidden' : 'visible',
                  ])}
                >
                  <Button
                    id={`card-new-order__edit-${kebabCase(props.title)}-info-link`}
                    variant="ghost"
                    size="small"
                    className="underline"
                    label={NEW_ORDER_LABELS.CARD_EDIT}
                    onClick={onOpenEdit}
                  />
                </div>
              )}
            </div>

            {props.isSmall && <div className="w-full" />}

            {props.alertText && (
              <div className="m-2">
                <Notification status="error" showStatusLabel={false} className="max-w-full w-10/12">
                  {props.alertText}
                </Notification>
              </div>
            )}

            <div
              className={classNames([
                'w-full',
                props.isSmall && !props.isEditing && 'sm:mt-4',
                props.isSmall && !props.isEditing && !isCollapsed() && 'sm-max:mt-4',
              ])}
            >
              {props.children}
            </div>

            {/* edit button seen in desktop view of new order only */}
            {!(props.isSmall || isMobile) && (
              <div
                className={classNames([
                  'relative float-right sm-max:top-1',
                  (props.isEditing || (isCollapsed() && isMobile)) && 'hidden',
                  props.disableEdit ? 'hidden' : 'visible',
                ])}
              >
                <Button
                  id={`wb_edit: ${props.title}`}
                  variant="ghost"
                  size="small"
                  className="underline"
                  label={NEW_ORDER_LABELS.CARD_EDIT}
                  onClick={onOpenEdit}
                />
              </div>
            )}
          </div>

          <div
            className={classNames([
              'mt-4',
              !props.isEditing
                ? 'hidden'
                : 'sm:flex sm:justify-start sm:flex-row-reverse items-center',
            ])}
          >
            {props.submitHelperText && (
              <div className="sm:mr-2 mb-2 sm:mb-0 order-1 text-center">
                <REText variant="body-long" color="primary">
                  {props.submitHelperText}
                </REText>
              </div>
            )}
            <div>
              <Button
                id={`wb_save: ${props.title}`}
                label={NEW_ORDER_LABELS.CARD_SAVE}
                variant="outlined"
                type="submit"
                size="medium"
                width="full"
                labelAlign="center"
                disabled={props.isSaveDisabled}
              />
            </div>

            {/* cancel button displayed in mobile view only */}
            {props.cancelModal && (
              <div className="mt-4 sm:hidden">
                <Button
                  id={`card-new-order__cancel-${kebabCase(props.title)}-link-xs`}
                  className="underline"
                  label={NEW_ORDER_LABELS.CARD_CANCEL}
                  variant="ghost"
                  size="medium"
                  width="full"
                  labelAlign="center"
                  onClick={onCancel}
                />
              </div>
            )}
          </div>

          {!props.isEditing && props.afterSummary && (
            <div className={classNames(['sm-max:pr-10', isMobile && isCollapsed() && 'hidden'])}>
              {props.afterSummary}
            </div>
          )}
        </form>
      </Panel>
    </div>
  );
};

export default CardNewOrder;
