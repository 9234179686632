import { Button, Input } from '@ftdr/blueprint-components-react';
import React from 'react';
import { validateEmailInput } from '@services/validation/NewOrder.FieldValidationRules';
import * as c from '@constants/formField-constants';
import { validateName as ValidateName } from '@services/validation/ValidationRules';
import REText from '@components/wrappedBDS/REText';

const NameType = {
  FIRST: 'first',
  LAST: 'last',
};

const CustomCooperatingAgent = (props) => {
  const validateName = (name: string, nameType: string) => {
    let errorMsg = '';
    if (!name) {
      errorMsg = nameType === NameType.FIRST ? c.FIRST_NAME_REQUIRED : c.LAST_NAME_REQUIRED;
    } else if (nameType === NameType.FIRST && name.length > c.FIRST_NAME_MAX_LENGTH) {
      errorMsg = c.FIRST_NAME_LENGTH_EXCEEDED;
    } else if (nameType === NameType.LAST && name.length > c.LAST_NAME_MAX_LENGTH) {
      errorMsg = c.LAST_NAME_LENGTH_EXCEEDED;
    } else if (ValidateName(name)) {
      errorMsg =
        nameType === NameType.FIRST
          ? c.CUSTOMER_FIRST_NAME_SPECIAL_CHARACTERS_NOT_ALLOWED
          : c.CUSTOMER_LAST_NAME_SPECIAL_CHARACTERS_NOT_ALLOWED;
    }
    return errorMsg;
  };

  const validateEmail = () => {
    props.setErrors({
      ...props.errors,
      email: validateEmailInput(props.cooperatingAgentCustom.email),
    });
  };

  const validateCooperatingFirstName = () => {
    props.setErrors({
      ...props.errors,
      firstName: validateName(props.cooperatingAgentCustom.firstName, NameType.FIRST),
    });
  };

  const validateCooperatingLastName = () => {
    props.setErrors({
      ...props.errors,
      lastName: validateName(props.cooperatingAgentCustom.lastName, NameType.LAST),
    });
  };

  return (
    <>
      <div className="sm:flex sm:justify-between w-full">
        <div>
          <REText id="wb_sellerCooperatingAgent" variant="label" className="inline">
            Cooperating Agent
          </REText>
          <Button
            id="cooperatingAgentSelectAgent-link"
            variant="ghost"
            size="small"
            className="underline"
            label="Choose Agent from List"
            onClick={() => props.setIsActive(false)}
          />
        </div>
      </div>
      <REText id="wb_sellerCooperatingAgent_helper" variant="helper-text" className="italic">
        We will email order details to the Cooperating Agent so they can associate themselves to
        this order.
      </REText>
      <div className="flex-1">
        <div className="w-full flex sm-max:flex-col sm-max:space-y-2 py-2">
          <div className="flex-1">
            <Input
              formField={true}
              type="text"
              inputMode="text"
              id="cooperatingAgentFName"
              name="customCooperatingAgentFirstName"
              value={props.cooperatingAgentCustom.firstName?.trim()}
              error={props.errors.firstName}
              autoComplete="off"
              required={true}
              onChange={(e) => {
                props.setCooperatingAgentCustom({
                  ...props.cooperatingAgentCustom,
                  firstName: e.target.value?.trim(),
                });
              }}
              label="First Name"
              onBlur={validateCooperatingFirstName}
            />
          </div>
          <div className="flex-1 md:pl-8">
            <Input
              formField={true}
              type="text"
              inputMode="text"
              id="cooperatingAgentLName"
              name="customCooperatingAgentLastName"
              required={true}
              value={props.cooperatingAgentCustom.lastName?.trim()}
              error={props.errors.lastName}
              autoComplete="off"
              onChange={(e) => {
                props.setCooperatingAgentCustom({
                  ...props.cooperatingAgentCustom,
                  lastName: e.target.value?.trim(),
                });
              }}
              label="Last Name"
              onBlur={validateCooperatingLastName}
            />
          </div>
        </div>
        <div className="w-full py-2">
          <Input
            formField={true}
            type="email"
            inputMode="email"
            id="cooperatingAgentEmail"
            name="customCooperatingAgentEmail"
            required={true}
            value={props.cooperatingAgentCustom.email?.trim()}
            error={props.errors.email}
            autoComplete="off"
            onChange={(e) => {
              props.setCooperatingAgentCustom({
                ...props.cooperatingAgentCustom,
                email: e.target.value?.trim(),
              });
            }}
            label="Email Address"
            onBlur={validateEmail}
          />
        </div>
      </div>
    </>
  );
};

export default CustomCooperatingAgent;
