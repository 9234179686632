import React, { Component } from 'react';
import { SearchButton } from '@components/button/SearchButton';
import CardUserDetails from '@components/card/CardUserDetails';
import { UserDetails } from '@app/models/profile.model';
import ProfileApi from '@apis/profile.api';
import Drawer from '@components/drawer/Drawer';
import ViewUserDetails from '@components/view/ViewUserDetails';
import { IconSearch, Input, Panel } from '@ftdr/blueprint-components-react';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

interface state {
  searchCriteria: string;
  searchResults: UserDetails[];
  isActive: boolean;
  showClearButton: boolean;
}
export default class AdminSearch extends Component<unknown, state> {
  selectedUser = null;

  constructor(props) {
    super(props);
    this.state = {
      searchCriteria: '',
      searchResults: [],
      isActive: false,
      showClearButton: false,
    };
  }

  onChangeSearch = (event) => {
    this.setState({ searchCriteria: event.target.value });
    if (event.target.value) {
      this.setState({ showClearButton: true });
    } else {
      this.setState({ showClearButton: false });
    }
  };

  handleSearch = () => {
    ProfileApi.searchUsers(this.state.searchCriteria).then((response) => {
      this.setState({ searchResults: response });
    });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  onClickUserDetails = (userDetails) => {
    this.selectedUser = userDetails;
    this.setState({ isActive: true });
  };

  updateUser = (updatedUser) => {
    const tempArray = this.state.searchResults;
    const userIndex = this.state.searchResults.findIndex(
      (user) => user.userID === updatedUser.userID,
    );

    if (userIndex > -1) {
      tempArray[userIndex] = updatedUser;
      this.setState({ searchResults: tempArray });
    }
  };

  removeSearchResult = (userID) => {
    this.setState({
      searchResults: this.state.searchResults.filter((user) => user.userID !== userID),
      isActive: false,
    });
  };

  noResultsFound = (text, isDisabled) => {
    return (
      <div
        className="flex flex-col justify-center items-center mt-10"
        style={{ marginBottom: '100px' }}
      >
        <IconSearch className="mb-5" size={72} color={isDisabled ? 'gray' : 'black'} />
        <REText
          variant="heading-06"
          className="flex md:hidden text-center"
          color={isDisabled ? 'gray' : 'black'}
        >
          {text}
        </REText>
        <REText
          variant="heading-04"
          className="hidden md:flex text-center"
          color={isDisabled ? 'gray' : 'black'}
        >
          {text}
        </REText>
      </div>
    );
  };

  getUsers = () => {
    return (
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-6">
        {this.state.searchResults.map((user) => {
          return (
            <div key={user.userID}>
              <CardUserDetails user={user} onClick={() => this.onClickUserDetails(user)} />
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <Panel
        className="mt-6"
        shadow={IsTheme(Theme.Ahs2024) ? false : true}
        border="0"
        rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'sm'}
        padding="xl"
      >
        <div className="flex items-center flex-col md:flex-row">
          <div className="flex-1 card card--search shadow-none w-full" style={{ float: 'left' }}>
            <Input
              type="text"
              name="search"
              label="Search by first name, last name, user type, or office affiliation"
              placeholder="Search by first name, last name, user type, or office affiliation"
              className="p-0 mb-6 md:mb-0"
              startEnhancer={<IconSearch />}
              value={this.state.searchCriteria}
              onChange={this.onChangeSearch}
              autoComplete="off"
              onKeyDown={this.handleKeyDown}
              autoFocus={true}
              showClearButton={this.state.showClearButton}
            />
          </div>
          <div className="md:pl-6 sm-max:w-full">
            <SearchButton
              id="adminSearchButton"
              onClick={this.handleSearch}
              className="flex items-center"
              isDisabled={this.state.searchCriteria ? false : true}
            />
          </div>
        </div>
        {!this.state.searchCriteria || this.state.searchCriteria === ''
          ? this.noResultsFound('Enter Search Terms for Results', true)
          : this.state.searchResults.length > 0
            ? this.getUsers()
            : this.noResultsFound('No Search Results Available', false)}
        <Drawer isActive={this.state.isActive} onClose={() => this.setState({ isActive: false })}>
          {this.selectedUser && this.state.isActive && (
            <ViewUserDetails
              user={this.selectedUser}
              updateUser={this.updateUser}
              removeUser={this.removeSearchResult}
            />
          )}
        </Drawer>
      </Panel>
    );
  }
}
