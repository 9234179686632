import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconCheckCircledCheckedFilled,
  IconCheckCircledCheckedOutline,
  Link,
  Panel
} from '@ftdr/blueprint-components-react';
import ContractApi, { ContractApiSuppressErrors } from '@apis/contract.api';
import { fireGAEvent } from '@app/core/tracking.service';
import { ORDER__MAKE_PAYMENT } from '@constants/ga-events.constants';
import { MILITARY_DISCOUNT, PAYMENTS } from '@constants/newOrder-constants';
import MakePaymentStripe from '@components/drawer/subcomponents/MakePaymentStripe';
import Path from '@constants/paths';
import OrdersApi from '@apis/orders.api';
import LoadingIndicator from '@components/spinner/LoadingIndicator';
import SentenceText from '@components/SentenceText';
import { IsTheme, Theme } from "@app/core/featureToggle";

const OrderSubmittedTemplate = (props) => {
  const [notificationEmailList, setEmailList] = useState([]);
  const [noticeTypes, setNoticeTypes] = useState([]);
  const [newOrderData, setnewOrderData] = useState([]);
  const [contractId, setContractId] = useState(0);

  const [sendDocumentHeaderText, setSendDocumentHeaderText] = useState(
    `Order #${props.contractId} has been submitted`,
  );
  const [pendingSendDocument, setPendingSendDocument] = useState(true);

  // payment stuff
  const [pendingMakePayment, setPendingMakePayment] = useState(false);
  const [contractPaymentBalance, setContractPaymentBalance] = useState(null);
  const [contractDetails, setContractDetails] = useState();
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const ref = useRef(null);
  // don't care about this if we dont pass in orderID
  const [orderFound, setOrderFound] = useState(
    props.orderID === null || props.orderID === undefined,
  );
  const [orderID, setOrderID] = useState(props.orderID);

  function handleCSCCCPaymentCall(request, contractID) {
    console.log(request);
    fireGAEvent(ORDER__MAKE_PAYMENT(PAYMENTS.CC, contractID));
    return ContractApi.chargeCC(request, contractID);
  }

  function handleCSCACHPaymentCall(request, contractID) {
    console.log(request);
    fireGAEvent(ORDER__MAKE_PAYMENT(PAYMENTS.ACH, contractID));
    return ContractApi.chargeACH(request, contractID);
  }

  function handleMakePaymentSuccess() {
    setSendDocumentHeaderText(`${sendDocumentHeaderText} and successfully paid.`);
    setPendingMakePayment(false);
    setPaymentSuccess(true);
  }

  function getOrders() {
    OrdersApi.get(orderID).then((res) => {
      if (res.status !== 'PENDING') {
        setOrderFound(true);
        clearInterval(ref.current);
      } else if (res.status === 'FAILED') {
        clearInterval(ref.current);
      }
    });
  }
  useEffect(() => {
    if (props.newOrderData) {
      setnewOrderData(props.newOrderData);
      setContractId(props.contractId);
    }
  }, [props.newOrderData]);

  useEffect(() => {
    if (newOrderData) {
      // TODO recheck setNoticeTypes logic when using actual API response
      setNoticeTypes({
        Confirmation: newOrderData.summary && newOrderData.summary['status'] == 'L' ? true : true,
        Invoice: !!(newOrderData.closingInfo && newOrderData?.closingInfo['projectedClosingDate']),
        // TODO Need to uncomment after the initial-lauch
        // 'Contract': (newOrderData.closingInfo && newOrderData.closingInfo['projectedClosingDate']) || (newOrderData.summary && newOrderData.summary['status'] == 'A')? true : false
      });

      const temp = {};
      temp.Myself = props.userDetails?.email || '';
      if (newOrderData.buyerInfo) {
        if (newOrderData.buyerInfo.email) {
          temp.Buyer = newOrderData.buyerInfo.email;
        }
        if (newOrderData.buyerInfo.coCustomerEmail) {
          temp.CoBuyer = newOrderData.buyerInfo.coCustomerEmail;
        }
        if (newOrderData.buyerInfo.AgentEmail) {
          temp.CooperatingAgent = newOrderData.buyerInfo.AgentEmail;
        }
      }
      if (newOrderData.sellerInfo) {
        if (newOrderData.sellerInfo.email) {
          temp.Seller = newOrderData.sellerInfo.email;
        }
        if (newOrderData.sellerInfo.coCustomerEmail) {
          temp.CoSeller = newOrderData.sellerInfo.coCustomerEmail;
        }
        if (newOrderData.sellerInfo.AgentEmail) {
          temp.CooperatingAgent = newOrderData.sellerInfo.AgentEmail;
        }
      }
      if (newOrderData.agentInfo && newOrderData.agentInfo.AgentEmail) {
        temp.InitiatingAgent = newOrderData.agentInfo.AgentEmail;
      }
      if (newOrderData.closingInfo && newOrderData.closingInfo.AgentEmail) {
        temp.ClosingAgent = newOrderData.closingInfo.AgentEmail;
      }

      for (const role in temp) {
        if (
          Object.prototype.hasOwnProperty.call(temp, role) &&
          role !== 'Myself' &&
          temp.Myself &&
          temp.Myself.localeCompare(temp[role]) === 0
        ) {
          delete temp[role];
        }
      }

      setEmailList(temp);
    }

    if (props.roFlow) {
      if (orderFound) {
        ContractApi.getContractDetails([props.contractId], true, true).then((contract) => {
          setContractDetails(contract[0]);
          ContractApi.getContractBalance(props.contractId).then((res) => {
            setContractPaymentBalance(res);
            setPendingMakePayment(true);
          });
        });
      }
    }
  }, [newOrderData, orderFound]);

  useEffect(() => {
    if (!orderFound && props.roFlow) {
      ref.current = setInterval(getOrders, 5000);
    }
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, [orderFound]);

  /** when all steps are complete, then */
  useEffect(() => {
    if (!pendingSendDocument && !pendingMakePayment) {
      console.log('completed all steps, redirecting user...');
      props.navigate(Path.Dashboard, { state: { notificationSent: true } });
    }
  }, [pendingSendDocument, pendingMakePayment]);

  return (
    <>
      <OrderConfirmationPanel>
        {paymentSuccess ? (
          <SentenceText variant="heading-03" className="pb-4">
            {sendDocumentHeaderText}
          </SentenceText>
        ) : (
          <SentenceText variant={IsTheme(Theme.AhsWebApp) ? "heading-05" : "body-long"}>
            {`Order ${props.contractId ? '#' + props.contractId : ''} has been submitted and is being processed. Please note that it may take a few minutes to process your submission.
                  You will receive a confirmation email once processing is complete. Please contact our `}
            <Link href={Path.ContactUs} color="interactive" bold>
              customer support
            </Link>
            &nbsp; if you do not see a confirmation email from us.
          </SentenceText>
        )}
      </OrderConfirmationPanel>

      {pendingMakePayment && (
        <Panel
          border="1"
          borderColor="gray-300"
          padding="none"
          className='card mt-6 px-4 sm:px-12 lg:px-32 py-8 sm:py-10'
          rounded={`${IsTheme(Theme.Ahs2024) ? 'xl' : 'sm'}`}
          shadow={!IsTheme(Theme.Ahs2024)}
        >
          <MakePaymentStripe
            id="drawer-make-payment2"
            isActive={pendingMakePayment}
            hideSuccessAlert={pendingSendDocument} // only hide success alert if user hasn't sent documents yet
            onClose={() => setPendingMakePayment(false)}
            submitACHPayment={handleCSCACHPaymentCall}
            submitCCPayment={handleCSCCCPaymentCall}
            onSuccess={handleMakePaymentSuccess}
            contractBalance={Number(contractPaymentBalance?.currentBalance)}
            totalPrice={contractPaymentBalance?.details.total}
            creditAmount={contractPaymentBalance?.details.credit}
            discountAmount={contractPaymentBalance?.details.discounts}
            militaryDiscountApplied={contractPaymentBalance?.appliedSpecialDiscounts?.includes(
              MILITARY_DISCOUNT,
            )}
            contract={contractDetails}
            hideCancelButton={props.roFlow}
            closeDrawer={() => console.log('closeDrawer called')}
            setShowPaymentSuccessModal={() => console.log('setShowPaymentSuccessModal called')}
            sourcePage="Order Submission page"
          />
        </Panel>
      )}
      {!orderFound && props.roFlow && (
        <div className="card mt-6 px-4 sm:px-12 lg:px-32 py-8 sm:py-10">
          <LoadingIndicator />
        </div>
      )}
    </>
  );
};

const OrderConfirmationPanel = (props) => {
  if (IsTheme(Theme.AhsWebApp)) {
    return (
      <div className="flex flex-col items-center card px-4 sm:px-12 lg:px-32 py-8 sm:py-10" id="order-confirmation-panel">
        <div className="align-center">
          <IconCheckCircledCheckedOutline color="success" size={96} className="mb-8" />
        </div>
        <div className="lg:px-20">
          {props.children}
        </div>
      </div>
    );
  } else {
    return (
      <Panel padding="none" className="card flex flex-col mt-6 px-4 sm:px-12 lg:px-32 py-8 sm:py-10 justify-center items-center" id="order-confirmation-panel">
        <div className="align-center">
          <IconCheckCircledCheckedFilled color="interactive" size={96} className="mb-8" />
        </div>
        <div className="px-5 md:px-20">
          {props.children}
        </div>
      </Panel>
    );
  }
};

export default OrderSubmittedTemplate;
