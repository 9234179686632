import FormFieldset from '@components/form/FormFieldset';
import { Input } from '@ftdr/blueprint-components-react';
import * as c from '@constants/formField-constants';
import React, { useEffect, useState } from 'react';
import { isEmailValid, isPhoneNumberValid } from '@services/validation/ValidationRules';
import { CustomerFields } from '@components/input/CustomerInfoInput';
import { cleanPhone, maskPhoneNumber } from '@helpers/utils';
import { CustomerData } from '@apis/models';
import { IsTheme, Theme } from '@app/core/featureToggle';

export interface CustomerInputFieldsProps {
  customerType: string;
  customer: CustomerData;
  onInputFieldChanged: (customer: CustomerData) => void;
  onBlurAllInputs: (doErrorsExist: boolean) => void;
  isDisabled: boolean;
  inputIdPrefix?: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

const CustomerInputFields = (props: CustomerInputFieldsProps) => {
  const [errors, setErrors] = useState<FormErrors>({});
  const [customer, setCustomer] = useState(props.customer);
  const [originalCustomerInfo, setOriginalCustomerInfo] = useState<CustomerData>(null);

  /* On load, mask the phone number */
  useEffect(() => {
    const temp = { ...customer };
    temp[CustomerFields.phone] = maskPhoneNumber(customer.phone || '');
    setCustomer(temp);

    setOriginalCustomerInfo({
      firstName: props.customer.firstName,
      lastName: props.customer.lastName,
      email: props.customer.email,
      phone: temp.phone,
    });
  }, []);

  function updateCustomerInfo(value, field) {
    const temp = { ...customer };
    temp[field] = value || '';
    setCustomer(temp);
    props.onInputFieldChanged(temp);
  }

  function updatePhoneWithMask(phone) {
    const temp = { ...customer };
    temp[CustomerFields.phone] = maskPhoneNumber(phone || '');
    setCustomer(temp);
    props.onInputFieldChanged(temp);
  }

  function handleBlur(editField: string) {
    let isError,
      hasValueStayedSame: boolean = false;
    const errorsCopy: FormErrors = errors;

    switch (editField) {
      case CustomerFields.firstName:
        if (customer.firstName === originalCustomerInfo?.firstName) {
          hasValueStayedSame = true;
          break;
        }
        isError = !customer.firstName || customer.firstName.length > c.FIRST_NAME_MAX_LENGTH;
        errorsCopy.firstName = isError ? c.FIRST_NAME_REQUIRED : '';
        break;
      case CustomerFields.lastName:
        if (customer.lastName === originalCustomerInfo?.lastName) {
          hasValueStayedSame = true;
          break;
        }
        isError = !customer.lastName || customer.lastName.length > c.LAST_NAME_MAX_LENGTH;
        errorsCopy.lastName = isError ? c.LAST_NAME_REQUIRED : '';
        break;
      case CustomerFields.phone:
        if (customer.phone === originalCustomerInfo?.phone) {
          hasValueStayedSame = true;
          break;
        }
        isError = !isPhoneNumberValid(cleanPhone(customer.phone));
        errorsCopy.phone = isError
          ? customer.phone.length > 0
            ? c.INVALID_PHONE_NUMBER
            : c.PHONE_NUMBER_REQUIRED
          : '';
        break;
      case CustomerFields.email:
        if (customer.email === originalCustomerInfo?.email) {
          hasValueStayedSame = true;
          break;
        }
        isError = !isEmailValid(customer.email);
        errorsCopy.email = isError
          ? customer.email.length > 0
            ? c.INVALID_EMAIL
            : c.EMAIL_REQUIRED
          : '';
        break;
      default:
        console.log('Error: Invalid field: ', editField);
        break;
    }

    setErrors(errorsCopy);
    props.onBlurAllInputs(hasFormErrors(errorsCopy, editField, hasValueStayedSame));
  }

  // hasFormErrors would return true if any field has an error AND that field has changed value
  function hasFormErrors(
    errorCheck: FormErrors,
    editField: string,
    hasValueStayedSame: boolean,
  ): boolean {
    let { firstName, lastName, phone, email } = errorCheck;

    // if the field in question has not changed, remove the error
    switch (editField) {
      case CustomerFields.firstName:
        if (hasValueStayedSame) firstName = '';
        break;
      case CustomerFields.lastName:
        if (hasValueStayedSame) lastName = '';
        break;
      case CustomerFields.phone:
        if (hasValueStayedSame) phone = '';
        break;
      case CustomerFields.email:
        if (hasValueStayedSame) email = '';
        break;
      default:
        console.log('Error: Invalid field: ', editField);
        return true;
    }

    return !!firstName || !!lastName || !!phone || !!email;
  }

  const ahs2024FormText = {
    BUYER_INFORMATION: 'Buyer Information',
    SELLER_INFORMATION: 'Seller Information',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    EMAIL_ADDRESS: 'Email Address',
    PHONE_NUMBER: 'Phone Number'
  }
  const formText = {
    BUYER_INFORMATION: 'BUYER INFORMATION',
    SELLER_INFORMATION: 'SELLER INFORMATION',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    EMAIL_ADDRESS: 'Email address',
    PHONE_NUMBER: 'Phone number'
  }

  const getFormText = (key: string) =>
    IsTheme(Theme.Ahs2024) ?
      ahs2024FormText[key] :
      formText[key];

  const customerTypeText = (customerType: string) =>
    (customerType === CustomerFields.buyer) ?
      getFormText("BUYER_INFORMATION") :
      getFormText("SELLER_INFORMATION");


  return (
    <div className="max-w-screen-lg">
      <FormFieldset
        heading={customerTypeText(props.customerType)}
        headingClassName={`sm-max:!mb-0 ${IsTheme(Theme.Ahs2024) ? '' : 'text-gray'}`}
      >
        <div className="flex flex-col -mx-2">
          <div className="flex w-full xs-max:flex-col">
            <div className={IsTheme(Theme.Ahs2024) ? "w-full sm:mr-10 p-2" : "w-full sm:w-1/2 sm:mr-10 p-2"}>
              <Input
                id={props.inputIdPrefix?.length > 0 ? `${props.inputIdPrefix}_first` : ''}
                formField
                required
                type="text"
                name="first-name"
                label={getFormText('FIRST_NAME')}
                onChange={(event) =>
                  updateCustomerInfo(event.target.value, CustomerFields.firstName)
                }
                onBlur={() => handleBlur(CustomerFields.firstName)}
                error={errors.firstName}
                value={customer.firstName}
                maxLength={c.FIRST_NAME_MAX_LENGTH}
                disabled={props.isDisabled}
              />
            </div>
            <div className={IsTheme(Theme.Ahs2024) ? "w-full p-2" : "w-full sm:w-1/2 p-2"}>
              <Input
                id={props.inputIdPrefix?.length > 0 ? `${props.inputIdPrefix}_last` : ''}
                formField
                required
                type="text"
                name="last-name"
                label={getFormText('LAST_NAME')}
                onChange={(event) =>
                  updateCustomerInfo(event.target.value, CustomerFields.lastName)
                }
                onBlur={() => handleBlur(CustomerFields.lastName)}
                error={errors.lastName}
                value={customer.lastName}
                maxLength={c.LAST_NAME_MAX_LENGTH}
                disabled={props.isDisabled}
              />
            </div>
          </div>

          <div className="flex w-full xs-max:flex-col">
            <div className={IsTheme(Theme.Ahs2024) ? "w-full sm:mr-10 p-2" : "w-full sm:w-1/2 sm:mr-10 p-2"}>
              <Input
                id={props.inputIdPrefix?.length > 0 ? `${props.inputIdPrefix}_email` : ''}
                formField
                required
                type="email"
                name="email"
                label={getFormText('EMAIL_ADDRESS')}
                onBlur={() => handleBlur(CustomerFields.email)}
                error={errors.email}
                hint={errors.email}
                onChange={(event) => updateCustomerInfo(event.target.value, CustomerFields.email)}
                value={customer.email}
                disabled={props.isDisabled}
              />
            </div>
            <div className={IsTheme(Theme.Ahs2024) ? "w-full p-2" : "w-full sm:w-1/2 p-2"}>
              <Input
                id={props.inputIdPrefix?.length > 0 ? `${props.inputIdPrefix}_phone` : ''}
                formField
                required
                type="tel"
                name="phone"
                label={getFormText('PHONE_NUMBER')}
                maxLength={c.PHONE_WITH_MASK_MAX_LENGTH}
                onBlur={() => handleBlur(CustomerFields.phone)}
                error={errors.phone}
                hint={errors.phone}
                onChange={(event) => updatePhoneWithMask(event.target.value)}
                value={customer.phone}
                disabled={props.isDisabled}
              />
            </div>
          </div>
        </div>
      </FormFieldset>
    </div>
  );
};

export default CustomerInputFields;
