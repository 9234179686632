import React, { useEffect, useState } from 'react';
import { getBrand } from '@helpers/brand.utils';
import {
  Ahs2024LogoColor,
  Ahs2024LogomarkColor,
  Ahs50YearsLogoColor,
  App,
  GlobalHeader,
  GlobalHeaderButtonProps,
  GlobalHeaderUtilityHeaderIconLinkProps,
  HsaLogoColor,
  IconPhone,
  IconUser,
  NavMenuItem,
} from '@ftdr/blueprint-components-react';
import { Brand } from '@constants/brands';
import { useNavigate } from 'react-router-dom';
import Path from '@constants/paths';
import { IsTheme, Theme } from '@app/core/featureToggle';

interface NavRef {
  Title: string;
  Url: string;
}

interface NavTitle {
  Title: string;
}

interface NavLinks {
  Home: NavRef;
  RealEstate: NavRef;
  Why?: NavRef;
  Plans: NavRef;
  FAQs: NavTitle;
}

interface HeaderLinks {
  Phone: NavRef;
  About: NavRef;
  ContactUs: NavTitle;
}

const AHS_MENU: NavLinks = {
  Home: {
    Title: '',
    Url: 'https://www.ahs.com',
  },
  RealEstate: {
    Title: 'Pro Home',
    Url: 'https://www.ahs.com/real-estate/',
  },
  Why: {
    Title: 'Why AHS',
    Url: 'https://www.ahs.com/real-estate/why-ahs/',
  },
  Plans: {
    Title: 'Real Estate Plans',
    Url: 'https://www.ahs.com/real-estate/why-ahs/',
  },
  FAQs: {
    Title: 'FAQs',
  },
};

const AHS_HEADER: HeaderLinks = {
  Phone: {
    Title: '800 735 4663',
    Url: 'tel:8007354663',
  },
  About: {
    Title: 'About',
    Url: 'https://www.ahs.com/about/',
  },
  ContactUs: {
    Title: 'Contact Us',
  },
};

const AHS_LOGIN: NavTitle = {
  Title: 'MyAccount PRO',
};

const HSA_MENU: NavLinks = {
  Home: {
    Title: '',
    Url: 'https://www.onlinehsa.com',
  },
  RealEstate: {
    Title: 'Real Estate Professionals',
    Url: 'https://www.onlinehsa.com/real-estate-agents/',
  },
  Why: null,
  Plans: {
    Title: 'Coverge & Cost',
    Url: 'https://www.onlinehsa.com/coverage-cost/',
  },
  FAQs: {
    Title: 'FAQs',
  },
};

const HSA_LOGIN: NavTitle = {
  Title: 'MyAccount',
};

const HSA_HEADER: HeaderLinks = {
  Phone: {
    Title: '800 367 1448',
    Url: 'tel:8003671448',
  },
  About: {
    Title: 'About',
    Url: 'https://www.onlinehsa.com/about/',
  },
  ContactUs: {
    Title: 'Contact Us',
  },
};

const HeaderNoAuth: React.FC = () => {
  const brand = getBrand();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState<NavMenuItem[]>([]);
  const [headerIconLinks, setHeaderIconLinkProps] = useState<
    GlobalHeaderUtilityHeaderIconLinkProps[]
  >([]);
  const [signInButtonProps, setSignInButtonProps] = useState<GlobalHeaderButtonProps>();

  useEffect(() => {
    if (brand === Brand.AHS) {
      initMenuItems(AHS_MENU);
      initHeaderItems(AHS_HEADER);
      initSignIn(AHS_LOGIN);
    } else {
      // brand == HSA
      initMenuItems(HSA_MENU);
      initHeaderItems(HSA_HEADER);
      initSignIn(HSA_LOGIN);
    }
  }, []);

  const initMenuItems = (menu: NavLinks) => {
    let items = [];
    items = [
      ...items,
      { id: 'nav-real-estate', label: menu.RealEstate.Title, href: menu.RealEstate.Url },
    ];
    if (menu.Why != null) {
      items = [...items, { id: 'nav-why', label: menu.Why.Title, href: menu.Why.Url }];
    }
    items = [...items, { id: 'nav-plans', label: menu.Plans.Title, href: menu.Plans.Url }];
    items = [
      ...items,
      { id: 'nav-faqs', label: menu.FAQs.Title, onClick: () => navigate(Path.FAQ) },
    ];
    setMenuItems(items);
  };

  const initHeaderItems = (header: HeaderLinks) => {
    setHeaderIconLinkProps([
      {
        id: 'nav-call-us',
        href: header.Phone.Url,
        children: header.Phone.Title,
        startIcon: <IconPhone />,
        placementOnMobile: 'mobile-menu',
      },
    ]);
  };

  const initSignIn = (login: NavTitle) => {
    setSignInButtonProps({
      label: login.Title,
      variant: 'ghost',
      size: 'small',
      placementOnMobile: 'main-header',
      startIcon: <IconUser />,
      onClick: (e) => {
        navigate(Path.Dashboard);
        e.preventDefault();
      },
    });
  };

  const getHeaderLogo = (variant, isDesktop) => {
    if (brand === 'AHS') {
      if (IsTheme(Theme.Ahs2024)) {
        return isDesktop ? <Ahs2024LogoColor height={50} /> : <Ahs2024LogomarkColor height={50} />;
      }
      return isDesktop ? <Ahs50YearsLogoColor height={50} /> : <Ahs50YearsLogoColor height={50} />;
    }
    if (brand === 'HSA') {
      return isDesktop ? <HsaLogoColor height={50} /> : <HsaLogoColor height={50} />;
    }
  };

  /** header CTA buttons for top-right corner */
  const ctaButtons = [signInButtonProps].filter((v) => !!v); // clears out null buttons from being included

  return (
    <App>
      <div>
        <GlobalHeader
          logoRenderer={(variant, isDesktop) => getHeaderLogo(variant, isDesktop)}
          navMenuItems={menuItems}
          ctaButtons={ctaButtons}
          onNavMenuItemActivate={(id) => {}}
          utilityHeaderIconLinks={headerIconLinks}
        />
      </div>
    </App>
  );
};

export default HeaderNoAuth;
