import React from 'react';
import { Button, Panel } from '@ftdr/blueprint-components-react';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

export interface SubmitSuccessProps {
  title: string;
  message: string;
  buttonText: string;
  isButtonVisible: boolean;
  onClick?: any;
}

const SumbitSuccess: React.FC<SubmitSuccessProps> = (props: SubmitSuccessProps) => {
  return (
    <Panel rounded={IsTheme(Theme.Ahs2024) ? "xl" : "md"} shadow={!IsTheme(Theme.Ahs2024)}>
      <div className="px-4 pt-4">
        <REText id="contact-success-title" variant="heading-05">
          {props.title}
        </REText>
      </div>
      <div className="px-4 pt-4">
        <REText id="contact-success-message">{props.message}</REText>
      </div>
      <div className="p-8">
        {props.isButtonVisible && (
          <div className={IsTheme(Theme.Ahs2024) ? "media-upload-format py-6 lg:m-auto button-pill" : ""}>
            <Button
              id="contact-success-button"
              label={props.buttonText}
              variant="outlined"
              shape="rounded"
              onClick={props.onClick}
              width={IsTheme(Theme.Ahs2024) ? "full" : "auto"}
              className={IsTheme(Theme.Ahs2024) ? "button-pill" : ""}
            />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default SumbitSuccess;
