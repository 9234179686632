// TODO Investigate why we are making double calls to the contract endpoint.

import React, { Component } from 'react';
import Layout from '@components/layout/Layout';
import QuotesTemplate from '@templates/order/QuoteTemplate';
import RealEstateQuoteApi from '@services/apis/realestatequote.api';
import {
  AgentSearchRecord,
  Contract,
  Office,
  REOrderSearchRequest,
  REQuoteSearchMetaStatus,
  REQuoteSearchRequest,
} from '@apis/models';
import OfficeApi from '@apis/office.api';
import ProfileContext from '../../context/ProfileContext/index';
import ProfileModel from '@app/models/profile.model';
import { FilterChangeEvent, FilterTagItem } from '@components/misc/misc.models';
import { DateFormat, formatDate, formatDateToISO8601, SECOND } from '@helpers/utils';
import AgentApi from '@apis/agent.api';
import {
  officeTypeDictionary,
  orderStatusDictionary,
  quotesStatusIDDictionary,
} from '@constants/dictionaries';
import {
  FilterOperation,
  FilterType,
  QuoteStatus,
  RealEstateStatus,
} from '@constants/dashboardFilters';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { getBrand } from '@helpers/brand.utils';
import { getProfileOfficesByType } from '@services/helpers/profile.offices.helper';
import { OfficeType } from '@app/models';
import { loadQualtric } from '@app/core/qualtric.service';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  NAVIGATION__ORDER_FILTER_SORT,
  NAVIGATION__ORDER_SEARCH,
} from '@constants/ga-events.constants';
import Path from '@constants/paths';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  RefreshContractServiceSubscriber,
  subscribeToRefreshedREOrders,
  unsubscribeRefreshedREOrders,
} from '@app/core/refreshContract.service';
import { Quote } from '@apis/models/quote.api.model';

let loadStatusCanceler = RealEstateQuoteApi.getCancelTokenSource();
let loadQuotesCanceler = RealEstateQuoteApi.getCancelTokenSource();

interface Meta {
  asc?: boolean;
  limit?: number;
  page?: number;
  sortBy?: string;
}

export interface QuotePreFilter {
  removeQuery?: boolean;
  filterText?: string;
  quoteStatuses?: string[];
  closingDateRange?: Date[];
  entryDateRange?: Date[];
  expirationDateRange?: Date[];
  offices?: number[];
  agents?: number[];
  missingCOEOnly?: boolean;
  meta?: Meta;
  openOrderDrawerContractId?: string;
  officeLimitBypass?: boolean;
}

interface QuoteState {
  isOnLoad: boolean;
  isLoadingQuotes: boolean;
  quoteIDs: string[];
  orders: Contract[];
  userDetails: ProfileModel;
  userOffices: Office[];
  agentsFilterList: AgentSearchRecord[];
  quoteSummary: Quote[];
  orderSearchFilter: REOrderSearchRequest;
  filterTagItems: FilterTagItem[];
  totalQuotes: number;
  loadProfileQuotesCanceler: any; // TODO: tighten up typing
  loadStatusCanceler: any;
  totalStatus: REQuoteSearchMetaStatus;
  totalAwaitingWlkSubmission: number;
  officeCount: number;
  hasTooManyOffices: boolean;
  searchText: string;
  preFilter: QuotePreFilter;
}

const ORDER_PAGE_SIZE = 15;

const TOO_MANY_OFFICES_THRESHOLD = 25;

const GAEventDelaySeconds = 1;

const MIN_CHAR_COUNT_SEARCH_FOR_GA = 3;

const DEFAULT_PRE_FILTER = {
  removeQuery: false,
  filterText: '',
  quoteStatuses: [
    orderStatusDictionary[QuoteStatus.SAVED],
    orderStatusDictionary[QuoteStatus.SHARED],
  ],
  closingDateRange: [],
  entryDateRange: [],
  expirationDateRange: [],
  offices: [],
  agents: [],
  missingCOEOnly: false,
  meta: {
    asc: true,
    limit: ORDER_PAGE_SIZE,
    page: 0,
    sortBy: 'EXPDATE',
  },
};

class Quotes extends Component<any, QuoteState> {
  static contextType = ProfileContext;
  private readonly brand = getBrand();
  private quotesSearchTimeoutFunction = null;
  private countCharSearchForGA = 0;
  private queryFilterSubscription: Subscription = null;
  private queryFilterSubject = new BehaviorSubject(null);
  private queryFilter$ = this.queryFilterSubject.asObservable().pipe(
    filter((value) => value !== null),
    distinctUntilChanged(),
    debounceTime(200), // wait 200ms before doing another request
  );

  constructor(props) {
    super(props);
    // Set default state
    this.state = {
      userDetails: new ProfileModel(),
      userOffices: [],
      isOnLoad: true,
      isLoadingQuotes: false,
      quoteIDs: [],
      orders: [],
      quoteSummary: [],
      orderSearchFilter: new REOrderSearchRequest(ORDER_PAGE_SIZE),
      filterTagItems: [],
      agentsFilterList: [],
      totalQuotes: 0,
      loadProfileQuotesCanceler: undefined,
      loadStatusCanceler: undefined,
      totalStatus: null,
      totalAwaitingWlkSubmission: null,
      officeCount: 0,
      hasTooManyOffices: false,
      searchText: '',
      preFilter: props.preFilter,
    };

    // Bind this to all functions
    this.loadProfileQuoteTotals = this.loadProfileQuoteTotals.bind(this);
    this.loadProfileQuotes = this.loadProfileQuotes.bind(this);
    this.loadUserQuotes = this.loadUserQuotes.bind(this);
    this.refreshQuotes = this.refreshQuotes.bind(this);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.updateOfficeFilter = this.updateOfficeFilter.bind(this);
    this.updateAgentFilter = this.updateAgentFilter.bind(this);
    this.updateDateFilter = this.updateDateFilter.bind(this);
    this.updateStatusFilter = this.updateStatusFilter.bind(this);
  }

  async componentDidMount() {
    const { profile } = this.context;

    // QUALTRICSSURVEY
    loadQualtric(10000);

    if (profile) {
      this.setState({ userDetails: profile }, async () => {
        const officeCount = await this.loadUserQuotes();

        // Handle pre-filtering
        if (window.location.search !== '') {
          const preFilter = this.getQuotePreFilterFromUrlQuery();
          await this.applyOrderPreFilter(preFilter);
        } else if (this.state.preFilter && Object.keys(this.state.preFilter).length > 0) {
          const preFilter = this.getQuotePreFilterFromRouteData(this.state.preFilter);
          await this.applyOrderPreFilter(preFilter);
        } else {
          const preFilter = this.getQuotePreFilterFromRouteData(DEFAULT_PRE_FILTER);
          await this.applyOrderPreFilter(preFilter);
        }

        if (officeCount > TOO_MANY_OFFICES_THRESHOLD) {
          this.setState({ officeCount });

          if (!this.props.preFilter?.officeLimitBypass) {
            return this.setState({ hasTooManyOffices: true, isOnLoad: false });
          }
        }

        this.loadProfileQuoteTotals();
        this.loadProfileQuotes();
      });
      this.handleQueryFilterChange();
    }

    subscribeToRefreshedREOrders(RefreshContractServiceSubscriber.OrdersPage, this.refreshQuotes);
  }

  componentWillUnmount() {
    loadStatusCanceler?.cancel();
    loadQuotesCanceler?.cancel();
    this.queryFilterSubscription?.unsubscribe();
    unsubscribeRefreshedREOrders(RefreshContractServiceSubscriber.OrdersPage);
  }

  async loadUserQuotes(): Promise<number> {
    // If user is a CC admin or agent, we only want to include CC's
    const closingCompanyOfficesOnly = ['ClosingCompanyAgent', 'ClosingCompanyAdmin'].includes(
      this.state.userDetails.roleIDType,
    );
    const officeType = closingCompanyOfficesOnly
      ? OfficeType.ClosingCompany
      : OfficeType.RealEstate;

    const offices = getProfileOfficesByType(this.state.userDetails, officeType);

    const userOffices = await OfficeApi.getOfficeList(
      offices.map((o) => {
        return { id: o.id, type: o.type };
      }),
    );
    this.setState({ userOffices: userOffices.offices });

    return userOffices?.offices?.length || 0;
  }

  parseYYYYMMDD(str: string): Date {
    if (str.length !== 8) return null;
    try {
      return new Date(
        parseInt(str.substr(0, 4)),
        parseInt(str.substr(4, 2)) - 1,
        parseInt(str.substr(6, 2)),
      );
    } catch (e) {
      console.error('Date parsing failed:', e);
      return null;
    }
  }

  getDateRangeFromYYYYMMDDArray(dates: string[]): Date[] {
    if (dates.length !== 2) return [];
    const mapped = dates.map(this.parseYYYYMMDD);
    return mapped[0] && mapped[1] ? mapped : [];
  }

  getQuotePreFilterFromUrlQuery(): QuotePreFilter {
    const query = new URLSearchParams(window.location.search);

    const preFilter = {
      removeQuery: (query.get('removeQuery') || 'true') === 'true',
      filterText: query.get('filterText') || '',
      quoteStatuses: query.get('quoteStatuses')?.split(',') || [],
      closingDateRange: this.getDateRangeFromYYYYMMDDArray(
        query.get('closingDateRange')?.split(',') || [],
      ),
      entryDateRange: this.getDateRangeFromYYYYMMDDArray(
        query.get('entryDateRange')?.split(',') || [],
      ),
      expirationDateRange: this.getDateRangeFromYYYYMMDDArray(
        query.get('expirationDateRange')?.split(',') || [],
      ),
      offices:
        query
          .get('offices')
          ?.split(',')
          .map((ns) => parseInt(ns)) || [],
      agents:
        query
          .get('agents')
          ?.split(',')
          .map((ns) => parseInt(ns)) || [],
      missingCOEOnly: (query.get('missingCOEOnly') || 'false') === 'true',
    };

    return preFilter;
  }

  getQuotePreFilterFromRouteData(data: QuotePreFilter): QuotePreFilter {
    const preFilter = {
      removeQuery: data.removeQuery || true,
      filterText: data.filterText || '',
      quoteStatuses: data.quoteStatuses || [],
      closingDateRange: data.closingDateRange || [],
      entryDateRange: data.entryDateRange || [],
      expirationDateRange: data.expirationDateRange || [],
      offices: data.offices || [],
      agents: data.agents || [],
      missingCOEOnly: data.missingCOEOnly || false,
      meta: data.meta,
    };

    return preFilter;
  }

  async applyOrderPreFilter(preFilter: QuotePreFilter) {
    let { orderSearchFilter, filterTagItems } = this.state;
    const { agentsFilterList } = this.state;

    const tagItems = [];

    preFilter.quoteStatuses.forEach((status) => {
      if (orderStatusDictionary[status]) {
        tagItems.push({
          operation: 'add',
          type: 'status',
          payload: { status: status },
          label: `Status: ${orderStatusDictionary[status]}`,
        });
      }
    });

    if (preFilter.meta) {
      if (preFilter.meta.sortBy) {
        tagItems.push({
          type: 'quoteSort',
          payload: { sortBy: preFilter.meta.sortBy, asc: preFilter.meta.asc },
        });
      }
    }

    if (preFilter.closingDateRange.length === 2) {
      tagItems.push({
        operation: 'add',
        type: 'date',
        payload: {
          date: {
            start: preFilter.closingDateRange[0].toISOString(),
            end: preFilter.closingDateRange[1].toISOString(),
            type: 'EFFDATE',
            presetTitle: '',
          },
        },

        label: `Created Date: ${formatDate(preFilter.closingDateRange[0], DateFormat.DEFAULT)} - ${formatDate(preFilter.closingDateRange[1], DateFormat.DEFAULT)}`,
      });
    }

    if (preFilter.entryDateRange.length === 2) {
      tagItems.push({
        operation: 'add',
        type: 'date',
        payload: {
          date: {
            start: preFilter.entryDateRange[0].toISOString(),
            end: preFilter.entryDateRange[1].toISOString(),
            type: 'ENTRYDATE',
            presetTitle: '',
          },
        },
        label: `Entry Date: ${formatDate(preFilter.entryDateRange[0], DateFormat.DEFAULT)} - ${formatDate(preFilter.entryDateRange[1], DateFormat.DEFAULT)}`,
      });
    }

    if (preFilter.expirationDateRange.length === 2) {
      tagItems.push({
        operation: 'add',
        type: 'date',
        payload: {
          date: {
            start: preFilter.expirationDateRange[0].toISOString(),
            end: preFilter.expirationDateRange[1].toISOString(),
            type: 'EXPDATE',
            presetTitle: '',
          },
        },
        label: `Expiration Date: ${formatDate(preFilter.expirationDateRange[0], DateFormat.DEFAULT)} - ${formatDate(preFilter.expirationDateRange[1], DateFormat.DEFAULT)}`,
      });
    }

    for (let index = 0; index < preFilter.offices.length; index += 1) {
      const matchingOffices = this.state.userOffices.filter(
        (uo) => uo.id === preFilter.offices[index].toString(),
      );
      if (matchingOffices.length === 1 && matchingOffices[0].id !== '') {
        tagItems.push({
          type: 'office',
          payload: { office: matchingOffices[0] },
          label: `Office: ${matchingOffices[0].name}`,
        });

        // fetch agents for this office
        const response = await AgentApi.searchAgents(
          {
            activeInactive: 'A',
            officeId: matchingOffices[0].id,
            officeType: matchingOffices[0].type,
          },
          { tags: { source: 'Quotes#updateOfficeFilter' } },
        );

        // apply agents to agent filter list
        agentsFilterList.push(...response.agentsList);
      }
    }

    preFilter.agents.forEach((id) => {
      const matchingAgent = agentsFilterList.filter((a) => a.realEstateAgentID === id.toString());
      if (matchingAgent.length === 1) {
        tagItems.push({
          operation: 'add',
          type: 'agent',
          payload: { agent: matchingAgent[0] },
          label: `Agent: ${matchingAgent[0].firstName} ${matchingAgent[0].lastName}`,
        });
      }
    });

    if (preFilter.missingCOEOnly) {
      tagItems.push({
        operation: 'add',
        type: 'missing-coe-only',
        payload: {},
        label: 'Missing Close of Escrow Date',
      });
    }

    filterTagItems = tagItems; // Replace tag list fow now. May want to modify list in the future.
    orderSearchFilter = this.mapNewFilterRequest(orderSearchFilter, tagItems, preFilter.filterText);

    if (preFilter.removeQuery) {
      window.history.pushState('', '', Path.MyQuote);
    }

    this.setState({
      orderSearchFilter: orderSearchFilter,
      filterTagItems,
      agentsFilterList,
      searchText: preFilter.filterText,
    });
  }

  loadProfileQuoteTotals() {
    loadStatusCanceler.cancel();
    loadStatusCanceler = RealEstateQuoteApi.getCancelTokenSource();

    this.setState(
      (prevState) => {
        if (prevState.loadStatusCanceler) prevState.loadStatusCanceler();

        return { loadStatusCanceler: loadStatusCanceler.cancel, isOnLoad: true };
      },
      async () => {
        if (
          !this.state.hasTooManyOffices ||
          this.state.orderSearchFilter.data.offices.length > 0 ||
          this.state.orderSearchFilter.data.order.ids.length > 0
        ) {
          const request = JSON.parse(JSON.stringify(this.state.orderSearchFilter));
          // this is to prevent the order status filter alert counts from updating when filter is modified
          request.data.order.realEstateStatus = [];
          request.data.order.awaitingWLKSubmissionOnly = false;
          request.data.order.sourceApplicationFlows = ['RE-PlansPrices'];
          const searchProfileQuotes = await RealEstateQuoteApi.searchProfileQuotes(request, {
            cancelToken: loadStatusCanceler.token,
          });
          if (!searchProfileQuotes) return;

          this.setState({
            totalStatus: searchProfileQuotes.meta.totalStatus,
            totalAwaitingWlkSubmission: searchProfileQuotes.meta.totalAwaitingWlkSubmission,
          });
        } else {
          this.setState({
            totalStatus: null,
            totalAwaitingWlkSubmission: null,
          });
        }
      },
    );
  }

  loadProfileQuotes() {
    loadQuotesCanceler.cancel();
    loadQuotesCanceler = RealEstateQuoteApi.getCancelTokenSource();
    // Given that Chrome and other browsers have limits for the maximum amount of concurrent connections to a given server (Chrome allows 6 concurrently per server), we can have timeouts if too many requests are queued before the others are able to finish
    // To avoid this, we ensure we're first cancelling any existing requests below before placing any new requests
    this.setState(
      (prevState) => {
        if (prevState.loadProfileQuotesCanceler) prevState.loadProfileQuotesCanceler();

        return { loadProfileQuotesCanceler: loadQuotesCanceler.cancel, isOnLoad: true };
      },
      async () => {
        // set sort order and sort field (by expiration date in asc order)
        const orderSearchFilter = { ...this.state.orderSearchFilter };
        orderSearchFilter.meta = {
          ...orderSearchFilter.meta,
          sortBy: 'EXPDATE',
          asc: true,
        };

        this.setState({ orderSearchFilter: orderSearchFilter });

        const searchProfileQuotes = await RealEstateQuoteApi.searchProfileQuotes(
          orderSearchFilter,
          { cancelToken: loadQuotesCanceler.token },
        );
        if (!searchProfileQuotes) return; // short circuit if above call was cancelled

        this.setState({
          quoteSummary: searchProfileQuotes.orders,
          totalQuotes: searchProfileQuotes.meta.total,
          isOnLoad: false,
        });

        const quoteIDs = searchProfileQuotes.orders.map((order) => {
          return order.id.toString();
        });

        if (quoteIDs.length === 0) return;

        this.setState({ isLoadingQuotes: true });
      },
    );
  }

  updateToREStatus = (orders: Quote[]) => {
    orders.forEach((item) => {
      if (item.status === quotesStatusIDDictionary.COMPLETE) {
        item.status = quotesStatusIDDictionary.CONVERTED_ORDER;
        item.summary.realEstateStatus = quotesStatusIDDictionary.CONVERTED_ORDER;
      }
    });

    return orders;
  };

  /** Refreshes the order in our dashboard table for a given contract id */
  refreshQuotes(quotes: Quote[]): void {
    try {
      const refreshedQuotes = [...this.state.quoteSummary];
      let hasRefreshedData = false;
      quotes.forEach((updatedOrder) => {
        const index = refreshedQuotes.findIndex((order) => order.id === updatedOrder.id);
        if (index > -1) {
          refreshedQuotes[index] = updatedOrder;
          hasRefreshedData = true;
        }
      });

      if (hasRefreshedData) {
        this.setState({ quoteSummary: refreshedQuotes });
      }
    } catch (e) {
      console.error('error occurred when refreshing table', e);
    }
  }

  /**
   * On [add/remove] filter changes, we want to check if it is a valid change. If so, we would then make a call to refresh the dashboard contracts.
   * On refresh, we should return back to first page in pagination.
   */
  onFilterChange(event: FilterChangeEvent, clearFilters = false): boolean {
    let result: { tags: FilterTagItem[]; validUpdate: boolean } = { tags: [], validUpdate: false };

    try {
      console.log(`filter change. type=${event.type}, operation=${event.operation}`);
      let { orderSearchFilter, filterTagItems } = this.state;

      if (clearFilters) {
        filterTagItems = [];
      }

      // Reset the page back to the first page in the request
      // Should not be moved down, because the page filter would override it
      orderSearchFilter.meta.page = 0;

      // Handle individual event type
      switch (event.type) {
        case FilterType.OFFICE:
          result = this.updateOfficeFilter(event, filterTagItems);
          break;
        case FilterType.AGENT:
          result = this.updateAgentFilter(event, filterTagItems);
          break;
        case FilterType.STATUS:
          result = this.updateStatusFilter(event, filterTagItems);
          break;
        case FilterType.DATE:
          result = this.updateDateFilter(event, filterTagItems);
          break;
        case FilterType.PAGE:
          orderSearchFilter.meta.page = event.payload.page;
          result = { tags: filterTagItems, validUpdate: true };
          break;
        case FilterType.MISSING_COE: // TODO Handle this in a better way based on future UI changes.
          if (event.operation === FilterOperation.REMOVE) {
            result = {
              tags: filterTagItems.filter((fti) => fti.type !== FilterType.MISSING_COE),
              validUpdate: true,
            };
          }
          break;
        case FilterType.ORDER_SORT: {
          // remove existing orderSort filter if present
          const tags = filterTagItems.filter((tag) => tag.type !== FilterType.ORDER_SORT);
          result = { tags: [...tags, event], validUpdate: true };
          break;
        }
        case FilterType.CLEAR_FILTER:
          result = { tags: [], validUpdate: true };
          break;
      }

      // On valid update, we want to proceed onwards to update the tags and make a new request
      if (result.validUpdate) {
        console.log('valid filter update, proceeding to update the contract list...');
        if (event.type !== FilterType.CLEAR_FILTER && event.label) {
          fireGAEvent(NAVIGATION__ORDER_FILTER_SORT(`${event.operation}: ${event.label}`));
        }
        // Overriding the search filter by basing it off of the tags instead
        orderSearchFilter = this.mapNewFilterRequest(orderSearchFilter, result.tags);

        this.setState({ orderSearchFilter: orderSearchFilter, filterTagItems: result.tags }, () => {
          // we set quoteSummary to an empty array so that the orders for the previous filter set don't remain on screen
          if (
            this.state.officeCount > TOO_MANY_OFFICES_THRESHOLD &&
            orderSearchFilter.data.offices.length === 0 &&
            (!this.props.preFilter?.officeLimitBypass ||
              orderSearchFilter.data.date.dateType === '') &&
            this.state.orderSearchFilter.data.order.ids.length === 0
          )
            return this.setState({ hasTooManyOffices: true, quoteSummary: [] });

          this.loadProfileQuoteTotals();
          this.loadProfileQuotes();
        });
      }
    } catch (e) {
      console.error('failed to apply filter changes', e);
    }

    return result.validUpdate;
  }

  /**
   * On [add/remove] filter changes, pass in filter list from the modal.
   * Overwrite the existing filters with the new list, fire GA event, update agent list.
   */
  onFilterBatchChange(events: FilterChangeEvent[]): boolean {
    const result: { tags: FilterTagItem[]; validUpdate: boolean } = {
      tags: this.state.filterTagItems,
      validUpdate: false,
    };
    // const query = this.state.contractSearchFilter?.data?.query || '';
    const query = this.state.orderSearchFilter?.data?.order?.ids?.[0] || '';

    let { orderSearchFilter } = this.state;
    const updatedAgentList = [];

    try {
      // remove all the office type tags then append with our new list
      result.tags = result.tags.filter((filter) => filter.type !== FilterType.OFFICE);
      events.forEach((e) => {
        const mappedEvent: FilterTagItem = {
          label: e.label,
          type: e.type,
          payload: e.payload,
        };
        result.tags.push(mappedEvent);
      });

      console.log('valid filter update, proceeding to update the contract list...');
      // Overriding the search filter by basing it off of the tags instead
      orderSearchFilter = this.mapNewFilterRequest(orderSearchFilter, result.tags, query);

      this.setState({ orderSearchFilter: orderSearchFilter, filterTagItems: result.tags }, () => {
        // we set quoteSummary to an empty array so that the orders for the previous filter set don't remain on screen
        if (
          this.state.officeCount > TOO_MANY_OFFICES_THRESHOLD &&
          orderSearchFilter.data.offices.length === 0 &&
          (!this.props.preFilter?.officeLimitBypass ||
            orderSearchFilter.data.date.dateType === '') &&
          this.state.orderSearchFilter.data.order.ids.length === 0
        )
          return this.setState({ hasTooManyOffices: true, quoteSummary: [] });
        this.loadProfileQuoteTotals();
        this.loadProfileQuotes();
      });

      events.forEach((e) => {
        // Update the agent list with a new set of agents for the office
        fireGAEvent(NAVIGATION__ORDER_FILTER_SORT(`${e.operation}: ${e.label}`));

        if (e.payload.office && e.payload.office.id !== '') {
          AgentApi.searchAgents(
            {
              activeInactive: 'A',
              officeId: e.payload.office.id,
              officeType: e.payload.office.type,
            },
            {
              tags: { source: 'Orders#updateOfficeFilter' },
            },
          )
            .then((res) => {
              if (res.agentsList.length) {
                console.log('adding agents to filter list, count=', res.agentsList.length);
                updatedAgentList.push(...res.agentsList);
              }
            })
            .catch((e) => {
              console.error(e);
            });
        }
      });

      this.setState({ agentsFilterList: updatedAgentList });
    } catch (e) {
      console.error('failed to apply filter changes', e);
      return false;
    }

    return true;
  }

  mapNewFilterRequest(
    quoteSearchFilter: REOrderSearchRequest,
    tags: FilterTagItem[],
    query = '',
  ): REQuoteSearchRequest {
    quoteSearchFilter.data.agents = tags
      .filter((i) => i.type === 'agent')
      .map((i) => ({
        id: i.payload.agent.realEstateAgentID,
        type: officeTypeDictionary[i.payload.agent.agentType],
      }));

    quoteSearchFilter.data.offices = tags
      .filter((i) => i.type === 'office')
      .map((i) => ({
        id: i.payload.office.id,
        type: i.payload.office.type,
      }));

    quoteSearchFilter.data.order.realEstateStatus = tags
      // warranty link submission will be sent as a flag
      .filter(
        (i) => i.type === 'status' && i.payload.status !== RealEstateStatus.AWAITING_WL_SUBMISSION,
      )
      .map((i) => i.payload.status);

    // set warranty link flag is status is present
    quoteSearchFilter.data.order.awaitingWLKSubmissionOnly =
      tags.find((i) => i.payload.status === RealEstateStatus.AWAITING_WL_SUBMISSION) !== undefined;

    // set missing coe only flag
    quoteSearchFilter.data.order.statusListingMissingCOEOnly =
      tags.find((i) => i.type === 'missing-coe-only') !== undefined;

    const quoteSortFilter = tags.find((tag) => tag.type === 'quoteSort');
    if (quoteSortFilter) {
      quoteSearchFilter.meta.sortBy = quoteSortFilter.payload.sortBy;
      quoteSearchFilter.meta.asc = quoteSortFilter.payload.asc;
    }

    const dateFilterTag = tags.find((i) => i.type === 'date');
    if (dateFilterTag) {
      const { date } = dateFilterTag.payload;
      const startDate = formatDateToISO8601(date.start, false);
      const endDate = formatDateToISO8601(date.end, false);
      quoteSearchFilter.data.date = {
        startDate,
        endDate,
        dateType: date.type,
      };
    } else {
      quoteSearchFilter.data.date = {
        startDate: '',
        endDate: '',
        dateType: '',
      };
    }

    quoteSearchFilter.data.query = this.state.searchText;

    return quoteSearchFilter;
  }

  updateOfficeFilter(
    event: FilterChangeEvent,
    tags: FilterTagItem[],
  ): { tags: FilterTagItem[]; validUpdate: boolean } {
    let validUpdate = false;

    const { office } = event.payload;
    const inFilter = tags.find(
      ({ type, payload }) =>
        type === 'office' && payload.office.id === office.id && payload.office.type === office.type,
    );

    switch (event.operation) {
      case 'add':
        if (!inFilter) {
          tags = [...tags, event];
          validUpdate = true;

          if (office && office.id !== '') {
            // Update the agent list with a new set of agents for the office
            AgentApi.searchAgents(
              {
                activeInactive: 'A',
                officeId: office.id,
                officeType: office.type,
              },
              {
                tags: { source: 'Orders#updateOfficeFilter' },
              },
            )
              .then((res) => {
                if (res.agentsList.length) {
                  console.log('adding agents to filter list, count=', res.agentsList.length);
                  this.setState({
                    agentsFilterList: [...this.state.agentsFilterList, ...res.agentsList],
                  });
                }
              })
              .catch((e) => {
                console.error(e);
              });
          }
        }
        break;
      case 'remove':
        if (inFilter) {
          tags = tags.filter(
            ({ type, payload }) =>
              !(
                type === 'office' &&
                payload.office.id === office.id &&
                payload.office.type === office.type
              ),
          );
          validUpdate = true;

          // Update the agent list and filter tags by removing agents under the office removed
          console.log('removing agents from filter list');
          const agentsFilterList = this.state.agentsFilterList.filter(
            (a) => !(a.officeID === office.id && officeTypeDictionary[a.agentType] === office.type),
          );
          tags = tags.filter(
            (i) =>
              !(
                i.type === 'agent' &&
                i.payload.agent.officeID === office.id &&
                officeTypeDictionary[i.payload.agent.agentType] === office.type
              ),
          );
          this.setState({ agentsFilterList });
        } else {
          console.warn('attempted to remove, but cannot find it', event, tags);
        }
        break;
    }

    return { tags, validUpdate };
  }

  updateAgentFilter(
    event: FilterChangeEvent,
    tags: FilterTagItem[],
  ): { tags: FilterTagItem[]; validUpdate: boolean } {
    let validUpdate = false;

    const { agent } = event.payload;
    const inFilter = tags.find(
      ({ type, payload }) =>
        type === 'agent' &&
        payload.agent.realEstateAgentID === agent.realEstateAgentID &&
        payload.agent.agentType === agent.agentType,
    );

    switch (event.operation) {
      case 'add':
        if (!inFilter) {
          tags = [...tags, event];
          validUpdate = true;
        }
        break;
      case 'remove':
        if (inFilter) {
          tags = tags.filter(
            ({ type, payload }) =>
              !(
                type === 'agent' &&
                payload.agent.realEstateAgentID === agent.realEstateAgentID &&
                payload.agent.agentType === agent.agentType
              ),
          );
          validUpdate = true;
        } else {
          console.warn('attempted to remove, but cannot find it', event, tags);
        }
        break;
    }

    return { tags, validUpdate };
  }

  updateDateFilter(
    event: FilterChangeEvent,
    tags: FilterTagItem[],
  ): { tags: FilterTagItem[]; validUpdate: boolean } {
    let validUpdate = false;

    const { date } = event.payload;
    const inFilter = tags.find(({ type }) => type === 'date');

    switch (event.operation) {
      case 'add': {
        // We always update the date range if add occurs; replacing the existing one; as there should only be 1 date range filter
        const startDate = formatDateToISO8601(date.start);
        const endDate = formatDateToISO8601(date.end);
        const validDates = Boolean(startDate && endDate);
        if (validDates) {
          event.payload.date.type = 'EFFDATE';
          tags = [...tags.filter((i) => i.type !== 'date'), event]; // Remove the existing tag first, then add
          validUpdate = true;
        }
        break;
      }
      case 'remove': {
        if (inFilter) {
          tags = tags.filter((i) => i.type !== 'date');
          validUpdate = true;
        } else {
          console.warn('attempted to remove date, but cannot find it', event, tags);
        }
        break;
      }
    }

    return { tags, validUpdate };
  }

  updateStatusFilter(
    event: FilterChangeEvent,
    tags: FilterTagItem[],
  ): { tags: FilterTagItem[]; validUpdate: boolean } {
    let validUpdate = false;

    const { status } = event.payload;
    const inFilter = tags.find(
      ({ type, payload }) => type === 'status' && payload.status === status,
    );

    switch (event.operation) {
      case 'add':
        if (!inFilter) {
          tags = [...tags, event];
          validUpdate = true;
        }
        break;
      case 'remove':
        if (inFilter) {
          tags = tags.filter(
            ({ type, payload }) => !(type === 'status' && payload.status === status),
          );
          validUpdate = true;
        }
        break;
    }

    return { tags, validUpdate };
  }

  handleQueryFilterChange = () => {
    this.queryFilterSubscription = this.queryFilter$.subscribe((value: string) => {
      const { orderSearchFilter } = this.state;

      orderSearchFilter.data.query = value;

      this.setState({ orderSearchFilter }, () => {
        this.loadProfileQuotes();
        this.loadProfileQuoteTotals();
      });
    });
  };

  onSearchFilterChange = (value: string) => {
    this.countCharSearchForGA += 1;
    const { orderSearchFilter } = this.state;
    orderSearchFilter.meta.page = 0;
    this.setState({ searchText: value, orderSearchFilter });
    clearTimeout(this.quotesSearchTimeoutFunction);
    this.queryFilterSubject.next(value);
    if (this.countCharSearchForGA >= MIN_CHAR_COUNT_SEARCH_FOR_GA) {
      this.quotesSearchTimeoutFunction = setTimeout(() => {
        fireGAEvent(NAVIGATION__ORDER_SEARCH(value));
        this.countCharSearchForGA = 0;
      }, GAEventDelaySeconds * SECOND);
    }
  };

  render() {
    return (
      <Layout isLoggedIn={true} slug="quotes">
        <QuotesTemplate
          summaryView={this.state.quoteSummary}
          orders={this.state.orders} // Source of truth for all contract data other than for summary view
          profile={this.state.userDetails}
          offices={this.state.userOffices}
          agents={this.state.agentsFilterList}
          isLoading={this.state.isOnLoad}
          isLoadingQuotes={this.state.isLoadingQuotes}
          filterTagItems={this.state.filterTagItems}
          searchText={this.state.searchText}
          onFilterChange={(event, clearFilters) => this.onFilterChange(event, clearFilters)}
          onFilterBatchChange={(eventList) => this.onFilterBatchChange(eventList)}
          onSearchFilterChange={(value) => this.onSearchFilterChange(value)}
          totalOrders={this.state.totalQuotes}
          totalStatus={this.state.totalStatus}
          totalAwaitingWlkSubmission={this.state.totalAwaitingWlkSubmission}
          hasTooManyOffices={this.state.hasTooManyOffices}
          onRefreshQuotes={this.loadProfileQuotes}
        />
      </Layout>
    );
  }
}

export default function (props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <Quotes {...props} navigate={navigate} preFilter={location.state} />;
}
