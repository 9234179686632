import React from 'react';
import Layout from '@components/layout/Layout';
import { useNavigate } from 'react-router-dom';
import Path from '@constants/paths';
import ContactUsSuccessTemplate from '@templates/contact/ContactUsSuccessTemplate';
import { ContentBox } from '@components/layout/ContentBox';

const ContactUsAuthSuccess = () => {
  const navigate = useNavigate();

  return (
    <Layout isLoggedIn={true}>
      <ContentBox title={`Contact Us`}>
        <ContactUsSuccessTemplate
          isButtonVisible={true}
          onClick={() => navigate(Path.Dashboard)}
        />
      </ContentBox>
    </Layout>
  );
};
export default ContactUsAuthSuccess;
