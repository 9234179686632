import React, { useContext } from 'react';
import {
  AhsLogomarkColor,
  AhsLogomarkMono,
  Ahs2024LogomarkColor,
  GlobalFooter,
  GlobalFooterGroupedLinksRowPreset,
  GlobalFooterLinkProps,
  GlobalFooterMinimalFooterRowPreset,
  HsaLogoColor,
  HsaLogoMono,
  IconMail,
  IconPhone,
  Separator,
} from '@ftdr/blueprint-components-react';
import { getBrand } from '@helpers/brand.utils';
import { isProdEnv } from '@helpers/utils';
import { IDLookup } from '@app/devtools/IDLookup';
import { useNavigate } from 'react-router-dom';
import Path from '@constants/paths';
import ProfileContext from '@context/ProfileContext';
import { UserRoleType } from '@constants/dictionaries';
import { hasWarrantyLinkOffice } from '@services/helpers/profile.offices.helper';
import { Features, IsFeatureEnabled } from '@app/core/featureToggle';
import { Brand } from '@constants/brands';
import { DemoPages } from '@constants/demo.paths';
import ExternalLinks from '@constants/external-links';
import { IsTheme, Theme } from '@app/core/featureToggle';

const allowedOfficeRoles = [UserRoleType.SiteAdmin];

const Footer = () => {
  const { profile } = useContext(ProfileContext);
  const brandLocation = getBrand();
  const navigate = useNavigate();
  const isProd = isProdEnv();
  // @ts-expect-error TODO: this is set by webpack globally
  const buildDate = new Date(__PROJECT_BUILD_DATE__);
  // @ts-expect-error TODO: this is set by webpack globally
  const gitBranch = __GIT__BRANCH__;

  const brandName =
    brandLocation === Brand.AHS ? 'American Home Shield' : 'Home Security of America';
  const oldBrandLegalText = `© ${new Date().getFullYear()} ${brandName} All Rights Reserved.`;
  const ahs2024BrandLegalText = `© ${new Date().getFullYear()} ${brandName} All Rights Reserved. American Home Shield, AHS, “Be sure with the Shield” and the shield logo are registered trademarks of American Home Shield Corporation.`;
  const brandLegalText = IsTheme(Theme.Ahs2024) ? ahs2024BrandLegalText : oldBrandLegalText;
  const footerVariant = 'light';
  const footerLinkColor = 'interactive'; // (footerVariant === 'dark' ? 'white' : 'interactive');

  const hrefWithRoute = (path: Path) => {
    return {
      href: path,
      onClick: (e) => {
        e.preventDefault();
        navigate(path);
      },
    };
  };

  const footerLinks = {
    realEstate: {
      id: 'footer_link_real_estate',
      children: 'Real Estate',
      href: 'https://www.ahs.com/real-estate/',
      color: footerLinkColor,
    },
    homeMatters: {
      id: 'footer_link_home_matters',
      children: 'Home Matters Blog',
      href: 'https://www.ahs.com/home-matters/real-estate/',
      color: footerLinkColor,
    },
    dashboard: {
      id: 'footer_link_dashboard',
      children: 'Dashboard',
      color: footerLinkColor,
      ...hrefWithRoute(Path.Dashboard),
    },
    myQuotes: {
      id: 'footer_link_my_orders',
      children: 'My Quotes',
      color: footerLinkColor,
      ...hrefWithRoute(Path.MyQuote),
    },
    myOrders: {
      id: 'footer_link_my_orders',
      children: 'My Orders',
      color: footerLinkColor,
      ...hrefWithRoute(Path.MyOrders),
    },
    offices: {
      id: 'footer_link_offices',
      children: 'Offices',
      color: footerLinkColor,
      ...hrefWithRoute(Path.MyOffices),
    },
    warrantyLink: {
      id: 'footer_link_warranty_link',
      children: 'WarrantyLink',
      color: footerLinkColor,
      ...hrefWithRoute(Path.Warrantylink),
    },
    plansAndPrices: {
      id: 'footer_link_plans_prices',
      children: 'Plans & Prices',
      color: footerLinkColor,
      ...hrefWithRoute(Path.PlansAndPrices),
    },
    salesMaterial: {
      id: 'footer_link_sales_material',
      children: 'Sales Material',
      color: footerLinkColor,
      ...hrefWithRoute(Path.SalesMaterial),
    },
    contactUs: {
      id: 'footer_link_contact_us',
      children: 'Contact Us',
      startIcon: <IconMail className="footer-icon" />,
      color: footerLinkColor,
      ...hrefWithRoute(Path.ContactUs),
    },
    ahsSalesPhone: {
      id: 'footer_ahs_sales_phone',
      children: '1.800.735.4663',
      href: 'tel:1-800-735-4663',
      startIcon: <IconPhone className="footer-icon" />,
      color: footerLinkColor,
    },
    realEstateProfessionals: {
      id: 'footer_link_real_estate_professionals',
      children: 'Real Estate Professionals',
      href: 'https://www.onlinehsa.com/real-estate-agents/',
      color: footerLinkColor,
    },
    mediaCenter: {
      id: 'footer_link_media_center',
      children: 'Media Center',
      href: 'https://www.onlinehsa.com/real-estate-agents/media-center/',
      color: footerLinkColor,
    },
    hsaSalesPhone: {
      id: 'footer_link_hsa_sales_phone',
      children: '1.800.367.1448',
      href: 'tel:1-800-367-1448',
      startIcon: <IconPhone className="footer-icon" />,
      color: footerLinkColor,
    },
    termsOfUse: {
      id: 'footer_link_terms_of_use',
      children: 'Terms of Use',
      color: footerLinkColor,
      href: ExternalLinks.AHS.TermsOfUse,
      target: '_blank',
    },
    privacyPolicy: {
      id: 'footer_link_privacy_policy',
      children: 'Privacy Policy',
      color: footerLinkColor,
      href: ExternalLinks.AHS.privacyPolicy,
      target: '_blank',
    },
    developerTools: {
      id: 'footer_link_developer_tools',
      children: (
        <>
          <IDLookup color={footerLinkColor} />
          {buildDate && (
            <div style={{ marginTop: '4px' }}>
              <em>
                <small>Last built: {buildDate.toString()}</small>
              </em>
            </div>
          )}
          <div style={{ marginTop: '4px' }}>
            <em>
              <small>Git Branch: {gitBranch}</small>
            </em>
          </div>
        </>
      ),
      color: footerLinkColor,
      underline: 'never',
    },
    developerDemoPages: {
      id: 'footer_link_developer_tools',
      children: (
        <>
          Demo Pages
          <br />
          {DemoPages.map((page) => (
            <div key={page.path} className="ml-1">
              <a
                href={page.path}
                className="hover:underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(page.path);
                }}
              >
                <small>{page.path}</small>
              </a>
            </div>
          ))}
        </>
      ),
      color: footerLinkColor,
      underline: 'never',
    },
  };

  const footerLinkSets = {
    americanHomeShield: [footerLinks.realEstate, footerLinks.homeMatters],
    homeSecurityOfAmerica: [
      footerLinks.realEstateProfessionals,
      footerLinks.mediaCenter,
    ],
    siteNavigation: [
      footerLinks.dashboard,
      ...(IsFeatureEnabled(Features.MyQuotes) && brandLocation === Brand.AHS
        ? [footerLinks.myQuotes]
        : []),
      footerLinks.myOrders,
      ...(profile && allowedOfficeRoles.includes(UserRoleType[profile.roleIDType])
        ? [footerLinks.offices]
        : []),
      ...(profile && hasWarrantyLinkOffice(profile) ? [footerLinks.warrantyLink] : []),
      ...[footerLinks.salesMaterial],
      ...(brandLocation === Brand.AHS ? [footerLinks.plansAndPrices] : []),
    ],
    ahsHelpCenter: [footerLinks.contactUs, footerLinks.ahsSalesPhone],
    hsaHelpCenter: [footerLinks.contactUs, footerLinks.hsaSalesPhone],
    bottom: [footerLinks.termsOfUse, footerLinks.privacyPolicy] as GlobalFooterLinkProps[],
    developerTools: [footerLinks.developerTools, footerLinks.developerDemoPages],
  };

  const footerLinkGroups = {
    AHS: [
      {
        title: 'American Home Shield',
        orientation: 'vertical',
        links: footerLinkSets.americanHomeShield,
      },
      {
        title: 'Partner Portal',
        orientation: 'vertical',
        links: footerLinkSets.siteNavigation,
      },
      {
        title: 'Help Center',
        orientation: 'vertical',
        links: footerLinkSets.ahsHelpCenter,
      },
    ],
    HSA: [
      {
        title: 'Home Security of America',
        orientation: 'vertical',
        links: footerLinkSets.homeSecurityOfAmerica,
      },
      {
        title: 'Partner Portal',
        orientation: 'vertical',
        links: footerLinkSets.siteNavigation,
      },
      {
        title: 'Help Center',
        orientation: 'vertical',
        links: footerLinkSets.hsaHelpCenter,
      },
    ],
    developerTools: [
      {
        title: 'Developer Tools (Non-Prod Only)',
        orientation: 'vertical',
        links: footerLinkSets.developerTools,
      },
    ],
  };

  const socialLinkProps = {
    facebook: brandLocation === Brand.AHS ? 'https://www.facebook.com/team.ahs' : '',
    twitter:
      brandLocation === Brand.AHS && !IsTheme(Theme.Ahs2024)
        ? 'https://twitter.com/AHS_Warranty'
        : '',
    youtube: brandLocation === Brand.AHS ? 'https://www.youtube.com/c/americanhomeshield' : '',
    instagram:
      brandLocation === Brand.AHS && IsTheme(Theme.Ahs2024)
        ? 'https://www.instagram.com/ahs_warranty'
        : '',
    x: brandLocation === Brand.AHS && IsTheme(Theme.Ahs2024) ? 'https://x.com/AHS_Warranty' : '',
  };

  return (
    <div id="footer-container">
      {IsTheme(Theme.Ahs2024) && (
        <Separator orientation="horizontal" className="footer-top-separator" />
      )}
      <GlobalFooter variant={footerVariant}>
        <GlobalFooterGroupedLinksRowPreset
          linkGroups={[
            ...footerLinkGroups[brandLocation],
            ...(!isProd ? footerLinkGroups.developerTools : []),
          ]}
          justify="between"
          accordionWhenMobileView={true}
          className={IsTheme(Theme.Ahs2024) ? 'ahs-2024' : ''}
        />
        <GlobalFooterMinimalFooterRowPreset
          logoRenderer={(variant) => {
            if (brandLocation === Brand.AHS && IsTheme(Theme.Ahs2024))
              return <Ahs2024LogomarkColor />;
            if (brandLocation === Brand.AHS)
              return variant === 'light' ? (
                <AhsLogomarkColor />
              ) : (
                <AhsLogomarkMono className="text-white" />
              );
            return variant === 'light' ? <HsaLogoColor /> : <HsaLogoMono className="text-white" />;
          }}
          title={IsTheme(Theme.Ahs2024) ? '' : brandName}
          legalText={brandLegalText}
          links={footerLinkSets.bottom}
          {...socialLinkProps}
        />
      </GlobalFooter>
    </div>
  );
};

export default Footer;
