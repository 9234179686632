import React from 'react';
import REText from '@components/wrappedBDS/REText';

const NotFound = (props): JSX.Element => {
  return (
    <div className="flex flex-col items-center justify-center py-8">
      <REText variant="heading-05" id="no-order-found" className="mb-4 no-order-found">
        No results found
      </REText>
    </div>
  );
};

export default NotFound;
