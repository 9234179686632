import REText from '@components/wrappedBDS/REText';
import { classNames } from '@utils';
import React from 'react';

interface LineTextProps {
  className?: string;
  boldText: string;
  text: string;
  boldTextId: string;
  textId: string;
  truncateValue?: boolean;
  textClassName?: string;
}

const LineText = ({
  className,
  boldText,
  text,
  boldTextId,
  textId,
  truncateValue,
  textClassName,
}: LineTextProps) => {
  return (
    <div className={classNames(['flex flex-row', className])}>
      <div className="w-1/3">
        <REText id={boldTextId} className="max-w-24" variant="heading-06">
          {boldText}
        </REText>
      </div>
      <div className={classNames(['w-2/3', textClassName])}>
        <REText id={textId} variant="body-short" className={truncateValue ? 'truncate' : ''}>
          {text}
        </REText>
      </div>
    </div>
  );
};

export default LineText;
