import { UserRoleType } from './dictionaries';
import { IsTheme, Theme } from '@app/core/featureToggle';
import { TextColorOption } from '@ftdr/blueprint-components-react';

export const ORDER_CARD_TITLE = {
  AGENT_INFO: 'Agent Information',
  PROPERTY_DETAILS: 'Property Details',
  PROPERTY_ADDRESS: 'Covered Property Address',
  BUYER_INFO: 'Buyer Information',
  SELLER_INFO: 'Seller Information',
  CLOSING_INFO: 'Closing Information',
  PLANS_AND_COVERAGES: 'Plans and Coverage',
};

export const CONTEXT = {
  AGENT_INFO: 'AGENT_INFO',
};

export const NEW_ORDER_LABELS = {
  FORM_HEADER: 'Place New Order',
  FORM_SUBMIT_BTN: 'Submit Order',
  FORM_SUBMIT_BTN_RO_NOW: 'Submit & Pay Now',
  FORM_SUBMIT_BTN_RO_LATER: 'Submit & Pay Later',
  FORM_CANCEL_BTN: 'Cancel',
  FORM_CANCEL_BTN__TOOLTIP: 'All steps need to be completed before submitting an order.',
  CARD_SAVE: 'Save & Continue',
  CARD_CANCEL: 'Cancel',
  CARD_CLEAR: 'Clear info',
  CARD_EDIT: 'Edit',
  HELP_TEXT__OPTIONAL: 'Optional',
};

export const ORDER_FORM_FIELDS = {
  MLS: 'MLS Number',
  MLS__PLACEHOLDER: 'Enter',
  RESIDENCE_TYPE: 'Type of Residence',
  RESIDENCE_TYPE__PLACEHOLDER: 'Select an option',
  SQUARE_FOOTAGE: 'Square Footage',
  SQUARE_FOOTAGE__PLACEHOLDER: 'Select an option',
  NEW_CONSTRUCTION: 'New Construction',
  NEW_CONSTRUCTION__QUESTION: 'Is this a new construction?',
  PROPERTY_ZIP: 'ZIP code',
  PROPERTY_UNITS: 'Unit #',
  PROPERTY_CITY: 'City',
  PROPERTY_STATE: 'State',
  PROPERTY_ADDRESS: 'Street address',
  CUSTOMER_STREET_ADDRESS: 'Street address',
  INITIATING_OFFICE: 'Initiating Office',
  INITIATING_AGENT: 'Initiating Agent',
  REPRESENTING: 'Represents',
  REPRESENTING__QUESTION: 'Who does the agent represent?',
  REPRESENTING__QUESTION_SELF: 'Who are you representing?',
  COOPERATING_OFFICE: 'Cooperating Office',
  COOPERATING_AGENT: 'Cooperating Agent',
  BUYER__QUESTION: 'Do you have a Buyer?',
  SELLER__QUESTION: 'Do you have a Seller?',
  PROJECTED_CLOSING_DATE: 'Projected closing date',
  CLOSING_COMPANY: 'Closing / title company',
  CLOSING_COMPANY__QUESTION: 'Are you working with a closing/title company?',
  CLOSING_AGENT: 'Closing / title agent or officer',
  CLOSING_FILE: 'Closing file #',
};

export const ORDER_VIEW_LABELS = {
  BUYER_NAME: 'Buyer Name',
  BUYER_CONTACT: 'Buyer Contact Information',
  BUYER_ADDRESS: 'Buyer Alternate Mailing Address',
  CO_BUYER_NAME: 'Co-Buyer',
  CO_BUYER_CONTACT: 'Co-Buyer Contact Information',
  CO_BUYER_ADDRESS: 'Co-Buyer Alternate Mailing Address',
  SELLER_NAME: 'Seller Name',
  SELLER_CONTACT: 'Seller Contact Information',
  SELLER_ADDRESS: 'Seller Alternate Mailing Address',
  CO_SELLER_NAME: 'Co-Seller',
  CO_SELLER_CONTACT: 'Co-Seller Contact Information',
  CO_SELLER_ADDRESS: 'Co-Seller Alternate Mailing Address',
  NO_CLOSING_INFORMATION: 'No Closing Information Provided',
};

export const AGENT_LABELS = {
  buyer: 'Buyer',
  seller: 'Seller',
  both: 'Both',
};

export const ACTION_UPDATE: string = 'Update';
export const ACTION_SAVE: string = 'Save';
export const ACTION_SELECT: string = 'Select';
export const ACTION_EDIT: string = 'Edit';
export const CLOSING_LABEL: string = 'Closing';
export const NEW_ORDER: string = 'New Order';
export const EXISTING_ORDER: string = 'Existing Order';

export const ACTION_VIEW_EXISTING_ORDER_DETAILS: string = 'View Existing Order Details';
export const ACTION_ADD_AS_COOPERATING_AGENT: string = 'Add As Cooperating Agent';
export const ACTION_CONTACT_SUPPORT: string = 'Contact Support';
export const ROLE_TYPE_REAL_ESTATE_ADMIN: string = 'RealEstateAdmin';
export const ROLE_TYPE_REAL_ESTATE_AGENT: string = 'RealEstateAgent';
export const ROLE_TYPE_REAL_ESTATE_BROKER: string = 'Broker';
export const REALTOR_OWNED_FOR_INIT_AGENT_ROLES: string[] = [
  UserRoleType.RealEstateAdmin,
  UserRoleType.FieldSales,
  UserRoleType.InternalSalesAgent,
  UserRoleType.SiteAdmin,
];
export const FSBO_ALLOWED_ROLES: string[] = [
  UserRoleType.ClosingCompanyAdmin,
  UserRoleType.ClosingCompanyAgent,
];
export const MILITARY_DISCOUNT = 'military';

export enum PAYMENTS {
  CC = 'Credit Card',
  ACH = 'Bank Account',
}

export const getOriginatorName = (contractData): string => {
  if (contractData.agentInfo.agentRepresents.both) {
    return AGENT_LABELS.both;
  } else if (contractData.agentInfo.agentRepresents.buyer) {
    return AGENT_LABELS.buyer;
  } else {
    return AGENT_LABELS.seller;
  }
};