import React, { useContext, useEffect, useCallback, useState, FunctionComponent } from 'react';
import OfficeApi from '@apis/office.api';
import { Office } from '@app/models/office.model';
import ProfileContext from '../../context/ProfileContext/index';
import Template from '../../templates/office/ConfirmOfficeTemplate';
import LayoutCard from '@components/layout/LayoutCard';

interface IProps {
  handleConfirm: (arg: string) => void;
  handleReject: (arg: string) => void;
}

const ConfirmOffice: FunctionComponent<IProps> = ({ handleConfirm, handleReject }) => {
  const { profile } = useContext(ProfileContext);
  const [office, setOffice] = useState<Office>(null);

  const searchOfficeById = useCallback(async () => {
    // Agent type can only have 1 office
    return await OfficeApi.searchOfficeById(profile.offices[0].id, profile.offices[0].type);
  }, [profile]);

  useEffect(() => {
    let isMounted = true;

    searchOfficeById()
      .then((office) => isMounted && setOffice(office))
      .catch((error) => console.error(error));

    return () => (isMounted = false);
  }, [profile]);

  return (
    <LayoutCard roundedBorders>
      <Template
        correctOfficeInfoBtnHandler={handleConfirm}
        incorrectOfficeInfoBtnHandler={handleReject}
        office={office}
      />
    </LayoutCard>
  );
};

export default ConfirmOffice;
