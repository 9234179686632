import React, { Component } from 'react';
import {
  hasNarIdDictionary,
  officeTypeDictionary,
  officeTypeFullFormDictionary,
} from '@constants/dictionaries';
import CardOfficeInfo from '@components/card/CardOfficeInfo';
import UserDetails from '../../types/api/UserDetails';
import * as c from '@constants/formField-constants';
import {
  ACCOUNT_TYPE_CHANGE,
  FIRST_NAME_REQUIRED,
  INVALID_EMAIL,
  INVALID_PHONE_NUMBER,
  LAST_NAME_REQUIRED,
} from '@constants/formField-constants';
import ProfileModel from '@app/models/profile.model';
import ProfileContext from '../../context/ProfileContext';
import * as app from '@app/models';
import { OfficeType } from '@app/models';
import { getResetPasswordURL, providerType } from '@app/auth/authService';
import { authProvider } from '@constants/auth-providers';
import { getOfficesByType } from '@services/helpers/profile.offices.helper';
import { isInternalUser } from '@helpers/profile.utils';
import { isEmailValid, isPhoneNumberValid } from '@services/validation/ValidationRules';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  ACCOUNT_MANAGEMENT__ACCOUNT_TYPE_CHANGED,
  ACCOUNT_MANAGEMENT__REMOVED_OFFICE,
} from '@constants/ga-events.constants';
import { Button, Dialog, Input, Link, Panel } from '@ftdr/blueprint-components-react';
import Path from '@constants/paths';
import { getBrand } from '@helpers/brand.utils';
import useGlobalAlert, { GlobalAlertFns } from '@app/core/GlobalAlertModal';
import { getFormInputErrorId } from '@storybook/addon-links';
import { IsTheme, Theme } from '@app/core/featureToggle';
import REText from '@components/wrappedBDS/REText';

// eventually it would be useful to extract these into generic interfaces that can be
// handed any kind of props so we're not re-declaring state everywhere
interface State {
  errors: UserDetails.UserDetailErrors;
  updateUser: any;
  isModalActive: boolean;
  theme: any;
  newPasswordConfirm?: string;
  isLoading: boolean;
  authProviderType: authProvider;
  isEditActive: any;
  canSave: boolean;
  tempUserInfo: {
    firstName: any;
    lastName: any;
    phoneNumber: any;
    email: any;
    narId: any;
  };
}

interface Props {
  userDetails: ProfileModel;
  errors: UserDetails.UserDetailErrors;
  handlePhoneFormat: any;
  updateUser: any;
  removeUserOffice: any;
  userOfficeDetail: app.Office[];
  userWorkedWithOfficeDetail: any;
  refreshUser: any;
}

enum AccountFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  NAR_ID = 'narId',
}

interface PropsWithAlertHook extends Props {
  globalAlert: GlobalAlertFns;
}

// TODO This can be removed and the below usage of globalAlert refactored
export default function MyAccountTemplate(props: Props) {
  const globalAlert: GlobalAlertFns = useGlobalAlert();
  return <MyAccountTemplateWithAlertHook {...props} globalAlert={globalAlert} />;
}

class MyAccountTemplateWithAlertHook extends Component<PropsWithAlertHook, State> {
  static contextType = ProfileContext;

  formRef = null;
  brand = getBrand();

  constructor(props) {
    super(props);
    this.state = {
      errors: props.errors,
      updateUser: props.updateUser,
      isModalActive: false,
      // TODO: Figure out how to bring the correct "getBrand" call here.
      theme: {},
      newPasswordConfirm: undefined,
      isLoading: true,
      authProviderType: authProvider.NONE,
      isEditActive: {
        firstName: false,
        lastName: false,
        phoneNumber: false,
        email: false,
      },
      canSave: false,
      tempUserInfo: {
        firstName: this.props.userDetails.firstName,
        lastName: this.props.userDetails.lastName,
        phoneNumber: this.props.userDetails.phoneNumber,
        email: this.props.userDetails.email,
        narId: this.props.userDetails.narID,
      },
    };
    this.formRef = React.createRef();
  }

  // This allows the component to update when errors are updated on the parent component
  // without losing state for things like isEditEnabled
  getDerivedStateFromProps(props, state): void {
    this.setState({ errors: props.errors });
  }

  async componentDidMount() {
    const authProvider = providerType();
    this.setState({ isLoading: false, authProviderType: authProvider });
  }

  componentDidUpdate(prevProps) {
    const updatedUser = this.props.userDetails;
    const currentUser = prevProps.userDetails;
    let shouldUpdate = false;

    if (updatedUser.firstName !== currentUser.firstName) {
      shouldUpdate = true;
    }
    if (updatedUser.lastName !== currentUser.lastName) {
      shouldUpdate = true;
    }
    if (updatedUser.phoneNumber !== currentUser.phoneNumber) {
      shouldUpdate = true;
    }
    if (updatedUser.email !== currentUser.email) {
      shouldUpdate = true;
    }
    if (shouldUpdate) {
      this.setNewValues(this.props.userDetails);
    }
  }

  toggleIsModalActive = (): void => {
    this.setState({ isModalActive: !this.state.isModalActive });
  };

  submit = (values: ProfileModel): void => {
    // this.ngOnInit();
    this.props.updateUser(values);
    this.setState({
      canSave: false,
      isEditActive: {
        firstName: false,
        lastName: false,
        phoneNumber: false,
        email: false,
      },
    });
  };

  toggleEdit = (editField): void => {
    const copyIsEditActive = this.state.isEditActive;
    if (copyIsEditActive[editField] === true) {
      this.setStateTempUserInfo(editField, this.props.userDetails[editField]);
      this.setStateErrorField(editField, false);
      this.setState({ canSave: false });
    }
    copyIsEditActive[editField] = !copyIsEditActive[editField];
    this.setState({ isEditActive: copyIsEditActive });
  };

  // recaptcha function
  siteKeyCaptcha: string = '6LcyEk4UAAAAAIJvKFb_qiQopLvvsUSTzA9tYX90';

  ngOnInit() {
    grecaptcha.render('capcha_element', {
      sitekey: this.siteKeyCaptcha,
    });
    window['getResponseCapcha'] = this.getResponseCapcha.bind(this);
  }

  getResponseCapcha(captchaResponse: string) {
    this.verifyCaptcha(captchaResponse);
  }

  verifyCaptcha(captchaResponse: string) {
    alert(captchaResponse);
  }

  removeOffice = async (selectedOffice) => {
    const { profile } = this.context;
    const updatedOfficeList = [];

    if (profile) {
      const officeList = this.whichOfficeListIsOfficeOnProfile(profile, selectedOffice);

      if (officeList === 'workedwithoffices') {
        profile.workedWithOffices.forEach((office) => {
          if (office.id !== selectedOffice.id) {
            const returnObj = {
              id: office.id,
              type: officeTypeFullFormDictionary[office.type]
                ? officeTypeFullFormDictionary[office.type]
                : office.type,
            };
            updatedOfficeList.push(returnObj);
          }
        });
      } else if (officeList === 'offices') {
        profile.offices.forEach((office) => {
          if (office.id !== selectedOffice.id) {
            const returnObj = {
              id: office.id,
              type: officeTypeFullFormDictionary[office.type]
                ? officeTypeFullFormDictionary[office.type]
                : office.type,
            };
            updatedOfficeList.push(returnObj);
          }
        });
      } else {
        console.error('office not found on profile ', selectedOffice.id);
        return;
      }
      this.props.removeUserOffice(updatedOfficeList, officeList === 'workedwithoffices');
      fireGAEvent(
        ACCOUNT_MANAGEMENT__REMOVED_OFFICE(officeTypeFullFormDictionary[selectedOffice.type]),
      );
    }
  };
  whichOfficeListIsOfficeOnProfile = (profile, selectedOffice): string => {
    let list = '';
    profile.offices.forEach((o) => {
      if (o.id === selectedOffice.id) {
        list = 'offices';
      }
    });
    profile.workedWithOffices.forEach((wwo) => {
      if (wwo.id === selectedOffice.id) {
        list = 'workedwithoffices';
      }
    });
    return list;
  };

  resetPassword = () => {
    window.location.assign(`${getResetPasswordURL()}`);
  };

  handleEditAccountType = () => {
    const { profile } = this.context;
    this.toggleIsModalActive();
    // This event is here intentionally even though it just links to the support form
    fireGAEvent(ACCOUNT_MANAGEMENT__ACCOUNT_TYPE_CHANGED(profile.accountType));
  };

  setNewValues = (newValues: UserDetails) => {
    this.state.tempUserInfo.firstName = newValues.firstName;
    this.state.tempUserInfo.lastName = newValues.lastName;
    this.state.tempUserInfo.phoneNumber = newValues.phoneNumber;
    this.state.tempUserInfo.email = newValues.email;
    this.state.tempUserInfo.narId = newValues.narId;
  };

  setStateTempUserInfo = (editField, value) => {
    const updatedTempUserInfo = this.state.tempUserInfo;
    updatedTempUserInfo[editField] = value;
    this.setState({ tempUserInfo: updatedTempUserInfo });
  };

  setStateErrorField = (editField, value) => {
    const updatedErrors = this.state.errors;
    updatedErrors[editField] = value;
    this.setState({ errors: updatedErrors });
  };

  handleChange = (editField, value) => {
    this.setStateTempUserInfo(editField, value);
    if (this.props.userDetails[editField] !== value) {
      this.setState({ canSave: true });
    } else {
      this.setState({ canSave: false });
    }
  };

  handleSubmit = () => {
    const updatedUser = this.props.userDetails;
    updatedUser.firstName = this.state.tempUserInfo.firstName;
    updatedUser.lastName = this.state.tempUserInfo.lastName;
    updatedUser.phoneNumber = this.state.tempUserInfo.phoneNumber;
    updatedUser.email = this.state.tempUserInfo.email;
    updatedUser.narID = this.state.tempUserInfo.narId;
    this.props.updateUser(updatedUser);

    this.setState({
      canSave: false,
      isEditActive: {
        firstName: false,
        lastName: false,
        phoneNumber: false,
        email: false,
      },
      errors: {
        firstName: false,
        lastName: false,
        phoneNumber: false,
        email: false,
      },
    });
  };

  handleBlur = (editField) => {
    let fieldError: boolean;
    switch (editField) {
      case AccountFields.FIRST_NAME:
        fieldError = !this.state.tempUserInfo.firstName;
        break;
      case AccountFields.LAST_NAME:
        fieldError = !this.state.tempUserInfo.lastName;
        break;
      case AccountFields.PHONE_NUMBER:
        fieldError = !isPhoneNumberValid(this.state.tempUserInfo.phoneNumber);
        break;
      case AccountFields.EMAIL:
        fieldError = !isEmailValid(this.state.tempUserInfo.email);
        break;
      default:
        console.error('Error: Invalid field: ', editField);
        break;
    }
    this.setStateErrorField(editField, fieldError);
    if (fieldError) {
      this.setState({ canSave: false });
    }
  };

  render() {
    const { profile } = this.context;

    const { isModalActive, isLoading, authProviderType } = this.state;
    const { userDetails, userOfficeDetail, userWorkedWithOfficeDetail } = this.props;

    let affiliatedOffices = userOfficeDetail;
    let workedWithOffices = userWorkedWithOfficeDetail;

    if (isInternalUser(profile)) {
      affiliatedOffices = getOfficesByType(
        userOfficeDetail,
        userWorkedWithOfficeDetail,
        OfficeType.RealEstate,
      );
      workedWithOffices = getOfficesByType(
        userOfficeDetail,
        userWorkedWithOfficeDetail,
        OfficeType.ClosingCompany,
      );
    }

    return (
      <>
        <div className="container py-10">
          <REText id="lbl-accountDetails" variant="heading-03">
            Account Details
          </REText>
          {!isLoading && (
            <Panel
              border="0"
              className={IsTheme(Theme.Ahs2024) ? 'p-4 md:p-6 mt-6' : 'px-8 py-10 mt-8'}
              rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'md'}
              padding="none"
              shadow={IsTheme(Theme.Ahs2024) ? false : true}
            >
              <REText
                id="lbl-accountInformation"
                className={IsTheme(Theme.Ahs2024) ? 'mb-6' : 'mb-4'}
                variant="heading-05"
              >
                Account Information
              </REText>
              <div className={IsTheme(Theme.Ahs2024) ? 'flex flex-col' : 'md:flex'}>
                <div
                  className={
                    IsTheme(Theme.Ahs2024) ? 'flex flex-col flex-1' : 'md:w-3/5 md:pr-8 lg:pr-16'
                  }
                >
                  <div className={`${IsTheme(Theme.Ahs2024) ? '' : '-mx-4'} flex flex-wrap`}>
                    <div className={IsTheme(Theme.Ahs2024) ? 'w-full' : 'w-full sm:w-3/4 ml-4'}>
                      <REText id="lbl-contactInformation" className="mb-2" variant="heading-06">
                        Contact Information
                      </REText>
                      <div
                        className="w-full pb-2 flex"
                        style={IsTheme(Theme.Ahs2024) ? { maxWidth: '680px' } : {}}
                      >
                        <Input
                          formField
                          required
                          id="wb_my-account-first-name"
                          formFieldMessageId={getFormInputErrorId('wb_my-account-first-name')}
                          formFieldClassName="mr-5 w-full"
                          label="First Name"
                          placeholder="First Name"
                          value={this.state.tempUserInfo.firstName}
                          onChange={(e) =>
                            this.handleChange(AccountFields.FIRST_NAME, e.target.value)
                          }
                          onBlur={() => this.handleBlur(AccountFields.FIRST_NAME)}
                          error={
                            this.state.errors[AccountFields.FIRST_NAME] ? FIRST_NAME_REQUIRED : ''
                          }
                          disabled={!this.state.isEditActive[AccountFields.FIRST_NAME]}
                          maxLength={c.FIRST_NAME_MAX_LENGTH}
                        />
                        <div
                          className={
                            IsTheme(Theme.Ahs2024)
                              ? 'flex w-fit self-start ml-1 md:min-w-32'
                              : 'py-0 flex-none w-14'
                          }
                        >
                          <Button
                            id="wb_my-account-first-name-edit"
                            className={IsTheme(Theme.Ahs2024) ? 'mt-5' : 'mt-6'}
                            label={
                              this.state.isEditActive[AccountFields.FIRST_NAME] ? 'Cancel' : 'Edit'
                            }
                            width={IsTheme(Theme.Ahs2024) ? 'full' : 'auto'}
                            variant="outlined"
                            color="interactive"
                            size="medium"
                            shape={IsTheme(Theme.Ahs2024) ? 'pill' : 'rounded'}
                            disabled={false}
                            loading={false}
                            onClick={() => this.toggleEdit(AccountFields.FIRST_NAME)}
                          />
                        </div>
                      </div>
                      <div
                        className="w-full py-2 flex"
                        style={IsTheme(Theme.Ahs2024) ? { maxWidth: '680px' } : {}}
                      >
                        <Input
                          formField
                          required
                          id="wb_my-account-last-name"
                          formFieldMessageId={getFormInputErrorId('wb_my-account-last-name')}
                          formFieldClassName="mr-5 w-full"
                          label="Last Name"
                          placeholder="Last Name"
                          value={this.state.tempUserInfo.lastName}
                          onChange={(e) =>
                            this.handleChange(AccountFields.LAST_NAME, e.target.value)
                          }
                          onBlur={() => this.handleBlur(AccountFields.LAST_NAME)}
                          error={
                            this.state.errors[AccountFields.LAST_NAME] ? LAST_NAME_REQUIRED : ''
                          }
                          disabled={!this.state.isEditActive[AccountFields.LAST_NAME]}
                          maxLength={c.LAST_NAME_MAX_LENGTH}
                        />
                        <div
                          className={
                            IsTheme(Theme.Ahs2024)
                              ? 'flex w-fit self-start ml-1 md:min-w-32'
                              : 'py-0 flex-none w-14'
                          }
                        >
                          <Button
                            id="wb_my-account-last-name-edit"
                            className={IsTheme(Theme.Ahs2024) ? 'mt-5' : 'mt-6'}
                            label={
                              this.state.isEditActive[AccountFields.LAST_NAME] ? 'Cancel' : 'Edit'
                            }
                            variant="outlined"
                            color="interactive"
                            size="medium"
                            shape={IsTheme(Theme.Ahs2024) ? 'pill' : 'rounded'}
                            disabled={false}
                            loading={false}
                            onClick={() => this.toggleEdit(AccountFields.LAST_NAME)}
                            width={IsTheme(Theme.Ahs2024) ? 'full' : 'auto'}
                          />
                        </div>
                      </div>
                      <div
                        className="w-full py-2 flex"
                        style={IsTheme(Theme.Ahs2024) ? { maxWidth: '680px' } : {}}
                      >
                        <Input
                          formField
                          required
                          id="wb_my-account-phone-number"
                          formFieldMessageId={getFormInputErrorId('wb_my-account-phone-number')}
                          formFieldClassName="mr-5 w-full"
                          label="Phone Number"
                          placeholder="Phone Number"
                          value={this.state.tempUserInfo.phoneNumber}
                          onChange={(e) =>
                            this.handleChange(AccountFields.PHONE_NUMBER, e.target.value)
                          }
                          onBlur={() => this.handleBlur(AccountFields.PHONE_NUMBER)}
                          error={
                            this.state.errors[AccountFields.PHONE_NUMBER]
                              ? INVALID_PHONE_NUMBER
                              : ''
                          }
                          disabled={!this.state.isEditActive[AccountFields.PHONE_NUMBER]}
                          maxLength={c.PHONE_MAX_LENGTH}
                        />
                        <div
                          className={
                            IsTheme(Theme.Ahs2024)
                              ? 'flex w-fit self-start ml-1 md:min-w-32'
                              : 'py-0 flex-none w-14'
                          }
                        >
                          <Button
                            id="wb_my-account-phone-number-edit"
                            className={IsTheme(Theme.Ahs2024) ? 'mt-5' : 'mt-6'}
                            label={
                              this.state.isEditActive[AccountFields.PHONE_NUMBER]
                                ? 'Cancel'
                                : 'Edit'
                            }
                            variant="outlined"
                            color="interactive"
                            size="medium"
                            shape={IsTheme(Theme.Ahs2024) ? 'pill' : 'rounded'}
                            disabled={false}
                            loading={false}
                            onClick={() => this.toggleEdit(AccountFields.PHONE_NUMBER)}
                            width={IsTheme(Theme.Ahs2024) ? 'full' : 'auto'}
                          />
                        </div>
                      </div>

                      <div
                        className="w-full pt-2 flex"
                        style={IsTheme(Theme.Ahs2024) ? { maxWidth: '680px' } : {}}
                      >
                        <Input
                          formField
                          required
                          id="wb_my-account-email"
                          formFieldMessageId={getFormInputErrorId('wb_my-account-email')}
                          formFieldClassName="mr-5 w-full"
                          label="Email"
                          placeholder="Email"
                          value={this.state.tempUserInfo.email}
                          onChange={(e) => this.handleChange(AccountFields.EMAIL, e.target.value)}
                          onBlur={() => this.handleBlur(AccountFields.EMAIL)}
                          error={this.state.errors[AccountFields.EMAIL] ? INVALID_EMAIL : ''}
                          disabled={!this.state.isEditActive[AccountFields.EMAIL]}
                        />
                        <div
                          className={
                            IsTheme(Theme.Ahs2024)
                              ? 'flex w-fit self-start ml-1 md:min-w-32'
                              : 'py-0 flex-none w-14'
                          }
                        >
                          <Button
                            id="wb_my-account-email-edit"
                            className={IsTheme(Theme.Ahs2024) ? 'mt-5' : 'mt-6'}
                            label={this.state.isEditActive[AccountFields.EMAIL] ? 'Cancel' : 'Edit'}
                            variant="outlined"
                            color="interactive"
                            size="medium"
                            shape={IsTheme(Theme.Ahs2024) ? 'pill' : 'rounded'}
                            disabled={authProviderType == authProvider.CORPORATE}
                            loading={false}
                            onClick={() => this.toggleEdit(AccountFields.EMAIL)}
                            width={IsTheme(Theme.Ahs2024) ? 'full' : 'auto'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {authProviderType === authProvider.CUSTOMER && (
                    <div className="mt-8">
                      <REText id="lbl-changePassword" className="mb-2" variant="heading-06">
                        Change Password
                      </REText>
                      <Link
                        className={`${IsTheme(Theme.Ahs2024) ? 'font-bold' : ''} mt-2`}
                        id="wb_my-account-change-password"
                        color="interactive"
                        onClick={this.resetPassword}
                      >
                        Click here to reset your password
                      </Link>
                    </div>
                  )}

                  <div className="mt-8">
                    <REText id="lbl-accountType" className="mb-2" variant="heading-06">
                      Account Type
                    </REText>
                    <div className="flex flex-row items-center">
                      <REText id="lbl-role" variant="body-short" className="mr-1">
                        {userDetails.roleIDType}
                      </REText>
                      <Link
                        id="wb_my-account-edit-account-type"
                        className={IsTheme(Theme.Ahs2024) ? 'font-bold' : ''}
                        color="interactive"
                        onClick={this.handleEditAccountType}
                      >
                        Edit Account Type
                      </Link>
                    </div>
                  </div>

                  {hasNarIdDictionary[userDetails.roleIDType] && (
                    <div
                      className={`${IsTheme(Theme.Ahs2024) ? '' : 'lg:w-1/2 lg:pr-4'} mt-8`}
                      style={IsTheme(Theme.Ahs2024) ? { maxWidth: '500px' } : {}}
                    >
                      <Input
                        formField
                        required={false}
                        id="wb_my-account-nar-id"
                        formFieldClassName="pt-0"
                        label="NAR Identification Number"
                        defaultValue={userDetails.narID}
                        onChange={(e) => this.handleChange(AccountFields.NAR_ID, e.target.value)}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>

                <div
                  className={`${IsTheme(Theme.Ahs2024) ? '' : 'md:w-2/5'} flex flex-row sm-max:mt-6 lg:pl-16"`}
                  style={{ justifyContent: 'right' }}
                >
                  <div
                    id="capcha_element"
                    className="g-recaptcha"
                    data-callback="getResponseCapcha"
                    data-sitekey={this.siteKeyCaptcha}
                    data-size="invisible"
                  />
                  <div
                    className={`${IsTheme(Theme.Ahs2024) ? 'md:mt-6 md:min-w-32' : 'mt-6 md:mt-auto'} md:flex md:justify-end sm-max:block sm-max:w-full`}
                  >
                    <Button
                      id="wb_my-account-save-button"
                      variant={IsTheme(Theme.Ahs2024) ? 'outlined' : 'filled'}
                      color="interactive"
                      className="sm-max:block"
                      label="Save"
                      disabled={!this.state.canSave}
                      onClick={() => this.handleSubmit()}
                      width="full"
                      labelAlign="center"
                    />
                  </div>
                </div>
              </div>
            </Panel>
          )}
          <div>
            {!isLoading && !userOfficeDetail && !userWorkedWithOfficeDetail && (
              <h1>No Offices Added</h1>
            )}
            {!isLoading && (
              <div>
                <CardOfficeInfo
                  userDetails={userDetails}
                  title={
                    officeTypeDictionary[userDetails.roleIDType] == 'CC'
                      ? 'Closing Company Offices'
                      : 'Real Estate Offices'
                  }
                  userOfficeDetail={affiliatedOffices}
                  refreshUser={this.props.refreshUser}
                  workedWithOffices={false}
                  removeOffice={this.removeOffice}
                  testIdPrefix={
                    officeTypeDictionary[userDetails.roleIDType] == 'CC' ? 'cc-' : 're-'
                  }
                />
                <CardOfficeInfo
                  userDetails={userDetails}
                  title={
                    officeTypeDictionary[userDetails.roleIDType] == 'CC'
                      ? 'Real Estate Offices'
                      : 'Closing Company Offices'
                  }
                  userOfficeDetail={workedWithOffices}
                  refreshUser={this.props.refreshUser}
                  workedWithOffices={true}
                  removeOffice={this.removeOffice}
                  testIdPrefix={
                    officeTypeDictionary[userDetails.roleIDType] == 'CC' ? 're-' : 'cc-'
                  }
                />
              </div>
            )}
          </div>
        </div>

        <Dialog
          open={isModalActive}
          header="Change Account Type"
          id="change-account-type"
          modal={false}
          closeOnOutsideClick={true}
          showBackdrop={true}
          onClose={this.toggleIsModalActive}
        >
          <div>
            {ACCOUNT_TYPE_CHANGE}
            <a className="underline" href={Path.ContactUs}>
              support team
            </a>
            .
          </div>
        </Dialog>
      </>
    );
  }
}
