import React, { useEffect, useMemo, useState } from 'react';
import { OrderFormProps } from '@components/orders/forms/OrderForm.types';
import { OrderPlanInfoFormData } from '@app/models/order/order.model';
import OrderForm from '@components/orders/forms/OrderForm';
import PlanCards from '@components/card/PlanCards';
import {
  PlanCardMetaFieldRecordsByLookup,
  PlanCardMetaFields,
  PLANS_AND_PRICES_LABELS,
} from '@templates/misc/PlansAndPricesTemplate';
import { PlanCardContent } from '@components/card/PlanCard';
import CardPlanPriceCoverage, {
  OptionalCoverageItem,
} from '@components/card/CardPlanPriceCoverage';
import ZestyApi from '@apis/zesty.apis';
import { findMatchRecordByName, mapWYSIWYGBulletToArray } from '@pages/misc/PlansAndPrices';
import { getBrand } from '@helpers/brand.utils';
import { PlansAndPricesMetaData, ZestyPlansAndPricesCoverage } from '@apis/models/zesty.api.model';
import { ProductAndCoveragesResponse, ProductPricingResponse } from '@apis/models';
import Skeleton from 'react-loading-skeleton';
import PlansPriceTradeServiceFeeFooter from '@components/misc/PlansPriceTradeServiceFeeFooter';
import REText from '@components/wrappedBDS/REText';

export enum OrderFormPlanInfoField {
  plans = 'plans',
}

interface OrderFormPlanInfoFieldProps
  extends OrderFormProps<OrderPlanInfoFormData, OrderFormPlanInfoField> {
  onPlanSelect: (pvid: string) => void;
  onCoverageSelect: (cvg: OptionalCoverageItem) => void;
  loadingPlans: boolean;
  productCoverages: ProductAndCoveragesResponse[];
  productPricings: ProductPricingResponse[];
}

const OrderFormPlanInfo: React.FC<OrderFormPlanInfoFieldProps> = (props) => {
  const [loadingZestyPlanData, setLoadingZestyPlanData] = useState<boolean>(false);
  const [zestyPlanPricePageData, setZestyPlanPricePageData] =
    useState<PlansAndPricesMetaData>(null);
  const [zestyPlanData, setZestyPlanData] = useState<PlanCardMetaFieldRecordsByLookup>({});
  const [loadingZestyCoverageData, setLoadingZestyCoverageData] = useState<boolean>(false);
  const [zestyCoverageData, setZestyCoverageData] = useState<ZestyPlansAndPricesCoverage[]>([]);

  useEffect(() => {
    loadZestyData();
  }, []);

  const getZestyPlanSubtext = (planName: string): string => {
    return (
      findMatchRecordByName<PlanCardMetaFieldRecordsByLookup, PlanCardMetaFields>(
        zestyPlanData,
        planName,
      )?.planSubtext || ''
    );
  };

  const getZestyCoverageItems = (planName: string): string[] => {
    return (
      findMatchRecordByName<PlanCardMetaFieldRecordsByLookup, PlanCardMetaFields>(
        zestyPlanData,
        planName,
      )?.coverageItems || []
    );
  };

  /** available plan cards. combines zesty data combined with the product and pricing information. selects a card. */
  const availablePlans: PlanCardContent[] = useMemo(() => {
    if (!props.productCoverages) {
      return [];
    }
    return props.productCoverages
      .map<PlanCardContent>(({ product }) => ({
        pvid: product.starPVID,
        heading: product.name,
        subtext: getZestyPlanSubtext(product.name),
        coverages: getZestyCoverageItems(product.name) as string[],
        price: props.productPricings.find((price) => price.product.id === product.starPVID)?.product
          .price,
        bestCoverage: false,
        selected: product.starPVID === props.formData.selectedPlanID,
        zestyBased: true,
      }))
      .sort((a, b) => a.price - b.price);
  }, [
    zestyPlanData,
    zestyCoverageData,
    props.productCoverages,
    props.productPricings,
    props.formData.selectedPlanID,
  ]);

  /** trade service fee depends on the products available. grabs the first trade service fee with assumption all fees are equal. */
  const tradeServiceFee: number = useMemo(() => {
    if (!props.productCoverages) {
      return null;
    }
    let serviceFee: number = 0;
    // grab the service fee for the first plan in the list
    serviceFee = props.productCoverages[0]?.product.serviceFees;
    if (serviceFee === 0) {
      console.warn('serviceFee is 0');
    }
    if (!props.productCoverages.every((p) => p.product.serviceFees === serviceFee)) {
      console.warn(
        'serviceFee do not equal in amount',
        props.productCoverages.map((p) => p.product.serviceFees),
      );
    }
    return serviceFee;
  }, [props.productCoverages]);

  /** optional coverages to display, based on the selected plan id */
  const optionalCoverages: OptionalCoverageItem[] = useMemo(() => {
    if (!props.formData.selectedPlanID || !props.productCoverages || !props.productPricings) {
      return [];
    }
    const availableCoverages = props.productCoverages.find(
      ({ product }) => product.starPVID === props.formData.selectedPlanID,
    );
    const optCvgs: OptionalCoverageItem[] =
      availableCoverages?.coverages.optional.map<OptionalCoverageItem>((cvg) => {
        const zestyData = zestyCoverageData.find((c) => c.coverage_id === cvg.starCoverageId);
        return {
          id: cvg.starCoverageId,
          price:
            props.productPricings
              .find((price) => price.product.id === availableCoverages.product.starPVID)
              ?.optionalCoverages.find((c) => c.id === cvg.starCoverageId)?.price || 0,
          icon: zestyData?.optional_coverage_icon,
          name: zestyData?.updated_name || `<b>${cvg.name}</b>`,
          subtext: zestyData?.description || '<i>Not loaded</i>',
        };
      }) || [];
    const grpCvgs: OptionalCoverageItem[] =
      availableCoverages?.coverages.group.map<OptionalCoverageItem>((cvg) => {
        const zestyData = zestyCoverageData.find((c) => c.coverage_id === cvg.starGroupId);
        return {
          id: cvg.starGroupId,
          price:
            props.productPricings
              .find((price) => price.product.id === availableCoverages.product.starPVID)
              ?.groupCoverages.find((c) => c.id === cvg.starGroupId)?.price || 0,
          icon: zestyData?.optional_coverage_icon,
          name: zestyData?.updated_name || `<b>${cvg.name}</b>`,
          subtext: zestyData?.description || '',
        };
      }) || [];
    return (
      [...optCvgs, ...grpCvgs].sort((a, b) => {
        const zestyDataA = zestyCoverageData.find((c) => c.coverage_id === a.id);
        const zestyDataB = zestyCoverageData.find((c) => c.coverage_id === b.id);
        return zestyDataA?.display_sequence - zestyDataB?.display_sequence;
      }) || []
    );
  }, [
    zestyPlanData,
    zestyCoverageData,
    props.productCoverages,
    props.productPricings,
    props.formData.selectedPlanID,
  ]);

  /** when user selects to view included coverages for a plan, return that information. */
  const getIncludedCoverages = (pvid: string): string[] => {
    const productCoverage = props.productCoverages.find(
      (productCoverage) => productCoverage.product.starPVID === pvid,
    );
    return productCoverage
      ? productCoverage.coverages.included.map((cvg) => cvg.name).sort((a, b) => a.localeCompare(b))
      : [];
  };

  const loadZestyData = () => {
    setLoadingZestyPlanData(true);
    ZestyApi.GetPlansAndPricesData(getBrand())
      .then((metaData) => {
        setZestyPlanPricePageData(metaData);
        if (metaData) {
          const metaFields: PlanCardMetaFieldRecordsByLookup = {};
          metaData.plans.forEach((plan) => {
            metaFields[plan.lookup_text] = {
              planSubtext: plan.plan_subtext,
              coverageItems: mapWYSIWYGBulletToArray(plan.coverage_items),
            };
          });
          setZestyPlanData(metaFields);
        }
      })
      .catch((e) => {
        console.error('failed to load page meta', e);
      })
      .finally(() => {
        setLoadingZestyPlanData(false);
      });

    setLoadingZestyCoverageData(true);
    ZestyApi.GetPlansAndPricesCoverages(getBrand())
      .then((data) => {
        setZestyCoverageData(data);
      })
      .catch((e) => {
        console.error('failed to load page meta', e);
      })
      .finally(() => {
        setLoadingZestyCoverageData(false);
      });
  };

  return (
    <>
      <OrderForm.Row>
        <OrderForm.RowItem>
          <div className="w-full flex space-x-2">
            {props.loadingPlans && (
              <PlanCards plans={[]} onSelectCallback={null} loading={true} loadingCount={3} />
            )}
            {!props.loadingPlans && availablePlans.length > 0 && (
              <PlanCards
                loading={false}
                onSelectCallback={props.onPlanSelect}
                plans={availablePlans}
                getIncludedCoverages={getIncludedCoverages}
                zestyBased={true}
                selectedPlanID={props.formData.selectedPlanID}
              />
            )}
          </div>
        </OrderForm.RowItem>
      </OrderForm.Row>

      <OrderForm.Row>
        <PlansPriceTradeServiceFeeFooter
          loadingServiceFee={props.loadingPlans}
          loadingText={loadingZestyPlanData}
          serviceFee={tradeServiceFee}
          serviceFeeDescription={zestyPlanPricePageData?.tscf_text}
          serviceFeeTooltip={zestyPlanPricePageData?.tscf_tooltip}
        />
      </OrderForm.Row>

      {availablePlans.length > 0 && (
        <div className="border-t-1 border-gray-300 mt-5 py-3 space-y-2">
          <REText variant="heading-04" className="mt-5" color="primary">
            {PLANS_AND_PRICES_LABELS.COVERAGE_SELECTION_HEADER}
          </REText>

          <div id="coverages-container">
            <div className="space-y-5 my-3">
              {optionalCoverages.map((cvg) => (
                <CardPlanPriceCoverage
                  key={cvg.id}
                  id={cvg.id}
                  name={cvg.name}
                  subtext={cvg.subtext}
                  icon={cvg.icon}
                  price={cvg.price}
                  selected={
                    !![
                      ...props.formData.selectedOptionalCoverages,
                      ...props.formData.selectedGroupCoverages,
                    ].find((s) => s.id === cvg.id)
                  }
                  onSelect={() => props.onCoverageSelect(cvg)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderFormPlanInfo;
