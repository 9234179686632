import React, { useEffect, useMemo, useState } from 'react';
import { classNames } from '@utils';
import { Checkbox, NumberInput } from '@ftdr/blueprint-components-react';
import { sanitizeId, toTitleCase } from '@helpers/utils';
import { MAX_CVG_QTY_ALLOWED } from '@constants/formField-constants';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

interface Props {
  id: string;
  className?: string;
  items: TableCheckboxOption[];
}

export interface TableCheckboxOption {
  label: string;
  price: number;
  quantity: number;
  maxQuantity?: number;
  isDisabled: boolean;
  meta?: {
    /** used for data attributes for informational purposes only */
    cvgType?: 'optional' | 'group' | 'oldProductGuestUnit';
  };
  /** Note: if selected is true, quantity MUST be non-zero, otherwise it triggers onQuantityChange incorrectly. This field may be removed entirely later */
  selected?: boolean;
  onSelect?: (option: TableCheckboxOption) => void;
  onQuantityChange?: (option: TableCheckboxOption, qty: number) => void;
  quantityEnabled?: boolean;
}

interface CheckboxItemProps {
  option: TableCheckboxOption;
}

const TableCheckboxes: React.FC<Props> = ({ ...props }) => {
  return (
    <div id={props.id} className={classNames([props.className])}>
      <div className="flex flex-col w-full divide-y divide-gray-300 border-t border-b border-gray-300">
        {props.items?.map((option) => (
          <TableCheckboxItem key={JSON.stringify(option)} option={option} />
        ))}
      </div>
    </div>
  );
};

export default TableCheckboxes;

const TableCheckboxItem: React.FC<CheckboxItemProps> = ({ option }) => {
  const [quantity, setQuantity] = useState(option.quantity || 1);

  const maxQuantity = useMemo(() => {
    return option.maxQuantity || MAX_CVG_QTY_ALLOWED;
  }, [option?.maxQuantity]);

  /** common id logic for each item in checkbox */
  const buildId = (suffix?: string) => {
    // currently, QA wants the ids to be the option name itself, normalize the string here
    const prefix = option.label || 'invalid id';
    return sanitizeId(prefix) + (suffix ? `--${suffix}` : '');
  };

  /** update the quantity when quantity changes */
  useEffect(() => {
    if (option.selected) {
      if (option.onQuantityChange && option.quantity !== quantity) {
        option.onQuantityChange(option, quantity || 0);
      }
    }
  }, [quantity, option.selected]);

  /** when the checkbox is unchecked, reset quantity back to 1 */
  useEffect(() => {
    if (!option.selected && quantity > 1) {
      setQuantity(1);
    }
  }, [option.selected]);

  return (
    <div
      id={buildId('container')}
      className={`${IsTheme(Theme.Ahs2024) ? 'justify-between' : ''} flex cursor-pointer items-center py-2 px-2`}
      data-cvgtype={option.meta?.cvgType}
    >
      <div id='optional-coverage' className={`${IsTheme(Theme.Ahs2024) ? '' : 'flex-grow flex'}`}>
        <Checkbox
          id={buildId('checkbox')}
          checked={option.selected}
          label={option.label}
          value={option.label}
          disabled={option.isDisabled}
          onChange={() => option.onSelect(option)}
          name={buildId()}
        />
      </div>

      <div>
        <div className="flex items-center justify-between">
          <div>
            {option.quantityEnabled && option.onQuantityChange && maxQuantity > 1 ? (
              <div>
                {!(option.isDisabled || !option.selected) && (
                  <NumberInput
                    id={buildId('qty')}
                    label="Quantity"
                    disabled={option.isDisabled || !option.selected}
                    size="small"
                    //This logic should be passed to only onChange prop but is passed here due BDS issue : When max value is added(25 here) and try to change by typing 250, though the quantity is 25, displays 250.
                    value={option.quantity < maxQuantity ? option.quantity || 1 : maxQuantity}
                    onChange={(val) =>
                      Number(val) < maxQuantity ? setQuantity(Number(val)) : setQuantity(maxQuantity)
                    }
                    min="1"
                    max={maxQuantity}
                    className={`${IsTheme(Theme.Ahs2024) ? 'optional-coverage-number-input' : ''}`}
                  />
                )}
              </div>
            ) : null}
          </div>
          <div className={`${IsTheme(Theme.Ahs2024) ? 'w-10 text-right optional-coverage-price' : ''}`}>
            <REText
              id={buildId('price')}
              color="primary"
              className={`${IsTheme(Theme.Ahs2024) ? 'text-lg md:text-xl' : 'text-right w-8 ml-3'} font-bold`}
            >
              ${option.price}
            </REText>
          </div>
        </div>
      </div>
    </div>
  );
};
