export enum FilterOperation {
  ADD = 'add',
  REMOVE = 'remove',
  PAGE_CHANGE = 'pageChange',
  SEARCH_INPUT = 'searchInput',
}

export enum FilterType {
  STATUS = 'status',
  DATE = 'date',
  OFFICE = 'office',
  AGENT = 'agent',
  TEXT = 'text',
  PAGE = 'page',
  MISSING_COE = 'missing-coe-only',
  ORDER_SORT = 'orderSort',
  QUOTE_SORT = 'quoteSort',
  CLEAR_FILTER = 'clearFilter',
  UP_FOR_RENEWAL = 'up-for-renewal-only',
}

export enum RealEstateStatus {
  OPEN_ORDER = 'open_order',
  CLOSING_SOON = 'closing_soon',
  CLOSING_LATER = 'closing_later',
  PAYMENT_DUE = 'payment_due',
  PAYMENT_PAST_DUE = 'payment_past_due',
  CLOSED = 'closed',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  EXPIRING = 'expiring',
  CANCELLED_ORDER = 'cancelled_order',
  CANCELLED_CONTRACT = 'cancelled_contract',
  AWAITING_WL_SUBMISSION = 'awaiting_wl_submission',
}

export enum WarrantylinkStatus {
  READY = 'Ready',
  SAVED = 'Saved',
  READYSAVED = 'Ready & Saved',
  CLOSED = 'Closed',
  SUBMITTED = 'Submitted',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
}

export enum QuoteStatus {
  SAVED = 'Saved',
  CANCELLED = 'Cancelled',
  SHARED = 'Shared',
  CONVERTED_TO_ORDER = 'Converted',
  EXPIRED = 'Expired',
}
