import React, { useContext } from 'react';
import ProfileContext from '../../context/ProfileContext/index';

// Components
import Skeleton from 'react-loading-skeleton';
import TooltipMenu from '@components/tooltip/TooltipMenu';
import { UserRoleType } from '@constants/dictionaries';
import { Link, Panel } from '@ftdr/blueprint-components-react';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';
import LineItem from '@components/offices/LineItem';

const CardOffice = (props) => {
  const { profile } = useContext(ProfileContext);

  return (
    <Panel
      rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'none'}
      backgroundColor="white"
      borderT={IsTheme(Theme.Ahs2024) ? '0' : '2'}
      borderColor="gray-100"
      shadow={IsTheme(Theme.Ahs2024) ? false : true}
      padding="xl"
      className="flex flex-col h-80"
      id={props.id}
    >
      <div className="flex justify-between relative">
        <>
          <REText
            id={`office-name-${props.id}`}
            className={IsTheme(Theme.Ahs2024) ? 'mb-3' : ''}
            variant={IsTheme(Theme.Ahs2024) ? 'label' : 'heading-06'}
          >
            {props.name}
          </REText>

          {/* WLK invite is the only menuLink, don't hide if another is added. Remove from menuLinks prop instead. */}
          {profile.roleIDType === UserRoleType.SiteAdmin && props.warrantyLinkEligible && (
            <div
              id={`action_dropdown-${props.id}`}
              className="ml-4 absolute right-0"
              style={{ transform: 'translateY(-10px)' }}
              onClick={(e) => e.stopPropagation()}
            >
              <TooltipMenu menuItems={props.menuLinks} />
            </div>
          )}
        </>
      </div>

      <div id={`office_address-${props.id}`} className={IsTheme(Theme.Ahs2024) ? '' : 'md:mb-1'}>
        {props.isSkeleton ? (
          <Skeleton width={200} height={16} />
        ) : (
          <REText
            id={`office_street-address-${props.id}`}
            variant={IsTheme(Theme.Ahs2024) ? 'caption' : 'body-short'}
            className={IsTheme(Theme.Ahs2024) ? 'mb-3' : ''}
          >
            {props.address.address1}
          </REText>
        )}

        {props.isSkeleton ? (
          <Skeleton width={200} height={16} />
        ) : (
          <REText
            variant={IsTheme(Theme.Ahs2024) ? 'caption' : 'body-short'}
            id={`office_country-address-${props.id}`}
            className={IsTheme(Theme.Ahs2024) ? 'mb-3' : ''}
          >
            {`${props.address.city}, ${props.address.state} ${props.address.zip}`}
          </REText>
        )}
      </div>

      <div className={IsTheme(Theme.Ahs2024) ? 'mb-3' : 'mb-1'}>
        {props.isSkeleton ? (
          <Skeleton width={200} height={16} />
        ) : (
          <LineItem
            id={`phone-${props.id}`}
            label="Office:"
            caption={
              <Link href={`tel:${props.phoneNumber}`} color="interactive">
                {props.phoneNumber}
              </Link>
            }
          />
        )}
      </div>

      <div id={`distributor-id-${props.id}`} className={IsTheme(Theme.Ahs2024) ? 'mb-3' : 'mb-1'}>
        {props.isSkeleton ? (
          <Skeleton width={200} height={16} />
        ) : (
          <LineItem id={`distributor-id-lbl-${props.id}`} label="Dist ID:" caption={props.id} />
        )}
      </div>

      {profile.roleIDType === UserRoleType.SiteAdmin && props.warrantyLinkEligible && (
        <div className={IsTheme(Theme.Ahs2024) ? '' : 'md:mb-1 leading-tight'}>
          <REText
            id={`warrantyLink_eligible-${props.id}`}
            variant={IsTheme(Theme.Ahs2024) ? 'label' : 'heading-06'}
            className={IsTheme(Theme.Ahs2024) ? 'mb-3' : ''}
          >
            WarrantyLink Eligible
          </REText>
          {props.warrantyLinkStatus && (
            <LineItem
              id={`warrantyLink-status-${props.id}`}
              label="Status:"
              caption={props.warrantyLinkStatus}
            />
          )}
        </div>
      )}
    </Panel>
  );
};

export default CardOffice;
