import React, { useContext } from 'react';
import Layout from '@components/layout/Layout';
import ContactUsTemplate from '@templates/contact/ContactUsTemplate';
import ProfileContext from '../../context/ProfileContext/index';
import { ContentBox } from '@components/layout/ContentBox';

const ContactUsAuth = (props) => {
  const { profile } = useContext(ProfileContext);

  return (
    <Layout isLoggedIn={true}>
      <ContentBox title={`Contact Us`}>
        <ContactUsTemplate
          email={profile.email}
          roleType={profile.roleIDType}
          fullName={`${profile.firstName} ${profile.lastName}`}
          isAuthed={true}
          contractId={props?.contractId}
          topic={props?.topic}
          emailText={props?.emailText}
        />
      </ContentBox>
    </Layout>
  );
};
export default ContactUsAuth;
