import React, { useState } from 'react';
import CardNewOrderPlansCoverage, {
  CardNewOrderPlansCoverageProps,
  PlansCoverageValues,
  PropertyDetails,
} from '@components/card/CardNewOrderPlansCoverage';
import {
  Accordion,
  AccordionSection,
  Button,
  Input,
  Panel,
  DateTimeInput,
  Select,
  Checkbox,
  TextArea,
  IconPage,
  ProgressIndicator,
  ContextMenu,
  MenuItem,
  Popover,
  PopoverContent,
  IconPlus,
  Dialog,
  IconNavArrowRight,
  Text,
} from '@ftdr/blueprint-components-react';
import addDays from 'date-fns/addDays';
import { ContractPricing } from '@apis/models';
import ContractApi from '@apis/contract.api';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { dwellingTypeDictionary } from '@constants/dictionaries';
import { getAgeByYearBuilt } from '@services/helpers';
import { parseDate } from '@helpers/utils';
import { isROProduct } from '@helpers/order.utils';
import { MILITARY_DISCOUNT } from '@constants/newOrder-constants';
import REText from '@components/wrappedBDS/REText';

const exampleFormData: PlansCoverageValues = {
  selectedGuestUnit: false,
  selectedFilters: {
    termYear: 1,
    includeSellersCoverage: true,
    includeACCoverage: false,
  },
  selectedPlanCoverages: {
    product: {
      id: '14d0c728-07ae-4c84-9c23-8694bfddf689',
      name: 'ShieldEssential: Buyer Only ',
      acCoverage: true,
      sellersCoverage: false,
      contractTermMonths: 12,
      starPVID: '24977',
    },
    optionalCoverages: [
      {
        id: '25',
        name: 'Subterranean Termite Treatment',
        quantity: 1,
      },
      {
        id: '104471',
        name: 'Spa Only',
        quantity: 1,
      },
    ],
    groupCoverages: [],
  },
  selectedMilitaryDiscount: false,
};

const stringifiedExampleFormData = JSON.stringify(exampleFormData, null, 2);

const DemoRefactoredPlanCoverages: React.FC<any> = () => {
  const { addErrorToQueue, addMessageToQueue } = useGlobalAlert();

  const [formData, setFormData] = useState<PlansCoverageValues>(null);
  const [pricing, setPricing] = useState<ContractPricing>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [enableQuantitySelection, setEnableQuantitySelection] = useState(false);
  const [disableSellersSelection, setDisableSellersSelection] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [alertText, setAlertText] = useState<string>(null);

  const onSave = (data) => {
    setPrevPlanCardProps(getPlanCardProps());
    setFormData(data.formData);
    setPricing(data.pricing);
    setIsEditing(false);
  };

  const onClose = () => {
    setIsEditing(false);
  };

  const onOpen = () => {
    setIsEditing(true);
  };

  /* ----------- states used for demo only ------------ */
  const [formDataError, setFormDataError] = useState<string>(null);

  const [showCard, setShowCard] = useState(false);

  const [isSmall, setIsSmall] = useState(true);
  const [defaultCollapsed, setDefaultCollapsed] = useState(true);

  const [propertyAddress, setPropertyAddress] = useState({
    address1: '111 MAIN ST',
    address2: '',
    city: 'MEMPHIS',
    state: 'TN',
    zip: '38138',
    zipPlus4: '',
  });
  const [propertyDetails, setPropertyDetails] = useState<PropertyDetails>({
    residenceType: 'SingleFamilyResidence',
    squareFootage: 4999,
    age: 10,
  });

  const [initiatingOfficeId, setInitiatingOfficeId] = useState('14252');
  const [initiatingOfficeFranchiseCode, setInitiatingOfficeFranchiseCode] = useState('');
  const [roProductsOnly, setROProductsOnly] = useState<boolean>(false);

  const [projectedClosingDate, setProjectedClosingDate] = useState(addDays(new Date(), 30));
  const [listingEffectiveDate, setListingEffectiveDate] = useState<Date>(null);

  const [loadingContract, setLoadingContract] = useState<boolean>(false);
  const searchContract = (id: string) => {
    // TODO: ARE-7272 is needed to fetch the least amount of data for plan/coverages. For now using the get contract endpoint.

    // remove whitespace, and don't call until we have 5 characters or more
    id = id.trim();
    if (id.length < 5) {
      return;
    }

    setLoadingContract(true);

    ContractApi.getContractDetails([id], true, true)
      .then((contracts) => {
        const contract = contracts[0];
        const { property, importantDates, initiatingOfficeAgent } = contract.detail;

        setPricing(null);
        setFormData(null);

        ContractApi.getContractPricing(id)
          .then((contractPricing) => {
            loadDrawerView();
            setDisableEdit(contract.detail.contractStatus !== 'L');

            setPricing(contractPricing);
            setInitiatingOfficeId(initiatingOfficeAgent.office.id);
            setInitiatingOfficeFranchiseCode(initiatingOfficeAgent.office.franchiseCode);

            setProjectedClosingDate(
              parseDate(importantDates.closingDate || importantDates.sellersCoverageExpirationDate),
            );
            setListingEffectiveDate(parseDate(importantDates.listDate));

            setPropertyAddress({
              ...contract.summary.address,
              zipPlus4: '',
            });

            let residenceType = dwellingTypeDictionary[property.dwellingType];
            residenceType = residenceType.charAt(0).toUpperCase() + residenceType.slice(1);

            setPropertyDetails({
              residenceType,
              age: getAgeByYearBuilt(
                +property.yearBuilt,
                new Date(importantDates.listDate).getFullYear(),
              ),
              squareFootage: property.squareFeet,
            });

            ContractApi.getContractPlanCoverages(id)
              .then((planAndCoverage) => {
                setDisableSellersSelection(planAndCoverage.product.sellersCoverage);
                setROProductsOnly(isROProduct(planAndCoverage.product));

                setFormData({
                  selectedFilters: {
                    includeSellersCoverage: planAndCoverage.product.sellersCoverage,
                    includeACCoverage: planAndCoverage.product.acCoverage,
                    termYear: planAndCoverage.product.contractTermMonths / 12,
                  },
                  selectedGuestUnit: property.numberMotherLawUnits > 0,
                  selectedPlanCoverages: {
                    product: {
                      id: planAndCoverage.product.id,
                      starPVID: planAndCoverage.product.id,
                      name: planAndCoverage.product.name,
                      sellersCoverage: planAndCoverage.product.sellersCoverage,
                      contractTermMonths: planAndCoverage.product.contractTermMonths,
                      acCoverage: planAndCoverage.product.acCoverage,
                    },
                    optionalCoverages: planAndCoverage.optionalCoverages.map((cvg) => ({
                      id: cvg.id,
                      name: cvg.name,
                      quantity: cvg.quantity,
                    })),
                    groupCoverages: planAndCoverage.groupCoverages.map((cvg) => ({
                      id: cvg.id,
                      name: cvg.name,
                      quantity: cvg.quantity,
                    })),
                  },
                  selectedMilitaryDiscount:
                    contractPricing?.appliedSpecialDiscounts?.includes(MILITARY_DISCOUNT),
                });
              })
              .catch((e) => {
                console.error(e);
                addErrorToQueue({
                  message: 'Unable to load existing contract prices',
                });
              })
              .finally(() => {
                setLoadingContract(false);
              });
          })
          .catch((e) => {
            console.error(e);
            setLoadingContract(false);
            addErrorToQueue({
              message: 'Unable to load existing contract prices',
            });
          });
      })
      .catch((e) => {
        console.error(e);
        setLoadingContract(false);
        addErrorToQueue({
          message: 'Unable to load contract',
        });
      });
  };

  const loadNewOrderView = () => {
    setIsSmall(false);
    setIsEditing(true);
    setDefaultCollapsed(true);
    setListingEffectiveDate(null);
    setEnableQuantitySelection(false);
    setDisableSellersSelection(false);
    setDisableEdit(false);
  };

  const loadDrawerView = () => {
    setIsSmall(true);
    setIsEditing(false);
    setDefaultCollapsed(true);
    setListingEffectiveDate(new Date());
    setEnableQuantitySelection(true);
  };

  const getPlanCardProps = (): CardNewOrderPlansCoverageProps => ({
    formData,
    pricing,
    onSave,
    onClose,
    onOpen,
    isEditing,
    isSmall,
    defaultCollapsed,
    initiatingOfficeId,
    initiatingOfficeFranchiseCode,
    propertyAddress,
    propertyDetails,
    listingEffectiveDate,
    enableQuantitySelection,
    disableSellersSelection,
    disableEdit,
    listingExpirationDate: projectedClosingDate,
    alertText,
    roProductsOnly,
  });

  const loadPlanCardProps = (item: Partial<CardNewOrderPlansCoverageProps>) => {
    setFormData(item.formData);
    setPricing(item.pricing);
    setIsEditing(item.isEditing);
    setIsSmall(item.isSmall);
    setDefaultCollapsed(item.defaultCollapsed);
    setInitiatingOfficeId(item.initiatingOfficeId);
    setInitiatingOfficeFranchiseCode(item.initiatingOfficeFranchiseCode);
    setPropertyAddress(item.propertyAddress);
    setPropertyDetails(item.propertyDetails);
    setEnableQuantitySelection(item.enableQuantitySelection);
    setDisableSellersSelection(item.disableSellersSelection);
    setDisableEdit(item.disableEdit);
    setAlertText(null);
    setROProductsOnly(item.roProductsOnly);

    if (item.listingEffectiveDate) {
      setListingEffectiveDate(new Date(item.listingEffectiveDate));
    }
    if (item.listingExpirationDate) {
      setProjectedClosingDate(new Date(item.listingExpirationDate));
    }
  };

  /** sets any related RO only props, such as NC not being allowed */
  const setROProductProps = (flag: boolean) => {
    setROProductsOnly(flag);
    if (flag) {
      const updates: string[] = [];

      if (propertyDetails.age < 2) {
        updates.push('NC to non-NC');
        setPropertyDetails({ ...propertyDetails, age: 10 });
      }
      if (initiatingOfficeFranchiseCode.length > 0) {
        updates.push('franchise code (office id also needs to not be franchise)');
        setInitiatingOfficeFranchiseCode('');
      }

      if (updates.length) {
        addMessageToQueue({
          messageHTML: (
            <>
              The following were also modified: <br />
              {updates.join(', ')}
            </>
          ),
        });
      }
    }
  };

  const [prevPlanCardProps, setPrevPlanCardProps] =
    useState<CardNewOrderPlansCoverageProps>(getPlanCardProps());

  const panelClassNames = 'flex-1 flex flex-col space-y-2 max-h-160 overflow-y-auto';

  /* ------------------------------------------------- */

  return (
    <div className="my-5 mb-8 flex flex-row">
      <div className="w-1/3 px-2">
        <Accordion mode="multiple">
          <AccordionSection label="Load Existing Contract ID">
            <div className="flex space-x-2 m-5">
              <Panel className={panelClassNames}>
                <Input
                  required={true}
                  label="Enter a Valid Contract ID, then blur from field"
                  formField={true}
                  hint="This would update the view to view order mode, set closing date, listing effective, listing expiration, offices, and property"
                  placeholder="334421638"
                  secondaryAction={
                    <div>
                      {loadingContract && <ProgressIndicator variant="circular" size="small" />}
                    </div>
                  }
                  onBlur={(e) => searchContract(e.target.value)}
                />
              </Panel>
            </div>
          </AccordionSection>
          <AccordionSection
            label={`Form Data: ${formData === null ? 'Currently Empty' : 'Loaded'}`}
          >
            <Panel className="my-5">
              <b>Submitted Form Data:</b>{' '}
              <small>(on successful save, form data and demo prop data should be updated)</small>
              <br />
              <pre>{JSON.stringify(formData, null, 2)}</pre>
            </Panel>
          </AccordionSection>
          <AccordionSection label="Prop Data">
            <div className="flex flex-col space-y-2 m-5">
              <Panel className={panelClassNames}>
                <b>Order Data</b>
                <Input
                  required={true}
                  label="Initiating Office ID"
                  formField={true}
                  defaultValue={initiatingOfficeId}
                  onBlur={(e) => setInitiatingOfficeId(e.target.value)}
                />
                <Input
                  required={true}
                  label="Initiating Office Franchise Code"
                  formField={true}
                  defaultValue={initiatingOfficeFranchiseCode}
                  onBlur={(e) => setInitiatingOfficeFranchiseCode(e.target.value)}
                />
                <Checkbox
                  label="RO Products"
                  checked={roProductsOnly}
                  onChange={(e) => setROProductProps(e.target.checked)}
                />
                <DateTimeInput
                  onlyDate={true}
                  datePicker={true}
                  showDateClearButton={true}
                  label="Projected Closing Date"
                  hint="Also considered the Listing Coverage Expiration Date"
                  selectedDate={projectedClosingDate}
                  onDateSelect={setProjectedClosingDate}
                />
                <hr />
                <DateTimeInput
                  onlyDate={true}
                  datePicker={true}
                  showDateClearButton={true}
                  label="Listing Effective Date for old products"
                  selectedDate={listingEffectiveDate}
                  onDateSelect={setListingEffectiveDate}
                  onDateClear={() => setListingEffectiveDate(null)}
                  hint="You need to set the flag below on edit order for this date to fetch old products."
                />
              </Panel>
              <Panel className={panelClassNames}>
                <b>Property Data</b>
                <Input
                  required={true}
                  label="Address1"
                  formField={true}
                  defaultValue={propertyAddress.address1}
                  onBlur={(e) =>
                    setPropertyAddress({ ...propertyAddress, address1: e.target.value })
                  }
                />
                <Input
                  label="Address2"
                  formField={true}
                  defaultValue={propertyAddress.address2}
                  onBlur={(e) =>
                    setPropertyAddress({ ...propertyAddress, address2: e.target.value })
                  }
                />
                <div className="flex space-x-2">
                  <Input
                    required={true}
                    label="City"
                    formField={true}
                    defaultValue={propertyAddress.city}
                    onBlur={(e) => setPropertyAddress({ ...propertyAddress, city: e.target.value })}
                  />
                  <Input
                    required={true}
                    label="State"
                    formField={true}
                    maxLength={2}
                    defaultValue={propertyAddress.state}
                    onBlur={(e) =>
                      setPropertyAddress({ ...propertyAddress, state: e.target.value })
                    }
                  />
                </div>
                <div className="flex space-x-2">
                  <Input
                    required={true}
                    label="ZIP"
                    formField={true}
                    maxLength={5}
                    defaultValue={propertyAddress.zip}
                    onBlur={(e) => setPropertyAddress({ ...propertyAddress, zip: e.target.value })}
                  />
                  <Input
                    label="ZIP+4"
                    formField={true}
                    maxLength={4}
                    defaultValue={propertyAddress.zipPlus4}
                    onBlur={(e) =>
                      setPropertyAddress({ ...propertyAddress, zipPlus4: e.target.value })
                    }
                  />
                </div>

                <hr />

                <div className="flex space-x-2">
                  <Select
                    required={true}
                    formField={true}
                    label="Age"
                    selected={{ value: `${propertyDetails.age}` }}
                    onSelect={(s: any) =>
                      setPropertyDetails({ ...propertyDetails, age: Number(s.value) })
                    }
                    options={[10, 1].map((o) => ({ value: `${o}` }))}
                  />
                  <Select
                    required={true}
                    formField={true}
                    label="Square Footage"
                    selected={{ value: `${propertyDetails.squareFootage}` }}
                    onSelect={(s: any) =>
                      setPropertyDetails({ ...propertyDetails, squareFootage: Number(s.value) })
                    }
                    options={[4999, 9999].map((o) => ({ value: `${o}` }))}
                  />
                </div>

                <Select
                  required={true}
                  formField={true}
                  label="Residence Type"
                  selected={{ value: `${propertyDetails.residenceType}` }}
                  onSelect={(s: any) =>
                    setPropertyDetails({ ...propertyDetails, residenceType: s.value })
                  }
                  options={[
                    'SingleFamilyResidence',
                    'Condominium',
                    'Townhouse',
                    'Duplex',
                    'Triplex',
                    'Fourplex',
                    'Mobilehome',
                  ].map((o) => ({ value: `${o}` }))}
                />
              </Panel>
              <Panel className={panelClassNames}>
                <b>View Data</b>
                <Input
                  label="Alert Text"
                  formField={true}
                  maxLength={4}
                  defaultValue={alertText}
                  onBlur={(e) => setAlertText(e.target.value)}
                />
                <Checkbox
                  label="Small Card (Drawer View)"
                  checked={isSmall}
                  onChange={(e) => setIsSmall(e.target.checked)}
                />
                <Checkbox
                  label="Default Collapsed (Mobile View)"
                  checked={defaultCollapsed}
                  onChange={(e) => setDefaultCollapsed(e.target.checked)}
                />
                <Checkbox
                  label="Edit Mode Enabled Initially (New Order View)"
                  checked={isEditing}
                  onChange={(e) => setIsEditing(e.target.checked)}
                />
                <Checkbox
                  label="Disable Sellers Selection (Existing Contracts, has Sellers Product already)"
                  checked={disableSellersSelection}
                  onChange={(e) => setDisableSellersSelection(e.target.checked)}
                />
                <Checkbox
                  label="Enable Quantity Selection (Existing Contracts Only)"
                  checked={enableQuantitySelection}
                  onChange={(e) => setEnableQuantitySelection(e.target.checked)}
                />
                <Checkbox
                  label="Disable Edit (Existing Contracts, not Listing Status)"
                  checked={disableEdit}
                  onChange={(e) => setDisableEdit(e.target.checked)}
                />
              </Panel>
            </div>
          </AccordionSection>
          <AccordionSection label="Custom Form Data (Advanced)">
            <div className="flex flex-col space-y-2 m-5">
              <Panel className={panelClassNames}>
                <TextArea
                  formField={true}
                  id="text-formdata"
                  label="Enter custom form data passed into component"
                  placeholder={stringifiedExampleFormData}
                  rows={30}
                  error={formDataError}
                  onChange={() => setFormDataError(null)}
                  onBlur={(e) => {
                    try {
                      setFormDataError(null);
                      const jsonValue: PlansCoverageValues = JSON.parse(
                        e.target.value,
                      ) as PlansCoverageValues;
                      setFormData(jsonValue);
                    } catch (e) {
                      console.error(e);
                      setFormDataError('Invalid shape of form data');
                    }
                  }}
                />
              </Panel>
              <Button
                label="Load Sample Data Below to Clipboard and Form Data"
                endIcon={<IconPage />}
                size="small"
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(stringifiedExampleFormData);
                  const $textArea = document.getElementById('text-formdata');
                  if ($textArea) {
                    $textArea.textContent = stringifiedExampleFormData;
                    $textArea.blur();
                  }
                }}
              />
            </div>
          </AccordionSection>
        </Accordion>
        <Accordion mode="multiple" defaultActiveIndex={[0]} className="mt-2">
          <AccordionSection label="Current Plan Card Props">
            <Panel className={panelClassNames}>
              <pre className="text-gray text-sm">{JSON.stringify(getPlanCardProps(), null, 2)}</pre>
            </Panel>
          </AccordionSection>
          <AccordionSection label="Plan Card Props Before Submitting">
            <Panel className={panelClassNames}>
              <pre className="text-gray text-sm">{JSON.stringify(prevPlanCardProps, null, 2)}</pre>
            </Panel>
          </AccordionSection>
        </Accordion>
      </div>

      <div className="container mx-auto pt-4 flex-1">
        <div className="mx-5 my-5">
          Destroy the card whenever you want to do a hard reset. Initialize card if you want to
          load. New and Drawer view initializes the card with proper props.
          <div className="flex flex-row space-x-2">
            {!showCard && (
              <>
                <Button
                  variant="outlined"
                  width="full"
                  label="New Order Page View"
                  onClick={() => {
                    loadNewOrderView();
                    setShowCard(true);
                  }}
                />
                <Button
                  variant="outlined"
                  width="full"
                  label="Drawer Order View"
                  onClick={() => {
                    loadDrawerView();
                    setShowCard(true);
                  }}
                />
              </>
            )}
            <Button
              width="full"
              label={showCard ? 'Destroy Card' : 'Initialize Card'}
              onClick={() => setShowCard(!showCard)}
            />
          </div>
        </div>

        {showCard && <CardNewOrderPlansCoverage {...getPlanCardProps()} />}

        <Panel className={panelClassNames}>
          <LocalStorageTestData currentProps={getPlanCardProps()} load={loadPlanCardProps} />
        </Panel>
      </div>
    </div>
  );
};

export default DemoRefactoredPlanCoverages;

interface LocalStorageTestDataProps {
  currentProps: CardNewOrderPlansCoverageProps;
  load: (item: Partial<CardNewOrderPlansCoverageProps>) => void;
}

const LocalStorageTestData: React.FC<LocalStorageTestDataProps> = (props) => {
  const LOCAL_STORAGE_TEST_DATA_KEY = 'demoPlanCardTestProps';

  interface TestPropsData {
    name: string;
    data: Partial<CardNewOrderPlansCoverageProps>;
  }
  const DEFAULT_TEST_DATA: TestPropsData = {
    name: 'Sample TN Example (AHS Only)',
    data: {
      formData: null,
      pricing: null,
      isEditing: false,
      isSmall: true,
      defaultCollapsed: true,
      initiatingOfficeId: '14252',
      initiatingOfficeFranchiseCode: '',
      propertyAddress: {
        address1: '111 MAIN ST',
        address2: '',
        city: 'MEMPHIS',
        state: 'TN',
        zip: '38138',
        zipPlus4: '',
      },
      propertyDetails: {
        residenceType: 'SingleFamilyResidence',
        squareFootage: 4999,
        age: 10,
      },
      listingEffectiveDate: null,
      enableQuantitySelection: false,
      disableSellersSelection: false,
      disableEdit: false,
      listingExpirationDate: new Date('2022-01-13T16:50:36.010Z'),
    },
  };

  const getLocalStorageData = (showSuccessCntMsg = false): TestPropsData[] => {
    try {
      const stringItem = localStorage.getItem(LOCAL_STORAGE_TEST_DATA_KEY);
      if (stringItem) {
        const data: TestPropsData[] = JSON.parse(stringItem);
        if (showSuccessCntMsg) {
          addSuccessToQueue({
            title: 'Loaded test data',
            message: `(${data.length}) test data was loaded`,
          });
        }
        return data;
      }
    } catch (e) {
      console.error('failed to load local storage', e);
      addErrorToQueue({
        message: 'failed to load data from local storage. see console.',
      });
    }
    return [];
  };

  const setLocalStorageData = (data: TestPropsData[]) => {
    localStorage.setItem(LOCAL_STORAGE_TEST_DATA_KEY, JSON.stringify(data, null, 2));
  };

  const addLocalStorageData = (item: TestPropsData, data = getLocalStorageData()) => {
    data.push(item);
    setLocalStorageData(data);
    setTestPropsData(data);
  };

  const removeLocalStorageDataByName = (name: string) => {
    const data = testPropsData.filter((data) => data.name !== name);
    setLocalStorageData(data);
    setTestPropsData(data);
  };

  const { addSuccessToQueue, addErrorToQueue } = useGlobalAlert();

  const initTestPropsData = () => {
    const data = getLocalStorageData(false);
    if (data.length === 0) {
      return [DEFAULT_TEST_DATA];
    }
    return data;
  };

  const [testPropsData, setTestPropsData] = useState<TestPropsData[]>(initTestPropsData());

  const [openModal, setOpenModal] = useState(false);
  const [modalItemData, setModalItemData] = useState<string>('');
  const [modalItemName, setModalItemName] = useState<string>('');
  const [isEditingItem, setIsEditingItem] = useState(false);

  const testPropMenuItems = (item: TestPropsData): MenuItem[] => {
    return [
      {
        label: 'Load',
        className: 'text-primary',
        onClick: () => {
          props.load(item.data);
          addSuccessToQueue({
            messageHTML: (
              <div>
                Loaded <b>{item.name}</b> test scenario.
              </div>
            ),
          });
        },
      },
      {
        label: 'View/Edit',
        className: 'text-primary',
        onClick: () => {
          openEditModal(item);
        },
      },
      {
        label: 'Delete',
        className: 'text-error',
        onClick: () => {
          removeLocalStorageDataByName(item.name);
        },
      },
    ];
  };

  const openSaveModal = () => {
    setOpenModal(true);
    setModalItemData(JSON.stringify(props.currentProps, null, 2));
    setModalItemName('');
  };

  const closeSaveModal = () => {
    setOpenModal(false);
    setModalItemData('');
    setIsEditingItem(false);
  };

  const openEditModal = (item: TestPropsData) => {
    setOpenModal(true);
    setIsEditingItem(true);
    setModalItemData(JSON.stringify(item.data, null, 2));
    setModalItemName(item.name);
  };

  const submitSaveModal = () => {
    try {
      if (isEditingItem) {
        addLocalStorageData(
          {
            data: JSON.parse(modalItemData),
            name: modalItemName,
          },
          testPropsData.filter((data) => data.name !== modalItemName),
        );
      } else {
        addLocalStorageData({
          data: JSON.parse(modalItemData),
          name: modalItemName,
        });
      }
      closeSaveModal();
    } catch (e) {
      console.error(e);
      addErrorToQueue({
        message: 'failed to save data to local storage. see console.',
      });
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row space-x-2 items-center">
        <Button label="Save" size="small" endIcon={<IconPlus />} onClick={openSaveModal} />
        <REText variant="caption">
          Save test scenarios. Can view any saved scenarios and share with others with the save to
          clipboard option. Stored in local storage.
        </REText>
      </div>
      <div>
        <b>Stored Test Scenarios:</b>
        <div className="flex space-x-2">
          {testPropsData.map((data) => (
            <Popover
              key={data.name}
              triggerInteraction="hover-anchor-only"
              placement="auto-end"
              hoverCloseDelay={0}
              overlayClassName="opacity-75"
              content={(popoverContentProps) => (
                <PopoverContent {...popoverContentProps}>
                  <pre className="text-gray text-sm">{JSON.stringify(data.data, null, 2)}</pre>
                </PopoverContent>
              )}
            >
              <ContextMenu menuItems={testPropMenuItems(data)}>
                <Button
                  label={data.name}
                  size="small"
                  variant="outlined"
                  className="m-1"
                  endIcon={<IconNavArrowRight />}
                />
              </ContextMenu>
            </Popover>
          ))}
        </div>
      </div>

      <Dialog
        className="w-160"
        open={openModal}
        onClose={closeSaveModal}
        actions={[
          <Button
            key="save"
            size="small"
            label={isEditingItem ? 'Update' : 'Save'}
            onClick={submitSaveModal}
          />,
          <Button
            key="copy"
            size="small"
            variant="outlined"
            label="Copy to Clipboard"
            onClick={() => navigator.clipboard.writeText(modalItemData)}
          />,
          <Button
            key="cancel"
            size="small"
            variant="ghost"
            label="Cancel"
            color="error"
            onClick={closeSaveModal}
          />,
        ]}
      >
        <div className="flex flex-col space-y-1">
          <div>
            <Input
              formField={true}
              label="Name"
              value={modalItemName}
              onChange={(e) => setModalItemName(e.target.value)}
              disabled={isEditingItem}
            />
            {isEditingItem && (
              <Button
                size="small"
                variant="outlined"
                label="Copy Name to Clipboard"
                onClick={() => navigator.clipboard.writeText(modalItemName)}
              />
            )}
          </div>
          <div className="max-h-64 overflow-y-auto">
            <TextArea
              rows={modalItemData.split('\n').length + 2}
              formField={true}
              label="Data"
              value={modalItemData}
              onChange={(e) => setModalItemData(e.target.value)}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
