import React from 'react';
import REText from '@components/wrappedBDS/REText';

const MustHaveBuyer = () => {
  return (
    <>
      <REText>Must have buyer phone to make payment</REText>
    </>
  );
};

export default MustHaveBuyer;
