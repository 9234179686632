import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  IconNavArrowDown,
  IconNavArrowUp,
} from '@ftdr/blueprint-components-react';
import REText from '@components/wrappedBDS/REText';

interface Props {}

const NewOrderPageDrawer: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      slideFrom="bottom"
      extension="fit-content"
      handle={
        <div className="NewOrderPageDrawer_Handle" onClick={() => setOpen(!open)}>
          <REText variant="heading-06">Order Summary</REText>
          <IconButton
            icon={open ? <IconNavArrowUp /> : <IconNavArrowDown />}
            label="toggle drawer"
            size="small"
            variant="ghost"
          />
        </div>
      }
      slidingContent={<div className="NewOrderPageDrawer_Content">{props.children}</div>}
    />
  );
};

export default NewOrderPageDrawer;
