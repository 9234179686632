import React, { useState, useEffect } from 'react';
import ProfileApi from '@apis/profile.api';
import ProfileModel from '@app/models/profile.model';
import { isInternalUser } from '@helpers/profile.utils';
import { loggedInEmail } from '@app/auth/authService';

export const defaultProfile: ProfileModel = {
  profileID: '',
  authID: '',
  authIDType: '',
  brand: '',
  firstName: '',
  lastName: '',
  roleID: '',
  roleIDType: '',
  email: '',
  phoneNumber: '',
  phoneType: '',
  narID: '',
  AhsUserID: '',
  offices: [],
  workedWithOffices: [],
  hasConfirmedOffice: false,
  active: false,
  isIncomplete: false,
};

interface ContextModel {
  profile: ProfileModel;
  setProfile: any;
  fetchProfileData: any;
}

const ProfileContext = React.createContext<ContextModel>({
  profile: defaultProfile,
  setProfile: null,
  fetchProfileData: null,
});

export const ProfileConsumer = ProfileContext.Consumer;

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  // TODO Find everywhere that is updating Profile manually, and use this instead
  // Easiest way to find them is to stop exporting setProfile, and find what breaks
  const fetchProfileData = async (shouldForceFetch: boolean = false) => {
    let returnedProfile = profile;
    if (!isFetching && (!mounted || shouldForceFetch)) {
      setIsFetching(true);
      try {
        let newProfile: ProfileModel = await ProfileApi.getUser();

        setMounted(true);
        // TODO We need a better way of handling a profile error vs a Not Found profile.
        // We have places fetching the profile directly rather than calling this method
        // So that refactor needs to happen first
        // Once that's done, we can unify a not found profile here better
        if (newProfile === null) {
          newProfile = defaultProfile;
          newProfile.profileID = 'NotFound';
        }

        // patches user email on front end only, leaving user's actual profile alone.
        if (isInternalUser(newProfile)) {
          newProfile.email = loggedInEmail();
        }

        setProfile(newProfile || defaultProfile);
        returnedProfile = newProfile || defaultProfile;
      } catch (e) {
        console.error('failed to fetch profile data', e);
        setProfile(defaultProfile);
        returnedProfile = defaultProfile;
      }
      setIsFetching(false);
    }
    return returnedProfile;
  };

  return (
    <ProfileContext.Provider value={{ profile, setProfile, fetchProfileData }}>
      {(!isFetching || profile) && children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;
