import React, { useState } from 'react';
import {
  Button,
  IconMail,
  IconProhibition,
  IconUser,
  IconRepair,
  IconDiscountTagOutline,
} from '@ftdr/blueprint-components-react';
import CardCustom, { CardCustomSize } from '@components/card/CardCustom';
import { CardTopStripType } from '@components/card/CardTopStrip';
import { BDSMainColor } from '@constants/bds.constants';
import DrawerQLUpdateBuyerInfo from '@components/drawer/DrawerQLUpdateBuyerInfo';
import DrawerQLSendDocuments from '@components/drawer/DrawerQLSendDocuments';
import DrawerQLRequestService from '@components/drawer/DrawerQLRequestService';

import { fireGAEvent } from '@app/core/tracking.service';
import {
  DASHBOARD_CANCEL_ORDER,
  DASHBOARD_REQUEST_SERVICE,
  DASHBOARD_SEND_INVOICE,
  DASHBOARD_UPDATE_BUYER_INFO,
} from '@constants/ga-events.constants';
import DrawerQLCancelOrder from '@components/drawer/DrawerQLCancelOrder';
import { FRONTDOOR_GIFT_UNLIMITED_URL } from '@constants/dashBoard-constants';
import { IsTheme, Theme } from '@app/core/featureToggle';

export interface CardDashboardQuickLinksProps {
  canCollapse?: false | boolean;
  isCollapsed?: true | boolean;
  setCollapsed?: (state: boolean) => void;
}

const CardDashboardQuickLinks: React.FC<CardDashboardQuickLinksProps> = (props) => {
  const [showSendInvoiceDrawer, setShowSendInvoiceDrawer] = useState<boolean>(false);
  const [showUpdateBuyerInfoDrawer, setShowUpdateBuyerInfoDrawer] = useState<boolean>(false);
  const [showCancelOrderDrawer, setShowCancelOrderDrawer] = useState<boolean>(false);
  const [showRequestServiceDrawer, setShowRequestServiceDrawer] = useState<boolean>(false);

  const handleClickGiftService = () => {
    window.open(FRONTDOOR_GIFT_UNLIMITED_URL, '_blank');
  };

  const handleClickRequestService = () => {
    setShowRequestServiceDrawer(true);
    fireGAEvent(DASHBOARD_REQUEST_SERVICE);
  };

  const handleClickUpdateBuyerInfo = () => {
    setShowUpdateBuyerInfoDrawer(true);
    fireGAEvent(DASHBOARD_UPDATE_BUYER_INFO);
  };

  const handleInvoiceClick = () => {
    setShowSendInvoiceDrawer(true);
    fireGAEvent(DASHBOARD_SEND_INVOICE);
  };

  const handleClickCancelOrder = () => {
    setShowCancelOrderDrawer(true);
    fireGAEvent(DASHBOARD_CANCEL_ORDER);
  };

  return (
    <>
      <CardCustom
        size={CardCustomSize.DashboardSmall}
        padding="xs"
        topStripTitle="Quick Links"
        topStripType={
          props.canCollapse ? CardTopStripType.CollapsingHeader : CardTopStripType.Header
        }
        topStripColor={BDSMainColor.Primary}
        isCollapsed={props.isCollapsed}
        setCollapsed={props.setCollapsed}
      >
        {/* TODO Turn the below into a component */}
        <div className={IsTheme(Theme.Ahs2024) ? "h-full flex flex-col justify-center" : "h-full flex flex-col gap-1 pt-5 justify-center"}>
          <Button
            id="dashboard-quick-links-send-invoice"
            startIcon={<IconMail />}
            label="Send Documents"
            variant="ghost"
            size="small"
            width="full"
            className="text-base m-1"
            labelAlign="left"
            onClick={handleInvoiceClick}
          />
          <Button
            id="dashboard-quick-links-buyer-info"
            startIcon={<IconUser />}
            label="Update Buyer Information"
            className="text-base m-1"
            variant="ghost"
            width="full"
            labelAlign="left"
            size="small"
            onClick={handleClickUpdateBuyerInfo}
          />
          <Button
            id="dashboard-quick-links-cancel-order"
            startIcon={<IconProhibition />}
            label="Cancel an Order"
            className="text-base m-1"
            variant="ghost"
            width="full"
            labelAlign="left"
            size="small"
            onClick={handleClickCancelOrder}
          />
          <Button
            id="dashboard-quick-links-request-service"
            startIcon={<IconRepair />}
            label="Request Service"
            className="text-base  m-1"
            labelAlign="left"
            variant="ghost"
            width="full"
            size="small"
            onClick={handleClickRequestService}
          />
          <Button
            id="dashboard-quick-links-frontdoor-gift-service"
            startIcon={<IconDiscountTagOutline />}
            label="Gift Frontdoor Unlimited"
            size="small"
            variant="ghost"
            width="full"
            onClick={handleClickGiftService}
            labelAlign="left"
            className="text-base m-1"
          />
        </div>
      </CardCustom>

      <DrawerQLSendDocuments
        isActive={showSendInvoiceDrawer}
        onClose={() => setShowSendInvoiceDrawer(false)}
      />

      <DrawerQLUpdateBuyerInfo
        isActive={showUpdateBuyerInfoDrawer}
        onClickContract={null}
        onClose={() => setShowUpdateBuyerInfoDrawer(false)}
      />

      <DrawerQLCancelOrder
        isActive={showCancelOrderDrawer}
        onClose={() => setShowCancelOrderDrawer(false)}
      />

      <DrawerQLRequestService
        isActive={showRequestServiceDrawer}
        onClose={() => setShowRequestServiceDrawer(false)}
      />
    </>
  );
};

export default CardDashboardQuickLinks;
