import React, { ChangeEvent, useState } from 'react';
import { Contract } from '@apis/models';
import {
  Button,
  Checkbox,
  IconButton,
  IconPlus,
  IconTrash,
  Input,
  Table,
} from '@ftdr/blueprint-components-react';
import { addressObjectToString } from '@services/helpers';
import { RecipientType } from '@app/generators/recipientGenerator';
import { camelCase, isEmpty } from 'lodash';
import { Recipient, RecipientRow, Type } from '@components/SendDocuments';
import ProfileContext from '@context/ProfileContext';
import ProfileModel from '@app/models/profile.model';
import { classNames, isMobileView } from '@utils';
import { validateEmailInput } from '@components/input/EmailInput';
import { formatDate } from '@helpers/utils';
import { TextSubHeading } from '@components/textUtilities/TextSubHeading';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

interface SendRenewalProps {
  id: string;
  contract: Contract;
  onClose: () => void;
  onConfirm: () => void;
  onChange: any;
  recipients: Recipient[];
  userDetails: ProfileModel;
  hideHeader?: boolean;
}

interface SendRenewalState {
  errorCount: number;
  isSaving: boolean;
}

interface EmailInputProps {
  email: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: string;
  isShort: boolean;
}

interface EmailCellProps {
  value: string;
  row: any;
  data: RecipientRow[];
}

interface NameInputProps {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface NameCellProps {
  value: string;
  row: any;
  data: RecipientRow[];
  getRecipients: any;
}

const getRecipients = (recipientRows: RecipientRow[]): Recipient[] => {
  return recipientRows.map((recipient) => {
    const { name, email, type, selectedTypes } = recipient;

    return {
      name,
      email,
      type,
      selectedTypes,
    };
  });
};

class SendRenewal extends React.Component<SendRenewalProps, SendRenewalState> {
  static contextType = ProfileContext;

  constructor(drawerProps: SendRenewalProps) {
    super(drawerProps);

    this.state = {
      errorCount: 0,
      isSaving: false,
    };
  }

  onValidate = (valid: boolean) => {
    let count = this.state.errorCount;

    if (valid && count > 0) {
      this.setState({ errorCount: (count -= 1) });
    }

    if (!valid) {
      this.setState({ errorCount: (count += 1) });
    }
  };

  getData = () => {
    const recipients = this.props.recipients.map((recipient) => {
      const data = {
        saved: ![RecipientType.Buyer, RecipientType.CoBuyer, RecipientType.Other].includes(
          recipient.type,
        ),
        onChange: this.props.onChange,
        onValidate: this.onValidate,
        ...recipient,
      };

      Object.keys(Type).forEach((type) => {
        data[`${camelCase(type)}Selected`] = { selected: data.selectedTypes.includes(Type[type]) };
      });

      return data;
    });

    const sortedRecipients = [
      ...recipients.filter((recipient) => recipient.type !== RecipientType.Other),
      ...recipients.filter((recipient) => recipient.type === RecipientType.Other),
    ];

    return sortedRecipients;
  };

  getColumns = () => {
    return [
      {
        Header: (
          <REText variant="heading-06" className="lg:text-center">
            Send Renewal Email
          </REText>
        ),
        accessor: Type.Renewal,
        Cell: ({ row }) => this.getCheckbox(row),
      },
      {
        Header: (
          <REText variant="heading-06" className={IsTheme(Theme.Ahs2024) ? 'w-20 md:w-full' : ''}>
            Recipient Type
          </REText>
        ),
        accessor: 'type',
      },
      {
        Header: <REText variant="heading-06">Recipient Name</REText>,
        accessor: 'name',
        Cell: NameCell,
      },
      {
        Header: <REText variant="heading-06">Email Address</REText>,
        accessor: 'email',
        Cell: EmailCell,
      },
    ];
  };

  updateSelection = (id: string, selected: boolean, type: Type): RecipientRow[] => {
    const data = this.getData();
    if (selected) data[id].selectedTypes = [...data[id].selectedTypes, type];

    if (!selected)
      data[id].selectedTypes = data[id].selectedTypes.filter(
        (selectedType) => type !== selectedType,
      );
    return data;
  };

  getCheckbox = (row) => {
    return (
      <div className={IsTheme(Theme.Ahs2024) ? 'flex pl-4 self-center checkbox' : 'lg:text-center'}>
        <Checkbox
          label=""
          name=""
          hideLabel={true}
          onChange={({ target }) =>
            this.props.onChange(
              getRecipients(this.updateSelection(row.id, target.checked, Type.Renewal)),
            )
          }
          checked={row.original.selectedTypes.includes(Type.Renewal)}
        />
      </div>
    );
  };

  getAddRecipientButton = (): React.ReactElement => {
    return (
      <>
        <Button
          label="Add Recipient"
          startIcon={<IconPlus />}
          onClick={() =>
            this.props.onChange([
              ...this.props.recipients,
              { name: '', type: RecipientType.Other, email: '', selectedTypes: [] },
            ])
          }
          width={IsTheme(Theme.Ahs2024) ? 'full' : undefined}
          size="small"
          variant="outlined"
          className="md:hidden"
        />
        <Button
          label="Add Recipient"
          startIcon={<IconPlus />}
          onClick={() =>
            this.props.onChange([
              ...this.props.recipients,
              { name: '', type: RecipientType.Other, email: '', selectedTypes: [] },
            ])
          }
          size="medium"
          variant="outlined"
          className="hidden md:inline-flex"
        />
      </>
    );
  };

  isValid = (): boolean => {
    return this.state.errorCount === 0;
  };

  isSaveDisabled = (): boolean => {
    const selectionsMade = !this.props.recipients.some((r) => {
      return r.selectedTypes.length > 0 && !isEmpty(r.email);
    });

    return !this.isValid() || selectionsMade || this.state.isSaving;
  };

  handleSendPromoEmail = async () => {
    this.setState({ isSaving: true });
    this.props.onConfirm();
  };

  render() {
    const address = addressObjectToString(this.props.contract?.summary?.address);
    const expDate = formatDate(this.props.contract?.detail?.importantDates?.expirationDate);

    return (
      <>
        <div className="w-full px-4">
          {!this.props.hideHeader && (
            <REText variant="heading-03" className="pt-2">
              Send Renewal Email
            </REText>
          )}

          {this.props.contract && !this.props.hideHeader && (
            <>
              <TextSubHeading
                id="send-renewal--property-address"
                label="Covered Property Address: "
                caption={address}
              />
              <TextSubHeading
                id="send-renewal--exp-date"
                label="Contract Expiry Date: "
                caption={expDate}
              />
              <TextSubHeading
                id="send-renewal--text"
                caption="Please select recipients to send the renewal discount email."
              />
            </>
          )}
          <Table
            id="send-renewals-table"
            key={`table-key-${this.props.id}`}
            data={this.getData()}
            columns={this.getColumns()}
            striped="gray"
            variant={IsTheme(Theme.Ahs2024) ? 'heavy' : 'light'}
            paginate={false}
            sortable={false}
          />
          <div className="md:flex">
            <div className="py-4">{this.getAddRecipientButton()}</div>
            <div
              className={classNames([
                'text-center md:flex-wrap md:flex-row-reverse md:justify-start md:items-center w-full md:pl-8 py-4 md:flex',
              ])}
            >
              <div>
                <Button
                  id="btn-send-notification"
                  className="justify-center"
                  label="Send Promo Email"
                  size="medium"
                  onClick={this.handleSendPromoEmail}
                  disabled={this.isSaveDisabled()}
                  width="full"
                  labelAlign="center"
                />
              </div>
              <Button
                className="sm-max:mt-4 md:mr-4"
                label="Cancel"
                size="small"
                variant="ghost"
                onClick={this.props.onClose}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const NameInput: React.FC<NameInputProps> = (props) => {
  return (
    <Input
      formField
      label=""
      size="medium"
      type="text"
      inputClassName="sm-max:pt-0 text-base pr-2 send-documents-input"
      placeholder="Recipient Name"
      value={props.name}
      onChange={props.onChange}
    />
  );
};

const NameCell: React.FC<NameCellProps> = (props) => {
  const {
    row: { id, original },
    data,
    value: name,
  } = props;
  const { saved, onChange, onValidate } = original;
  const dataCopy = [...data]; // copy to avoid mutating props

  if (!isEmpty(name) && original.type !== RecipientType.Other) return <div>{name}</div>;

  const updateName = (newName: string): RecipientRow[] => {
    dataCopy[id].name = newName;
    return dataCopy;
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(getRecipients(updateName(e.target.value)));
  };

  return (
    <>
      <div className="flex">
        <NameInput name={name} onChange={onInputChange} />
      </div>
    </>
  );
};

const EmailInput: React.FC<EmailInputProps> = (props) => {
  return (
    <Input
      formField
      label=""
      size="medium"
      type="email"
      inputClassName={`sm-max:pt-0 text-base pr-2 send-documents-input ${props.isShort ? 'send-documents-input-short' : ''}`}
      placeholder="Email Address"
      value={props.email}
      error={props.errors}
      onChange={props.onChange}
      onBlur={props.onBlur}
    />
  );
};

const EmailCell: React.FC<EmailCellProps> = (props) => {
  const {
    row: { id, original },
    data,
    value: email,
  } = props;
  const { saved, onChange, onValidate } = original;
  const dataCopy = [...data]; // copy to avoid mutating props
  const [errors, setErrors] = useState('');

  const isMobile = isMobileView();

  if (!isEmpty(email) && saved) return <div>{email}</div>;

  const updateEmail = (newEmail: string): RecipientRow[] => {
    dataCopy[id].email = newEmail;
    return dataCopy;
  };

  const getOtherCount = () => {
    return data.filter(({ type }) => type === RecipientType.Other).length;
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(getRecipients(updateEmail(e.target.value)));
  };

  const checkErrors = (e: ChangeEvent<HTMLInputElement>) => {
    const errs = validateEmailInput(e.target.value, false);
    if (errors !== errs) {
      onValidate(isEmpty(errs));
    }

    setErrors(errs);
  };

  return (
    <>
      <div className="flex">
        <EmailInput
          email={email}
          onChange={onInputChange}
          onBlur={checkErrors}
          errors={errors}
          isShort={isMobile && getOtherCount() > 1}
        />
        {getOtherCount() > 1 && original.type === RecipientType.Other && (
          <IconButton
            label=""
            icon={<IconTrash />}
            variant="ghost"
            size="medium"
            color={IsTheme(Theme.Ahs2024) ? 'interactive' : 'primary'}
            onClick={() => {
              dataCopy.splice(id, 1);
              onChange(getRecipients(dataCopy));
            }}
          />
        )}
      </div>
    </>
  );
};

export default SendRenewal;
