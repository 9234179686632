import React from 'react';
import { OrderHistory } from '@app/models';
import { DateFormat, formatDate } from '@helpers/utils';

import { Button, IconClockFull } from '@ftdr/blueprint-components-react';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

interface Props {
  onClickViewMore?: () => void;
  history: OrderHistory[];
}

const OrderEditHistoryTable: React.FC<Props> = (props) => {
  return (
    <div className={IsTheme(Theme.Ahs2024) ? "mt-4 p-4 bg-white rounded-tr-5 rounded-br-5 rounded-bl-5 rounded-tl-5" : "card mt-8 p-4"}>
      <REText className="h5 font-bold" variant="heading-06">
        <IconClockFull
          className="inline-block max-w-full align-middle -mt-1 mr-2"
          size={18}
          color={IsTheme(Theme.Ahs2024) ? "interactive" : "gray"}
          title="clock"
        ></IconClockFull>
        Edit History
      </REText>
      {props.history.map((item) => (
        <div key={item.data + item.dateTimeStamp} className="mt-2">
          <p className="mb-1 text-xs leading-tight">
            <REText as="strong" variant="label">
              {formatDate(item.dateTimeStamp, DateFormat.FULL)}
            </REText>{' '}
          </p>
          <REText className="mb-0 mt-0 text-xs leading-tight" variant="helper-text">
            {item.data}
          </REText>
        </div>
      ))}
      <div className="pt-4">
        <Button
          label="Show All History"
          variant="ghost"
          size="small"
          onClick={() => props.onClickViewMore()}
          width="full"
        />
      </div>
    </div>
  );
};

export default OrderEditHistoryTable;
