import React, { useEffect, useState } from 'react';

// Components
import Drawer, { DrawerProps } from '@components/drawer/Drawer';
import TableExtendListing from '@components/table/TableExtendListing';
import { Contract } from '@apis/models';
import { addressToString } from '@services/helpers';

import { fireGAEvent } from '@app/core/tracking.service';
import { ORDER__EXTEND_ORDER_CONTRACT } from '@constants/ga-events.constants';
import { Button } from '@ftdr/blueprint-components-react';
import { getTableExtendListingArray } from '@helpers/order.utils';
import msgs from '@app/locales/en';
import REText from '@components/wrappedBDS/REText';

interface Props extends DrawerProps {
  /** the contract that is being extended */
  contract: Contract;
  /** trigger when approving to extend listing */
  onApprove: (contract: Contract) => void;
}

const DrawerExtendListing: React.FC<Props> = ({ contract, ...props }) => {
  const header = 'Extend Listing';
  const confirmationMsg = 'Continue to approve the new dates provided below.';
  const invalidMsg = 'This listing cannot be extended';
  const confirmBtnLabel = 'Ok, Got It';

  const [isValid, setIsValid] = useState(false);
  const [address, setAddress] = useState(undefined);
  const [listingDatesArray, setListingDatesArray] = useState([]);
  const listingWithSellerCoverageAndServiceReq =
    contract?.detail.importantDates.sellersCoverageEffectiveDate &&
    contract?.features?.extendListing?.workOrderCount > 0;

  /** When contract is loaded, refresh the view with information needed */
  useEffect(() => {
    // Reset view
    setIsValid(false);
    setAddress(undefined);
    setListingDatesArray([]);

    // Load view if valid
    const isValid =
      contract &&
      contract.features &&
      contract.features.extendListing &&
      contract.features.extendListing.canExtend;

    if (isValid) {
      const { address1, address2, city, state, zip } = contract.summary.address;
      setAddress(addressToString(address1, address2, city, state, zip));
      setListingDatesArray(getTableExtendListingArray(contract));
      setIsValid(true);
    }
  }, [contract]);

  return (
    <Drawer {...props}>
      <h1 className="h3">{header}</h1>

      {!isValid ? (
        <p>{invalidMsg}</p>
      ) : listingWithSellerCoverageAndServiceReq ? (
        <REText variant="body-short" className="mt-3 text-lg">
          {msgs.EXTEND_ORDER_ERROR_DASHBOARD.messageHTML}
        </REText>
      ) : (
        <>
          <REText variant="heading-06">{address}</REText>
          <p>{confirmationMsg}</p>

          <div className="mt-4">
            <TableExtendListing rows={listingDatesArray} />
          </div>

          <div className="mt-6 -mx-4 md:-mx-8">
            <div className="md:flex md:flex-wrap md:flex-row-reverse md:justify-start md:items-center w-full px-4 md:px-8">
              <Button
                id="drawer-extend-listing--confirm"
                label={confirmBtnLabel}
                color="interactive"
                size="medium"
                onClick={() => {
                  fireGAEvent(ORDER__EXTEND_ORDER_CONTRACT(contract.detail.id));
                  props.onApprove(contract);
                }}
              />
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
};

export default DrawerExtendListing;
