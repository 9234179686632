import React, { useState } from 'react';

import { FilterOperation, FilterType, RealEstateStatus } from '@constants/dashboardFilters';
import {
  Accordion,
  AccordionSection,
  Button,
  IconPin,
  Popover,
  PopoverContent,
} from '@ftdr/blueprint-components-react';
import OrderStatusFilter, { StatusMenuType } from '@components/filter/OrderStatusFilter';
import OrderAgentFilter from '@components/filter/OrderAgentFilter';
import { UserRoleType } from '@constants/dictionaries';
import { formattedDate } from '@helpers/utils';
import ModalOfficesFilter from '@components/modal/ModalOfficesFilter';
import { FilterChangeEvent } from '@components/misc/misc.models';
import { Office } from '@apis/models';
import DateRangeSelectionModal from '@components/dateRange/DateRangeSelectionModal';
import { DateFilterButton } from '@components/button/DateFilterButton';
import REText from '@components/wrappedBDS/REText';

const ListFiltering = (props) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showOfficesModal, setShowOfficesModal] = useState(false);
  const dictStatusMenuType = {
    warrantyLink: 'WarrantyLink Status',
    quotes: 'Quote Status',
    orders: 'Order Status',
  };

  // TODO Consider moving this into the DateRange component directly
  const handleDateRangeFilter = (dateType: string, presetTitle: string, start, end) => {
    if (start && end && dateType) {
      const startDateStr = formattedDate(start);
      const endDateStr = formattedDate(end);

      const dateTypeLabel = {
        ESTCOE: 'Closing',
        ENTRYDATE: 'Entry',
      };

      props.addFilter(undefined, {
        operation: FilterOperation.ADD,
        type: FilterType.DATE,
        payload: {
          date: {
            start,
            end,
            type: dateType,
            presetTitle,
          },
        },
        label: `${props.statusMenuType === StatusMenuType.Quotes ? 'Created' : dateTypeLabel[dateType]} Date: ${startDateStr} - ${endDateStr}`,
      });
    }
  };

  const dateRenderer = () => {
    return <DateFilterButton id="list_date_filter" onClick={() => setShowDatePicker(true)} />;
  };

  const officesRenderer = () => {
    return (
      <Button
        onClick={() => setShowOfficesModal(true)}
        width="full"
        className="pt-2 pb-2 pl-0"
        size="small"
        variant="ghost"
        label="Offices"
        labelAlign="left"
        startIcon={<IconPin className="button-medium-icon ml-0-override mr-0-override" />}
      />
    );
  };

  const handleOfficesFilterApply = (officeList: Office[]) => {
    const filterApplyList = officeList.map((o) => createFilterChange(o, FilterOperation.ADD));
    props.applyFilters(filterApplyList);
  };

  const createFilterChange = (office: Office, operation: FilterOperation): FilterChangeEvent => {
    return {
      operation,
      type: FilterType.OFFICE,
      payload: { office },
      label: `Office: ${office.name}`,
    };
  };

  const isAgentDropdownDisabled =
    !props.activeFilters.some((filter) => filter.type === FilterType.OFFICE) ||
    !props.agentList.length;

  return (
    <>
      <Accordion mode="multiple">
        <AccordionSection label={dictStatusMenuType[props.statusMenuType]}>
          <OrderStatusFilter
            addFilter={props.addFilter}
            activeFilters={props.activeFilters.filter(
              (filter) => filter.type === FilterType.STATUS,
            )}
            filterAlerts={{
              ...props.statusAlerts,
              [RealEstateStatus.AWAITING_WL_SUBMISSION]: props.totalAwaitingWlkSubmission,
            }}
            roleIDType={props.roleIDType}
            statusMenuType={props.statusMenuType}
          />
        </AccordionSection>

        <AccordionSection label="Date Range" headerRenderer={dateRenderer} />

        {![UserRoleType.RealEstateAgent, UserRoleType.ClosingCompanyAgent].includes(
          props.userRoleIDType,
        ) && <AccordionSection label="Offices" headerRenderer={officesRenderer} />}

        {![UserRoleType.RealEstateAgent, UserRoleType.ClosingCompanyAgent].includes(
          props.userRoleIDType,
        ) &&
          (isAgentDropdownDisabled ? (
            <Popover
              placement="bottom"
              triggerInteraction={props.isMobile ? 'click' : 'hover'}
              content={(popoverContentProps) => (
                <PopoverContent {...popoverContentProps}>
                  <REText className="max-w-xs" color="gray" variant="caption">
                    To filter by Agent, you must have an office selected.
                  </REText>
                </PopoverContent>
              )}
              className="mx-auto w-full"
            >
              <AccordionSection label="Agent" disabled={isAgentDropdownDisabled} />
            </Popover>
          ) : (
            <AccordionSection label="Agent">
              <OrderAgentFilter
                addFilter={props.addFilter}
                activeFilters={props.activeFilters.filter(
                  (filter) => filter.type === FilterType.AGENT,
                )}
                agents={props.agentList}
              />
            </AccordionSection>
          ))}
      </Accordion>
      <DateRangeSelectionModal
        open={showDatePicker}
        initialValues={
          props.activeFilters.filter((filter) => filter.type === FilterType.DATE)[0]?.payload.date
        }
        statusMenuType={props.statusMenuType}
        onChange={handleDateRangeFilter}
        onClose={() => setShowDatePicker(false)}
      />
      <ModalOfficesFilter
        heading="Filter by Offices"
        isActive={showOfficesModal}
        onClose={() => setShowOfficesModal(false)}
        addFilter={props.addFilter}
        onApply={handleOfficesFilterApply}
        activeFilters={props.activeFilters.filter((filter) => filter.type === FilterType.OFFICE)}
        offices={props.officeList}
      />
    </>
  );
};

export default ListFiltering;
