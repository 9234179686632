import React, { useContext, useState } from 'react';
import Modal, { ModalProps } from '@components/modal/Modal';
import { Button } from '@ftdr/blueprint-components-react';
import AssociateAgent from '@components/modal/subcomponents/AssociateAgent';
import {
  ACTION_ADD_AS_COOPERATING_AGENT,
  ACTION_CONTACT_SUPPORT,
} from '@constants/newOrder-constants';
import Path from '@constants/paths';
import { useNavigate } from 'react-router-dom';
import { addressToString } from '@services/helpers';
import { ContractDetail } from '@apis/models';
import ContractApi from '@apis/contract.api';
import ProfileContext from '@context/ProfileContext';
import { openOrderDrawer } from '@app/core/GlobalDrawerOrder';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  ASSOCIATE_COOPERATING_AGENT,
  ORDERS_ADDMCA_ACTIVE_CONTRACT,
  ORDERS_ADDMCA_ALREADY_ASSIGNED,
  ORDERS_ADDMCA_CANCELLED_CONTRACT,
  ORDERS_ADDMCA_CANCELLED_ORDER,
  ORDERS_ADDMCA_NONE_ASSIGNED,
} from '@constants/ga-events.constants';

interface Props extends ModalProps {
  isActive: boolean;
  onClose: () => void;
  contract: ContractDetail;
}

const contactUsText = (contractID, propertyAddress) => {
  return `I have been notified that my contact details have been added for Cooperating agent details on this order ${contractID} for ${propertyAddress}. I need assistance with getting more details on this contract.`;
};

const headerText = (type) => {
  return `Your contact details have been listed under ${type} Agent details on this order. If you are the ${type} agent on this order, please select the first option below to associate yourself to the order.`;
};

const ModalAssociateAgent: React.FC<Props> = (props) => {
  const [radioSelected, setRadioSelected] = useState('');
  const navigate = useNavigate();
  const { profile } = useContext(ProfileContext);

  const onConfirm = () => {
    switch (radioSelected) {
      case ACTION_ADD_AS_COOPERATING_AGENT:
        handleAddAgent();
        break;
      case ACTION_CONTACT_SUPPORT:
        handleContactUs();
        break;
      default:
        console.error('Modal Associate Agent: Invalid radio option selected.');
        break;
    }
  };

  const handleAddAgent = async () => {
    // Call update contract endpoint
    await ContractApi.updateCooperatingAgentInfo(props.contract.id, {
      agentId: profile.roleID,
      officeId: profile.offices[0].id,
    });
    props.onClose();

    fireGAEvent(ASSOCIATE_COOPERATING_AGENT);

    // Open The Global Drawer
    openOrderDrawer({
      contractId: props.contract.id,
    });
  };

  const handleContactUs = () => {
    const contractID = props.contract?.id;
    const { property } = props.contract;
    const propertyAddress = addressToString(
      property.streetAddress,
      property.streetAddress2,
      property.city,
      property.state,
      property.zip,
    );

    if (props.contract?.contractStatus === 'A') {
      fireGAEvent(ORDERS_ADDMCA_ACTIVE_CONTRACT);
    } else if (props.contract?.contractStatus === 'C') {
      fireGAEvent(ORDERS_ADDMCA_CANCELLED_CONTRACT);
    } else if (props.contract?.contractStatus === 'X') {
      fireGAEvent(ORDERS_ADDMCA_CANCELLED_ORDER);
    } else if (!props.contract?.cooperatingOfficeAgent) {
      fireGAEvent(ORDERS_ADDMCA_NONE_ASSIGNED);
    } else if (props.contract?.cooperatingOfficeAgent) {
      fireGAEvent(ORDERS_ADDMCA_ALREADY_ASSIGNED);
    }

    navigate(Path.ContactUs, {
      state: {
        topic: 'Order Management',
        emailText: contactUsText(contractID, propertyAddress),
      },
      replace: true,
    });
  };

  const getHeading = () => {
    let type = '';

    if (props.contract?.initiatingOfficeAgent?.represents === 'SELLER') {
      type = 'buyer';
    } else {
      type = 'seller';
    }

    return headerText(type);
  };

  const actions = [
    <Button
      key="submit"
      id="modal-confirm"
      label="Continue"
      onClick={onConfirm}
      width="full"
      labelAlign="center"
      size="medium"
    />,
    <Button
      key="cancel"
      id="modal-cancel"
      variant="ghost"
      label="Cancel"
      onClick={props.onClose}
      width="full"
      labelAlign="center"
      size="medium"
    />,
  ];

  return (
    <Modal
      id="modal-associate-agent"
      isActive={props.isActive}
      actions={actions}
      onClose={props.onClose}
      heading={getHeading()}
    >
      {props.isActive && (
        <AssociateAgent
          {...props}
          setRadio={setRadioSelected}
          selectedRadio={radioSelected}
          contract={props.contract}
        />
      )}
    </Modal>
  );
};

export default ModalAssociateAgent;
