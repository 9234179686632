import React from 'react';
import { OptionalCoverageIconValue, ZestyWYSIWYGField } from '@apis/models/zesty.api.model';
import {
  IconCheckboxChecked,
  IconCheckboxUnchecked,
  IconCoolingLightBackground,
  IconDishwasherLightBackground,
  IconElectronicsLightBackground,
  IconGuestHouseLightBackground,
  IconHeatingLightBackground,
  IconHomeWarrantyLightBackground,
  IconItemV1Heating,
  IconItemV2Cooktop,
  IconItemV2Cooling,
  IconItemV2Dishwasher,
  IconItemV2Electronics,
  IconItemV2GuestUnit,
  IconItemV2Home,
  IconItemV2Microwave,
  IconItemV2Pool,
  IconItemV2RoofRepair,
  IconItemV2SaltwaterPool,
  IconItemV2SewageEjectorPump,
  IconItemV2TubMotor,
  IconItemV2WaterHeater,
  IconItemV2WellPump,
  IconItemV2WineFridge,
  IconMicrowaveLightBackground,
  IconOperatorMono,
  IconPoolSpaLightBackground,
  IconProps,
  IconRangeOvenLightBackground,
  IconRefrigeratorLightBackground,
  IconRoofCoverageLightBackground,
  IconSepticPumpLightBackground,
  IconShieldAssurancesLightBackground,
  IconShieldAssurancesMono,
  IconTubMotorLightBackground,
  IconWasherLightBackground,
  IconWaterHeaterLightBackground,
  IconWellPumpLightBackground,
} from '@ftdr/blueprint-components-react';
import { classNames } from '@utils';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

export interface OptionalCoverageItem {
  /** the cvg id */
  id: string;
  name: ZestyWYSIWYGField;
  subtext: ZestyWYSIWYGField;
  icon: string;
  price: number;
  isGroupCoverage?: boolean;
}

export interface PropsCardPlanPriceCoverage extends OptionalCoverageItem {
  selected?: boolean;
  onSelect: SelectCoverageHandler;
}

type SelectCoverageHandler = (item: OptionalCoverageItem) => void;

const CardPlanPriceCoverage: React.FC<PropsCardPlanPriceCoverage> = (props) => {
  const getIcon = () => {
    let CoverageIcon, CoverageIconV2;
    const iconProps: IconProps = {
      size: 96,
    };

    switch (props.icon?.toLowerCase()) {
      case OptionalCoverageIconValue.Electronics:
        CoverageIcon = IconElectronicsLightBackground;
        CoverageIconV2 = IconItemV2Electronics;
        break;
      case OptionalCoverageIconValue.GuestHouse:
        CoverageIcon = IconGuestHouseLightBackground;
        CoverageIconV2 = IconItemV2GuestUnit;
        break;
      case OptionalCoverageIconValue.HomeWarranty:
        CoverageIcon = IconHomeWarrantyLightBackground;
        CoverageIconV2 = IconItemV2Home;
        break;
      case OptionalCoverageIconValue.PoolSpa:
        CoverageIcon = IconPoolSpaLightBackground;
        CoverageIconV2 = IconItemV2Pool;
        break;
      case OptionalCoverageIconValue.SaltwaterPool:
        CoverageIcon = IconPoolSpaLightBackground;
        CoverageIconV2 = IconItemV2SaltwaterPool;
        break;
      case OptionalCoverageIconValue.Refrigerator:
        CoverageIcon = IconRefrigeratorLightBackground;
        CoverageIconV2 = IconItemV2WineFridge;
        break;
      case OptionalCoverageIconValue.RoofCoverage:
        CoverageIcon = IconRoofCoverageLightBackground;
        CoverageIconV2 = IconItemV2RoofRepair;
        break;
      case OptionalCoverageIconValue.SepticPump:
        CoverageIcon = IconSepticPumpLightBackground;
        CoverageIconV2 = IconItemV2SewageEjectorPump;
        break;
      case OptionalCoverageIconValue.WellPump:
        CoverageIcon = IconWellPumpLightBackground;
        CoverageIconV2 = IconItemV2WellPump;
        break;
      case OptionalCoverageIconValue.Cooling:
        CoverageIcon = IconCoolingLightBackground;
        CoverageIconV2 = IconItemV2Cooling;
        break;
      case OptionalCoverageIconValue.Dishwasher:
        CoverageIcon = IconDishwasherLightBackground;
        CoverageIconV2 = IconItemV2Dishwasher;
        break;
      case OptionalCoverageIconValue.Heating:
        CoverageIcon = IconHeatingLightBackground;
        CoverageIconV2 = IconItemV1Heating;
        break;
      case OptionalCoverageIconValue.Microwave:
        CoverageIcon = IconMicrowaveLightBackground;
        CoverageIconV2 = IconItemV2Microwave;
        break;
      case OptionalCoverageIconValue.RangeOven:
        CoverageIcon = IconRangeOvenLightBackground;
        CoverageIconV2 = IconItemV2Cooktop;
        break;
      case OptionalCoverageIconValue.ShieldAssurances:
        CoverageIcon = IconShieldAssurancesLightBackground;
        CoverageIconV2 = IconShieldAssurancesMono;
        break;
      case OptionalCoverageIconValue.TubMotor:
        CoverageIcon = IconTubMotorLightBackground;
        CoverageIconV2 = IconTubMotorLightBackground;
        break;
      case OptionalCoverageIconValue.Washer:
        CoverageIcon = IconWasherLightBackground;
        CoverageIconV2 = IconItemV2TubMotor;
        break;
      case OptionalCoverageIconValue.WaterHeater:
        CoverageIcon = IconWaterHeaterLightBackground;
        CoverageIconV2 = IconItemV2WaterHeater;
        break;
      default:
        CoverageIcon = IconOperatorMono;
        CoverageIconV2 = IconOperatorMono;
        iconProps.color = 'gray';
        break;
    }

    if (IsTheme(Theme.Ahs2024)) {
      iconProps.color = 'primary';
      iconProps.size = '64';
      return <CoverageIconV2 {...iconProps} />;
    }
    return <CoverageIcon {...iconProps} />;
  };

  const getCheckbox = () => {
    const CheckboxIcon = props.selected ? IconCheckboxChecked : IconCheckboxUnchecked;
    return <CheckboxIcon color={'interactive'} />;
  };

  return (
    <div
      id={props.id}
      className={classNames([
        'card-plan-price--coverage bg-white p-2 md:p-6 shadow-2 rounded',
        `${IsTheme(Theme.Ahs2024) ? '' : 'border-3'}`,
        props.selected ? 'border-interactive' : 'border-white',
      ])}
      onClick={() => props.onSelect(props)}
    >
      <div className="flex flex-row space-x-4">
        <div id={`${props.id}--checkbox`} className="flex items-center">
          {getCheckbox()}
        </div>
        <div id={`${props.id}--icon`} className="hidden md:flex items-center -my-6">
          {getIcon()}
        </div>
        <div className="flex flex-col flex-1 md:space-y-1">
          <REText
            id={`${props.id}--heading`}
            color="primary"
            className="my-0 zesty-html"
            dangerouslySetInnerHTML={{ __html: props.name }}
          />
          <REText
            id={`${props.id}--heading--caption`}
            className="card-plan-price--coverage--caption my-0 zesty-html"
            dangerouslySetInnerHTML={{ __html: props.subtext }}
          />
        </div>
        <REText id={`${props.id}--price`} className={`${IsTheme(Theme.Ahs2024) ? 'm-auto' : ''} font-bold`}>
          ${props.price}
        </REText>
      </div>
    </div>
  );
};

export default CardPlanPriceCoverage;
