import React from 'react';
import { classNames } from '@utils';
import REText from '@components/wrappedBDS/REText';

interface NewOrderFormContainerProps {
  id: string;
  heading?: string;
}

interface NewOrderFormRowProps {
  id?: string;
  className?: string;
}

interface NewOrderFormRowItemProps {
  width?: 'full' | 'initial';
  className?: string;
}

const OrderFormContainer: React.FC<NewOrderFormContainerProps> = (props) => {
  return (
    <div id={props.id} className="OrderForm_Container">
      {props.heading && (
        <REText variant="heading-06" className="OrderForm_Container_Heading">
          {props.heading}
        </REText>
      )}
      <div className="OrderForm_Container_Content">{props.children}</div>
    </div>
  );
};

const OrderFormRow: React.FC<NewOrderFormRowProps> = (props) => {
  return (
    <div id={props.id} className={classNames(['OrderForm_Row md:space-x-4', props.className])}>
      {props.children}
    </div>
  );
};

const OrderFormRowItem: React.FC<NewOrderFormRowItemProps> = (props) => {
  return (
    <div
      className={classNames([
        'OrderForm_RowItem',
        props.width === 'full' && 'flex-1 w-full',
        props.className,
      ])}
    >
      {props.children}
    </div>
  );
};

OrderFormRowItem.defaultProps = {
  width: 'full',
};

const OrderForm = {
  Container: OrderFormContainer,
  Row: OrderFormRow,
  RowItem: OrderFormRowItem,
};

export default OrderForm;
