import React, { useEffect, useRef, useState } from 'react';
import { classNames } from '@utils';
import { useWindowSize } from 'react-use';
import isEmpty from 'lodash/isEmpty';
import CardNewOrder from '@components/card/CardNewOrder';
import {
  convertCheckboxGroupOnChangeToOnClick,
  convertOptionsObjToCheckboxGroupOptions,
  convertValuesToCheckboxGroupValue,
} from '@components/form/FormCheckbox';
import {
  allDisallowedEmailsErrors,
  allDisallowedPhoneErrors,
  containsNoErrors,
  isAllFieldFormatsValid,
  isAllRequiredValuesPresent,
  validateBuyerForm,
  validateName,
} from '@services/validation/ValidationRules';
import useForm from '@helpers/UseForm';
import * as c from '@constants/formField-constants';
import DrawerOfficeSearch from '@components/drawer/DrawerOfficeSearch';
import { deleteUndefinedValues, formatPhoneNumber } from '@app/helpers/utils';
import AgentApi from '@apis/agent.api';
import { selectSuggestion } from '@services/autosuggest/autosuggest';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import AgentAutosuggest from '../form/AgentAutosuggest';
import FormNewOrderCustomer from '@components/form/FormNewOrderCustomer';
import FormNewOrderCustomerAddress from '@components/form/FormNewOrderCustomerAddress';
import { CheckboxGroup, Input, Panel, RadioGroup } from '@ftdr/blueprint-components-react';
import { ORDER_FORM_FIELDS, ORDER_VIEW_LABELS } from '@constants/newOrder-constants';
import {
  convertOptionsObjToRadioGroupOptions,
  convertRadioGroupOnChangeToOnClick,
  convertValuesToRadioGroupValue,
  getFormInputErrorId,
  getFormRadioErrorId,
} from '@storybook/addon-links';
import AddressVerification from '@components/misc/AddressVerification';
import CustomCooperatingAgent from '@components/form/CustomCooperatingAgent';
import {
  ADDED_EXISTING_COOP_AGENT,
  ADD_BUYER_PHONE_NUMBER,
  ADD_BUYER_PHONE_NUMBER_LATER,
  CREATED_CUSTOM_AGENT,
  MISSING_BUYER_PHONE_POPUP,
} from '@constants/ga-events.constants';
import { useOptimizely } from '@context/OptimizelyContext';
import ModalMissingBuyerPhone from '@components/modal/ModalMissingBuyerPhone';
import { fireGAEvent } from '@app/core/tracking.service';
import { ModalAgentVerification } from '@components/modal/ModalAgentVerification';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

/** enum key used for referencing
 * temporary simulated enum until this file is typescript. */
const CustomerKey = {
  Customer: 'customer',
  CoCustomer: 'coCustomer',
};

const defaultBuyerInfo = { yes: false, no: false };
/* 
    Notes for props when we convert to typescript due to certain props not being clear what they are.

    alertText: if included, displays an alert text in the card
    values: the data supplied to this component for display. Is renamed to 'savedFormValues' for clarity within the child that it is meant to be the 'saved data' for resets.
    isRequired: if set to true, we should default the radio selection to true and not display it
    showCooperatingFields: if set to true, display the cooperating office and agent fields
    userDetails: user profile
    agentInfo: agent card

 */
let foundCustomCooperatingAgent = null;
const CardNewOrderBuyerInfo = ({
  values: savedFormValues = {
    buyerInfo: defaultBuyerInfo,
  },
  isEditing,
  setIsEditing,
  ...props
}) => {
  const { addErrorToQueue } = useGlobalAlert();

  const { values, errors, handleBlur, handleSubmit, setValues, setErrors, setTouched } = useForm(
    validateBuyerForm(
      props.isRequired,
      props.isCustomerContactInfoRequired,
      props.userDetails,
      { email: props.agentInfo?.AgentEmail, phone: props.agentInfo?.AgentPhone?.phoneNumber },
      props.agentInfo?.isRealtorOwned,
    ),
    savedFormValues,
  );

  const initialCustomCooperatingAgent = {
    firstName: '',
    lastName: '',
    email: '',
    office: null,
    cooperatingOffice: '',
    hasValue: false,
  };
  const unsetCustomCooperatingAgent = {
    AgentName: '',
    AgentEmail: '',
    AgentPhone: '',
    AgentId: '',
  };

  const [scratchFormValues, setScratchFormValues] = [values, setValues];

  const { width } = useWindowSize();
  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed || true);
  const [isDrawerActive, setIsDrawerActive] = useState(false);
  const [showBuyerInfoFields, setShowBuyerInfoFields] = useState(!!props.isRequired);
  const [showAlternateAddressFields, setShowAlternateAddressFields] = useState(false);
  const [showCoBuyerAlternateAddressFields, setShowCoBuyerAlternateAddressFields] = useState(false);
  const [showCoBuyerFields, setShowCoBuyerFields] = useState(false);
  const alternateAddressFields = useRef(null);
  const coBuyerFields = useRef(null);
  const coBuyerAlternateAddressFields = useRef(null);
  const buyerInfoFields = useRef(null);
  const [doesZipExist, setDoesZipExist] = useState(true);
  const [doesCoCustomerZipExist, setDoesCoCustomerZipExist] = useState(true);

  const [typeAheadAddress, setTypeAheadAddress] = useState([]);
  const [selectedAgent, changeSelectedAgent] = useState(savedFormValues?.cooperatingAgent || '');
  const [agentHelpText, setAgentHelpText] = useState('');
  const [isAddAgentModalActive, setIsAddAgentModalActive] = useState(false);
  const [isAgentVerificationActive, setIsAgentVerificationActive] = useState(false);

  const [buyerFirstNameError, setBuyerFirstNameError] = useState('');
  const [buyerLastNameError, setBuyerLastNameError] = useState('');
  const [cobuyerFirstNameError, setCobuyerFirstNameError] = useState('');
  const [cobuyerLastNameError, setCobuyerLastNameError] = useState('');

  const [addressVerificationItems, setAddressVerificationItems] = useState([]);
  const [enableAddressVerification, setEnableAddressVerification] = useState(false);

  const [cooperatingAgentCustom, setCooperatingAgentCustom] = useState({
    ...initialCustomCooperatingAgent,
  });
  const [cooperatingAgentErrors, setCooperatingAgentErrors] = useState({
    ...initialCustomCooperatingAgent,
  });
  const [previousCustomCooperatingAgent, setPreviousCustomCooperatingAgent] = useState({
    ...initialCustomCooperatingAgent,
    cooperatingOffice: '',
  });
  const [previousCooperatingAgent, setPreviousCooperatingAgent] = useState({
    selectedAgent: savedFormValues?.cooperatingAgent || '',
    selectedAgentId: values.AgentId || '',
    cooperatingOffice: '',
  });
  const [customCooperatingAgentForEditOrder, setCustomCooperatingAgentForEditOrder] = useState({
    ...initialCustomCooperatingAgent,
    cooperatingOffice: '',
    office: {},
  });
  // Buyer Phone ABTest Modal
  const [enableMissingBuyerPhoneModal, setEnableMissingBuyerPhoneModal] = useState(false);
  const optimizely = useOptimizely();
  const [missingBuyerPhoneABTest, setMissingBuyerPhoneABTest] = useState(
    optimizely.variations.showpopupforbuyerphone
      ? optimizely.variations.showpopupforbuyerphone
      : false,
  );
  const buyerPhoneRef = useRef(null);

  useEffect(() => {
    setMissingBuyerPhoneABTest(
      optimizely.variations.showpopupforbuyerphone
        ? optimizely.variations.showpopupforbuyerphone
        : false,
    );
  }, [optimizely.variations]);

  const customCooperatingAgentErrorMessage = {
    firstName: 'First Name required',
    lastName: 'Last Name required',
    email: 'Email required',
  };
  const customerCheckboxLabels = {
    alternateAddressFields: 'Use alternate mailing address',
    coBuyerFields: 'Include Co-buyer',
  };
  const [customerCheckboxValues, setCustomerCheckboxValues] = useState({
    alternateAddressFields: savedFormValues?.alternateAddressFields || false,
    coBuyerFields: savedFormValues?.coBuyerFields || false,
  });

  const customerRadioLabel = {
    hasBuyerInfo: 'Yes',
    noBuyerInfo: 'No',
  };
  const [customerRadioValues, setCustomerRadioValues] = useState({
    hasBuyerInfo: props.isRequired,
    noBuyerInfo: false,
  });

  const coCustomerCheckboxLabels = {
    coBuyerAlternateAddressFields: 'Add Alternate Mailing Address',
  };
  const [coCustomerCheckboxValues, setCoCustomerCheckboxValues] = useState({
    coBuyerAlternateAddressFields: savedFormValues?.coBuyerAlternateAddressFields || false,
  });

  /** used for FormNewOrderCustomer.ids and FormNewOrderCustomerAddress.ids
   * The ids of the form fields */
  const formNewOrderCustomerPropIds = {
    customer: {
      firstName: 'wb_buyerFirstName',
      lastName: 'wb_buyerLastName',
      email: 'wb_buyerEmail',
      phone: 'wb_buyerPhone',
    },
    customerAddress: {
      address: 'wb_streetAddressBuyer',
      unit: 'wb_unitBuyer',
      city: 'wb_cityBuyer',
      state: 'wb_stateBuyer',
      zip: 'buyer-info-alternate-address-zip-code-input',
    },
    coCustomer: {
      firstName: 'wb_coBuyerFirstName',
      lastName: 'wb_coBuyerLastName',
      email: 'wb_coBuyerEmail',
      phone: 'wb_coBuyerPhone',
    },
    coCustomerAddress: {
      address: 'wb_streetAddressCoBuyer',
      unit: 'wb_unitCoBuyer',
      city: 'wb_cityCoBuyer',
      state: 'wb_stateCoBuyer',
      zip: 'wb_zipCodeBuyer',
    },
  };

  /** used for FormNewOrderCustomer.names and FormNewOrderCustomerAddress.names
   * The names of the form fields.  These names are inevitably used in the useForm logic */
  const formNewOrderCustomerPropNames = {
    customer: {
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      phone: 'phone',
    },
    customerAddress: {
      address: 'streetAddress',
      unit: 'unit',
      city: 'city',
      state: 'state',
      zip: 'zipCode',
    },
    coCustomer: {
      firstName: 'coCustomerFirstName',
      lastName: 'coCustomerLastName',
      email: 'coCustomerEmail',
      phone: 'coCustomerPhone',
    },
    coCustomerAddress: {
      address: 'coCustomerStreetAddress',
      unit: 'coCustomerUnit',
      city: 'coCustomerCity',
      state: 'coCustomerState',
      zip: 'coCustomerZipCode',
    },
  };

  // Show/hide Buyer info fields
  const toggleBuyerInfoFields = (e) => {
    setCustomerRadioValues({
      hasBuyerInfo: false,
      noBuyerInfo: false,
      [e.target.name]: e.target.checked,
    });

    if (e.target.name === 'hasBuyerInfo') {
      setScratchFormValues({ ...scratchFormValues, buyerInfo: { yes: true, no: false } });
    } else {
      setScratchFormValues({
        ...scratchFormValues,
        buyerInfo: { yes: false, no: true },
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        alternateAddressFields: false,
        coBuyerFields: false,
      });
      setErrors({});
      setCustomerCheckboxValues({ alternateAddressFields: false, coBuyerFields: false });
      setShowAlternateAddressFields(false);
      setShowCoBuyerFields(false);
      setShowCoBuyerAlternateAddressFields(false);
      setCoCustomerCheckboxValues({ coBuyerAlternateAddressFields: false });
    }
  };

  // Show/hide alternate address and co-buyer fields
  const toggleCheckboxFields = (e) => {
    if (e.target.name === 'alternateAddressFields') {
      setShowAlternateAddressFields(!showAlternateAddressFields);
      if (!e.target.checked) {
        setScratchFormValues({
          ...scratchFormValues,
          zipCode: '',
          streetAddress: '',
          unit: '',
          city: '',
          state: '',
          [e.target.name]: e.target.checked,
        });
      } else {
        setScratchFormValues({ ...scratchFormValues, [e.target.name]: e.target.checked });
      }
    } else {
      setShowCoBuyerFields(!showCoBuyerFields);
      if (!e.target.checked) {
        setScratchFormValues({
          ...scratchFormValues,
          coCustomerFirstName: '',
          coCustomerLastName: '',
          coCustomerEmail: '',
          coCustomerPhone: '',
          coCustomerZipCode: '',
          coCustomerStreetAddress: '',
          coCustomerUnit: '',
          coCustomerCity: '',
          coCustomerState: '',
          coBuyerAlternateAddressFields: false,
          [e.target.name]: e.target.checked,
        });
      } else {
        setScratchFormValues({ ...scratchFormValues, [e.target.name]: e.target.checked });
      }
    }
    setCustomerCheckboxValues({ ...customerCheckboxValues, [e.target.name]: e.target.checked });
  };

  // Show/hide co-buyer alternate address fields
  const toggleCoBuyerCheckboxFields = (e) => {
    if (!e.target.checked) {
      setScratchFormValues({
        ...scratchFormValues,
        coCustomerZipCode: '',
        coCustomerStreetAddress: '',
        coCustomerUnit: '',
        coCustomerCity: '',
        coCustomerState: '',
        [e.target.name]: e.target.checked,
      });
    } else {
      setScratchFormValues({ ...scratchFormValues, [e.target.name]: e.target.checked });
    }
    setShowCoBuyerAlternateAddressFields(!showCoBuyerAlternateAddressFields);
    setCoCustomerCheckboxValues({ ...coCustomerCheckboxValues, [e.target.name]: e.target.checked });
  };

  // Scroll to alternate address fields
  useEffect(() => {
    if (showAlternateAddressFields && alternateAddressFields.current !== null) {
      alternateAddressFields.current.scrollIntoView({
        block: width >= 768 ? 'center' : 'start',
        behavior: 'smooth',
      });
    }
  }, [showAlternateAddressFields]);

  // Scroll to Buyer info fields
  useEffect(() => {
    if (showBuyerInfoFields && buyerInfoFields.current !== null) {
      buyerInfoFields.current.scrollIntoView({
        block: width >= 768 ? 'center' : 'start',
        behavior: 'smooth',
      });
    }
  }, [showBuyerInfoFields]);

  // Scroll to co-buyer fields
  useEffect(() => {
    if (showCoBuyerFields && coBuyerFields.current !== null) {
      coBuyerFields.current.scrollIntoView({
        block: width >= 768 ? 'center' : 'start',
        behavior: 'smooth',
      });
    }
  }, [showCoBuyerFields]);

  // scroll to co-buyer alternate address fields.
  useEffect(() => {
    if (showCoBuyerAlternateAddressFields && coBuyerAlternateAddressFields.current !== null) {
      coBuyerAlternateAddressFields.current.scrollIntoView({
        block: width >= 768 ? 'center' : 'start',
        behavior: 'smooth',
      });
    }
  }, [showCoBuyerAlternateAddressFields]);

  /** When the agent represents get changed, we should update our view if necessary */
  useEffect(() => {
    const isCustomerInfoOptional = !props.isRequired;
    const hasCustomerInfo = Boolean(savedFormValues?.firstName); // Just check one of the fields...

    console.log(
      'customer info is optional',
      isCustomerInfoOptional,
      'has customer info',
      hasCustomerInfo,
    );

    // If customer info is required, we should mark it with 'yes' immediately
    if (!isCustomerInfoOptional) {
      setCustomerRadioValues({
        hasBuyerInfo: true,
        noBuyerInfo: false,
      });
    }

    if (props.isExistingContract && isCustomerInfoOptional && !hasCustomerInfo) {
      setCustomerRadioValues({
        hasBuyerInfo: false,
        noBuyerInfo: true,
      });
    }

    // If customer info is optional, and we have customer info, default to 'yes'
    if (isCustomerInfoOptional && hasCustomerInfo) {
      setCustomerRadioValues({
        hasBuyerInfo: true,
        noBuyerInfo: false,
      });
    }
  }, [props.isRequired]);

  /** Update the show buyer info fields boolean when selection changes */
  useEffect(() => {
    setShowBuyerInfoFields(customerRadioValues.hasBuyerInfo);
  }, [customerRadioValues]);

  useEffect(() => {
    if (isEmpty(savedFormValues)) return;

    if (isEditing) {
      // Applicable only in Edit Order flow (custom cooperating agent flow)
      // user has already selected and saved custom cooperating agent. Now, if the user again tried to edit this component then set the scratchForValues.
      // In custom cooperating agent flow, scratchFormValues will not have any values as the contract does not save custom cooperating agent details.
      if (props.flow == 'Edit Order' && customCooperatingAgentForEditOrder.hasValue) {
        const editCustomCoopertingValues = {
          ...savedFormValues,
          office: customCooperatingAgentForEditOrder.office,
          cooperatingOffice: customCooperatingAgentForEditOrder.cooperatingOffice,
          AgentName: `${customCooperatingAgentForEditOrder.firstName} ${customCooperatingAgentForEditOrder.lastName}`,
          AgentEmail: customCooperatingAgentForEditOrder.email,
        };
        setScratchFormValues({ ...editCustomCoopertingValues });
      } else {
        setScratchFormValues(savedFormValues); // reset so previous scratch values aren't carried over
      }

      setCustomerCheckboxValues({
        ...customerCheckboxValues,
        coBuyerFields: savedFormValues.coBuyerFields,
        alternateAddressFields: savedFormValues.alternateAddressFields,
      });

      setShowAlternateAddressFields(savedFormValues.alternateAddressFields);
      setShowCoBuyerFields(savedFormValues.coBuyerFields);
      setCoCustomerCheckboxValues({
        coBuyerAlternateAddressFields: savedFormValues.coBuyerAlternateAddressFields,
      });
      setShowCoBuyerAlternateAddressFields(savedFormValues.coBuyerAlternateAddressFields);
      setErrors({});
    }
  }, [isEditing]);

  useEffect(() => {
    if (errors.email === c.EMAIL_REQUIRED) {
      setErrors({ ...errors, email: '' });
    }
    if (errors.phone === c.PHONE_NUMBER_REQUIRED) {
      setErrors({ ...errors, phone: '' });
    }

    if (props.isRequired) {
      if (
        errors.email === c.PHONE_OR_EMAIL_REQUIRED &&
        errors.phone === c.PHONE_OR_EMAIL_REQUIRED &&
        !values.needEmailOrPhone
      ) {
        // if agent represents seller, dont require phone or email
        setErrors({ ...errors, email: undefined, phone: undefined });
      }

      if (
        errors.email === c.PHONE_OR_EMAIL_REQUIRED &&
        errors.phone === c.PHONE_OR_EMAIL_REQUIRED
      ) {
        // dont want to display error message but highlight the phone field.
        setErrors({ ...errors, email: '', phone: c.PHONE_OR_EMAIL_REQUIRED });
      }

      // this deletes an error for some reason. i dont know why, it's probably something with the clear info or cancel logic.
      // if there is an error message on the phone field and the card can still be saved even with that error message, it's probably this here......
      if (
        scratchFormValues.email &&
        errors.phone !== c.INVALID_PHONE_NUMBER &&
        !allDisallowedPhoneErrors.includes(errors.phone)
      ) {
        delete errors.phone;
      }

      // this deletes an error for some reason. i dont know why, it's probably something with the clear info or cancel logic.
      // if there is an error message on the email field and the card can still be saved even with that error message, it's probably this here......
      if (
        scratchFormValues.phone &&
        errors.email !== c.INVALID_EMAIL &&
        !allDisallowedEmailsErrors.includes(errors.email)
      ) {
        delete errors.email;
      }

      if (scratchFormValues.coCustomerEmail && errors.coCustomerPhone !== c.INVALID_PHONE_NUMBER) {
        delete errors.coCustomerPhone;
      }

      if (scratchFormValues.coCustomerPhone && errors.coCustomerEmail !== c.INVALID_EMAIL) {
        delete errors.coCustomerEmail;
      }
      if (Object.keys(errors).length) {
        // setIsCollapsed(false)
        // setIsEditing(true)
      }
    }
  }, [errors]);

  useEffect(() => {
    if (selectedAgent) {
      setScratchFormValues({ ...scratchFormValues, cooperatingAgent: selectedAgent });
    }
    // quick fix to update displayed cooperating agent if empty when editing
    else if (
      !(Object.keys(scratchFormValues).length === 0 && scratchFormValues.constructor === Object)
    ) {
      setScratchFormValues({ ...scratchFormValues, cooperatingAgent: '' });
    }
  }, [selectedAgent]);

  /** transform the data from the card to what is needed for the parent.
   * prior to that, validate the address during submission. */
  const submit = async (skipAddressVerification = false) => {
    // check address validation necessary
    if (!skipAddressVerification) {
      const addressVerificationNeeded = performAddressVerification();
      if (addressVerificationNeeded) {
        setIsEditing(true);
        return;
      }
    }

    // Update the buyerInfo boolean
    const { hasBuyerInfo, noBuyerInfo } = customerRadioValues;
    let savedData = {
      ...scratchFormValues,
      buyerInfo: { yes: hasBuyerInfo, no: noBuyerInfo },
    };

    // send a GA event if the agent selected already exists
    if (foundCustomCooperatingAgent || !!selectedAgent) {
      fireGAEvent(ADDED_EXISTING_COOP_AGENT(props.flow));
    } else if (cooperatingAgentCustom.firstName) {
      fireGAEvent(CREATED_CUSTOM_AGENT(props.flow));
    }

    if (foundCustomCooperatingAgent) {
      const agentName = `${foundCustomCooperatingAgent?.firstName?.trim()} ${foundCustomCooperatingAgent?.lastName?.trim()}`;

      savedData = {
        ...savedData,
        AgentName: agentName,
        AgentEmail: foundCustomCooperatingAgent.email,
        AgentPhone:
          foundCustomCooperatingAgent.phoneNumbers.length > 0
            ? foundCustomCooperatingAgent.phoneNumbers[0]
            : '',
        AgentId: foundCustomCooperatingAgent.realEstateAgentID,
        cooperatingAgent: agentName,
      };
    }

    // address verification modal calls submit function directly. At that time the cooperatingAgentCustom object is not set. Dont use cooperatingAgentCustom in this flow
    // dont save agent/office details for custom coopertating agent flow on save and continue button click. It will be saved on submit click
    if (
      !foundCustomCooperatingAgent &&
      !scratchFormValues.AgentId &&
      cooperatingAgentCustom.firstName &&
      cooperatingAgentCustom.lastName &&
      cooperatingAgentCustom.email
    ) {
      const cooperatingAgentName = `${cooperatingAgentCustom?.firstName?.trim()} ${cooperatingAgentCustom?.lastName?.trim()}`;
      savedData = {
        ...savedData,
        cooperatingAgent: cooperatingAgentName?.trim(),
        office: { id: '', type: '' },
        ...unsetCustomCooperatingAgent,
      };

      // for display view in Edit-Order flow
      setCustomCooperatingAgentForEditOrder({
        ...cooperatingAgentCustom,
        cooperatingOffice: scratchFormValues.cooperatingOffice,
        office: { ...scratchFormValues.office, name: scratchFormValues.cooperatingOffice },
        agentlist: null,
        hasValue: true,
      });
    } else {
      // clear our edit data. This will be fetched from contract
      setCustomCooperatingAgentForEditOrder({
        ...initialCustomCooperatingAgent,
        cooperatingOffice: '',
        office: {},
        hasValue: false,
      });
    }

    const success = await props.handleSubmit(savedData);
    clearPreviousCooperatingAgents();
  };

  const hasCustomCooperatingAgentErrors = () =>
    cooperatingAgentErrors.firstName ||
    cooperatingAgentErrors.lastName ||
    cooperatingAgentErrors.email;
  const validateAndAssignCustomCooperatingAgent = (initiatingAndCooperatingAgentMatchParam) => {
    let isValid = true;
    let firstNameError = '';
    let lastNameError = '';
    let emailError = '';

    if (hasCustomCooperatingAgentErrors()) {
      isValid = false;
      return isValid;
    }
    if (!cooperatingAgentCustom.firstName) {
      firstNameError = customCooperatingAgentErrorMessage.firstName;
    }
    if (!cooperatingAgentCustom.lastName) {
      lastNameError = customCooperatingAgentErrorMessage.lastName;
    }
    if (!cooperatingAgentCustom.email) {
      emailError = customCooperatingAgentErrorMessage.email;
    }

    if (firstNameError || lastNameError || emailError) {
      setCooperatingAgentErrors({
        firstName: firstNameError,
        lastName: lastNameError,
        email: emailError,
      });
      isValid = false;
      return isValid;
    }

    // save agent and office details
    setCooperatingAgentCustom({
      ...cooperatingAgentCustom,
      office: scratchFormValues.office,
      cooperatingOffice: scratchFormValues.cooperatingOffice,
      agentlist: null,
      hasValue: true,
    });

    // clear previously selected agent
    setScratchFormValues({ ...scratchFormValues, AgentId: '', AgentName: '', AgentEmail: '' });
    changeSelectedAgent('');

    // check if there is an existing agent with the inputted firstName, lastName and email. If so, use the existing agent
    foundCustomCooperatingAgent = scratchFormValues.agentlist?.find(
      (a) =>
        a?.firstName?.trim().toUpperCase() ==
          cooperatingAgentCustom?.firstName?.trim().toUpperCase() &&
        a?.lastName?.trim().toUpperCase() ==
          cooperatingAgentCustom?.lastName?.trim().toUpperCase() &&
        a?.email?.trim().toUpperCase() == cooperatingAgentCustom?.email?.trim().toUpperCase(),
    );

    // exact match case
    if (foundCustomCooperatingAgent) {
      // check if the found custom cooperating agent matches selected initiating agent
      const initiatingAndCooperatingAgentMatch =
        props.initiatingAgentId === foundCustomCooperatingAgent?.realEstateAgentID &&
        foundCustomCooperatingAgent?.realEstateAgentID;
      if (initiatingAndCooperatingAgentMatch) {
        // If initiating and cooperating agent matches, then display error later
        if (initiatingAndCooperatingAgentMatch) {
          changeSelectedAgent('');
          initiatingAndCooperatingAgentMatchParam.key = true;
          return isValid;
        }
      } else {
        setIsAddAgentModalActive(!isAddAgentModalActive);
        setCooperatingAgentCustom({ ...initialCustomCooperatingAgent });
      }

      const agentName = `${foundCustomCooperatingAgent?.firstName?.trim()} ${foundCustomCooperatingAgent?.lastName?.trim()}`;
      changeSelectedAgent(agentName);

      // set scratch form values (mimicing existing agent selection steps)
      const newValues = {
        ...scratchFormValues,
        AgentName: agentName,
        AgentEmail: foundCustomCooperatingAgent.email,
        AgentPhone:
          foundCustomCooperatingAgent.phoneNumbers.length > 0
            ? foundCustomCooperatingAgent.phoneNumbers[0]
            : '',
        AgentId: foundCustomCooperatingAgent.realEstateAgentID,
      };

      setScratchFormValues(newValues);
      changeSelectedAgent(agentName);

      // clear NewOrderTemplate data.
      if (props.onPersistCustomCooporatingAgent) {
        props.onPersistCustomCooporatingAgent({ ...unsetCustomCooperatingAgent, hasValue: false });
      }
    } else {
      // persist custom coorporating agent details in NewOrderTemplate. This will be saved to server on submit
      if (props.onPersistCustomCooporatingAgent) {
        props.onPersistCustomCooporatingAgent({
          agentFirstName: cooperatingAgentCustom.firstName,
          agentLastName: cooperatingAgentCustom.lastName,
          agentEmail: cooperatingAgentCustom.email,
          hasValue: true,
        });
      }
    }

    return isValid;
  };

  const clearPreviousCooperatingAgents = () => {
    setPreviousCustomCooperatingAgent({ ...initialCustomCooperatingAgent });
    setPreviousCooperatingAgent({ selectedAgent: '', selectedAgentId: '' });
    foundCustomCooperatingAgent = null;
  };

  const handleSave = (event) => {
    handleSubmit(event);

    const hasCustomerName =
      scratchFormValues?.firstName?.trim() && scratchFormValues?.lastName?.trim();
    const hasCooperatingOffice = !!scratchFormValues?.cooperatingOffice?.trim();
    let hasCooperatingAgent = !!scratchFormValues?.cooperatingAgent?.trim();
    let initiatingAndCooperatingAgentMatch =
      props.initiatingAgentId === scratchFormValues?.AgentId && scratchFormValues?.AgentId;

    if (
      props.showCooperatingFields &&
      !selectedAgent?.trim() &&
      hasCooperatingOffice &&
      isAddAgentModalActive
    ) {
      // sending object cause this need to pass this by ref
      const initiatingAndCooperatingAgentMatchParam = { key: initiatingAndCooperatingAgentMatch };
      if (validateAndAssignCustomCooperatingAgent(initiatingAndCooperatingAgentMatchParam)) {
        hasCooperatingAgent = true;
        initiatingAndCooperatingAgentMatch = initiatingAndCooperatingAgentMatchParam.key;
      } else {
        return;
      }
    } else {
      // clear out OrderNewTemplate custom cooperating agent details
      if (props.onPersistCustomCooporatingAgent) {
        props.onPersistCustomCooporatingAgent({ ...unsetCustomCooperatingAgent, hasValue: false });
      }
    }

    // in edit order flow, set the parent component's cooperatingAgentCustom
    if (props.cooperatingAgentCustom) {
      props.cooperatingAgentCustom.firstName = cooperatingAgentCustom.firstName;
      props.cooperatingAgentCustom.lastName = cooperatingAgentCustom.lastName;
      props.cooperatingAgentCustom.email = cooperatingAgentCustom.email;
    }

    const cooperatingInfoValid = hasCooperatingAgent === hasCooperatingOffice; // if both filled or both not filled
    const { hasBuyerInfo, noBuyerInfo } = customerRadioValues;

    const allNameFiledsValid = validateAllNameFields();

    // TODO: most of the logic below should be handled without needing all of these field checks. Common between the logic
    if (
      !isAllFieldFormatsValid(errors) ||
      !doesCoCustomerZipExist ||
      !doesZipExist ||
      !allNameFiledsValid
    ) {
      setIsEditing(true);
      return;
    }

    if (
      values.alternateAddressFields &&
      !(values.streetAddress && values.city && values.state && values.zipCode)
    ) {
      setIsEditing(true);
      return;
    }

    if (
      values.coBuyerAlternateAddressFields &&
      !(
        values.coCustomerStreetAddress &&
        values.coCustomerCity &&
        values.coCustomerState &&
        values.coCustomerZipCode
      )
    ) {
      setIsEditing(true);
      return;
    }

    // If BUYER agent was entered but no BUYER agent id was selected, open add agent box
    if (!isAddAgentModalActive && selectedAgent && !values.AgentId && props.showCooperatingFields) {
      setIsAgentVerificationActive(true);
      return;
    }

    // If initiating and cooperating agent matches, then display error
    if (initiatingAndCooperatingAgentMatch) {
      addErrorToQueue({
        title: 'Attention Required',
        message: c.INITIATING_COOPERATING_OFFICE_MATCH,
      });
      return;
    }

    // If cooperating information not properly entered, then end
    if (!cooperatingInfoValid) {
      // Ensure error message shows up. sometimes it does not show up
      if (!hasCooperatingAgent) {
        setErrors({ ...errors, cooperatingAgent: c.COOPERATING_AGENT_REQUIRED });
      }
      console.log(
        `cooperating information incomplete office=${hasCooperatingOffice}, agent=${hasCooperatingAgent}`,
      );
      return;
    }

    if (
      scratchFormValues.coBuyerFields &&
      !(scratchFormValues.coCustomerFirstName && scratchFormValues.coCustomerLastName)
    ) {
      setIsEditing(true);
      return;
    }

    // If required, we check if all fields are present and no errors
    if (props.isRequired) {
      if (
        isAllRequiredValuesPresent(scratchFormValues, values.needEmailOrPhone) &&
        containsNoErrors(errors)
      ) {
        if (missingBuyerPhoneABTest && !scratchFormValues?.phone) {
          // the focus is being done here because the modal wont render the focus correctly
          buyerPhoneRef?.current?.focus();
          setEnableMissingBuyerPhoneModal(true);
          fireGAEvent(MISSING_BUYER_PHONE_POPUP(props.agentInfo?.representing));
        } else {
          submit();
        }
      }
    } else {
      // If not required, check if user selected 'Yes' and have name. Otherwise check if user selected 'No'
      if ((hasBuyerInfo && hasCustomerName) || noBuyerInfo) {
        submit();
      } else {
        // errors object does not have 'Buyer required' error message in custom cooperating agent flow when neither of the buyer radio options is selected. Setting the error message here.
        setErrors({ buyerInfo: c.BUYER_RADIO_REQUIRED });
      }
    }
  };

  const onLoadCustomerAddressZipDetails = (res) => {
    if (res) {
      setDoesZipExist(true);
      setScratchFormValues({
        ...scratchFormValues,
        state: res.state,
        city: res.city,
        isDisabled: true,
      });
    } else {
      setDoesZipExist(false);
      setScratchFormValues({
        ...scratchFormValues,
        state: '',
        city: '',
        isDisabled: false,
      });
    }
  };

  const onLoadCoCustomerAddressZipDetails = (res) => {
    if (res) {
      setDoesCoCustomerZipExist(true);
      setScratchFormValues({
        ...scratchFormValues,
        coCustomerState: res.state,
        coCustomerCity: res.city,
        isCoCustomerDisabled: true,
      });
    } else {
      setDoesCoCustomerZipExist(false);
      setScratchFormValues({
        ...scratchFormValues,
        coCustomerState: '',
        coCustomerCity: '',
        isCoCustomerDisabled: false,
      });
    }
  };

  const onChangeInput = (e) => {
    if (e) {
      setScratchFormValues({
        ...scratchFormValues,
        [e.target.name]: e.target.value,
      });

      if (
        ['firstName', 'lastName', 'coCustomerFirstName', 'coCustomerLastName'].some(
          (name) => e.target.name === name,
        )
      ) {
        validateNameFields(e.target.name, e.target.value);
      }

      setErrors({
        ...errors,
        [e.target.name]: '',
      });
    }
  };

  // Check the validity of the first name and return the error message if it does not meet the requirements.
  const validateFirstName = (firstName) => {
    let errorMsg = '';
    if (!firstName) {
      errorMsg = c.FIRST_NAME_REQUIRED;
    } else if (firstName.length > c.FIRST_NAME_MAX_LENGTH) {
      errorMsg = c.FIRST_NAME_LENGTH_EXCEEDED;
    } else if (validateName(firstName)) {
      errorMsg = c.CUSTOMER_FIRST_NAME_SPECIAL_CHARACTERS_NOT_ALLOWED;
    }
    return errorMsg;
  };

  // Check the validity of the last name and return the error message if it does not meet the requirements.
  const validateLastName = (lastName) => {
    let errorMsg = '';
    if (!lastName) {
      errorMsg = c.LAST_NAME_REQUIRED;
    } else if (lastName.length > c.LAST_NAME_MAX_LENGTH) {
      errorMsg = c.LAST_NAME_LENGTH_EXCEEDED;
    } else if (validateName(lastName)) {
      errorMsg = c.CUSTOMER_LAST_NAME_SPECIAL_CHARACTERS_NOT_ALLOWED;
    }
    return errorMsg;
  };

  // Check the validity of the field and set its state
  const validateNameFields = (fieldName, fieldValue) => {
    switch (fieldName) {
      case 'firstName':
        setBuyerFirstNameError(validateFirstName(fieldValue));
        break;
      case 'lastName':
        setBuyerLastNameError(validateLastName(fieldValue));
        break;
      case 'coCustomerFirstName':
        setCobuyerFirstNameError(validateFirstName(fieldValue));
        break;
      case 'coCustomerLastName':
        setCobuyerLastNameError(validateLastName(fieldValue));
        break;
      default:
        console.log(
          `deafult condition encountered in validateNameFields, field ${fieldName} value: ${fieldValue}`,
        );
    }
  };

  // validate all name fields and set their state
  const validateAllNameFields = () => {
    let allNameFieldsValid = true;
    let errMsg = '';

    if (
      showBuyerInfoFields &&
      (savedFormValues.firstName !== scratchFormValues.firstName || !scratchFormValues.firstName)
    ) {
      // validate the first name
      errMsg = validateFirstName(scratchFormValues.firstName);
      setBuyerFirstNameError(errMsg);
      if (errMsg.length > 0) {
        allNameFieldsValid = false;
        errMsg = '';
      }
    }

    if (
      showBuyerInfoFields &&
      (savedFormValues.lastName !== scratchFormValues.lastName || !scratchFormValues.lastName)
    ) {
      // validate the last name
      errMsg = validateLastName(scratchFormValues.lastName);
      setBuyerLastNameError(errMsg);
      if (errMsg.length > 0) {
        allNameFieldsValid = false;
        errMsg = '';
      }
    }

    if (
      scratchFormValues.coBuyerFields &&
      (savedFormValues.coCustomerFirstName !== scratchFormValues.coCustomerFirstName ||
        !scratchFormValues.coCustomerFirstName)
    ) {
      // validate the coCustomer first name
      errMsg = validateFirstName(scratchFormValues.coCustomerFirstName);
      setCobuyerFirstNameError(errMsg);
      if (errMsg.length > 0) {
        allNameFieldsValid = false;
        errMsg = '';
      }
    }

    if (
      scratchFormValues.coBuyerFields &&
      (savedFormValues.coCustomerLastName !== scratchFormValues.coCustomerLastName ||
        !scratchFormValues.coCustomerLastName)
    ) {
      // validate the coCustomer last name
      errMsg = validateLastName(scratchFormValues.coCustomerLastName);
      setCobuyerLastNameError(errMsg);
      if (errMsg.length > 0) {
        allNameFieldsValid = false;
        errMsg = '';
      }
    }
    return allNameFieldsValid;
  };

  const data = {
    values: scratchFormValues,
    setValues: setScratchFormValues,
    setOptionValues: setCustomerCheckboxValues,
    handleSubmit: handleSave,
    optionValues: customerCheckboxValues,
    setShowAlternateAddressFields,
    setShowCoBuyerFields,
    setShowCoBuyerAlternateAddressFields,
    coBuyerOptionValues: coCustomerCheckboxValues,
    setBuyerOptionValues: setCustomerRadioValues,
    buyerOptionValues: customerRadioValues,
    setShowBuyerInfoFields,
    setCoBuyerOptionValues: setCoCustomerCheckboxValues,
    changeSelectedAgent,
    setErrors,
    overrideDefaultToggleCollapsed: true,
    setTouched,
  };

  async function updateOfficeAgents(offices) {
    let agentsList = [];
    if (offices[0] && offices[0].id !== '') {
      // getting list of agent as per selected office
      await AgentApi.searchAgents(
        {
          officeId: offices[0].id,
          officeType: offices[0].type,
          activeInactive: 'A',
        },
        {
          tags: { source: 'CardNewBuyerInfo#handleOfficeSelect' },
        },
      ).then((res) => {
        agentsList = res?.agentsList;
      });
      setScratchFormValues({
        ...scratchFormValues,
        office: offices[0],
        cooperatingOffice: offices[0].name,
        agentlist: agentsList,
      });
    }
  }

  async function handleOfficeSelect(offices) {
    await updateOfficeAgents(offices);

    setIsDrawerActive(false);

    // Clear the selected agent when the selected office changes
    changeSelectedAgent('');
  }

  useEffect(() => {
    if (scratchFormValues.office && !scratchFormValues.agentlist) {
      updateOfficeAgents([scratchFormValues.office]);
    }
  }, [scratchFormValues.agentlist]);

  /** When adding agent to office, we want to update the agent list to include the new agent, as well as select the agent */
  function addNewAgentToOffice(agent) {
    // Add the agent to the list of agents
    const updatedValues = {
      ...scratchFormValues,
      agentlist: [...scratchFormValues.agentlist, agent],
    };

    // Make the selection (done this way so that we are sync up with the auto-suggest logic)
    selectSuggestion(
      undefined,
      { suggestion: agent },
      changeSelectedAgent,
      setScratchFormValues,
      updatedValues,
    );
  }

  const handleSetAddress = (address, formFieldAddressNames) => {
    if (address) {
      // due to co-customers, derive the property name via the form field name
      const addressValues = {
        [formFieldAddressNames.address]: address.streetAddress,
        [formFieldAddressNames.unit]: address.unit,
        [formFieldAddressNames.city]: address.city,
        [formFieldAddressNames.state]: address.state,
        [formFieldAddressNames.zip]: address.zip,
      };

      setValues({
        ...values,
        ...deleteUndefinedValues(addressValues), // clear undefined values to avoid replacement
      });
    }
  };

  const handleStreetAddressError = (err, formFieldStreetAddressName) => {
    setErrors({
      ...errors,
      [formFieldStreetAddressName]: err,
    });
  };

  /** performs address verification.
   * Returns true if address verification necessary and executing.
   * Otherwise, returns false if no address verification necessary (i.e. no addresses entered) */
  const performAddressVerification = () => {
    const addressesToVerify = [];
    if (showAlternateAddressFields) {
      addressesToVerify.push({
        overrideModalId: 'validate-address--buyer',
        overrideModalHeading: 'Buyer Address Verification',
        key: CustomerKey.Customer,
        address: {
          streetAddress: scratchFormValues.streetAddress,
          unit: scratchFormValues.unit,
          city: scratchFormValues.city,
          state: scratchFormValues.state,
          zip: scratchFormValues.zipCode,
        },
      });
    }
    if (showCoBuyerAlternateAddressFields) {
      addressesToVerify.push({
        overrideModalId: 'validate-address--cobuyer',
        overrideModalHeading: 'Co-Buyer Address Verification',
        key: CustomerKey.CoCustomer,
        address: {
          streetAddress: scratchFormValues.coCustomerStreetAddress,
          unit: scratchFormValues.coCustomerUnit,
          city: scratchFormValues.coCustomerCity,
          state: scratchFormValues.coCustomerState,
          zip: scratchFormValues.coCustomerZipCode,
        },
      });
    }
    setAddressVerificationItems(addressesToVerify);

    if (addressesToVerify.length !== 0) {
      setEnableAddressVerification(true);
      return true;
    }
    return false;
  };

  const updateAddressFromVerification = ({ key, verifiedAddress }) => {
    switch (key) {
      case CustomerKey.Customer:
        setScratchFormValues({
          ...scratchFormValues,
          streetAddress: verifiedAddress.streetAddress,
          unit: verifiedAddress.unit,
          city: verifiedAddress.city,
          state: verifiedAddress.state,
          zipCode: verifiedAddress.zip,
          customerAddressUUID: verifiedAddress.meta?.addressId,
        });
        break;
      case CustomerKey.CoCustomer:
        setScratchFormValues({
          ...scratchFormValues,
          coCustomerStreetAddress: verifiedAddress.streetAddress,
          coCustomerUnit: verifiedAddress.unit,
          coCustomerCity: verifiedAddress.city,
          coCustomerState: verifiedAddress.state,
          coCustomerZipCode: verifiedAddress.zip,
          coCustomerAddressUUID: verifiedAddress.meta?.addressId,
        });
        break;
    }
  };

  const toggleCustomCooperatingAgent = () => {
    setIsAddAgentModalActive(!isAddAgentModalActive);
    setCooperatingAgentCustom({ ...initialCustomCooperatingAgent });
    setCooperatingAgentErrors({ ...initialCustomCooperatingAgent });
    foundCustomCooperatingAgent = null;

    // clear any previously selected custom cooperating agent details
    const newValues = {
      ...scratchFormValues,
      AgentName: '',
      AgentEmail: '',
      AgentPhone: '',
      AgentId: '',
    };
    setScratchFormValues(newValues);
    changeSelectedAgent('');
    setPreviousCustomCooperatingAgent({ ...cooperatingAgentCustom, hasValue: true });
    setPreviousCooperatingAgent({ selectedAgent, selectedAgentId: values.AgentId });

    // Applicable only in Edit Order flow.
    // Switching to existing agent selection flow from custom cooperating agent. Loading office and its agents.
    if (props.flow == 'Edit Order' && customCooperatingAgentForEditOrder.hasValue) {
      updateOfficeAgents([customCooperatingAgentForEditOrder.office]);
    }
  };

  const onClearCustomCooperatingAgentFields = () => {
    foundCustomCooperatingAgent = null;
    setCooperatingAgentErrors({ ...initialCustomCooperatingAgent });
    setCooperatingAgentCustom({ ...initialCustomCooperatingAgent });

    // save a copy if the user decides to cancel changes
    setPreviousCustomCooperatingAgent(cooperatingAgentCustom);
    // setPreviousCooperatingAgent({selectedAgent, selectedAgentId: values.AgentId}); // TODO Why are we doing this? Removed because this caused a bug during ARE-11726
    setIsAddAgentModalActive(false);
  };

  const onClear = () => {
    setCustomerCheckboxValues({ alternateAddressFields: false, coBuyerFields: false });
    setCustomerRadioValues({ hasBuyerInfo: props.isRequired, noBuyerInfo: false });
    setShowAlternateAddressFields(false);
    setShowCoBuyerFields(false);
    setShowCoBuyerAlternateAddressFields(false);
    setCoCustomerCheckboxValues({ coBuyerAlternateAddressFields: false });
    changeSelectedAgent('');
    setShowBuyerInfoFields(props.isRequired);
    setScratchFormValues({
      buyerInfo: { yes: props.isRequired, no: false },
      needEmailOrPhone: values.needEmailOrPhone,
    });
    setTouched({});
    setValues({});
    setErrors?.({});
    onClearCustomCooperatingAgentFields();
  };

  function handleMissingPhoneOnClick() {
    fireGAEvent(ADD_BUYER_PHONE_NUMBER(props.agentInfo?.representing));
    setEnableMissingBuyerPhoneModal(false);
  }

  function handleClickDoLater() {
    fireGAEvent(ADD_BUYER_PHONE_NUMBER_LATER(props.agentInfo?.representing));
    setEnableMissingBuyerPhoneModal(false);
    submit();
  }

  useEffect(() => {
    if (scratchFormValues.office && scratchFormValues.agentlist?.length === 0) {
      updateOfficeAgents([scratchFormValues.office]);
    }
  }, [scratchFormValues.AgentId]);

  const getCooperatingAgentName = () => {
    if (
      cooperatingAgentCustom.hasValue &&
      !scratchFormValues.AgentId &&
      !foundCustomCooperatingAgent
    ) {
      return (
        <div>
          <div>
            {`${cooperatingAgentCustom.firstName} ${cooperatingAgentCustom.lastName} (to be invited)`}
          </div>
          <div>{cooperatingAgentCustom.email}</div>
        </div>
      );
    }
    return savedFormValues.cooperatingAgent;
  };

  const onSetIsAddAgentModalActive = () => {
    setIsAddAgentModalActive(true);

    // clear if an existing agent was selected from agentList
    setScratchFormValues({
      ...scratchFormValues,
      AgentEmail: '',
      AgentId: '',
      AgentName: '',
      AgentPhone: '',
    });
  };

  const onClose = () => {
    setIsEditing(!isEditing);

    if (scratchFormValues.AgentId || foundCustomCooperatingAgent?.AgentId) {
      changeSelectedAgent(savedFormValues.cooperatingAgent);
      setIsAddAgentModalActive(false);
      setCooperatingAgentCustom({ ...initialCustomCooperatingAgent });
      setCooperatingAgentErrors({ ...initialCustomCooperatingAgent });
      foundCustomCooperatingAgent = null;
    } else if (previousCustomCooperatingAgent.hasValue || cooperatingAgentCustom.hasValue) {
      // reset custom cooperating agent
      setCooperatingAgentCustom({ ...previousCustomCooperatingAgent });
      const agentName = `${cooperatingAgentCustom.firstName} ${cooperatingAgentCustom.lastName}`;
      changeSelectedAgent(agentName?.trim());
      setIsAgentVerificationActive(agentName?.trim() !== '');
    }
  };

  const onEdit = () => {
    setIsEditing(!isEditing);

    if (previousCooperatingAgent.selectedAgent && previousCooperatingAgent.selectedAgentId) {
      changeSelectedAgent(previousCooperatingAgent.selectedAgent);
      setValues({ ...values, AgentId: previousCooperatingAgent.selectedAgentId });
    }

    // default to cooperating agent flow when no selection is made yet
    if (
      (!foundCustomCooperatingAgent || !scratchFormValues.AgentId) &&
      !cooperatingAgentCustom.hasValue
    ) {
      setIsAddAgentModalActive(false);
      return;
    }

    // switch to custom cooperating agent view if selectedAgentId is not assigned
    if (!scratchFormValues?.AgentId && !foundCustomCooperatingAgent) {
      setPreviousCustomCooperatingAgent({ ...cooperatingAgentCustom, hasValue: true });
      setIsAddAgentModalActive(true);
    }
  };

  const getCooperatingOfficeInEditMode = () => {
    // return saved data if it is Edit order with custom cooperating agent flow
    if (props.flow == 'Edit Order' && customCooperatingAgentForEditOrder.hasValue) {
      return customCooperatingAgentForEditOrder.cooperatingOffice;
    }

    return scratchFormValues.cooperatingOffice || '';
  };

  const getOfficeInDisplayMode = () => {
    // return saved data if it is Edit order with custom cooperating agent flow
    if (props.flow == 'Edit Order' && customCooperatingAgentForEditOrder.hasValue) {
      return customCooperatingAgentForEditOrder.cooperatingOffice;
    }

    return savedFormValues.cooperatingOffice || '';
  };

  const isCooperatingAgentDisabled = () => {
    // custom cooperating already selected and now user wants to select existing agent
    if (props.flow == 'Edit Order') {
      return !scratchFormValues.cooperatingOffice && !customCooperatingAgentForEditOrder.hasValue;
    }

    return !scratchFormValues.cooperatingOffice;
  };

  // clear custom cooperating fields on unload
  useEffect(() => {
    return () => {
      setCustomCooperatingAgentForEditOrder({
        ...initialCustomCooperatingAgent,
        cooperatingOffice: '',
        office: undefined,
      });
      setScratchFormValues({ ...scratchFormValues, agentlist: null });
    };
  }, []);

  return (
    <>
      <CardNewOrder
        title="Buyer Information"
        alertText={props.alertText}
        isEditing={isEditing}
        toggleEditing={onEdit}
        isCollapsed={isCollapsed}
        toggleCollapsed={() => {
          setIsCollapsed(!isCollapsed);
          setIsEditing(false);
        }}
        isSmall={props.isSmall}
        disableEdit={props.disableEdit}
        onCancel={props.onCancel}
        data={data}
        isRequired={props.isRequired}
        cancelModal={props.cancelModal}
        isSaveDisabled={props.isBuyerDisabled}
        onClear={onClear}
        onClose={onClose}
      >
        {!isEditing ? (
          <div
            className={classNames([
              'sm:flex sm:flex-col sm:flex-1 sm:-my-2',
              props.isSmall && 'sm:-mx-4',
              isCollapsed && 'xs-max:hidden',
            ])}
          >
            {/* Buyer information */}
            <div className="sm:flex w-full sm:py-2">
              <div className="sm:w-1/3 sm:px-4">
                <REText variant="label">{ORDER_VIEW_LABELS.BUYER_NAME}</REText>
                <REText id="card-new-order-buyer-info__buyer-name-text">
                  {savedFormValues.firstName} {savedFormValues.lastName}
                </REText>
              </div>

              <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                <REText variant="label">{ORDER_VIEW_LABELS.BUYER_CONTACT}</REText>
                <REText id="card-new-order-buyer-info__buyer-contact-information-email-text">
                  {savedFormValues.email}
                </REText>
                <REText id="card-new-order-buyer-info__buyer-contact-information-phone-text">
                  {formatPhoneNumber(savedFormValues.phone)}
                </REText>
              </div>

              {savedFormValues.alternateAddressFields && (
                <div className="sm:w-1/3 sm:px-4">
                  <REText variant="label">{ORDER_VIEW_LABELS.BUYER_ADDRESS}</REText>
                  <REText id="card-new-order-buyer-info__buyer-alternate-mailing-address-text">
                    {savedFormValues.streetAddress}{' '}
                    {savedFormValues.unit ? `unit # ${savedFormValues.unit}` : ''}
                    <br />
                    {`${savedFormValues.city} ${savedFormValues.state} ${savedFormValues.zipCode}`}
                  </REText>
                </div>
              )}
            </div>

            {/* Co-Buyer Information */}
            {savedFormValues.coBuyerFields && (
              <div className="sm:flex w-full sm:py-2">
                <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                  <REText variant="label">{ORDER_VIEW_LABELS.CO_BUYER_NAME}</REText>
                  <REText id="card-new-order-buyer-info__co-buyer-name-text">
                    {savedFormValues.coCustomerFirstName} {savedFormValues.coCustomerLastName}
                  </REText>
                </div>

                {savedFormValues.coBuyerFields && (
                  <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                    <REText variant="label">{ORDER_VIEW_LABELS.CO_BUYER_CONTACT}</REText>
                    <REText id="card-new-order-buyer-info__co-buyer-contact-information-email-text">
                      {savedFormValues.coCustomerEmail}
                    </REText>
                    <REText id="card-new-order-buyer-info__co-buyer-contact-information-phone-text">
                      {formatPhoneNumber(savedFormValues.coCustomerPhone)}
                    </REText>
                  </div>
                )}

                {savedFormValues.coBuyerAlternateAddressFields && (
                  <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                    <REText variant="label">{ORDER_VIEW_LABELS.CO_BUYER_ADDRESS}</REText>
                    <REText id="card-new-order-buyer-info__co-buyer-alternate-mailing-address-text">
                      {savedFormValues.coCustomerStreetAddress}{' '}
                      {savedFormValues.coCustomerUnit
                        ? `unit # ${savedFormValues.coCustomerUnit}`
                        : ''}
                      <br />
                      {`${savedFormValues.coCustomerCity} ${savedFormValues.coCustomerState} ${savedFormValues.coCustomerZipCode}`}
                    </REText>
                  </div>
                )}
              </div>
            )}

            {/* Cooperating Office/Agent */}
            <div className={!props.showCooperatingFields ? 'hidden' : 'sm:flex w-full sm:py-2'}>
              <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                <REText variant="label">{ORDER_FORM_FIELDS.COOPERATING_OFFICE}</REText>
                <REText id="card-new-order-buyer-info__cooperating-buyer-office-text">
                  {getOfficeInDisplayMode()}
                </REText>
              </div>

              <div className="sm:w-1/3 xs-max:mt-3 sm:px-4">
                <REText variant="label">{ORDER_FORM_FIELDS.COOPERATING_AGENT}</REText>
                <REText id="card-new-order-buyer-info__cooperating-buyer-agent-text">
                  {getCooperatingAgentName()}
                </REText>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap -mx-4">
            <div className="seller-info-fields flex lg:flex-wrap w-full mt-1 flex-col lg:grid lg:grid-cols-2">
              <div
                className={classNames([
                  !props.showCooperatingFields
                    ? 'hidden'
                    : 'w-full px-4 py-2 cursor-pointer order-0 lg:row-start-1 lg:col-start-1',
                ])}
                onClick={() => setIsDrawerActive(!isDrawerActive)}
              >
                <div className="pointer-events-none">
                  <Input
                    formField={true}
                    formFieldMessageId={getFormInputErrorId('wb_buyerCooperatingOffice')}
                    id="wb_buyerCooperatingOffice"
                    name="cooperatingOffice"
                    label={ORDER_FORM_FIELDS.COOPERATING_OFFICE}
                    value={getCooperatingOfficeInEditMode()}
                    autocomplete="off"
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div
                className={
                  !props.showCooperatingFields
                    ? 'hidden'
                    : 'px-4 py-2 order-1 lg:col-start-2 lg:row-start-1 lg:row-span-3'
                }
              >
                {!isAddAgentModalActive ? (
                  <AgentAutosuggest
                    id="wb_buyerCooperatingAgent"
                    idPrefix={"cooperating-agent"}
                    typeAheadAddress={typeAheadAddress}
                    setTypeAheadAddress={setTypeAheadAddress}
                    selectedAgent={selectedAgent}
                    changeSelectedAgent={changeSelectedAgent}
                    agentHelpText={errors.cooperatingAgent}
                    setAgentHelpText={setAgentHelpText}
                    agentOffice={scratchFormValues.cooperatingOffice}
                    agentlist={scratchFormValues.agentlist}
                    placeholder={ORDER_FORM_FIELDS.COOPERATING_AGENT}
                    setValues={setScratchFormValues}
                    values={scratchFormValues}
                    addAgentEnabled={true}
                    setIsAddAgentModalActive={onSetIsAddAgentModalActive}
                    isDisabled={isCooperatingAgentDisabled()}
                    buttonText="Invite Cooperating Agent"
                    subText="Invite the cooperating agent to associate themselves to the order"
                    noResultsFoundHelperText="Try searching for a different agent or send invitation to register"
                  />
                ) : (
                  <CustomCooperatingAgent
                    cooperatingAgentCustom={cooperatingAgentCustom}
                    setIsActive={toggleCustomCooperatingAgent}
                    setCooperatingAgentCustom={setCooperatingAgentCustom}
                    setCooperatingAgentDetails={setCooperatingAgentCustom}
                    agentsList={scratchFormValues.agentlist}
                    savedFormValues={savedFormValues}
                    errors={cooperatingAgentErrors}
                    setErrors={setCooperatingAgentErrors}
                  />
                )}
              </div>
              <div
                className={
                  props.isRequired
                    ? 'hidden'
                    : 'px-4 py-2 -mb-1 order-2  w-fit lg:col-start-1 lg:row-start-2'
                }
              >
                <RadioGroup
                  formField={true}
                  formFieldMessageId={getFormRadioErrorId('wb_buyerInfo')}
                  id="wb_buyerInfo"
                  name="buyerInfo"
                  label={ORDER_FORM_FIELDS.BUYER__QUESTION}
                  required={true}
                  radios={convertOptionsObjToRadioGroupOptions('wb_buyerInfo', customerRadioLabel)}
                  value={convertValuesToRadioGroupValue(customerRadioValues)}
                  onChange={(selected) =>
                    convertRadioGroupOnChangeToOnClick(selected, toggleBuyerInfoFields)
                  }
                  error={errors.buyerInfo}
                  orientation="horizontal"
                />
              </div>
            </div>
            <div ref={buyerInfoFields} className="w-full  flex flex-wrap">
              {showBuyerInfoFields && (
                <>
                  {/* Buyer phone number message when the agent represents Buyer/Both */}
                  {!props.agentInfo.agentRepresents.seller && (
                    <div className="m-2 w-full flex">
                      <Panel
                        backgroundColor={IsTheme(Theme.Ahs2024) ? "accent-500" : "interactive-50"}
                        rounded={IsTheme(Theme.Ahs2024) ? "xl" : "md"}
                        borderColor="gray-300"
                      >
                        <REText color="secondary" variant="body-short" className="font-bold inline">
                          Please provide the buyer’s phone number.{' '}
                        </REText>
                        <REText variant="body-short" className="inline">
                          This information ensures that the buyer is properly informed and aware of
                          their warranty coverage and benefits. We will not reach out to the buyer
                          before sale is completed.
                        </REText>
                      </Panel>
                    </div>
                  )}

                  <FormNewOrderCustomer
                    ids={formNewOrderCustomerPropIds.customer}
                    names={formNewOrderCustomerPropNames.customer}
                    values={{
                      firstName: scratchFormValues.firstName,
                      lastName: scratchFormValues.lastName,
                      email: scratchFormValues.email,
                      phone: scratchFormValues.phone,
                    }}
                    errors={{
                      firstName: buyerFirstNameError,
                      lastName: buyerLastNameError,
                      email: errors.email,
                      phone: errors.phone,
                    }}
                    required={{
                      firstName: true,
                      lastName: true,
                      email: props.isCustomerContactInfoRequired,
                      phone: props.isCustomerContactInfoRequired,
                    }}
                    onInputChange={onChangeInput}
                    onInputBlur={handleBlur}
                    buyerPhoneRef={buyerPhoneRef}
                  />

                  <div className="w-full px-4 py-2">
                    <CheckboxGroup
                      id="wb_buyer-checkbox"
                      label="Buyer Options"
                      checkboxes={convertOptionsObjToCheckboxGroupOptions(
                        'wb_buyer-checkbox',
                        customerCheckboxLabels,
                      )}
                      value={convertValuesToCheckboxGroupValue(customerCheckboxValues)}
                      onChange={(items) =>
                        convertCheckboxGroupOnChangeToOnClick(
                          items,
                          customerCheckboxValues,
                          toggleCheckboxFields,
                        )
                      }
                    />
                  </div>

                  <div ref={alternateAddressFields} className="w-full">
                    {showAlternateAddressFields && (
                      <FormNewOrderCustomerAddress
                        shouldLoadZipDetails={true}
                        onLoadZipDetails={onLoadCustomerAddressZipDetails}
                        ids={formNewOrderCustomerPropIds.customerAddress}
                        names={formNewOrderCustomerPropNames.customerAddress}
                        values={{
                          address: scratchFormValues.streetAddress,
                          unit: scratchFormValues.unit,
                          city: scratchFormValues.city,
                          state: scratchFormValues.state,
                          zip: scratchFormValues.zipCode,
                        }}
                        errors={{
                          address: errors.streetAddress,
                          unit: undefined,
                          city: errors.city,
                          state: errors.state,
                          zip:
                            !doesZipExist && errors.zipCode == null
                              ? c.INVALID_ZIP_CODE
                              : errors.zipCode,
                        }}
                        required={{
                          address: false,
                          unit: false,
                          city: false,
                          state: false,
                          zip: false,
                        }}
                        onInputChange={onChangeInput}
                        onInputBlur={handleBlur}
                        onStateSelect={(item) => {
                          handleBlur({
                            target: {
                              name: 'state',
                              value: item.value,
                            },
                          });
                        }}
                        stateDisabled={scratchFormValues.isDisabled}
                        setAddress={(address) =>
                          handleSetAddress(address, formNewOrderCustomerPropNames.customerAddress)
                        }
                        setStreetAddressError={(err) =>
                          handleStreetAddressError(
                            err,
                            formNewOrderCustomerPropNames.customerAddress.address,
                          )
                        }
                      />
                    )}
                  </div>

                  <div ref={coBuyerFields} className="w-full flex flex-wrap">
                    {showCoBuyerFields && (
                      <>
                        <FormNewOrderCustomer
                          ids={formNewOrderCustomerPropIds.coCustomer}
                          names={formNewOrderCustomerPropNames.coCustomer}
                          values={{
                            firstName: scratchFormValues.coCustomerFirstName,
                            lastName: scratchFormValues.coCustomerLastName,
                            email: scratchFormValues.coCustomerEmail,
                            phone: scratchFormValues.coCustomerPhone,
                          }}
                          errors={{
                            firstName: cobuyerFirstNameError,
                            lastName: cobuyerLastNameError,
                            email: errors.coCustomerEmail,
                            phone: errors.coCustomerPhone,
                          }}
                          required={{
                            firstName: true,
                            lastName: true,
                            email: false,
                            phone: false,
                          }}
                          onInputChange={onChangeInput}
                          onInputBlur={handleBlur}
                        />

                        <div className="sm:flex sm:items-end w-full">
                          <div className="w-full px-4 py-2">
                            <CheckboxGroup
                              id="wb_coBuyerAltAddressCheck"
                              label="Co-Buyer Options"
                              checkboxes={convertOptionsObjToCheckboxGroupOptions(
                                'wb_coBuyerAltAddressCheck',
                                coCustomerCheckboxLabels,
                              )}
                              value={convertValuesToCheckboxGroupValue(coCustomerCheckboxValues)}
                              onChange={(items) =>
                                convertCheckboxGroupOnChangeToOnClick(
                                  items,
                                  coCustomerCheckboxValues,
                                  toggleCoBuyerCheckboxFields,
                                )
                              }
                            />
                          </div>
                        </div>

                        <div ref={coBuyerAlternateAddressFields} className="flex flex-wrap w-full">
                          {showCoBuyerAlternateAddressFields && (
                            <FormNewOrderCustomerAddress
                              shouldLoadZipDetails={true}
                              onLoadZipDetails={onLoadCoCustomerAddressZipDetails}
                              ids={formNewOrderCustomerPropIds.coCustomerAddress}
                              names={formNewOrderCustomerPropNames.coCustomerAddress}
                              values={{
                                address: scratchFormValues.coCustomerStreetAddress,
                                unit: scratchFormValues.coCustomerUnit,
                                city: scratchFormValues.coCustomerCity,
                                state: scratchFormValues.coCustomerState,
                                zip: scratchFormValues.coCustomerZipCode,
                              }}
                              errors={{
                                address: errors.coCustomerStreetAddress,
                                unit: undefined,
                                city: errors.coCustomerCity,
                                state: errors.coCustomerState,
                                zip:
                                  !doesCoCustomerZipExist && errors.coCustomerZipCode == null
                                    ? c.INVALID_ZIP_CODE
                                    : errors.coCustomerZipCode,
                              }}
                              required={{
                                address: false,
                                unit: false,
                                city: false,
                                state: false,
                                zip: false,
                              }}
                              onInputChange={onChangeInput}
                              onInputBlur={handleBlur}
                              onStateSelect={(item) => {
                                handleBlur({
                                  target: {
                                    name: 'coCustomerState',
                                    value: item.value,
                                  },
                                });
                              }}
                              stateDisabled={scratchFormValues.isCoCustomerDisabled}
                              setAddress={(address) =>
                                handleSetAddress(
                                  address,
                                  formNewOrderCustomerPropNames.coCustomerAddress,
                                )
                              }
                              setStreetAddressError={(err) =>
                                handleStreetAddressError(
                                  err,
                                  formNewOrderCustomerPropNames.coCustomerAddress.address,
                                )
                              }
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </CardNewOrder>

      <DrawerOfficeSearch
        id="card-new-order-buyer-info__drawer-office-search"
        usaStates={props.usaStates}
        typeCode="RE"
        isActive={isDrawerActive}
        onClose={() => setIsDrawerActive(false)}
        userOfficeIDs={props.setUserOfficeIDs}
        onSubmit={handleOfficeSelect}
        ignoreReadOnly={true}
      />

      <ModalMissingBuyerPhone
        isActive={enableMissingBuyerPhoneModal}
        onClickAddPhone={handleMissingPhoneOnClick}
        onClickDoLater={() => {
          handleClickDoLater();
        }}
        onClose={() => setEnableMissingBuyerPhoneModal(false)}
      />

      <ModalAgentVerification
        id="modal-agent-verification"
        isActive={isAgentVerificationActive}
        onClose={() => setIsAgentVerificationActive(false)}
        selectedAgent={selectedAgent}
        agentRole="cooperating"
        setTypeAheadAddress={setTypeAheadAddress}
        setIsAddAgentModalActive={toggleCustomCooperatingAgent}
      />

      <AddressVerification
        id="validate-address--buyer"
        items={addressVerificationItems}
        isActive={enableAddressVerification}
        onVerifiedAddress={updateAddressFromVerification}
        onComplete={() => submit(true)}
        onReset={() => {
          setAddressVerificationItems([]);
          setEnableAddressVerification(false);
        }}
        disableUnverifiedAddressCreation={props.disableUnverifiedAddressCreation}
      />
    </>
  );
};

export default CardNewOrderBuyerInfo;
