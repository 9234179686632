import React from 'react';
import { classNames } from '@utils';
import { IsTheme, Theme } from '@app/core/featureToggle';

/*
 props {
   isRequired: if set to true, add asterisk to end of the heading
   heading: the heading text
   headingHelper: text to add to the heading (not bolded)
   headingClassName: class styling to add to the heading
   id: the DOM id of the component
   className: the class styling for the component
 }
 */

const FormFieldset = (props) => {
  return (
    <fieldset id={props.id} className={classNames(['form-fieldset', props.className || ''])}>
      {props.heading && (
        <legend className={classNames(['form__legend mb-2', props.headingClassName || ''])}>
          <span className='font-bold'>
            {props.heading} {props.isRequired && '*'}
          </span>{' '}
          {props.headingHelper || ''}
        </legend>
      )}

      {props.children}
    </fieldset>
  );
};

export default FormFieldset;
