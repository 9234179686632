import React, { useContext, useEffect, useState } from 'react';
import { addressToString } from '@services/helpers';
import { DateFormat, formatDate, formatDateFromString, toCurrency } from '@app/helpers/utils';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import {
  Button,
  IconDiscountTag,
  IconMoney,
  IconPin,
  MaskedInput,
  Notification,
  Panel,
  Radio,
} from '@ftdr/blueprint-components-react';
import ProfileContext from '@context/ProfileContext/index';
import StripeMFE from '@components/payment/StripeMicroFrontEnd';
import { captureMessage as sentryCaptureMessage } from '@sentry/react';
import { PAYMENT_MIN_AMOUNT_ERROR } from '@constants/formField-constants';
import { MINIMUM_ALLOWED_PARTIAL_PAYMENT } from '@constants/formField-constants';
import msgs from '@app/locales/en';
import { MakePaymentResponse } from '@apis/models';
import { classNames } from '@utils';
import { fireGAEvent } from '@app/core/tracking.service';
import { ORDER__PAYMENT_CLICK } from '@constants/ga-events.constants';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

const defaultPartialPaymentErr = {
  hasError: false,
  errorMessage: '',
};

export interface MakePaymentStripeProps {
  id: string;
  isActive: boolean;
  hideSuccessAlert?: boolean;
  onClose: () => void;
  submitACHPayment: (any, string) => Promise<MakePaymentResponse>;
  submitCCPayment: (any, string) => Promise<MakePaymentResponse>;
  onSuccess: (contractID: string) => void;
  contractBalance: number;
  totalPrice: number;
  creditAmount: number;
  discountAmount: number;
  militaryDiscountApplied: boolean;
  contract: any;
  hideCancelButton?: boolean;
  closeDrawer?: () => void;
  sourcePage?: string;
}

interface DiscountLabelProps {
  label?: string;
}

export const DiscountLabel: React.FC<DiscountLabelProps> = (props) => {
  return (
    <>
      <IconDiscountTag id="disountTag" size={22} color="warning" className="inline-flex" />
      <span className="pl-1 inline-flex">{props.label}</span>
    </>
  );
};
DiscountLabel.defaultProps = {
  label: 'Discount Applied',
};

const MakePaymentStripe: React.FC<MakePaymentStripeProps> = (props) => {
  const { profile } = useContext(ProfileContext);
  const { addErrorToQueue, addSuccessToQueue } = useGlobalAlert();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [fullAmntRadio, setfullAmntRadio] = useState(true);

  const [partialAmntRadio, setPartialAmntRadio] = useState(false);
  const [partialPaymenAmnt, setPartialPaymentAmnt] = useState('');
  const [partialPaymentErr, setPartialPaymentErr] = useState(defaultPartialPaymentErr);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    resetState();
  }, [props.isActive]);

  const resetState = () => {
    setIsSubmitting(false);
    setfullAmntRadio(true);
    setPartialAmntRadio(false);
    setPartialPaymentAmnt('');
    setPartialPaymentErr(defaultPartialPaymentErr);
  };

  const validateFullPayment = () => {
    return props.contractBalance > 0;
  };

  const validateAndSetPartialPayment = (paymentAmount) => {
    const paymentAmnt = parseFloat(paymentAmount);
    const contractBlnc = props.contractBalance;
    if (paymentAmnt > contractBlnc) {
      setPartialPaymentErr({
        hasError: true,
        errorMessage: 'Amount must be less than balance due',
      });
    } else if (paymentAmnt <= 0) {
      setPartialPaymentErr({
        hasError: true,
        errorMessage: PAYMENT_MIN_AMOUNT_ERROR,
      });
    } else if (paymentAmnt < MINIMUM_ALLOWED_PARTIAL_PAYMENT) {
      setPartialPaymentErr({
        hasError: true,
        errorMessage: PAYMENT_MIN_AMOUNT_ERROR,
      });
    } else if (paymentAmount === '' || Number.isNaN(paymentAmnt)) {
      setPartialPaymentErr({
        hasError: true,
        errorMessage: 'Amount is required',
      });
    } else {
      setPartialPaymentErr(defaultPartialPaymentErr);
    }
    setPartialPaymentAmnt(paymentAmnt.toString());
    return !partialPaymentErr.hasError;
  };

  const validateForm = () => {
    let valid = true;
    if (partialAmntRadio) {
      valid = validateAndSetPartialPayment(partialPaymenAmnt);
    } else {
      valid = validateFullPayment();
    }
    return valid;
  };

  const getBuyerInformation = (customersArr) => {
    const buyer = customersArr.find((customer) => customer.type === 'BUY');
    return buyer.customerId ? buyer.customerId : 1;
  };

  const handleRadioPaymentChange = (event) => {
    if (event.target.value === 'partial') {
      setPartialAmntRadio(event.target.checked);
      setfullAmntRadio(!event.target.checked);
      // validateAndSetPartialPayment(partialPaymenAmnt);
    }
    if (event.target.value === 'full') {
      setfullAmntRadio(event.target.checked);
      setPartialAmntRadio(!event.target.checked);
      setPartialPaymentErr(defaultPartialPaymentErr);
      setPartialPaymentAmnt('');
    }
  };

  useEffect(() => {
    if (partialAmntRadio) {
      document.getElementById('stripe-partial-payment-amount').focus();
    }
  }, [partialAmntRadio]);

  const cardTypes = {
    10: 'AMEX',
    70: 'VISA',
    50: 'MC',
    30: 'DSC',
  };

  const getDateToProcess = () => {
    const currentDate = new Date();
    const estcoeDateObject = props.contract.detail.dates.find((escoeDate) => {
      return escoeDate.type === 'ESTCOE';
    });
    const estcoeDate = new Date(estcoeDateObject.effective);
    estcoeDate.setDate(estcoeDate.getDate() + 1);

    if (currentDate > estcoeDate) {
      return formatDate(currentDate, DateFormat.ISO8601);
    }
    if (estcoeDate > currentDate) {
      return formatDate(estcoeDate, DateFormat.ISO8601);
    }
    console.error('invalid date, defaulting to current');
    return formatDate(currentDate, DateFormat.ISO8601);
  };

  const populateCSCRequest = (fullAmntRadio1: boolean, partialPaymentAmnt1: string) => {
    const cscRequest = {
      contractId: props.contract.summary.id,
      dateToProcess: getDateToProcess(),
      transactionAmount: fullAmntRadio1 ? props.contractBalance : partialPaymentAmnt1,
      customerId: getBuyerInformation(props.contract.detail.customers),
      propertyId: props.contract.detail.property.ID,
      paymentTypeCode:
        props.contractBalance < Math.floor(props.totalPrice * 100) / 100 ? 'INSTPAY' : 'TOTAL',
      userId: profile.AhsUserID,
      lastModifiedBy: 'SVCRealEstatePro',
      transactionSourceTypeCode: 'WEBSALES',
    };
    return cscRequest;
  };
  const mfeResponse = (response, basicCSCRequest) => {
    const { summary } = props.contract;
    console.info(`[STRIPE MFE] onSuccess or onFailure called for for contractID: ${summary.id}`);

    // ACH
    if (response.type === 'ach_success') {
      const cscACHRequest = {
        ...basicCSCRequest,
        SavePaymentSource: 'N',
        SaveAsDefault: 'N',
        bankAccountNumber: response.achSuccessResponse.bankAccountNumber,
        bankRoutingNumber: response.achSuccessResponse.bankRoutingNumber,
        transactionTypeCode: 'ACH',
        accountType: 'Checking',
      };
      props
        .submitACHPayment(cscACHRequest, summary.id)
        .then((res) => {
          if (res.status === 'success') {
            handleSuccessfulCall(cscACHRequest.transactionTypeCode, summary.id);
          }
        })
        .catch((err) => {
          sentryCaptureMessage(`[ACH PAYMENT FAILED] for contractID: ${summary.id}`);
          addErrorToQueue(msgs.PAYMENT_FAILED_CONTACT_SUPPORT_ERROR);
          console.error('error calling ACH payments: ', err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (response.type === 'stripe_success') {
      const cardExpDate = new Date(
        `${response.stripeSuccessResponse.paymentMethod.details.cc.expirationDate.month}/01/${response.stripeSuccessResponse.paymentMethod.details.cc.expirationDate.year}`,
      );
      const cardMonth = cardExpDate.getMonth();
      const cardYear = cardExpDate.getFullYear();
      const cardExpDateLastDateOfMonth = new Date(cardYear, cardMonth + 1, 0);

      const ccRequest = {
        ...basicCSCRequest,
        cardNumberLast4: response.stripeSuccessResponse.paymentMethod.details.cc.last4,
        transactionTypeCode: 'CC',
        cardExpirationDate: cardExpDateLastDateOfMonth.toISOString(),
        cardId: response.stripeSuccessResponse.paymentMethod.paymentMethodID,
        cardTypeCode: cardTypes[response.stripeSuccessResponse.paymentMethod.details.cc.brand],
        accountId: `CUSTOMER${summary.id}`,
      };

      props
        .submitCCPayment(ccRequest, summary.id)
        .then((res) => {
          if (res.status === 'success') {
            handleSuccessfulCall(ccRequest.transactionTypeCode, summary.id);
          }
        })
        .catch((err) => {
          sentryCaptureMessage(`[CC PAYMENT FAILED] for contractID: ${summary.id}`);
          addErrorToQueue(msgs.PAYMENT_FAILED_CONTACT_SUPPORT_ERROR);

          console.error('error calling CC payments: ', err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (response.type === 'paymentus_success') {
      const cscPaymentusRequest = {
        ...basicCSCRequest,
        transactionTypeCode: 'ACH',
        cardId: response.paymentusSuccessResponse.paymentMethod.paymentMethodID,
        cardTypeCode: 'ACH',
      };
      props
        .submitCCPayment(cscPaymentusRequest, summary.id)
        .then((res) => {
          if (res.status === 'success') {
            handleSuccessfulCall(cscPaymentusRequest.transactionTypeCode, summary.id);
          }
        })
        .catch((err) => {
          sentryCaptureMessage(`[PAYMENTUS PAYMENT FAILED] for contractID: ${summary.id}`);
          addErrorToQueue(msgs.PAYMENT_FAILED_CONTACT_SUPPORT_ERROR);

          console.error('error calling PAYMENTUS payments: ', err);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      sentryCaptureMessage(`[PAYMENT FAILED] due to request issue for contractID: ${summary.id}`);
      // addMessageToQueue(msgs.PAYMENT_FAILED_ERROR);

      setIsSubmitting(false);
    }
  };

  const handleSuccessfulCall = (paymentSource: string, contractID: string): void => {
    const sentryLogTag = `[POST ${paymentSource} PAYMENT SUBMISSION`;
    if (!props.hideSuccessAlert) {
      console.info(
        `${sentryLogTag} in mfeResponse, displaying success notification for contractID: ${contractID}`,
      );
      addSuccessToQueue(msgs.PAYMENT_SUCCESSFUL);
    }

    console.info(
      `${sentryLogTag} in mfeResponse, about to call props.onSuccess for contractID: ${contractID}`,
    );
    props.onSuccess(contractID);

    console.info(
      `${sentryLogTag} in mfeResponse, about to call closeDrawer for contractID: ${contractID}`,
    );
    props.closeDrawer();
  };

  const submitRequest = () => {
    fireGAEvent(ORDER__PAYMENT_CLICK('Submit Payment', props.sourcePage));
    if (validateForm()) {
      setIsSubmitting(true);
    } else {
      setIsSubmitting(false);
    }
  };

  const priceBreakdownRenderer = () => {
    const lineIemClassName = 'flex flex-row justify-between';

    return (
      <div id="price-breakdown" className="">
        {IsTheme(Theme.Ahs2024) && <span className='flex flex-col w-full divide-y divide-gray-300 border-t  border-gray-300' />}
        <REText variant={IsTheme(Theme.Ahs2024) ? 'caption' : 'heading-06'} className={`${IsTheme(Theme.Ahs2024) ? 'font-bold flex justify-between pt-2' : lineIemClassName}`}>
          <span>Plan Total:</span>
          <span id="price-breakdown--total">{toCurrency(props.totalPrice)}</span>
        </REText>
        {props.creditAmount > 0 && (
          <>
            {IsTheme(Theme.Ahs2024) && (
              <span className='pt-1 flex flex-col w-full divide-y divide-gray-300 border-t  border-gray-300' />
            )}
            <REText variant="caption" className={`${IsTheme(Theme.Ahs2024) ? 'font-bold flex justify-between pt-2' : lineIemClassName}`}>
              <span>Credit:</span>
              <span id="price-breakdown--credit">-{toCurrency(props.creditAmount)}</span>
            </REText>
          </>
        )}
        {props.discountAmount > 0 ? (
          <>
            {IsTheme(Theme.Ahs2024) && (
              <span className='mt-1 flex flex-col w-full divide-y divide-gray-300 border-t  border-gray-300' />
            )}
            <REText variant="caption" className={`${IsTheme(Theme.Ahs2024) ? 'font-bold flex justify-between pt-2' : lineIemClassName}`}>
              <span className={`${IsTheme(Theme.Ahs2024) ? '' : 'inline-flex'}`}>
                <DiscountLabel
                  label={props.militaryDiscountApplied ? 'Military Discount Applied' : undefined}
                />
              </span>
              <span id="price-breakdown--discount">-{toCurrency(props.discountAmount)}</span>
            </REText>
          </>
        ) : null
        }

        {
          IsTheme(Theme.Ahs2024) && (
            <span className='flex flex-col w-full divide-y divide-gray-300 border-t  border-gray-300' />
          )
        }
        <REText variant={IsTheme(Theme.Ahs2024) ? 'caption' : 'heading-06'}
          className={`${IsTheme(Theme.Ahs2024) ? 'flex justify-between pt-2 font-bold' : classNames([lineIemClassName, 'mt-1'])}`}>
          <span className={IsTheme(Theme.Ahs2024) ? '' : 'heading-06'}>Remaining Balance:</span>
          <span id="price-breakdown--balance">{toCurrency(props.contractBalance)}</span>
        </REText>
      </div >
    );
  };

  return (
    <>
      {props.contract && (
        <>
          <REText variant="heading-03" className="sm:pl-6 pb-5 h3">
            Make a Payment
          </REText>
          {/* <h1 className="sm:pl-6 pb-5 h3">Make a Payment</h1> */}
          <div className="sm-max:flex sm-max:flex-col sm:grid grid-cols-7 gap-4">
            {hasError && (
              <div className="col-start-1 row-start-1 row span-1 col-span-6">
                <Notification status="error" showStatusLabel={false}>
                  Here is some error text
                </Notification>
              </div>
            )}
            {!hasError && <div className="col-start-1 row-start-1 row span-1 col-span-6" />}

            {props.contractBalance <= 0 && (
              <div className="col-start-1 row-start-1 row span-1 col-span-3">
                <Notification status="warning">
                  A payment can not be made for a contract with a $0.00 balance.
                </Notification>
              </div>
            )}

            <div className="sm:pl-6 col-start-1 row-start-2 row span-1 col-span-3">
              <Radio
                label="Pay Full Amount"
                value="full"
                checked={fullAmntRadio}
                onChange={(e) => handleRadioPaymentChange(e)}
              />
            </div>

            {/* <div className="sm-max:flex flex-nowrap"> */}

            <div className="col-start-1 row-start-3 row span-1 col-span-5 flex">
              <div className="pt-4 sm:pl-6">
                <Radio
                  label={IsTheme(Theme.Ahs2024) ? 'Partial Amount' : 'Partial Payment'}
                  value="partial"
                  checked={partialAmntRadio}
                  onChange={(e) => handleRadioPaymentChange(e)}
                />
              </div>

              <div className="pl-4 sm:pl-6 w-3/5">
                <MaskedInput
                  id="stripe-partial-payment-amount"
                  formField={true}
                  placeholder="0.00"
                  inputMode="decimal"
                  unmask={true}
                  startEnhancer={<IconMoney color="gray" />}
                  maskOptions={{
                    mask: Number,
                    scale: 2,
                    signed: false,
                    padFractionalZeros: true,
                    radix: '.',
                    mapToRadix: ['.'],
                    min: MINIMUM_ALLOWED_PARTIAL_PAYMENT,
                    max: 9999,
                  }}
                  value={partialPaymenAmnt}
                  autoFocus={true}
                  onBlur={(input) => {
                    validateAndSetPartialPayment(input.target.value);
                  }}
                  // onChange={(event, value)=>
                  //             validateAndSetPartialPayment(value)
                  //
                  // }
                  error={partialPaymentErr.hasError && partialPaymentErr.errorMessage}
                  disabled={!partialAmntRadio}
                  label={IsTheme(Theme.Ahs2024) ? 'Partial Amount' : 'Partial Payment'}
                  hideLabel
                />
              </div>
            </div>

            <div className="order-first sm:col-start-6 row-start-1 row-span-5 col-span-2">
              <div className={!IsTheme(Theme.Ahs2024) ? 'sm:p-3 border-solid border-1 border-gray-400 px-4 py-2' : ''}>
                <Panel
                  border="0"
                  padding="none"
                  shadow={IsTheme(Theme.Ahs2024) ? true : false}
                  rounded={IsTheme(Theme.Ahs2024) ? 'xl' : 'md'}
                  className={IsTheme(Theme.Ahs2024) ? 'p-4' : ''}
                >
                  <ul>
                    <div >
                      <p className={`flex  ${IsTheme(Theme.Ahs2024) ? 'pl-3' : 'mt-2'}`}>
                        {!IsTheme(Theme.Ahs2024) && <IconPin size={22} />}
                        <REText as="strong" variant="heading-05" id='property_address'>
                          {addressToString(
                            props.contract?.summary?.address?.address1,
                            props.contract?.summary?.address?.address2,
                            props.contract?.summary?.address?.city,
                            props.contract?.summary?.address?.state,
                            props.contract?.summary?.address?.zip,
                          )}
                        </REText>
                      </p>
                    </div>
                    <div className="pl-3 mt-3">
                      <p>
                        <REText as="strong" variant="heading-06" id='init_agent'>
                          {props.contract.summary.agentName}
                        </REText>
                        <br />
                        <REText variant="caption" id='init_office'>{props.contract.summary.officeName}</REText>
                      </p>
                      <p className={IsTheme(Theme.Ahs2024) ? 'pt-4 pb-1' : ''}>
                        <REText variant="caption" id='contractNumber' className={`${IsTheme(Theme.Ahs2024) ? 'flex justify-between' : ''}`}><span className={IsTheme(Theme.Ahs2024) ? 'font-bold' : ''}>Order #:</span> <span>{props.contract.summary.id}</span></REText>
                        {!IsTheme(Theme.Ahs2024) && <br />}
                        <REText variant="caption" className={`${IsTheme(Theme.Ahs2024) ? 'flex justify-between pt-2' : ''}`}>
                          <span className={IsTheme(Theme.Ahs2024) ? 'font-bold' : ''}> Expires:</span> <span>{formatDateFromString(props.contract.summary.expirationDate)}</span>
                        </REText>
                        {!IsTheme(Theme.Ahs2024) && <br />}
                      </p>
                      {priceBreakdownRenderer()}
                    </div>
                  </ul>

                </Panel>
              </div>
            </div>

            <div className="mt-4 col-start-1 col-end-6">
              <StripeMFE
                handleMFEResponse={(value) =>
                  mfeResponse(value, populateCSCRequest(fullAmntRadio, partialPaymenAmnt))
                }
                contractId={props.contract.summary.id}
                isSubmitting={isSubmitting}
              />
            </div>

            <div className="col-start-1 row-start-5 col-end-6">
              <div className="mt-4 -mx-4 md:-mx-8">
                <div className='md:flex md:flex-wrap md:justify-start md:items-center w-full px-4 md:px-8 md:flex-row-reverse'>
                  <div className="sm-max:w-full">
                    <Button
                      label="Submit Payment"
                      size="medium"
                      labelAlign="center"
                      width="full"
                      disabled={props.contractBalance <= 0 ? true : isSubmitting}
                      onClick={() => submitRequest()}
                      id='submit-payment'
                    />
                  </div>
                  {!props.hideCancelButton && (
                    <div className="text-center sm-max:mt-4">
                      <Button
                        className="text-primary-400"
                        variant="ghost"
                        label="Cancel"
                        size="small"
                        onClick={() => props.closeDrawer()}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div >
        </>
      )
      }
    </>
  );
};

MakePaymentStripe.defaultProps = {
  hideCancelButton: false,
  closeDrawer: () => { },
};

export default MakePaymentStripe;
