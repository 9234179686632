import React from 'react';
import { PaddingOption, Panel } from '@ftdr/blueprint-components-react';
import CardTopStrip, { CardTopStripType } from '@components/card/CardTopStrip';
import { BDSMainColor } from '@constants/bds.constants';
import { classNames } from '@utils';
import { IsTheme, Theme } from '@app/core/featureToggle';

export enum CardCustomSize {
  DashboardSmall = 'dashboard-small',
  DashboardWide = 'dashboard-wide',
  DashboardFull = 'dashboard-full',
}

export interface CardCustomProps {
  id?: string;
  size: CardCustomSize;
  topStripType?: CardTopStripType;
  isCollapsed?: boolean;
  setCollapsed?: (state: boolean) => void;
  topStripTitle?: string;
  topStripColor?: BDSMainColor;
  padding?: PaddingOption;
  className?: string;
  style?: React.CSSProperties;
}

const paddingValues = {
  none: 'p-0',
  xs: 'p-1',
  sm: 'p-2',
  md: 'p-3',
  lg: 'p-4',
  xl: 'p-5',
};

const getPaddingClassNames = (padding: CardCustomProps['padding']) => {
  return paddingValues[padding];
};

const CardCustom: React.FC<CardCustomProps> = (props) => {
  const showCollapsed = CardTopStripType.CollapsingHeader && props.isCollapsed;
  const sizeClassNames =
    props.size === CardCustomSize.DashboardSmall && !showCollapsed
      ? 'dashboard-card-small'
      : props.size === CardCustomSize.DashboardSmall && showCollapsed
        ? 'dashboard-card-small-collapsed'
        : props.size === CardCustomSize.DashboardWide
          ? 'dashboard-card-wide'
          : '';

  return (
    <div
      id={props.id}
      className={classNames([sizeClassNames, props.className])}
      style={props.style}
    >
      <Panel
        border="0"
        shadow={true}
        className={classNames([
          'w-full',
          'h-full',
          !showCollapsed && props.size === CardCustomSize.DashboardSmall
            ? 'dashboard-card-small-min'
            : '',
        ])}
        padding={props.topStripType !== CardTopStripType.None ? 'none' : props.padding}
        rounded={IsTheme(Theme.Ahs2024) ? "xl" : "lg"}
      >
        {props.topStripType !== CardTopStripType.None && (
          <div className="h-full flex flex-col">
            <CardTopStrip
              type={props.topStripType}
              color={props.topStripColor}
              title={props.topStripTitle}
              isCollapsed={showCollapsed}
              onClick={() => props.setCollapsed?.(!props.isCollapsed)}
            />
            {!showCollapsed && (
              <div className={classNames(['h-full', getPaddingClassNames(props.padding)])}>
                {props.children}
              </div>
            )}
          </div>
        )}
        {props.topStripType === CardTopStripType.None && props.children}
      </Panel>
    </div>
  );
};

CardCustom.defaultProps = {
  padding: 'md',
  isCollapsed: false,
  className: '',
  topStripType: CardTopStripType.None,
  topStripColor: BDSMainColor.Primary,
  style: {},
};

export default CardCustom;
