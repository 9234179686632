import React, { Component } from 'react';
import { classNames, isMobileView } from '@utils';
import { useLocation } from 'react-router-dom';
import { getBrand } from '@helpers/brand.utils';
import CardOrderGrid from '@components/card/CardOrderGrid';
import TagGroup from '@components/tag/TagGroup';
import DrawerMakePaymentStripe from '@components/drawer/DrawerMakePaymentStripe';
import DrawerExtendListing from '@components/drawer/DrawerExtendListing';
import DrawerCancelOrder from '@components/drawer/DrawerCancelOrder';
import DrawerNotifications from '@components/drawer/DrawerNotifications';
import ListFiltering from '@components/misc/ListFiltering';
import { PER_PAGE_FIFTEEN_RECORDS } from '@constants/pagination-constants';
import * as paginationActions from '@services/pagination/Pagination';
import ProfileContext from '../../context/ProfileContext/index';
import ContractApi, { ContractApiSuppressErrors } from '@apis/contract.api';
import ProfileModel from '@app/models/profile.model';
import {
  AgentSearchRecord,
  Contract,
  ContractBalance,
  Office,
  REOrder,
  REOrderSearchMetaStatus,
} from '@apis/models';
import { addressToString } from '@services/helpers';
import { FilterChangeEvent, FilterTagItem, OrderSort } from '@components/misc/misc.models';
import { FilterOperation, FilterType, RealEstateStatus } from '@constants/dashboardFilters';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  MY_ORDER_URL_AUDIT_REQUEST_STATUS,
  ORDER__PAYMENT_CLICK,
  ORDER__REQUEST_SERVICE,
  RENEWALS_SELECTED_SEND_RENEWAL_EMAIL_MY_ORDERS,
} from '@constants/ga-events.constants';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import { getContractRecipientsForNotifications, GetRequestServiceUrl } from '@helpers/order.utils';
import { FILTER_BY_NUMBER_NAME_ADDRESS, TOO_MANY_OFFICES } from '@constants/formField-constants';
import { isCCAdmin, isInternalUser } from '@helpers/profile.utils';
import { MerchandiseWidget } from '@components/misc/MerchandiseWidget';
import ZestyApi from '@services/apis/zesty.apis';
import { MerchandisingContent } from '@services/apis/models/zesty.api.model';
import AtypicallySlowOverlay from '@components/loader/AtypicallySlowOverlay';
import {
  Badge,
  Button,
  Dialog,
  IconWarningCircledFull,
  Link,
  Pagination,
  Table,
  Tag,
} from '@ftdr/blueprint-components-react';
import SearchBar from '@components/input/SearchBar';
import { formatDateFromString } from '@helpers/utils';
import { orderStatusDictionary } from '@constants/dictionaries';
import ActionsDropdown from '@components/button/ActionsDropdown';
import msgs from '@app/locales/en';
import { ContentBox } from '@components/layout/ContentBox';
import { IconTypes } from '@components/card/CardFilterBar';
import { isEmpty } from 'lodash';
import { openOrderDrawer } from '@app/core/GlobalDrawerOrder';
import {
  refreshContractData,
  RefreshContractServiceSubscriber,
  subscribeToRefreshedContracts,
  unsubscribeRefreshedContracts,
} from '@app/core/refreshContract.service';
import { StatusMenuType } from '@components/filter/OrderStatusFilter';
import ModalAssociateAgent from '@components/modal/ModalAssociateAgent';
import { MILITARY_DISCOUNT, ROLE_TYPE_REAL_ESTATE_AGENT } from '@constants/newOrder-constants';
import deepLinkService from '@services/deepLink/deepLink.service';
import { category } from '@services/deepLink/deepLink.models';
import {
  CannotAddCooperatingType,
  ModalCannotAddCooperating,
} from '@components/modal/ModalCannotAddCooperating';
import { Represents } from '@app/models/order/order.model';
import OrdersActionSwitcher, { OrdersActionSearchType } from './OrdersActionSwitcher';
import { Features, IsFeatureEnabled, IsTheme, Theme } from '@app/core/featureToggle';
import DrawerSendRenewal from '@components/drawer/DrawerSendRenewal';
import Path from '@constants/paths';
import { Brand } from '@constants/brands';
import REText from '@components/wrappedBDS/REText';

// ///////////////////////////////////////////////////////////////////////////////////
/** Timeout period for notifications to be automatically closed */
const NOTIFICATION_TIMEOUT = 3500;
/** # of grid cards to display while on load */
const SKELETON_LOAD_CNT_GRID = 6;

/** Contract Pagination Count */
const PER_PAGE_RECORDS = PER_PAGE_FIFTEEN_RECORDS;
const ATYPICALLY_SLOW_OVERLAY_THRESHOLD = 500; // .5 seconds

let renderFastTimeout;

const noLoadedTooltips = [{ label: 'No items loaded', onClick: null, id: 'no-items-loaded' }];

interface OrdersState {
  // summary view
  summaryView: REOrder[];
  orderSort: OrderSort;

  // Pagination
  filters: {
    defaultOfficeList: Office[];
    filteredOfficeList: Office[];
  };
  activePage: number;
  pagination: {
    mainContractList: Contract[];
    totalPages: number;
    isFilteredList: boolean;
    beforeFilterActivePage: number;
  };

  // Drawer
  selectedContract: Contract;
  isMakePaymentDrawerActiveStripe: boolean;
  isExtendListingDrawerActive: boolean;
  isCancelOrderDrawerActive: boolean;
  isNotificationsDrawerActive: boolean;
  isRenewalDrawerActive: boolean;

  // Other
  isNotificationActive: boolean;
  contractPaymentBalance: ContractBalance;
  hasTooManyOffices: boolean;
  isAtypicallySlow: boolean;

  // merchandising
  merchandising: {
    isLoading: boolean;
    isMerchandiseWidgetActive: boolean;
    merchandisingContent: MerchandisingContent;
  };

  // config
  showFilterModal: boolean;

  isAssociateModalActive: boolean;
  isCannotAddCooperatingModalActive: boolean;
  cannotAddCooperatingReason: CannotAddCooperatingType;
  contractForModal: any;
}

interface OrdersProps {
  contracts: Contract[];
  isLoading: boolean;
  isLoadingContracts: boolean;
  profile: ProfileModel;
  offices: Office[];
  agents: AgentSearchRecord[];
  summaryView: REOrder[];
  filterTagItems: FilterTagItem[];
  totalOrders: number;
  totalStatus: REOrderSearchMetaStatus;
  totalAwaitingWlkSubmission: number;
  searchText: string;
  officesToDisplayMerchandiseBanner: Office[];

  handleCSCACHPaymentCall: (request: any, contractID: string) => Promise<any>;
  handleCSCCCPaymentCall: (request: any, contractID: string) => Promise<any>;
  onExtendListing: (contract: Contract) => Promise<boolean>;
  onFilterChange: (event: FilterChangeEvent, clearFilters?: boolean) => boolean;
  onFilterBatchChange: (events: FilterChangeEvent[]) => boolean;
  onSearchFilterChange: (value: string) => void;
  updateTab: (tabName: OrdersActionSearchType) => void;

  hasTooManyOffices: boolean;
}

// React Navi currently does not support router/navigation information for class components.
// Workaround is to wrap the class component with a functional component as a measure.
// See https://github.com/frontarm/navi/issues/80
interface OrdersPropsWithRouteHook extends OrdersProps {
  location;
  isMobile: boolean;
}

// React Navi currently does not support router/navigation information for class components.
// Workaround is to wrap the class component with a functional component as a measure.
// See https://github.com/frontarm/navi/issues/80
function OrdersTemplate(props: OrdersProps) {
  const location = useLocation();
  return <OrdersTemplateWithRouteHook {...props} location={location} isMobile={isMobileView()} />;
}

// TODO Componentize DrawerOrder and its dependency Drawers
export class OrdersTemplateWithRouteHook extends Component<OrdersPropsWithRouteHook, OrdersState> {
  static contextType = ProfileContext;

  profile: ProfileModel;

  isUpForRenewalVisible = (profile) => {
    return (
      ['RealEstateAgent', 'RealEstateAdmin', 'Broker'].includes(profile.roleIDType) &&
      IsFeatureEnabled(Features.RenewalEmails) &&
      getBrand() !== Brand.HSA
    );
  };

  /** Add tooltip actions based off of contract and location */
  tooltipActions = (contractID: number, fromOrderDrawer = false) => {
    const contract = this.state.summaryView?.find((c) => c.tableId === contractID);
    if (!contract) return noLoadedTooltips;

    const details = this.props.contracts?.find((c) => c.summary.id === String(contract.id));
    if (!details) return noLoadedTooltips;

    const items: { label: string; onClick: () => void; disabled?: boolean; id?: string }[] = [
      {
        label: 'Send Renewal Discount',
        onClick: () => {
          fireGAEvent(RENEWALS_SELECTED_SEND_RENEWAL_EMAIL_MY_ORDERS);
          this.setState({
            isRenewalDrawerActive: true,
            selectedContract: details,
          });
        },
        disabled: !(
          details.features.upForRenewal &&
          details.features.upForRenewal.canSendPromoEmail &&
          this.isUpForRenewalVisible(this.props.profile)
        ),
        id: 'send_renewal_discount',
      },
      {
        label: 'View Order',
        onClick: () => this.SummaryViewOrderHandler(contract.id),
        disabled: fromOrderDrawer, // Dont open the drawer again,
        id: 'view_order',
      },
      {
        label: 'Make a Payment',
        onClick: () => {
          this.setState({ selectedContract: details });
          this.openPaymentHandler(contract);
        },
        disabled: !(
          details.features.makePayment &&
          details.features.makePayment.isPayable &&
          !isInternalUser(this.props.profile)
        ),
        id: 'make_payment_action',
      },
      {
        label: 'Extend Listing',
        onClick: () => {
          this.setState({
            selectedContract: details,
            isExtendListingDrawerActive: true,
          });
        },
        disabled: !(details.features.extendListing && details.features.extendListing.canExtend),
        id: 'extend_listing_action',
      },
      {
        label: 'Documents',
        onClick: () => {
          this.setState({
            selectedContract: details,
            isNotificationsDrawerActive: true,
          });
        },
        disabled: false,
        id: 'documents_action',
      },
      {
        label: 'Request Service',
        onClick: () => {
          fireGAEvent(ORDER__REQUEST_SERVICE(details.detail.id));
          window.open(GetRequestServiceUrl(contract), '_blank');
        },
        disabled: false,
        id: 'request_service_action',
      },
      {
        label: 'WarrantyLink',
        onClick: () => {
          this.setState({ selectedContract: details });
          window.location.assign('/warranty-link');
        },
        disabled: !contract.awaitingWlkSubmission,
        id: 'warrantylink_action',
      },
      {
        label: 'Cancel Order',
        onClick: () => {
          this.setState({
            selectedContract: details,
            isCancelOrderDrawerActive: true,
          });
        },
        disabled: !details.features.cancelListing?.isCancellable,
        id: 'cancel_order_action',
      },
    ];

    // Remove any items that are disabled. Done this way so we can enhance in future
    // to disable instead of remove, since the ButtonDropdown does not accept disabled as prop value
    // If disabled added to ButtonDropdown, remove map and filter.
    return items
      .filter((i) => !i.disabled)
      .map((i) => ({ label: i.label, onClick: i.onClick, id: i.id }));
  };

  getNoticeTypes = () => {
    if (!this.state.selectedContract) return {};
    const { projectedClosingDate } = this.state.selectedContract.detail.importantDates;
    return {
      Confirmation:
        this.state.selectedContract.summary && this.state.selectedContract.summary['status'] === 'L'
          ? true
          : true,
      Invoice: !!projectedClosingDate,
    };
  };

  getEmailAddresses = () => {
    if (!this.state.selectedContract) return {};
    return getContractRecipientsForNotifications(this.state.selectedContract, this.profile);
  };

  // should we show the current merchandising campaign to the customer?
  // this is hardcoded for now - TODO find better way to manage this selection (future story)
  // this was set up specifically for NHD (limiting to CA offices)
  // if we use this again, we need to update the logic
  showMerchandisingForCustomer = (props: OrdersPropsWithRouteHook = this.props): boolean => {
    return false; //disable (hardcoded) see comment above
    // return props.officesToDisplayMerchandiseBanner?.some((office) => {
    //   return office?.address?.state == 'CA';
    // });
  };

  isMerchandisingActive = () => {
    return (
      this.state.merchandising.isMerchandiseWidgetActive &&
      this.state.merchandising.merchandisingContent.isActive
    );
  };

  /** show the widget if it is in the middle of loading or if it is active */
  showMerchandisingWidget = () => {
    return (
      this.showMerchandisingForCustomer() &&
      (this.state.merchandising.isLoading || this.isMerchandisingActive())
    );
  };

  columns = [
    {
      Header: <REText variant="heading-06">Covered Property Address</REText>,
      accessor: 'address', // accessor is the "key" in the data
      Cell: ({ value }) => (
        <REText variant="caption">
          {addressToString(value.address1, value.address2, value.city, value.state, value.zip)}
        </REText>
      ),
    },
    {
      Header: <REText variant="heading-06">Order #</REText>,
      accessor: 'id',
      Cell: ({ value }) => (
        <Link
          className={IsTheme(Theme.Ahs2024) ? 'font-bold' : undefined}
          color="interactive"
          onClick={() => {
            this.SummaryViewOrderHandler(value);
          }}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: <REText variant="heading-06">Expiration</REText>,
      accessor: 'expirationDate',
      Cell: ({ value }) => <REText variant="caption">{formatDateFromString(value)}</REText>,
    },
    {
      Header: <REText variant="heading-06">Agent</REText>,
      accessor: 'initiatingAgentName',
      Cell: ({ value }) => <REText variant="caption">{value}</REText>,
    },
    {
      Header: <REText variant="heading-06">Office</REText>,
      accessor: 'initiatingOfficeName',
      Cell: ({ value }) => <REText variant="caption">{value}</REText>,
    },
    {
      Header: <REText variant="heading-06">Status</REText>,
      accessor: 'realEstateStatus',
      Cell: ({ value }) => this.renderStatus(value),
    },
    {
      Header: '',
      accessor: 'tableId',
      disableSortBy: true,
      Cell: ({ value }) => <ActionsDropdown menuItems={this.tooltipActions(value)} />,
    },
  ];

  constructor(props) {
    super(props);

    // Set default
    this.state = {
      summaryView: [],
      orderSort: {
        sortBy: 'id',
        asc: false,
      },

      filters: {
        defaultOfficeList: [],
        filteredOfficeList: [],
      },
      activePage: 1,
      pagination: {
        mainContractList: undefined,
        totalPages: 0,
        isFilteredList: false,
        beforeFilterActivePage: 0,
      },

      selectedContract: undefined,
      isMakePaymentDrawerActiveStripe: false,
      isExtendListingDrawerActive: false,
      isCancelOrderDrawerActive: false,
      isNotificationsDrawerActive: false,
      isRenewalDrawerActive: false,

      isNotificationActive: false,
      contractPaymentBalance: null,
      hasTooManyOffices: false,
      isAtypicallySlow: false,

      merchandising: {
        isLoading: false,
        isMerchandiseWidgetActive: false,
        merchandisingContent: {
          isActive: false,
          copyText: '',
          merchandisingUrl: '',
          imageIconUrl: '',
          navIconUrl: '',
          ctaText: undefined,
        },
      },
      showFilterModal: false,
      isAssociateModalActive: false,
      isCannotAddCooperatingModalActive: false,
      cannotAddCooperatingReason: CannotAddCooperatingType.CooperatingExists,
      contractForModal: {},
    };

    this.initView = this.initView.bind(this);
    this.initContractDataPagination = this.initContractDataPagination.bind(this);
    this.initContractDataPagination = this.initContractDataPagination.bind(this);
    this.initMerchandising = this.initMerchandising.bind(this);
    this.setNotificationTimeout = this.setNotificationTimeout.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.onFilterTextChangeHandler = this.onFilterTextChangeHandler.bind(this);
    this.makeACHCSCPaymentCall = this.makeACHCSCPaymentCall.bind(this);
    this.makeCCCSCPaymentCall = this.makeCCCSCPaymentCall.bind(this);
    this.handleDrawerMakePaymentStripeClose = this.handleDrawerMakePaymentStripeClose.bind(this);
    this.onExtendListing = this.onExtendListing.bind(this);
    this.SummaryViewOrderHandler = this.SummaryViewOrderHandler.bind(this);
    this.onContractUpdate = this.onContractUpdate.bind(this);
    this.updateMerchandising = this.updateMerchandising.bind(this);
  }

  componentDidMount() {
    this.initView();
    this.initContractDataPagination();
    this.initMerchandising();
    this.renderOverlayIfLoadingTooSlow(this.props.isLoading);

    subscribeToRefreshedContracts(
      RefreshContractServiceSubscriber.OrdersTemplate,
      this.onContractUpdate,
    );
    this.loadOrderDetailsIfContractIdInUrl();
    this.updateOfficeFilterList();
  }

  public loadOrderDetailsIfContractIdInUrl = async () => {
    const splitPath = window.location.pathname.split('/');
    if (splitPath.length === 3) {
      const contractId = parseInt(splitPath[2], 10);
      if (Number.isNaN(contractId)) {
        console.error('non-numerical contract id:', contractId);
        fireGAEvent(MY_ORDER_URL_AUDIT_REQUEST_STATUS(false, window.location.href));
        return;
      }

      if (
        this.profile.roleIDType === ROLE_TYPE_REAL_ESTATE_AGENT &&
        deepLinkService?.actions?.deepLinkCategory === category.missingCooperatingAgent
      ) {
        const contractDetails = await ContractApiSuppressErrors.getContractDetails(
          [contractId.toString()],
          true,
          true,
        );
        if (!contractDetails || !contractDetails.length) {
          window.location.assign(Path.Dashboard);
        }

        //TODO A future story will re-add the following:
        //fireGAEvent(EMAIL_CAMPAIGNS_CATEGORY_REQUEST_STATUS(!!contractDetails, window.location.href));

        const initiatingAgentId =
          contractDetails?.[0]?.detail?.initiatingOfficeAgent?.agent?.realEstateAgentID;
        const initiatingRepresents =
          contractDetails?.[0]?.detail?.initiatingOfficeAgent?.represents;
        const cooperatingAgentId =
          contractDetails?.[0]?.detail?.cooperatingOfficeAgent?.agent?.realEstateAgentID;
        const contractStatus = contractDetails?.[0]?.summary.status;

        const currentUserAlreadyOnOrder =
          cooperatingAgentId === this.profile.roleID || initiatingAgentId === this.profile.roleID;
        const cooperatingAgentExists =
          cooperatingAgentId || initiatingRepresents === Represents.Both;

        if (currentUserAlreadyOnOrder) {
          this.SummaryViewOrderHandler(contractId);
          return;
        }

        let cannotAddReason;

        if (cooperatingAgentExists) cannotAddReason = CannotAddCooperatingType.CooperatingExists;

        switch (contractStatus) {
          case 'A':
            cannotAddReason = CannotAddCooperatingType.ContractActive;
            break;
          case 'X':
            cannotAddReason = CannotAddCooperatingType.CancelledOrder;
            break;
          case 'C':
            cannotAddReason = CannotAddCooperatingType.CancelledContract;
            break;
          default:
            break;
        }

        if (cannotAddReason) {
          this.setState({
            isCannotAddCooperatingModalActive: true,
            contractForModal: contractDetails?.[0]?.detail,
            cannotAddCooperatingReason: cannotAddReason,
          });
          return;
        }

        this.setState({
          isAssociateModalActive: true,
          contractForModal: contractDetails?.[0]?.detail,
        });
      } else {
        // Using the faster elastic call to validate contract id (we don't need the details)
        const [promise] = ContractApi.searchGlobalContracts({
          query: contractId.toString(),
          limit: 1,
        });
        const response = await promise();
        //TODO A future story will re-add the following:
        //fireGAEvent(EMAIL_CAMPAIGNS_CATEGORY_REQUEST_STATUS(!!response.orders.length, window.location.href));

        this.SummaryViewOrderHandler(contractId);
      }
    }
  };

  componentWillUnmount() {
    unsubscribeRefreshedContracts(RefreshContractServiceSubscriber.OrdersTemplate);
  }

  componentDidUpdate(prevProps: OrdersProps, prevState: OrdersState) {
    const { selectedContract } = this.state;
    // TODO: These logs are here temporarily to observe update performance. Remove once completed.
    // Set a timeout if the notification state was changed
    if (this.state.isNotificationActive !== prevState.isNotificationActive) {
      console.log('trigger update, notification timeout');
      this.setNotificationTimeout();
    }
    // Update contracts if it was loaded or if pagination changes
    if (this.props.summaryView !== prevProps.summaryView) {
      console.log('trigger update, init pagination');
      this.initContractDataPagination();
    }
    // Update the page if pagination changes
    if (this.state.activePage !== prevState.activePage) {
      console.log('trigger update, update pagination');
      this.initContractDataPagination();
    }

    // Update the office list for filters
    if (this.props.offices !== prevProps.offices) {
      console.log('trigger update, offices loaded');
      this.updateOfficeFilterList();
      this.updateMerchandising();
    }

    if (this.props.isLoading !== prevProps.isLoading) {
      this.renderOverlayIfLoadingTooSlow(this.props.isLoading);
    }
  }

  /** Initialize the view depending on user role */
  initView(): void {
    const { profile } = this.context;
    this.profile = profile;
  }

  /** Initialize the contract data for pagination */
  initContractDataPagination(): void {
    const { contracts, summaryView } = this.props;
    const { pagination } = this.state;
    if (summaryView) {
      const totalPageCount = paginationActions.setTotalPageCount(
        this.props.totalOrders,
        PER_PAGE_RECORDS,
      );
      this.setState({
        pagination: {
          ...pagination,
          mainContractList: contracts,
          totalPages: totalPageCount,
        },
        summaryView: summaryView.slice(0, PER_PAGE_RECORDS),
      });
    }
  }

  initMerchandising(): void {
    if (getBrand().toLowerCase() == 'hsa') {
      return;
    }
    const { merchandising } = this.state;
    this.setState({
      merchandising: {
        ...merchandising,
        isLoading: true,
      },
    });
    ZestyApi.GetMerchandiseData()
      .then((zestyContent) => {
        if (zestyContent == null) {
          console.warn('no merchandising information returned.');
          return;
        }
        const { merchandising } = this.state;
        this.setState({
          merchandising: {
            ...merchandising,
            isLoading: false,
            merchandisingContent: zestyContent,
          },
        });
        this.updateMerchandising();
      })
      .catch(() => {
        console.warn('getting merchandising information failed.');
        const { merchandising } = this.state;
        this.setState({
          merchandising: {
            ...merchandising,
            isLoading: false,
          },
        });
      });
  }

  renderOverlayIfLoadingTooSlow(isLoading: boolean): void {
    if (isLoading) {
      renderFastTimeout = setTimeout(() => {
        this.setState({ isAtypicallySlow: true });
      }, ATYPICALLY_SLOW_OVERLAY_THRESHOLD);
    } else {
      clearTimeout(renderFastTimeout); // cancel any queued renderings
      this.setState({ isAtypicallySlow: false });
    }
  }

  updateMerchandising(): void {
    if (this.showMerchandisingForCustomer(this.props)) {
      const { merchandising } = this.state;
      this.setState({
        merchandising: {
          ...merchandising,
          isMerchandiseWidgetActive: true,
        },
      });
    }
  }

  // Updates the dropdown for filtering by office
  updateOfficeFilterList(): void {
    const { offices } = this.props;
    if (offices) {
      this.setState({ filters: { defaultOfficeList: offices, filteredOfficeList: offices } });
    }
  }

  /** Automatically times out notifications */
  setNotificationTimeout(): void {
    if (this.state.isNotificationActive) {
      setTimeout(() => {
        this.setState({ isNotificationActive: false });
      }, NOTIFICATION_TIMEOUT);
    }
  }

  /** Adds a filter into filter list.
   *  TODO: label param is temporary to not break existing functionality.
   *  For new implementations, set label as undefined and event to hold the label.
   *  For existing implementation, the first label param will be set into a default event and not truly handled properly, but at least won't cause any errors.
   */
  addFilter(
    label: string,
    event: FilterChangeEvent = {
      label,
      operation: FilterOperation.ADD,
      payload: undefined,
      type: undefined,
    },
    clearFilters = false,
  ): void {
    if (event.type && event.payload) {
      console.log(event);
      this.props.onFilterChange(event, clearFilters);
      this.setState({ activePage: 1 });
    }
  }

  /** Removes a filter from the list */
  removeFilter(tagItem: FilterTagItem): void {
    // TODO: Move this logic to <Tag> code directly so it can manipulate classes there
    // e.target.classList.add("transition", "opacity-0");
    // Opacity code is removed for now since the filter list is controlled on dashboard, so element gets destroyed immediately instead of after a timeout.

    this.props.onFilterChange({ ...tagItem, operation: FilterOperation.REMOVE });
    this.setState({ activePage: 1 });
  }

  onFilterTextChangeHandler(event): void {
    const { contracts } = this.props;

    if (event.target.value !== '') {
      const searchText = event.target.value.toLowerCase();
      this.setState({ activePage: 1 });
      contracts.filter((contract) => {
        const {
          address: { city, state, address1, zip },
          id,
          agentName,
          officeName,
        } = contract.summary;
        const addressString = `${address1}, ${city}, ${state} ${zip}`;
        if (
          addressString.toLowerCase().includes(searchText) ||
          id.includes(searchText) ||
          agentName.includes(searchText) ||
          officeName.toLowerCase().includes(searchText)
        ) {
          return contract;
        }
      });
    }
  }

  // Added SummaryViewOrderHandler just to avoid the breaking of existing functionality
  async SummaryViewOrderHandler(contractID: number): Promise<void> {
    openOrderDrawer({
      contractId: `${contractID}`,
    });
  }

  showMakePaymentButton(item) {
    const details = this.props.contracts?.find((c) => c.summary.id === String(item.id));
    if (!details) return;
    return (
      details.features &&
      details.features.makePayment.isPayable &&
      item.realEstateStatus === RealEstateStatus.PAYMENT_DUE &&
      !isInternalUser(this.props.profile)
    );
  }

  openPaymentHandler(contract: REOrder): void {
    const details = this.props.contracts?.find((c) => c.summary.id === String(contract.id));
    if (!details) return;
    ContractApi.getContractBalance(contract.id.toString())
      .then((res) => {
        if (!res) {
          useGlobalAlert().addWarningToQueue(msgs.FAILED_TO_LOAD_CONTRACT_BALANCE);
        } else {
          this.setState({
            selectedContract: details,
            contractPaymentBalance: res,
            isMakePaymentDrawerActiveStripe: true,
          });
          fireGAEvent(ORDER__PAYMENT_CLICK('Make Payment', 'My Orders page'));
        }
      })
      .catch(() => {
        console.log('exception');
      });
  }

  makeACHCSCPaymentCall(request, contractId) {
    return this.props.handleCSCACHPaymentCall(request, contractId);
  }

  makeCCCSCPaymentCall(request, contractId) {
    return this.props.handleCSCCCPaymentCall(request, contractId);
  }

  handleDrawerMakePaymentStripeClose() {
    this.setState({ isMakePaymentDrawerActiveStripe: false });
  }

  /** Extend listing of a contract. Only when successful, the drawer will close. */
  onExtendListing(contract: Contract): void {
    this.props.onExtendListing(contract).then((success) => {
      if (success) {
        this.setState({ isExtendListingDrawerActive: false });
        this.refreshContract(contract.summary.id);
      }
    });
  }

  /** Removes certain tags from display in the filter */
  filtersForDisplay(filters: FilterTagItem[]): FilterTagItem[] {
    const excludeFilterTypes = ['orderSort'];
    /** Prevents RE agents from clearing the filter showing only their contracts */
    if (this.props.profile.roleIDType === 'RealEstateAgent') {
      excludeFilterTypes.push('office', 'agent');
    }
    return filters.filter((tag) => !excludeFilterTypes.includes(tag.type));
  }

  handleOrderCancelled = (contractId: string) => {
    this.setState({ isCancelOrderDrawerActive: false });
    this.refreshContract(contractId);
  };

  renderStatus = (status: RealEstateStatus) => {
    const warning = [
      RealEstateStatus.EXPIRED,
      RealEstateStatus.EXPIRING,
      RealEstateStatus.PAYMENT_DUE,
      RealEstateStatus.PAYMENT_PAST_DUE,
      RealEstateStatus.AWAITING_WL_SUBMISSION,
    ].includes(status);

    const displayStatus = orderStatusDictionary[status];

    return warning ? (
      <REText color="secondary" className="font-bold-override flex items-center">
        <IconWarningCircledFull size={16} className="mr-1" />
        {displayStatus}
      </REText>
    ) : (
      <REText>{displayStatus}</REText>
    );
  };

  /** When contract in param updates, trigger a refresh on the contract's data to reload it.
   * If the selected contract matches with the updated contract, then update the selected contract as well.
   */
  onContractUpdate(contracts: Contract[]): void {
    if (this.state.selectedContract && contracts?.length > 0) {
      const contract = contracts?.find(
        (c) => c.summary.id === this.state.selectedContract.summary.id,
      );
      if (contract) {
        this.setState({ selectedContract: contract });
      }
    }
  }

  onSearchTextChange(value: string) {
    this.setState({ activePage: 1 });
    this.props.onSearchFilterChange(value);
  }

  filterWarrantyLinkOffices = (officesToFilter) => {
    return officesToFilter.filter((office) => office.warrantyLinkEligible);
  };

  onCategoryChange = (shouldFilterOutWLOffices: boolean) => {
    if (shouldFilterOutWLOffices) {
      const wlkOffices = this.filterWarrantyLinkOffices(this.state.filters.defaultOfficeList);
      this.setState({ filters: { ...this.state.filters, filteredOfficeList: wlkOffices } });
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          filteredOfficeList: this.state.filters.defaultOfficeList,
        },
      });
    }
  };

  onPageChange = (pageNumber: number) => {
    if (this.state.activePage !== pageNumber) {
      this.addFilter(undefined, {
        label: undefined,
        operation: FilterOperation.PAGE_CHANGE,
        type: FilterType.PAGE,
        payload: { page: pageNumber - 1 },
      });

      this.setState({ activePage: pageNumber });
    }
  };

  getListFiltering = () => {
    return (
      <ListFiltering
        id="orders-container--filter-dropdowns"
        addFilter={this.addFilter}
        applyFilters={(events) => this.props.onFilterBatchChange(events)}
        onFilterTextChangeHandler={(e) => this.onFilterTextChangeHandler(e)}
        officeList={this.state.filters.filteredOfficeList}
        agentList={this.props.agents}
        userRoleID={this.props.profile.roleID}
        userRoleIDType={this.props.profile.roleIDType}
        activeFilters={this.props.filterTagItems}
        statusAlerts={this.props.totalStatus}
        isMobile={this.props.isMobile}
        totalAwaitingWlkSubmission={this.props.totalAwaitingWlkSubmission}
        statusMenuType={StatusMenuType.Orders}
      />
    );
  };

  translateWLKStatus = (order: REOrder) => {
    /**
     * This is going to overwrite the other status displayed in the table.
     * Because of this, only display WLK Eligible on active contracts.
     */
    //Commenting out this temporarely for <ARE-9853></ARE-9853>
    // if (order.awaitingWlkSubmission && order.realEstateStatus === RealEstateStatus.ACTIVE) {
    //   return RealEstateStatus.AWAITING_WL_SUBMISSION;
    // }
    return order.realEstateStatus;
  };

  getFilterBadge = () => {
    const filterCount = this.props.filterTagItems.filter((item) => !isEmpty(item.label)).length; // some filters we don't display to the user (such as orderSort) and don't want to include them as a part of the count, this accounts for that

    return filterCount ? (
      <Badge
        color="interactive"
        size={IsTheme(Theme.Ahs2024) ? 'large' : 'small'}
        maxCount={99}
        className="ml-1-important"
      >
        {filterCount.toString()}
      </Badge>
    ) : null;
  };

  refreshContract = (contractID: string): void => {
    refreshContractData({
      contractId: contractID,
    });
  };

  render() {
    return (
      <div className="flex flex-row py-0">
        {/* FILTER DROPDOWNS */}
        <div className="flex-1">
          <ContentBox
            title="My Orders"
            headerFullWidth={IsTheme(Theme.Ahs2024) ? true : false}
            leftContent={
              !this.props.isMobile && (
                <div className="w-48 min-w-48 mr-4">
                  <REText variant="heading-06" className="my-2">
                    Filter Orders
                  </REText>
                  {this.getListFiltering()}
                </div>
              )
            }
            titleRightContent={
              this.showMerchandisingWidget() && (
                <MerchandiseWidget
                  loading={this.state.merchandising.isLoading}
                  htmlText={this.state.merchandising.merchandisingContent.copyText}
                  navigationUrl={this.state.merchandising.merchandisingContent.merchandisingUrl}
                  imageIconUrl={this.state.merchandising.merchandisingContent.imageIconUrl}
                  navIconUrl={this.state.merchandising.merchandisingContent.navIconUrl}
                  ctaText={this.state.merchandising.merchandisingContent.ctaText}
                />
              )
            }
          >
            <div id="orders-container" className="relative px-0 pt-0 pb-6">
              {/* FILTER BUTTON OR TAGS + SUBMITTED TAB */}
              <div id="orders-container--tag-group" className="mb-4 sm:mt-3">
                {this.props.isMobile ? (
                  <>
                    <Button
                      id="filter-orders-button"
                      label="Filter Orders"
                      shape={IsTheme(Theme.Ahs2024) ? 'pill' : 'rounded'}
                      variant="outlined"
                      endIcon={this.getFilterBadge()}
                      onClick={() => this.setState({ showFilterModal: true })}
                      width="full"
                      labelAlign="center"
                    />
                    {isCCAdmin(this.props.profile) &&
                      IsFeatureEnabled(Features.CCAdminAbandonedOrders) && (
                        <OrdersActionSwitcher
                          id="orders-action-submitted"
                          searchType={OrdersActionSearchType.Submitted}
                          onChangeSearchType={this.props.updateTab}
                        />
                      )}
                    {!isCCAdmin(this.props.profile) &&
                      IsFeatureEnabled(Features.MyOrdersActionSwitcher) && (
                        <OrdersActionSwitcher
                          id="orders-action-submitted"
                          searchType={OrdersActionSearchType.Submitted}
                          onChangeSearchType={this.props.updateTab}
                        />
                      )}
                  </>
                ) : (
                  <>
                    <TagGroup>
                      <div>
                        <div className="float-left">Filtered by:</div>
                        <div className="items-center flex flex-wrap">
                          {this.filtersForDisplay(this.props.filterTagItems).map((item, idx) => (
                            <Tag
                              id={`tag-${idx}`}
                              key={item.label}
                              color="interactive"
                              removable
                              onRemove={() => {
                                this.removeFilter(item);
                              }}
                              className="ml-4"
                            >
                              {item.label}
                            </Tag>
                          ))}
                        </div>
                      </div>
                    </TagGroup>

                    {isCCAdmin(this.props.profile) &&
                      IsFeatureEnabled(Features.CCAdminAbandonedOrders) && (
                        <OrdersActionSwitcher
                          id="orders-action-submitted"
                          searchType={OrdersActionSearchType.Submitted}
                          onChangeSearchType={this.props.updateTab}
                        />
                      )}
                    {!isCCAdmin(this.props.profile) &&
                      IsFeatureEnabled(Features.MyOrdersActionSwitcher) && (
                        <OrdersActionSwitcher
                          id="orders-action-submitted"
                          searchType={OrdersActionSearchType.Submitted}
                          onChangeSearchType={this.props.updateTab}
                        />
                      )}
                  </>
                )}
              </div>

              <div className="orders-container-box">
                {/* SEARCH FILTER + VIEW TOGGLE */}
                <div
                  id="orders-container--search-container"
                  className={classNames([
                    IsTheme(Theme.AhsWebApp) ? 'card' : '',
                    !this.props.isMobile && 'block',
                  ])}
                >
                  <SearchBar
                    searchText={this.props.searchText}
                    onChange={(value) => this.onSearchTextChange(value)}
                    icon={IconTypes.Filter}
                    placeHolder={FILTER_BY_NUMBER_NAME_ADDRESS}
                    showClearButton={true}
                    outerCSS="p-3"
                  />
                </div>

                {!this.state.isAtypicallySlow && (
                  <>
                    {/* TABLE VIEW */}
                    {!this.props.isMobile && (
                      <div
                        id="orders-container--table-container"
                        className={IsTheme(Theme.Ahs2024) ? 'sm-max:hidden' : 'card sm-max:hidden'}
                      >
                        <Table
                          {...{
                            data: this.state.summaryView.map((c) => ({
                              ...c,
                              realEstateStatus: this.translateWLKStatus(c),
                            })),
                            columns: this.columns,
                          }}
                          striped="gray"
                          variant="heavy"
                          sortable={true}
                          paginate={false}
                          selectable={false}
                          onRowsSelect={console.log}
                        />
                      </div>
                    )}

                    {/* GRID VIEW */}
                    <div
                      id="orders-container--grid-container"
                      className={classNames([
                        'sm:flex sm:flex-wrap -mx-2',
                        !this.props.isMobile && 'md:hidden',
                      ])}
                    >
                      {this.props.isLoading ? (
                        Array(SKELETON_LOAD_CNT_GRID)
                          .fill(null)
                          .map((v, idx) => (
                            <div
                              id={`orders-card-${idx}`}
                              key={idx}
                              className="w-full sm:w-1/2 lg:w-1/3 p-2"
                            >
                              <CardOrderGrid skeleton={true} />
                            </div>
                          ))
                      ) : (
                        <>
                          {this.state.summaryView.map((item, idx) => {
                            return (
                              <div
                                id={`orders-card-${idx}`}
                                key={idx}
                                className="w-full sm:w-1/2 lg:w-1/3 p-2"
                              >
                                <CardOrderGrid
                                  awaitingWlkSubmission={item.awaitingWlkSubmission}
                                  status={item.realEstateStatus}
                                  street={item.address.address1}
                                  unit={item.address.address2}
                                  city={item.address.city}
                                  state={item.address.state}
                                  zip={item.address.zip}
                                  agent={item.initiatingAgentName}
                                  office={item.initiatingOfficeName}
                                  menuLinks={this.tooltipActions(item.tableId)}
                                  viewOrder={() => this.SummaryViewOrderHandler(item.id)}
                                  showMakePaymentButton={this.showMakePaymentButton(item)}
                                  openPayment={() => {
                                    this.openPaymentHandler(item);
                                  }}
                                  enableActions={true}
                                />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </>
                )}

                <AtypicallySlowOverlay isActive={this.state.isAtypicallySlow} />
                <Pagination
                  onItemsPerPageChange={null}
                  itemsPerPage={15}
                  itemsPerPageOptions={[{ value: '15', label: '15' }]}
                  onPageChange={this.onPageChange}
                  totalItems={this.props.totalOrders}
                  page={this.state.activePage}
                  hideViewAll={true}
                />
              </div>
              {/* If too many offices, we should display a message here */}
              {!this.props.isLoading &&
                !this.state.isAtypicallySlow &&
                this.props.hasTooManyOffices && (
                  <div id="orders-container--no-results" className="m-5">
                    {TOO_MANY_OFFICES}
                  </div>
                )}

              {/* If no contracts, we should display a message here */}
              {!this.props.isLoading &&
                !this.state.isAtypicallySlow &&
                this.props.summaryView.length === 0 &&
                !this.props.hasTooManyOffices && (
                  <div id="orders-container--no-results" className="m-5">
                    You have no contracts saved
                  </div>
                )}
            </div>
          </ContentBox>
        </div>
        <DrawerMakePaymentStripe
          id="drawer-make-payment2"
          isActive={this.state.isMakePaymentDrawerActiveStripe}
          onClose={this.handleDrawerMakePaymentStripeClose}
          submitACHPayment={this.makeACHCSCPaymentCall}
          submitCCPayment={this.makeCCCSCPaymentCall}
          onSuccess={this.refreshContract}
          contractBalance={Number(this.state.contractPaymentBalance?.currentBalance)}
          totalPrice={this.state.contractPaymentBalance?.details.total}
          creditAmount={this.state.contractPaymentBalance?.details?.credit}
          discountAmount={this.state.contractPaymentBalance?.details.discounts}
          militaryDiscountApplied={this.state.contractPaymentBalance?.appliedSpecialDiscounts?.includes(
            MILITARY_DISCOUNT,
          )}
          contract={this.state.selectedContract}
          sourcePage="My Orders page"
        />

        <DrawerExtendListing
          id="drawer-extend-listing"
          isActive={this.state.isExtendListingDrawerActive}
          onClose={() => this.setState({ isExtendListingDrawerActive: false })}
          onApprove={(contract) => this.onExtendListing(contract)}
          contract={this.state.selectedContract}
        />

        <DrawerCancelOrder
          id="drawer-cancel-order"
          isActive={this.state.isCancelOrderDrawerActive}
          onClose={() => this.setState({ isCancelOrderDrawerActive: false })}
          onOrderCancelled={this.handleOrderCancelled}
          contract={this.state.selectedContract}
        />

        <DrawerNotifications
          id="drawer-notifications"
          isActive={this.state.isNotificationsDrawerActive}
          userDetails={this.profile}
          newOrderData={this.state.selectedContract}
          contractId={this.state.selectedContract && this.state.selectedContract.summary.id}
          onClose={() => this.setState({ isNotificationsDrawerActive: false })}
          slidingContentClassName="drawer-notifications-container"
        />

        {this.state.isRenewalDrawerActive && (
          <DrawerSendRenewal
            id="drawer-send-renewal"
            isActive={this.state.isRenewalDrawerActive}
            onClose={() => this.setState({ isRenewalDrawerActive: false })}
            contract={this.state.selectedContract}
            userDetails={this.props.profile}
            onSendRenewal={this.refreshContract}
          />
        )}

        <Dialog
          id="filter-modal"
          header="Filter Orders"
          modal={true}
          onClose={() => this.setState({ showFilterModal: false })}
          open={this.state.showFilterModal && this.props.isMobile}
          actionsAlign="right"
          actions={[
            <Button
              key="action"
              size="small"
              label="Done"
              onClick={() => this.setState({ showFilterModal: false })}
            />,
          ]}
        >
          <div className="min-w-80">{this.getListFiltering()}</div>
        </Dialog>

        <ModalAssociateAgent
          isActive={this.state.isAssociateModalActive}
          onClose={() => this.setState({ isAssociateModalActive: false })}
          contract={this.state.contractForModal}
        />
        <ModalCannotAddCooperating
          isActive={this.state.isCannotAddCooperatingModalActive}
          onClose={() => this.setState({ isCannotAddCooperatingModalActive: false })}
          contract={this.state.contractForModal}
          cannotAddCooperatingReason={this.state.cannotAddCooperatingReason}
        />
      </div>
    );
  }
}

export default OrdersTemplate;
