import React, { useContext } from 'react';
import CardDashboardAction, { DashboardActionType } from '@components/card/CardDashboardAction';
import { Button } from '@ftdr/blueprint-components-react';
import { formatDate, toFormattedNumber } from '@helpers/utils';
import { useNavigate } from 'react-router-dom';
import { fireGAEvent } from '@app/core/tracking.service';
import { FilterOperation } from '@constants/dashboardFilters';
import Path from '@constants/paths';
import { subYears } from 'date-fns/esm';
import { dateTypes } from '@constants/dates';
import { DASHBOARD_WLK_PAYMENTS } from '@constants/ga-events.constants';
import { WarrantyLinkPaymentFilter } from '@components/filter/WarrantyLinkPaymentFilter';
import ProfileContext from '@context/ProfileContext';
import { getWLKBrokerOffices } from '@services/helpers/profile.offices.helper';
import ICardDashBoardActionProps from '@components/card/ICardDashboardAction';

export const CardDashBoardActionWLKTotalPaymentsCard = (props: ICardDashBoardActionProps) => {
  const navigate = useNavigate();

  const navigateToWlkPayments = () => {
    fireGAEvent(DASHBOARD_WLK_PAYMENTS);

    const startDate = formatDate(subYears(new Date(), 1));
    const endDate = formatDate(new Date());

    const filterList: WarrantyLinkPaymentFilter = {
      operation: FilterOperation.ADD,
      type: dateTypes.ESTCOE,
      payload: {
        date: {
          start: new Date(startDate),
          end: new Date(endDate),
        },
      },
      label: `Payment Date: ${startDate} - ${endDate}`,
    };

    navigate(Path.WarrantylinkPayments, { state: filterList });
  };

  const { profile } = useContext(ProfileContext);
  const hasWLKOffices = getWLKBrokerOffices(profile).length > 0;

  return (
    hasWLKOffices && (
      <CardDashboardAction
        isManualFetch={props.isManualFetch}
        fetchCount={props.fetchCount}
        type={props.count ? DashboardActionType.NoLabel : DashboardActionType.DoesNotNeedAttention}
        heading={props.count === null ? null : `$${toFormattedNumber(props.count)}`}
        subHeading="warrantyLink payouts in last 12 months"
        action={
          <Button
            label="View Orders"
            size="medium"
            variant="ghost"
            disabled={!props.count}
            onClick={navigateToWlkPayments}
          />
        }
      />
    )
  );
};
