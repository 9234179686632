import React from 'react';
import { IconDiscountTag } from '@ftdr/blueprint-components-react';
import { formatDate, toCurrency, toPercentage } from '@helpers/utils';
import { classNames } from '@utils';
import REText from '@components/wrappedBDS/REText';

interface TableNewOrderPriceSummaryProps {
  /** on drawer view, we want to make things more tidy */
  isSmall?: boolean;

  plan: NewOrderPriceSummaryItem;

  showSellersCoverageSubLine: boolean;
  sellerId: string;
  sellerFromDate: any;
  sellerToDate: any;

  showPerDiemSubLine: boolean;
  perDiem: NewOrderPriceSummaryItem;

  options: NewOrderPriceSummaryItem;

  taxRate: number;
  taxableAmount: number;
  taxAmount: NewOrderPriceSummaryItem;

  discountAmount: NewOrderPriceSummaryItem;

  totalDueAtClosing: NewOrderPriceSummaryItem;
}

interface NewOrderPriceSummaryIds {
  labelId?: string;
  priceId?: string;
}

interface NewOrderPriceSummaryPrice extends NewOrderPriceSummaryIds {
  price: number;
}

interface NewOrderPriceSummaryItem extends NewOrderPriceSummaryPrice, NewOrderPriceSummaryIds {
  label: string;
}

interface NewOrderPriceSummarySubLine extends NewOrderPriceSummaryIds {
  label?: string;
  price?: number;
}

interface TableNewOrderPriceSummaryItemProps {
  item: NewOrderPriceSummaryItem;
  bold?: boolean;
  helpText?: string;
  subLines?: NewOrderPriceSummarySubLine[];
  discountPrice?: boolean;
}

const TableNewOrderPriceSummary: React.FC<TableNewOrderPriceSummaryProps> = (props) => {
  const getSellersCoverageLineLabel = (fromDate: any, toDate: any) => {
    if (fromDate && fromDate instanceof Date) {
      fromDate = formatDate(fromDate);
    }
    if (toDate && toDate instanceof Date) {
      toDate = formatDate(toDate);
    }

    return `Seller's Coverage Effective: ${fromDate} - ${toDate}`;
  };

  const getTaxHelpText = (taxRate, taxableAmount) => {
    return `${toPercentage(taxRate)} of ${toCurrency(taxableAmount)}`;
  };

  return (
    <div className={classNames(['w-full', props.isSmall ? 'pt-5' : 'p-4'])}>
      <REText
        variant={props.isSmall ? 'heading-05' : 'heading-04'}
        className={classNames([props.isSmall ? 'my-2' : 'my-4'])}
      >
        Order Summary
      </REText>

      <div className="flex flex-col w-full divide-y divide-gray-300 border-t border-gray-300">
        <TableNewOrderPriceSummaryItem
          item={props.plan}
          subLines={[
            props.showSellersCoverageSubLine && {
              label: getSellersCoverageLineLabel(props.sellerFromDate, props.sellerToDate),
              labelId: props.sellerId,
            },
            props.showSellersCoverageSubLine && props.showPerDiemSubLine && props.perDiem,
          ]}
        />
        <TableNewOrderPriceSummaryItem item={props.options} />

        <TableNewOrderPriceSummaryItem
          item={props.taxAmount}
          helpText={getTaxHelpText(props.taxRate, props.taxableAmount)}
        />

        {props.discountAmount?.price !== 0 && (
          <TableNewOrderPriceSummaryItem item={props.discountAmount} discountPrice={true} />
        )}

        <div>
          <div className="border-t border-black">
            <TableNewOrderPriceSummaryItem item={props.totalDueAtClosing} bold={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

const TableNewOrderPriceSummaryItem: React.FC<TableNewOrderPriceSummaryItemProps> = ({
  item,
  ...props
}) => {
  const variant = props.bold ? 'heading-06' : 'body-long';
  const helperVariant = 'helper-text';
  const subVariant = props.bold ? 'label' : 'caption';

  return (
    <div className="flex flex-col px-2 py-2 space-y-1">
      <div className="flex items-center justify-between">
        <div className="flex flex-col space-y-1">
          <div className="flex items-center space-x-2">
            {props.discountPrice && (
              <IconDiscountTag id="disountTag" size={22} color="warning" className="inline-flex" />
            )}

            <REText variant={variant} id={item.labelId}>
              {item.label || '-'}
            </REText>
            {props.helpText && (
              <REText variant={helperVariant} color="gray">
                {props.helpText}
              </REText>
            )}
          </div>
        </div>
        <REText variant={variant} id={item.priceId}>
          {toCurrency(item.price || 0)}
        </REText>
      </div>
      {props.subLines &&
        props.subLines.map(
          (subLine) =>
            subLine && (
              <div key={subLine.labelId} className="flex items-center justify-between">
                <REText variant={subVariant} color="gray" className="ml-4" id={subLine.labelId}>
                  {subLine.label}
                </REText>
                {subLine.price !== undefined && (
                  <REText variant={subVariant} color="gray" id={subLine.priceId}>
                    {toCurrency(subLine.price || 0)}
                  </REText>
                )}
              </div>
            ),
        )}
    </div>
  );
};

export default TableNewOrderPriceSummary;
