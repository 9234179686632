import { GAEventArgsMaster, PagePath } from '@app/models/gaevents.model';
import { UTM_PARAMETERS_PERSIST_STORAGE_KEY } from '@services/utmParameters/utmParameters.service';

class utag {
  /**
   * Tracks page views, virtual page views, and Ajax page flows. Calling this function
   * triggers the corresponding page tracking functionality within your configured vendor tags.
   * Should be triggered on every page load.
   */
  public async view(utag_data?: PagePath) {
    try {
      const u_tag = await this.getUtag();
      u_tag.view(utag_data);
    } catch (e) {
      console.error(e);
    }
  }

  /** Track events such as non-page views, page interactions, and other dynamic page events.
   * Even tracking collects informationa bout your visitors' interactions within a page
   */
  public async link(utag_data: GAEventArgsMaster) {
    //GA4 workaround
    const currentLocation = window.location.href;
    const trackingLocation = localStorage.getItem(UTM_PARAMETERS_PERSIST_STORAGE_KEY) || window.location.origin;
    window.history.replaceState('','',trackingLocation);

    try {
      const u_tag = await this.getUtag();
      u_tag.link(utag_data);
    } catch (e) {
      console.error(e);
    }

    //GA4 workaround
    window.history.replaceState('','',currentLocation);
  }

  private async getUtag(): Promise<any> {
    for (let attempt = 0; attempt < 5; attempt++) {
      const { utag } = window as any;
      if (utag) {
        return utag;
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    throw new Error('Tealium was not loaded, cannot get utag');
  }
}

export default new utag();

// See ATG code header-global.jsp and my-account-common.js:sendUtagData()
// Certain fields don't seem to be set. This may be something that was taken from another project and copied over.
// Check universal utag_data attributes if necessary, but for now leaving a copy here in case we need it
/*
<dsp:getvalueof var="deviceCategory" bean="/atg/userprofiling/Profile.deviceCategory"/>
<c:set var="deviceCategory" value="${fn:toLowerCase(deviceCategory)}"/>
<dsp:getvalueof var="transientProfile" bean="/atg/userprofiling/Profile.transient"/>
<c:set var="uid_prefix" value="${transientProfile ? 't' : ''}"/>
<script type="text/javascript">
	var utag_data = {
	  customer_city : "", // city
	  customer_country : "", // country
	  customer_id : "${uid_prefix}<dsp:valueof bean='/atg/userprofiling/Profile.id'/>", // customer id
	  customer_state : "", // state
	  customer_zip : "", // zip
	  deviceCategory : "${deviceCategory}",
	  event_action: "", //
	  event_category: "", //
	  event_label: "", //
	  site_section: "${siteSection}", //
	  fp_channel : "", // _fp.channel for quantcast
	  fp_event : "", // _fp.event for quantcast
	  order_currency_code : "", // currency type
	  order_grand_total : "", // Grand Total
	  order_id : "", // Order ID
	  order_shipping_amt : "", // shipping
	  order_subtotal : "", // Sub total
	  order_tax_amt : "", // tax
	  product_brands : "", // product brand(s) array
	  product_categories : "", // product category(s) array
	  product_discounts : "", // product discount(s) array
	  product_ids : "", // unique product id(s) array
	  product_names : "AHS", // product name(s) array
	  product_quantities : "", // product quantity(s) array
	  product_skus : "", // product sku(s) array
	  product_unit_prices : "", // product unit price(s) array
	  transaction_ids : "", // transaction id(s) string array
	  pageTemplateUrl : "${pageTemplateUrl}",
	  ueCookie : "${ueCookie}"
	};
	<c:if test="${not empty atgABTestGaDimensionValue}">
		utag_data.atg_ab_test_dimension='${atgABTestGaDimensionValue}';
	</c:if>

*/
