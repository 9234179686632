// TODO Investigate why we are making double calls to the contract endpoint.

import React, { Component } from 'react';
import Layout from '@components/layout/Layout';
import OrdersTemplate from '@templates/order/OrdersTemplate';
import ContractApi, { ContractApiSuppressErrors } from '@services/apis/contract.api';
import RealEstateOrderApi from '@services/apis/realestateorder.api';
import {
  AgentSearchRecord,
  Contract,
  Office,
  REOrder,
  REOrderDataFilterCode,
  REOrderSearchMetaStatus,
  REOrderSearchRequest,
} from '@apis/models';
import OfficeApi from '@apis/office.api';
import ProfileContext from '../../context/ProfileContext/index';
import ProfileModel from '@app/models/profile.model';
import { FilterChangeEvent, FilterTagItem } from '@components/misc/misc.models';
import { DateFormat, formatDate, formatDateToISO8601, SECOND } from '@helpers/utils';
import AgentApi from '@apis/agent.api';
import { officeTypeDictionary, orderStatusDictionary } from '@constants/dictionaries';
import { FilterOperation, FilterType, RealEstateStatus } from '@constants/dashboardFilters';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import withRetry from '@apis/utils/withRetry';
import { getBrand } from '@helpers/brand.utils';
import {
  deduplicateOffices,
  getProfileOfficesByType,
} from '@services/helpers/profile.offices.helper';
import { OfficeType } from '@app/models';
import { loadQualtric } from '@app/core/qualtric.service';
import { fireGAEvent } from '@app/core/tracking.service';
import {
  NAVIGATION__ORDER_FILTER_SORT,
  NAVIGATION__ORDER_SEARCH,
  NOT_SUBMITTED_TAB,
  ORDER__MAKE_PAYMENT,
} from '@constants/ga-events.constants';
import { PAYMENTS } from '@constants/newOrder-constants';
import Path from '@constants/paths';
import { useLocation, useNavigate } from "react-router-dom";
import { openOrderDrawer } from '@app/core/GlobalDrawerOrder';
import {
  RefreshContractServiceSubscriber,
  subscribeToRefreshedContracts,
  subscribeToRefreshedREOrders,
  unsubscribeRefreshedContracts,
  unsubscribeRefreshedREOrders,
} from '@app/core/refreshContract.service';
import OrdersAbandonedTemplate from '@templates/order/OrdersAbandonedTemplate';
import { OrdersActionSearchType } from '@templates/order/OrdersActionSwitcher';
import msgs from '@app/locales/en';
import useGlobalAlert from '@app/core/GlobalAlertModal';

let canceler = ContractApi.getCancelTokenSource();
let loadStatusCanceler = RealEstateOrderApi.getCancelTokenSource();
let loadContractsCanceler = RealEstateOrderApi.getCancelTokenSource();

interface Meta {
  asc?: boolean;
  limit?: number;
  page?: number;
  sortBy?: string;
}

export interface OrderPreFilter {
  removeQuery?: boolean;
  filterText?: string;
  orderStatuses?: string[];
  orderFilters?: number[];
  closingDateRange?: Date[];
  entryDateRange?: Date[];
  offices?: number[];
  agents?: number[];
  missingCOEOnly?: boolean;
  meta?: Meta;
  openOrderDrawerContractId?: string;
  officeLimitBypass?: boolean;
}

interface DashboardState {
  isOnLoad: boolean;
  isLoadingContracts: boolean;
  contractIDs: string[];
  contracts: Contract[];
  userDetails: ProfileModel;
  userOffices: Office[];
  agentsFilterList: AgentSearchRecord[];
  orderSummary: REOrder[];
  contractSearchFilter: REOrderSearchRequest;
  filterTagItems: FilterTagItem[];
  totalOrders: number;
  loadProfileContractsCanceler: any; // TODO: tighten up typing
  loadStatusCanceler: any;
  totalStatus: REOrderSearchMetaStatus;
  totalAwaitingWlkSubmission: number;
  officeCount: number;
  hasTooManyOffices: boolean;
  searchText: string;
  preFilter: OrderPreFilter;
  officesToDisplayMerchandiseBanner: Office[];
  tab: OrdersActionSearchType;
}

// How many contracts to load data at a time
const CONTRACT_DATA_INCLUDE_DETAILS = true;
const CONTRACT_DATA_INCLUDE_FEATURES = true;
const CONTRACT_DATA_LOAD_SIZE = 5;
const ENABLE_SUBSET_LOAD = true;

const CONTRACT_PAGE_SIZE = 15;

const TOO_MANY_OFFICES_THRESHOLD = 25;

const GAEventDelaySeconds = 1;

const MIN_CHAR_COUNT_SEARCH_FOR_GA = 3;

class Orders extends Component<any, DashboardState> {
  static contextType = ProfileContext;
  private readonly brand = getBrand();
  private ordersSearchTimeoutFunction = null;
  private countCharSearchForGA = 0;
  private queryFilterSubscription: Subscription = null;
  private queryFilterSubject = new BehaviorSubject(null);
  private queryFilter$ = this.queryFilterSubject.asObservable().pipe(
    filter((value) => value !== null),
    distinctUntilChanged(),
    debounceTime(200), // wait 200ms before doing another request
  );

  constructor(props) {
    super(props);
    // Set default state
    this.state = {
      userDetails: new ProfileModel(),
      userOffices: [],
      isOnLoad: true,
      isLoadingContracts: false,
      contractIDs: [],
      contracts: [],
      orderSummary: [],
      contractSearchFilter: new REOrderSearchRequest(CONTRACT_PAGE_SIZE),
      filterTagItems: [],
      agentsFilterList: [],
      totalOrders: 0,
      loadProfileContractsCanceler: undefined,
      loadStatusCanceler: undefined,
      totalStatus: null,
      totalAwaitingWlkSubmission: null,
      officeCount: 0,
      hasTooManyOffices: false,
      searchText: '',
      preFilter: props.preFilter,
      officesToDisplayMerchandiseBanner: [],
      tab: OrdersActionSearchType.Submitted,
    };

    // Bind this to all functions
    this.loadProfileContractTotals = this.loadProfileContractTotals.bind(this);
    this.loadProfileContracts = this.loadProfileContracts.bind(this);
    this.loadUserOffices = this.loadUserOffices.bind(this);
    this.loadContractData = this.loadContractData.bind(this);
    this.onExtendListing = this.onExtendListing.bind(this);
    this.refreshOrders = this.refreshOrders.bind(this);
    this.refreshContracts = this.refreshContracts.bind(this);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.updateOfficeFilter = this.updateOfficeFilter.bind(this);
    this.updateAgentFilter = this.updateAgentFilter.bind(this);
    this.updateDateFilter = this.updateDateFilter.bind(this);
    this.updateStatusFilter = this.updateStatusFilter.bind(this);
    this.updateWLKContractStatus = this.updateWLKContractStatus.bind(this);
  }

  async componentDidMount() {
    const { profile } = this.context;

    // QUALTRICSSURVEY
    loadQualtric(10000);

    if (profile) {
      this.setState(
        {
          userDetails: profile,
          officesToDisplayMerchandiseBanner: [...profile.offices, ...profile.workedWithOffices],
        },
        async () => {
          const officeCount = await this.loadUserOffices();

          // Handle pre-filtering
          if (window.location.search !== '') {
            const preFilter = this.getOrderPreFilterFromUrlQuery();
            await this.applyOrderPreFilter(preFilter);
          } else if (this.state.preFilter && Object.keys(this.state.preFilter).length > 0) {
            this.applyGlobalSearchFilter(this.state.preFilter);
            const preFilter = this.getOrderPreFilterFromRouteData(this.state.preFilter);
            await this.applyOrderPreFilter(preFilter);
          }

          if (officeCount > TOO_MANY_OFFICES_THRESHOLD) {
            this.setState({ officeCount });

            if (!this.props.preFilter?.officeLimitBypass) {
              return this.setState({ hasTooManyOffices: true, isOnLoad: false });
            }
          }

          this.loadProfileContractTotals();
          this.loadProfileContracts();
        },
      );
      this.handleQueryFilterChange();
    }

    subscribeToRefreshedContracts(
      RefreshContractServiceSubscriber.OrdersPage,
      this.refreshContracts,
    );
    subscribeToRefreshedREOrders(RefreshContractServiceSubscriber.OrdersPage, this.refreshOrders);
  }

  componentWillUnmount() {
    canceler.cancel();
    loadStatusCanceler?.cancel();
    loadContractsCanceler?.cancel();
    this.queryFilterSubscription?.unsubscribe();
    unsubscribeRefreshedContracts(RefreshContractServiceSubscriber.OrdersPage);
    unsubscribeRefreshedREOrders(RefreshContractServiceSubscriber.OrdersPage);
  }

  async loadUserOffices(): Promise<number> {
    // If user is a CC admin or agent, we only want to include CC's
    const closingCompanyOfficesOnly = ['ClosingCompanyAgent', 'ClosingCompanyAdmin'].includes(
      this.state.userDetails.roleIDType,
    );
    const officeType = closingCompanyOfficesOnly
      ? OfficeType.ClosingCompany
      : OfficeType.RealEstate;

    const offices = getProfileOfficesByType(this.state.userDetails, officeType);

    const userOffices = await OfficeApi.getOfficeList(
      offices.map((o) => {
        return { id: o.id, type: o.type };
      }),
    );
    this.setState({ userOffices: userOffices.offices });

    return userOffices?.offices?.length || 0;
  }

  parseYYYYMMDD(str: string): Date {
    if (str.length !== 8) return null;
    try {
      return new Date(
        parseInt(str.substr(0, 4)),
        parseInt(str.substr(4, 2)) - 1,
        parseInt(str.substr(6, 2)),
      );
    } catch (e) {
      console.error('Date parsing failed:', e);
      return null;
    }
  }

  getDateRangeFromYYYYMMDDArray(dates: string[]): Date[] {
    if (dates.length !== 2) return [];
    const mapped = dates.map(this.parseYYYYMMDD);
    return mapped[0] && mapped[1] ? mapped : [];
  }

  getOrderPreFilterFromUrlQuery(): OrderPreFilter {
    const query = new URLSearchParams(window.location.search);

    const preFilter = {
      removeQuery: (query.get('removeQuery') || 'true') === 'true',
      filterText: query.get('filterText') || '',
      orderStatuses: query.get('orderStatuses')?.split(',') || [],
      closingDateRange: this.getDateRangeFromYYYYMMDDArray(
        query.get('closingDateRange')?.split(',') || [],
      ),
      entryDateRange: this.getDateRangeFromYYYYMMDDArray(
        query.get('entryDateRange')?.split(',') || [],
      ),
      offices:
        query
          .get('offices')
          ?.split(',')
          .map((ns) => parseInt(ns)) || [],
      agents:
        query
          .get('agents')
          ?.split(',')
          .map((ns) => parseInt(ns)) || [],
      missingCOEOnly: (query.get('missingCOEOnly') || 'false') === 'true',
    };

    return preFilter;
  }

  getOrderPreFilterFromRouteData(data: OrderPreFilter): OrderPreFilter {
    const preFilter = {
      removeQuery: data.removeQuery || true,
      filterText: data.filterText || '',
      orderStatuses: data.orderStatuses || [],
      orderFilters: data.orderFilters || [],
      closingDateRange: data.closingDateRange || [],
      entryDateRange: data.entryDateRange || [],
      offices: data.offices || [],
      agents: data.agents || [],
      missingCOEOnly: data.missingCOEOnly || false,
      meta: data.meta,
    };

    return preFilter;
  }

  applyGlobalSearchFilter(data: OrderPreFilter): void {
    if (data.openOrderDrawerContractId) {
      console.debug('opening order drawer for:', data.openOrderDrawerContractId);
      openOrderDrawer({
        contractId: data.openOrderDrawerContractId,
      });
    }
  }

  async applyOrderPreFilter(preFilter: OrderPreFilter) {
    let { contractSearchFilter, filterTagItems } = this.state;
    const { agentsFilterList } = this.state;

    const tagItems = [];

    preFilter.orderStatuses.forEach((status) => {
      if (orderStatusDictionary[status]) {
        tagItems.push({
          operation: 'add',
          type: 'status',
          payload: { status: status },
          label: `Status: ${orderStatusDictionary[status]}`,
        });
      }
    });

    if (preFilter.meta) {
      if (preFilter.meta.sortBy) {
        tagItems.push({
          type: 'orderSort',
          payload: { sortBy: preFilter.meta.sortBy, asc: preFilter.meta.asc },
        });
      }
    }

    if (preFilter.closingDateRange.length === 2) {
      tagItems.push({
        operation: 'add',
        type: 'date',
        payload: {
          date: {
            start: preFilter.closingDateRange[0].toISOString(),
            end: preFilter.closingDateRange[1].toISOString(),
            type: 'ESTCOE',
            presetTitle: '',
          },
        },
        label: `Closing Date: ${formatDate(preFilter.closingDateRange[0], DateFormat.DEFAULT)} - ${formatDate(preFilter.closingDateRange[1], DateFormat.DEFAULT)}`,
      });
    }

    if (preFilter.entryDateRange.length === 2) {
      tagItems.push({
        operation: 'add',
        type: 'date',
        payload: {
          date: {
            start: preFilter.entryDateRange[0].toISOString(),
            end: preFilter.entryDateRange[1].toISOString(),
            type: 'ENTRYDATE',
            presetTitle: '',
          },
        },
        label: `Entry Date: ${formatDate(preFilter.entryDateRange[0], DateFormat.DEFAULT)} - ${formatDate(preFilter.entryDateRange[1], DateFormat.DEFAULT)}`,
      });
    }

    for (let index = 0; index < preFilter.offices.length; index += 1) {
      const matchingOffices = this.state.userOffices.filter(
        (uo) => uo.id === preFilter.offices[index].toString(),
      );
      if (matchingOffices.length === 1 && matchingOffices[0].id !== '') {
        tagItems.push({
          type: 'office',
          payload: { office: matchingOffices[0] },
          label: `Office: ${matchingOffices[0].name}`,
        });

        // fetch agents for this office
        const response = await AgentApi.searchAgents(
          {
            activeInactive: 'A',
            officeId: matchingOffices[0].id,
            officeType: matchingOffices[0].type,
          },
          { tags: { source: 'Orders#updateOfficeFilter' } },
        );

        // apply agents to agent filter list
        agentsFilterList.push(...response.agentsList);
      }
    }

    preFilter.agents.forEach((id) => {
      const matchingAgent = agentsFilterList.filter((a) => a.realEstateAgentID === id.toString());
      if (matchingAgent.length === 1) {
        tagItems.push({
          operation: 'add',
          type: 'agent',
          payload: { agent: matchingAgent[0] },
          label: `Agent: ${matchingAgent[0].firstName} ${matchingAgent[0].lastName}`,
        });
      }
    });

    if (preFilter.missingCOEOnly) {
      tagItems.push({
        operation: 'add',
        type: 'missing-coe-only',
        payload: {},
        label: 'Missing Close of Escrow Date',
      });
    }

    if (preFilter.orderFilters && preFilter.orderFilters.length > 0) {
      for (let index = 0; index < preFilter.orderFilters.length; index += 1) {
        switch (preFilter.orderFilters[index]) {
          case REOrderDataFilterCode.contractRenewal:
            tagItems.push({
              operation: 'add',
              type: 'up-for-renewal-only',
              payload: {},
              label: 'Up for Renewal',
            });
            break;
          default:
            break;
        }
      }
    }

    filterTagItems = tagItems; // Replace tag list fow now. May want to modify list in the future.
    contractSearchFilter = this.mapNewFilterRequest(
      contractSearchFilter,
      tagItems,
      preFilter.filterText,
    );

    if (preFilter.removeQuery) {
      window.history.pushState('', '', Path.MyOrders);
    }

    this.setState({
      contractSearchFilter,
      filterTagItems,
      agentsFilterList,
      searchText: preFilter.filterText,
    });
  }

  loadProfileContractTotals() {
    loadStatusCanceler.cancel();
    loadStatusCanceler = RealEstateOrderApi.getCancelTokenSource();

    this.setState(
      (prevState) => {
        if (prevState.loadStatusCanceler) prevState.loadStatusCanceler();

        return { loadStatusCanceler: loadStatusCanceler.cancel, isOnLoad: true };
      },
      async () => {
        if (
          !this.state.hasTooManyOffices ||
          this.state.contractSearchFilter.data.offices.length > 0
        ) {
          const request = JSON.parse(JSON.stringify(this.state.contractSearchFilter));
          // this is to prevent the order status filter alert counts from updating when filter is modified
          request.data.order.realEstateStatus = [];
          request.data.order.awaitingWLKSubmissionOnly = false;
          const searchProfileContracts = await RealEstateOrderApi.searchProfileContracts(request, {
            cancelToken: loadStatusCanceler.token,
          });
          if (!searchProfileContracts) return;

          this.setState({
            totalStatus: searchProfileContracts.meta.totalStatus,
            totalAwaitingWlkSubmission: searchProfileContracts.meta.totalAwaitingWlkSubmission,
          });
        } else {
          this.setState({
            totalStatus: null,
            totalAwaitingWlkSubmission: null,
          });
        }
      },
    );
  }

  loadProfileContracts() {
    loadContractsCanceler.cancel();
    loadContractsCanceler = RealEstateOrderApi.getCancelTokenSource();
    // Given that Chrome and other browsers have limits for the maximum amount of concurrent connections to a given server (Chrome allows 6 concurrently per server), we can have timeouts if too many requests are queued before the others are able to finish
    // To avoid this, we ensure we're first cancelling any existing requests below before placing any new requests
    this.setState(
      (prevState) => {
        if (prevState.loadProfileContractsCanceler) prevState.loadProfileContractsCanceler();

        return { loadProfileContractsCanceler: loadContractsCanceler.cancel, isOnLoad: true };
      },
      async () => {
        const searchProfileContracts = await RealEstateOrderApi.searchProfileContracts(
          this.state.contractSearchFilter,
          { cancelToken: loadContractsCanceler.token },
        );
        if (!searchProfileContracts) return; // short circuit if above call was cancelled

        // Update the warrantylink flag to be false if not the initiating office
        searchProfileContracts.orders = this.updateWLKContractStatus(searchProfileContracts.orders);

        this.setState({
          orderSummary: searchProfileContracts.orders,
          totalOrders: searchProfileContracts.meta.total,
          isOnLoad: false,
        });

        const contractIDs = searchProfileContracts.orders.map((order) => {
          return order.id.toString();
        });
        console.log('Contract count: ', contractIDs.length);

        // If no contracts, stop load
        if (contractIDs.length === 0) return;

        this.setState({ isLoadingContracts: true });

        if (ENABLE_SUBSET_LOAD) {
          // Load contracts by a threshold load count
          this.loadContractDataBySubsetCalls(contractIDs).then(() => {
            this.setState({ isLoadingContracts: false });
          });
        } else {
          // Load all contracts given.
          this.loadContractData(contractIDs, { cancelToken: canceler.token }).then((resp) => {
            this.setState({ contracts: [...resp], isLoadingContracts: false });
          });
        }
      },
    );
  }

  /** Only initiating office should see this status. The user profile's main RE offices */
  updateWLKContractStatus(orders: REOrder[]): REOrder[] {
    const { profile } = this.context;
    if (profile && profile.offices) {
      const reOfficeIDs = profile.offices
        ?.filter((o) => officeTypeDictionary[o.type] === 'RE')
        .map((o) => o.id);

      orders = orders.map((o) => ({
        ...o,
        awaitingWlkSubmission:
          reOfficeIDs.includes(o.initiatingOfficeID) && o.awaitingWlkSubmission,
      }));
    }

    return orders;
  }

  /** Loads contracts by multiple subset calls synchronously to address timeout concerns.
   *  Sets the state as well in order to load contracts partially.
   */
  async loadContractDataBySubsetCalls(
    contractIDs: string[],
    subsetSize = CONTRACT_DATA_LOAD_SIZE,
  ): Promise<Contract[]> {
    canceler.cancel();
    canceler = ContractApi.getCancelTokenSource();

    // Load contracts by a threshold load count.
    if (contractIDs.length <= subsetSize) {
      return this.loadContractData(contractIDs, { cancelToken: canceler.token }).then(
        (contracts) => {
          this.setState({ contracts });
          return contracts;
        },
      );
    }

    const subsetCount = Math.ceil(contractIDs.length / subsetSize);
    console.log(`expected # of subset calls = ${subsetCount}`);

    const contracts: Contract[] = [];

    // Loop through each subset call
    for (let i = 0; i < subsetCount; i++) {
      const startIndex = i * subsetSize;
      const endIndex = (i + 1) * subsetSize;
      const loadList = contractIDs.slice(startIndex, endIndex);

      const loadedContracts = await this.loadContractData(loadList, {
        cancelToken: canceler.token,
      });
      contracts.push(...loadedContracts);

      // Update state contracts
      this.setState({ contracts });
    }

    // For logging purposes
    if (contractIDs.length !== contracts.length) {
      const missingIDs = contractIDs.reduce<string[]>((acc, id) => {
        if (!contracts.find((c) => c.summary.id === id)) {
          acc = [...acc, id];
        }
        return acc;
      }, []);
      console.warn('some contracts have failed to load', missingIDs);
    }

    return contracts;
  }

  async loadContractData(contractIDs: string[], opts): Promise<Contract[]> {
    const apiCall = async () =>
      ContractApiSuppressErrors.getContractDetails(
        contractIDs,
        CONTRACT_DATA_INCLUDE_FEATURES,
        CONTRACT_DATA_INCLUDE_DETAILS,
        opts,
      );

    return withRetry(apiCall).then((resp) => {
      return resp || [];
    });
  }

  handleCSCACHPaymentCall(request, contractID): Promise<any> {
    console.log(request);
    fireGAEvent(ORDER__MAKE_PAYMENT(PAYMENTS.ACH, contractID));
    return ContractApi.chargeACH(request, contractID);
  }

  handleCSCCCPaymentCall(request, contractID): Promise<any> {
    console.log(request);
    fireGAEvent(ORDER__MAKE_PAYMENT(PAYMENTS.CC, contractID));
    return ContractApi.chargeCC(request, contractID);
  }

  /** Extend a contract listing. Returns true if successful, otherwise false. */
  onExtendListing(contract: Contract): Promise<boolean> {
    try {
      if (contract) {
        console.log('extend listing for contract', contract.summary.id);
        return ContractApi.extendListing(contract.summary.id)
          .then((success) => {
            if (!success) {
              console.log('extend listing failed');
              useGlobalAlert().addErrorToQueue(msgs.FAILED_TO_EXTEND_LISTING);
            } else {
              useGlobalAlert().addSuccessToQueue(msgs.EXTEND_LISTING_SUCCESS);
            }
            return success;
          })
          .catch((err) => {
            console.error('error occurred when extend listing', err);
            useGlobalAlert().addErrorToQueue(msgs.FAILED_TO_EXTEND_LISTING);
            return false;
          });
      }
    } catch (e) {
      console.error('error occurred when extend listing', e);
      useGlobalAlert().addErrorToQueue(msgs.FAILED_TO_EXTEND_LISTING);
      return new Promise((resolve) => resolve(false));
    }
  }

  /** Upon completed action, refresh the contract */
  refreshContracts(loadedContracts: Contract[]): void {
    try {
      const contracts = [...this.state.contracts];
      let hasRefreshedData = false;
      loadedContracts.forEach((updatedContract) => {
        if (updatedContract) {
          const index = contracts.findIndex(
            (contract) => contract.summary.id === updatedContract.summary.id,
          );
          if (index > -1) {
            contracts[index] = updatedContract;
            hasRefreshedData = true;
          }
        }
      });

      if (hasRefreshedData) {
        this.setState({ contracts });
      }
    } catch (e) {
      console.error('error occurred when refreshing contract', e);
    }
  }

  /** Refreshes the order in our dashboard table for a given contract id */
  refreshOrders(orders: REOrder[]): void {
    try {
      const refreshedOrders = [...this.state.orderSummary];
      let hasRefreshedData = false;
      orders.forEach((updatedOrder) => {
        const index = refreshedOrders.findIndex((order) => order.id === updatedOrder.id);
        if (index > -1) {
          refreshedOrders[index] = updatedOrder;
          hasRefreshedData = true;
        }
      });

      if (hasRefreshedData) {
        this.setState({ orderSummary: refreshedOrders });
      }
    } catch (e) {
      console.error('error occurred when refreshing table', e);
    }
  }

  updateTab = (newSearchType: OrdersActionSearchType) => {
    this.setState({ tab: newSearchType });
    if (newSearchType === OrdersActionSearchType.NotSubmitted) {
      fireGAEvent(NOT_SUBMITTED_TAB);
    }
  };

  /**
   * On [add/remove] filter changes, we want to check if it is a valid change. If so, we would then make a call to refresh the dashboard contracts.
   * On refresh, we should return back to first page in pagination.
   */
  onFilterChange(event: FilterChangeEvent, clearFilters = false): boolean {
    let result: { tags: FilterTagItem[]; validUpdate: boolean } = { tags: [], validUpdate: false };
    let query = this.state.contractSearchFilter?.data?.query || '';

    try {
      console.log(`filter change. type=${event.type}, operation=${event.operation}`);
      let { contractSearchFilter, filterTagItems } = this.state;

      if (clearFilters) {
        filterTagItems = [];
      }

      // Reset the page back to the first page in the request
      // Should not be moved down, because the page filter would override it
      contractSearchFilter.meta.page = 0;

      // Handle individual event type
      switch (event.type) {
        case FilterType.OFFICE:
          result = this.updateOfficeFilter(event, filterTagItems);
          break;
        case FilterType.AGENT:
          result = this.updateAgentFilter(event, filterTagItems);
          break;
        case FilterType.STATUS:
          result = this.updateStatusFilter(event, filterTagItems);
          break;
        case FilterType.DATE:
          result = this.updateDateFilter(event, filterTagItems);
          break;
        case FilterType.PAGE:
          contractSearchFilter.meta.page = event.payload.page;
          result = { tags: filterTagItems, validUpdate: true };
          break;
        case FilterType.MISSING_COE: // TODO Handle this in a better way based on future UI changes.
          if (event.operation === FilterOperation.REMOVE) {
            result = {
              tags: filterTagItems.filter((fti) => fti.type !== FilterType.MISSING_COE),
              validUpdate: true,
            };
          }
          break;
        case FilterType.UP_FOR_RENEWAL:
          if (event.operation === FilterOperation.REMOVE) {
            result = {
              tags: filterTagItems.filter((fti) => fti.type !== FilterType.UP_FOR_RENEWAL),
              validUpdate: true,
            };
          }
          break;
        case FilterType.ORDER_SORT: {
          // remove existing orderSort filter if present
          const tags = filterTagItems.filter((tag) => tag.type !== FilterType.ORDER_SORT);
          result = { tags: [...tags, event], validUpdate: true };
          break;
        }
        case FilterType.CLEAR_FILTER:
          result = { tags: [], validUpdate: true };
          break;
      }

      if (event.operation === FilterOperation.SEARCH_INPUT) {
        query = event.payload.query;
        result.validUpdate = true;
      }

      // On valid update, we want to proceed onwards to update the tags and make a new request
      if (result.validUpdate) {
        console.log('valid filter update, proceeding to update the contract list...');
        if (event.type !== FilterType.CLEAR_FILTER && event.label) {
          fireGAEvent(NAVIGATION__ORDER_FILTER_SORT(`${event.operation}: ${event.label}`));
        }
        // Overriding the search filter by basing it off of the tags instead
        contractSearchFilter = this.mapNewFilterRequest(contractSearchFilter, result.tags, query);

        this.setState({ contractSearchFilter, filterTagItems: result.tags }, () => {
          // we set orderSummary to an empty array so that the orders for the previous filter set don't remain on screen
          if (
            this.state.officeCount > TOO_MANY_OFFICES_THRESHOLD &&
            contractSearchFilter.data.offices.length === 0 &&
            (!this.props.preFilter?.officeLimitBypass ||
              contractSearchFilter.data.date.dateType === '')
          )
            return this.setState({ hasTooManyOffices: true, orderSummary: [] });

          this.loadProfileContractTotals();
          this.loadProfileContracts();
        });
      }
    } catch (e) {
      console.error('failed to apply filter changes', e);
    }

    return result.validUpdate;
  }

  /**
   * On [add/remove] filter changes, pass in filter list from the modal.
   * Overwrite the existing filters with the new list, fire GA event, update agent list.
   */
  onFilterBatchChange(events: FilterChangeEvent[]): boolean {
    const result: { tags: FilterTagItem[]; validUpdate: boolean } = {
      tags: this.state.filterTagItems,
      validUpdate: false,
    };
    const query = this.state.contractSearchFilter?.data?.query || '';
    let { contractSearchFilter } = this.state;
    const updatedAgentList = [];

    try {
      // remove all the office type tags then append with our new list
      result.tags = result.tags.filter((filter) => filter.type !== FilterType.OFFICE);
      events.forEach((e) => {
        const mappedEvent: FilterTagItem = {
          label: e.label,
          type: e.type,
          payload: e.payload,
        };
        result.tags.push(mappedEvent);
      });

      console.log('valid filter update, proceeding to update the contract list...');
      // Overriding the search filter by basing it off of the tags instead
      contractSearchFilter = this.mapNewFilterRequest(contractSearchFilter, result.tags, query);

      this.setState({ contractSearchFilter, filterTagItems: result.tags }, () => {
        // we set orderSummary to an empty array so that the orders for the previous filter set don't remain on screen
        if (
          this.state.officeCount > TOO_MANY_OFFICES_THRESHOLD &&
          contractSearchFilter.data.offices.length === 0 &&
          (!this.props.preFilter?.officeLimitBypass ||
            contractSearchFilter.data.date.dateType === '')
        )
          return this.setState({ hasTooManyOffices: true, orderSummary: [] });
        this.loadProfileContractTotals();
        this.loadProfileContracts();
      });

      events.forEach((e) => {
        // Update the agent list with a new set of agents for the office
        fireGAEvent(NAVIGATION__ORDER_FILTER_SORT(`${e.operation}: ${e.label}`));

        if (e.payload.office && e.payload.office.id !== '') {
          AgentApi.searchAgents(
            {
              activeInactive: 'A',
              officeId: e.payload.office.id,
              officeType: e.payload.office.type,
            },
            {
              tags: { source: 'Orders#updateOfficeFilter' },
            },
          )
            .then((res) => {
              if (res.agentsList.length) {
                console.log('adding agents to filter list, count=', res.agentsList.length);
                updatedAgentList.push(...res.agentsList);
              }
            })
            .catch((e) => {
              console.error(e);
            });
        }
      });

      this.setState({ agentsFilterList: updatedAgentList });
    } catch (e) {
      console.error('failed to apply filter changes', e);
      return false;
    }

    return true;
  }

  mapNewFilterRequest(
    contractSearchFilter: REOrderSearchRequest,
    tags: FilterTagItem[],
    query = '',
  ): REOrderSearchRequest {
    contractSearchFilter.data.agents = tags
      .filter((i) => i.type === 'agent')
      .map((i) => ({
        id: i.payload.agent.realEstateAgentID,
        type: officeTypeDictionary[i.payload.agent.agentType],
      }));

    contractSearchFilter.data.offices = tags
      .filter((i) => i.type === 'office')
      .map((i) => ({
        id: i.payload.office.id,
        type: i.payload.office.type,
      }));

    contractSearchFilter.data.order.realEstateStatus = tags
      // warranty link submission will be sent as a flag
      .filter(
        (i) => i.type === 'status' && i.payload.status !== RealEstateStatus.AWAITING_WL_SUBMISSION,
      )
      .map((i) => i.payload.status);

    // set warranty link flag is status is present
    contractSearchFilter.data.order.awaitingWLKSubmissionOnly =
      tags.find((i) => i.payload.status === RealEstateStatus.AWAITING_WL_SUBMISSION) !== undefined;

    // set missing coe only flag
    contractSearchFilter.data.order.statusListingMissingCOEOnly =
      tags.find((i) => i.type === 'missing-coe-only') !== undefined;

    // apply filters
    contractSearchFilter.data.filters = [];
    if (tags.find((i) => i.type === 'up-for-renewal-only'))
      contractSearchFilter.data.filters.push(REOrderDataFilterCode.contractRenewal);

    const orderSortFilter = tags.find((tag) => tag.type === 'orderSort');
    if (orderSortFilter) {
      contractSearchFilter.meta.sortBy = orderSortFilter.payload.sortBy;
      contractSearchFilter.meta.asc = orderSortFilter.payload.asc;
    }

    const dateFilterTag = tags.find((i) => i.type === 'date');
    if (dateFilterTag) {
      const { date } = dateFilterTag.payload;
      const startDate = formatDateToISO8601(date.start, false);
      const endDate = formatDateToISO8601(date.end, false);
      contractSearchFilter.data.date = {
        startDate,
        endDate,
        dateType: date.type,
      };
    } else {
      contractSearchFilter.data.date = {
        startDate: '',
        endDate: '',
        dateType: '',
      };
    }

    contractSearchFilter.data.query = query;

    return contractSearchFilter;
  }

  updateOfficeFilter(
    event: FilterChangeEvent,
    tags: FilterTagItem[],
  ): { tags: FilterTagItem[]; validUpdate: boolean } {
    let validUpdate = false;

    const { office } = event.payload;
    const inFilter = tags.find(
      ({ type, payload }) =>
        type === 'office' && payload.office.id === office.id && payload.office.type === office.type,
    );

    switch (event.operation) {
      case 'add':
        if (!inFilter) {
          tags = [...tags, event];
          validUpdate = true;

          if (office && office.id !== '') {
            // Update the agent list with a new set of agents for the office
            AgentApi.searchAgents(
              {
                activeInactive: 'A',
                officeId: office.id,
                officeType: office.type,
              },
              {
                tags: { source: 'Orders#updateOfficeFilter' },
              },
            )
              .then((res) => {
                if (res.agentsList.length) {
                  console.log('adding agents to filter list, count=', res.agentsList.length);
                  this.setState({
                    agentsFilterList: [...this.state.agentsFilterList, ...res.agentsList],
                  });
                }
              })
              .catch((e) => {
                console.error(e);
              });
          }
        }
        break;
      case 'remove':
        if (inFilter) {
          tags = tags.filter(
            ({ type, payload }) =>
              !(
                type === 'office' &&
                payload.office.id === office.id &&
                payload.office.type === office.type
              ),
          );
          validUpdate = true;

          // Update the agent list and filter tags by removing agents under the office removed
          console.log('removing agents from filter list');
          const agentsFilterList = this.state.agentsFilterList.filter(
            (a) => !(a.officeID === office.id && officeTypeDictionary[a.agentType] === office.type),
          );
          tags = tags.filter(
            (i) =>
              !(
                i.type === 'agent' &&
                i.payload.agent.officeID === office.id &&
                officeTypeDictionary[i.payload.agent.agentType] === office.type
              ),
          );
          this.setState({ agentsFilterList });
        } else {
          console.warn('attempted to remove, but cannot find it', event, tags);
        }
        break;
    }

    return { tags, validUpdate };
  }

  updateAgentFilter(
    event: FilterChangeEvent,
    tags: FilterTagItem[],
  ): { tags: FilterTagItem[]; validUpdate: boolean } {
    let validUpdate = false;

    const { agent } = event.payload;
    const inFilter = tags.find(
      ({ type, payload }) =>
        type === 'agent' &&
        payload.agent.realEstateAgentID === agent.realEstateAgentID &&
        payload.agent.agentType === agent.agentType,
    );

    switch (event.operation) {
      case 'add':
        if (!inFilter) {
          tags = [...tags, event];
          validUpdate = true;
        }
        break;
      case 'remove':
        if (inFilter) {
          tags = tags.filter(
            ({ type, payload }) =>
              !(
                type === 'agent' &&
                payload.agent.realEstateAgentID === agent.realEstateAgentID &&
                payload.agent.agentType === agent.agentType
              ),
          );
          validUpdate = true;
        } else {
          console.warn('attempted to remove, but cannot find it', event, tags);
        }
        break;
    }

    return { tags, validUpdate };
  }

  updateDateFilter(
    event: FilterChangeEvent,
    tags: FilterTagItem[],
  ): { tags: FilterTagItem[]; validUpdate: boolean } {
    let validUpdate = false;

    const { date } = event.payload;
    const inFilter = tags.find(({ type }) => type === 'date');

    switch (event.operation) {
      case 'add': {
        // We always update the date range if add occurs; replacing the existing one; as there should only be 1 date range filter
        const startDate = formatDateToISO8601(date.start);
        const endDate = formatDateToISO8601(date.end);
        const validDates = Boolean(startDate && endDate);
        if (validDates) {
          tags = [...tags.filter((i) => i.type !== 'date'), event]; // Remove the existing tag first, then add
          validUpdate = true;
        }
        break;
      }
      case 'remove':
        if (inFilter) {
          tags = tags.filter((i) => i.type !== 'date');
          validUpdate = true;
        } else {
          console.warn('attempted to remove date, but cannot find it', event, tags);
        }
        break;
    }

    return { tags, validUpdate };
  }

  updateStatusFilter(
    event: FilterChangeEvent,
    tags: FilterTagItem[],
  ): { tags: FilterTagItem[]; validUpdate: boolean } {
    let validUpdate = false;

    const { status } = event.payload;
    const inFilter = tags.find(
      ({ type, payload }) => type === 'status' && payload.status === status,
    );

    switch (event.operation) {
      case 'add':
        if (!inFilter) {
          tags = [...tags, event];
          validUpdate = true;
        }
        break;
      case 'remove':
        if (inFilter) {
          tags = tags.filter(
            ({ type, payload }) => !(type === 'status' && payload.status === status),
          );
          validUpdate = true;
        }
        break;
    }

    return { tags, validUpdate };
  }

  handleQueryFilterChange = () => {
    this.queryFilterSubscription = this.queryFilter$.subscribe((value: string) => {
      const { contractSearchFilter } = this.state;
      contractSearchFilter.data.query = value;
      this.setState({ contractSearchFilter }, () => {
        this.loadProfileContracts();
        this.loadProfileContractTotals();
      });
    });
  };

  onSearchFilterChange = (value: string) => {
    const { contractSearchFilter } = this.state;
    contractSearchFilter.meta.page = 0;
    this.setState({ searchText: value, contractSearchFilter });
    this.countCharSearchForGA += 1;
    clearTimeout(this.ordersSearchTimeoutFunction);
    this.queryFilterSubject.next(value);
    if (this.countCharSearchForGA >= MIN_CHAR_COUNT_SEARCH_FOR_GA) {
      this.ordersSearchTimeoutFunction = setTimeout(() => {
        fireGAEvent(NAVIGATION__ORDER_SEARCH(value));
        this.countCharSearchForGA = 0;
      }, GAEventDelaySeconds * SECOND);
    }
  };

  render() {
    return (
      <Layout isLoggedIn={true} slug="orders">
        {this.state.tab === OrdersActionSearchType.Submitted ? (
          <OrdersTemplate
            summaryView={this.state.orderSummary}
            contracts={this.state.contracts} // Source of truth for all contract data other than for summary view
            profile={this.state.userDetails}
            offices={this.state.userOffices}
            agents={this.state.agentsFilterList}
            isLoading={this.state.isOnLoad}
            isLoadingContracts={this.state.isLoadingContracts}
            filterTagItems={this.state.filterTagItems}
            searchText={this.state.searchText}
            handleCSCACHPaymentCall={this.handleCSCACHPaymentCall}
            handleCSCCCPaymentCall={this.handleCSCCCPaymentCall}
            onExtendListing={(contract) => this.onExtendListing(contract)}
            onFilterChange={(event, clearFilters) => this.onFilterChange(event, clearFilters)}
            onFilterBatchChange={(eventList) => this.onFilterBatchChange(eventList)}
            onSearchFilterChange={(value) => this.onSearchFilterChange(value)}
            totalOrders={this.state.totalOrders}
            totalStatus={this.state.totalStatus}
            totalAwaitingWlkSubmission={this.state.totalAwaitingWlkSubmission}
            hasTooManyOffices={this.state.hasTooManyOffices}
            officesToDisplayMerchandiseBanner={this.state.officesToDisplayMerchandiseBanner}
            updateTab={this.updateTab}
          />
        ) : (
          <OrdersAbandonedTemplate updateTab={this.updateTab} profile={this.state.userDetails} />
        )}
      </Layout>
    );
  }
}

export default function (props) {
  const navigate = useNavigate();
  const location = useLocation();

  return <Orders {...props} navigate={navigate} preFilter={location.state} />;
}
