import React from 'react';
import './confirmAddress.css';
import { Button } from '@ftdr/blueprint-components-react';
import { Address } from '@app/models';
import REText from '@components/wrappedBDS/REText';

export interface UnknownAddressModalContentProps {
  id: string;
  addressFromService: Address;
  addressFromInput: Address;
  onEditAddress: any;
  onUseCurrentAddress: any;
  onUseServiceAddress: any;
}

function addressToString(address1: string, address2: string): string {
  return address2 && address2.length > 0 ? `${address1}, ${address2}` : `${address1}`;
}

const ConfirmAddress: React.FC<UnknownAddressModalContentProps> = ({
  id = 'confirm-address',
  addressFromService,
  addressFromInput,
  onEditAddress,
  onUseCurrentAddress,
  onUseServiceAddress,
}) => {
  return (
    <div className="typo-address-confirmation-modal-content" id={`${id}--content`}>
      {addressFromService && (
        <div className="align-left w-full">
          <REText variant={"caption"} className={"font-bold"}>Did you mean:</REText>

          <div className="flex flex-row items-center justify-between w-full">
            <div>
              <REText variant="caption">
                {addressToString(addressFromService.streetAddress, addressFromService.unit)}{' '}
              </REText>
              <REText variant="caption">
                {`${addressFromService.city}, ${addressFromService.state} ${addressFromService.zip}`}
              </REText>
            </div>
            <Button
              id="confirm-address__use-suggested-address-btn"
              label="Use"
              onClick={onUseServiceAddress}
              size="small"
              className="ml-4"
            />
          </div>
        </div>
      )}

      {!addressFromService && (
        <>
          <div className="align-left w-full flex">
            <REText variant={"caption"} className={"font-bold"}>We couldn’t find the address you entered.</REText>
            <div className="flex-1">
              <Button
                id="confirm-address__edit-address-btn"
                label="Edit Address"
                onClick={onEditAddress}
                size="small"
                className="ml-4"
              />
            </div>
          </div>
        </>
      )}
      <hr className="horizontal-divider" />
      <div className="align-left w-full">
        <REText variant={"caption"} className={"font-bold"}>
          The address you entered:
        </REText>

        <div className="flex flex-row items-center justify-between w-full">
          <div>
            <REText variant="caption">
              {addressToString(addressFromInput.streetAddress, addressFromInput.unit)}{' '}
            </REText>
            <REText variant="caption">
              {`${addressFromInput.city}, ${addressFromInput.state} ${addressFromInput.zip}`}
            </REText>
          </div>
          <Button
            id="confirm-address__use-given-address-btn"
            label="Use"
            variant="outlined"
            onClick={onUseCurrentAddress}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmAddress;
