import { Office, WarrantylinkAccount, WarrantylinkAgreement } from '@apis/models';
import { formatDateFromString } from '@helpers/utils';
import React, { useEffect, useState } from 'react';
import { Link, Panel, Table } from '@ftdr/blueprint-components-react';
import Skeleton from 'react-loading-skeleton';
import useGlobalAlert from '@app/core/GlobalAlertModal';
import msgs from '@app/locales/en';
import DrawerWLKAgreements from '@components/drawer/DrawerWLKAgreements';
import REText from '@components/wrappedBDS/REText';
import { IsTheme, Theme } from '@app/core/featureToggle';

interface Props {
  accounts: WarrantylinkAccountWithOffice[];
  loadingAgreements: boolean;
  onSignAgreement: (office: Office, agreement: WarrantylinkAgreement) => Promise<boolean>;
}

interface AgreementsTableProps {
  accounts: WarrantylinkAccountWithOffice[];
  loadingAgreements: boolean;
  onSelectAgreement: (office: Office, agreement: WarrantylinkAgreement) => void;
}

const AGREEMENT_TYPE_LABEL: { [key: string]: string } = {
  MSA: 'Master Service Agreement',
  SOW: 'Statement of Work',
};

export interface WarrantylinkAccountWithOffice extends WarrantylinkAccount {
  office: Office;
}

export const WarrantyLinkAgreementsTemplate: React.FC<Props> = (props) => {
  const [selectedAgreement, setSelectedAgreement] = useState<WarrantylinkAgreement>(undefined);
  const [selectedOffice, setSelectedOffice] = useState<Office>(undefined);
  const [isAgreementDrawerActive, setIsAgreementDrawerActive] = useState(false);

  const { addErrorToQueue, addSuccessToQueue } = useGlobalAlert();

  const selectAgreement = (office: Office, agreement: WarrantylinkAgreement): void => {
    if (office && agreement) {
      setSelectedAgreement(agreement);
      setSelectedOffice(office);
      setIsAgreementDrawerActive(true);
    }
  };

  const closeAgreementDrawer = () => {
    setSelectedAgreement(undefined);
    setSelectedOffice(undefined);
    setIsAgreementDrawerActive(false);
  };

  const handleAcceptAgreement = (name: string, title: string) => {
    const updatedAgreement: WarrantylinkAgreement = {
      ...selectedAgreement,
      name,
      title,
      signedDate: new Date().toUTCString(),
      status: 'Accepted',
    };
    props.onSignAgreement(selectedOffice, updatedAgreement).then((success) => {
      if (success) {
        closeAgreementDrawer();
        addSuccessToQueue(msgs.SIGN_WLK_AGREEMENTS_SUCCESS);
      } else {
        addErrorToQueue(msgs.SIGN_WLK_AGREEMENTS_FAILURE);
      }
    });
  };

  const handleDeclineAgreement = (name: string, title: string) => {
    const updatedAgreement: WarrantylinkAgreement = {
      ...selectedAgreement,
      name,
      title,
      signedDate: new Date().toUTCString(),
      status: 'Declined',
    };
    props.onSignAgreement(selectedOffice, updatedAgreement).then((success) => {
      if (success) {
        closeAgreementDrawer();
      }
    });
  };

  return (
    <>
      {props.accounts?.length > 0 && (
        <WarrantyLinkAgreementsTable
          accounts={props.accounts}
          loadingAgreements={props.loadingAgreements}
          onSelectAgreement={selectAgreement}
        />
      )}
      <DrawerWLKAgreements
        id="drawer-wlk-agreement"
        isActive={isAgreementDrawerActive}
        office={selectedOffice}
        agreement={selectedAgreement}
        heading={AGREEMENT_TYPE_LABEL[selectedAgreement?.type]}
        onAccept={handleAcceptAgreement}
        onDecline={handleDeclineAgreement}
        onClose={closeAgreementDrawer}
      />
    </>
  );
};

interface AgreementTableItem {
  office: Office;
  agreements: {
    name: string;
    type: string;
    status: string;
    signer: string;
    date: string;
    meta: WarrantylinkAgreement;
  }[];
}

const WarrantyLinkAgreementsTable: React.FC<AgreementsTableProps> = (props) => {
  const getColumnsForAccount = (account: AgreementTableItem) => {
    return [
      {
        Header: <REText variant="heading-06">Agreements</REText>,
        accessor: 'name',
        Cell: ({ value }) => (
          <Link
            color="interactive"
            onClick={() => {
              props.onSelectAgreement(
                account.office,
                account.agreements.find((element) => element.name === value).meta,
              );
            }}
          >
            {value}
          </Link>
        ),
      },
      { Header: <REText variant="heading-06">Status</REText>, accessor: 'status' },
      { Header: <REText variant="heading-06">Accepted By (Title)</REText>, accessor: 'signer' },
      { Header: <REText variant="heading-06">Last Decision Date</REText>, accessor: 'date' },
    ];
  };

  const [agreementTableItems, setAgreementTableItems] = useState<AgreementTableItem[]>([]);

  useEffect(() => {
    let items: AgreementTableItem[] = [];

    if (props.accounts) {
      items = props.accounts
        .filter((account) => account.office.warrantyLinkEligible)
        .map((account) => ({
          office: account.office,
          agreements: account.agreements.map((agreement) => ({
            name: AGREEMENT_TYPE_LABEL[agreement.type] || 'Unknown',
            type: agreement.type,
            status: agreement.status,
            signer: `${agreement.name} (${agreement.title})`,
            date: formatDateFromString(agreement.signedDate),
            meta: agreement,
          })),
        }));
    }

    setAgreementTableItems(items);
  }, [props.accounts]);

  return (
    <div className="mb-8">
      {props.loadingAgreements ? (
        <Panel padding="none" rounded="md" borderColor="gray-300" shadow={true} className="mb-4 pt-1">
          <REText variant="heading-05" className="m-2">
            <Skeleton />
          </REText>
          <Skeleton height="13rem" />
        </Panel>
      ) : (
        agreementTableItems.map((account) => (
          <Panel
            padding="none"
            rounded={IsTheme(Theme.Ahs2024) ? "xl" : "md"}
            borderColor="gray-300"
            shadow={!IsTheme(Theme.Ahs2024)}
            className="mb-4"
            key={account.office.id}
          >
            <div className={IsTheme(Theme.Ahs2024) ? 'ml-4 mr-4' : ''}>
              <REText variant="heading-05" className={`m-2 ${IsTheme(Theme.Ahs2024) ? "pt-5" : ""}`}>
                {account.office.name}
              </REText>
              <Table
                columns={getColumnsForAccount(account)}
                data={account.agreements}
                variant="light"
                sortable={false}
                paginate={false}
              />
            </div>
          </Panel>
        ))
      )}
    </div>
  );
};
